import React, { FC } from "react";
import { Calendar } from "react-date-range";
import { MdDateRange } from "react-icons/md";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { MutableRefObject, useEffect, useRef } from "react";
import Modal from "../Modal/Modal";

interface IDatePicker {
  onChange: (value: string) => void;
  value?: string;
  label: string;
  minDate?: string | undefined;
  maxDate?: string | undefined;
  isDisabled?: boolean;
}

const DatePicker: FC<IDatePicker> = ({
  onChange,
  value,
  label,
  minDate,
  maxDate,
  isDisabled,
}) => {
  const target = useRef() as MutableRefObject<HTMLDivElement>;
  const modal = useRef() as MutableRefObject<any>;
  const scroller = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    scroller.current?.scroll(scroller.current?.scrollWidth, 0);
  }, [value]);
  return (
    <>
      <div
        className="from-input"
        style={
          // isDisabled
          //   ? {
          //       pointerEvents: "none",
          //       opacity: "0.5",
          //     }
          //   : {}
          { height: "56px" }
        }
        ref={target}
        tabIndex={0}
        onFocus={() => {
          if (!target.current) return;
          modal.current.setModalPosition({
            x: target.current.offsetLeft,
            y: target.current.offsetTop,
          });
          modal.current.showModal();
        }}
      >
        <div className="label">{label}</div>
        <div className="inner" ref={scroller}>
          <span className="text-muted fw-semibold">
            {" "}
            {value || "Choose date"}
          </span>
        </div>
      </div>

      <Modal
        ref={modal}
        body={
          <div className="wrapper-departure p-3 pb-1">
            <div className=" w-100">
              <p
                className="mb-1"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                {label}
              </p>
              <div className="mb-2 d-flex align-items-center px-1 border-1 border rounded w-100">
                <MdDateRange size={22} />
                <div className="p-2 text-muted fw-bold">
                  {value || "Pick a date"}
                </div>
              </div>
            </div>
            <Calendar
              minDate={
                minDate
                  ? moment(minDate).toDate()
                  : moment("1900-01-01").toDate()
              }
              maxDate={
                maxDate
                  ? moment(maxDate).toDate()
                  : moment("2099-12-12").toDate()
              }
              onChange={(date) => onChange(moment(date).format("YYYY-MM-DD"))}
              color="#4337BE"
              // onShownDateChange={(value) => {
              // }}
              date={value ? moment(value).toDate() : undefined}
            />
          </div>
        }
      />
    </>
  );
};

export default DatePicker;
