import React from "react";

import Text from "@kiwicom/orbit-components/lib/Text";
import PictureCard from "@kiwicom/orbit-components/lib/PictureCard/index";
import { ChevronRight } from "@kiwicom/orbit-components/lib/icons";
import imgParis from "../../assets/images/locations/paris.png";
import imgAus from "../../assets/images/locations/australia.png";
import imgIndonesia from "../../assets/images/locations/indonesia.png";
import imgItaly from "../../assets/images/locations/italy.webp";
import imgIndia from "../../assets/images/locations/india.png";

import "./Offers.scss";
import CardDestinationDeals from "../Common/CardDestinationDeals/CardDestinationDeals";

export default function Offers() {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <h2>Special Deals & Offers</h2>
          <p className="text-muted">On our most sought-after destinations</p>
        </div>
        <div>
          <Text>
            <button className="btn btn-link btn-sm p-0">
              View All Deals <ChevronRight />
            </button>
          </Text>
        </div>
      </div>

      <div className="spacer--sm"></div>
      <div className="offer-grid">
        <div>
          <CardDestinationDeals
            destinationCity={"Macau"}
            imgSrc={imgParis}
            type={"Family fun"}
            offer={"6 nights from Rs. 50,000"}
            destinationCountry={"Switzerland"}
          />
        </div>
        <div>
          <CardDestinationDeals
            destinationCity={"Paris"}
            imgSrc={imgIndonesia}
            type={"Family fun"}
            offer={"6 nights from Rs. 50,000"}
            destinationCountry={"London"}
          />
        </div>
        <div>
          <CardDestinationDeals
            destinationCity={"India"}
            imgSrc={imgIndia}
            type={"Family fun"}
            offer={"6 nights from Rs. 50,000"}
            destinationCountry={"France"}
          />
        </div>
        <div className="row-span-2">
          <CardDestinationDeals
            destinationCity={"India"}
            imgSrc={"No Image"}
            type={"Family fun"}
            offer={"6 nights from Rs. 50,000"}
            destinationCountry={"France"}
          />
        </div>
        <div className="col-span-2">
          <CardDestinationDeals
            destinationCity={"Westros"}
            imgSrc={imgItaly}
            type={"Family fun"}
            offer={"6 nights from Rs. 50,000"}
            destinationCountry={"Braavos"}
          />
        </div>
        <div>
          <CardDestinationDeals
            destinationCity={"Hogwarts"}
            imgSrc={imgAus}
            type={"Family fun"}
            offer={"6 nights from Rs. 50,000"}
            destinationCountry={"Godric's Hollow"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
