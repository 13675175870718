import React from "react";
import "./AccordionItem.scss";
interface Props {
  title: string;
  onOpen: () => void;
  body?: JSX.Element;
  isCollapsed?: boolean;
  noBorder?: boolean;
}

const AccordionItem = (props: Props) => {
  return (
    <div
      onClick={() => {
        props.onOpen();
      }}
      className={`${props.noBorder ? "" : "border-bottom"}`}
    >
      <button
        className={`accordion-button ${props.isCollapsed ? "collapsed" : ""}`}
        type="button"
      >
        {props.title}
      </button>
      <div
        className={`py-2  ${props.isCollapsed ? "collapse" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.body}
      </div>
    </div>
  );
};

export default AccordionItem;
