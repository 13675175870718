import React from "react";
import "./Input.scss";

interface Props {
  type: string;
  value: string;
  label?: string;
  inlineLabel?: boolean;
  onChange: (value: string) => void;
  icon?: string | JSX.Element;
  onIconClick?: () => void;
  placeholder?: string;
}

const InputWithIcon = ({
  type,
  value,
  onChange,
  icon,
  onIconClick,
  label,
  inlineLabel,
  placeholder,
}: Props) => {
  return (
    <div>
      {label && inlineLabel === undefined && (
        <label htmlFor="placeholder">{label}</label>
      )}
      <div className="input-group rounded border">
        {label && inlineLabel && <span className="my-auto px-1">{label}</span>}
        <input
          type={type}
          className={`form-control`}
          placeholder={placeholder ? placeholder : "Enter here.."}
          onChange={(e) => {
            const { value } = e.target;
            onChange(value);
          }}
          value={value}
        />
        <div
          className="input-group-text opacity-100"
          onClick={() => onIconClick && onIconClick()}
        >
          {icon}
        </div>
      </div>
    </div>
  );
};

export default InputWithIcon;
