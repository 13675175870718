import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsFillPersonFill } from "react-icons/bs";
import { FaChild } from "react-icons/fa";
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox";
import { InformationCircle } from "@kiwicom/orbit-components/lib/icons";

import CardBase from "../../../../components/Common/CardBase/CardBase";
import HotelPersonalDetailsForm from "../../HotelBeds/Components/HotelPersonalDetailsForm";

import { State } from "../../../../redux/types";
import useHotel from "../../../../utils/hooks/Hotel/useHotel";
import { commonStateSlice } from "../../../../redux/reducers/commonState";
import THotelPersonalDetailsForm from "../../TBO/Components/THotelPersonalDetailsForm";

const HotelPassengerDetails = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { searchHotel, passenger, bookingPassenger } = useSelector(
    (state: State) => state.hotel
  );
  const { isLoggedIn } = useSelector((state: State) => state.user);

  const { updateBookingPassenger } = useHotel();

  const [guestCount, setGuestCount] = useState<{
    rooms: number;
    adults: number;
    children: number;
  }>({
    rooms: 0,
    adults: 0,
    children: 0,
  });

  useEffect(() => {
    // calculating no. of rooms, adults, children
    setGuestCount({
      rooms: searchHotel?.noOfRooms,
      adults: searchHotel?.RoomGuest?.reduce(
        (accu, curr) => (accu += Number(curr?.NoOfAdults)),
        0
      ),
      children: searchHotel?.RoomGuest?.reduce(
        (accu, curr) => (accu += Number(curr?.NoOfChild)),
        0
      ),
    });
  }, [isLoggedIn]);


  
  return (
    <React.Fragment>
      {!isLoggedIn && (
        <div className="alert alert-primary d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <InformationCircle />
            <p className="px-2 my-auto">
              <strong>Existing customer? </strong> Please signin for easier
              booking.
            </p>
          </div>
          <button
            className="btn btn-primary btn-sm"
            onClick={() =>
              dispatch(commonStateSlice.actions.toggle("showSignInModal"))
            }
          >
            Sign in
          </button>
        </div>
      )}

      {/* BOOKING CUSTOMER DETAILS */}
      <CardBase title={"Contact Details"}>
        {/* <div className="d-flex gap-3 justify-content-between py-4 align-items-end"> */}
        <div className="row py-3">
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label htmlFor="Mobile" className="small mb-0">
                Mobile Number
              </label>
              <div className="input-group border p-0 rounded">
                <select
                  className=" border-0 form-select shadow-none"
                  style={{ maxWidth: "30%" }}
                  onChange={(e: any) => {
                    const { value } = e.target;
                    updateBookingPassenger("countryCode", value);
                  }}
                  value={bookingPassenger.countryCode}
                >
                  <option value="+91">+91 India</option>
                  <option value="+01">+01 USA</option>
                  <option value="+12">+12 Finland</option>
                  <option value="+06">+06 Russia</option>
                </select>
                <span className="px-1 text-muted my-auto">|</span>
                <input
                  type="tel"
                  id="Mobile"
                  className="form-control border-0 shadow-none"
                  placeholder="9999 999 999"
                  aria-label="9999 999 999"
                  value={bookingPassenger.mobileNo}
                  min={0}
                  maxLength={10}
                  onChange={(e: any) => {
                    const { value } = e.target;
                    updateBookingPassenger("mobileNo", value);
                  }}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group">
              <label htmlFor="passengerEmail" className="small mb-0">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="passengerEmail"
                placeholder="e.g.: john.doe@gmail.com"
                value={bookingPassenger.email}
                onChange={(e: any) => {
                  const { value } = e.target;
                  updateBookingPassenger("email", value);
                }}
              />
            </div>
          </div>
        </div>

        <Checkbox
          checked
          //   info="Additional information for this choice"
          label="Send me travel offers, deals and news by email and sms."
          //   name="name"
          onChange={function () { }}
        //   value="value"
        />
      </CardBase>

      {/* ALL GUEST DETAILS */}
      <CardBase title={"Guest Details"}>
        <p className="text-muted ">
          {guestCount.rooms} Rooms, {guestCount.adults} Adult(s){" "}
          {guestCount.children
            ? ", " +
            guestCount.children +
            (guestCount.children > 1 ? " Children" : " Child")
            : ""}
        </p>

        <div className="alert alert-warning d-flex align-items-center">
          <InformationCircle />
          <p className="px-2 my-auto">
            Use given names and surnames exactly as they appear in your
            passport/ID to avoid boarding complications.
          </p>
        </div>

        {passenger?.map((_, roomIndex) => (
          <React.Fragment key={roomIndex}>
            <h4>Room {roomIndex + 1}</h4>
            <hr />
            {passenger[roomIndex]?.map((_, i) => (
              <React.Fragment key={i}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-muted d-flex align-items-center">
                    <span>
                      {passenger[roomIndex][i].PaxType === "1"
                        ? "Adult"
                        : "Child"}
                    </span>
                    <span className="d-flex align-items-center fw-bold">
                      {passenger[roomIndex][i].PaxType === "1" ? (
                        <BsFillPersonFill />
                      ) : (
                        <FaChild />
                      )}
                      <span>
                      </span>
                    </span>
                  </div>

                  {passenger[roomIndex][i].PaxType === "2"
                    ?
                    <div className="badge bg-light text-dark">Age - {passenger[roomIndex][i].Age}</div>
                    :
                    <></>}
                </div>
                {location.pathname.includes("HB")
                  ?
                  <HotelPersonalDetailsForm
                    room={roomIndex}
                    passengerIndex={i}
                  />
                  :
                  <THotelPersonalDetailsForm
                    room={roomIndex}
                    passengerIndex={i}
                  />}
                <div className="spacer spacer-sm" />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </CardBase>
    </React.Fragment>
  );
};

export default HotelPassengerDetails;
