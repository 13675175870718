import React from "react";
import Skeleton from "./Skeleton";

interface Props {}

const LoadingSearchHotel = (props: Props) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <Skeleton typeArray={["text"]} elementStyle={{ width: "50%" }} />
          <Skeleton typeArray={["title"]} elementStyle={{ width: "100%" }} />
          <Skeleton typeArray={["box"]} />
          <Skeleton
            typeArray={["box"]}
            count={2}
            elementStyle={{ height: "150px" }}
          />
        </div>
        <div className="col-md-9">
          <Skeleton typeArray={["text"]} />
          <div>
            {[1, 2].map((_, index) => (
              <div key={index + "ske_ele"}>
                <div className="row">
                  <div className="col-4">
                    <Skeleton
                      typeArray={["box"]}
                      elementStyle={{ height: "230px" }}
                    />
                  </div>
                  <div className="col-8">
                    <Skeleton typeArray={["title", "text", "text"]} count={2} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSearchHotel;
