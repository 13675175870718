import { ChevronRight } from '@kiwicom/orbit-components/lib/icons';
import moment from 'moment';
import React, { Dispatch, useEffect } from 'react';

import Button from '../../../../../components/Common/Button/Button';
import CardBase from '../../../../../components/Common/CardBase/CardBase';
import Image from '../../../../../components/Common/Image/Image';

import { ISelectedHotel, ISearchHotelInfo, State } from '../../../../../redux/types';
import { HOTEL_BEDS_FILE_BASE_URL } from '../../../../../utils/constants';
import { priceFormatter } from '../../../../../utils/utilityFunctions/utilityFunction';
import { BsInfoCircle } from 'react-icons/bs';
import { Popover, Stack } from '@kiwicom/orbit-components';

interface Props {
    addPayment: (bookingSum: number) => void;
    isLoggedIn: boolean;
    isGeneratingOrder: boolean;
    isBookingInProgress: boolean;
    blockRoom: (res: any) => void;
    toggleLoginModal: () => void;
    searchHotel: ISearchHotelInfo;
    searchedHotel: ISelectedHotel;
    guestCount: {
        rooms: number;
        adults: number;
        children: number;
    };
    billingInfo: {
        markedUpBasePrice: number;
        Tax: number;
        GST: number;
        otherMarkUpCharges: number;
        myCharges: number;
    };
};

export default function PricingSummary({
    addPayment,
    isLoggedIn,
    isGeneratingOrder,
    isBookingInProgress,
    blockRoom,
    toggleLoginModal,
    searchedHotel,
    searchHotel,
    guestCount,
    billingInfo
}: Props) {
    return (
        <React.Fragment>
            {/* Right panels */}
            <div className="pb-4 sticky-container">
                {/*  style={{ zIndex: 5 }}  sticky-container */}
                <CardBase>
                    <div
                        className="mb-3"
                        style={{
                            height: 180,
                            overflow: "hidden",
                            borderRadius: 4,
                        }}
                    >
                        <Image
                            src={
                                HOTEL_BEDS_FILE_BASE_URL
                                +
                                (searchedHotel.hotel_static_data.images?.find((img) => img?.imageTypeName?.toLocaleLowerCase() === "general view")?.path
                                    ||
                                    searchedHotel.hotel_static_data.images[0]?.path)
                            }
                            className={"w-100"}
                        />
                    </div>

                    <div>
                        <h4 className="mb-1">{searchedHotel?.hotel_dynamic_data.name}</h4>
                        <p className="text-muted">
                            {searchedHotel.noOfNights + " "}
                            night stay
                        </p>
                        <div className="">
                            <ul className="list list--hotel-summary">
                                <div className="list-item">
                                    <b>
                                        {guestCount.rooms} Rooms, {guestCount.adults}{" "}
                                        Adult(s){" "}
                                        {guestCount.children
                                            ? ", " + guestCount.children +
                                            (guestCount.children > 1
                                                ? " Children"
                                                : " Child")
                                            : ""}
                                    </b>
                                </div>
                                <div className="list-item">
                                    <b>Check-In</b> -{" "}
                                    {moment(searchHotel.checkInDate).format(
                                        "MMM DD, YYYY"
                                    )}
                                </div>
                                <div className="list-item">
                                    <b>Check-Out</b> -{" "}
                                    {moment(searchHotel.checkOutDate).format(
                                        "MMM DD, YYYY"
                                    )}
                                </div>
                            </ul>
                        </div>
                    </div>

                    <div className="spacer spacer--md"></div>
                    {/* <h4 className="">Price</h4> */}
                    <div className="d-flex justify-content-between mb-2">
                        <div className="fw-bold">
                            {searchHotel?.noOfRooms} room,{" "}
                            {moment(searchHotel.checkOutDate).diff(
                                searchHotel.checkInDate,
                                "days"
                            )}{" "}
                            nights
                        </div>
                        <div className="fw-bold pb-0">
                            {priceFormatter(
                                billingInfo?.markedUpBasePrice,
                                searchedHotel.hotel_dynamic_data.currency
                            )}
                        </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mb-2">
                        <div>Taxes & fees</div>
                        <div>{priceFormatter(
                            billingInfo?.Tax,
                            searchedHotel.hotel_dynamic_data.currency
                        )}</div>
                    </div> */}

                    {/* <div className="d-flex justify-content-between mb-2">
                        <div>GST</div>
                        <div>{priceFormatter(
                            billingInfo?.GST,
                            searchedHotel.hotel_dynamic_data.currency
                        )}</div>
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                        <div>Other Charge</div>
                        <div>{priceFormatter(
                            billingInfo?.otherMarkUpCharges + billingInfo?.myCharges,
                            searchedHotel.hotel_dynamic_data.currency
                        )}</div>
                    </div> */}

                    <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex align-items-center gap-2">
                            <span>Taxes & Service Fees</span>
                            <Popover
                                overlapped
                                content={
                                    <Stack>
                                        <React.Fragment key=".0">
                                            <div className="d-flex justify-content-between mb-2">
                                                <div>GST</div>
                                                <div>{priceFormatter(
                                                    billingInfo?.GST,
                                                    searchedHotel.hotel_dynamic_data.currency
                                                )}</div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2 gap-3">
                                                <div>Service Charge</div>
                                                <div>{priceFormatter(
                                                    billingInfo?.otherMarkUpCharges + billingInfo?.myCharges,
                                                    searchedHotel.hotel_dynamic_data.currency
                                                )}</div>
                                            </div>
                                        </React.Fragment>
                                    </Stack>
                                }
                            >
                                <BsInfoCircle
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="View Details"
                                    className="pointer"
                                />
                            </Popover>

                        </div>
                        <div>{priceFormatter(
                            billingInfo?.GST +
                            billingInfo?.otherMarkUpCharges +
                            billingInfo?.myCharges,
                            searchedHotel.hotel_dynamic_data.currency
                        )}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-4 border-top">
                        <h3 className="fw-bold">Total</h3>
                        <h3 className="fw-bold">
                            {priceFormatter(
                                billingInfo?.markedUpBasePrice +
                                billingInfo?.Tax +
                                billingInfo?.GST +
                                billingInfo?.otherMarkUpCharges +
                                billingInfo?.myCharges,
                                searchedHotel.hotel_dynamic_data.currency
                            )}
                        </h3>
                    </div>
                    <small className="text-muted py-2">
                        Rates are in Indian rupees
                    </small>

                    <div className="mt-4">
                        {
                            !isLoggedIn
                                ?
                                <React.Fragment>
                                    <span className="text-danger">
                                        * Please login to continue booking
                                    </span>
                                    <Button
                                        color={"primary"}
                                        size={"sm"}
                                        className={"w-100"}
                                        label={
                                            <React.Fragment>
                                                Sign In
                                                <ChevronRight />
                                            </React.Fragment>
                                        }
                                        onClick={() => toggleLoginModal()}
                                    />
                                </React.Fragment>
                                :
                                (!isBookingInProgress && !isGeneratingOrder) ? (
                                    <Button
                                        color={"primary"}
                                        size={"sm"}
                                        className={"w-100"}
                                        label={
                                            <React.Fragment>
                                                Book Hotel
                                                <ChevronRight />
                                            </React.Fragment>
                                        }
                                        disabled={!isLoggedIn}
                                        onClick={() => {
                                            addPayment(
                                                billingInfo?.markedUpBasePrice +
                                                billingInfo?.Tax +
                                                billingInfo?.GST +
                                                billingInfo?.otherMarkUpCharges +
                                                billingInfo?.myCharges
                                            )
                                        }
                                            // blockRoom()
                                        }
                                    />
                                ) : (
                                    <Button
                                        color={"primary"}
                                        className={"w-100"}
                                        disabled={true}
                                        size={"sm"}
                                        label={""}
                                        onClick={() => { }}
                                        isLoading={true}
                                    />
                                )}
                    </div>
                </CardBase>
            </div>

            {/* Coupon section */}
            {/* <Coupons /> */}
        </React.Fragment>
    )
}
