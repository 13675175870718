import React from 'react'
import { ITicketDetails } from '../../../utils/types/flightTypes'
import "../Pages/Flight/BookingDetails.scss";

import Image from '../../../components/Common/Image/Image';
import moment from 'moment';
import SimpleCard from '../../../components/card/SimpleCard';
import QRCode from 'react-qr-code';
import { BiRupee } from 'react-icons/bi';
type IProps = {
  ticketDetails: ITicketDetails | undefined
  netPayable: {
    totalTravelerAmount: number;
    netAmount: number;
  }
}
export default function TicketInvoice({ ticketDetails, netPayable }: IProps) {
  return (
    <div>
      <div className=' d-flex justify-content-between'>
        <p className=' fs-4 xl-bold'> TAX INVOICE</p>
        <Image
          className='logo'
          height={45}
          src={require('../../../assets/images/logo.png').default} />
      </div>
      <div className='row border-2 border-bottom border-dark pb-2'>
        <div className='col'>
          <SimpleCard
            label='Booking Id :'
            value={ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || "--"}
          />
          <SimpleCard
            label='Date:'
            value={
              ticketDetails?.lstTicketBooking[0]?.CreatedDate ?
                moment(ticketDetails?.lstTicketBooking[0]?.CreatedDate).format("ddd DD MMM YYYY") : "--"}
          />
          <SimpleCard
            label='Document type :'
            value={'INVOICE'}
          />
          {/* <SimpleCard
            label='Place of Supply :'
            value={ticketDetails?.lstTicketBooking[0]?.CustomerAddress ?
              String(ticketDetails?.lstTicketBooking[0]?.CustomerAddress) : " ---? "}
          /> */}
        </div>
        <div className='col'>
          <SimpleCard
            label='Invoice No. :'
            value={ticketDetails?.lstTicketBooking[0]?.InvoiceNumber ? ticketDetails?.lstTicketBooking[0]?.InvoiceNumber : " -"}
          />
          {/* <SimpleCard
            label='TRANSACTIONAL TYPE/CATEGORY :'
            value={" -- ? "}
          />
          <SimpleCard
            label='TRANSACTION DETAIL :'
            value={' -- ?'}
          /> */}
        </div>
        <div className='col text-center pt-4'>
          <QRCode
            size={120}
            value={`Your Tax Invoice No. is : ${ticketDetails?.lstTicketBooking[0]?.InvoiceNumber}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>

      <h4 className='mt-2'>
        INVOICE ISSUED FOR FLIGHT
      </h4>
      {ticketDetails?.lstTicketPNRDetails?.map((e) =>
        <div className='row mt-1'>
          <div className=' col-lg-3'>
            <p className='fs-7 fw-bolder'>{e?.FlightNo || "--"} </p>
          </div>
          <div className=' col'>
            <div>
              <p className='fs-9 m-0 mt-1 text-secondary fw-bolder'>
                <span className='fs-7 fw-bolder text-dark'>{e?.DepartureCode || " -"}</span>
                -
                <span className='fs-7 fw-bolder text-dark'>{e?.ArrivalCode || "--"}</span>

                {ticketDetails?.lstTicketBooking[0]?.CreatedDate ?
                  moment(ticketDetails?.lstTicketBooking[0]?.CreatedDate).format(" (DD-MM-YYYY)") : " -"}
              </p>
              {ticketDetails?.lstTicketTravellerDetails?.map((e) =>
                <p className='m-0 text-secondary fs-8 fw-bolder'>
                  {`${e?.TravelerTitle || ""} ${e?.TravellerFirstName || " - "} ${e?.TravellerLastName || ""} ( PNR : ${e?.AirlinePNR} ) ( Ticket No : ${e?.TicketNumber} )`}
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      <div className='row mt-5'>
        <div className='col'>
          <p className='label'>CUSTOMER NAME</p>
          <p className='value'>{ticketDetails?.lstTicketBooking[0]?.CustomerFullName || "--"}</p>
          {/* <p className='label'>CUSTOMER GSTIN</p>
          <p className='value'>{'--?'}</p> */}
          {/* <p className='label'>BOOKING BY</p>
          <p className='value'>{ticketDetails?.lstTicketBooking[0]?.BookedByName || "--"}</p>
          <p></p> */}
        </div>
        <div className='col'>
          <p className='label'>BOOKING BY</p>
          <p className='value'>{ticketDetails?.lstTicketBooking[0]?.FirstName}
            {" "}
            {ticketDetails?.lstTicketBooking[0]?.LastName
              ?
              " " + ticketDetails?.lstTicketBooking[0]?.LastName
              :
              ""}</p>
          {/* <p className='label'>COMPANY NAME</p>
          <p className='value'>{ticketDetails?.lstTicketBooking[0]?.CompanyName || "--"}</p>
          <p className='label'>CUSTOMER ADDRESS</p>
          <p className='value'>{ticketDetails?.lstTicketBooking[0]?.CustomerAddress || "--"}</p> */}
        </div>
      </div>

      <div className='border border-2 rounded mt-1'>

        <h3 className='border-bottom pt-2 p-5 pb-2'>PAYMENT BREAKUP</h3>
        <div className='price-detail'>
          {/* <p className='fw-bolder fs-5'>Price Breakup</p> */}
          <div className='row  px-4'>
            <p className='col label '>{"Ticket Charge"}</p>
            <p className='col value col-lg-4 text-end  m-0'>
              <BiRupee />
              <span>{netPayable.totalTravelerAmount ?? 0}</span>
            </p>
          </div>
          {
            ticketDetails?.lstTicketCharges?.map((eachCharge, index) => (
              eachCharge?.ChargeName !== "My Markup" && eachCharge?.ChargeName !== "Discount"
                ?
                <div className='row py-1 px-4' key={index + "charge"}>
                  <p className='col label '>{eachCharge?.ChargeName}</p>
                  <p className='col value col-lg-4 text-end'>
                    <BiRupee />
                    <span>{eachCharge?.Amount ?? 0}</span>
                  </p>
                </div>
                :
                <></>
            ))
          }
          <div className='row  mx-2 pt-2 my-0 border-top border-dark'>
            <p className='col value'>Total Amount</p>
            <p className='col value col-lg-4 m-0 text-end'>
              <BiRupee />
              <span>
                {netPayable.netAmount}
              </span>
            </p>
          </div>
        </div>

        {/* <div className='p-5 pt-1 pb-0 '>
          <div className='d-flex fs-8 justify-content-between fw-bolder'>
            <p className='m-0 text-dark '>*Fare charge
              <p className='mt-0 mb-1 fw-normal'>(including applicable flight taxes collected on behalf of airline & other ancillary charges )</p>
            </p>
            <p> INR 66228.0</p>
          </div>
          <div className='d-flex justify-content-between fs-8 fw-bolder'>
            <p className='m-0'>PML Service
              <p className='mt-0 mb-1'>Fees</p>
            </p>
            <p> INR 0.0</p>
          </div>
          <div className='d-flex justify-content-between text-danger fw-bolder'>
            <p className='m-0'>Effective Discount</p>
            <p className='mb-1'> INR -4661.0</p>
          </div>
          <div className='d-flex justify-content-between pt-1 pb-1 fs-6 fw-bolder border-top border-dark'>
            <p className='m-0'>Total Booking Amount</p>
            <p className='m-0'> INR 616567.0</p>
          </div>
        </div> */}
        <div className='border-top d-flex justify-content-center'>
          <p className='mt-1 mb-1 fs-6 text-muted'>
            This is a computer generated Invoice and does not require Signature/Stamp
          </p>
        </div>
      </div>
      <p className='text-muted m-0 mt-1'>
        GST credit charged by the airline operator is only available against the invoice issued by the respective airline operator.
      </p>
      <p className='text-muted m-0'>If youare looking for the airline GST invoice, check the airline website & download it from there</p>
      <p className='text-muted m-0'>
        This is not a valid travel document
      </p>

      {/* <p className='fw-bolder fs-5 mt-2 mb-3'>
        Invoice issued by PML Travels India Pvt. Ltd
      </p>
      <div className='row'>
        <div className='col'>
          <p className='label'>PAN</p>
          <p className='value'>{"--?"}</p>
        </div>
        <div className='col'>
          <p className='label'>HSN / SAC</p>
          <p className='value'>{"--?"}</p>
        </div>
        <div className='col'>
          <p className='label'>SERVICE DESCRIPTION</p>
          <p className='value'>{"--?"}</p>
        </div>
        <div className='col'></div>
      </div>
      <div className='row'>
        <div className='col'>
          <p className='label'>GSTN</p>
          <p className='value'>{"--?"}</p>
        </div>
        <div className='col'>
          <p className='label'>CIN</p>
          <p className='value'>{"--?"}</p>
        </div>
        <div className='col'>
        </div>
        <div className='col'></div>
      </div> */}
      <div className='row mt-3'>
        <div className='col col-lg-4'>
          <p className='m-0 mt-2 fs-6 fw-bolder'>Paul Merchants Ltd  Corp.Off.PML House</p>
          {/* <p className='m-0 fs-6 fw-bolder'>Corp.Off.PML House</p> */}
          <p className='m-0 fs-6 fw-bolder'>S.C.O. 829-830, Sector 22-A</p>
          <p className='m-0 fs-6 fw-bolder'>Chandigarh</p>
          {/* <p className='m-0 fs-6 fw-bolder'>Phone: 9317714203</p> */}
        </div>
        <div className='col'>
          <p className='text-secondary m-0 fw-bold '>REGISTERED OFFICE</p>
          <p className='m-0 fs-6 fw-bolder'>Paul Merchants Ltd  Corp.Off.PML House</p>
          {/* <p className='m-0 fs-6 fw-bolder'>Corp.Off.PML House</p> */}
          <p className='m-0 fs-6 fw-bolder'>S.C.O. 829-830, Sector 22-A</p>
          <p className='m-0 fs-6 fw-bolder'>Chandigarh</p>
          {/* <p className='m-0 fs-6 fw-bolder'>Phone: 9317714203</p> */}
        </div>
      </div>
    </div>
  )
}
