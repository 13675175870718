import { useState } from "react";
// import Button from "@kiwicom/orbit-components/lib/Button";
import Coupon from "@kiwicom/orbit-components/lib/Coupon";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import { Close } from "@kiwicom/orbit-components/lib/icons";
import InputField from "@kiwicom/orbit-components/lib/InputField";
import Radio from "@kiwicom/orbit-components/lib/Radio";
import Loader from "@kiwicom/orbit-components/lib/Loading/index";
import Text from "@kiwicom/orbit-components/lib/Text";
import CardBase from "../../../../components/Common/CardBase/CardBase";
import Button from "../../../../components/Common/Button/Button";
import InputWithIcon from "../../../../components/Common/Input/InputWithIcon";
import InputSearch from "../../../../components/Common/Input/InputSearch";

interface Props {}

const Coupons = (props: Props) => {
  const [appliedCoupon, setAppliedCoupon] = useState(false);
  const [applyingCoupon, setApplyingCoupon] = useState(false);
  const applyCoupon = () => {
    setApplyingCoupon(true);
    setTimeout(() => {
      setAppliedCoupon(true);
      setApplyingCoupon(false);
    }, 3000);
  };
  const removeCoupon = () => {
    setAppliedCoupon(false);
  };
  return (
    <CardBase title="Have a Discount Coupon?">
      {appliedCoupon ? (
        <Alert
          icon
          title="Successfully applied coupon"
          type="success"
          inlineActions={
            <button
              className="btn btn-sm btn-outline-danger border-0"
              onClick={() => {
                removeCoupon();
              }}
            >
              {/* remove */}
              <Close />
            </button>
          }
        >
          NY2022FLAT10
        </Alert>
      ) : applyingCoupon ? (
        <Loader text="Applying Coupon..." />
      ) : (
        <>
          <p className="small text-muted">
            Please enter the coupon code below or select from the currect
            offers.
          </p>

          {/* <InputField
            onChange={() => {}}
            placeholder="Enter here..."
            suffix={
              <button
                onClick={() => {
                  // applyCoupon();
                }}
                className="bg-primary text-white h-100 px-3 outline-none border-0 rounded"
                style={{ zIndex: -1 }}
              >
                Apply
              </button>
            }
          /> */}
          <InputSearch
            onSearch={(value) => {}}
            placeHolder="Enter Coupon code"
            searchLabel="Apply"
          />
          <div className="mt-4">
            <Radio
              info={
                <Text>
                  Get flat 10% off using <Coupon>NY2022FLAT10</Coupon> on all
                  flight bookings.
                </Text>
              }
              label={
                <span className="fw-bold">New Year Special - 10% off</span>
              }
              onChange={() => {
                // applyCoupon();
              }}
              value="value"
            />
            <Radio
              info="
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat, corrupti!"
              label={
                <span className="fw-bold">
                  Upto 30% off on domestic flights
                </span>
              }
              onChange={() => {
                // applyCoupon();
              }}
              value="value"
            />
          </div>
        </>
      )}
    </CardBase>
  );
};

export default Coupons;
