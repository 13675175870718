import Checkbox from "@kiwicom/orbit-components/lib/Checkbox";
import { InformationCircle } from "@kiwicom/orbit-components/lib/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { flightSlice } from "../../../../redux/reducers/flight";
import { commonStateSlice } from "../../../../redux/reducers/commonState";
import { State } from "../../../../redux/types";
// import { toggle } from "../../../../redux/reducers/commonState";
import PersonalInfoForm from "./PersonalInfoForm";
import CardBase from "../../../../components/Common/CardBase/CardBase";
import { TypePersonalInfo } from "../../../../utils/types/flightTypes";
import GroupedInput from "../../../../components/Common/Input/GroupedInput";
import Button from "../../../../components/Common/Button/Button";
interface Props {}

const PassengerDetails = (props: Props) => {
  const dispatch = useDispatch();
  const { searchInfo, personalInfo } = useSelector(
    (state: State) => state.flight
  );
  const { isLoggedIn } = useSelector((state: State) => state.user);

  const dispatcher = (key: TypePersonalInfo, value: any) => {
    dispatch(flightSlice.actions.updatePersonalInfo({ key, value }));
  };
  return (
    <React.Fragment>
      {!isLoggedIn && (
        <div className="alert alert-primary d-flex align-items-center p-2 mt-2 justify-content-between">
          <div className="d-flex align-items-center">
            <InformationCircle />
            <p className="px-2 my-auto">
              <strong>Existing customer? </strong> Please signin for easier
              booking.
            </p>
          </div>
          <Button
            className=" px-3 py-2"
            onClick={() =>
              dispatch(commonStateSlice.actions.toggle("showSignInModal"))
            }
            label="
            Sign in"
          />
        </div>
      )}
      <CardBase title={"Contact Details"}>
        <div className="row">
          <div className="col-6">
            <GroupedInput
              label="Mobile Number"
              inputs={[
                {
                  body: (
                    <select
                      className=" border-0 form-select shadow-none"
                      style={{ maxWidth: "30%" }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        dispatcher("countryCode", value);
                      }}
                      value={personalInfo?.countryCode}
                    >
                      <option value="+91">+91 India</option>
                      <option value="+01">+01 USA</option>
                      <option value="+12">+12 Finland</option>
                      <option value="+06">+06 Russia</option>
                    </select>
                  ),
                },
                {
                  body: (
                    <input
                      type="text"
                      className="form-control border-0 shadow-none"
                      placeholder="9999 999 999"
                      aria-label="9999 999 999"
                      value={personalInfo?.mobileNo}
                      min={0}
                      maxLength={10}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        dispatcher("mobileNo", value);
                      }}
                      required
                    />
                  ),
                },
              ]}
            />
          </div>
          <div className="col-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              placeholder="e.g.: john.doe@gmail.com"
              type="email"
              id="email"
              className="form-control"
              value={personalInfo.email}
              onChange={(e: any) => {
                const { value } = e.target;
                dispatcher("email", value);
              }}
              required
            />
          </div>
        </div>

        <Checkbox
          checked
          //   info="Additional information for this choice"
          label="Send me travel offers, deals and news by email and sms."
          //   name="name"
          onChange={function () {}}
          //   value="value"
        />
      </CardBase>

      {/* Passenger Info Forms */}

      {/* <p className="text-muted ">
        {searchInfo.adult} {searchInfo.adult > 1 ? `Adults` : "Adult"}
        {searchInfo?.child > 0
          ? `, ${searchInfo?.child} ${
              searchInfo?.child > 1 ? "Children" : "Child"
            }`
          : ""}
      </p> */}

      <CardBase title={"Passenger Details"}>
        <div className="alert alert-warning d-flex align-items-center">
          <InformationCircle />
          <p className="px-2 my-auto">
            Use given names and surnames exactly as they appear in your
            passport/ID to avoid boarding complications.
          </p>
        </div>

        {/* Adult forms */}
        {personalInfo.passengers.adult?.map((_, index) => (
          <div className="py-3" key={index}>
            <h5>Adult {index + 1}</h5>
            <PersonalInfoForm index={index} travellerType={1} />
          </div>
        ))}
        {/* Child forms */}
        {personalInfo.passengers.child?.map((_, index) => (
          <div className="py-3" key={index}>
            <h5>Child {index + 1}</h5>
            <PersonalInfoForm travellerType={2} index={index} />
          </div>
        ))}
        {/* Infant forms */}
        {personalInfo.passengers.infant?.map((_, index) => (
          <div className="py-3" key={index}>
            <h5>Infant {index + 1}</h5>
            <PersonalInfoForm travellerType={3} index={index} />
          </div>
        ))}
      </CardBase>
    </React.Fragment>
  );
};

export default PassengerDetails;
