import moment from "moment";
import { IFlightItinerary } from "../../../redux/types";
import {
  IFlightMarkUpData,
  IFlightMetaData,
  IListChargesOfMarkup,
  IMarkUpResponse,
  IJourney,
  IFLightPricesToShow,
  ISSRLegInfo,
  ISSR,
  ISaveFlightData,
  ISeatAvailability,
  ISearchInfo,
  IPersonalDetails,
  ISelectedAddOns,
  ISelectedFaresForItinerary,
  IFlightSearchData,
  TFlightResultList,
} from "../../types/flightTypes";
import { getMyChargesAmount } from "../utilityFunction";
import { initialState } from "../../../redux/initialState";

import SSRToBeSold from "../../../Data/SSRToBeSold.json";
import fareTypeInfo from "../../../Data/FareTypeInfo.json";

// get required fields from the getAvailablity API response data.

/* 
  check if the value passed is array or object.
  if object then convert it to array of Objects
  using for Consistency in data since when the data in response of flight APIs has
  one value then it return an object but if it has multi values then it returns array of objects hence converting all the possible
*/
export const formatToArr = (data: any[] | any) => {
  return Array.isArray(data) ? data : [data];
};

// calculates base price, service price and booking price based on markup charges data
// this is calculating for showing on search result page only
// expanded calculation is done in below function
export const getFlightMarkedUpPriceForSearchResults = (
  markup: IMarkUpResponse[], //repsonsedata []
  adultBasePrice: number, //price to be markedup i.e actual price from supplier
  adultTravelPrice: number, //price to be markedup i.e actual price from supplier
  supplier: string, //supplier code such as "Spicejet" || "TBO"
  numOfPassengers: number,
  numOfJourney: number,
  isDomestic: boolean = true,
  isAgent: boolean = false,
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[],
  affiliateMarkup?: IMarkUpResponse[]
) => {
  const response: {
    markedupBasePrice: number;
    markedupTotalPrice: number;
    markedUpTaxPrice: number;
    baseMarkupAmount: number;
    taxMarkupAmount: number;
    gstAmount: number;
  } = {
    markedupBasePrice: adultBasePrice,
    markedUpTaxPrice: adultTravelPrice,
    markedupTotalPrice: adultBasePrice + adultTravelPrice,
    baseMarkupAmount: 0,
    taxMarkupAmount: 0,
    gstAmount: 0
  };

  const markupAmounts = getFilteredMarkups(
    markup,
    supplier,
    adultBasePrice,
    adultTravelPrice,
    numOfPassengers,
    numOfJourney,
    GSTRates,
    isDomestic
  );

  const affiliateMarkupAmounts = getFilteredMarkups(
    affiliateMarkup || [],
    supplier,
    adultBasePrice,
    adultTravelPrice,
    numOfPassengers,
    numOfJourney,
    GSTRates,
    isDomestic
  );
  response.baseMarkupAmount += markupAmounts.baseMarkupAmount;
  response.markedupBasePrice += markupAmounts.baseMarkupAmount;
  response.markedupTotalPrice += markupAmounts.baseMarkupAmount;
  response.gstAmount += markupAmounts.GSTAmount;

  response.baseMarkupAmount += affiliateMarkupAmounts.baseMarkupAmount;
  response.markedupBasePrice += affiliateMarkupAmounts.baseMarkupAmount;
  response.markedupTotalPrice += affiliateMarkupAmounts.baseMarkupAmount;
  response.gstAmount += affiliateMarkupAmounts.GSTAmount;

  // console.log("-------------------------------");
  // console.log("markup: ", markup);
  // console.log("supplier: ", supplier);
  // console.log("adultBasePrice: ", adultBasePrice);
  // console.log("adultTravelPrice: ", adultTravelPrice);
  // console.log("response before: ", response);
  // console.log("*******************************");
  // console.log("markupAmounts: ", markupAmounts);
  // console.log("response after: ", response);

  return response;
};

// calculates base price, service price and booking price based on markup charges data
export const getFlightMarkedUpPrice = (
  markup: IMarkUpResponse[], //repsonsedata []
  adultBasePrice: number, //price to be markedup i.e actual price from supplier
  adultTravelPrice: number, //price to be markedup i.e actual price from supplier
  childBasePrice: number, //price to be markedup i.e actual price from supplier
  childTravelPrice: number, //price to be markedup i.e actual price from supplier
  supplier: string, //supplier code such as "Spicejet" || "TBO"
  numOfAdult: number,
  numOfChild: number,
  numOfJourney: number,
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[],
  affiliateMarkup?: IMarkUpResponse[],
  isDomestic: boolean = true
) => {
  const response: IFlightMarkUpData = {
    adult: {
      markedupBasePrice: adultBasePrice,
      markedupBookingPrice: 0,
      markedupServicePrice: 0,
      markedUpTaxPrice: adultTravelPrice,
      baseMarkupAmount: 0,
      taxMarkupAmount: 0,
      agentBaseMarkupAmount: 0,
      agentTaxMarkupAmount: 0,
      gstAmount: 0
    },
    child: {
      markedupBasePrice: childBasePrice || 0,
      markedupBookingPrice: 0,
      markedupServicePrice: 0,
      markedUpTaxPrice: childTravelPrice || 0,
      baseMarkupAmount: 0,
      taxMarkupAmount: 0,
      agentBaseMarkupAmount: 0,
      agentTaxMarkupAmount: 0,
      gstAmount: 0
    },
  };

  const adultMarkupAmount = getFilteredMarkups(
    markup,
    supplier,
    adultBasePrice,
    adultTravelPrice,
    numOfAdult,
    numOfJourney,
    GSTRates,
    isDomestic
  );
  const childMarkupAmount = getFilteredMarkups(
    markup,
    supplier,
    childBasePrice,
    childTravelPrice,
    numOfChild,
    numOfJourney,
    GSTRates,
    isDomestic
  );

  const affiliateAdultMarkup = getFilteredMarkups(
    affiliateMarkup || [],
    supplier,
    adultBasePrice,
    adultTravelPrice,
    numOfAdult,
    numOfJourney,
    GSTRates,
    isDomestic
  );
  const affiliateChildMarkup = getFilteredMarkups(
    affiliateMarkup || [],
    supplier,
    childBasePrice,
    childTravelPrice,
    numOfChild,
    numOfJourney,
    GSTRates,
    isDomestic
  );

  // Admin markups
  response.adult.baseMarkupAmount = adultMarkupAmount.baseMarkupAmount;
  response.adult.markedupBasePrice += adultMarkupAmount.baseMarkupAmount;
  response.adult.markedupServicePrice += adultMarkupAmount.serviceMarkupAmount;
  response.adult.gstAmount += adultMarkupAmount.GSTAmount;

  response.child.baseMarkupAmount = childMarkupAmount.baseMarkupAmount;
  response.child.markedupBasePrice += childMarkupAmount.baseMarkupAmount;
  response.child.markedupServicePrice += childMarkupAmount.serviceMarkupAmount;
  response.child.gstAmount += childMarkupAmount.GSTAmount;

  // Agent markups
  response.adult.agentBaseMarkupAmount = affiliateAdultMarkup.baseMarkupAmount;
  response.adult.markedupBasePrice += affiliateAdultMarkup.baseMarkupAmount;
  response.adult.markedupServicePrice += affiliateAdultMarkup.serviceMarkupAmount;
  response.adult.gstAmount += affiliateAdultMarkup.GSTAmount;

  response.child.agentBaseMarkupAmount = affiliateChildMarkup.baseMarkupAmount;
  response.child.markedupBasePrice += affiliateChildMarkup.baseMarkupAmount;
  response.child.markedupServicePrice += affiliateChildMarkup.serviceMarkupAmount;
  response.child.gstAmount += affiliateChildMarkup.GSTAmount;

  // let filteredMarkUp = markup?.filter(
  //   (mark) =>
  //     String(mark?.SupplierNames).split(",").includes(supplier) &&
  //     mark?.ApplyTypeId === 1 //for online type only
  //   // && isDomestic
  //   //   ? mark?.MarkupTypeId === 1
  //   //   : 2
  // );
  // let filteredAffiliateMarkUp = affiliateMarkup?.filter(
  //   (mark) =>
  //     String(mark?.SupplierNames).split(",").includes(supplier) &&
  //     mark?.ApplyTypeId === 1 //for online type only
  // );
  // let lstChargesForAdult: IListChargesOfMarkup[] =
  //   filteredMarkUp?.find(
  //     (mark) =>
  //       mark?.GrossPriceFrom < adultBasePrice &&
  //       mark?.GrossPriceTo > adultBasePrice
  //   )?.lstCharges || [];
  // let lstChargesForChild: IListChargesOfMarkup[] =
  //   filteredMarkUp?.find(
  //     (mark) =>
  //       mark?.GrossPriceFrom < childBasePrice &&
  //       mark?.GrossPriceTo > childBasePrice
  //   )?.lstCharges || [];

  // // const { ApplyForId, ApplyOnId } = filteredMarkUp?.[0];
  // const ApplyForId = filteredMarkUp?.[0]?.ApplyForId || 0;
  // const ApplyOnId = filteredMarkUp?.[0]?.ApplyOnId || 0;

  // if (listChargesForChild?.length > 0) {
  //   listChargesForChild?.forEach((charges) => {
  //     let amount = calculateChargeAmount(
  //       ApplyOnId === 2
  //         ? childBasePrice
  //         : ApplyOnId === 3
  //         ? childTravelPrice
  //         : ApplyOnId === 4
  //         ? childBasePrice + childTravelPrice
  //         : ApplyOnId === 1
  //         ? childBasePrice + childTravelPrice
  //         : 0,
  //       charges?.FeeValue,
  //       ApplyForId,
  //       charges?.MarkupFeeTypeId,
  //       numOfChild,
  //       numOfJourney
  //     );
  //     if (charges?.MarkupFeeId === 1) {
  //       //Base Charge
  //       response.child.baseMarkupAmount = amount;
  //       response.child.markedupBasePrice += amount;
  //     } else if (charges?.MarkupFeeId === 2) {
  //       //Service charge
  //       response.child.markedupServicePrice = amount;
  //     } else {
  //       //Booking Charge
  //       response.child.markedupBookingPrice = amount;
  //     }
  //   });
  // }
  return response;
};

const calculateChargeAmount = (
  price: number,
  feeValue: number,
  ApplyFor: number,
  MarkupFeeTypeId: number,
  numOfPassenger: number,
  numOfJourney: number
) => {
  if (MarkupFeeTypeId === 1) {
    //fixed
    if (ApplyFor === 2) {
      //per passenger
      return feeValue;
    } else if (ApplyFor === 1) {
      //booking
      // if feeValue/markup is 10k and 1 pax with return journey then
      //  5k will be added on first trip flights and 5k on 2nd trip prices
      let perPassengerValue = Math.floor(
        feeValue / (numOfPassenger * numOfJourney)
      );
      return perPassengerValue;
    } else {
      return 0; //write here for sector ApplyFor
    }
  } else {
    //percentage
    return price * (feeValue / 100);
  }
};

export const calculateFlightPricesToShow = (
  journeys: IJourney[],
  adultCount: number,
  childCount: number,
  selectedAddOns: ISelectedAddOns[],
  agentAddonMarkup: number
) => {

  let result: IFLightPricesToShow = {
    Base: {
      TotalAdult: 0,
      TotalChild: 0,
      PerAdultByJourney: [],
      PerChildByJourney: [],
    },
    Charges: {
      FareTax: {
        TotalAdult: 0,
        TotalChild: 0,
        PerAdultByJourney: [],
        PerChildByJourney: [],
      }, //on Fare excluding addons
      Baggage: 0,
      Meals: 0,
      Seat: 0,
      TotalGST: 0, //includes all GST
      SSRChargesByPaxPerJourney: [],
    },
    Discounts: { Child: 0, Coupon: 0 },
    ServiceCharges: 0,
    AgentAddOnMarkup: agentAddonMarkup,
    NetPayable: 0,
  };

  journeys.forEach((journey) => {
    const { adult, child } = journey?.meta?.markupPrice;
    const {
      vendorCharges,
      adultGSTPrice,
      childGSTPrice,
      adultTravelFee,
      childTravelFee,
      childDiscountPrice,
    } = journey?.meta;

    result.Base.TotalAdult +=
      Number(adult?.markedupBasePrice || 0) * adultCount;
    result.Base.PerAdultByJourney.push(Number(adult?.markedupBasePrice || 0));

    result.Base.TotalChild +=
      Number(child?.markedupBasePrice - (childDiscountPrice || 0)) * childCount;
    result.Base.PerChildByJourney.push(Number(child?.markedupBasePrice || 0));

    // result.Charges.FareTax.TotalAdult +=
    //   Number(adult?.markedUpTaxPrice || 0) * adultCount + (Number(adultGSTPrice || 0) * adultCount);
    // result.Charges.FareTax.TotalChild +=
    //   Number(child?.markedUpTaxPrice || 0) * childCount + (Number(childGSTPrice || 0) * childCount);
    result.Charges.FareTax.TotalAdult += Number(adult?.markedUpTaxPrice || 0) * adultCount + (Number(adultGSTPrice || 0) * adultCount);
    result.Charges.FareTax.TotalChild += Number(child?.markedUpTaxPrice || 0) * childCount + (Number(childGSTPrice || 0) * childCount);
    result.Charges.FareTax.PerAdultByJourney.push(
      Number(adult?.markedUpTaxPrice || 0) + (Number(adultGSTPrice || 0) * adultCount)
    );
    result.Charges.FareTax.PerChildByJourney.push(
      Number(child?.markedUpTaxPrice || 0) + (Number(childGSTPrice || 0) * childCount)
    );

    result.Charges.TotalGST +=
      // Number(adultGSTPrice || 0) * adultCount
      // +
      // Number(childGSTPrice || 0) * childCount
      // +
      journey.meta.markupPrice.adult.gstAmount
      +
      journey.meta.markupPrice.child.gstAmount;

    result.ServiceCharges +=
      adult?.markedupServicePrice + child?.markedupServicePrice;

    result.Discounts.Child += (childDiscountPrice || 0) * childCount;
  });

  let SSRPrices = calculateSSRPrices(
    selectedAddOns,
    journeys?.length,
    adultCount + childCount
  );

  // result.SSR = calculatedSSRPrices;
  result.Charges.Baggage = SSRPrices.Baggage.Charge;
  result.Charges.Seat = SSRPrices.Seat.Charge;
  result.Charges.Meals = SSRPrices.Meals.Charge;
  result.Charges.SSRChargesByPaxPerJourney = SSRPrices.ChargeByPaxPerJourney;
  result.Charges.TotalGST += SSRPrices.TotalGST;
  result.NetPayable =
    result.Base.TotalAdult +
    result.Base.TotalChild +
    result.Charges.FareTax.TotalAdult +
    result.Charges.FareTax.TotalChild +
    result.Charges.TotalGST +
    result.Charges.Baggage +
    result.Charges.Meals +
    result.Charges.Seat +
    result.ServiceCharges -
    (result.Discounts.Child + result.Discounts.Coupon) +
    result.AgentAddOnMarkup;

  return result;

};

export const calculateFlightPricesToShowAirIQ = (
  // selectedFlights: IFlightSearchData,
  markedupPrice: IFlightMarkUpData[],
  adultCount: number,
  childCount: number,
  // selectedAddOns: ISelectedAddOns[],
  agentAddonMarkup: number
) => {

  let result: IFLightPricesToShow = {
    Base: {
      TotalAdult: 0,
      TotalChild: 0,
      PerAdultByJourney: [],
      PerChildByJourney: [],
    },
    Charges: {
      FareTax: {
        TotalAdult: 0,
        TotalChild: 0,
        PerAdultByJourney: [],
        PerChildByJourney: [],
      }, //on Fare excluding addons
      Baggage: 0,
      Meals: 0,
      Seat: 0,
      TotalGST: 0, //includes all GST
      SSRChargesByPaxPerJourney: [],
    },
    Discounts: { Child: 0, Coupon: 0 },
    ServiceCharges: 0,
    AgentAddOnMarkup: agentAddonMarkup,
    NetPayable: 0,
  };

  markedupPrice.forEach((journey) => {
    const { adult, child } = journey;
    // const {
    //   vendorCharges,
    //   adultGSTPrice,
    //   childGSTPrice,
    //   adultTravelFee,
    //   childTravelFee,
    //   childDiscountPrice,
    // } = journey?.meta;
    const adultGSTPrice = 0;
    const childGSTPrice = 0;
    const childDiscountPrice = 0;

    result.Base.TotalAdult += Number(adult?.markedupBasePrice || 0) * adultCount;
    result.Base.PerAdultByJourney.push(Number(adult?.markedupBasePrice || 0));

    result.Base.TotalChild += Number(child?.markedupBasePrice - (childDiscountPrice || 0)) * childCount;
    result.Base.PerChildByJourney.push(Number(child?.markedupBasePrice || 0));

    // result.Charges.FareTax.TotalAdult +=
    //   Number(adult?.markedUpTaxPrice || 0) * adultCount + (Number(adultGSTPrice || 0) * adultCount);
    // result.Charges.FareTax.TotalChild +=
    //   Number(child?.markedUpTaxPrice || 0) * childCount + (Number(childGSTPrice || 0) * childCount);
    result.Charges.FareTax.TotalAdult += Number(adult?.markedUpTaxPrice || 0) * adultCount + (Number(adultGSTPrice || 0) * adultCount);
    result.Charges.FareTax.TotalChild += Number(child?.markedUpTaxPrice || 0) * childCount + (Number(childGSTPrice || 0) * childCount);
    result.Charges.FareTax.PerAdultByJourney.push(
      Number(adult?.markedUpTaxPrice || 0) + (Number(adultGSTPrice || 0) * adultCount)
    );
    result.Charges.FareTax.PerChildByJourney.push(
      Number(child?.markedUpTaxPrice || 0) + (Number(childGSTPrice || 0) * childCount)
    );

    result.Charges.TotalGST += 0;
    // journey.meta.markupPrice.adult.gstAmount
    // +
    // journey.meta.markupPrice.child.gstAmount;

    result.ServiceCharges +=
      adult?.markedupServicePrice + child?.markedupServicePrice;

    result.Discounts.Child += (childDiscountPrice || 0) * childCount;
  });

  // result.SSR = calculatedSSRPrices;
  result.Charges.Baggage = 0;
  result.Charges.Seat = 0;
  result.Charges.Meals = 0;
  // result.Charges.SSRChargesByPaxPerJourney = 0;
  result.Charges.TotalGST += 0;
  result.NetPayable =
    result.Base.TotalAdult +
    result.Base.TotalChild +
    result.Charges.FareTax.TotalAdult +
    result.Charges.FareTax.TotalChild +
    result.Charges.TotalGST +
    result.Charges.Baggage +
    result.Charges.Meals +
    result.Charges.Seat +
    result.ServiceCharges -
    (result.Discounts.Child + result.Discounts.Coupon) +
    result.AgentAddOnMarkup;

  return result;

};

export const calculateFlightPricesToShow1G = (
  // selectedFlights: IFlightSearchData,
  markedupPrice: IFlightMarkUpData[],
  adultCount: number,
  childCount: number,
  // selectedAddOns: ISelectedAddOns[],
  agentAddonMarkup: number
) => {

  let result: IFLightPricesToShow = {
    Base: {
      TotalAdult: 0,
      TotalChild: 0,
      PerAdultByJourney: [],
      PerChildByJourney: [],
    },
    Charges: {
      FareTax: {
        TotalAdult: 0,
        TotalChild: 0,
        PerAdultByJourney: [],
        PerChildByJourney: [],
      }, //on Fare excluding addons
      Baggage: 0,
      Meals: 0,
      Seat: 0,
      TotalGST: 0, //includes all GST
      SSRChargesByPaxPerJourney: [],
    },
    Discounts: { Child: 0, Coupon: 0 },
    ServiceCharges: 0,
    AgentAddOnMarkup: agentAddonMarkup,
    NetPayable: 0,
  };

  markedupPrice.forEach((journey) => {
    const { adult, child } = journey;
    // const {
    //   vendorCharges,
    //   adultGSTPrice,
    //   childGSTPrice,
    //   adultTravelFee,
    //   childTravelFee,
    //   childDiscountPrice,
    // } = journey?.meta;
    const adultGSTPrice = 0;
    const childGSTPrice = 0;
    const childDiscountPrice = 0;

    result.Base.TotalAdult += Number(adult?.markedupBasePrice || 0) * adultCount;
    result.Base.PerAdultByJourney.push(Number(adult?.markedupBasePrice || 0));

    result.Base.TotalChild += Number(child?.markedupBasePrice - (childDiscountPrice || 0)) * childCount;
    result.Base.PerChildByJourney.push(Number(child?.markedupBasePrice || 0));

    // result.Charges.FareTax.TotalAdult +=
    //   Number(adult?.markedUpTaxPrice || 0) * adultCount + (Number(adultGSTPrice || 0) * adultCount);
    // result.Charges.FareTax.TotalChild +=
    //   Number(child?.markedUpTaxPrice || 0) * childCount + (Number(childGSTPrice || 0) * childCount);
    result.Charges.FareTax.TotalAdult += Number(adult?.markedUpTaxPrice || 0) * adultCount + (Number(adultGSTPrice || 0) * adultCount);
    result.Charges.FareTax.TotalChild += Number(child?.markedUpTaxPrice || 0) * childCount + (Number(childGSTPrice || 0) * childCount);
    result.Charges.FareTax.PerAdultByJourney.push(
      Number(adult?.markedUpTaxPrice || 0) + (Number(adultGSTPrice || 0) * adultCount)
    );
    result.Charges.FareTax.PerChildByJourney.push(
      Number(child?.markedUpTaxPrice || 0) + (Number(childGSTPrice || 0) * childCount)
    );

    result.Charges.TotalGST += 0;
    // journey.meta.markupPrice.adult.gstAmount
    // +
    // journey.meta.markupPrice.child.gstAmount;

    result.ServiceCharges +=
      adult?.markedupServicePrice + child?.markedupServicePrice;

    result.Discounts.Child += (childDiscountPrice || 0) * childCount;
  });

  // result.SSR = calculatedSSRPrices;
  result.Charges.Baggage = 0;
  result.Charges.Seat = 0;
  result.Charges.Meals = 0;
  // result.Charges.SSRChargesByPaxPerJourney = 0;
  result.Charges.TotalGST += 0;
  result.NetPayable =
    result.Base.TotalAdult +
    result.Base.TotalChild +
    result.Charges.FareTax.TotalAdult +
    result.Charges.FareTax.TotalChild +
    result.Charges.TotalGST +
    result.Charges.Baggage +
    result.Charges.Meals +
    result.Charges.Seat +
    result.ServiceCharges -
    (result.Discounts.Child + result.Discounts.Coupon) +
    result.AgentAddOnMarkup;

  return result;

};

export const calculateSSRPrices = (
  selectedSSRList: ISelectedAddOns[],
  journeyCount: number,
  paxCount: number
) => {
  let result: {
    TotalCharge: number;
    TotalGST: number;
    Baggage: { Charge: number; GST: number };
    Meals: { Charge: number; GST: number };
    Seat: { Charge: number; GST: number };
    ChargeByPaxPerJourney: number[][];
  } = {
    TotalCharge: 0,
    TotalGST: 0,
    Baggage: { Charge: 0, GST: 0 },
    Meals: { Charge: 0, GST: 0 },
    Seat: { Charge: 0, GST: 0 },
    ChargeByPaxPerJourney: Array(journeyCount).fill(Array(paxCount).fill(0)),
    // charge[0][1] means all the charges of ssr selected by pax 1 at journey 0
  };
  if (selectedSSRList.length > 0) {
    let allSelectedSSRs = selectedSSRList.map((seg) => seg?.SSR).flat(2);
    let allSelectedSeats = selectedSSRList?.map((seg) => seg?.Seats).flat(2);

    allSelectedSSRs?.forEach((s) => {
      if (s?.type === "EB") {
        result.Baggage.Charge += Number(s?.TotalPrice?.Charge);
        result.Baggage.GST += Number(s?.TotalPrice?.GST);
      } else if (s?.type === "MEAL") {
        result.Meals.Charge += Number(s?.TotalPrice?.Charge);
        result.Meals.GST += Number(s?.TotalPrice?.GST);
      }
      result.TotalGST += Number(s?.TotalPrice?.GST);
      result.TotalCharge += Number(s?.TotalPrice?.Charge);
    });
    allSelectedSeats?.forEach((seat) => {
      let charge = seat?.PriceList?.reduce(
        (acc, p) => acc + p?.TotalPrice?.Charge,
        0
      );
      let gst = seat?.PriceList?.reduce(
        (acc, p) => acc + p?.TotalPrice?.GST,
        0
      );
      result.Seat.Charge += charge;
      result.Seat.GST += gst;
      result.TotalCharge += charge;
      result.TotalGST += gst;
    });
    selectedSSRList?.forEach((seg) => {
      let journeyIndex = seg?.journeyIndex;
      let ssrBySeg = seg?.SSR;
      let seatBySeg = seg?.Seats;
      let journeyPrice: number[] = Array(paxCount).fill(0);
      ssrBySeg?.forEach((pax, paxIndex) => {
        let charge = pax?.reduce(
          (acc, ssr) => acc + Number(ssr?.TotalPrice?.Charge),
          0
        );
        journeyPrice[paxIndex] += charge;
      });
      seatBySeg?.forEach((pax, paxIndex) => {
        pax?.forEach((seat, ssrIndex) => {
          let charge = seat?.PriceList?.reduce(
            (acc, p) => acc + p?.TotalPrice?.Charge,
            0
          );
          journeyPrice[paxIndex] += charge;
        });
      });
      result.ChargeByPaxPerJourney[journeyIndex] =
        result?.ChargeByPaxPerJourney[journeyIndex]?.map(
          (e, i) => e + journeyPrice[i]
        );
    });
  }
  return result;
};

export const formatSSRList = (SSRList: any[]) => {
  return SSRList?.map((ssr) => {
    let result: { AvailableSSR: ISSR[]; LegInfo: ISSRLegInfo } = {
      AvailableSSR: [],
      LegInfo: {
        ArrivalStation: "",
        DepartureDate: "",
        FlightNumber: "",
        CarrierCode: "",
        DepartureStation: "",
      },
    };
    let legKey = ssr?.LegKey;
    let CarrierCode = legKey?.CarrierCode?.["#text"];
    result.LegInfo.ArrivalStation = legKey?.ArrivalStation;
    result.LegInfo.DepartureDate = legKey?.DepartureDate;
    result.LegInfo.DepartureStation = legKey?.DepartureStation;
    result.LegInfo.CarrierCode = CarrierCode;
    result.LegInfo.FlightNumber = legKey?.FlightNumber?.["#text"];

    let avSSR = formatToArr(ssr?.AvailablePaxSSRList?.AvailablePaxSSR);
    result.AvailableSSR = avSSR?.map((av) => {
      let ssLocalList = [...SSRToBeSold?.EB, ...SSRToBeSold?.MEAL] || [];
      let temp = ssLocalList.find((ss) => ss.SSRCode === av?.SSRCode);
      let paxSSRPrice = formatToArr(av?.PaxSSRPriceList?.PaxSSRPrice);
      let fare = formatToArr(
        paxSSRPrice[0]?.PaxFee?.ServiceCharges?.BookingServiceCharge
      );
      let charge = fare?.filter((f) => !f?.ChargeCode?.includes("GST"));
      let gst = fare?.filter((f) => f?.ChargeCode?.includes("GST"));
      return {
        Available: av?.Available,
        InventoryControlled: av?.InventoryControlled === "true",
        SeatDependent: av?.SeatDependent === "true",
        PassengerNumberList: av?.PassengerNumberList?.["a:short"],
        SSRCode: av?.SSRCode,
        type: temp?.type || "",
        description: temp?.description || "",
        // PaxSSRPrice is a list in data but at any given time only object is received
        // still keeping it as array just in case.
        // to access PaxSSRPrice use PaxSSRPrice[0].Fare
        PaxSSRPrice: paxSSRPrice?.map((paxPrice) => {
          let paxFee = paxPrice?.PaxFee;
          let fare = formatToArr(paxFee?.ServiceCharges?.BookingServiceCharge);
          let obj: any = {};
          obj.FeeApplicationType = paxFee?.FeeApplicationType;
          obj.FeeCode = paxFee?.FeeCode;
          obj.FeeDetail = paxFee?.FeeDetail;
          obj.FeeNumber = paxFee?.FeeNumber;
          obj.FeeType = paxFee?.FeeType;
          obj.PaymentNumber = paxFee?.PaymentNumber;
          obj.Fare = fare?.map((f) => ({
            Amount: f?.Amount,
            ChargeCode: f?.ChargeCode,
            ChargeDetail: f?.ChargeDetail,
            ChargeType: f?.ChargeType,
            CollectType: f?.CollectType,
            CurrencyCode: f?.CurrencyCode,
            ForeignAmount: f?.ForeignAmount,
            ForeignCurrencyCode: f?.ForeignCurrencyCode,
            TicketCode: f?.TicketCode,
          }));
          return obj;
        }),
        TotalPrice: {
          Charge: charge?.reduce(
            (acc: number, fare: any) => acc + Number(fare?.Amount),
            0
          ),
          GST: gst?.reduce(
            (acc: number, fare: any) => acc + Number(fare?.Amount),
            0
          ),
        },
      };
    });
    return result;
  });
};

export const formatCreateBookingData = (
  bookingResponseByCarrier: { [key: string]: any },
  SelectedAddons: ISelectedAddOns[],
  Itinerary: IFlightItinerary,
  searchInfo: ISearchInfo,
  Client: { id: number; name: string },
  metaData: IFlightMetaData,
  personalInfo: IPersonalDetails,
  userId: string | null,
  affiliateId: string | null,
  agentAddOnMarkup: number
) => {
  // console.log("params for booking data formatter", {
  //   bookingResponseByCarrier,
  //   SelectedAddons,
  //   Itinerary,
  //   searchInfo,
  //   Client,
  //   metaData,
  //   personalInfo,
  // });
  // taking pax info out of first booking response
  // since all the booking should have same pax infos
  const passengers = formatToArr(
    Object.values(bookingResponseByCarrier)[0]?.Passengers?.Passenger
  );

  // const SSRCharges = calculateSSRPrices(
  //   SelectedAddons || [],
  //   Itinerary.Journeys?.length,
  //   searchInfo?.adult + searchInfo?.child
  // );
  const allPrices = calculateFlightPricesToShow(
    Itinerary?.Journeys,
    searchInfo?.adult,
    searchInfo?.child,
    SelectedAddons,
    agentAddOnMarkup
  );
  const It = Itinerary ? Itinerary : initialState?.flight?.Itinerary;

  // console.log("It: ", It);
  // console.log("Itinerary: ", Itinerary);

  // let SupplierPNR =
  //   // bookingResponse?.RecordLocators?.["a:RecordLocator"]?.["a:RecordCode"];
  //   firstBookingResponse?.BookingID;
  // let AirlinePNR = firstBookingResponse?.RecordLocator;
  let totalPax = searchInfo?.adult + searchInfo?.child;
  let Duration = 0;
  let Baggage = 0;
  let NoOfStops = 0;
  let totalMarkupAddedOnFare = 0;
  let totalAgentMarkupAddedOnFare = 0;
  It?.Journeys?.forEach((e) => {
    const markup = e.meta.markupPrice;
    totalMarkupAddedOnFare +=
      markup.adult.baseMarkupAmount +
      markup.adult.taxMarkupAmount +
      markup.child.baseMarkupAmount +
      markup.child.taxMarkupAmount;
    totalAgentMarkupAddedOnFare +=
      markup.adult.agentBaseMarkupAmount +
      markup.adult.agentTaxMarkupAmount +
      markup.child.agentBaseMarkupAmount +
      markup.child.agentTaxMarkupAmount;
  });
  if (It !== undefined) {
    Duration = It?.Journeys?.reduce(
      (acc, journey) => acc + journey?.meta?.durationInMins,
      0
    );
    Baggage = 15 * totalPax;
    NoOfStops = It?.Journeys?.reduce(
      (acc: number, journey) => acc + +journey?.meta?.airports?.length - 2,
      0
    );
  }
  const {
    Charges,
    Base,
    NetPayable,
    ServiceCharges,
    Discounts,
    AgentAddOnMarkup,
  } = allPrices;

  const body: ISaveFlightData = {
    ClientId: Client?.id || 0,
    // BookingAmount: It !== undefined ? Number(It?.BookingSum?.TotalCost) : 0,
    CustomerId: userId,
    AffiliateID: affiliateId,
    PaymentStatusId: 1,
    TitleMasterId: 0,
    BookingAmount:
      Base?.TotalAdult +
      Base?.TotalChild +
      Charges?.Baggage +
      Charges?.Meals +
      Charges?.Seat,
    TaxAmount:
      Charges?.FareTax.TotalAdult +
      Charges?.FareTax.TotalChild +
      Charges?.TotalGST,
    NetpayableAmount: NetPayable,
    Duration: `${Math.floor(Duration / 60)}:${Math.round(Duration % 60)}hrs`,
    Baggage: `${Baggage}kg`,
    NoOfStops: `${NoOfStops}`,
    Remarks: "",
    // Journey Details
    lstPNRDetails: It?.Journeys?.map((journey, index) => {
      // First segment of journey;
      const carrierCode = journey?.meta?.carrierCode;
      let bookingResponseOfJourney = bookingResponseByCarrier[carrierCode];
      let firstSegment = formatToArr(
        formatToArr(bookingResponseOfJourney?.Journeys?.Journey)?.[index]
          ?.Segments?.Segment
      )[0];
      let firstLegOfSegment = formatToArr(firstSegment?.Legs?.Leg)[0];
      let firstLegInfo = firstLegOfSegment?.LegInfo;
      let FlightCabinClassId =
        metaData?.FlightCabinClass?.filter(
          (cabin) => cabin.FlightCabinClassName === firstSegment?.CabinOfService
        )?.[0]?.FlightCabinClassId || "17"; //17 is flightCabinClassId for of 'Y'
      // if no cabin found then default to 'Y'
      return {
        SupplierPNR: bookingResponseOfJourney?.BookingID,
        AirlinePNR: bookingResponseOfJourney?.RecordLocator,
        FlightAirlineID: "" + journey?.meta?.flightAirline?.AirlineId,
        DepartureRouteID: "" + searchInfo?.fromCity[index].RouteDestinationId,
        ArrivalRouteID: "" + searchInfo?.toCity[index]?.RouteDestinationId,
        FlightNo: journey?.meta?.flightCode,
        FromDate: `${journey?.meta?.departureTime}Z`,
        ToDate: `${journey?.meta?.arrivalTime}Z`,
        BookingSupplierId: "" + journey?.meta?.bookingSupplier?.SupplierId,
        TicketedSupplierId:
          "" + journey?.meta?.ticketedSupplier?.TicketedSupplierID,
        TicketStatusId: "1",
        Terminal: firstLegInfo?.DepartureTerminal,
        ArrivalTerminal: firstLegInfo?.ArrivalTerminal,
        FlightCabinClassId,
        FlightClassId: "" + searchInfo.class.FlightClassId,
        TicketPNRDetailId: index,
      };
    }),
    lstTravellers: passengers
      ?.map((passenger, passengerIndex) => {
        const name = passenger?.Names?.BookingName;
        const info: any = passenger?.PassengerTypeInfo;

        return It?.Journeys?.map((journey, index) => {
          const seatNumbers = (
            SelectedAddons.filter((e) => e.journeyIndex === index).map(
              (e) => e?.Seats?.[passengerIndex]?.[0]?.SeatDesignator
            ) || []
          ).join("");
          return {
            TravellerFirstName: name?.FirstName,
            TravellerLastName: name?.LastName,
            TravelerTitle: `${name?.Title}.`,
            TravellerDOB: info?.DOB,
            TicketNumber: passenger?.PassengerID,
            Gender: passenger?.PassengerInfo?.Gender === "Male" ? 1 : 2,
            TravellerTypeId: info?.PaxType === "ADT" ? "1" : "2",
            // TravellerTypeId: ""+metaData?.TravellerTypes?.filter(traveller=>traveller.TypeName===info?.PaxType)?.[0]?.TravellerTypeId
            Baggage: "15kg",
            SeatNumber: seatNumbers,
            Basefare:
              info?.PaxType === "ADT"
                ? Base?.PerAdultByJourney[index]
                : Base?.PerChildByJourney[index],
            // ? (ServiceCharges + Base?.Adult)/searchInfo?.adult
            // : (ServiceCharges + Base?.Child)/searchInfo?.child,
            TaxesAndCharges:
              info?.PaxType === "ADT"
                ? Charges?.FareTax.PerAdultByJourney[index] +
                Charges?.SSRChargesByPaxPerJourney[index][passengerIndex]
                : Charges?.FareTax.PerChildByJourney[index] +
                Charges?.SSRChargesByPaxPerJourney[index][passengerIndex],
            TotalAmount:
              info?.PaxType === "ADT"
                ? Base?.PerAdultByJourney[index] +
                Charges?.FareTax.PerAdultByJourney[index] +
                Charges?.SSRChargesByPaxPerJourney[index][passengerIndex]
                : Base?.PerChildByJourney[index] +
                Charges?.FareTax.PerChildByJourney[index] +
                Charges?.SSRChargesByPaxPerJourney[index][passengerIndex],
            TripType: searchInfo?.travelType?.SearchTypeId,
            TicketPNRDetailId: index,
            // TicketPNRDetailId: passenger?.PassengerNumber,
            Meal: metaData?.MealType[0],
            Age: "" + moment().diff(moment(info?.DOB), "years"),
            SupplierPNR:
              bookingResponseByCarrier[journey?.meta?.carrierCode]?.BookingID,
            AirlinePNR:
              bookingResponseByCarrier[journey?.meta?.carrierCode]
                ?.RecordLocator,
          };
        });
      })
      .flat(),
    lstCharges: metaData?.Charges?.map((charge) => ({
      TicketChargeCalculationTypeId: charge?.TicketChargeCalculationTypeId,
      TicketChargeTypeId: charge?.TicketChargeTypeId,
      Amount:
        charge?.ChargeName === "My Markup"
          ? totalMarkupAddedOnFare
          : charge?.ChargeName === "GST "
            ? Charges?.TotalGST
            // : charge?.ChargeName === "Convenience Charges"
            : charge?.ChargeName === "Service Charges"
              ? ServiceCharges
              : charge?.ChargeName === "Discount"
                ? Discounts.Child + Discounts.Coupon
                : charge?.ChargeName === "Affiliate Markup"
                  ? totalAgentMarkupAddedOnFare
                  : charge?.ChargeName === "Affiliate Add-on Markup"
                    ? AgentAddOnMarkup
                    : 0,
      ChargeName: charge?.ChargeName,
    })),
  };

  // Adding Selected SSR Infants into the lstTravellers array of body
  if (
    searchInfo?.infant > 0 &&
    SelectedAddons !== undefined &&
    SelectedAddons?.length > 0
  ) {
    personalInfo.passengers.infant.forEach((infant, index) => {
      // let infantSSR = SelectedAddons?.map(
      //   (seg) => seg.SSR[index].filter((ssr) => ssr.SSRCode === "INFT")[0]
      // );
      // let totalSSRChargesOfInfant =
      //   infantSSR !== undefined
      //     ? infantSSR?.reduce(
      //         (total, ssr) => total + (+ssr?.TotalPrice.Charge || 0),
      //         0
      //       )
      //     : 0;
      It?.Journeys.forEach((journey, journeyIndex) => {
        let infantSSRByJourney = SelectedAddons?.find(
          (addon) => addon.journeyIndex === journeyIndex
        )
          ?.SSR.flat()
          .filter((ssr) => ssr.SSRCode === "INFT");
        let infantCharge =
          infantSSRByJourney !== undefined
            ? infantSSRByJourney?.reduce(
              (total, ssr) => total + (+ssr?.TotalPrice.Charge || 0),
              0
            )
            : 0;
        let carrierCode = journey?.meta?.carrierCode;
        body.lstTravellers.push({
          TravellerFirstName: infant?.firstName,
          TravellerLastName: infant?.lastName,
          TravelerTitle: `${infant?.Title}`,
          TravellerDOB: infant?.dateOfBirth,
          TicketNumber: "0",
          Gender: infant?.gender === "Male" ? 1 : 2,
          TravellerTypeId: "3",
          // TravellerTypeId: ""+metaData?.TravellerTypes?.filter(traveller=>traveller.TypeName===info?.PaxType)?.[0]?.TravellerTypeId
          Baggage: "0",
          SeatNumber: "",
          Basefare: 0,
          // TaxesAndCharges: infantCharge,
          // TotalAmount: infantCharge,
          TaxesAndCharges: 0,
          TotalAmount: 0, //total charge amount is added in parent of the infant as SSR charges
          //are not added in the parent  and infant is an SSR
          TripType: searchInfo?.travelType?.SearchTypeId,
          TicketPNRDetailId: journeyIndex,
          Meal: metaData?.MealType[0],
          Age: "" + moment().diff(moment(infant?.dateOfBirth), "years"),
          SupplierPNR: bookingResponseByCarrier[carrierCode]?.BookingID,
          AirlinePNR: bookingResponseByCarrier[carrierCode]?.RecordLocator,
        });
      });
    });
  }
  return body;
};

export const filterSSRByType = (
  ssrList: ISSR[],
  type: "EB" | "MEAL",
  carrierCode: string
) => {
  // if (carrierCode === "SG") {
  if (type === "EB") {
    let baggage = SSRToBeSold.EB;
    return ssrList.filter((e) =>
      baggage.some((bag) => bag.SSRCode === e.SSRCode)
    );
  } else if (type === "MEAL") {
    let meals = SSRToBeSold.MEAL;
    return ssrList.filter((e) =>
      meals.some((meal) => meal.SSRCode === e.SSRCode)
    );
  } else {
    return ssrList;
  }
  // } else {
  //   return ssrList;
  // }
};

export const getFareInfo = (classType: string, carrierCode: "SG" | "G8" | "6E") => {
  // @ts-ignore
  const carrier = fareTypeInfo[carrierCode];

  if (carrier?.productClass.standard.includes(classType)) {
    return {
      genericType: "STANDARD" as "SAVER" | "STANDARD" | "PREMIUM",
      features: carrier.features.standard,
      type: carrier.type.standard,
      typeDescription: carrier.typeDescription.standard,
    };
  } else if (carrier?.productClass.premium.includes(classType)) {
    return {
      genericType: "PREMIUM" as "SAVER" | "STANDARD" | "PREMIUM",
      features: carrier.features.premium,
      type: carrier.type.premium,
      typeDescription: carrier.typeDescription.premium,
    };
  } else {
    //  carrier.productClass.saver.includes(classType)
    // NOTHING MATCHES THEN RETURN SAVER
    return {
      genericType: "SAVER" as "SAVER" | "STANDARD" | "PREMIUM",
      features: carrier?.features.saver,
      type: carrier?.type.saver,
      typeDescription: carrier?.typeDescription.saver,
    };
  }
};

export const getSellAndItineraryPayload = (
  journeyArr: ISelectedFaresForItinerary[]
) => {

  let journeys: {
    [x: string]: { JourneySellKey: string; FareSellKey: string }[];
  } = {};

  journeyArr.forEach((journey) => {
    journeys[journey.carrierCode] = journeys[journey?.carrierCode]
      ? [
        ...journeys[journey.carrierCode],
        {
          JourneySellKey: journey.JourneySellKey,
          FareSellKey: journey.FareSellKey,
        },
      ]
      : [
        {
          JourneySellKey: journey.JourneySellKey,
          FareSellKey: journey.FareSellKey,
        },
      ];
  });
  return journeys;

};

const getFilteredMarkups = (
  markup: IMarkUpResponse[],
  supplier: string,
  basePrice: number,
  taxPrice: number,
  numOfPassengers: number,
  numOfJourney: number,
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[],
  isDomestic?: boolean
) => {
  const result = {
    baseMarkupAmount: 0,
    serviceMarkupAmount: 0,
    GSTAmount: 0
  };

  let filteredMarkUp = markup?.filter(
    (mark) => {
      // console.log("---------------------------------");
      // console.log("markup suppliers: ", mark.SupplierNames);
      // console.log("does contain supplier: ", String(mark?.SupplierNames.toLowerCase().replace(/ /g,'')).split(",").includes(supplier.toLowerCase()));
      return String(mark?.SupplierNames.toLowerCase().replace(/ /g, '')).split(",").includes(supplier.toLowerCase())
        &&
        mark?.ApplyTypeId === 1 //for online type only
      // && isDomestic
      //   ? mark?.MarkupTypeId === 1
      //   : 2
    }
  );
  let listCharges =
    filteredMarkUp?.find(
      (mark) =>
        mark?.GrossPriceFrom < basePrice && mark?.GrossPriceTo > basePrice
    )?.lstCharges || [];
  const ApplyForId = filteredMarkUp?.[0]?.ApplyForId || 0;
  const ApplyOnId = filteredMarkUp?.[0]?.ApplyOnId || 0;

  // console.log("markup: ", markup);
  // console.log("supplier: ", supplier);
  // console.log("listCharges: ", listCharges);

  if (listCharges?.length > 0) {

    let totalMarkupAmount = 0;
    listCharges?.forEach((charges) => {
      //apply on Base
      // considering total price as base price for now
      // have to change this
      let amount = calculateChargeAmount(
        ApplyOnId === 2
          ? basePrice
          : ApplyOnId === 3
            ? taxPrice
            : ApplyOnId === 4
              ? basePrice + taxPrice
              : ApplyOnId === 1
                ? basePrice + taxPrice
                : 0,
        charges?.FeeValue,
        ApplyForId,
        charges?.MarkupFeeTypeId,
        numOfPassengers,
        numOfJourney
      );
      if (charges?.MarkupFeeId === 1) {
        // add marked up price on Base Charge
        result.baseMarkupAmount += amount;
      } else if (charges?.MarkupFeeId === 2) {
        //Service charge
        result.serviceMarkupAmount += amount;
      }

      // calculate GST on "chargeToBeAdded"
      if (!charges?.IsGSTAppplied) {

        // totalMarkupAmount += 
        const GSTRate = GSTRates?.[0]?.Value;
        // const markup = result.baseMarkupAmount + result.serviceMarkupAmount;
        const markup = amount;
        result.GSTAmount += markup * (GSTRate / 100);
        // console.log("--------------------------------------------------");
        // console.log("charges: ", charges);
        // console.log("amount", amount);
        // console.log("GST Rate: ", GSTRate);
        // console.log("total markup amount: ", markup);
        // console.log("GST Amount: ", markup * (GSTRate / 100));
        // console.log("--------------------------------------------------");

      };

    });
  }

  // console.log("result: ", result);-
  return result;
};

export const calculateIfNavitaireFlightSelected = (selectedFlights: TFlightResultList[]) => { // IFlightSearchData

  // filter out Navitaire based journeys to prepare for itinerary payload
  const filteredNavitaireJourneys = selectedFlights?.filter(
    journey => journey.supplier === "navitaire"
  );

  if (filteredNavitaireJourneys.length) return true;

  return false;

};

export const calculateIfAirIQFlightSelected = (selectedFlights: TFlightResultList[]) => { // IFlightSearchData

  // filter out Navitaire based journeys to prepare for itinerary payload
  const filteredAirIQJourneys = selectedFlights?.filter(
    journey => journey.supplier === "airiq" || journey.supplier === "1G"
  );

  if (filteredAirIQJourneys.length) return true;

  return false;

};

export const constructJourneyUniqueAirportArray = (segments: Array<{ origin: string; destination: string }>) => {
  if (segments.length === 0) return [];

  let airport = [segments[0].origin]; // Start with the origin of the first segment

  for (let segment of segments) {
    airport.push(segment.destination); // Add the destination of each segment
  };

  return airport;
};

export const constructFlightNumbersFor1GJourney = (carrierCode: string, flightCode: string | string[]) => {

  const flightNumber = carrierCode.split(" - ").map((each, index) => {
    if (each.length) return `${each} ${flightCode[index]}${index !== flightCode.length - 1 ? ", " : ""} `
  }).filter(e => e);

  return flightNumber;

};