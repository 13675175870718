import React from "react";
import { useSelector } from "react-redux";

import GroupedInput from "../../../../components/Common/Input/GroupedInput";

import { ITBORoom, State } from "../../../../redux/types";
import useHotel from "../../../../utils/hooks/Hotel/useHotel";

import "../../hotel.scss";

interface Props {
    room: number;
    passengerIndex: number;
}

export default function THotelPersonalDetailsForm({ room, passengerIndex }: Props) {

    const { passenger, TBO } = useSelector((state: State) => state.hotel);
    const { updateLeadPassenger } = useHotel();

    return (
        <React.Fragment>

            <div className="row py-2">
                <div className="col-md-6 col-sm-12">
                    <GroupedInput
                        label="First Name"
                        inputs={[
                            {
                                body: (
                                    <select
                                        id="title"
                                        className="form-control border-0 shadow-none"
                                        style={{ maxWidth: "30%" }}
                                        aria-label="Select for title"
                                        value={passenger[room][passengerIndex].Title}
                                        onChange={function (e: any) {
                                            updateLeadPassenger('Title', e.target.value, room, passengerIndex);
                                        }}
                                    >
                                        <option value="Mr">Mr</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Mrs">Mrs</option>
                                    </select>
                                ),
                            },
                            {
                                body: (
                                    <input
                                        id="firstName"
                                        className="form-control"
                                        placeholder="John"
                                        onChange={function (e: any) {
                                            updateLeadPassenger('FirstName', e.target.value, room, passengerIndex);
                                        }}
                                        value={passenger[room][passengerIndex].FirstName}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            id="lastName"
                            className="form-control"
                            placeholder="Doe"
                            onChange={function (e: any) {
                                updateLeadPassenger('LastName', e.target.value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].LastName}
                        />
                    </div>
                </div>
            </div>

            {/* Email & Phone no */}
            <div className="row py-2">
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            className="form-control"
                            placeholder="Ex: abc@gmail.com"
                            type="email"
                            onChange={function (e: any) {
                                updateLeadPassenger('Email', e.target.value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].Email}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <GroupedInput
                        label="Mobile Number"
                        inputs={[
                            {
                                body: (
                                    <select
                                        id="title"
                                        className="form-control border-0 shadow-none"
                                        style={{ maxWidth: "30%" }}
                                        aria-label="Select for title"
                                        value={passenger[room][passengerIndex].Title}
                                        onChange={function (e: any) {
                                            updateLeadPassenger('CountryCode', e.target.value, room, passengerIndex);
                                        }}
                                    >
                                        <option value="+91">+91 India</option>
                                        <option value="+01">+01 USA</option>
                                        <option value="_12">+12 Finland</option>
                                    </select>
                                ),
                            },
                            {
                                body: (
                                    <input
                                        id="contactNumber"
                                        type="tel"
                                        // minValue={0}
                                        maxLength={10}
                                        className="form-control"
                                        placeholder="9999 999 999"
                                        value={passenger[room][passengerIndex].Phoneno}
                                        onChange={function (e: any) {
                                            updateLeadPassenger('Phoneno', e.target.value, room, passengerIndex);
                                        }}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>

            <div className="row py-2">
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="age">Age</label>
                        <input
                            className={`form-control`}
                            id="age"
                            placeholder="Ex: 24"
                            type="text"
                            onChange={function (e: any) {
                                updateLeadPassenger('Age', e.target.value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].Age}
                            readOnly={passenger[room][passengerIndex].PaxType == "2" ? true : false}
                        />
                    </div>
                </div>
                {/* <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="International Traveler">International Traveler</label>
                        <select
                            id="International Traveler"
                            className="form-select form-select-lg"
                            aria-label="Default select example"
                            value={passenger[room][passengerIndex].InternationalTraveler}
                            onChange={function (e: any) {
                                updateLeadPassenger('InternationalTraveler', e.target.value, room, passengerIndex);
                            }}
                        >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div> */}
                {TBO.selectedRoom[0]?.IsPANMandatory && passenger[room][passengerIndex].PaxType === "1" ? <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="pan">PAN</label>
                                <input
                                    className={`form-control ${TBO.selectedRoom[0]?.IsPANMandatory && passenger[room][passengerIndex].PAN?.length !== 10 && 'border-danger'}`}
                                    id="pan"
                                    placeholder="Ex: AADFG1234L"
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PAN', e.target.value?.toUpperCase(), room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PAN?.toUpperCase()}
                                />
                            </div>
                        </div>
                    </div> : <></>}
            </div>

            {/* For PAN or Passport details */}
            {/* {passenger[room][passengerIndex].InternationalTraveler === "No" ? <React.Fragment>
                {TBO.selectedRoom[0]?.IsPANMandatory && passenger[room][passengerIndex].PaxType === "1" ? <div className="row py-2">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="pan">PAN</label>
                                <input
                                    className={`form-control ${TBO.selectedRoom[0]?.IsPANMandatory && passenger[room][passengerIndex].PAN?.length !== 10 && 'border-danger'}`}
                                    id="pan"
                                    placeholder="Ex: AADFG1234L"
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PAN', e.target.value?.toUpperCase(), room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PAN?.toUpperCase()}
                                />
                            </div>
                        </div>
                    </div>
                </div> : <></>}
            </React.Fragment>
                :
                <React.Fragment>
                    <div className="row py-2">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="passportNo">Passport Number</label>
                                <input
                                    id="passportNo"
                                    className={`form-control ${TBO.selectedRoom[0]?.IsPassportMandatory && passenger[room][passengerIndex]?.PassportNo === "" ? 'border-danger' : ''}`}
                                    placeholder="Ex: AADFG1234L"
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PassportNo', e.target.value, room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PassportNo}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="issueDate">Issue Date</label>
                                <input
                                    type="date"
                                    id="issueDate"
                                    className={`form-control ${TBO.selectedRoom[0]?.IsPassportMandatory && passenger[room][passengerIndex]?.PassportIssueDate === "" ? 'border-danger' : ''}`}
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PassportIssueDate', e.target.value, room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PassportIssueDate}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="expDate">Expiry Date</label>
                                <input
                                    type="date"
                                    id="expDate"
                                    className={`form-control ${TBO.selectedRoom[0]?.IsPassportMandatory && passenger[room][passengerIndex]?.PassportExpDate === "" ? 'border-danger' : ''}`}
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PassportExpDate', e.target.value, room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PassportExpDate}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>} */}

        </React.Fragment>
    )
}

