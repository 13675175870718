import React from 'react';
import { GoVerified } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/types';

interface IconTypeProps {
    width: number;
    height: number;
    color: string;
  }
type IconType = (props: IconTypeProps) => JSX.Element;

interface IProps {
    value: string;
    setEditProfileData: any;
    editProfile: boolean;
    editProfileData: any;
    nameOfField: string;
    label: string;
    showIcon?: boolean;
}


export default function DetailsInputBox({ value, editProfile, editProfileData,setEditProfileData, nameOfField, label,showIcon  }: IProps) {  
  const data = useSelector((state: State) => state.user.profile);
  return (
    <div className="details-input-box border-bottom mt-3 ">
    <div className="label">{label}</div>
    {!editProfile ? (
      <div className="field-text d-flex ">
        {value}
        { showIcon && nameOfField === "Mobile" && <span className="ms-3 text-success verified">
                    <GoVerified height={"6px"} width={"7.65px"}/> Verified
                  </span>
                  
    }
    { nameOfField === "Email" && <div className="ms-3 px-1 rounded text-primary verify d-flex justify-content-center align-items-center pointer">
                    Verify Email
                  </div>
                  
    }
    
      </div>
    ) : (
      <input
        required
        className="form-control shadow-none border-0 ps-0"
        value={value}
        onChange={(e) => {
            setEditProfileData({
              ...editProfileData,
              [nameOfField]: e.target.value,
            });
          }}
      />
    )}
  </div>
  )
}
