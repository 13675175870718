import React, { Fragment, useEffect, useState } from "react";

import "../flight.scss";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/types";
import useFlight from "../../../utils/hooks/Flight/useFlight";
import TopFilters from "./TopFilters";
import MultiCityInputs from "./MultiCityInputs";
import { getDistance } from "../../../utils/utilityFunctions/utilityFunction";
import Loading from "@kiwicom/orbit-components/lib/Loading";
import Button from "../../../components/Common/Button/Button";
import moment from "moment";
import DateRangePicker from "../../../components/FlightModal/DateRangePicker/DateRangePicker";
import Input2 from "../../../components/Common/Input/Input2";
import DatePicker from "../../../components/FlightModal/DatePicker";
import { flightSlice, resetPassengers } from "../../../redux/reducers/flight";
interface Props {
  isPreSearch: boolean;
  isSearching: boolean;
  onSearch: () => void;
}

export default function SearchFlightForm({
  onSearch,
  isPreSearch,
  isSearching,
}: Props) {

  const dispatch = useDispatch();

  const { location } = useSelector((state: State) => state.user);
  const { searchInfo, metaData, personalInfo } = useSelector((state: State) => state.flight);

  const { updateSearch, resetFlightData } = useFlight();

  const [isFormFilled, setIsFormFilled] = useState<boolean>(false);


  useEffect(() => {
    dispatch(flightSlice.actions.resetSelectedFlights());
  }, []);

  useEffect(() => {
    setIsFormFilled(
      searchInfo?.fromCity[0]?.RouteDestinationCode?.length === 3 &&
      searchInfo?.toCity[0]?.RouteDestinationCode?.length === 3 &&
      searchInfo?.departureDate[0]?.length > 0 &&
      Number(searchInfo?.adult) > 0 &&
      (searchInfo?.travelType?.SearchTypeId === 2
        ? searchInfo?.departureDate[1]?.length > 0
        : true) &&
      (searchInfo?.travelType?.SearchTypeId === 3
        ? searchInfo?.departureDate?.length === searchInfo?.toCity?.length
        : true)
    );
  }, [searchInfo]);



  return (
    <section>
      {isPreSearch ? (
        <Fragment>
          {/* <SectionTitle
            title="Search Flights"
            subTitle="Find the cheapest and best flights"
          />
          <div className="spacer--sm"></div> */}
        </Fragment>
      ) : (
        <></>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSearch();
        }}
      >
        {/* Filters and selections for search */}
        <TopFilters isPreSearch={isPreSearch} />

        {/* Input fields */}
        <div className="spacer--sm"></div>
        {searchInfo?.travelType?.SearchTypeId === 3 ? (
          <MultiCityInputs isPreSearch={isPreSearch} />
        ) : (
          <div className="search-flight-container">
            <Input2
              label="From"
              placeholder="Select city"
              value={searchInfo?.fromCity[0]?.City || ""}
              onChange={(value) => {
                updateSearch(
                  "fromCity",
                  metaData.FlightOrigins?.filter(
                    (e) => e.RouteDestinationCode === value
                  )[0]
                );
                searchInfo.travelType?.SearchTypeId === 2 &&
                  updateSearch(
                    "toCity",
                    metaData.FlightDestinations?.filter(
                      (e) => e.RouteDestinationCode === value
                    )[0],
                    1
                  );
              }}
              suggestionArray={metaData?.FlightOrigins?.filter(
                (airport) =>
                  airport.RouteDestinationCode !==
                  searchInfo.toCity[0]?.RouteDestinationCode
              )?.map((air) => ({
                title: air?.City,
                airportCode: air.RouteDestinationCode,
                subtitle: `${air.RouteDestinationName.slice(0, 40)}${air?.RouteDestinationName.length > 40 ? "..." : ""}`,
                value: air.RouteDestinationCode,
                icon: air.RouteDestinationCode,
                label: air.ISOCountry
                // getDistance(
                //   {
                //     lat: Number(air.Coordinate2),
                //     lon: Number(air.Coordinate1),
                //   },
                //   { lat: location?.latitude, lon: location?.longitude }
                // ),
              }))}
            />
            <Input2
              label="To"
              placeholder="Select city"
              value={searchInfo?.toCity[0]?.City || ""}
              onChange={(value) => {
                updateSearch(
                  "toCity",
                  metaData.FlightDestinations?.filter(
                    (e) => e.RouteDestinationCode === value
                  )[0]
                );
                searchInfo.travelType?.SearchTypeId === 2 &&
                  updateSearch(
                    "fromCity",
                    metaData.FlightOrigins?.filter(
                      (e) => e.RouteDestinationCode === value
                    )[0],
                    1
                  );
              }}
              suggestionArray={metaData?.FlightDestinations?.filter(
                (airport) =>
                  airport.RouteDestinationCode !==
                  searchInfo.fromCity[0]?.RouteDestinationCode
              )?.map((air) => ({
                title: air?.City,
                airportCode: air.RouteDestinationCode,
                subtitle: `${air.RouteDestinationName.slice(0, 40)}${air.RouteDestinationName.length > 40 ? "..." : ""}`,
                value: air.RouteDestinationCode,
                icon: air.RouteDestinationCode,
                label: air.ISOCountry
                // getDistance(
                //   {
                //     lat: Number(air.Coordinate2),
                //     lon: Number(air.Coordinate1),
                //   },
                //   { lat: location?.latitude, lon: location?.longitude }
                // ),
              }))}
            />
            {/* <DepartureReturnDatePicker
              departure={{
                value: searchInfo?.departureDate[0],
                label: "Departure",
                onChange: (value) => {
                  updateSearch("departureDate", value);
                  // departureDate[1] is return date in return flights
                  if (
                    searchInfo?.departureDate[1]?.length > 0 &&
                    moment(searchInfo?.departureDate[1]).isBefore(moment(value))
                  ) {
                    // if departure date is selected after the return date is selected
                    // value of departure date is more than return date then set the return date to departure date as well

                    updateSearch("departureDate", value, 1);
                  }
                },
              }}
              returnDate={{
                value: searchInfo?.departureDate[1],
                label: "Return",
                onChange: (value) => updateSearch("departureDate", value, 1),
                isDisabled: searchInfo?.travelType?.SearchTypeId !== 2,
                minDate: searchInfo?.departureDate[0],
              }}
            /> */}
            {searchInfo?.travelType?.SearchTypeId === 2 ? (
              <DateRangePicker
                departure={{
                  value: searchInfo?.departureDate[0],
                  label: "Departure",
                  onChange: (value) => {
                    updateSearch("departureDate", value);
                    // departureDate[1] is return date in return flights
                    if (
                      searchInfo?.departureDate[1]?.length > 0 &&
                      moment(searchInfo?.departureDate[1]).isBefore(
                        moment(value)
                      )
                    ) {
                      // if departure date is selected after the return date is selected
                      // value of departure date is more than return date then set the return date to departure date as well

                      updateSearch("departureDate", value, 1);
                    }
                  },
                }}
                returnDate={{
                  value: searchInfo?.departureDate[1],
                  label: "Return",
                  onChange: (value) => updateSearch("departureDate", value, 1),
                  isDisabled: searchInfo?.travelType?.SearchTypeId !== 2,
                  minDate: searchInfo?.departureDate[0],
                }}
              />
            ) : (
              <DatePicker
                onChange={(value) => {
                  updateSearch("departureDate", value);
                }}
                minDate={moment().format()}
                value={searchInfo?.departureDate[0]}
                label={"Departure"}
              />
            )}
            {/* <DepartureReturn /> */}
            {/* </div>
            {/* <DepartureReturnDatePicker
                departure={{
                  value: searchInfo?.departureDate[0],
                  label: "Departure",
                  onChange: (value) => updateSearch("departureDate", value),
                }}
                returnDate={{
                  value: searchInfo?.departureDate[1],
                  label: "Return",
                  onChange: (value) => updateSearch("departureDate", value, 1),
                  isDisabled: searchInfo?.travelType !== "return",
                  minDate: searchInfo?.departureDate[0],
                }}
                label="Return"
                value={searchInfo?.departureDate[1]}
              /> */}
            {/* <DatePicker
                isDisabled={searchInfo?.travelType !== "return"}
                onChange={(value) => {
                  updateSearch("departureDate", value, 1);
                }}
                value={searchInfo?.departureDate[1]}
                label={"Return"}
                minDate={searchInfo?.departureDate[0]}
              /> */}

            {!isPreSearch ? (
              <Button
                className="search-btn"
                type="submit"
                disabled={!isFormFilled || isSearching}
                label={isSearching ? <Loading type="inlineLoader" /> : "Search"}
                onClick={() => { }}
                color="primary"
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </form>
    </section>
  );
}
