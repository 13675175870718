import "../seatSelection.scss";
import Seat from "../Components/Seat";
import {
  ISeat,
  ISelectedSeat,
} from "../../../../../../utils/types/flightTypes";
import { FC } from "react";
import {
  filterSeatsByRow,
  formatSGSeatForProps,
} from "../../../../../../utils/utilityFunctions/flight/SGUtilityFunctions";

type IAirCraft = {
  seats: ISeat[];
  onSeatSelect: (seat: ISeat) => void;
  selectedPax: number;
  selectedSeats: ISelectedSeat[];
  totalRows: number;
  maxSeatRows: number[];
  airCraftName: string;
  rowsNotAllowed: number[];
  seatsNotAllowed: string[];
};
const SeatGroup: FC<{
  seats: { seatsOnLeft: ISeat[]; seatsOnRight: ISeat[] };
  rowNumber: number;
  maxSeatRows: number[];
  onSelect: (seat: ISeat) => void;
  selectedPax: number;
  airCraftName: string;
  selectedSeats: ISelectedSeat[];
  rowsNotAllowed: number[];
  seatsNotAllowed: string[];
}> = ({
  seats,
  rowNumber,
  maxSeatRows,
  onSelect,
  selectedPax,
  airCraftName,
  selectedSeats,
  rowsNotAllowed,
  seatsNotAllowed,
}) => {
  const getFormattedProps = (seat: ISeat) => {
    return formatSGSeatForProps(
      seat,
      maxSeatRows,
      rowNumber,
      selectedPax,
      selectedSeats,
      airCraftName,
      rowsNotAllowed
    );
  };
  return (
    <div
      className={`seat-selection ${
        airCraftName === "B-737-800 (Y189)" && rowNumber === 4
          ? "justify-content-start"
          : ""
      }`}
    >
      <div className="seat-selection-group">
        {seats.seatsOnRight.map((seat, index) => (
          <Seat
          key={index + "seat on right"}
          seatsNotAllowed={seatsNotAllowed}
            seatData={getFormattedProps(seat)}
            onSelect={() => {
              onSelect(seat);
            }}
          />
        ))}
      </div>
      <div className="seat-selection-group">
        {seats.seatsOnLeft.map((seat, index) => (
          <Seat
          key={index + "seat on left"}
          seatsNotAllowed={seatsNotAllowed}
            seatData={getFormattedProps(seat)}
            onSelect={() => {
              onSelect(seat);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const Aircraft: FC<IAirCraft> = ({
  airCraftName,
  seats,
  onSeatSelect,
  totalRows,
  maxSeatRows,
  selectedPax,
  selectedSeats,
  rowsNotAllowed,
  seatsNotAllowed,
}) => {
  return (
    <div className="plane">
      <div className="plane-cockpit"></div>
      <div className="plane-body">
        {[...Array(totalRows)].fill("").map((e, i) => {
          return (
            <SeatGroup
              key={i + "seats"}
              seats={filterSeatsByRow(seats, i + 1, airCraftName)}
              rowNumber={i + 1}
              maxSeatRows={maxSeatRows}
              onSelect={(selectedSeat) => {
                onSeatSelect(selectedSeat);
              }}
              selectedPax={selectedPax}
              airCraftName={airCraftName}
              selectedSeats={selectedSeats}
              rowsNotAllowed={rowsNotAllowed}
              seatsNotAllowed={seatsNotAllowed}
            />
          );
        })}
      </div>
      <div className="door door-left  bg-danger"></div>
      <div className="door door-right  bg-danger"></div>
    </div>
  );
};

export default Aircraft;
