import { ChevronRight } from '@kiwicom/orbit-components/lib/icons';
import moment from 'moment';
import React, { useEffect } from 'react';

import Button from '../../../../../components/Common/Button/Button';
import CardBase from '../../../../../components/Common/CardBase/CardBase';
import Image from '../../../../../components/Common/Image/Image';

import { ISearchHotelInfo, State, IHotelDetails, ITBOHotelDetails } from '../../../../../redux/types';
import { HOTEL_BEDS_FILE_BASE_URL } from '../../../../../utils/constants';
import { priceFormatter } from '../../../../../utils/utilityFunctions/utilityFunction';

interface Props {
    isLoggedIn: boolean;
    isBookingInProgress: boolean;
    isBlockRoomInProgress: boolean;
    blockRoom: () => void;
    toggleLoginModal: () => void;
    searchHotel: ISearchHotelInfo;
    searchedHotel: ITBOHotelDetails; // IHotelDetails;
    guestCount: {
        rooms: number;
        adults: number;
        children: number;
    };
    billingInfo: {
        markedUpBasePrice: number;
        Tax: number;
        GST: number;
        otherMarkUpCharges: number;
        myCharges: number;
    };
};

export default function TPricingSummary({
    isLoggedIn,
    isBookingInProgress,
    isBlockRoomInProgress,
    blockRoom,
    toggleLoginModal,
    searchedHotel,
    searchHotel,
    guestCount,
    billingInfo
}: Props) {
    return (
        <React.Fragment>
            {/* RIGHT PANEL */}
            <div className="pb-4 sticky-container">
                <CardBase>
                    <div
                        className="mb-3"
                        style={{
                            height: 160,
                            overflow: "hidden",
                            borderRadius: 4,
                        }}
                    >
                        <Image
                            src={searchedHotel?.HotelPicture}
                            className={"h-100"}
                            style={{ objectFit: "cover" }}
                        />
                    </div>

                    <div>
                        <h4 className="mb-1">{searchedHotel.HotelName}</h4>
                        <p className="text-muted">
                            {moment(searchHotel.checkOutDate).diff(searchHotel.checkInDate, "days") > 1
                                ?
                                moment(searchHotel.checkOutDate).diff(searchHotel.checkInDate, "days") + " nights stay"
                                :
                                moment(searchHotel.checkOutDate).diff(searchHotel.checkInDate, "days") + " night stay"}
                        </p>
                        <div className="">
                            <ul className="list list--hotel-summary">
                                <div className="list-item">
                                    <b>
                                        {guestCount.rooms} Rooms, {guestCount.adults}{" "}
                                        Adult(s){" "}
                                        {guestCount.children
                                            ? ", " + guestCount.children +
                                            (guestCount.children > 1
                                                ? " Children"
                                                : " Child")
                                            : ""}
                                    </b>
                                </div>
                                <div className="list-item">
                                    <b>Check-In</b> -{" "}
                                    {moment(searchHotel.checkInDate).format(
                                        "MMM DD, YYYY"
                                    )}
                                </div>
                                <div className="list-item">
                                    <b>Check-Out</b> -{" "}
                                    {moment(searchHotel.checkOutDate).format(
                                        "MMM DD, YYYY"
                                    )}
                                </div>
                            </ul>
                        </div>
                    </div>

                    <div className="spacer spacer--md"></div>
                    {/* <h4 className="">Price</h4> */}
                    <div className="d-flex justify-content-between mb-2">
                        <div className="fw-bold">
                            {searchHotel?.noOfRooms} room,{" "}
                            {moment(searchHotel.checkOutDate).diff(
                                searchHotel.checkInDate,
                                "days"
                            )}{" "}
                            nights
                        </div>
                        <div className="fw-bold pb-0">
                            {priceFormatter(
                                billingInfo?.markedUpBasePrice,
                                "INR"
                            )}
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                        <div>Taxes & fees</div>
                        <div>{priceFormatter(
                            billingInfo?.Tax,
                            "INR"
                        )}</div>
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                        <div>GST</div>
                        <div>{priceFormatter(
                            billingInfo?.GST,
                            "INR"
                        )}</div>
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                        <div>Other Charge</div>
                        <div>{priceFormatter(
                            billingInfo?.otherMarkUpCharges + billingInfo?.myCharges,
                            "INR"
                        )}</div>
                    </div>

                    <div className="d-flex justify-content-between pt-4 border-top">
                        <h3 className="fw-bold">Total</h3>
                        <h3 className="fw-bold">
                            {priceFormatter(
                                billingInfo?.markedUpBasePrice +
                                billingInfo?.Tax +
                                billingInfo?.GST +
                                billingInfo?.otherMarkUpCharges +
                                billingInfo?.myCharges,
                                "INR"
                            )}
                        </h3>
                    </div>
                    <small className="text-muted py-2">
                        Rates are in Indian rupees
                    </small>

                    <div className="mt-4">
                        {
                            !isLoggedIn
                                ?
                                <React.Fragment>
                                    <span className="text-danger">
                                        * Please login to continue booking
                                    </span>
                                    <Button
                                        color={"primary"}
                                        size={"sm"}
                                        className={"w-100"}
                                        label={
                                            <React.Fragment>
                                                Sign In
                                                <ChevronRight />
                                            </React.Fragment>
                                        }
                                        onClick={() => toggleLoginModal()}
                                    />
                                </React.Fragment>
                                :
                                !isBookingInProgress && !isBlockRoomInProgress ? (
                                    <Button
                                        color={"primary"}
                                        size={"sm"}
                                        className={"w-100"}
                                        label={
                                            <React.Fragment>
                                                Book Hotel
                                                <ChevronRight />
                                            </React.Fragment>
                                        }
                                        disabled={!isLoggedIn}
                                        onClick={() => blockRoom()}
                                    />
                                ) : (
                                    <Button
                                        color={"primary"}
                                        className={"w-100"}
                                        disabled={true}
                                        size={"sm"}
                                        label={""}
                                        onClick={() => { }}
                                        isLoading={true}
                                    />
                                )}
                    </div>
                </CardBase>
            </div>

            {/* Coupon section */}
            {/* <Coupons /> */}
        </React.Fragment>
    )
}
