import React from "react";

interface Props {}

const Bus = (props: Props) => {
  return (
    <div className="container">
      <h1>Buses</h1>
    </div>
  );
};

export default Bus;
