import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { State } from "../../../redux/types";
import moment from "moment/moment";
import useFlightSearch from "../../../utils/hooks/Flight/useFlightSearch";
import useFlightBooking from "../../../utils/hooks/Flight/useFlightBooking";
import Button from "../../../components/Common/Button/Button";
import { priceFormatter } from "../../../utils/utilityFunctions/utilityFunction";
import BsModal from "../../../components/Modal/BSModal";
import FareCard from "../FlightBooking/TripSummary/Components/FareCard";
import { showToast, toggle } from "../../../redux/reducers/commonState";
import { BsArrowRight } from "react-icons/bs";
import { IFlightSearchData, ISelectedFaresForItinerary, TFlightResultList } from "../../../utils/types/flightTypes";
import ShareFlightInfoModal from "./ShareFlightInfoModal";
import { constructFlightNumbersFor1GJourney } from "../../../utils/utilityFunctions/flight/flightUtilityFunctions";

interface Props {
  selectedFlights: TFlightResultList[];
}
interface ISingleFlightCard {
  isReturn?: boolean;
  data: TFlightResultList;
  noofJourneys: number;
}

const SingleFlightCard = ({
  isReturn,
  data,
  noofJourneys,
}: ISingleFlightCard) => {
  return (
    <div className="card card--flight-select bg-dark" style={{}}>
      <div className="card-body">
        <div className="airline-logo">
          <img
            src={
              require(`../../../assets/${data?.carrierCode === "SG"
                ?
                "spicejet"
                :
                data?.carrierCode === "6E"
                  ?
                  "indigo"
                  :
                  data?.carrierCode === "QP"
                    ?
                    "akasaair"
                    :
                    "airway-placeholder"
                }.png`)?.default
            }
            className="img rounded ms-1"
            height={30}
            width={30}
            alt="airline"
          />
          <p className="my-0 fs-9">
            {
              data.carrierCode === "SG"
                ?
                "Spicejet"
                :
                data.carrierCode === "6E"
                  ?
                  "Indigo"
                  :
                  data.carrierCode === "QP"
                    ?
                    "Akasaair"
                    :
                    constructFlightNumbersFor1GJourney(data.carrierCode, data.flightCode)
            }
          </p>
        </div>
        <div className="time w-100">
          <div className="d-flex align-items-center justify-content-between fw-bold">
            <div>{moment(data?.departureTime).format("HH:mm")}</div>{" "}
            <div className="me-2 pe-4">
              {moment(data?.arrivalTime).format("HH:mm")}
            </div>
          </div>
          <div className="small d-flex align-items-center">
            {data?.airports?.map((seg: any, index: number) => (
              <span key={index}>
                {seg}{" "}
                {index < data?.airports.length - 1 ? (
                  <BsArrowRight className="mb-1" />
                ) : (
                  <></>
                )}
              </span>
            ))}
          </div>
        </div>
        <div className="duration text-center">
          <div className="fw-bold">{data?.duration}</div>
          <div className="small">
            {data?.airports && data?.airports?.length - 2 > 0
              ? data?.airports?.length - 2
              : "Non"}{" "}
            Stop
          </div>
        </div>
        <div className="text-center my-auto fs-5 fw-semibold">
          {priceFormatter(
            data?.supplier === "navitaire"
              ?
              data?.FareToShow?.markedUpPrice?.markedupTotalPrice
              :
              data?.supplier === "airiq"
                ?
                data?.fare_data.markedUpPrice.markedupTotalPrice
                :
                data.supplier === "1G"
                  ?
                  +data?.["1GSegmentData"][0].fareInfo.price // +data?.["1GSegmentData"].reduce((total, item) => total += item.fareInfo.markedUpPrice.markedupTotalPrice, 0)
                  :
                  0
          )}
        </div>
      </div>
    </div>
  );
};

const SelectedFlightCard2 = ({ selectedFlights }: Props) => {


  // const dispatch = useDispatch();

  const { searchInfo } = useSelector((state: State) => state.flight);

  const { sellTicket, isSelling } = useFlightBooking();
  const { getItinerary, itineraryLoading } = useFlightSearch();

  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  // const [isAirIQFlightSelected, setIsAirIQFlightSelected] = useState<boolean>(false);


  // useEffect(() => {

  // console.log("selectedFlights: ", selectedFlights);

  //   if (selectedFlights) {
  //     const isAirIQSelected = selectedFlights.filter((flights) => flights?.supplier === "airiq");
  //     if (isAirIQSelected.length > 0) {
  //       setIsAirIQFlightSelected(true);
  //       dispatch(
  //         showToast({
  //           type: "error",
  //           title: "Feature Not Available!",
  //           subTitle: "Work on booking flow for AirIQ is in progress.",
  //         })
  //       );
  //     } else {
  //       setIsAirIQFlightSelected(false);
  //     };
  //   };

  // }, [selectedFlights]);


  const selectedFares: ISelectedFaresForItinerary[] = useMemo(
    () =>
      selectedFlights.map((flights) => ({
        FareSellKey: flights?.FareToShow.FareSellKey,
        JourneySellKey: flights?.JourneySellKey,
        carrierCode: flights?.carrierCode,
        supplier: flights?.supplier,
      })),
    [selectedFlights]
  );
  // console.log("selectedFares: ", selectedFares);



  return (
    <React.Fragment>
      <motion.div
        className={`card card--selected-flight-summary_2`}
        initial={{ opacity: 0, y: -8 }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          // type: "spring",
          // stiffness: 250,
          // damping: 20,
          duration: 0.1,
        }}
      >
        <div
          className={`card-body ${selectedFlights.length > 2 ? "multi-city-flight" : ""
            }`}
        >
          {/* <div className="selected-flights"> */}
          {selectedFlights?.map((flight: TFlightResultList, index) =>
            flight !== null ? (
              <div className="departure" key={index}>
                <SingleFlightCard
                  data={flight}
                  noofJourneys={selectedFlights.length}
                />
              </div>
            ) : (
              // empty placeholder
              <div className="no-selected" key={index}>
                Please select a flight from list above.
              </div>
            )
          )}
          {/* </div> */}

          <div className="total bg-dark">
            <div className="text-center">
              <h1 className="mb-0">
                {priceFormatter(
                  selectedFlights?.reduce(
                    (acc: number, flights) =>
                      acc + Number(
                        flights?.supplier === "navitaire"
                          ?
                          flights?.FareToShow?.markedUpPrice.markedupTotalPrice
                          :
                          flights?.supplier === "airiq"
                            ?
                            flights?.fare_data?.markedUpPrice.markedupTotalPrice
                            :
                            flights?.supplier === "1G"
                              ?
                              +flights?.["1GSegmentData"][0].fareInfo.price // +flights?.["1GSegmentData"].reduce((total, item) => total += item.fareInfo.markedUpPrice.markedupTotalPrice, 0)
                              :
                              0
                      ),
                    0
                  )
                )}
              </h1>
              <span className="small opacity-75">Total (incl. taxes)</span>
            </div>
            <div className="d-flex flex-column">
              <Button
                size="xs"
                color="light"
                className="text-primary"
                isLoading={isSelling || itineraryLoading}
                onClick={async () => {
                  // const isSold = await sellTicket(selectedFares);
                  // if (isSold) {
                  //   getItinerary(selectedFares);
                  // };
                  const isSold = await getItinerary(selectedFares);
                  if (isSold) {
                    sellTicket(selectedFares);
                  };
                }}
                disabled={
                  selectedFlights?.filter((flight) => flight !== null).length !== searchInfo?.toCity.length
                  ||
                  itineraryLoading
                  ||
                  isSelling
                  // ||
                  // isAirIQFlightSelected // for AirIQ selected flights
                }
                label={
                  itineraryLoading || isSelling ? "Please Wait..." : "Book Now"
                }
              />
              <Button
                label={"Share"}
                color="link"
                size="xs"
                className="text-white"
                onClick={() => {
                  setShowShareModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </motion.div>

      <ShareFlightInfoModal
        show={showShareModal}
        onClose={() => setShowShareModal(false)}
        selectedFlights={selectedFlights}
      />

      {/* Modal for price selection */}
      {/* {showFareSelectionModal && (
        <BsModal
          title={`Upgrade your travel experience`}
          subTitle="select fare types below for extra facilities."
          size="xl"
          body={
            <div style={{ maxHeight: "63vh", overflowY: "auto" }}>
              {selectedFlights?.map((flight, index) => {
                let cityNames = flight?.airports.map(
                  (a) =>
                    metaData?.FlightOrigins.find(
                      (airport) => airport.RouteDestinationCode === a
                    )?.City || a
                );
                return (
                  <div
                    className={`d-flex ${
                      index !== selectedFlights?.length - 1
                        ? "border-bottom border-2 mb-2"
                        : ""
                    }`}
                    key={index}
                  >
                    <div className="w-25 fs-5">
                      {cityNames.map((f, idx) =>
                        idx !== cityNames?.length - 1 ? (
                          <span className="fw-bold me-2" key={idx}>
                            <span className="me-2">{f}</span>
                            <BsArrowRight className="mb-1" />
                          </span>
                        ) : (
                          <span className="fw-bold" key={idx}>
                            {f}
                          </span>
                        )
                      )}
                      <p className="small text-muted">
                        {moment(flight.departureTime).format("DD MMM,YYYY")}
                      </p>
                      <p className="fw-bold fs-6">
                        {flight.SegmentData.map(
                          (seg, idx) =>
                            `${seg?.carrierCode}-${seg?.flightNumber}${
                              idx < flight?.SegmentData.length - 1 ? ", " : ""
                            }`
                        )}
                      </p>
                    </div>
                    <div className="d-flex flex-column flex-lg-row gap-2">
                      {flight.FareData?.map((fare, idx) => {
                        return (
                          <FareCard
                            key={idx}
                            onFareSelect={() => {
                              let temp = [...selectedFares];
                              temp[index] = {
                                ...temp[index],
                                FareSellKey: fare.FareSellKey,
                                JourneySellKey: flight.JourneySellKey,
                              };
                              setSelectedFares(temp);
                            }}
                            isSelected={
                              selectedFares?.findIndex(
                                (f) =>
                                  f?.FareSellKey + f.JourneySellKey ===
                                  fare?.FareSellKey + flight.JourneySellKey
                              ) !== -1
                            }
                            title={fare?.FareInfo?.type}
                            subTitle={fare?.FareInfo?.typeDescription}
                            price={"" + fare?.markedUpPrice?.markedupTotalPrice}
                            labels={fare?.FareInfo?.features}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          }
          onClose={() => {
            setShowFareSelectionModal(false);
          }}
          customFooter
          footer={
            <div className=" bg-dark rounded-bottom p-2 d-flex">
              <Button
                label={itineraryLoading ? "Please Wait..." : "Continue"}
                type="button"
                className="ms-auto text-primary"
                color="light"
                isLoading={isSelling || itineraryLoading}
                disabled={isSelling || itineraryLoading}
                onClick={async () => {
                  const isSold = await sellTicket(selectedFares);
                  if (isSold) {
                    getItinerary(selectedFares);
                  }
                }}
              />
            </div>
          }
        />
      )} */}
    </React.Fragment>
  );
};

export default SelectedFlightCard2;
