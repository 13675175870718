import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';
import Card, { CardSection } from '@kiwicom/orbit-components/lib/Card';

import { Airplane } from '@kiwicom/orbit-components/lib/icons';

interface TypeLayout {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
};

export default function Layout({ title, subtitle, children }: TypeLayout) {
    return (
        <div style={{ marginBottom: 20 }}>
            <Card>
                <CardSection>
                    <Text
                        size="large"
                        weight="bold">{title}</Text>
                    {/* <Text type="secondary">{subtitle}</Text> */}
                    <div style={{ marginTop: 10 }}>{children}</div>
                </CardSection>
            </Card>
        </div>
    )
}
