import React from "react";
import { MdClear, MdEventSeat } from "react-icons/md";

type Props = {};

const SeatLegends = (props: Props) => {
  return (
    <div className="d-flex gap-4 pb-4 px-4 ms-auto">
      <div className="">
        <div className="d-flex align-items-center gap-2">
          <MdClear
            size={18}
            className=" bg-black bg-opacity-10 d-flex m-0 p-0 text-white rounded"
          />
          Unavailable Seats
        </div>
        {/* <div className="d-flex align-items-center gap-2">
          <MdEventSeat
            size={18}
            className=" bg-primary d-flex m-0 p-0 text-white rounded"
          />
          Window/Aisle seats
        </div> */}
      </div>
      <div className="">
        <div className="d-flex align-items-center gap-2">
          <MdEventSeat
            size={18}
            className=" bg-success bg-opacity-50 d-flex m-0 p-0 text-white rounded"
          />
          Max seats
        </div>
        {/* <div className="d-flex align-items-center gap-2">
          <MdEventSeat
            size={18}
            className=" bg-black bg-opacity-50 d-flex m-0 p-0 text-white rounded"
          />
          Middle Seats
        </div> */}
      </div>
    </div>
  );
};

export default SeatLegends;
