import moment from "moment";
import React, { FC } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";

interface Props {
  cities: {
    to: string;
    from: string;
    date: string;
  }[];
  onChange: (index: number) => void;
  selectedCityIndex: number;
}

const MultiCityTab: FC<Props> = ({ cities, onChange, selectedCityIndex }) => {
  return (
    <div
      className="w-100  my-2 rounded d-flex justify-content-between gap-1"
      style={{ height: "6em" }}
    >
      {cities.map((city, index) => (
        <div
          className={`card card--selectable ${
            selectedCityIndex === index ? "bg-primary text-white" : ""
          } w-100 px-2 h-100 d-flex flex-column justify-content-center align-items-center`}
          key={index}
          onClick={() => onChange(index)}
        >
          <div className="d-flex  align-items-center">
            <span className="fs-4"> {city.from}</span>
            <span className="mx-2">
              <BsArrowRight className="mb-1" />
            </span>{" "}
            <span className="fs-4">{city.to}</span>
          </div>
          <p>{moment(city.date).format("ddd, DD MMM YY")}</p>
        </div>
      ))}
    </div>
  );
};

export default MultiCityTab;
