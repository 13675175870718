import React from "react";
import { BiSortDown } from "react-icons/bi";

interface Props {
  onClick: (
    key: "totalPrice" | "durationInMins" | "airports" | "departureTime"
  ) => void;
  selectedSortKey:
    | "totalPrice"
    | "durationInMins"
    | "airports"
    | "departureTime";
}

const FlightCartSortBar = ({ onClick, selectedSortKey }: Props) => {
  return (
    <React.Fragment>
      <div
        className="airline-logo"
        // onClick={() => onClick("airports")}
      >
        Airline
        {selectedSortKey === "airports" && <BiSortDown size={18} />}
      </div>
      <div
        className="d-flex justify-content-between"
        // onClick={() => onClick("depTime")}
      >
        <span>Departure</span>
        {/* {selectedSortKey === "departureTime" && <BiSortDown size={18} />} */}
        <span>Arrival</span>
      </div>

      <div className="duration" onClick={() => onClick("durationInMins")}>
        Duration
        {selectedSortKey === "durationInMins" && <BiSortDown size={18} />}
      </div>
      <div className="price ms-auto" onClick={() => onClick("totalPrice")}>
        Price
        {selectedSortKey === "totalPrice" && <BiSortDown size={18} />}
      </div>
    </React.Fragment>
  );
};

export default FlightCartSortBar;
