export const API_URL = "http://139.59.81.226:5002/";
// export const API_URL = "http://103.35.120.248:5050/";
// export const API_URL = "http://192.168.1.27:5000/";


// for spicejet
// export const SJ_API_URL = `${API_URL}sg/`;

// FOR HOTEL BEDS
export const HOTEL_API_URL = `${API_URL}hb/`;

// FOR TBO
export const TBO_HOTEL_API_URL = `${API_URL}ht/`;

// FOR BACKEND ADMIN PORTAL
// export const SERVER_API_URL = "http://api.travelyatra.com/api/";
export const SERVER_API_URL = "http://apiprod.travelyatra.com:5000/api/";

// file base url for HotelBeds images
export const HOTEL_BEDS_FILE_BASE_URL = "https://photos.hotelbeds.com/giata/";
