import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Path from "./routePaths";
import { State } from "../redux/types";
import { useSelector } from "react-redux";
interface Props {
  services?: number[];
}

const Routes = ({ services }: Props) => {
  const { isLoggedIn } = useSelector((state: State) => state.user);

  return (
    <React.Fragment>
      <Switch>
        {Path.flat().map((path, index) => {
          return (path.isAuthenticated && isLoggedIn) ||
            path?.isAuthenticated === undefined ? (
            <Route exact path={path.route} key={index}>
              {path.component}
            </Route>
          ) : (
            <Redirect to={"/"} key={index} />
          );
        })}
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
