import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BiSortDown, BiSortUp } from 'react-icons/bi';

import Pagination from './Pagination';
import HotelListItem from '../Components/HotelListItem';
import PlaceHolder from "../../../assets/images/hotel-placeholder.png";

import "../hotel.scss";

import { IHotel, State } from '../../../redux/types';
import useHotel from '../../../utils/hooks/Hotel/useHotel';
import { IHotelListingInfo } from '../../../utils/types/hotelTypes';
import { HOTEL_BEDS_FILE_BASE_URL } from '../../../utils/constants';
import useHotelDetails from '../../../utils/hooks/Hotel/useHotelDetails';
import { formatHotelAvailabilityList } from '../../../utils/utilityFunctions/hotelUtitlityFunctions';
import { createPaginatedArray, getMarkedUpPrice } from '../../../utils/utilityFunctions/utilityFunction';

interface Props {
  isLoading: boolean;
  hotelList: IHotelListingInfo[];
  iHotel: IHotel;
};

export default function SearchedHotelList({ isLoading, hotelList, iHotel }: Props) {

  const { filters } = useSelector((state: State) => state.hotel);
  const { ClientData } = useSelector((state: State) => state.commonState);

  const {
    handleHotelFilterSearch,
    updateSelectedHotel,
    handleHotelListingInfo,
    handleHotelListingInfoReset,
    updatePaginationInfo
  } = useHotel();
  const { searchHotelHandler } = useHotelDetails();

  const [sortKey, setSortKey] = useState<"starRating" | "price_down" | "price_up">('price_down');
  const [filteredHotelList, setFilteredHotelList] = useState<IHotelListingInfo[]>([]);



  useEffect(() => {
    const data = handleHotelFilterSearch(hotelList);
    data.length && setFilteredHotelList(data);
  }, [hotelList, filters.hotelName]);

  // const handleHotelFilterSearch = (hotels: IHotelListingInfo[]) => {
  //   console.log("Hotel filters: ", hotels?.filter(hotel =>
  //     (filters.hotelName.length > 0 ? hotel.hotelName.toLowerCase().includes(filters.hotelName.toLowerCase()) : true)
  //     &&
  //     (filters?.StarRating.length > 0 ? filters.StarRating.findIndex(rating => Number(rating) <= Number(hotel.starRating)) !== -1 : true)
  //     &&
  //     (filters?.price.length > 0 ? (+hotel.price <= Number(filters.price[0]))
  //       :
  //       true)
  //   ));

  //   return hotels?.filter(hotel =>
  //     (filters.hotelName.length > 0 ? hotel.hotelName.toLowerCase().includes(filters.hotelName.toLowerCase()) : true)
  //     &&
  //     (filters?.StarRating.length > 0 ? filters.StarRating.findIndex(rating => Number(rating) <= Number(hotel.starRating)) !== -1 : true)
  //     &&
  //     (filters?.price.length > 0 ? (+hotel.price <= Number(filters.price[0]))
  //       :
  //       true)
  //   );
  // }

  // let hotels = useMemo(() => {
  //   return hotelList?.filter(hotel =>
  //     (filters.hotelName.length > 0 ? hotel.hotelName.toLowerCase().includes(filters.hotelName.toLowerCase()) : true)
  //     &&
  //     (filters?.StarRating.length > 0 ? filters.StarRating.findIndex(rating => Number(rating) <= Number(hotel.starRating)) !== -1 : true)
  //     &&
  //     (filters?.price.length > 0 ? (+hotel.price <= Number(filters.price[0]))
  //       :
  //       true)
  //   )
  // }, [hotelList]);

  // console.log("Hotels: ", hotels);


  useEffect(() => {
    console.log("Zone filter has been added!");
  }, [filters.zones]);


  /**
   * Fetch the hotels for the current page from TBO
   * @param {number} currentPage - number
   */
  const fetchHotelsForTBOPage = (currentPage: number) => {
    handleHotelListingInfoReset();
    updatePaginationInfo("pageNumber", currentPage, "TBO");

    let hotelList = iHotel.TBO.searchedHotelList.slice(
      (currentPage - 1) * iHotel.TBO.paginationInfo.pageSize,
      currentPage * iHotel.TBO.paginationInfo.pageSize
    );
    let formatedHotelList = formatHotelAvailabilityList(
      hotelList,
      iHotel.searchHotel.checkInDate,
      iHotel.searchHotel.checkOutDate,
      "TBO"
    );
    handleHotelListingInfo(formatedHotelList);
    // console.group("TBO hotel pagination");
    // console.log("Current page: ", currentPage);
    // console.log("hotelList: ", hotelList);
    // console.log("Formated list: ", formatHotelAvailabilityList(
    //   hotelList,
    //   iHotel.searchHotel.checkInDate,
    //   iHotel.searchHotel.checkOutDate,
    //   "TBO"
    // ));
    // console.log("Total hotels: ", iHotel.TBO.searchedHotelList);
    // console.groupEnd();
  };


  return (
    <div>
      {hotelList.length > 0
        ?
        <React.Fragment>
          {/* SORTING FILTERS */}
          <div className="sort-container">
            <div className="item" onClick={() => setSortKey("price_up")}>
              {/* Star Rating
              {sortKey === "starRating" && <BiSortDown size={18} />} */}
              Price (High-to-Low)
              {sortKey === "price_up" ? <BiSortUp color={sortKey == "price_up" ? "blue" : "black"} size={18} /> : <></>}
            </div>
            <div className="item" onClick={() => setSortKey("price_down")}>
              Price (Low-to-High)
              {sortKey === "price_down" ? <BiSortDown color={sortKey == "price_down" ? "blue" : "black"} size={18} /> : <></>}
            </div>
          </div>
          {/* AVAILABLE HOTEL LIST */}
          {
            /* Sorting the hotels in ascending order based on the `sortKey`
             and then mapping the hotels to the `HotelListItem` component. */
            filteredHotelList?.sort(
              (a, b) => {
                if (sortKey === "price_down") {
                  return a?.price - b?.price;
                } else if (sortKey === "price_up") {
                  return b?.price - a?.price;
                };
                return a[sortKey] - b[sortKey];
              })?.map(
                (hotel, index: number) => (
                  <React.Fragment key={index + "_hotel"}>
                    <HotelListItem
                      code={String(hotel.hotelCode)}
                      title={hotel?.hotelName}
                      address={hotel?.hotelAddress}
                      description={hotel?.hotelDescription}
                      coverImg={
                        hotel.serviceProvider === "HOTELBEDS"
                          ?
                          HOTEL_BEDS_FILE_BASE_URL + (
                            hotel?.hotelPicture
                            ||
                            PlaceHolder
                          )
                          :
                          hotel.hotelPicture
                      }
                      price={{
                        amount: getMarkedUpPrice(
                          iHotel.metaData.markUps,
                          (+hotel?.price * iHotel.searchHotel.RoomGuest.length) || 0,
                          hotel.serviceProvider === "HOTELBEDS" ? "Hotelbeds" : "TBO",
                          ClientData?.GSTRates[0]?.Value ? Number(ClientData?.GSTRates[0]?.Value) : 0,
                          iHotel.metaData.affiliateMarkup,
                          iHotel.searchHotel.RoomGuest
                        )?.markedUpPrice.Base,
                        currency: hotel.currency
                      }}
                      starRating={+hotel?.starRating}
                      noOfNights={hotel.noOfNights}
                      facilities={hotel?.facilities || []}
                      onSelectHotel={() => {
                        updateSelectedHotel(hotel, hotel.serviceProvider)
                      }}
                    />
                  </React.Fragment>
                ))
          }
        </React.Fragment>
        :
        <></>}

      {/* PAGINATION */}
      <Pagination
        TPaginationInfo={iHotel.TBO.paginationInfo}
        HBPaginationInfo={iHotel.HotelBeds.paginationInfo}
        searchHotel={searchHotelHandler}
        updatePagination={updatePaginationInfo}
        getTBOHotelList={fetchHotelsForTBOPage}
      />
    </div>
  )
}