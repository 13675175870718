import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toggle } from "./redux/reducers/commonState";
import { State } from "./redux/types";
import IndianFlag from "./assets/flags/india.svg";

import { commonStateSlice } from "./redux/reducers/commonState";
import { IoMdAirplane } from "react-icons/io";
import { FaAngleDown, FaHotel } from "react-icons/fa";
import ProfileMenu from "./components/ProfileMenu/ProfileMenu";
import useClickOutSide from "./utils/hooks/useClickOutSide";
import { useLocation } from "react-router-dom";

const NavItem = ({
  isActive,
  onClick,
  label,
  icon,
}: {
  isActive: boolean;
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
}) => {
  return (
    <div
      className={`nav-items + ${isActive ? "nav-item-active" : ""}`}
      onClick={() => onClick()}
    >
      {icon ? <div className="icon">{icon}</div> : null}
      <span className="fw-bold">{label}</span>
    </div>
  );
};

interface Props {
  hasNavbar?: boolean;
}

const AppHeader = ({ hasNavbar }: Props) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isLoggedIn, firstName, lastName, isAgent } = useSelector(
    (state: State) => state.user
  );
  const { activeTab, ClientData } = useSelector(
    (state: State) => state.commonState
  );

  const [showMenu, setShowMenu] = useState<boolean>(false);


  return (
    <div className="app-header sticky-app-header">
      {/* HEADER SECTION */}
      <div className="container">
        <div className="header-section">
          <div>
            <img
              src={require("./assets/images/logo.png").default}
              alt="logo"
              className="app-logo pointer"
              onClick={() => {
                history.push("/");
              }}
            />
          </div>

          <div className="d-flex gap-3 header-content">
            <div
              className="my-auto fw-bold pointer d-flex gap-1"
              style={{ fontSize: "0.9em" }}
            >
              <img
                src={IndianFlag}
                alt="India"
                height={"18px"}
                className="my-auto"
              />
              <span>INR</span>
            </div>
            <div
              className="my-auto fw-bold pointer"
              style={{ fontSize: "0.9em" }}
            >
              Support
            </div>
            {/* sign btn or profile menu */}
            <div
              onClick={() => {
                !isLoggedIn
                  ? dispatch(toggle("showSignInModal"))
                  : setShowMenu(!showMenu);
              }}
              className="my-auto fw-bold pointer position-relative"
              style={{ fontSize: "0.9em" }}
            >
              {isLoggedIn ? (
                <span>
                  {firstName + " " + lastName}
                  <FaAngleDown size={18} />
                </span>
              ) : (
                "Sign in"
              )}

              {showMenu
                ?
                <ProfileMenu
                  isAgent={isAgent}
                  onClose={() => setShowMenu(false)}
                />
                :
                <></>}
            </div>
          </div>
        </div>
      </div>

      {/* Navbar Section */}
      {hasNavbar ? (
        <div className="container-fluid border-light">
          <div className="custom-navbar">
            <div className="nav-links">
              {ClientData.Services.map((item, index: number) => {
                return (
                  item?.ServiceName !== "Bus" && (
                    <NavItem
                      key={index}
                      onClick={() => {
                        dispatch(commonStateSlice.actions.setActiveTab(item));
                        history.replace("/");
                      }}
                      isActive={
                        activeTab?.ServiceId === item.ServiceId &&
                        !location.pathname.includes("/profile")
                      }
                      label={item?.ServiceName}
                      icon={
                        item?.ServiceId === 1 ? <IoMdAirplane /> : <FaHotel />
                      }
                    />
                  )
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="spacer spacer--sm"></div>
      )}
    </div>
  );
};

export default AppHeader;
