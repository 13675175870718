import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { FaSearch, FaTimes } from 'react-icons/fa';
import ChoiceGroup from '@kiwicom/orbit-components/lib/ChoiceGroup';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import { StarFull } from '@kiwicom/orbit-components/lib/icons';

import "../../hotel.scss";

import { State } from '../../../../redux/types';
import useHotel from '../../../../utils/hooks/Hotel/useHotel';
import Button from '../../../../components/Common/Button/Button';
import useHotelDetails from '../../../../utils/hooks/Hotel/useHotelDetails';
import AccordionCard from '../../../../components/Common/AccordionCard/AccordionCard'
import { hotelBedsContentTypes } from '../../../../Interfaces/SearchHotel/SearchHotel';
import { IHotelBedsAccommodationTypes, IHotelListingInfo, INearbySearchGeoLocation } from '../../../../utils/types/hotelTypes';
import { extractUniqueZoneInfoFromHotelList } from '../../../../utils/utilityFunctions/hotelUtitlityFunctions';
import axios from 'axios';
import { API_URL } from '../../../../utils/constants';

interface Props {
    hotel: IHotelListingInfo[];
};

interface IChoiceGroupProps {
    onChange: (value: string) => void;
    checkBoxProps: {
        label: string;
        value: string;
        sublabel?: string;
        isChecked?: boolean;
    }[];
};

interface ISearchedLocation {
    index?: number;
    formatted_address: string | null;
    geometry: {
        bounds: {
            northeast: {
                lat: number | null;
                lng: number | null;
            },
            southwest: {
                lat: number | null;
                lng: number | null;
            }
        },
        location: {
            lat: number | null;
            lng: number | null;
        },
        location_type: string | null;
        viewport: {
            northeast: {
                lat: number | null;
                lng: number | null;
            },
            southwest: {
                lat: number | null;
                lng: number | null;
            }
        }
    }
};

const initialSearchedLocation: ISearchedLocation = {
    index: 9999,
    formatted_address: null,
    geometry: {
        bounds: {
            northeast: {
                lat: null,
                lng: null,
            },
            southwest: {
                lat: null,
                lng: null,
            }
        },
        location: {
            lat: null,
            lng: null,
        },
        location_type: null,
        viewport: {
            northeast: {
                lat: null,
                lng: null,
            },
            southwest: {
                lat: null,
                lng: null
            }
        }
    }
};


const ChoiceGroupComp: FC<IChoiceGroupProps> = ({
    onChange,
    checkBoxProps,
}) => {
    return (
        <ChoiceGroup
            // filter
            onChange={(e: any) => {
                const { value } = e.target;
                onChange(value);
            }}
        >
            {checkBoxProps?.map((check, index) => (
                <Checkbox
                    key={index}
                    checked={check?.isChecked}
                    label={
                        <div className="d-flex justify-content-between">
                            <span className="">{check?.label}</span>
                            <span className="text-muted" style={{ fontSize: "0.9em" }}>
                                {check?.sublabel}
                            </span>
                        </div>
                    }
                    value={check?.value}
                />
            ))}
        </ChoiceGroup>
    );
};

const OverflowWrapper = ({ children }: any) => {
    return <div style={{ height: "200px", overflowY: "scroll" }}>{children}</div>
};

export default function SearchHotelFilters({ hotel }: Props) {

    const hotels = useSelector((state: State) => state.hotel.HotelBeds.searchedHotelList);
    const { filters: searchHotelFilter, nearbySearchGeoLocations, searchHotel } = useSelector((state: State) => state.hotel);

    const {
        handleHotelFilters,
        handleSpecificHotelFilterReset
    } = useHotel();
    const {
        handleShowToast,
        resetPaginationInfo,
        handleHotelListingInfoReset,
        updateNearbyGeoLocations,
        updateHotelFilterSelectedNearbySearchGeoLocation
    } = useHotel();
    const { searchHotelHandler } = useHotelDetails();


    const [searchLocationAddress, setSearchLocationAddress] = useState<string>("");
    const [searchLocationInfo, setSearchLocationInfo] = useState<ISearchedLocation>(initialSearchedLocation);
    const [accommodationTypes, setAccommodationTypes] = useState<IHotelBedsAccommodationTypes[]>([]);
    const [selectedPrice, setSelectedPrice] = useState<string>(searchHotelFilter.price[0] || "0");
    const [zones, setZones] = useState<{
        zoneCode: number;
        zoneName: string;
    }[]>([]);
    const [boards, setBoards] = useState<{
        code: string;
        name: string;
        data: string;
    }[]>([
        {
            "code": "AI",
            "name": "ALL INCLUSIVE",
            "data": "{\"code\": \"AI\", \"description\": {\"content\": \"ALL INCLUSIVE\"}, \"multiLingualCode\": \"AI\"}"
        },
        {
            "code": "BB",
            "name": "BED AND BREAKFAST",
            "data": "{\"code\": \"BB\", \"description\": {\"content\": \"BED AND BREAKFAST\"}, \"multiLingualCode\": \"BB\"}"
        },
        {
            "code": "FB",
            "name": "FULL BOARD",
            "data": "{\"code\": \"FB\", \"description\": {\"content\": \"FULL BOARD\"}, \"multiLingualCode\": \"FB\"}"
        },
        {
            "code": "HB",
            "name": "HALF BOARD",
            "data": "{\"code\": \"HB\", \"description\": {\"content\": \"HALF BOARD\"}, \"multiLingualCode\": \"HB\"}"
        },
        {
            "code": "RO",
            "name": "ROOM ONLY",
            "data": "{\"code\": \"RO\", \"description\": {\"content\": \"ROOM ONLY\"}, \"multiLingualCode\": \"RO\"}"
        },
        {
            "code": "SC",
            "name": "SELF CATERING",
            "data": "{\"code\": \"SC\", \"description\": {\"content\": \"SELF CATERING\"}, \"multiLingualCode\": \"SC\"}"
        },
        // {
        //     "code": "AS",
        //     "name": "All Inclusive Premium",
        //     "data": "{\"code\": \"AS\", \"description\": {\"content\": \"All Inclusive Premium\"}, \"multiLingualCode\": \"AS\"}"
        // },
        // {
        //     "code": "B1",
        //     "name": "Breakfast for one guest",
        //     "data": "{\"code\": \"B1\", \"description\": {\"content\": \"Breakfast for one guest\"}, \"multiLingualCode\": \"B1\"}"
        // },
        // {
        //     "code": "B2",
        //     "name": "Breakfast for two guests",
        //     "data": "{\"code\": \"B2\", \"description\": {\"content\": \"Breakfast for two guests\"}, \"multiLingualCode\": \"B2\"}"
        // },
        // {
        //     "code": "BH",
        //     "name": "1 BED AND BREAKFAST + 1 HALF BOARD",
        //     "data": "{\"code\": \"BH\", \"description\": {\"content\": \"1 BED AND BREAKFAST + 1 HALF BOARD\"}, \"multiLingualCode\": \"BH\"}"
        // },
        // {
        //     "code": "FH",
        //     "name": "1 HALF BOARD + 1 FULL BOARD",
        //     "data": "{\"code\": \"FH\", \"description\": {\"content\": \"1 HALF BOARD + 1 FULL BOARD\"}, \"multiLingualCode\": \"FH\"}"
        // },
        // {
        //     "code": "MB",
        //     "name": "HALF BOARD WITH BEVERAGES INCLUDED",
        //     "data": "{\"code\": \"MB\", \"description\": {\"content\": \"HALF BOARD WITH BEVERAGES INCLUDED\"}, \"multiLingualCode\": \"HV\"}"
        // },
        // {
        //     "code": "TL",
        //     "name": "ALL INCLUSIVE SOFT",
        //     "data": "{\"code\": \"TL\", \"description\": {\"content\": \"ALL INCLUSIVE SOFT\"}, \"multiLingualCode\": \"AA\"}"
        // }
    ]);



    useEffect(() => {

        const uniqueZones = extractUniqueZoneInfoFromHotelList(hotels);
        setZones(uniqueZones);

        // console.log("--------------------------------------------------------");
        // console.log("hotels: ", hotels);
        // console.log("uniqueZones: ", uniqueZones);
        // console.log("--------------------------------------------------------");

        hotels.length && getAccommodationTypes();

    }, [hotels]);



    // HANDLER FUNCTIONS
    const handleSearchHotel = () => {
        resetPaginationInfo("HOTELBEDS");
        resetPaginationInfo("TBO");
        handleHotelListingInfoReset();
        searchHotelHandler();
    };

    const handleManageZonesWithSearchedLocation = (location: ISearchedLocation) => {

        const existingZones: INearbySearchGeoLocation[] = JSON.parse(JSON.stringify(nearbySearchGeoLocations));
        if (existingZones.find(each => each.index === 9999 && each.name === location?.formatted_address)) return;

        const data = {
            index: new Date().valueOf(),
            name: location?.formatted_address || "",
            Address: location?.formatted_address || "",
            location: {
                latitude: location?.geometry.location.lat ? location?.geometry.location.lat : 0,
                longitude: location?.geometry.location.lng ? location?.geometry.location.lng : 0
            }
        };
        existingZones.unshift(data);
        setSearchLocationInfo(initialSearchedLocation);
        updateNearbyGeoLocations(existingZones);
        updateHotelFilterSelectedNearbySearchGeoLocation(data);


    };

    const handleLocationBasedOnUserInput = (e: React.KeyboardEvent<HTMLInputElement>) => {

        // console.log("Value: ", searchLocationAddress);
        // console.log("Event: ", e.key);

        if (e.key === "Enter") {

            getLocationBasedOnUserInput();

        };

    };

    const handleUpdateMaxPrice = () => {
        handleHotelFilters('price', "", selectedPrice);
    };

    // API CALLS
    const getAccommodationTypes = () => {

        hotelBedsContentTypes.getAccommodationTypes().then(
            (response) => {

                const accommodationList: IHotelBedsAccommodationTypes[] = response.data?.data?.accommodations || [];
                // console.log("Response for fetch accommodation types: ", accommodationList);
                setAccommodationTypes(accommodationList);

            }, (error) => {

                console.log("Error while fetching Accommodation Types from HotelBeds API: ", error?.response);

            }
        );

    };


    const getLocationBasedOnUserInput = () => {

        axios.get(`${API_URL}GetLocationFromGeocode/GetGeocodingRequestAndResponse?address=${searchLocationAddress}, ${searchHotel.whereTo.CityName}`).then(
            (response) => {

                // console.log("Response for fetch location by address: ", response.data);
                const location = response.data?.data;
                if (location) handleManageZonesWithSearchedLocation(location);
                else handleShowToast({
                    title: "No Data Found!",
                    subTitle: "Could not fetch zone. Please try some other zone.",
                    type: "error",
                });
                // setSearchLocationInfo(response.data?.data);

            }, (error) => {

                console.log("Error while fetching location by address: ", error?.response);
                handleShowToast({
                    title: "Something Went Wrong!",
                    subTitle: "Could not fetch zone. Please try again later.",
                    type: "error",
                });

            }
        );

    };


    return (
        <div className="sticky-container filter_section">

            <div className='input-group mb-1'>
                <input
                    className='form-control'
                    type='text'
                    placeholder='Search by hotel name'
                    value={searchHotelFilter.hotelName}
                    onChange={(e: any) => handleHotelFilters("hotelName", e.target.value)}
                />
                {searchHotelFilter.hotelName && <span
                    className="input-group-text pointer"
                    onClick={() => handleHotelFilters("hotelName", "")}
                >
                    <FaTimes />
                </span>}
            </div>

            <AccordionCard title={"Star Rating"} size="sm" isCollapsed={searchHotelFilter.StarRating.length ? false : true}>
                {[5, 4, 3, 2, 1].map((star, index) => (
                    <div className="form-check" key={index}>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={searchHotelFilter.StarRating.includes(String(star))}
                            onClick={() => handleHotelFilters("StarRating", String(star))}
                        />
                        <label className="form-check-label d-flex align-items-center" htmlFor="flexRadioDefault1">
                            <div className="star-rating">
                                {[...Array(star)].map((_, i) => (
                                    <StarFull size="small" key={i} />
                                ))}
                            </div>
                            <span className="small ms-1"> {star}</span>
                        </label>
                    </div>
                ))}
                <span
                    className="text-primary small pointer mt-3"
                    onClick={() => handleSpecificHotelFilterReset("StarRating")}
                >
                    Clear Filters
                </span>
            </AccordionCard>

            <AccordionCard title={"Review"} size="sm" isCollapsed={searchHotelFilter.HotelCategory.length ? false : true}>
                {[5, 4, 3, 2, 1].map((star, index) => (
                    <div className="form-check" key={index}>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioReview"
                            id="flexRadioDefaultReview"
                            checked={searchHotelFilter.HotelCategory.includes(String(star))}
                            onClick={() => handleHotelFilters("HotelCategory", String(star))}
                        />
                        <label className="form-check-label d-flex align-items-center" htmlFor="flexRadioDefaultReview">
                            <div className="star-rating">
                                {[...Array(star)].map((_, i) => (
                                    // <StarFull size="small" key={i} />
                                    <div key={"review_" + i} style={{ marginRight: "2px" }}>
                                        <img
                                            width={16}
                                            height={16}
                                            src={require("../../../../assets/images/tripadvisor-icon.png").default}
                                        />
                                    </div>
                                ))}
                            </div>
                            <span className="small ms-1"> {star}+</span>
                        </label>
                    </div>
                ))}
                <span
                    className="text-primary small pointer mt-3"
                    onClick={() => handleSpecificHotelFilterReset("HotelCategory")}
                >
                    Clear Filters
                </span>
            </AccordionCard>

            {/* <AccordionCard title={"Maximum Price"} size="sm" isCollapsed={true}>
                <label htmlFor="priceRange" className="form-label">Price: &#8377;{
                    searchHotelFilter.price[0]
                    ||
                    Math.ceil(hotel?.reduce((acc, shot) => acc = acc > Number(shot.price) ? acc : Number(shot.price), 0)
                        ||
                        0)
                }</label>
                <input
                    type="range"
                    className="form-range"
                    step="500"
                    id="priceRange"
                    min={0
                        // hotel?.length > 0
                        //     ?
                        //     Math.ceil(+hotel?.reduce((acc, shot) => shot?.price < acc?.price ? shot : acc)?.price)
                        //     :
                        //     0
                    }
                    max={65000
                        // hotel?.length > 0
                        //     ?
                        //     Math.ceil(hotel?.reduce((acc, shot) => acc = acc > Number(shot.price) ? acc : Number(shot.price), 0))
                        //     :
                        //     0
                    }
                    value={
                        searchHotelFilter.price[0]
                        ||
                        Math.ceil(+hotel?.reduce((acc, shot) => acc = acc > Number(shot.price) ? acc : Number(shot.price), 0)
                            ||
                            0)
                    }
                    onChange={(e) => handleHotelFilters('price', "", e.target.value)}
                />
                <span
                    className="text-primary small pointer mt-3"
                    onClick={() => handleSpecificHotelFilterReset("price")}
                >
                    Clear Filters
                </span>
            </AccordionCard> */}

            <AccordionCard title={"Maximum Price"} size="sm" isCollapsed={true}>
                <label htmlFor="priceRange" className="form-label">Price: &#8377;{
                    selectedPrice
                    ||
                    0
                }</label>
                <input
                    type="range"
                    className="form-range"
                    step="500"
                    id="priceRange"
                    min={0}
                    max={500000}
                    value={
                        selectedPrice
                        ||
                        0
                    }
                    onChange={(e) => setSelectedPrice(e.target.value)}
                />
                <div className="d-flex justify-content-between">
                    <span
                        className="text-primary small pointer mt-3"
                        onClick={() => handleSpecificHotelFilterReset("price")}
                    >
                        Clear Filters
                    </span>
                    {selectedPrice !== "0"
                        ?
                        <span
                            className="text-primary small pointer mt-3"
                            onClick={() => handleUpdateMaxPrice()}
                        >
                            Apply
                        </span>
                        :
                        <></>}
                </div>
            </AccordionCard>

            <AccordionCard title={"Board"} size="sm" isCollapsed={searchHotelFilter.HotelBoards.length ? false : true}>
                {boards.map((board, index) => (
                    <div className="form-check" key={index}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={searchHotelFilter.HotelBoards.includes(board.code)}
                            onClick={() => handleHotelFilters("HotelBoards", board.code)}
                        />
                        <label className="form-check-label d-flex align-items-center" htmlFor="flexRadioDefault1">
                            {board.name}
                        </label>
                    </div>
                ))}
                <span
                    className="text-primary small pointer mt-3"
                    onClick={() => handleSpecificHotelFilterReset("HotelBoards")}
                >
                    Clear Filters
                </span>
            </AccordionCard>

            <AccordionCard title={"Zone"} size="sm" isCollapsed={searchHotelFilter.selectedNearbySearchGeoLocation ? false : true}>
                {/* SEARCH LOCATION - Google Geocoding */}
                <>
                    {/* search box */}
                    <div className='input-group mb-2'>
                        <input
                            className='form-control'
                            type='text'
                            placeholder='Search zone...'
                            value={searchLocationAddress}
                            onChange={(e: any) => setSearchLocationAddress(e.target.value)}
                            onKeyDown={(e) => handleLocationBasedOnUserInput(e)}
                        />
                        {searchLocationAddress
                            ?
                            <span
                                className="input-group-text pointer"
                                onClick={() => setSearchLocationAddress("")}
                            >
                                <FaTimes />
                            </span>
                            :
                            <></>}
                    </div>
                    <hr />
                    <p className="text-sm text-muted">Or select from below list</p>
                    {/* show result from geocoding */}
                    {/* {searchLocationInfo?.formatted_address
                        ?
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="locationCheckBox"
                                id="locationCheckBox"
                                // checked={searchHotelFilter.selectedNearbySearchGeoLocation?.index === searchLocationInfo!.index}
                                onClick={() => handleManageZonesWithSearchedLocation()}
                            />
                            <label className="form-check-label d-flex align-items-center" htmlFor="zoneCheckBoxt1">
                                {searchLocationInfo?.formatted_address || ""}
                            </label>
                            <hr />
                        </div>
                        :
                        <></>} */}
                </>

                {/* POI - Google Places (New) */}
                <OverflowWrapper>
                    {nearbySearchGeoLocations?.map((location, index) => (
                        <div
                            key={"zone_" + index}
                            className="form-check pointer"
                            onClick={() => handleHotelFilters("selectedNearbySearchGeoLocation", String(location.index))}
                        >
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="zoneCheckBoxt"
                                id="zoneCheckBoxt1"
                                checked={searchHotelFilter.selectedNearbySearchGeoLocation?.index === +location?.index}
                            />
                            <label className="form-check-label d-flex align-items-center" htmlFor="zoneCheckBoxt1">
                                {location.name}
                            </label>
                        </div>
                    ))}
                </OverflowWrapper>
                <span
                    className="text-primary small pointer mt-3"
                    onClick={() => handleSpecificHotelFilterReset("selectedNearbySearchGeoLocation")}
                >
                    Clear Filters
                </span>
            </AccordionCard>

            <AccordionCard title={"Accommodation Type"} size="sm" isCollapsed={searchHotelFilter.accommodationTypes.length ? false : true} isDisabled={false}>
                <OverflowWrapper>
                    {accommodationTypes.map((accommodation, index) => (
                        <div className="form-check" key={"zone" + index}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="accommodationCheckBoxt"
                                id="accommodationCheckBoxt1"
                                checked={searchHotelFilter.accommodationTypes.includes(accommodation.code)}
                                onClick={() => handleHotelFilters("accommodationTypes", accommodation.code)}
                            />
                            <label className="form-check-label d-flex align-items-center" htmlFor="accommodationCheckBoxt1">
                                {accommodation.typeDescription}
                            </label>
                        </div>
                    ))}
                </OverflowWrapper>
                <span
                    className="text-primary small pointer mt-3"
                    onClick={() => handleSpecificHotelFilterReset("accommodationTypes")}
                >
                    Clear Filters
                </span>
            </AccordionCard>



            {/* <div className="d-flex justify-content-end">
                <Button
                    className="mt-2 search-btn"
                    //   disabled={!isValidated()}
                    onClick={() => handleSearchHotel()}
                    label={
                        <span>
                            Apply
                            <FaSearch size={16} />
                        </span>
                    }
                />
            </div> */}

        </div>
    )
};
