import { useMemo } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import "./stepper.scss";

interface Props {
  value: number;
  minValue?: number;
  maxValue?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  onDisabledClick?: (type?: "add" | "sub") => void;
}

export default function IStepper({
  value,
  minValue,
  maxValue,
  onChange,
  disabled,
  onDisabledClick,
}: Props) {
  const isMinusDisabled = useMemo(
    () => disabled === true || value <= (minValue ? minValue : 0),
    [value, minValue, disabled]
  );
  const isPlusDisabled = useMemo(
    () => disabled === true || value >= (maxValue ? maxValue : 99999),
    [value, maxValue, disabled]
  );
  return (
    <div className="stepper">
      <div
        className={`stepper-button ${isMinusDisabled ? "disabled" : ""}`}
        onClick={() =>
          !isMinusDisabled
            ? onChange(value - 1)
            : onDisabledClick && onDisabledClick("sub")
        }
      >
        <AiOutlineMinus size={12} className="p-0 m-0" />
      </div>

      <div className="stepper-value">
        <span>{value}</span>
      </div>

      <div
        className={`stepper-button ${isPlusDisabled ? "disabled" : ""}`}
        onClick={() =>
          !isPlusDisabled
            ? onChange(value + 1)
            : onDisabledClick && onDisabledClick("add")
        }
      >
        <AiOutlinePlus size={12} className="p-0 m-0" />
      </div>
    </div>
  );
}
