import React, { FC } from "react";
import "./Skeleton.scss";

interface ISkeleton {
  typeArray: string[]; //text| title | box
  count?: number;
  elementStyle?: {};
}

export const Skeleton: FC<ISkeleton> = ({ typeArray, count, elementStyle }) => {
  return (
    <>
      {[...new Array(count)].map((_, index) => (
        <div className="skeleton-wrapper" key={index}>
          {typeArray.map((type, idx) => (
            <div
              className={`skeleton ${type}`}
              style={elementStyle ? elementStyle : {}}
              key={idx}
            ></div>
          ))}
          <div className="anim-wrapper">
            <div className="anim"></div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Skeleton;
