import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError, AxiosResponse } from "axios";

import useHotel from "./useHotel";
import useFetch from "../useFetch";
import { State } from "../../../redux/types";
import { SERVER_API_URL } from "../../constants";
import { updateHotelMetaData } from "../../../redux/reducers/hotel";
import { IHotelMarkup, ICreateBooking } from "../../types/hotelTypes";

const useHotelMetaData = () => {

  const dispatch = useDispatch();

  const { user } = useSelector((state: State) => state);
  const { Client } = useSelector((state: State) => state.commonState);
  const { metaData } = useSelector((state: State) => state.hotel);

  const { updateMarkup, updateAffiliateMarkup } = useHotel();


  const URL = `${SERVER_API_URL}Hotel/`;

  const { get: getDestination } = useFetch(
    `${URL}GetDestination/${Client?.id}`
  );
  const { get: GetBookingSupplier } = useFetch(
    `${URL}GetBookingSupplier/${Client?.id}`
  );
  const { get: GetTicketedSupplier } = useFetch(
    `${URL}GetTicketedSupplier/${Client?.id}`
  );
  const { get: GetCharges } = useFetch(`${URL}GetCharges/${Client?.id}`);
  const { get: GetAffiliateCharges } = useFetch(`${SERVER_API_URL}Agent/GetHotelCharges/${Client?.id}/${user.affiliateId}`);

  const getDestinations = () => {
    getDestination(({ data, error }) => {
      if (data?.length > 0) {
        dispatch(updateHotelMetaData({ key: "Destination", value: data }));
      }
    });
  };

  const getBookingSupplierList = () => {
    GetBookingSupplier(({ data, error }) => {
      if (data?.length > 0) {
        dispatch(updateHotelMetaData({ key: "BookingSupplier", value: data }));
      }
    });
  };

  const getTicketedSupplierList = () => {
    GetTicketedSupplier(({ data, error }) => {
      if (data?.length > 0) {
        dispatch(updateHotelMetaData({ key: "TicketedSupplier", value: data }));
      }
    });
  };

  const getChargesList = (isAffiliate = false) => {
    isAffiliate
      ?
      GetAffiliateCharges(({ data, error }) => {
        if (data?.length > 0) {
          dispatch(updateHotelMetaData({ key: "Charges", value: data }));
        }
      })
      :
      GetCharges(({ data, error }) => {
        if (data?.length > 0) {
          dispatch(updateHotelMetaData({ key: "Charges", value: data }));
        }
      });
  };

  const refreshHotelMetaData = () => {
    if (Client !== null && metaData) {
      if (metaData.Destination.length === 0) {
        getDestinations();
      }
      if (metaData.BookingSupplier.length === 0) {
        getBookingSupplierList();
      }
      // commented bcoz TicketedSupplier data is coming [] from API so this API is being called everytime
      // if (metaData.TicketedSupplier.length === 0) {
      //   getTicketedSupplierList();
      // }

      // if (metaData.Charges.length === 0) {
      //   getChargesList();
      // }
    }
  };


  /**
   * It calls the API to get the markup data for the given destination and date range.
   * @param {number} destinationId - The ID of the destination for which you want to get markup.
   * @param {string} fromDate - The date from which you want to get the markup.
   * @param {string} toDate - The date to which the markup is to be calculated.
   */
  const handlePostMarkup = (
    destinationId: number,
    fromDate: string,
    toDate: string,
    affiliateId?: string | null
  ) => {
    let body: {
      ClientId?: number;
      DestinationId?: number;
      FromDate?: string;
      ToDate?: string;
      AffiliateId?: string | null;
    } = {
      ClientId: Client?.id,
      DestinationId: destinationId,
      FromDate: moment(fromDate).toISOString(),
      ToDate: moment(toDate).toISOString(),
    };
    if (affiliateId !== null) {
      body["AffiliateId"] = affiliateId;
    }
    axios.post(`${URL}GetMarkup${affiliateId !== null ? 'B2B' : ''}`, body).then(
      (response: AxiosResponse) => {
        // console.log("Response for GetMarkup: ", response?.data);
        let data: IHotelMarkup[] = response?.data?.ResponseData;
        updateMarkup(data);
      },
      (error: AxiosError) => {
        console.log("Error for GetMarkup: ", error?.response);
      }
    );
  };

  /**
   * "This function takes in 4 parameters, and then makes an API call to get  affiliate markup,
   * it then updates the state with the data that was received from the API call."
   * </code>
   * @param {number} destinationId - number,
   * @param {string} fromDate - string,
   * @param {string} toDate - string,
   * @param {string} affiliateId - string | null
   */
  const handleAffiliatePostMarkup = (
    destinationId: number,
    fromDate: string,
    toDate: string,
    affiliateId: string
  ) => {
    let body: {
      ClientId?: number;
      DestinationId?: number;
      FromDate?: string;
      ToDate?: string;
      AffiliateId?: string | null;
    } = {
      ClientId: Client?.id,
      DestinationId: destinationId,
      FromDate: moment(fromDate).toISOString(),
      ToDate: moment(toDate).toISOString(),
      AffiliateId: affiliateId
    };

    axios.post(`${SERVER_API_URL}Agent/GetHotelMarkup`, body).then(
      (response: AxiosResponse) => {
        // console.log("Response for GetMarkup: ", response?.data);
        let data: IHotelMarkup[] = response?.data?.ResponseData;
        updateAffiliateMarkup(data);
      },
      (error: AxiosError) => {
        console.log("Error for GetMarkup: ", error?.response);
      }
    );
  };


  /**
   * It creates a booking for a customer.
   * @param {ICreateBooking} payload -  booking payload
   */
  const handleCreateBooking = (payload: ICreateBooking) => {
    axios.post(`${URL}CreateBooking`, payload).then(
      (response: AxiosResponse) => {
        console.log("Response for CreateBooking: ", response?.data);
      },
      (error: AxiosError) => {
        console.log("Error for CreateBooking: ", error?.response);
      }
    );
  };


  return {
    getDestinations,
    getBookingSupplierList,
    getTicketedSupplierList,
    getChargesList,
    refreshHotelMetaData,
    handlePostMarkup,
    handleAffiliatePostMarkup,
    handleCreateBooking,
  };

};
export default useHotelMetaData;
