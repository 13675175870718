import React from "react";
import { MdClear, MdDone, MdEventSeat } from "react-icons/md";
import { ISeatPrice } from "../../../../../../utils/types/flightTypes";
import { priceFormatter } from "../../../../../../utils/utilityFunctions/utilityFunction";
import "../seatSelection.scss";
export interface ISeatData {
  seatId: string;
  isAvailable?: boolean;
  seatClass?: "MAX" | "REG";
  seatLocation?: "W" | "M" | "A"; //window | middle | aisle
  isSelected?: boolean;
  hasLegroom?: boolean;
  price: ISeatPrice;
  features: string[];
  selectedByPax?: number;
}

interface Props {
  seatData: ISeatData;
  onSelect: () => void;
  seatsNotAllowed?: string[];
}
const Seat = ({ seatData,seatsNotAllowed, onSelect }: Props) => {
  let iconSize = 20;
  const {
    seatId,
    isAvailable,
    seatClass,
    seatLocation,
    isSelected,
    hasLegroom,
    price,
    features,
    selectedByPax,
  } = seatData;
  let featureList: string[] = [];
  if (features.includes("LEGROOM")) {
    featureList.push("Extra Legroom");
  }
  if (features.includes("WINDOW")) {
    featureList.push("Window Seat");
  }
  if (features.includes("AISLE")) {
    featureList.push("Aisle Seat");
  }
  if (features.includes("EXITROW")) {
    featureList.push("Emergency Exit");
  }
  if (features.includes("WING")) {
    featureList.push("Wing Side Seat");
  }

  return (
    <div
      onClick={() => {
        if (isAvailable && !isSelected && !seatsNotAllowed?.includes(seatId)) {
          onSelect();
        }
      }}
      className={`seat-item ${
       ( !isAvailable || seatsNotAllowed?.includes(seatId))
          ? "unavailable-seat"
          : isSelected
          ? "selected-seat"
          : seatClass === "MAX"
          ? "max-seat"
          : seatLocation === "A" || seatLocation === "W" || seatLocation === "M"
          ? "available-seat"
          : "middle-seat"
      }  ${hasLegroom ? "exLegroom" : ""}`}
    >
      {isSelected ? (
        <MdDone size={iconSize} />
      ) : // <span style={{ fontSize: "0.75em" }}>P{selectedByPax}</span>
      (isAvailable && !seatsNotAllowed?.includes(seatId))? (
        // <MdEventSeat size={iconSize} className="seat-icon" />
        <span
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        ></span>
      ) : 
      (
        <MdClear size={iconSize} />
      )}
      <div
        className={` ${
          seatId.includes("F") || seatId.includes("E")
            ? "seat-info-top"
            : "seat-info"
        }`}
        style={{ fontSize: "0.8em" }}
      >
        <span>Seat No.: {seatId}</span>
        <span>
          Price:{" "}
          {Number(price.TotalPrice.Charge) === 0
            ? "Free"
            : priceFormatter(
                Number(price?.TotalPrice.Charge) + Number(price?.TotalPrice.GST)
              )}
        </span>
        <span>
          <span className="fw-bold">Features: </span>
          {featureList?.map((feat, index) => (
            <>
              {feat}
              {index < featureList.length - 1 ? ", " : ""}
            </>
          ))}
        </span>
      </div>
    </div>
  );
};

export default Seat;
