import { useEffect, useRef, useState } from "react";

import {
  AirLineServices,
  IAirIQFlightBookingPayload,
  ISeatAvailability,
  IUpdatePassenger,
} from "../../types/flightTypes";
import {
  IItineraryBookingSum,
  ISelectedFaresForItinerary,
  TypeCarrierCode,
} from "./../../types/flightTypes";
import { useHistory } from "react-router-dom";
import { State } from "./../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { BookFlights } from "../../../Interfaces/BookFlights/BookFlights";
import usePayment from "../Payment/usePayment";
import useFlightPostBooking from "./useFlightPostBooking";
import { showToast } from "../../../redux/reducers/commonState";
import { getUniqueValuedArray } from "../../utilityFunctions/utilityFunction";
import { airIQFlight, searchFlights } from "../../../Interfaces/SearchFlights/SearchFlights";
import { formatSGSeatAvailabilityData } from "../../utilityFunctions/flight/SGUtilityFunctions";
import { calculateFlightPricesToShow, getSellAndItineraryPayload } from "../../utilityFunctions/flight/flightUtilityFunctions";
import moment from "moment";

const useFlightSearch = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { personalInfo, searchInfo, selectedFlights, Itinerary } = useSelector((state: State) => state.flight);


  const { getBooking, createBooking } = useFlightPostBooking();

  const paymentForm = useRef<HTMLDivElement>(null);
  const [confirmCancelPayment, setConfirmCancelPayment] = useState<boolean>(false);

  const [isSelling, setIsSelling] = useState<boolean>(false);
  const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
  const [isPassengerUpdated, setIsPassengerUpdated] = useState<boolean>(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(false);
  const [isPaymentDone, setIsPaymentDone] = useState<boolean>(false);
  const [seatList, setSeatList] = useState<ISeatAvailability[]>([]);
  const [agentAddOnMarkup, addAgentAddOnMarkup] = useState(0);

  // loading
  const [isUpdatingPassengers, setIsUpdatingPassengers] = useState<boolean>(false);

  useEffect(() => {
    if (isSessionExpired) {
      dispatch(
        showToast({
          title: "Session Expired!",
          subTitle: "Redirecting to HomePage",
          type: "error",
          time: 3,
        })
      );
      history.push("/");
    }
  }, [isSessionExpired]);



  /* PAYMENT HANDLER FUNCTIONS */
  const handlePaymentSuccess = async (bookingSums: IItineraryBookingSum[]) => {
    // console.log("Payment Success!");
    // console.log("bookingSums after payment success: ", bookingSums);
    try {

      setIsPaymentProcessing(true);
      const addPaymentPromise = bookingSums.map((bookingSum) =>
        BookFlights[
          AirLineServices[bookingSum.CarrierCode as TypeCarrierCode]
        ]?.addPaymentToBooking(String(bookingSum.TotalCost))
      );
      // const response = await BookFlights[0]?.addPaymentToBooking(amount);

      const response = await Promise.all(addPaymentPromise);
      if (response) {
        commitBooking(bookingSums);
      };

    } catch (error) {

      setIsSessionExpired(true);
      console.log("Error while adding payment to booking: ", error);

    };
  };

  const handlePaymentFailure = () => {

    dispatch(
      showToast({
        type: "error",
        title: "Payment Failed!",
        subTitle: "If any amount is debited from your account please contact support."
      })
    );

  };

  const {
    isPaymentFormVisible,
    setIsPaymentFormVisible,
    setNetPayable,
    setOnSuccessDetails
  } = usePayment({
    paymentForm,
    handlePaymentSuccess,
    handlePaymentFailure
  });

  /* API CALLS */
  const sellTicket = async (
    journeyArr: ISelectedFaresForItinerary[]
  ) => {

    // let isSold = false;
    setIsSelling(true);
    try {

      // filter out Navitaire based journeys to prepare for itinerary payload
      const filteredNavitaireJourneys = journeyArr.filter(journey => journey.supplier === "navitaire");
      if (filteredNavitaireJourneys.length === 0) {
        console.log("No Navitaire flights selected!!!");
        setIsSelling(false);
        return;
      };

      // eg: {sg:[{selected flights data of spicejet }]}
      const journeys = getSellAndItineraryPayload(filteredNavitaireJourneys);

      let sellPromises = Object.keys(journeys).map((carrierCode) =>
        BookFlights[
          AirLineServices?.[carrierCode as TypeCarrierCode]
        ]?.postSell(journeys[carrierCode], searchInfo?.adult, searchInfo?.child)
      );

      // console.log("journeys: ", journeys);
      // console.log("AirLineServices: ", AirLineServices);
      // console.log("journeys[carrierCode]: ", journeys["6E"]);

      const response = await Promise.all(sellPromises);
      if (response) {
        response.forEach((r) => {
          console.log(
            `Journeys sold! Make Payment in next 10 mins or,Start over.`
          );
        });
        // isSold = true;
        history.push("/flight/booking");
      } else {
        dispatch(
          showToast({
            title: "Something went wrong!",
            subTitle: "try again",
            type: "error",
            time: 3,
          })
        );
      };

    } catch (error) {

      console.log("Error while selling navitaire journeys: ", error);

    };
    // return isSold;
    setIsSelling(false);

  };

  const updatePassenger = () => {

    setIsUpdatingPassengers(true);

    let passengers: IUpdatePassenger = personalInfo?.passengers?.adult?.map(
      (p, index) => {
        let obj: any = {};
        obj.FirstName = p.firstName;
        obj.LastName = p.lastName;
        obj.Title = p.Title.slice(0, p.Title.length - 1);
        obj.Gender = p.gender;
        obj.DOB = p.dateOfBirth;
        obj.PaxType = "ADT";
        if (searchInfo.infant > 0) {
          let infantInfo = personalInfo?.passengers?.infant[index]; //infant at index 0 is assigned with adult 0
          obj.Infant = {
            DOB: infantInfo.dateOfBirth,
            Gender: infantInfo.gender,
            FirstName: infantInfo.firstName,
            LastName: infantInfo.lastName,
            Title: infantInfo.Title.slice(0, infantInfo.Title.length - 1),
          };
        }
        return obj;
      }
    );
    const children: IUpdatePassenger = personalInfo?.passengers?.child?.map(
      (p) => ({
        FirstName: p.firstName,
        LastName: p.lastName,
        Title: p.Title.slice(0, p.Title.length - 1),
        Gender: p.gender,
        DOB: p.dateOfBirth,
        PaxType: "CHD",
      })
    );
    let uniqueCarrierCodes = getUniqueValuedArray(
      Itinerary.Journeys.map((journey) => journey.meta.carrierCode)
    );
    let updatePaxPromise = uniqueCarrierCodes.map((carrierCode) =>
      BookFlights[
        AirLineServices[carrierCode as TypeCarrierCode]
      ]?.updatePassenger([...passengers, ...children])
    );
    // BookFlights[0]
    //   .updatePassenger([...passengers, ...children])
    //   .then((res) => {
    //     // setIsSelling(false);
    //     setIsPassengerUpdated(true);
    //   })
    //   .catch((err) => console.log("error updating pax details", err));
    Promise.all(updatePaxPromise)
      .then((res) => {
        setIsPassengerUpdated(true);
      })
      .catch((err) => {

        console.log("Passenger Details couldn't be updated. Error:", err?.response?.data?.message?.APIGeneralFault?.Message);
        dispatch(
          showToast({
            title: `Update Passenger Failed!`,
            subTitle: err?.response?.data?.message?.APIGeneralFault?.Message || "Could not update passenger information. Please contact helpdesk.",
            type: "error",
            time: 6,
          })
        );

      }).finally(() => setIsUpdatingPassengers(false));
  };

  const addPayment = async (bookingSums: IItineraryBookingSum[]) => {

    // NOTE: Uncomment below code block (Payment Gateway Integration)
    // const calculatedPrice = calculateFlightPricesToShow(
    //   Itinerary?.Journeys,
    //   searchInfo?.adult,
    //   searchInfo?.child,
    //   SelectedAddOns || [],
    //   agentAddOnMarkup
    // );

    // setIsPaymentFormVisible(true);
    // setNetPayable(String(calculatedPrice.NetPayable));
    // setOnSuccessDetails(bookingSums);

    // WARN: comment the below code blocks (Without Payment Gateway Integration)
    try {
      setIsPaymentProcessing(true);
      const addPaymentPromise = bookingSums.map((bookingSum) =>
        BookFlights[
          AirLineServices[bookingSum.CarrierCode as TypeCarrierCode]
        ]?.addPaymentToBooking(String(bookingSum.TotalCost))
      );
      // const response = await BookFlights[0]?.addPaymentToBooking(amount);

      const response = await Promise.all(addPaymentPromise);
      if (response) {
        commitBooking(bookingSums);
      };
    } catch (error) {
      setIsSessionExpired(true);
    };

  };

  const commitBooking = (bookingSums: IItineraryBookingSum[]) => {

    const { Title, firstName, lastName } = personalInfo?.passengers?.adult[0];
    let passengers = [
      {
        Title: Title.slice(0, Title.length - 1), //slicing because title such as Mr is accepted from SJ API but our backend gives MR.
        FirstName: firstName,
        LastName: lastName,
      },
    ];

    const allCarriers = bookingSums.map((bookingSum) => bookingSum.CarrierCode);
    const commitBookingPromise = allCarriers.map((carrierCode) =>
      BookFlights[
        AirLineServices[carrierCode as TypeCarrierCode]
      ]?.commitBooking(
        passengers,
        personalInfo?.email,
        `${personalInfo?.countryCode}${personalInfo?.mobileNo}`,
        passengers?.length
      )
    );
    // BookFlights[0]
    //   ?.commitBooking(
    //     passengers,
    //     personalInfo?.email,
    //     `${personalInfo?.countryCode}${personalInfo?.mobileNo}`,
    //     passengers?.length
    //   )

    try {

      Promise.all(commitBookingPromise).then(
        async (response) => {
          const commitResponses = response.map((r) => r?.data?.data);

          const getBookingPromise = commitResponses.map((cr, index) =>
            getBooking(cr.RecordLocator, allCarriers[index])
          );

          const bookingResponses = await Promise.all(getBookingPromise);

          // {SG:BookingResponseOfSG,G8:BookingResponseOfG8}
          let bookingResponseByCarrier: { [x: string]: any } = {};
          bookingResponses.forEach(
            (e, index) => (bookingResponseByCarrier[allCarriers[index]] = e)
          );

          // const bookingResponse = await getBooking(data?.RecordLocator);
          // dispatch(userSlice.actions.addNewBooking(data?.RecordLocator));


          // AirIQ Booking Starts
          const AirIQFlights = selectedFlights?.filter(each => each.supplier === "airiq");
          if (AirIQFlights?.length) {

            const AirIQBookingPayload: IAirIQFlightBookingPayload[] = AirIQFlights.map((flight) => {

              const journeyPayload: IAirIQFlightBookingPayload = {
                ticket_id: flight.JourneySellKey,
                total_pax: String(searchInfo.adult + searchInfo.child + searchInfo.infant),
                adult: String(searchInfo.adult),
                child: String(searchInfo.child),
                infant: String(searchInfo.infant),
                adult_info: personalInfo.passengers.adult.map(eachAdult => ({
                  title: eachAdult.Title,
                  first_name: eachAdult.firstName,
                  last_name: eachAdult.lastName
                })),
                child_info: personalInfo.passengers.child.map(eachChild => ({
                  title: eachChild.Title,
                  first_name: eachChild.firstName,
                  last_name: eachChild.lastName
                })),
                infant_info: personalInfo.passengers.infant.map(eachInfant => ({
                  title: eachInfant.Title,
                  first_name: eachInfant.firstName,
                  last_name: eachInfant.lastName,
                  dob: moment(eachInfant.dateOfBirth).format("YYYY/MM/DD"),
                  travel_with: "1"
                }))
                // [{
                //   "title": "Mstr.",
                //   "first_name": "first name",
                //   "last_name": "last name",
                //   "dob": "2022/04/22",
                //   "travel_with": "1"
                // }]
              };

              return journeyPayload;

            });

            // return journeyPayload;
            airIQFlight.ticketBooking(AirIQBookingPayload).then((response) => {

              console.log("AirIQ Booking Response: ", response.data);

            }, (error) => {

              console.error("AirIQ Booking Error: ", error?.response);
              dispatch(
                showToast({
                  title: `Booking Commit Failed!`,
                  subTitle: "Could not commit booking for one of the carriers. Please contact helpdesk.",
                  type: "error",
                  time: 6
                })
              );

            });


          };
          // AirIQ Booking Ends


          const isBookingCreated = await createBooking(
            bookingResponseByCarrier,
            agentAddOnMarkup
          );
          setIsPaymentDone(true);
        });

    } catch (error) {

      console.error("Booking Commit Failed: ", error);
      dispatch(
        showToast({
          title: `Booking Commit Failed!`,
          subTitle: "Could not commit booking for one of the carriers. Please contact helpdesk.",
          type: "error",
          time: 6,
        })
      );

    };

  };

  const getSeatAvailability = async () => {

    // filter out Navitaire based journeys to prepare for itinerary payload
    const filteredNavitaireJourneys = selectedFlights?.filter(
      journey => journey.supplier === "navitaire"
    );

    if (filteredNavitaireJourneys.length === 0) {
      return;
    };

    let passengersIndexes = [
      ...Array(searchInfo?.adult + searchInfo?.child),
    ].map((_, i) => i);

    let segmentsArr = filteredNavitaireJourneys?.map((journey, journeyIndex) =>
      journey?.SegmentData?.map((seg, index) => ({
        STD: seg?.departure?.scheduled_time,
        DepartureStation: seg?.departure?.iata_code,
        ArrivalStation: seg?.arrival?.iata_code,
        FlightNumber:
          (seg?.flightNumber.length === 3 ? " " : "") + seg?.flightNumber,
        CarrierCode: seg?.carrierCode,
        PassengerID: passengersIndexes,
        CurrencyCode: "INR"
      }))
    ).flat();
    // console.log("segmentsArr: ", segmentsArr);

    const seatAv = segmentsArr?.map((seg) => {
      // if (seg.CarrierCode === "SG") {
      return searchFlights[
        AirLineServices[seg.CarrierCode as TypeCarrierCode]
      ]?.getSeatAvailability(seg);
      // }
    });

    const response = await Promise.allSettled(seatAv);
    response.forEach((res, index) => {
      if (res.status === "fulfilled") {
        setSeatList((prev) => [
          ...prev,
          formatSGSeatAvailabilityData(res?.value?.data?.data),
        ]);
      } else {
        console.log("Error at: ", index, " reason: ", res?.reason);
      }
    });

  };



  return {
    // payment related
    paymentForm,
    isPaymentFormVisible,
    setIsPaymentFormVisible,
    confirmCancelPayment,
    setConfirmCancelPayment,

    // booking flow related
    sellTicket,
    getSeatAvailability,
    seatList,
    updatePassenger,
    addPayment,
    commitBooking,
    isSelling,
    isPassengerUpdated,
    isPaymentDone,
    setIsPaymentDone,
    isPaymentProcessing,
    isSessionExpired,
    agentAddOnMarkup,
    addAgentAddOnMarkup,
    isUpdatingPassengers
  };
};

export default useFlightSearch;

