import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
// @ts-ignore
// import DatePicker from "react-datepicker";

// import InputField from '@kiwicom/orbit-components/lib/InputField';
import Stack from "@kiwicom/orbit-components/lib/Stack";
import Text from "@kiwicom/orbit-components/lib/Text";
import { FaChild, FaPlusCircle } from "react-icons/fa";

import IStepper from "../../../../components/Common/Stepper/Stepper";
import CardBase from "../../../../components/Common/CardBase/CardBase";
import DateRangePicker from "../../../../components/FlightModal/DateRangePicker/DateRangePicker";
import Input2 from "../../../../components/Common/Input/Input2";
// import DepartureReturnDatePicker from '../../../../components/FlightModal/DepartureReturnDatePicker/DepartureReturnDatePicker';
// import Input from '../../../../components/Common/Input/Input';
// import Button from '../../../../components/Common/Button/Button';
// import SectionTitle from '../../../../components/Common/SectionTitle/SectionTitle';

import "react-datepicker/dist/react-datepicker.css";

import useHotel from "../../../../utils/hooks/Hotel/useHotel";
import { IHotelWhereTo, State } from "../../../../redux/types";
import { initialState } from "../../../../redux/initialState";
import DestinationSearch from "../../../../components/Common/Input/DestinationSearch";
import DatePicker from "../../../../components/FlightModal/DatePicker";


const ExampleCustomInput = forwardRef(({ value, onClick }: { value: any; onClick: any }, ref: ForwardedRef<any>) => (
  <button className="example-custom-input" onClick={onClick} ref={ref}>
    {value}
  </button>
));


export default function SearchHotelData() {

  const { searchHotel } = useSelector((state: State) => state.hotel);

  const {
    resetFilters,
    updateNoOfRooms,
    removeRoomGuest,
    updateRoomGuest,
    updateHotelSearch,
    resetRoomGuestInfo,
    resetSearchedHotelList,
    handleResetHotelFilters,
    handleHotelListingInfoReset
  } = useHotel();


  const [showRoomInfo, setShowRoomInfo] = useState<boolean>(false); // show or hide the room info

  useEffect(() => {

    // reset form on page load
    resetFilters();
    resetRoomGuestInfo();
    handleResetHotelFilters();
    handleHotelListingInfoReset();
    resetSearchedHotelList("HOTELBEDS");
    // resetRoomGuest();
    
    if (
      searchHotel.checkInDate?.length < 2 &&
      searchHotel.checkOutDate?.length < 2
    ) {
      updateHotelSearch("checkInDate", moment().format("YYYY-MM-DD"));
      updateHotelSearch(
        "checkOutDate",
        moment().add(1, "d").format("YYYY-MM-DD")
      );
    };

  }, []);

  useEffect(() => {
    console.log("Filter");
  }, [searchHotel.RoomGuest]);


  return (
    <React.Fragment>
      {/* <SectionTitle
        title={"Search Hotels"}
        subTitle={"Search for best hotels in India"}
      /> */}
      <div className="search-hotel-form">
        <DestinationSearch allowLocationSearch={false} />


        <div className="departure_return">
          <div style={{ width: "49%" }}>
            {/* <DatePicker
              style={{ width: "100%" }}
              value={searchHotel.checkInDate}
              onChange={(date: any) => console.log(date)}
              customInput={
                <div
                  className="py-1 px-2 overflow-hidden border-1 border shadow-none rounded"
                  style={{
                    height: "60px"
                  }}
                >
                  <span className="small text-muted">{searchHotel.checkInDate ? searchHotel.checkInDate : "Check-In"}</span>
                  {
                    searchHotel.checkInDate ? (
                      <p className="fw-bold">
                        {searchHotel.checkInDate}
                      </p>
                    ) : (
                      <p className="text-muted ms-auto"></p>
                    )
                  }
                </div>
              }
            /> */}

            <DatePicker
              label="Check-In"
              value={searchHotel.checkInDate}
              onChange={(date) => {
                updateHotelSearch("checkInDate", date);
                updateHotelSearch("checkOutDate", moment(date).add(1, "d").format("YYYY-MM-DD"));
              }}
            // minDate={searchHotel.checkInDate ? searchHotel.checkInDate : moment().format("YYYY-MM-DD")}
            // maxDate={searchHotel.checkOutDate ? searchHotel.checkOutDate : moment().add(1, "d").format("YYYY-MM-DD")}
            />
          </div>
          <div style={{ width: "49%", marginLeft: "2%" }}>
            {/* <DatePicker
              style={{ width: "100%" }}
              value={searchHotel.checkOutDate}
              onChange={(date: any) => console.log(date)}
              customInput={
                <div
                  className="py-1 px-2 overflow-hidden border-1 border shadow-none rounded"
                  style={{
                    height: "60px"
                  }}
                >
                  <span className="small text-muted">{searchHotel.checkOutDate ? searchHotel.checkOutDate : "Check-Out"}</span>
                  {
                    searchHotel.checkOutDate ? (
                      <p className="fw-bold">
                        {searchHotel.checkOutDate}
                      </p>
                    ) : (
                      <p className="text-muted ms-auto"></p>
                    )
                  }
                </div>
              }
            /> */}
            <DatePicker
              label="Check-Out"
              value={searchHotel.checkOutDate}
              onChange={(date) => updateHotelSearch("checkOutDate", date)}
              minDate={searchHotel.checkInDate ? moment(searchHotel.checkInDate).add(1, "d").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
            />
          </div>
        </div>

        {/* <DateRangePicker
          departure={{
            value: searchHotel.checkInDate, // || moment().format("YYYY-MM-DD")
            label: "Check-In",
            onChange: (value) => updateHotelSearch("checkInDate", value),
          }}
          returnDate={{
            value: searchHotel.checkOutDate, // || moment().add(1, "d").format("YYYY-MM-DD"),
            label: "Check-Out",
            onChange: (value) => updateHotelSearch("checkOutDate", value),
            minDate:
              searchHotel.checkInDate ||
              moment().add(1, "d").format("YYYY-MM-DD"),
          }}
        /> */}

        {/* No of Rooms & Guests */}
        <div className="hotel-room-input rounded w-100">
          <div
            // onBlur={() => setShowRoomInfo(false)}
            onMouseLeave={() => setShowRoomInfo(false)}
          >
            <div
              className="border rounded py-1 px-2"
              style={{
                height: "56px",
              }}
              onClick={() => setShowRoomInfo(true)}
            >
              <div className="d-flex flex-column">
                <span className="small text-muted">Room & Guests</span>
                <p className="fw-bold">
                  {`Rooms: ${searchHotel.noOfRooms
                    }, Adults: ${searchHotel.RoomGuest?.reduce(
                      (accu, curr) => (accu += Number(curr?.NoOfAdults)),
                      0
                    )}, Children: ${searchHotel.RoomGuest?.reduce(
                      (accu, curr) => (accu += Number(curr?.NoOfChild)),
                      0
                    )}`}
                </p>
              </div>
            </div>

            {showRoomInfo
              ?
              (
                <div
                  className="room-card"
                // onMouseLeave={() => setShowRoomInfo(false)}
                >
                  <CardBase title={"Rooms & Guests"}>
                    {[...Array(+searchHotel.noOfRooms)].map((_, index) => (
                      <React.Fragment key={index}>
                        <div className="d-flex justify-content-between">
                          <h5>Room {index + 1}</h5>
                          {index ? (
                            <span
                              className="small remove-room"
                              onClick={() => removeRoomGuest(index)}
                            >
                              Remove
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <Stack>
                          <React.Fragment key=".0">
                            <Stack align="center">
                              <Stack spacing="none">
                                <Text>Adults</Text>
                                <Text type="secondary" size="small">
                                  Above 11 years
                                </Text>
                              </Stack>
                              <IStepper
                                minValue={1}
                                maxValue={8}
                                value={Number(
                                  searchHotel.RoomGuest[index]?.NoOfAdults
                                )}
                                onChange={(value) => {
                                  updateRoomGuest(index, "NoOfAdults", value);
                                }}
                              />
                            </Stack>
                            <Stack align="center">
                              <Stack spacing="none">
                                <Text>Children</Text>
                                <Text type="secondary" size="small">
                                  1-11 years
                                </Text>
                              </Stack>
                              <IStepper
                                minValue={0}
                                maxValue={2}
                                value={Number(
                                  searchHotel.RoomGuest[index]?.NoOfChild
                                )}
                                onChange={(value) => {
                                  if (
                                    +searchHotel.RoomGuest[index]?.NoOfChild >
                                    value
                                  ) {
                                  }
                                  updateRoomGuest(index, "NoOfChild", value);
                                }}
                              />
                            </Stack>

                            {/* <div className="spacer spacer--sm" /> */}
                            {searchHotel.RoomGuest[index]?.NoOfChild ? (
                              <span className="small text-muted my-2">
                                *To find you a place to stay that fits your entire
                                group along with correct prices, we need to know
                                how old your children will be at check-out
                              </span>
                            ) : (
                              <></>
                            )}
                            {/* Child age dropdowns */}
                            <div>
                              {[
                                ...Array(
                                  +searchHotel.RoomGuest[index]?.NoOfChild
                                ),
                              ]?.map((_, i) => (
                                <div
                                  className="d-flex justify-content-between align-items-center mb-2"
                                  key={i}
                                >
                                  <span className="small w-50 d-flex align-items-center">
                                    <FaChild className="text-" />
                                    Child {i + 1}
                                  </span>
                                  <select
                                    id="title"
                                    className="form-select"
                                    style={{ height: "40px" }}
                                    aria-label="Default select example"
                                    value={
                                      searchHotel.RoomGuest[index]?.ChildAge[i]
                                        ? searchHotel.RoomGuest[index]?.ChildAge[
                                        i
                                        ]
                                        : 0
                                    }
                                    onChange={function (e: any) {
                                      updateRoomGuest(
                                        index,
                                        "ChildAge",
                                        Number(e.target.value),
                                        i
                                      );
                                    }}
                                  >
                                    <option value="0">Select Age</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="7">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                  </select>
                                </div>
                              ))}
                            </div>
                          </React.Fragment>
                        </Stack>
                        <hr />
                      </React.Fragment>
                    ))}
                    {searchHotel.noOfRooms <= 5 ? (
                      <div
                        className="small add-room"
                        onClick={() => updateNoOfRooms("add")}
                      >
                        Add room <FaPlusCircle />
                      </div>
                    ) : (
                      <></>
                    )}
                  </CardBase>
                </div>
              )
              :
              <></>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
