import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import { SERVER_API_URL } from './../../utils/constants';
import { IVerifyOrderResponse } from '../../utils/hooks/Payment/usePayment';
import { ICreateBooking, IHotelDetailsToBeStored, TTicketStatus } from '../../utils/types/hotelTypes';
import { getTotalGuestCountForHotel } from '../../utils/utilityFunctions/hotelUtitlityFunctions';
import { getMarkedUpPrice, getMyChargesAmount } from '../../utils/utilityFunctions/utilityFunction';
import { ICombination, IMetaHotel, ISearchHotelInfo, ISelectedHotel, ITBORoom } from './../../redux/types';
import { IClientData, IHotelCharges, IPassenger, IStaticHotelRoomInfo, ITBOHotelDetails } from '../../redux/types';

interface IHBPostBookHotel {
    createBooking: (
        onSuccessDetails: IVerifyOrderResponse,
        ticketStatus: TTicketStatus,
        clientData: IClientData,
        Client: {
            id: number;
            name: string;
        } | null,
        useId: number,
        metaData: IMetaHotel,
        selectedRoomCombinations: ICombination,
        staticRoomInfo: IStaticHotelRoomInfo[],
        // hotel: IHotel,
        searchHotel: ISearchHotelInfo,
        searchedHotel: ISelectedHotel,
        passenger: IPassenger[][],
        bookingReferenceId: string,
        clientReference: string | null,
        ticketSupplierName: string | null,
        ticketSupplierVatNumber: string | null
    ) => Promise<AxiosResponse>;

    fetchBookingInformation: (
        clientID: number,
        ticketID: number
    ) => Promise<AxiosResponse>;

    fetchBookedHotelDetails: (ticketID: number) => Promise<AxiosResponse>;

    cancelBookedHotel: (ticketID: number) => Promise<AxiosResponse>;
};

interface ITBOPostBookHotel {
    createBooking: (
        clientData: IClientData,
        Client: {
            id: number;
            name: string;
        } | null,
        userId: number,
        metaData: IMetaHotel,
        selectedRoomCombinations: ITBORoom[],
        searchHotel: ISearchHotelInfo,
        searchedHotel: ITBOHotelDetails,
        passenger: IPassenger[][],
        bookingReferenceId: string,
        bookingRefNo: string
    ) => Promise<AxiosResponse>;
};


class SendVoucher {
    sendVoucherPayload = (
        hotelDetails: {
            HotelName: string;
            HotelAddress: string;
            HotelDestinationName: string;
            HotelCategory?: string;
            HotelPhoneNumber?: string;
        },
        passengerDetails: {
            leadPassenger: string;
            passengerDetails: IPassenger[][]; // at least one pax per room has to be mentioned. If child is present in room then mention child age as well
        },
        bookingDetails: {
            HotelbedsBookingReference: string | number;
            CheckInDate: string;
            CheckOutDate: string;
            RoomType: string;
            BoardType: string;
            RateComments?: string;
        }
    ) => {
        // console.log("Voucher details are: ", { hotelDetails, passengerDetails, bookingDetails });
    };
}

class HBPostBookHotel extends SendVoucher implements IHBPostBookHotel {

    // URL = `${SERVER_API_URL}Hotel/`;

    createBooking = (
        onSuccessDetails: IVerifyOrderResponse,
        ticketStatus: TTicketStatus,
        clientData: IClientData,
        Client: {
            id: number;
            name: string;
        } | null,
        userId: number,
        metaData: IMetaHotel,
        selectedRoomCombinations: ICombination,
        staticRoomInfo: IStaticHotelRoomInfo[],
        // hotel: IHotel,
        searchHotel: ISearchHotelInfo,
        searchedHotel: ISelectedHotel,
        passenger: IPassenger[][],
        bookingReferenceId: string,
        clientReference: string | null,
        ticketSupplierName: string | null,
        ticketSupplierVatNumber: string | null
    ) => {

        // calculating billing info based on selected rooms
        // let published_price_per_room: number[] = selectedRoomCombinations.combination.map(each => +each.net);
        let total_TAX: number = 0;
        let total_GST: number = 0;

        let supplierName = metaData.BookingSupplier?.find(
            each => each?.SupplierName?.toLowerCase() === "hotelbeds")?.SupplierName
            ||
            metaData.BookingSupplier[0].SupplierName;

        // calculation my charges based on data received from my charges API request
        // let myCharges: number[] = selectedRoomCombinations.combination?.map((room: IDynamicHotelRate, index) => {
        //     return getMyChargesAmount(
        //         clientData.MyCharges,
        //         +room.net,
        //         Number(searchHotel.RoomGuest[index].NoOfAdults) + Number(searchHotel.RoomGuest[index].NoOfChild),
        //         selectedRoomCombinations.combination.length
        //     );
        // });
        let myCharges = getMyChargesAmount(
            clientData.MyCharges,
            selectedRoomCombinations.combination.reduce((accu, curr) => accu += Number(curr.net), 0),
            getTotalGuestCountForHotel(searchHotel.RoomGuest),
            selectedRoomCombinations.combination.length
        );

        // fetching markup price
        // let temp_markup = published_price_per_room.map(
        //     (price) => getMarkedUpPrice(
        //         metaData.markUps,
        //         price,
        //         supplierName,
        //         clientData.GSTRates[0]?.Value,
        //         metaData.affiliateMarkup,
        //         getTotalGuestCountForHotel(searchHotel.RoomGuest)
        //     ));

        // let markup_charges: {
        //     Base: number;
        //     baseMarkup: number;
        //     Service: number;
        //     Booking: number;
        //     GST: number;
        // } = {
        //     Base: 0,
        //     baseMarkup: 0,
        //     Service: 0,
        //     Booking: 0,
        //     GST: 0
        // };
        // temp_markup.forEach((markup, index) => {
        //     markup_charges.Base += (markup.Base - markup.GST) || temp_markup[index].Base;
        //     markup_charges.baseMarkup += markup.baseMarkup;
        //     markup_charges.Service += markup.Service;
        //     markup_charges.Booking += markup.Booking;
        //     markup_charges.GST += markup.GST;
        // });
        // total_GST += markup_charges.GST;

        let markup_charges: {
            Base: number;
            baseMarkup: number;
            Service: number;
            Booking: number;
            GST: number;
        } = getMarkedUpPrice(
            metaData.markUps,
            selectedRoomCombinations.combination.reduce((accu, curr) => accu += Number(curr.net), 0),
            supplierName,
            clientData.GSTRates[0]?.Value,
            metaData.affiliateMarkup,
            searchHotel.RoomGuest
        ).markedUpPrice;
        total_GST += markup_charges.GST;

        // stores the first(lead) passenger details from all room
        let travelers = passenger.map((pass: IPassenger[]) => pass[0]);

        /* 
        * referenceNumber is based on response received from the book hotel request.
       */
        // let referenceNumber = bookingReferenceId;

        // construct data for lead passengers(guests) from each room
        let leadPassengers = travelers?.map((each: IPassenger, index) => {
            let markup_fare = getMarkedUpPrice(
                metaData.markUps,
                +selectedRoomCombinations.combination[index]?.net,
                supplierName,
                clientData.GSTRates[0]?.Value,
                metaData.affiliateMarkup,
                searchHotel.RoomGuest
            ).markedUpPrice;
            // console.log("Markup for room: ", markup_fare);
            let total_amount = ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking + 0).toFixed(2);
            return Object.assign({
                BaseFare: ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking).toFixed(2),
                TotalAmount: total_amount,
                TaxesAndCharges: 0,
                Title: each?.Title,
                FirstName: each?.FirstName,
                LastName: each?.LastName,
                NoOfAdults: searchHotel.RoomGuest[index].NoOfAdults,
                NoOfChildren: searchHotel.RoomGuest[index].NoOfChild,
                RoomNo: String(index + 1),
                RoomDetails: selectedRoomCombinations.combination[index]?.boardName,
            });
        });


        // construct data for all passengers(guests) from all rooms including the lead passengers(guests)
        let allGueatsInfo = passenger?.map((each: IPassenger[], index) => (
            each.map((eachPassenger: IPassenger) => {

                let markup_fare = getMarkedUpPrice(
                    metaData.markUps,
                    +selectedRoomCombinations.combination[index]?.net,
                    supplierName,
                    clientData.GSTRates[0]?.Value,
                    metaData.affiliateMarkup,
                    searchHotel.RoomGuest
                ).markedUpPrice;
                let total_amount = ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking + 0).toFixed(2);

                return Object.assign({
                    BaseFare: ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking).toFixed(2),
                    TotalAmount: total_amount,
                    TaxesAndCharges: 0,
                    GuestName: eachPassenger?.Title + " " + eachPassenger?.FirstName + " " + eachPassenger?.LastName,
                    Title: eachPassenger?.Title,
                    FirstName: eachPassenger?.FirstName,
                    LastName: eachPassenger?.LastName,
                    Age: eachPassenger?.Age ? +eachPassenger.Age : 0,
                    NoOfAdults: searchHotel.RoomGuest[index].NoOfAdults,
                    NoOfChildren: searchHotel.RoomGuest[index].NoOfChild,
                    RoomNo: String(index + 1),
                    RoomDetails: selectedRoomCombinations.combination[index]?.boardName,
                });

            })
        )).flat();

        // construct data for all type of charges
        let allCharges = metaData?.Charges?.map((eachCharge: IHotelCharges) => {
            return Object.assign({
                TicketChargeCalculationTypeId: eachCharge.TicketChargeCalculationTypeId,
                TicketChargeTypeId: eachCharge.TicketChargeTypeId,
                ChargeName: eachCharge.ChargeName,
                Amount: eachCharge.TicketChargeTypeName === "Markup"
                    ?
                    +(markup_charges.baseMarkup + markup_charges.Service + markup_charges.Booking).toFixed(2)
                    :
                    eachCharge.TicketChargeTypeName === "Affiliate Markup"
                        ?
                        0
                        :
                        eachCharge.TicketChargeTypeName === "My Other Charges"
                            ?
                            // my charges calculation
                            // myCharges.reduce((acc, curr) => acc + curr, 0).toFixed(2)

                            // myCharges
                            0
                            :
                            eachCharge.TicketChargeTypeName === "Service Charges"
                                ?
                                myCharges // 0
                                :
                                eachCharge.TicketChargeTypeName === "GST"
                                    ?
                                    total_GST
                                    :
                                    eachCharge.TicketChargeTypeName === "Discount"
                                        ?
                                        0
                                        :
                                        0
            });
        });


        let bookingAndTicketSupplier = metaData.BookingSupplier.filter(
            each => each?.SupplierName?.toLowerCase() === "hotelbeds")[0]?.SupplierId
            ||
            metaData.BookingSupplier[0]?.SupplierId;

        const hotelName = searchedHotel.hotel_dynamic_data?.name || searchedHotel.hotel_static_data.name?.content;
        const hotelDescription = searchedHotel.hotel_static_data.description?.content || null;
        const hotelAddress = searchedHotel.hotel_static_data.address?.content || null;
        const hotelPostalCode = searchedHotel.hotel_static_data?.postalCode || null;
        const email = searchedHotel.hotel_static_data?.email?.trim() || "";
        const contactNumbers = searchedHotel.hotel_static_data?.phones?.map(each => each.phoneNumber || "")?.join(", ");
        const hotelInfo = `${hotelName} | Email: ${email} | Phone: ${contactNumbers}`;

        const roomNames = staticRoomInfo.map(eachRoom => eachRoom?.roomTypeName || "");
        const boardCode = selectedRoomCombinations.combination.map(eachCombination => eachCombination?.boardCode || "");
        const boardName = selectedRoomCombinations.combination.map(eachCombination => eachCombination?.boardName || "");
        const rateComments = selectedRoomCombinations.combination.map(eachCombination => eachCombination?.rateComments || "");
        const promotionCode = selectedRoomCombinations.combination.map(
            (eachCombination) => eachCombination.promotions?.map(
                (eachPromotion) => `${eachPromotion?.code})`
            )
        );
        const promotionName = selectedRoomCombinations.combination.map(
            (eachCombination) => eachCombination.promotions?.map(
                (eachPromotion) => `${eachPromotion?.name} - (${eachPromotion?.remark})`
            )
        );
        const cancellationPolicies = selectedRoomCombinations.combination.map(
            (eachCombination) => eachCombination?.cancellationPolicies || []
        );

        // console.log("selectedRoomCombinations: ", selectedRoomCombinations);
        // console.log("cancellationPolicies: ", cancellationPolicies);

        // hotel information to be stored
        const hotelInformation: IHotelDetailsToBeStored = {
            SupplierName: ticketSupplierName || null, // "HOTELBEDS PRODUCT,S.L.U.", // make it dynamic
            SupplierVatNumber: ticketSupplierVatNumber || null, // "ESB38877676", // make it dynamic
            BookingReferenceNumber: bookingReferenceId || null, // "B_REF_NO", // make it dynamic
            ClientReferenceNumber: clientReference || null, // "C_REF_NO", // make it dynamic
            HotelName: hotelName,
            HotelAddress: hotelAddress,
            HotelDescription: hotelDescription,
            HotelEmail: email,
            HotelContactNumber: contactNumbers,
            HotelPostalCode: hotelPostalCode,
            HotelCategory: searchedHotel.hotel_dynamic_data?.categoryName || null,
            RoomName: JSON.stringify(roomNames),
            BoardCode: JSON.stringify(boardCode),
            BoardName: JSON.stringify(boardName),
            RateComments: JSON.stringify(rateComments),
            PromotionCode: JSON.stringify(promotionCode),
            PromotionName: JSON.stringify(promotionName),
            CancellationPolicies: JSON.stringify(cancellationPolicies) || null,
            Website: searchedHotel.hotel_static_data.web || null,
            BookingSupplier: String(bookingAndTicketSupplier),
            TicketedSupplier: String(bookingAndTicketSupplier),
            OrderId: onSuccessDetails?.order_id || null
        };

        // construct data for create booking request at .net server
        let create_booking_payload: ICreateBooking = {
            TicketStatus: ticketStatus,
            NetpayableAmount: +(markup_charges.Base + markup_charges.Service + markup_charges.Booking + total_TAX + total_GST).toFixed(2),
            ClientId: Client!.id,
            CustomerId: userId,
            TaxAmount: total_TAX,
            Remarks: "",
            HotelName: hotelInfo,
            HotelAddress: hotelAddress || "Not Available",
            BookingDate: new Date().toISOString(),
            CheckInDate: moment(searchHotel.checkInDate + "T00:00:00.000Z").toISOString(),
            CheckOutDate: moment(searchHotel.checkOutDate + "T00:00:00.000Z").toISOString(),
            // BookingSupplier: metaData.BookingSupplier[0]?.SupplierId,
            // TicketedSupplier: metaData.BookingSupplier[0]?.SupplierId,
            BookingSupplier: bookingAndTicketSupplier,
            TicketedSupplier: bookingAndTicketSupplier,
            HotelReservationID: bookingReferenceId,
            HotelID: +searchedHotel.hotel_dynamic_data.code,
            lstTravellers: leadPassengers,
            _lstHotelGuests: allGueatsInfo,
            lstCharges: allCharges,
            HotelDetails: hotelInformation
        };

        console.log("-------------------------------------------------------------------------------");
        console.log("Create booking payload", create_booking_payload);
        console.log("-------------------------------------------------------------------------------");

        return axios.post(`${SERVER_API_URL}Hotel/V2/CreateBooking`, create_booking_payload);
        // return axios.get("https://api.spacexdata.com/v3/rockets");

    };

    fetchBookingInformation = (clientID: number, ticketID: number) => {

        return axios.get(`${SERVER_API_URL}Customer/GetTicketDetails/${clientID}/${ticketID}`);

    };

    fetchBookedHotelDetails = (ticketID: number) => {

        return axios.get(`${SERVER_API_URL}Hotel/GetHotelDetailByTicketId/${ticketID}`);

    };

    cancelBookedHotel = (ticketID: number) => {

        return axios.post(`${SERVER_API_URL}Customer/CancelTicketBooking/${ticketID}`);

    };

};

class TBOPostBookHotel extends SendVoucher implements ITBOPostBookHotel {
    createBooking = (
        clientData: IClientData,
        Client: {
            id: number;
            name: string;
        } | null,
        userId: number,
        metaData: IMetaHotel,
        selectedRoomCombinations: ITBORoom[],
        searchHotel: ISearchHotelInfo,
        searchedHotel: ITBOHotelDetails,
        passenger: IPassenger[][],
        bookingReferenceId: string,
        bookingRefNo: string,
    ) => {

        // calculating billing info based on selected rooms
        // let published_price_per_room: number[] = selectedRoomCombinations?.map(each => +each.Price.OfferedPrice);
        let total_TAX: number = selectedRoomCombinations?.reduce((acc, curr) => curr?.Price?.Tax + acc, 0) || 0;
        let total_GST: number = selectedRoomCombinations?.reduce((acc, curr) => curr?.Price?.TotalGSTAmount + acc, 0) || 0;

        let supplierName = metaData.BookingSupplier?.find(
            each => each?.SupplierName?.toLowerCase() === "tbo")?.SupplierName
            ||
            metaData.BookingSupplier[0].SupplierName;

        // calculation my charges based on data received from my charges API request
        // let myCharges: number[] = selectedRoomCombinations?.map((room, index) => {
        //     return getMyChargesAmount(
        //         clientData.MyCharges,
        //         +room.Price.OfferedPrice,
        //         Number(searchHotel.RoomGuest[index].NoOfAdults) + Number(searchHotel.RoomGuest[index].NoOfChild),
        //         selectedRoomCombinations.length
        //     );
        // });
        let myCharges = getMyChargesAmount(
            clientData.MyCharges,
            selectedRoomCombinations.reduce((accu, curr) => accu += Number(curr.Price.OfferedPrice), 0),
            getTotalGuestCountForHotel(searchHotel.RoomGuest),
            selectedRoomCombinations.length
        );

        // fetching markup price
        // let temp_markup = published_price_per_room.map(
        //     (price) => getMarkedUpPrice(
        //         metaData.markUps,
        //         price,
        //         supplierName,
        //         clientData.GSTRates[0]?.Value,
        //         metaData.affiliateMarkup,
        //         searchHotel.RoomGuest
        //     ).markedUpPrice
        // );

        // let markup_charges: {
        //     Base: number;
        //     baseMarkup: number;
        //     Service: number;
        //     Booking: number;
        //     GST: number;
        // } = {
        //     Base: 0,
        //     baseMarkup: 0,
        //     Service: 0,
        //     Booking: 0,
        //     GST: 0
        // };
        // temp_markup.forEach((markup, index) => {
        //     markup_charges.Base += (markup.Base - markup.GST) || temp_markup[index].Base;
        //     markup_charges.baseMarkup += markup.baseMarkup;
        //     markup_charges.Service += markup.Service;
        //     markup_charges.Booking += markup.Booking;
        //     markup_charges.GST += markup.GST;
        // });
        // total_GST += markup_charges.GST;

        let markup_charges: {
            Base: number;
            baseMarkup: number;
            Service: number;
            Booking: number;
            GST: number;
        } = getMarkedUpPrice(
            metaData.markUps,
            selectedRoomCombinations.reduce((accu, curr) => accu += Number(curr.Price.OfferedPrice), 0),
            supplierName,
            clientData.GSTRates[0]?.Value,
            metaData.affiliateMarkup,
            searchHotel.RoomGuest
        ).markedUpPrice;
        total_GST += markup_charges.GST;

        // console.log("Temp Markup", temp_markup);
        // console.log("Markup Charges", markup_charges);

        // stores the first(lead) passenger details from all room
        let travelers = passenger.map((pass: IPassenger[]) => pass[0]);

        /* referenceNumber is based on response received from the book hotel request. */
        // let referenceNumber = bookingReferenceId;

        /* referenceNumber is based on response received from the book hotel request.
        "BookingRefNo" containes a string which includes numbers(one for each of the rooms) separeted by a " , "
        We are spliting the string and creating an array which contains all "BookingRefNo" */
        let referenceNumber = bookingRefNo?.split(",");
        let roomNumbers = Array.from(Array(referenceNumber.length + 1).keys()).slice(1);
        // console.log("REFERENCE NUMBER: -> ", referenceNumber, " - ", roomNumbers);


        // construct data for lead passengers(guests) from each room
        let leadPassengers = travelers?.map((each: IPassenger, index) => {
            let markup_fare = getMarkedUpPrice(
                metaData.markUps,
                +selectedRoomCombinations[index]?.Price.OfferedPrice,
                supplierName,
                clientData.GSTRates[0]?.Value,
                metaData.affiliateMarkup,
                searchHotel.RoomGuest
            ).markedUpPrice;
            // console.log("Markup for room: ", markup_fare);
            let total_amount = ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking + selectedRoomCombinations[index]?.Price?.Tax).toFixed(2);
            return Object.assign({
                BaseFare: ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking).toFixed(2),
                TotalAmount: total_amount,
                TaxesAndCharges: selectedRoomCombinations[index]?.Price?.Tax || 0,
                Title: each?.Title,
                FirstName: each?.FirstName,
                LastName: each?.LastName,
                NoOfAdults: searchHotel.RoomGuest[index].NoOfAdults,
                NoOfChildren: searchHotel.RoomGuest[index].NoOfChild,
                RoomNo: String(index + 1),
                RoomDetails: selectedRoomCombinations[index]?.RoomTypeName + " *Room Ref No: " + referenceNumber[index],
            });
        });

        // construct data for all passengers(guests) from all rooms including the lead passengers(guests)
        let allGueatsInfo = passenger?.map((each: IPassenger[], index) => (
            each.map((eachPassenger: IPassenger) => {
                let markup_fare = getMarkedUpPrice(
                    metaData.markUps,
                    +selectedRoomCombinations[index]?.Price?.OfferedPrice,
                    supplierName,
                    clientData.GSTRates[0]?.Value,
                    metaData.affiliateMarkup,
                    searchHotel.RoomGuest
                ).markedUpPrice;
                let total_amount = ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking + selectedRoomCombinations[index]?.Price?.Tax).toFixed(2)
                return Object.assign({
                    BaseFare: ((markup_fare.Base - markup_fare.GST) + markup_fare.Service + markup_fare.Booking).toFixed(2),
                    TotalAmount: total_amount,
                    TaxesAndCharges: selectedRoomCombinations[index]?.Price?.Tax,
                    GuestName: eachPassenger?.Title + " " + eachPassenger?.FirstName + " " + eachPassenger?.LastName,
                    Title: eachPassenger?.Title,
                    FirstName: eachPassenger?.FirstName,
                    LastName: eachPassenger?.LastName,
                    NoOfAdults: searchHotel.RoomGuest[index].NoOfAdults,
                    NoOfChildren: searchHotel.RoomGuest[index].NoOfChild,
                    RoomNo: String(index + 1),
                    RoomDetails: selectedRoomCombinations[index]?.RoomTypeName + " *Room Ref No: " + referenceNumber[index],
                })
            })
        )).flat();

        // construct data for all type of charges
        let allCharges = metaData?.Charges?.map((eachCharge: IHotelCharges) => {
            return Object.assign({
                TicketChargeCalculationTypeId: eachCharge.TicketChargeCalculationTypeId,
                TicketChargeTypeId: eachCharge.TicketChargeTypeId,
                ChargeName: eachCharge.ChargeName,
                Amount: eachCharge.TicketChargeTypeName === "Markup"
                    ?
                    +(markup_charges.baseMarkup + markup_charges.Service + markup_charges.Booking).toFixed(2)
                    :
                    eachCharge.TicketChargeTypeName === "My Other Charges"
                        ?
                        // my charges calculation
                        // myCharges.reduce((acc, curr) => acc + curr, 0).toFixed(2)
                        myCharges
                        :
                        eachCharge.TicketChargeTypeName === "Service Charges"
                            ?
                            0
                            :
                            eachCharge.TicketChargeTypeName === "GST"
                                ?
                                total_GST
                                :
                                eachCharge.TicketChargeTypeName === "Discount"
                                    ?
                                    0
                                    :
                                    0
            });
        });


        let bookingAndTicketSupplier = metaData.BookingSupplier.filter(
            each => each?.SupplierName?.toLowerCase() === "tbo")[0]?.SupplierId
            ||
            metaData.BookingSupplier[0]?.SupplierId;


        //TODO: hotel information to be stored
        const hotelInformation: IHotelDetailsToBeStored = {
            SupplierName: "",
            SupplierVatNumber: "",
            BookingSupplier: "",
            BookingReferenceNumber: "",
            ClientReferenceNumber: "",
            HotelName: "",
            HotelAddress: "",
            HotelDescription: "",
            HotelEmail: "",
            HotelContactNumber: "",
            HotelPostalCode: "",
            HotelCategory: "",
            RoomName: "",
            BoardCode: "",
            BoardName: "",
            RateComments: "",
            PromotionCode: "",
            PromotionName: "",
            Website: "",
            TicketedSupplier: "",
            OrderId: "",
            CancellationPolicies: ""
        };

        // construct data for create booking request at .net server
        let create_booking_payload: ICreateBooking = {
            TicketStatus: "Payment_Failed",
            NetpayableAmount: +(markup_charges.Base + markup_charges.Service + markup_charges.Booking + total_TAX + total_GST).toFixed(2),
            ClientId: Client!.id,
            CustomerId: userId,
            TaxAmount: total_TAX,
            Remarks: "",
            HotelName: searchedHotel?.HotelName || "Name is not available",
            HotelAddress: searchedHotel?.HotelAddress || "Not Available",
            BookingDate: new Date().toISOString(),
            CheckInDate: moment(searchHotel.checkInDate + "T00:00:00.000Z").toISOString(),
            CheckOutDate: moment(searchHotel.checkOutDate + "T00:00:00.000Z").toISOString(),
            // BookingSupplier: metaData.BookingSupplier[0]?.SupplierId,
            // TicketedSupplier: metaData.BookingSupplier[0]?.SupplierId,
            BookingSupplier: bookingAndTicketSupplier,
            TicketedSupplier: bookingAndTicketSupplier,
            HotelReservationID: bookingReferenceId,
            HotelID: +searchedHotel.HotelCode,
            lstTravellers: leadPassengers,
            _lstHotelGuests: allGueatsInfo,
            lstCharges: allCharges,
            HotelDetails: hotelInformation
        };

        console.group("Create booking payload");
        console.log(create_booking_payload, SERVER_API_URL + "Hotel/CreateBooking");
        console.groupEnd();

        // return axios.post(`${SERVER_API_URL}Hotel/CreateBooking`, create_booking_payload);
        return axios.get("https://api.spacexdata.com/v3/rockets");

    }
};


const postBookingHotel = new HBPostBookHotel();
const tboPostBookingHotel = new TBOPostBookHotel();
export { postBookingHotel, tboPostBookingHotel };
