import React, { useEffect } from 'react';

import SearchedHotelList from '../SearchedHotel/SearchedHotelList';

import { IHotelListingInfo } from '../../../utils/types/hotelTypes';
import Placeholder from '../../../components/Common/Placeholder/Placeholder';
import { IHotel } from '../../../redux/types';

interface IProps {
    hotel: IHotel;
    list: IHotelListingInfo[];
    isLoading: boolean;
};

export default function HotelListing({
    hotel,
    list,
    isLoading
}: IProps) {

    // useEffect(() => {console.log("List: ", list)}, [list]);

    return (
        <React.Fragment>
            {!isLoading && list?.length > 0
                ?
                <SearchedHotelList
                    isLoading={isLoading}
                    hotelList={list}
                    iHotel={hotel}
                />
                :
                <div className="mt-5 d-flex justify-content-center align-items-center">
                    <Placeholder
                        title="No Hotels Found"
                        illustration={"NoResults"}
                    />
                </div>}
        </React.Fragment>
    )
}
