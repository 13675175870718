import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import TextLink from "@kiwicom/orbit-components/lib/TextLink/index";

import "./tripSummary.scss";

import SingleTrip from "./Components/SingleTrip";
import SingleTrip1G from "./Components/SingleTrip1G";
import SingleTripAirIQ from "./Components/SingleTripAirIQ";

import { State } from "../../../../redux/types";
import CardBase from "../../../../components/Common/CardBase/CardBase";


const TripSummary = () => {

  const { searchInfo, selectedFlights, Itinerary } = useSelector((state: State) => state.flight);

  const [showDetailedInfo, setShowDetailedInfo] = useState<boolean>(false);


  return (
    <Fragment>
      {/* Trip Summary */}
      <div className=" bg-light" style={{ zIndex: 5 }}>
        <CardBase title="Trip Summary">
          <div className="row">
            {/* NAVITAIRE JOURNEYS */}
            {Itinerary?.Journeys?.map((journey, index) => (
              <div className="col" key={index + "_nav_journey"}>
                <SingleTrip
                  isDetailedShown={showDetailedInfo}
                  journey={journey}
                  // label="Departure"
                  departureDate={searchInfo?.departureDate[index]}
                />
              </div>
            ))}

            {/* AIRIQ JOURNEYS */}
            {selectedFlights?.filter(each => each.supplier === "airiq")?.map((journey, index) => (
              <div className="col" key={index + "_ai_journey"}>
                <SingleTripAirIQ
                  isDetailedShown={showDetailedInfo}
                  selectedFlight={journey}
                  // label="Departure"
                  departureDate={searchInfo?.departureDate[index]}
                />
              </div>
            ))}

            {/* 1g-Galileo JOURNEYS */}
            {selectedFlights?.filter(each => each.supplier === "1G")?.map((journey, index) => (
              <div className="col" key={index + "_1G_journey"}>
                <SingleTrip1G
                  isDetailedShown={showDetailedInfo}
                  selectedFlight={journey}
                  // label="Departure"
                  departureDate={searchInfo?.departureDate[index]}
                />
              </div>
            ))}

            {/* <div className="col">
            </div>
            {selectedFlight?.flight_2 !== null && (
              <div className="col">
                <SingleTrip
                  isDetailedShown={showDetailedInfo}
                  tripData={selectedFlight?.flight_2}
                  // label="Return"
                  departureDate={searchInfo?.departureDate[1]}
                />
              </div>
            )}
            {selectedFlight?.flight_3 !== null && (
              <div className="col">
                <SingleTrip
                  isDetailedShown={showDetailedInfo}
                  tripData={selectedFlight?.flight_3}
                  // label="Return"
                  departureDate={searchInfo?.departureDate[2]}
                />
              </div>
            )} */}
          </div>

          <div className="pt-4">
            <TextLink
              onClick={() => {
                setShowDetailedInfo((prev) => !prev);
              }}
              type="primary"
              size="small"
            >
              {showDetailedInfo ? "Hide" : "Show"} Details
            </TextLink>
            {/* </div> */}
          </div>
        </CardBase>
      </div>
      {/* Select Fare */}
      {/* <CardBase title={"Select your fare"}>
        <div className="row g-3">
          <div className="col-sm-12 col-md-6">
            <FareCard
              onFareSelect={() => {
                setSelectedFare(0);
              }}
              isSelected={selectedFare === 0}
              title={"Standard Fare"}
              price={
                selectedFlight?.flight_1?.fares[0]?.fare?.reduce(
                  (acc: number, f: any) => acc + Number(f?.Amount),
                  0
                ) +
                (selectedFlight?.flight_2 !== null
                  ? selectedFlight?.flight_2?.fares[0]?.fare?.reduce(
                      (acc: number, f: any) => acc + Number(f?.Amount),
                      0
                    )
                  : 0) +
                (selectedFlight?.flight_3 !== null
                  ? selectedFlight?.flight_3?.fares[0]?.fare?.reduce(
                      (acc: number, f: any) => acc + Number(f?.Amount),
                      0
                    )
                  : 0)
              }
              labels={[
                "Date change fee upto Rs. 2,000",
                "Cancellation Fee upto 3000",
                "Cancellation Fee upto 3000",
              ]}
            />
          </div>

          <div className="col-sm-12 col-md-6">
            <FareCard
              onFareSelect={() => {
                setSelectedFare(1);
              }}
              isSelected={selectedFare === 1}
              title={"Flexible"}
              price={
                selectedFlight.flight_1?.fares[1]?.fare?.reduce(
                  (acc: number, f: any) => acc + Number(f?.Amount),
                  0
                ) +
                (selectedFlight?.flight_2 !== null
                  ? selectedFlight?.flight_2?.fares[1]?.fare?.reduce(
                      (acc: number, f: any) => acc + Number(f?.Amount),
                      0
                    )
                  : 0) +
                (selectedFlight?.flight_3 !== null
                  ? selectedFlight?.flight_3?.fares[1]?.fare?.reduce(
                      (acc: number, f: any) => acc + Number(f?.Amount),
                      0
                    )
                  : 0)
              }
              labels={[
                "Date change fee is Nil",
                "Cancellation Fee Nil before 10 days of journey",
                "Cancellation Fee only 500",
              ]}
            />
          </div>
        </div>
      </CardBase> */}
      {/* Trip insurance */}
      {/* <CardBase title={"Secure your trip"}>
        <InsuranceCard
          onSelect={() => {}}
          title="Travel Insurance"
          subTitle="&#8377; 300 / person"
          listItems={[
            "Qui ea Lorem anim reprehenderit ullamco sit enim ullamco",
            "Mollit commodo consequat veniam fugiat laboris officia ea incididunqui",
            " Excepteur minim nisi nulla est aliqua aliquip laboris ut ut quiveniam est ea mollit",
            "Commodo est dolor consectetur laborum excepteur",
          ]}
        />
        <InsuranceCard
          onSelect={() => {}}
          title="Baggage Protection"
          subTitle="&#8377; 100 / person"
          listItems={[
            "Qui ea Lorem anim reprehenderit ullamco sit enim ullamco",
            "Mollit commodo consequat veniam fugiat laboris officia ea incididunqui",
            " Excepteur minim nisi nulla est aliqua aliquip laboris ut ut quiveniam est ea mollit",
            "Commodo est dolor consectetur laborum excepteur",
          ]}
          footerText="Officia ut mollit pariatur duis aute amet occaecat deserunt id
          sunt non proident."
        />
      </CardBase> */}
    </Fragment>
  );
};

export default TripSummary;
