import React, { useEffect, useState } from "react";

import KiwiWizard, { WizardStep } from "@kiwicom/orbit-components/lib/Wizard";

type Props = {
  onStepChange: (currentStep: number) => void;
  stepsTitle: string[];
  currentStep?: number;
};

const Wizard = ({ onStepChange, stepsTitle, currentStep }: Props) => {

  const [activeStep, setActiveStep] = useState<number>(0);
  const handleStepChange = (stepIndex: number) => {
    setActiveStep(stepIndex);
    onStepChange(stepIndex);
  };

  useEffect(() => {
    currentStep && setActiveStep(currentStep);
  }, [currentStep]);

// console.log("activeStep: ", activeStep);

  return (
    <div>
      <KiwiWizard
        activeStep={activeStep}
        completedSteps={activeStep}
        id="wizard"
        // onChangeStep={(step) => {
        //   handleStepChange(step);
        // }}
      >
        {stepsTitle.map((step, index) => (
          <div
            className="w-100"
            onClick={() => handleStepChange(index)}
            key={index}
          >
            <WizardStep title={step} onClick={() => handleStepChange(index)} />
          </div>
        ))}
      </KiwiWizard>
      {/* 
      <div className="custom-wizard">
        <div className="py-3 px-3">
          <div className="progress" style={{ height: "2px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${10}%`, height: "2px" }}
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between small text-muted">
          {stepsTitle.map((step, index) => {
            return (
              <div key={index} onClick={() => handleStepChange(index)}>
                <span onClick={() => handleStepChange(index)}>{step}</span>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default Wizard;
