import { useState, useEffect } from 'react'

// const useIntersection = (element: any, rootMargin: string) => {
//     const [isVisible, setState] = useState(false);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 setState(entry.isIntersecting);
//             }, { rootMargin }
//         );

//         element.current && observer.observe(element.current);

//         return () => observer.unobserve(element.current);
//     }, []);

//     return isVisible;
// };

// export default useIntersection;

export default function useOnScreen(ref: any) {

    const [isIntersecting, setIntersecting] = useState<boolean>(false)

    const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    )

    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    return isIntersecting
}
