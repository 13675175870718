import React from "react";

interface Props {}
const PMLPrivacyPolicy = (props: Props) => {
  return (
    <div className="container mx-auto my-4 ">
      <h2 className="text-primary">Privacy Policy - PML Holidays</h2>
      <ul className="list">
        <li className="py-2">
          PML Holidays (India) Private Limited (hereinafter “PML Holidays”)
          recognizes the importance of privacy of its users and also of
          maintaining confidentiality of the information provided by its users
          as a responsible data controller and data processer.
        </li>
        <li className="py-2">
          This Privacy Policy provides for the practices for handling and
          securing user's Personal Information (defined hereunder) by PML
          Holidays and its subsidiaries and affiliates.
        </li>
        <li className="py-2">
          This Privacy Policy is applicable to any person (‘User’) who purchase,
          intend to purchase, or inquire about any product(s) or service(s) made
          available by PML Holidays through any of PML Holidays’s customer
          interface channels including its website, mobile site, mobile app
          &amp; offline channels including call centers and offices
          (collectively referred herein as <strong>"Sales Channels"</strong>).
        </li>
        <li className="py-2">
          For the purpose of this Privacy Policy, wherever the context so
          requires "you" or "your" shall mean User and the term "we", "us",
          "our" shall mean PML Holidays. For the purpose of this Privacy Policy,
          Website means the website(s), mobile site(s) and mobile app(s).
        </li>
        <li className="py-2">
          By using or accessing the Website or other Sales Channels, the User
          hereby agrees with the terms of this Privacy Policy and the contents
          herein. If you disagree with this Privacy Policy please do not use or
          access our Website or other Sales Channels.
        </li>
        <li className="py-2">
          This Privacy Policy does not apply to any website(s), mobile sites and
          mobile apps of third parties, even if their websites/products are
          linked to our Website. User should take note that information and
          privacy practices of PML Holidays’s business partners, advertisers,
          sponsors or other sites to which PML Holidays provides hyperlink(s),
          may be materially different from this Privacy Policy. Accordingly, it
          is recommended that you review the privacy statements and policies of
          any such third parties with whom they interact.
        </li>
        <li className="py-2">
          This Privacy Policy is an integral part of your User Agreement with
          PML Holidays and all capitalized terms used, but not otherwise defined
          herein, shall have the respective meanings as ascribed to them in the
          User Agreement.
        </li>
      </ul>
    </div>
  );
};

export default PMLPrivacyPolicy;
