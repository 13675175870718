import { IProfile, State } from "./../../redux/types";
import { SERVER_API_URL } from "./../constants";
// import { randomIdGenerator } from "./../utilityFunctions/utilityFunction";
import {
  setProfileData,
  setUserData,
  userSlice,
} from "./../../redux/reducers/user";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError, AxiosResponse } from "axios";
import { commonStateSlice, showToast } from "../../redux/reducers/commonState";
import { flightSlice } from "../../redux/reducers/flight";
import useHotel from "../../utils/hooks/Hotel/useHotel";
import { useHistory } from "react-router-dom";

const useAuth = () => {
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetBookingPassenger, updateBookingPassenger } = useHotel();
  const { Client } = useSelector((state: State) => state.commonState);
  const { userId, token } = useSelector((state: State) => state.user);
  const login = async (
    formData: { Email: string; Password: string },
    isAgent: boolean
  ) => {
    setIsLoggingIn(true);

    try {
      const response = await axios.post(
        `${SERVER_API_URL}${isAgent ? "Agent" : "Customer"}/Login`,
        {
          ClientId: Client?.id,
          Username: formData.Email,
          Password: formData.Password,
        }
      );

      const { IsSuccess, ResponseData, StatusCode, Token } = response?.data;
      if (IsSuccess && StatusCode === 200) {
        dispatch(
          setUserData({
            firstName: ResponseData?.FirstName,
            lastName: ResponseData?.LastName,
            userId: isAgent
              ? ResponseData?.AffiliateId
              : ResponseData?.UserLoginID,
            email: formData.Email,
            phone: ResponseData?.Mobile,
            token: Token,
            isAgent,
          })
        );
        dispatch(commonStateSlice.actions.toggle("showSignInModal"));
        dispatch(
          flightSlice.actions.updatePersonalInfo({
            key: "email",
            value: formData.Email,
          })
        );
        dispatch(
          flightSlice.actions.updatePersonalInfo({
            key: "mobileNo",
            value: ResponseData?.Mobile,
          })
        );
        updateBookingPassenger("mobileNo", ResponseData?.Mobile);
        updateBookingPassenger("email", formData.Email);
        !isAgent &&
          getUserProfile(ResponseData?.ClientId, ResponseData?.UserLoginID);
        if (isAgent) {
          // clear search results if the user is logged in as agent.
          // because the markup data is different if the user is agent and needs
          // to be fetched again hence we restart the process.
          dispatch(flightSlice.actions.resetFlightData());
          history.push("/");
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          title: "Wrong Email or Password!",
          subTitle: "Please try again.",
          type: "error",
          time: 3,
        })
      );
    }
    setIsLoggingIn(false);
    // await new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(() => {});
    //     dispatch(
    //       setUserData({
    //         firstName: "Rohit",
    //         lastName: "Singh",
    //         userId: randomIdGenerator(16),
    //         email: "rksingh620@gmail.com",
    //         phone: "7352822874",
    //       })
    //     );
    //   }, 2000);
    // });
  };
  const registerUser = async (formData: {
    FirstName: string;
    LastName: string;
    Email: string;
    Mobile: string;
    Password: string;
  }) => {
    setIsRegistering(true);
    const response = await axios.post(`${SERVER_API_URL}Customer/Register`, {
      ClientId: Client!.id,
      FirstName: formData.FirstName,
      LastName: formData.LastName,
      Email: formData.Email,
      Mobile: formData.Mobile,
      Password: formData.Password,
    });
    const { ResponseData } = response?.data;
    if (ResponseData?.UserId === -1) {
      dispatch(
        showToast({
          title: "User already exists!",
          subTitle: "Please login with your credentials",
          type: "error",
          time: 3,
        })
      );
    } else {
      // login user after successfully registering
      login({ Email: formData?.Email, Password: formData?.Password }, false);
      // dispatch(
      //   setUserData({
      //     firstName: formData.FirstName,
      //     lastName: formData.LastName,
      //     userId: ResponseData?.UserId,
      //     email: formData.Email,
      //     phone: formData.Mobile,
      //     token: "",
      //   })
      // );
    }
    setIsRegistering(false);
  };

  const logout = () => {
    dispatch(userSlice.actions.logout());
    resetBookingPassenger(); // reset booking passenger info for hotel
    dispatch(
      showToast({
        title: "Logged out Successfully",
        subTitle: "see you again!",
        type: "info",
      })
    );
    history.push("/");
  };

  const getUserProfile = (cliendId: number, customerId: number) => {
    axios
      .get(
        `${SERVER_API_URL}Customer/GetCustomerProfile/${cliendId}/${customerId}`
      )
      .then((res: AxiosResponse) => {
        const { ResponseData } = res?.data;
        dispatch(
          setProfileData({
            CustomerId: ResponseData?.CustomerId,
            Title: ResponseData?.Title,
            FirstName: ResponseData?.FirstName,
            LastName: ResponseData?.LastName,
            Email: ResponseData?.Email,
            Phone: ResponseData?.Phone,
            Mobile: ResponseData?.Mobile,
            CountryId: ResponseData?.CountryId,
            StateId: ResponseData?.StateId,
            City: ResponseData?.City,
            Pincode: ResponseData?.Pincode,
            Address1: ResponseData?.Address1,
            Address2: ResponseData?.Address2,
          })
        );
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  const updateUserProfile = (formData: any) => {
    Object.assign(formData, { ClientId: Client?.id });
    // const headers = new Headers();
    // headers.append( "Content-Type", "application/json");
    // headers.append( "Authorization", "Bearer " + token);
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + token,
    // };

    const response = axios
      .post(`${SERVER_API_URL}Customer/UpdateProfile`, formData)
      .then((res: AxiosResponse) => {
        const { IsSuccess } = res?.data;
        if (IsSuccess) {
          dispatch(
            showToast({
              title: "Profile Updated Successfully",
              subTitle: "",
              type: "info",
            })
          );
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
        dispatch(
          showToast({
            title: "Something went wrong",
            subTitle: "",
            type: "error",
          })
        );
      });
  };

  return {
    login,
    isLoggingIn,
    registerUser,
    isRegistering,
    logout,
    updateUserProfile,
  };
};
export default useAuth;
