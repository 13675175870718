import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { FaClock } from "react-icons/fa";
import { Badge } from "@kiwicom/orbit-components";
import { FlightDirect } from "@kiwicom/orbit-components/lib/icons";

import { State } from "../../../redux/types";
import BsModal from "../../../components/Modal/BSModal";
import { constructFlightNumbersFor1GJourney } from "../../../utils/utilityFunctions/flight/flightUtilityFunctions";
import {
  IFLightOrigins,
  IFlightSearchData,
  TFlightResultList,
} from "../../../utils/types/flightTypes";

type Props = {
  flightData: TFlightResultList; // IFlightSearchData;
  show: boolean;
  onClose: () => void;
};

const FlightCardInfoModal = ({ flightData, show, onClose }: Props) => {

  const { metaData } = useSelector((state: State) => state.flight);

  const getAirportDetails = useCallback(
    (iataCode: string) => {
      return metaData?.FlightOrigins.find(
        (airport) => airport.RouteDestinationCode === iataCode
      );
    },
    [metaData?.FlightOrigins]
  );

  const BaggageInfoFor1G = useMemo(() => {
    return flightData["1GSegmentData"]?.[0]["1GPricingSolutions"][0]["FareInfo"]?.["air:BaggageAllowance"]?.["air:MaxWeight"];
  }, [flightData]);

  // console.log("flightData: ", flightData);
  // const data = flightData["1GSegmentData"]?.map((seg) =>
  //   seg["1GPricingSolutions"][0]["FareInfo"]?.["air:BaggageAllowance"]?.["air:MaxWeight"]
  // );
  // console.log("baggage data: ", data);
  // console.log("BaggageInfoFor1G: ", BaggageInfoFor1G);

  return (
    <>
      {
        show
          ?
          (
            <BsModal
              scrollable
              title={"Flight Information"}
              size={"lg"}
              onClose={onClose}
              position="top"
              body={
                <>
                  <h4>
                    {flightData?.airports?.map((seg: any, index: number) => (
                      <span key={index}>
                        {getAirportDetails(seg)?.City}
                        {index <
                          (flightData?.airports &&
                            flightData?.airports?.length - 1) ? (
                          <FlightDirect />
                        ) : (
                          <></>
                        )}
                      </span>
                    ))}
                  </h4>
                  <div className="d-flex align-items-center gap-1">
                    <img
                      src={
                        require(`../../../assets/${flightData?.carrierCode === "SG"
                          ?
                          "spicejet"
                          :
                          flightData?.carrierCode === "6E"
                            ?
                            "indigo"
                            :
                            flightData?.carrierCode === "QP"
                              ?
                              "akasaair"
                              :
                              "airway-placeholder"
                          }.png`)?.default
                      }
                      className="img rounded"
                      height={20}
                      width={20}
                      alt="airline"
                    />
                    <div className="d-flex align-items-center"></div>
                    {
                      flightData?.carrierCode === "SG"
                        ?
                        "Spicejet"
                        :
                        flightData?.carrierCode === "6E"
                          ?
                          "Indigo"
                          :
                          flightData?.carrierCode === "QP"
                            ?
                            "Akasaair"
                            :
                            constructFlightNumbersFor1GJourney(flightData.carrierCode, flightData.flightCode)
                    }
                    <div
                      className="bg-dark mx-2"
                      style={{ width: 4, height: 4, borderRadius: 4 }}
                    ></div>
                    {moment(flightData?.departureTime).format("DD, MMM YYYY")}
                  </div>

                  <div className="d-flex">
                    <div className="detailed_info w-50">
                      {flightData?.SegmentData?.map((segment, index) => {
                        let departure = segment?.departure;
                        let arrival = segment?.arrival;
                        let STA = arrival?.scheduled_time;
                        let STD = departure?.scheduled_time;
                        let nextSTD =
                          flightData?.SegmentData?.[index + 1]?.departure
                            ?.scheduled_time;
                        let segmentDiff = moment(STA).diff(STD, "minutes");
                        let layOver =
                          index < flightData?.SegmentData?.length - 1
                            ? moment(nextSTD).diff(STA, "minutes")
                            : -1;
                        // if city name is not match in DB airport then show the iata code itself coming from  supplier
                        let cityName =
                          getAirportDetails(departure?.iata_code)?.City ||
                          departure?.iata_code;
                        let arrivalCityName =
                          getAirportDetails(arrival?.iata_code)?.City ||
                          arrival?.iata_code;
                        return (
                          <React.Fragment key={index}>
                            <div className="items">
                              <div className="content">
                                <>
                                  <p className="mb-1 fw-semibold">
                                    {moment(STD).format("HH:mm")} - {cityName}
                                  </p>
                                  <p className="small text-muted d-flex align-items-center mb-0">
                                    {getAirportDetails(
                                      departure?.iata_code
                                    )?.RouteDestinationName?.slice(0, 35)}
                                    <div
                                      className="bg-dark mx-2"
                                      style={{
                                        width: 4,
                                        height: 4,
                                        borderRadius: 4,
                                      }}
                                    ></div>
                                    {departure?.iata_code}
                                  </p>
                                </>

                                <div className=" d-flex gap-2 mt-3">
                                  <Badge>
                                    {Math.floor(segmentDiff / 60)}h{" "}
                                    {Math.floor(segmentDiff % 60)}m flight
                                  </Badge>
                                  <Badge type="info">
                                    {segment?.carrierCode}-
                                    {segment?.flightNumber.length === 3 ? "0" : ""}
                                    {segment?.flightNumber}
                                  </Badge>
                                  {flightData.supplier === "airiq"
                                    ?
                                    <></>
                                    : <Badge>
                                      <span className="text-capitalize">
                                        Terminal {departure.terminal}
                                      </span>
                                    </Badge>}
                                </div>
                              </div>
                            </div>
                            {/* Leg end */}
                            <div className="items">
                              <div className="content">
                                <>
                                  <p className="mb-1 fw-semibold">
                                    {moment(STA).format("HH:mm")} -{" "}
                                    {arrivalCityName}
                                  </p>
                                  <p className="small text-muted d-flex align-items-center mb-0">
                                    {getAirportDetails(
                                      arrival?.iata_code
                                    )?.RouteDestinationName?.slice(0, 35)}
                                    <div
                                      className="bg-dark mx-2"
                                      style={{
                                        width: 4,
                                        height: 4,
                                        borderRadius: 4,
                                      }}
                                    ></div>
                                    {arrival?.iata_code}
                                  </p>
                                </>
                              </div>
                            </div>

                            {layOver !== -1 ? (
                              <div className="item_layover">
                                <div className="layover small">
                                  <FaClock />
                                  <div className="opacity-75">
                                    <strong>
                                      {Math.floor(layOver / 60)}h{" "}
                                      {Math.floor(layOver % 60)}m
                                    </strong>{" "}
                                    Stopover in {arrivalCityName}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>

                    <div className="w-50">
                      {
                        flightData.supplier === "airiq"
                          ?
                          <></>
                          :
                          flightData.supplier === "1G"
                            ?
                            <div className="">
                              <h5 className="fw-semibold">
                                Baggage{" "}
                                {/* <span className="text-muted fs-8">(Per Person)</span> */}
                              </h5>
                              <p className="d-flex gap-1 fs-6">
                                <span>{BaggageInfoFor1G?.["@Value"]}</span>
                                <span className="text-muted">{BaggageInfoFor1G?.["@Unit"]}</span>
                              </p>
                            </div>
                            :
                            <div className="">
                              <h5 className="fw-semibold">
                                Baggage{" "}
                                <span className="text-muted fs-8">(Per Person)</span>
                              </h5>
                              <p className="fs-6 my-1">
                                <span className="text-muted">Check In:</span>15kgs
                              </p>
                              <p className="d-flex gap-4 fs-6">
                                <span className="text-muted">Cabin:</span> 7kgs
                              </p>
                            </div>
                      }
                    </div>
                  </div>
                </>
              }
            />
          )
          :
          <></>
      }
    </>
  );

};

export default FlightCardInfoModal;
