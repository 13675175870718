import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../../redux/types";
import Logo from "../../../assets/images/logo.png";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
interface Props {}

const Footer = (props: Props) => {
  const { Client } = useSelector((state: State) => state.commonState);
  return (
    <footer className="pt-4 bg-dark text-white" style={{ height: "35vh" }}>
      <div className="container d-flex flex-column justify-content-between h-100">
        <img
          src={Logo}
          alt="logo"
          width="200px"
          style={{ filter: "invert(1)" }}
        />
        <ul className="list-inline opacity-75 pb-4">
          {[
            "About Us",
            "News & Updates",
            "Jobs",
            "Support",
            "Refund Policy",
            "Cancel Booking",
            "Member Login",
          ].map((item) => (
            <li key={item} className="list-inline-item px-2 pointer">
              {item}
            </li>
          ))}
        </ul>
        <div
          className="d-flex justify-content-between pt-2"
          style={{ borderTop: "1px solid #cccccc60" }}
        >
          <div className="d-flex justify-content-between gap-3 small">
            <span className="text-muted">©2021 {Client?.name} Pvt. Ltd.</span>
            <span
              className="text-muted pointer"
              onClick={() => window.open("/privacy-policy", "blank")}
            >
              Privacy Policy
            </span>
            <span
              className="text-muted pointer"
              onClick={() => window.open("/privacy-policy", "blank")}
            >
              Terms & Conditions
            </span>
          </div>
          <div className="socials">
            <FaFacebook
              size={24}
              className="mx-2 pointer"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/paulmerchantsofficial",
                  "blank"
                )
              }
            />
            <FaTwitter
              size={24}
              className="mx-2 pointer"
              onClick={() =>
                window.open("https://twitter.com/PaulMerchants1", "blank")
              }
            />
            <FaInstagram
              size={24}
              className="mx-2 pointer"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/paulmerchants/?hl=en",
                  "blank"
                )
              }
            />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
