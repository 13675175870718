import React, { FC, useState } from "react";
import { DateRangePicker as RangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import Modal from "../../Modal/Modal";
import { MdDateRange } from "react-icons/md";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { MutableRefObject, useEffect, useRef } from "react";

interface IDatePicker {
  onChange: (value: string) => void;
  value: string;
  label: string;
  minDate?: string | undefined;
  maxDate?: string | undefined;
  isDisabled?: boolean;
}

interface Props {
  departure: IDatePicker;
  returnDate: IDatePicker;
  label?: string;
  value?: string;
}
const DateRangePicker: FC<Props> = ({ returnDate, departure }) => {
  const [state, setState] = useState<
    { startDate?: Date; endDate?: Date; key?: string }[]
  >([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const target = useRef() as MutableRefObject<HTMLDivElement>;
  const modal = useRef() as MutableRefObject<any>;
  const scroller = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    scroller.current?.scroll(scroller.current?.scrollWidth, 0);
  }, [returnDate.value, departure?.value]);
  
  return (
    <>
      <div
        className="departure_return"
        style={{ boxShadow: "none" }}
        ref={target}
        tabIndex={0}
        onClick={() => {
          if (!target.current) return;
          modal.current.setModalPosition({
            x: target.current.offsetLeft,
            y: target.current.offsetTop,
          });
          modal.current.showModal();
        }}
      >
        <div className="departure_return_input w-100">
          <div className="departure input d-flex flex-column px-2 py-1">
            <span className="small text-muted p-0">{departure?.label}</span>
            <p className="fw-bold" ref={scroller}>
              {departure?.value && moment(departure?.value).format("Do")}{" "}
              {/* <span > */}
              {departure?.value
                ? moment(departure?.value).format("MMM' YY")
                : <span className="fw-normal text-muted">Choose date</span>}
              {/* </span> */}
            </p>
            {/* <span className="fw-normal small d-block">
                {departure?.value
                  ? moment(departure?.value).format("dddd")
                  : "Choose date"}
              </span> */}
            {/* <div className="ms-auto">
              <MdDateRange size={22} />
            </div> */}
          </div>
          <div
            className={`departure ${
              returnDate?.isDisabled ? "disabled_field" : ""
            } d-flex flex-column px-2 py-1`}
          >
            <span className="small text-muted">{returnDate?.label}</span>
            <p className="fw-bold" ref={scroller}>
              {returnDate?.value && moment(returnDate?.value).format("Do")}{" "}
              {/* <span className=" fw-semibold fs-6"> */}
              {returnDate?.value
                ? moment(returnDate?.value).format("MMM' YY")
                : <span className="fw-normal text-muted">Choose date</span>}
              {/* </span> */}
              {/* <span className="fw-normal small d-block">
                {returnDate?.value
                  ? moment(returnDate?.value).format("dddd")
                  : "Choose date"}
              </span> */}
            </p>
            {/* <div className="ms-auto">
              <MdDateRange size={22} />
            </div> */}
          </div>
        </div>
      </div>

      <Modal
        ref={modal}
        body={
          <div className="wrapper-departure">
            <div className="p-3 pb-1">
              <div className="d-flex w-100" style={{ alignItems: "flex-end" }}>
                {/* <select
                  name="way"
                  id="way"
                  className="mb-2 border-1 border shadow-none rounded px-1"
                  style={{ height: "42px" }}
                >
                  <option value="return">Return</option>
                  <option value="oneWay">One-way</option>
                </select> */}
                <div className=" w-100 mx-2">
                  <p
                    className="mb-1"
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                  >
                    {departure.label || "Departure"}
                  </p>
                  {/* <div className="mb-2 d-flex align-items-center px-1 border-1 border rounded">
                    <svg
                      
                      style={{ height: "2.5vh", color: "white" }}
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M19.917 4.5c.916 0 1.666.75 1.666 1.667v14.166c0 .917-.75 1.667-1.666 1.667H4.083c-.916 0-1.666-.75-1.666-1.667V6.167c0-.917.75-1.667 1.666-1.667h1.25c.25 0 .417.167.334.417v1.916c0 .417.333.667.666.667A.657.657 0 007 6.833v-4c0-.5.333-.833.833-.833s.834.333.834.833v1.5a.18.18 0 00.166.167h5.25c.25 0 .417.167.334.417v1.916c0 .417.333.667.666.667a.657.657 0 00.667-.667v-4c0-.5.333-.833.833-.833s.834.333.834.833v1.5a.18.18 0 00.166.167h2.334zM19.5 20.333c.25 0 .417-.166.417-.416v-10c0-.25-.167-.417-.417-.417h-15c-.25 0-.417.167-.417.417v10c0 .25.167.416.417.416h15zM8.25 10.75c.5 0 .833.333.833.833s-.333.834-.833.834h-.833c-.5 0-.834-.334-.834-.834s.334-.833.834-.833h.833z"></path>
                    </svg>
                    <div className="p-2">Anytime</div>
                  </div> */}
                </div>
                <div className=" w-100">
                  <p
                    className="mb-1"
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                  >
                    {returnDate.label || "Return"}
                  </p>
                  {/* <div className="mb-2 d-flex align-items-center px-1 border-1 border rounded">
                    <svg
                      
                      style={{ height: "2.5vh", color: "white" }}
                      viewBox="0 0 24 24"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <path d="M19.917 4.5c.916 0 1.666.75 1.666 1.667v14.166c0 .917-.75 1.667-1.666 1.667H4.083c-.916 0-1.666-.75-1.666-1.667V6.167c0-.917.75-1.667 1.666-1.667h1.25c.25 0 .417.167.334.417v1.916c0 .417.333.667.666.667A.657.657 0 007 6.833v-4c0-.5.333-.833.833-.833s.834.333.834.833v1.5a.18.18 0 00.166.167h5.25c.25 0 .417.167.334.417v1.916c0 .417.333.667.666.667a.657.657 0 00.667-.667v-4c0-.5.333-.833.833-.833s.834.333.834.833v1.5a.18.18 0 00.166.167h2.334zM19.5 20.333c.25 0 .417-.166.417-.416v-10c0-.25-.167-.417-.417-.417h-15c-.25 0-.417.167-.417.417v10c0 .25.167.416.417.416h15zM8.25 10.75c.5 0 .833.333.833.833s-.333.834-.833.834h-.833c-.5 0-.834-.334-.834-.834s.334-.833.834-.833h.833z"></path>
                    </svg>
                    <div className="p-2">Anytime</div>
                  </div> */}
                </div>
              </div>
              <RangePicker
                ranges={state}
                onChange={(item) => {
                  setState([item.selection]);
                  returnDate.onChange(
                    moment(item.selection.endDate).format("YYYY-MM-DD")
                  );
                  departure.onChange(
                    moment(item.selection.startDate).format("YYYY-MM-DD")
                  );
                }}
                // showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                direction={window.innerWidth > 768 ? "horizontal" : "vertical"}
                minDate={moment().toDate()}
              />
            </div>
          </div>
        }
      />
    </>
  );
};
export default DateRangePicker;
