import moment from "moment";
import React, { FC, useState } from "react";
import { motion } from "framer-motion";
import { BsArrowRight } from "react-icons/bs";

import FlightCardInfoModal from "./FlightCardInfoModal";
import { IFlightSearchData, TFlightResultList } from "../../../utils/types/flightTypes";
import { priceFormatter } from "../../../utils/utilityFunctions/utilityFunction";
import { constructFlightNumbersFor1GJourney } from "../../../utils/utilityFunctions/flight/flightUtilityFunctions";

interface IFlightCardProps {
  isSelected: boolean;
  onSelect: () => void;
  flight: TFlightResultList; // IFlightSearchData;
};

const FlightCard: FC<IFlightCardProps> = ({ isSelected, onSelect, flight }) => {

  const [isViewDetailsOpen, setIsViewDetailsOpen] = useState<boolean>(false);

  // if (flight.supplier === "airiq") {
  //   console.log("airiq: ", flight);
  // } else {
  //   console.log("navitaire: ", flight);
  // };

  return (
    <React.Fragment>
      <motion.div
        whileTap={{ scale: 0.985 }}
        className={`card card--flight-select ${isSelected ? " selected" : ""}`}
        onClick={() => onSelect()}
      >
        <div className="card-body">
          <div className="airline-logo" style={{ width: 40 }}>
            <img
              src={
                require(`../../../assets/${flight.carrierCode === "SG"
                  ?
                  "spicejet"
                  :
                  flight.carrierCode === "6E"
                    ?
                    "indigo"
                    :
                    flight.carrierCode === "QP"
                      ?
                      "akasaair"
                      :
                      "airway-placeholder"
                  }.png`).default
              }
              className="img rounded"
              height={40}
              width={40}
              alt="airline"
            />
            <p
              className="mx-auto my-0 py-0 text-center"
              style={{ fontSize: "0.6em" }}
            >
              {
                flight.carrierCode === "SG"
                  ?
                  "Spicejet"
                  :
                  flight.carrierCode === "6E"
                    ?
                    "IndiGo"
                    :
                    flight.carrierCode === "QP"
                      ?
                      "Akasa Air"
                      :
                      constructFlightNumbersFor1GJourney(flight.carrierCode, flight.flightCode)
              } ({flight.supplier})
            </p>
          </div>
          <div className="time w-100">
            <div className="d-flex align-items-center justify-content-between fw-bold">
              <div>{moment(flight?.departureTime).format("HH:mm")}</div>{" "}
              <div className="me-2 pe-4">
                {moment(flight?.arrivalTime).format("HH:mm")}
              </div>
            </div>
            <div className="small text-muted d-flex align-items-center">
              {flight?.airports?.map((seg, index) => (
                <span key={index}>
                  {seg}{" "}
                  {index < flight?.airports.length - 1 ? (
                    <BsArrowRight className="mb-1" />
                  ) : (
                    <></>
                  )}
                </span>
              ))}
            </div>
          </div>
          <div className="duration">
            <div className="fw-bold">{flight?.duration}</div>
            <div className="small text-muted">
              {flight?.airports && flight?.airports?.length - 2 > 0
                ? flight?.airports?.length - 2
                : "Non"}{" "}
              Stop
            </div>
          </div>
          <div className="price-details">
            <div className="price">
              {priceFormatter(
                flight.supplier === "navitaire"
                  ?
                  flight?.FareToShow?.markedUpPrice?.markedupTotalPrice
                  :
                  flight.supplier === "airiq"
                    ?
                    flight?.fare_data?.markedUpPrice?.markedupTotalPrice
                    :
                    flight.supplier === "1G"
                      ?
                      +flight?.["1GSegmentData"][0].fareInfo.price // +flight?.["1GSegmentData"].reduce((total, item) => total += item.fareInfo.markedUpPrice.markedupTotalPrice, 0)
                      :
                      0
              )}
            </div>
            <span
              className="fs-8 text-primary"
              onClick={(e) => {
                e.stopPropagation();
                setIsViewDetailsOpen(true);
              }}
            >
              View Details
            </span>
          </div>
        </div>
      </motion.div>

      <FlightCardInfoModal
        flightData={flight}
        show={isViewDetailsOpen}
        onClose={() => setIsViewDetailsOpen(false)}
      />
    </React.Fragment>
  );
};
export default FlightCard;
