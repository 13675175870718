import { AirLineServices, TypeCarrierCode } from "./../../types/flightTypes";
import { SERVER_API_URL } from "./../../constants";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BookFlights } from "../../../Interfaces/BookFlights/BookFlights";
import { PostBookFlights } from "../../../Interfaces/BookFlights/PostBookFlights";
import { State } from "../../../redux/types";
import {
  formatCreateBookingData,
  formatToArr,
} from "../../utilityFunctions/flight/flightUtilityFunctions";

const useFlightPostBooking = () => {

  const { Client } = useSelector((state: State) => state.commonState);
  const { userId, affiliateId, isAgent } = useSelector((state: State) => state.user);
  const { SelectedAddOns, searchInfo, personalInfo, Itinerary, metaData } = useSelector((state: State) => state.flight);

  const [cancellingTicket, setCancellingTicket] = useState(false);



  const getBooking = async (recordLocator: string, carrierCode: string) => {
    let bookingResponseData = undefined;
    try {
      const response = await PostBookFlights[
        AirLineServices[carrierCode as TypeCarrierCode]
      ]?.getBooking(recordLocator);
      const { data } = response?.data;
      bookingResponseData = data;
    } catch (error) {
      console.log(error);
    }
    return bookingResponseData;
  };

  const createBooking = async (
    bookingResponseByCarrier: {
      [x: string]: any;
    },
    agentAddOnMarkup: number
  ) => {
    if (Client !== null) {
      const body = formatCreateBookingData(
        bookingResponseByCarrier,
        SelectedAddOns || [],
        Itinerary,
        searchInfo,
        Client,
        metaData,
        personalInfo,
        userId,
        affiliateId,
        agentAddOnMarkup
      );
      const response = await axios.post(
        `${SERVER_API_URL}${isAgent ? "Agent/CreateFlightBooking" : "Flight/CreateBooking"
        }`,
        body
      );
      if (response?.data?.IsSuccess) {
        return true;
      }
    }
    // return true;
  };

  const cancelBooking = async (recordLocator: string) => {
    setCancellingTicket(true);
    const bookingInfo = await PostBookFlights[0]?.getBooking(recordLocator);
    const passengers: {
      FirstName: string;
      LastName: string;
      Title: string;
    }[] = formatToArr(bookingInfo?.data?.data?.Passengers?.Passenger)?.map(
      (pax: any) => ({
        FirstName: pax?.Names?.BookingName?.FirstName,
        LastName: pax?.Names?.BookingName?.LastName,
        Title: pax?.Names?.BookingName?.Title,
      })
    );

    const email =
      bookingInfo?.data?.data?.BookingContacts?.BookingContact?.EmailAddress;
    const phone =
      bookingInfo?.data?.data?.BookingContacts?.BookingContact?.HomePhone;

    const cancelResponse = await PostBookFlights[0]?.cancelTicket();
    const { PNRAmount } = cancelResponse?.data?.data;
    const BalanceDue = PNRAmount?.BalanceDue;

    const addPaymentResponse = await BookFlights[0].addPaymentToBooking(
      BalanceDue
    );
    if (addPaymentResponse?.data) {
      const commitBookingResponse = await BookFlights[0].commitBooking(
        passengers,
        email,
        phone,
        passengers?.length,
        recordLocator
      );
      if (commitBookingResponse?.data) {
        setCancellingTicket(false);
      }
    }
  };

  return { getBooking, cancelBooking, cancellingTicket, createBooking };

};
export default useFlightPostBooking;
