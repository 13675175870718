import React, { Children, FC } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { StarFull } from '@kiwicom/orbit-components/lib/icons';

import "../../hotel.scss";

import useHotel from '../../../../utils/hooks/Hotel/useHotel';
import { ISelectedHotel, State } from '../../../../redux/types';
import { FaTimes } from 'react-icons/fa';
import { IHotelListingInfo } from '../../../../utils/types/hotelTypes';

interface PropFilters {
    onHideModal: () => void;
    hotels: IHotelListingInfo[];
};

export default function FiltersOnModal({ onHideModal, hotels }: PropFilters) {

    const { hotelName, StarRating, price } = useSelector((state: State) => state.hotel.filters);

    return <React.Fragment>
        <motion.div
            className="modal show d-block"
            tabIndex={-1}
            aria-labelledby="filtersModal"
            aria-hidden="true"
            initial={{ translateY: "-50%", scale: 0.5, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            animate={{
                translateY: 0,
                scale: 1,
                opacity: 1,
            }}
        >
            <div className="modal-dialog mpdal-lg modal-dialog-centered modal-dialog-scrollable">
                {/* modal-fullscreen-xxl-down */}
                <div className="modal-content">
                    <div className="modal-header border-0 bg-dark text-white">
                        <h6 className="fw-bold mt-3">Filters</h6>
                        <button
                            type="button"
                            className="btn-modal-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => onHideModal()}><AiOutlineClose /></button>
                    </div>
                    <div className="modal-body">
                        <Filters
                            onHideModal={onHideModal}
                            hotels={hotels || []}
                        />

                        {hotelName !== "" ||
                            price.length > 0 ? (
                            <div className="d-grid sticky-filter-button">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                        onHideModal();
                                    }}
                                >
                                    Apply Filters
                                </button>
                            </div>
                        ) : (
                            <></>
                        )}

                    </div>
                </div>
            </div>
        </motion.div>
        <div className="modal-backdrop fade show"></div>
    </React.Fragment>;
}


const Filters: FC<PropFilters> = ({
    onHideModal,
    hotels
}) => {

    const { hotelName, StarRating, price } = useSelector((state: State) => state.hotel.filters);
    const { handleHotelFilters, handleHotelFilterReset } = useHotel();

    return <React.Fragment>

        {/* RESET FILTERS */}
        <div className="d-flex justify-content-end">
            {hotelName !== "" ||
                price.length > 0 ? (
                <span
                    className="text-primary small pointer mb-3"
                    onClick={() => {
                        handleHotelFilterReset();
                        onHideModal();
                    }}
                >
                    Clear Filters
                </span>
            ) : (
                <></>
            )}
        </div>

        {/* FOR SEARCH HOTEL BY NAME */}
        <CardBase>
            <div className='input-group'>
                <input
                    className='form-control'
                    type='text'
                    placeholder='Search by hotel name'
                    value={hotelName}
                    onChange={(e: any) => handleHotelFilters("hotelName", e.target.value)}
                />
                {hotelName && <span
                    className="input-group-text pointer"
                    onClick={() => handleHotelFilters("hotelName", "")}
                >
                    <FaTimes />
                </span>}
            </div>
        </CardBase>

        {/* FOR START RATING */}
        <CardBase
            header={"Category"}
        >
            {[5, 4, 3, 2, 1].map((star, index) => (
                <div className="form-check" key={index}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={StarRating.includes(String(star))}
                        onClick={() => {
                            handleHotelFilters("StarRating", String(star));
                            onHideModal();
                        }}
                    />
                    <label className="form-check-label d-flex align-items-center" htmlFor="flexRadioDefault1">
                        <div className="star-rating">
                            {[...Array(star)].map((_, i) => (
                                <StarFull size="small" key={i} />
                            ))}</div> {star !== 5 && <span className="small ms-1"> {star}+</span>}
                    </label>
                </div>
            ))}
        </CardBase>

        {/* FOR PRICE SLIDER */}
        <CardBase
            header={"Maximum Price"}
        >
            <label htmlFor="priceRange" className="form-label">Price: &#8377;{
                price[0]
                ||
                Math.ceil(hotels?.reduce((acc, shot) => acc = acc > Number(shot.price) ? acc : Number(shot.price), 0)
                    ||
                    0)
            }</label>
            <input
                type="range"
                className="form-range"
                min={
                    hotels?.length > 0
                        ?
                        Math.ceil(+hotels?.reduce((acc, shot) => shot?.price < acc?.price ? shot : acc)?.price)
                        :
                        0
                }
                max={
                    hotels?.length > 0
                        ?
                        Math.ceil(hotels?.reduce((acc, shot) => acc = acc > Number(shot.price) ? acc : Number(shot.price), 0))
                        :
                        0
                }
                step="500"
                id="priceRange"
                value={
                    price[0]
                    ||
                    Math.ceil(+hotels?.reduce((acc, shot) => acc = acc > Number(shot.price) ? acc : Number(shot.price), 0)
                        ||
                        0)
                }
                onChange={(e) => handleHotelFilters('price', "", e.target.value)}
            />
        </CardBase>

    </React.Fragment>
};

const CardBase: FC<{ header?: string }> = ({ children, header }) => (
    <div className="card">
        {header && <div className="card-header">
            <h6 className="fw-bold">{header}</h6>
        </div>}

        <div className={`card-body ${!header && "py-0 px-1"}`}>
            {children}
        </div>
    </div>
)
