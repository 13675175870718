import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FaLandmark, FaLongArrowAltLeft } from "react-icons/fa";
import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { ChevronRight, StarFull } from "@kiwicom/orbit-components/lib/icons";

import Button from "../../../../../components/Common/Button/Button";
import Tab from "../../../../../components/Common/Tab/Tab";
import img1 from "../../../../../assets/images/hotel-placeholder.png";
import ListCheck from "../../../../../components/Common/ListCheck/ListCheck";
import CardBase from "../../../../../components/Common/CardBase/CardBase";
import Image from "../../../../../components/Common/Image/Image";
import RoomCombination from "../../Components/RoomCombination";
import BsModal from "../../../../../components/Modal/BSModal";

import "../../../hotel.scss";

import useHotel from "../../../../../utils/hooks/Hotel/useHotel";

import { HOTEL_BEDS_FILE_BASE_URL } from "../../../../../utils/constants";
import useOnScreen from "../../../../../utils/hooks/Hotel/useIntersection";
import { useElementOnScreen } from "../../../../../utils/hooks/useElementOnScreen";
import { ICombination, ICommon, IHotel, IHotelFacillity, IRoomCombinations, State } from "../../../../../redux/types";
import { splitString, showInMapClicked, calculateMarkedupBaseCharge } from "../../../../../utils/utilityFunctions/utilityFunction";
import { createRoomCombinationsForHotelBedsRateArray, getTotalGuestCountForHotel } from "../../../../../utils/utilityFunctions/hotelUtitlityFunctions";
import { GiPathDistance } from "react-icons/gi";


interface Board {
  boardCode: string;
  boardName: string;
};


export default function HotelDetails() {

  const history = useHistory();

  const [
    { HotelBeds, searchHotel, metaData },
    { ClientData }
  ]: [IHotel, ICommon] = useSelector((state: State) => [
    state.hotel,
    state.commonState
  ]);
  const { updateSelectedRoom, removeSelectedRoom } = useHotel();

  const [facilities, setFacilities] = useState<IHotelFacillity[]>([]);
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0); // for hotel image slider
  const [showAllAmenities, setShowAllAmenities] = useState<boolean>(false); // to show all available amenities
  const [showFullDescription, setShowFullDescription] = useState<boolean>(false); // to show full description
  const [showImageModal, setShowImageModal] = useState<boolean>(false); // to show modal for hotel images

  const [roomCombinations, setRoomCombinations] = useState<IRoomCombinations>({});
  const [uniqueRoomCombination, setUniqueRoomCombination] = useState<Board[]>([]);
  const [selectedRoomCombinationForFilter, setSelectedRoomCombinationForFilter] = useState<Board>({
    boardCode: "",
    boardName: ""
  });
  const [filteredRoomCombinations, setFilteredRoomCombinations] = useState<IRoomCombinations>();

  // for intersaction observer - For sticky tabs(Overview, Room)
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  const scrollToTop = useRef<HTMLDivElement>(null); // for scroll to top
  const [activeTab, setActiveTab] = useState<number>(0); // for active tab
  // go to overview tab
  const overview = useRef<HTMLDivElement>(null);
  const scrollToOverview = () => { overview?.current?.scrollIntoView({ behavior: "smooth" }); setActiveTab(0); };
  // go to rooms tab
  const rooms = useRef<HTMLDivElement>(null);
  const scrollToSummary = () => { rooms?.current?.scrollIntoView({ behavior: "smooth" }); setActiveTab(1); };
  const roomScroll = useOnScreen(rooms);



  useEffect(() => {

    scrollToTop?.current?.scrollIntoView({ behavior: "smooth" });
    /* Setting the title of the page to the name of the hotel. */
    document.title = HotelBeds.searchedHotel.hotel_dynamic_data.name;

    let facility = HotelBeds.searchedHotel.hotel_static_data?.facilities?.filter((facility) => {
      if (facility?.facilityName) {
        if (facility.indLogic) return facility;
        else if (facility.indYesOrNo) return facility;
        else if (facility.facilityName?.toLocaleLowerCase() === "wheelchair-accessible" && !facility.indYesOrNo) return facility;
        else if (!facility.hasOwnProperty("indYesOrNo") && !facility.hasOwnProperty("indLogic")) return facility;
      };
    });
    // console.log("facility: ", facility);
    setFacilities(facility);

    let combinations = createRoomCombinationsForHotelBedsRateArray(HotelBeds.searchedHotel.hotel_dynamic_data, searchHotel.RoomGuest);
    setRoomCombinations(combinations);
    console.log("roomCombinations: ", combinations);

    // get unique boards for filter
    const allBoard: Board[] = Object.keys(combinations).map((boardCode) =>
      combinations[boardCode].map(
        (rate: ICombination, index: number) => ({
          boardCode: rate.combination?.[0]?.boardCode,
          boardName: rate.combination?.[0]?.boardName
        })
      )
    ).flat();
    const uniqueBoard: Board[] = filterUniqueValues(allBoard);
    setUniqueRoomCombination(uniqueBoard);

    // console.log("allBoard: ", allBoard);
    // console.log("uniqueBoard: ", uniqueBoard);

  }, []);



  // HANDLER FUNCTIONS
  function filterUniqueValues(allBoard: Board[]): Board[] {
    const uniqueSet: Set<string> = new Set();
    const uniqueArray: Board[] = [];

    for (const item of allBoard) {
      const key = `${item.boardCode}-${item.boardName}`;
      if (!uniqueSet.has(key)) {
        uniqueSet.add(key);
        uniqueArray.push(item);
      };
    };

    return uniqueArray;
  };

  const handleSelectBoardToBeFiltered = (board: Board) => {

    if (selectedRoomCombinationForFilter?.boardCode === board.boardCode) {

      setFilteredRoomCombinations(undefined);
      setSelectedRoomCombinationForFilter({ boardCode: "", boardName: "" });

    } else {

      let data = Object.keys(roomCombinations).filter(
        each => each === board?.boardCode)?.map(
          (boardCode) => Object.assign({ [board.boardCode]: roomCombinations[boardCode] })
        );
      setFilteredRoomCombinations(data[0]);
      setSelectedRoomCombinationForFilter(board);

    };

  };



  return (
    <div>

      <div className="searched-hotel-container h-100">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            {/* <div className="spacer spacer--sm"></div> */}
            <h2>Hotel Details</h2>
            <Button
              type={"button"}
              size={"sm"}
              color={"primary"}
              outline={true}
              className={"py-1 px-2 rounded-3"}
              label={
                <div className="d-flex align-items-center">
                  <FaLongArrowAltLeft />
                  <span className="small ms-1">Go back</span>
                </div>
              }
              onClick={() => history.goBack()}
            />
          </div>
          <div className="spacer spacer--sm"></div>

          <CardBase title="">
            <div className="hotel-details" ref={scrollToTop}>
              {/* HEADER */}
              <div className="header">
                <div>
                  <div className="d-flex flex-column flex-md-row gap-2 align-items-md-center">
                    <h2 className="mb-0">{HotelBeds.searchedHotel?.hotel_dynamic_data?.name}</h2>
                    <div className="star-rating">
                      {HotelBeds.searchedHotel?.hotel_dynamic_data?.categoryName?.split(" ")?.length > 1
                        ?
                        [...Array(+HotelBeds.searchedHotel?.hotel_dynamic_data?.categoryName?.split(" ")[0])].map((_, index) => (
                          <StarFull size="small" key={index} />
                        ))
                        :
                        HotelBeds.searchedHotel?.hotel_dynamic_data?.categoryName
                      }
                    </div>
                  </div>
                  <p className="text-muted mb-0 mt-1">{HotelBeds.searchedHotel?.hotel_static_data?.address?.content}</p>
                </div>

                <div className="text-end d-flex flex-column">
                  <div>
                    <div className="text-muted">
                      For {HotelBeds.searchedHotel.noOfNights > 1
                        ?
                        HotelBeds.searchedHotel.noOfNights + " nights"
                        :
                        HotelBeds.searchedHotel.noOfNights + " night"}
                    </div>
                    {/* {HotelBeds.searchedHotel?.hotel_static_data?.web && <React.Fragment>
                      <div>
                        {<span
                          className="text-primary pointer"
                          onClick={() => window.open("http://" + HotelBeds.searchedHotel.hotel_static_data?.web, "_blank")}
                        >
                          {HotelBeds.searchedHotel.hotel_static_data?.web?.toLowerCase()}
                        </span>
                          ||
                          <span className="text-muted">Not Available</span>}
                      </div>
                    </React.Fragment>} */}
                    <div>
                      <AiFillMail />
                      <span className="ms-1">
                        {HotelBeds.searchedHotel?.hotel_static_data?.email
                          ?
                          <span className="text-lowercase">{HotelBeds.searchedHotel.hotel_static_data?.email}</span>
                          :
                          <span className="text-muted">Not Available</span>}
                      </span>
                    </div>
                    <div>
                      <AiFillPhone />
                      <span className="ms-1">
                        {HotelBeds.searchedHotel?.hotel_static_data.phones[0]?.phoneNumber
                          ||
                          <span className="text-muted">Not Available</span>}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* TABS */}
              <div className="m-2 mb-0 mx-0 sticky-container">
                <div className={`d-flex px-2 ${isVisible && "hotel-details-tabs"}`}>
                  <Tab title="Overview" isActive={activeTab === 0 ? true : false} goTo={() => scrollToOverview()} />
                  <Tab title="Rooms" isActive={activeTab === 1 ? true : false} goTo={() => scrollToSummary()} />
                </div>
              </div>
              <div className="spacer spacer--sm"></div>

              <div ref={containerRef as React.RefObject<HTMLDivElement>}> {/* reference for intersection observer*/}
                {/* OVERVIEW */}
                <div className="" ref={overview}>
                  {/* card */}
                  <div className="image-grid-container">
                    {/* card-body  */}
                    {/* IMAGE GRID ON WEB VIEW */}
                    <div className="image-grid mt-2">
                      <div className="row g-1">
                        <div className="col-md-6 col-sm-12">
                          <div className="image">
                            <img
                              src={HotelBeds.searchedHotel?.hotel_static_data?.images && (HOTEL_BEDS_FILE_BASE_URL + "bigger/" + HotelBeds.searchedHotel?.hotel_static_data?.images[0]?.path) || img1}
                              alt=""
                              onClick={() => {
                                if (HotelBeds.searchedHotel?.hotel_static_data?.images[0].path) {
                                  setActiveImageIndex(0);
                                  setShowImageModal(true);
                                }
                              }} />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="image">
                            <img
                              src={HotelBeds.searchedHotel?.hotel_static_data?.images && (HOTEL_BEDS_FILE_BASE_URL + "bigger/" + HotelBeds.searchedHotel?.hotel_static_data?.images[1]?.path) || img1}
                              alt=""
                              onClick={() => {
                                if (HotelBeds.searchedHotel?.hotel_static_data?.images[1].path) {
                                  setActiveImageIndex(1);
                                  setShowImageModal(true);
                                }
                              }} />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <div className="image">
                            <div className="image-count">
                              <img
                                src={HotelBeds.searchedHotel?.hotel_static_data?.images && (HOTEL_BEDS_FILE_BASE_URL + "bigger/" + HotelBeds.searchedHotel?.hotel_static_data?.images[2]?.path) || img1}
                                alt=""
                                onClick={() => {
                                  if (HotelBeds.searchedHotel?.hotel_static_data?.images[2]?.path) {
                                    setActiveImageIndex(2);
                                    setShowImageModal(true);
                                  }
                                }} />
                              {HotelBeds.searchedHotel?.hotel_static_data?.images && HotelBeds.searchedHotel?.hotel_static_data?.images?.length > 3 ? <div
                                className="count"
                                onClick={() => {
                                  if (HotelBeds.searchedHotel?.hotel_static_data?.images[2]) {
                                    setActiveImageIndex(2);
                                    setShowImageModal(true);
                                  }
                                }}
                              >
                                +{HotelBeds.searchedHotel?.hotel_static_data?.images?.length - 3}
                              </div> : <></>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* IMAGE CAROUSEL ON MOBILE VIEW */}
                    <div id="carouselExampleControls" className="carousel slide image-slider" data-bs-ride="carousel">
                      <div className="carousel-inner">
                        {
                          HotelBeds.searchedHotel?.hotel_static_data?.images && HotelBeds.searchedHotel?.hotel_static_data?.images?.map((image, index) => (
                            <div className={`carousel-item ${activeImageIndex === index && 'active'}`} key={index}>
                              <Image
                                src={image?.path ? (HOTEL_BEDS_FILE_BASE_URL + image?.path) : img1}
                                className={"rounded"}
                                style={{ height: 200, width: "100%", objectFit: "contain" }}
                              />
                            </div>
                          ))
                        }
                      </div>
                      {activeImageIndex ? <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                        onClick={() => setActiveImageIndex(activeImageIndex - 1)}
                      >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                      </button> : <></>}
                      {(activeImageIndex + 1) !== HotelBeds.searchedHotel?.hotel_static_data?.images?.length ? <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                        onClick={() => {
                          setActiveImageIndex(activeImageIndex + 1)
                          // console.log(HOTEL_BEDS_FILE_BASE_URL + hotelInfo?.Images[activeImageIndex])
                        }}
                      >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                      </button> : <></>}
                    </div>

                    <div className="my-3">
                      <div className="row d-flex justify-content-between">
                        {/* Description & Facilities & Location */}
                        <div className="col-12">
                          <h4>Description</h4>
                          {/* <div
                              dangerouslySetInnerHTML={{
                                __html: String(showFullDescription && searchedHotel?.hotel_static_data?.description?.content
                                  ?
                                  searchedHotel?.hotel_static_data?.description?.content
                                  :
                                  splitString(searchedHotel?.hotel_static_data?.description?.content || "", 500) + " ...")
                              }}
                            /> */}
                          <div style={{ textAlign: "justify", }}>
                            {showFullDescription && HotelBeds.searchedHotel?.hotel_static_data?.description?.content
                              ?
                              HotelBeds.searchedHotel?.hotel_static_data?.description?.content
                              :
                              splitString(HotelBeds.searchedHotel?.hotel_static_data?.description?.content || "", 500) + " ..."}
                            <Button
                              type={"button"}
                              color={"link"}
                              size={"sm"}
                              label={showFullDescription ? ' hide' : 'more'}
                              className={"text-decoration-none px-0 py-0"}
                              onClick={() => setShowFullDescription(!showFullDescription)}
                            />
                          </div>
                          <div className="spacer spacer--sm"></div>
                          <div className="row">
                            {/* Amenities */}
                            {/* <div className="col-md-6 col-sm-12">
                                <h4>Amenities</h4>
                                {
                                  !facilities.find((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")?.indYesOrNo
                                  &&
                                  <ListCheck
                                    type={"error"}
                                    title={facilities.filter((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")[0]?.facilityName}
                                  />
                                }
                                {
                                  facilities?.slice(0, 10).map((facility, index) => (
                                    <div className="d-flex" key={index}>
                                      <ListCheck title={facility?.facilityName} key={index} />
                                      {facility.indFee && <span className="d-flex flex-column align-items-start ms-1">*</span>}
                                    </div>
                                  ))
                                }
                                <div className="spacer spacer--sm"></div>
                                <p className="muted-text mb-0">* These are paid facilities</p>
                                <Button
                                  type={"button"}
                                  color={"link"}
                                  size={"sm"}
                                  label={showAllAmenities ? 'Hide' : 'Show all'}
                                  className={"text-decoration-none px-0 py-0"}
                                  onClick={() => setShowAllAmenities(!showAllAmenities)}
                                />
                              </div> */}

                            {/* KEY LOCATION */}
                            <div className="col-md-4 col-sm-12">
                              {HotelBeds.searchedHotel?.hotel_static_data?.interestPoints?.length
                                ?
                                <>
                                  <h4>Around this property</h4>
                                  <div className="list-group list-group-flush">
                                    {
                                      HotelBeds.searchedHotel?.hotel_static_data?.interestPoints?.map((each, index) => (
                                        <div className="border-bottom d-flex flex-column py-2" key={index + "_key_location"}>
                                          <div className="d-flex gap-1">
                                            <span><FaLandmark /></span>
                                            <p className="mb-0 pb-0 fw-semibold">{each.poiName || " - "}</p>
                                          </div>
                                          <div className="d-flex gap-1">
                                            <span><GiPathDistance /></span>
                                            <p className="mb-0 pb-0 text-muted text-sm">{each.distance ? (+each.distance / 1000) + " km" : ""}</p>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </div>
                                </>
                                :
                                <></>}
                            </div>

                            {/* AMENITIES */}
                            <div className="col-md-5 col-sm-12">
                              <h4>Amenities</h4>
                              <div className="amenity-grid">
                                {
                                  !facilities.find((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")?.indYesOrNo
                                    ?
                                    <ListCheck
                                      type={"error"}
                                      title={facilities.filter((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")[0]?.facilityName}
                                    />
                                    :
                                    <></>
                                }
                                {
                                  facilities?.slice(0, 10).map((facility, index) => facility?.facilityName
                                    ?
                                    <div className="d-flex" key={"facility_" + index}>
                                      <ListCheck title={facility?.facilityName} key={"facility_name" + index} />
                                      {facility.indFee && <span className="d-flex flex-column align-items-start ms-1">*</span>}
                                    </div>
                                    :
                                    <></>)
                                }
                              </div>
                              <div className="spacer spacer--sm"></div>
                              <p className="muted-text mb-0">* These are paid facilities</p>
                              <Button
                                type={"button"}
                                color={"link"}
                                size={"sm"}
                                label={showAllAmenities ? 'Hide' : 'Show all'}
                                className={"text-decoration-none px-0 py-0"}
                                onClick={() => setShowAllAmenities(!showAllAmenities)}
                              />
                            </div>

                            {/* LOCATION */}
                            <div className="col-md-3 col-sm-12">
                              <h4>Location</h4>
                              <div
                                className="map-placeholder mt-3"
                                onClick={() =>
                                  showInMapClicked(+HotelBeds.searchedHotel.hotel_dynamic_data.latitude, +HotelBeds.searchedHotel.hotel_dynamic_data.longitude)
                                }
                              >
                                <Image
                                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD9YSkJq_hgJvYhMvVatqATlw-IqugNFVDHA&usqp=CAU"
                                  className="rounded w-100"
                                />
                                <div className="text">View on map</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ROOM CARDS */}
                <div className="section-rooms" ref={rooms}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="section-title">Rooms</div>
                    <div>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={e => {
                          let filteredData = uniqueRoomCombination.filter(each => each.boardCode === e.target.value)[0];
                          handleSelectBoardToBeFiltered(filteredData);
                        }}
                      >
                        <option selected={!selectedRoomCombinationForFilter.boardCode} disabled>Select Room Type</option>
                        {
                          uniqueRoomCombination.map((board: Board, index) => (
                            <option
                              key={"board_" + index}
                              value={board.boardCode}
                              selected={selectedRoomCombinationForFilter.boardCode === board.boardCode}
                            >
                              {board.boardName}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>

                  <div className="row g-2">
                    <div className="list-group list-group-flush">
                      {
                        roomCombinations
                          ?
                          filteredRoomCombinations
                            ?
                            Object.keys(filteredRoomCombinations).map((boardCode) =>
                              filteredRoomCombinations[boardCode].map(
                                (rate: ICombination, index: number) => (
                                  <RoomCombination
                                    key={index + "_room_combination"}
                                    boardCode={boardCode}
                                    currency={HotelBeds.searchedHotel.hotel_dynamic_data.currency}
                                    rate={rate}
                                    onSelect={() => {
                                      updateSelectedRoom(rate, "HOTELBEDS");
                                    }}
                                    onRemove={() => removeSelectedRoom("HOTELBEDS")}
                                  />
                                )
                              )
                            )
                            :
                            Object.keys(roomCombinations).map((boardCode) =>
                              roomCombinations[boardCode].map(
                                (rate: ICombination, index: number) => (
                                  <RoomCombination
                                    key={index + "_room_combination"}
                                    boardCode={boardCode}
                                    currency={HotelBeds.searchedHotel.hotel_dynamic_data.currency}
                                    rate={rate}
                                    onSelect={() => {
                                      updateSelectedRoom(rate, "HOTELBEDS");
                                    }}
                                    onRemove={() => removeSelectedRoom("HOTELBEDS")}
                                  />
                                )
                              )
                            )
                          :
                          <></>
                      }
                    </div>
                  </div>
                </div>

                {/* HOTEL BOOKING INFO - SUMMARY */}
                {/* <div ref={summary}>
                  <HotelBooking
                    coverImg={hotelInfo?.Images?.[0]}
                    scrollToRoom={scrollToSummary}
                  />
                </div> */}
              </div>
            </div>
          </CardBase>
        </div>

      </div>

      {/* SELECTED COMBINATION SECTION */}
      {HotelBeds.selectedRoomCombinations.id !== -1
        ?
        <motion.div
          className="selected-room-combination"
          initial={{ translateY: "-50%", scale: 0.5, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          animate={{
            translateY: 0,
            scale: 1,
            opacity: 1,
          }}
        >
          <div className="container">
            <div className="selected-container">
              <div className="d-flex flex-column">
                <span className="small">
                  {moment(searchHotel.checkOutDate).diff(
                    searchHotel.checkInDate,
                    "days"
                  )}{" "}
                  night stay
                </span>
                <h3 className="mb-0">
                  {calculateMarkedupBaseCharge(
                    metaData?.markUps,
                    metaData?.affiliateMarkup,
                    HotelBeds.selectedRoomCombinations.combination,
                    "Hotelbeds",
                    HotelBeds.searchedHotel.hotel_dynamic_data.currency,
                    ClientData.GSTRates[0]?.Value,
                    searchHotel.RoomGuest
                  )}
                </h3>
                <span className="small">Exclusive taxes</span>
              </div>
              <Button
                type={"button"}
                color={"light"}
                className={"text-primary"}
                size={"sm"}
                label={<span className="d-flex align-items-center">Book Now <ChevronRight /></span>}
                onClick={(e) => { e.preventDefault(); history.push("/hotel/HB/booking") }}
              />
            </div>
          </div>
        </motion.div>
        :
        <></>}


      {/* Modal for all amenities */}
      {showAllAmenities
        ?
        <React.Fragment>
          <BsModal
            title={"Amenities"}
            scrollable={true}
            onClose={() => setShowAllAmenities(false)}
            body={<div>
              <div className="amenity-grid">
                {
                  !facilities.find((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")?.indYesOrNo
                  &&
                  <ListCheck
                    type={"error"}
                    title={facilities.filter((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")[0]?.facilityName}
                  />
                }
                {
                  facilities?.map((facility, index) => facility?.facilityName
                    ?
                    <div className="d-flex" key={index}>
                      <ListCheck title={facility?.facilityName} key={index} />
                      {facility.indFee && <span className="d-flex flex-column align-items-start ms-1">*</span>}
                    </div>
                    :
                    <></>)
                }
              </div>
              <div className="spacer spacer--sm"></div>
              <p className="muted-text mb-0">* These are paid facilities</p>
            </div>}
            footer={<Button
              type="button"
              color="primary"
              size="sm"
              label="Close"
              onClick={() => setShowAllAmenities(false)} />}
          />
        </React.Fragment>
        :
        <></>}

      {/* Modal for images */}
      {showImageModal
        ?
        <React.Fragment>
          <motion.div
            className="modal show d-block image-modal"
            tabIndex={-1}
            aria-labelledby="filtersModal"
            aria-hidden="true"
            initial={{ translateY: "-50%", scale: 0.5, opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            animate={{
              translateY: 0,
              scale: 1,
              opacity: 1,
            }}
          >
            <div className={"modal-dialog modal-dialog-centered modal-lg"}>
              <div className="modal-content shadow-lg border-0 rounded">
                <div className="modal-body p-0">
                  <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                      {
                        HotelBeds.searchedHotel.hotel_static_data?.images && HotelBeds.searchedHotel.hotel_static_data?.images?.map((image, index) => (
                          <div className={`carousel-item ${activeImageIndex === index && 'active'}`} key={index}>
                            <Image
                              src={image?.path ? (HOTEL_BEDS_FILE_BASE_URL + "bigger/" + image?.path) : img1}
                              className="rounded"
                              style={{ height: "80vh", width: "100%", objectFit: "cover" }}
                            />
                          </div>
                        ))
                      }
                    </div>
                    {activeImageIndex ? <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                      onClick={() => setActiveImageIndex(activeImageIndex - 1)}
                    >
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button> : <></>}
                    {(activeImageIndex + 1) !== HotelBeds.searchedHotel.hotel_static_data?.images?.length ? <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                      onClick={() => setActiveImageIndex(activeImageIndex + 1)}
                    >
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button> : <></>}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <div className="modal-backdrop fade show" onClick={() => setShowImageModal(false)}></div>
        </React.Fragment>
        :
        <></>}

    </div>
  );
}
