import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaFilter } from "react-icons/fa";
import Coupon from "@kiwicom/orbit-components/lib/Coupon";

import FiltersOnModal from "./Components/Filters/FiltersOnModal";
import SearchedHotelList from "./SearchedHotel/SearchedHotelList";
import SearchHotelForm from "./Components/SeachHotel/SearchHotelForm";
import Placeholder from "../../components/Common/Placeholder/Placeholder";
import SearchHotelFilters from "./Components/Filters/SearchHotelFilters";
import LoadingSearchHotel from "../../components/Common/LoadingSkeleton/LoadingSearchHotel";

import "./hotel.scss";

import { State } from "../../redux/types";
import useHotel from "../../utils/hooks/Hotel/useHotel";
import useHotelDetails from "../../utils/hooks/Hotel/useHotelDetails";
import useTBO from "../../utils/hooks/Hotel/useTBO";
import HotelListing from "./Components/HotelListing";
import useClientData from "../../utils/hooks/useClientData";


interface Props { }

const Hotel = (props: Props) => {

  const hotel = useSelector((state: State) => state.hotel);
  const { Client, ClientData } = useSelector((state: State) => state.commonState);

  const {
    isLoading,
    searchHotelHandler
  } = useHotelDetails();
  const {
    handleHotelFilterReset,
    handleHotelListingInfoReset,
    resetPaginationInfo
  } = useHotel();
  const { getChargesByService } = useClientData();
  const { searchHotelHandlerTBO } = useTBO();

  // let isFirstLoad = false;
  const [isFirstLoad, setIsFirstLoad] = useState(true);


  // useEffect(() => {
  //   let clientId = ClientData.Services?.find((service) => service.ServiceName?.toLocaleLowerCase() === "hotel")?.ServiceId || 2;
  //   getChargesByService(clientId);
  // }, []);

  useEffect(() => {

    if (isFirstLoad) {
      setIsFirstLoad(false);
    };
    let clientId = ClientData.Services?.find((service) => service.ServiceName?.toLocaleLowerCase() === "hotel")?.ServiceId || 2;
    getChargesByService(clientId);
    // removeSelectedHotel("HOTELBEDS");
    // removeSelectedRoom("HOTELBEDS");
    // removeSelectedHotel("TBO");
    // removeSelectedRoom("TBO");
    resetPaginationInfo("HOTELBEDS");
    resetPaginationInfo("TBO");
    console.log("!Availability from Hotel Availability");
    searchHotelAvailability();

  }, [
    // hotel.filters.StarRating
  ]);

  // search hotels when user selects zones
  useEffect(() => {
    if (!isFirstLoad && hotel.filters.selectedNearbySearchGeoLocation?.index !== null) {
      console.log("!!Availability from Geo Location", hotel.filters.selectedNearbySearchGeoLocation?.index);
      // console.log("!!isFirstLoad: ", isFirstLoad);
      searchHotelAvailability();
    };
  }, [
    hotel.filters.selectedNearbySearchGeoLocation,
    hotel.filters.StarRating,
    hotel.filters.HotelBoards,
    hotel.filters.HotelCategory,
    hotel.filters.accommodationTypes,
    hotel.filters.price
  ]);

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  // search available hotels for HotelBeds & TBO
  const searchHotelAvailability = () => {
    handleHotelListingInfoReset();
    searchHotelHandler();
    //* uncomment the below code to fetch hotels from TBO
    // searchHotelHandlerTBO();
  };

  // const validateSearchHotelParameters = () => {
  //   removeSelectedHotel();
  //   handleUpdateHotelData(false);

  //   let checkIn = moment(hotel.searchHotel.checkInDate).format("YYYY-MM-DD");
  //   let checkOut = moment(hotel.searchHotel.checkOutDate).format("YYYY-MM-DD");

  //   // checking whether check-out date is after check-in date or not
  //   if (moment(checkOut).isAfter(checkIn)) {
  //     // checking whether destination is selected or not
  //     if (hotel.searchHotel.whereTo.DestinationNumber !== "") {
  //       history.push("/hotel/hotel-listing");
  //       return true;
  //     } else {
  //       handleShowToast({
  //         title: "City Not Selected!",
  //         subTitle: "Please select a city to search hotels",
  //         type: "error",
  //       });
  //       return false;
  //     }
  //   } else {
  //     handleShowToast({
  //       title: "Invalid Dates!",
  //       subTitle: "Check-out date should be greater than check-in date",
  //       type: "error",
  //     });
  //     return false;
  //   }
  // };

  return (
    <div>

      <div className="spacer--md" />
      {/* SEARCH PARAMETER FORM */}
      <div className="container">
        <SearchHotelForm
          onSearch={searchHotelAvailability}
          isSearching={isLoading}
          city_list={hotel.metaData.Destination} // cityList
          allowLocationSearch={true}
        />
      </div>
      <div className="spacer--md"></div>

      {
        isLoading ? (
          <div>
            <p className="text-center text-muted">Please wait, we are loading hotels for you...</p>
            <LoadingSearchHotel />
          </div>
        ) : (
          <React.Fragment>
            <div className="searched-hotel-container">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <div className="d-flex justify-content-between">
                      {/* <h6 className="fw-bold mt-3">Found {hotels?.length} hotels</h6> */}
                      <h6 className="fw-bold mt-3">Filters</h6>
                      {hotel.filters.hotelName !== "" ||
                        hotel.filters.price.length > 0 ? (
                        <span
                          className="text-primary small pointer mt-3"
                          onClick={() => handleHotelFilterReset()}
                        >
                          Clear Filters
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-8 col-lg-9">
                    <div className="alert p-2 bg-warning bg-opacity-25 sticky-container mb-0">
                      <div className="bg-warning d-inline-flex rounded-pill py-1 px-2 fw-bold text-white small">
                        OFFER
                      </div>
                      <span className="ms-3">
                        Get flat 7% off using <Coupon>HB2022FLAT10</Coupon> on
                        all hotel bookings.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 hide-filter-on-desktop overflow-auto"
                    style={{ maxHeight: 'calc(100vh - 100px)' }}
                  >
                    {/* FILTERS */}
                    <SearchHotelFilters hotel={hotel.hotelListing || []} />
                  </div>
                  <div
                    className="col-sm-12 col-md-8 col-lg-9 overflow-auto"
                    style={{ maxHeight: 'calc(100vh - 100px)' }}
                  >
                    {/* HOTEL LIST */}
                    <HotelListing
                      hotel={hotel}
                      list={hotel.hotelListing}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>

            {hotel.HotelBeds.searchedHotelList?.length > 0
              ?
              <button
                className="btn btn-primary show-filters-on-mobile"
                onClick={() => setShowFiltersModal(true)}
              >
                Filters <FaFilter />
              </button>
              :
              <></>}

          </React.Fragment>
        )
      }

      {showFiltersModal
        ?
        <FiltersOnModal
          onHideModal={() => setShowFiltersModal(false)}
          hotels={hotel.hotelListing}
        />
        :
        <></>}

    </div>
  );
};

export default Hotel;
