import { useEffect, useState } from "react";

import { ISSR } from "../../../../../utils/types/flightTypes";
import { priceFormatter } from "../../../../../utils/utilityFunctions/utilityFunction";

import SSRToBeSold from "../../../../../Data/SSRToBeSold.json";
import SSRToBeSoldIndigo from "../../../../../Data/SSRToBeSoldIndigo.json";

type Props = {
  CarrierCode: string;
  ssr: ISSR[];
  onSelect: (item: ISSR) => void;
  tempSelected: {
    segIndex: number;
    passengerIndex: number;
    ssrData: ISSR;
  }[];
  selectedPax: number;
  segIndex: number;
};

const BaggageSSR = ({
  CarrierCode,
  onSelect,
  ssr,
  tempSelected,
  selectedPax,
  segIndex,
}: Props) => {

  const [SSRToBeSoldDetails, setSSRToBeSoldDetails] = useState<any>();

  useEffect(() => {
    // console.log(CarrierCode === "6E" ? SSRToBeSoldIndigo : SSRToBeSold);
    setSSRToBeSoldDetails(CarrierCode === "6E" ? SSRToBeSoldIndigo : SSRToBeSold);
  }, [CarrierCode]);



  return (
    <>
      {ssr.map((item, index) => {

        const detailsToShow = SSRToBeSoldDetails?.EB.find(
          (s: any) => s?.SSRCode === item.SSRCode
        );
        const isSelected = tempSelected?.filter(
          (e) => e.passengerIndex === selectedPax && e.segIndex === segIndex
        ).find((e) => e.ssrData.SSRCode === item.SSRCode);
        let price = item.PaxSSRPrice[0].Fare.reduce(
          (total, e) => total + Number(e.Amount),
          0
        );

        return (
          <>
            {
              detailsToShow
                ?
                <div
                  className={`card card--selectable baggage mb-2 ${isSelected ? "border border-primary bg-primary bg-opacity-10" : ""
                    }`}
                  key={index}
                  onClick={() => {
                    onSelect(item);
                  }}
                >
                  <div className="d-flex justify-content-between p-2 align-items-center">
                    <span className="fw-bold">{`${detailsToShow?.description}`}</span>
                    <span className="ms-auto me-2">{priceFormatter(price)}</span>
                  </div>
                </div>
                :
                <></>
            }
          </>
        );
      })}
    </>
  );
};

export default BaggageSSR;
