import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
// import InputField from "@kiwicom/orbit-components/lib/InputField";
import Text from "@kiwicom/orbit-components/lib/Text";
import { FaChild, FaPlusCircle } from "react-icons/fa";
import Stack from "@kiwicom/orbit-components/lib/Stack";

import Button from "../../../../components/Common/Button/Button";
import IStepper from "../../../../components/Common/Stepper/Stepper";
import CardBase from "../../../../components/Common/CardBase/CardBase";
import DestinationSearch from "../../../../components/Common/Input/DestinationSearch";
import DateRangePicker from "../../../../components/FlightModal/DateRangePicker/DateRangePicker";

import "react-datepicker/dist/react-datepicker.css";

import { State } from "../../../../redux/types";
import useHotel from "../../../../utils/hooks/Hotel/useHotel";
import DatePicker from "../../../../components/FlightModal/DatePicker";

interface Props {
  onSearch: () => void;
  isSearching: boolean;
  city_list: any[];
  allowLocationSearch: boolean;
};

export default function SearchHotelForm({
  onSearch,
  isSearching,
  city_list,
  allowLocationSearch
}: Props) {

  const searchHotelFilter = useSelector((state: State) => state.hotel);

  const {
    updateNoOfRooms,
    removeRoomGuest,
    updateRoomGuest,
    updateWhereTo,
    updateHotelSearch,
    removeWhereTo,
    handleUpdateHotelData,
    resetPaginationInfo,
  } = useHotel();

  // const [whereToSuggesstion, setWhereToSuggestion] = useState<any[]>([]); // holds suggested cities

  const [showSuggesstion, setShowSuggestion] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState<boolean>(false); // enable or disable the submit button
  const [showRoomInfo, setShowRoomInfo] = useState<boolean>(false); // show or hide the room info


  useEffect(() => {
    // resetRoomGuest();
    if (
      searchHotelFilter.searchHotel.checkInDate?.length < 2 &&
      searchHotelFilter.searchHotel.checkOutDate?.length < 2
    ) {
      updateHotelSearch("checkInDate", moment().format("YYYY-MM-DD"));
      updateHotelSearch(
        "checkOutDate",
        moment().add(1, "d").format("YYYY-MM-DD")
      );
    }
  }, []);

  useEffect(() => {

    setIsFormFilled(
      (
        (searchHotelFilter.searchHotel.whereTo.DestinationId !== -1 || !searchHotelFilter.searchHotel.geoLocation.name)
        &&
        searchHotelFilter.searchHotel.checkInDate?.length > 2
        &&
        searchHotelFilter.searchHotel.checkOutDate?.length > 2
        &&
        Number(searchHotelFilter.searchHotel.noOfRooms) !== 0
        &&
        Number(searchHotelFilter.searchHotel.RoomGuest[0].NoOfAdults) !== 0
      )
        ||
        Number(searchHotelFilter.searchHotel.RoomGuest[0].NoOfAdults) !== 0
        ?
        true
        :
        false
    );
    handleUpdateHotelData(false);
    // updateSearchedHotelList([]);

  }, [searchHotelFilter]);

  useEffect(() => {
    console.log("Filter");
  }, [searchHotelFilter.searchHotel.RoomGuest]);

  // filter where to city based on user input
  // const filterWhereToCity = (e: any) => {
  //     let value: string = e.target.value;
  //     let filteredList = city_list.filter(city => city.CityName?.toLowerCase().includes(value.toLowerCase()));
  //     setWhereToSuggestion(filteredList.slice(0, 5));
  // };

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          resetPaginationInfo("HOTELBEDS");
          resetPaginationInfo("TBO");
          onSearch();
        }}
      >
        {/* <div className="row">
          <div className="col-lg-"
        </div> search-flight-container */}
        <div className="search-hotel-form">
          {/* <Input
            label="City"
            value={
              city_list?.find(
                (a: ICityList) =>
                  +a?.DestinationNumber ===
                  +searchHotelFilter.searchHotel.whereTo.DestinationNumber
              )?.DestinationName
            }
            onChange={(value) => {
              value
                ? updateWhereTo(
                  city_list?.find(
                    (city: ICityList) => city?.DestinationNumber === value
                  )
                )
                : removeWhereTo();

              setShowSuggestion(false);
            }}
            placeholder="Select city"
            suggestionArray={city_list?.map((city: ICityList) => ({
              title: city?.CityName,
              subtitle: "",
              value: city?.DestinationNumber,
              label: city?.CountryName,
            }))}
          /> */}

          {/* <Input2
            label="City"
            value={
              searchHotelFilter.metaData?.Destination?.find((a: IHotelWhereTo) => (
                a.DestinationNumber === searchHotelFilter.searchHotel.whereTo.DestinationNumber)
              )?.DestinationName || ""
            }
            onChange={(value) => {
              value ?
                updateWhereTo(searchHotelFilter.metaData?.Destination?.find((city: IHotelWhereTo) => city.DestinationNumber === value) || initialState.hotel.searchHotel.whereTo)
                :
                removeWhereTo()
            }}
            placeholder="Select city"
            suggestionArray={searchHotelFilter.metaData?.Destination?.map((city: IHotelWhereTo) => ({
              title: city?.DestinationName,
              subtitle: "",
              value: city?.DestinationNumber,
              label: city?.CountryCode,
            }))}
          /> */}

          <DestinationSearch allowLocationSearch={allowLocationSearch} />

          {/* Check in & Check out Date */}
          {/* <DepartureReturnDatePicker
            departure={{
              value:
                searchHotelFilter.searchHotel.checkInDate ||
                moment().format("YYYY-MM-DD"),
              label: "Check-In",
              onChange: (value) => updateHotelSearch("checkInDate", value),
            }}
            returnDate={{
              value:
                searchHotelFilter.searchHotel.checkOutDate ||
                moment().add(1, "d").format("YYYY-MM-DD"),
              label: "Check-Out",
              onChange: (value) => updateHotelSearch("checkOutDate", value),
              minDate:
                searchHotelFilter.searchHotel.checkInDate ||
                moment().add(1, "d").format("YYYY-MM-DD"),
            }}
          /> */}

          <div className="departure_return">
            <div style={{ width: "49%" }}>
              <DatePicker
                label="Check-In"
                value={searchHotelFilter.searchHotel.checkInDate}
                onChange={(date) => {
                  updateHotelSearch("checkInDate", date);
                  updateHotelSearch("checkOutDate", moment(date).add(1, "d").format("YYYY-MM-DD"));
                }}
                minDate={searchHotelFilter.searchHotel.checkInDate ? searchHotelFilter.searchHotel.checkInDate : moment().format("YYYY-MM-DD")}
              // maxDate={searchHotel.checkOutDate ? searchHotel.checkOutDate : moment().add(1, "d").format("YYYY-MM-DD")}
              />
            </div>
            <div style={{ width: "49%", marginLeft: "2%" }}>
              <DatePicker
                label="Check-Out"
                value={searchHotelFilter.searchHotel.checkOutDate}
                onChange={(date) => updateHotelSearch("checkOutDate", date)}
                minDate={searchHotelFilter.searchHotel.checkInDate ? searchHotelFilter.searchHotel.checkInDate : moment().format("YYYY-MM-DD")}
              />
            </div>
          </div>

          {/* <DateRangePicker
            departure={{
              value:
                searchHotelFilter.searchHotel.checkInDate ||
                moment().format("YYYY-MM-DD"),
              label: "Check-In",
              onChange: (value) => updateHotelSearch("checkInDate", value),
            }}
            returnDate={{
              value:
                searchHotelFilter.searchHotel.checkOutDate ||
                moment().add(1, "d").format("YYYY-MM-DD"),
              label: "Check-Out",
              onChange: (value) => updateHotelSearch("checkOutDate", value),
              minDate:
                searchHotelFilter.searchHotel.checkInDate ||
                moment().add(1, "d").format("YYYY-MM-DD"),
            }}
          /> */}

          {/* No of Rooms & Guests */}
          <div className="hotel-room-input rounded w-100">
            <div
              // onBlur={() => setShowRoomInfo(false)}
              onMouseLeave={() => setShowRoomInfo(false)}
            >
              <div
                className="border rounded py-1 px-2"
                style={{
                  height: "56px",
                }}
                onClick={() => setShowRoomInfo(true)}
              >
                <div className="d-flex flex-column">
                  <span className="small text-muted">Room & Guests</span>
                  <p className="fw-bold">
                    {`Rooms: ${searchHotelFilter.searchHotel.noOfRooms
                      }, Adults: ${searchHotelFilter.searchHotel.RoomGuest?.reduce(
                        (accu, curr) => (accu += Number(curr?.NoOfAdults)),
                        0
                      )}, Children: ${searchHotelFilter.searchHotel.RoomGuest?.reduce(
                        (accu, curr) => (accu += Number(curr?.NoOfChild)),
                        0
                      )}`}
                  </p>
                </div>
              </div>
              {showRoomInfo && (
                <div
                  className="room-card"
                // onMouseLeave={() => setShowRoomInfo(false)}
                >
                  <CardBase title={"Rooms & Guests"}>
                    {[...Array(+searchHotelFilter.searchHotel.noOfRooms)].map(
                      (_, index) => (
                        <React.Fragment key={index}>
                          <div className="d-flex justify-content-between">
                            <h5>Room {index + 1}</h5>
                            {index ? (
                              <span
                                className="small remove-room"
                                onClick={() => removeRoomGuest(index)}
                              >
                                Remove
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <Stack>
                            <React.Fragment key=".0">
                              <Stack align="center">
                                <Stack spacing="none">
                                  <Text>Adults</Text>
                                  <Text type="secondary" size="small">
                                    Above 11 years
                                  </Text>
                                </Stack>
                                <IStepper
                                  minValue={1}
                                  maxValue={8}
                                  value={Number(
                                    searchHotelFilter.searchHotel.RoomGuest[
                                      index
                                    ]?.NoOfAdults
                                  )}
                                  onChange={(value) => {
                                    updateRoomGuest(index, "NoOfAdults", value);
                                  }}
                                />
                              </Stack>
                              <Stack align="center">
                                <Stack spacing="none">
                                  <Text>Children</Text>
                                  <Text type="secondary" size="small">
                                    1-11 years
                                  </Text>
                                </Stack>
                                <IStepper
                                  minValue={0}
                                  maxValue={2}
                                  value={Number(
                                    searchHotelFilter.searchHotel.RoomGuest[
                                      index
                                    ]?.NoOfChild
                                  )}
                                  onChange={(value) => {
                                    if (
                                      +searchHotelFilter.searchHotel.RoomGuest[
                                        index
                                      ]?.NoOfChild > value
                                    ) {
                                    }
                                    updateRoomGuest(index, "NoOfChild", value);
                                  }}
                                />
                              </Stack>

                              {/* <div className="spacer spacer--sm" /> */}
                              {searchHotelFilter.searchHotel.RoomGuest[index]
                                ?.NoOfChild ? (
                                <span className="small text-muted my-2">
                                  *To find you a place to stay that fits your
                                  entire group along with correct prices, we
                                  need to know how old your children will be at
                                  check-out
                                </span>
                              ) : (
                                <></>
                              )}
                              {/* Child age dropdowns */}
                              <div>
                                {[
                                  ...Array(
                                    +searchHotelFilter.searchHotel.RoomGuest[
                                      index
                                    ]?.NoOfChild
                                  ),
                                ]?.map((_, i) => (
                                  <div
                                    className="d-flex justify-content-between align-items-center mb-2"
                                    key={i}
                                  >
                                    <span className="small w-50 d-flex align-items-center">
                                      <FaChild className="text-" />
                                      Child {i + 1}
                                    </span>
                                    <select
                                      id="title"
                                      className="form-select"
                                      style={{ height: "40px" }}
                                      aria-label="Default select example"
                                      value={
                                        searchHotelFilter.searchHotel.RoomGuest[
                                          index
                                        ]?.ChildAge[i]
                                          ? searchHotelFilter.searchHotel
                                            .RoomGuest[index]?.ChildAge[i]
                                          : 0
                                      }
                                      onChange={function (e: any) {
                                        updateRoomGuest(
                                          index,
                                          "ChildAge",
                                          Number(e.target.value),
                                          i
                                        );
                                      }}
                                    >
                                      <option value="0">Select Age</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="7">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                    </select>
                                  </div>
                                ))}
                              </div>
                            </React.Fragment>
                          </Stack>
                          <hr />
                        </React.Fragment>
                      )
                    )}
                    {searchHotelFilter.searchHotel.noOfRooms <= 5 ? (
                      <div
                        className="small add-room"
                        onClick={() => updateNoOfRooms("add")}
                      >
                        Add room <FaPlusCircle />
                      </div>
                    ) : (
                      <></>
                    )}
                  </CardBase>
                </div>
              )}
            </div>
          </div>

          <Button
            type={"submit"}
            color={"primary"}
            // size={"sm"}
            className="search-btn"
            disabled={!isFormFilled || isSearching}
            isLoading={isSearching}
            label={!isSearching ? allowLocationSearch ? "Modify" : "Search" : ""}
            onClick={() => { }}
          />
        </div>
      </form>
    </React.Fragment>
  );
}
