import axios from "axios";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import Button from "../../../components/Common/Button/Button";
import Input from "../../../components/Common/Input/Input";
import InputWithIcon from "../../../components/Common/Input/InputWithIcon";
import BsModal from "../../../components/Modal/BSModal";
import { showToast } from "../../../redux/reducers/commonState";
import { API_URL } from "../../../utils/constants";
import { TFlightResultList } from "../../../utils/types/flightTypes";
import { isValidEmail } from "../../../utils/utilityFunctions/utilityFunction";

type Props = {
  show: boolean;
  onClose: () => void;
  selectedFlights: TFlightResultList[];
};

const ShareFlightInfoModal = ({ show, onClose, selectedFlights }: Props) => {

  const dispatch = useDispatch();
  
  const [email, setEmail] = React.useState("");
  const [allEmails, setAllEmails] = React.useState<string[]>([]);
  const [emailError, setEmailError] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);



  const handleClose = () => {
    setEmail("");
    setAllEmails([]);
    setEmailError("");
    onClose();
  };
  const handleSendEmail = async () => {
    try {
      setIsLoading(true);
      const sendEmailRes = await axios.post(`${API_URL}sendItinerary`, {
        subject: "Flight Itinerary",
        body: JSON.stringify(selectedFlights),
        recipients: allEmails,
      });
      dispatch(
        showToast({
          type: "success",
          subTitle: "Email sent successfully",
          title: "",
        })
      );
    } catch (error) {
      console.log("email send error", error);
      dispatch(
        showToast({
          type: "error",
          subTitle: "Try again later.",
          title: "Something went wrong",
        })
      );
    }
    setIsLoading(false);
    handleClose();
  };



  return (
    <>
      {show && (
        <BsModal
          title="Share Flight Information"
          onClose={handleClose}
          position="top"
          body={
            <div className="d-flex flex-column">
              <div>
                <InputWithIcon
                  label="Enter email"
                  type="text"
                  value={email}
                  onChange={(value) => {
                    if (!isValidEmail(value) && value.length !== 0) {
                      setEmailError("Invalid email");
                    } else if (allEmails.includes(value)) {
                      setEmailError("Email already added");
                    } else {
                      setEmailError("");
                    }
                    setEmail(value);
                  }}
                  icon={
                    <Button
                      label={"add email"}
                      className={`text-primary bg-white border-0 ${
                        isValidEmail(email) ? "" : "opacity-75"
                      }`}
                      // color="none"
                      size="xs"
                    />
                  }
                  onIconClick={() => {
                    if (email.length > 0 && emailError.length === 0) {
                      setAllEmails([...allEmails, email]);
                      setEmail("");
                    }
                  }}
                  placeholder="enter email"
                />
                {emailError.length > 0 && (
                  <span className="text-danger fs-8 px-1">{emailError}</span>
                )}
              </div>
              {allEmails?.length > 0 && (
                <div className="">
                  <p className="pb-0 pt-2 mb-1">Selected Recipients</p>
                  <div className="share-email">
                    {allEmails.map((email, index) => (
                      <div
                        className="share-email-item"
                        onClick={() => {
                          setAllEmails(allEmails.filter((e, i) => i !== index));
                        }}
                      >
                        {email} <FaTimes size={12} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          }
          footer={
            <>
              <Button
                label={"Cancel"}
                size="xs"
                outline
                color="danger"
                onClick={handleClose}
              />
              <Button
                label={"Share"}
                size="xs"
                disabled={allEmails.length === 0 || isLoading}
                isLoading={isLoading}
                onClick={handleSendEmail}
              />
            </>
          }
        />
      )}
    </>
  );
};

export default ShareFlightInfoModal;
