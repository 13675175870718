import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "../initialState";

export const userSlice = createSlice({
  name: "user",
  initialState: initialState.user,
  reducers: {
    setUserData: (
      state,
      {
        payload,
      }: PayloadAction<{
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        userId: string;
        token: string;
        isAgent: boolean;
      }>
    ) => {
      state.isLoggedIn = true;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.phone = payload.phone;
      state.token = payload.token;
      state.userId = !payload.isAgent ? payload.userId : null;
      state.affiliateId = payload.isAgent ? payload.userId : null;
      state.isAgent = payload.isAgent;
    },
    setProfileData: (
      state,
      {
        payload,
      }: PayloadAction<{
        CustomerId: number;
        Title: string;
        FirstName: string;
        LastName: string;
        Email: string;
        Phone: any;
        Mobile: string;
        CountryId: string;
        StateId: string;
        City: string;
        Pincode: string;
        Address1: string;
        Address2: string;
      }>
    ) => {
      state.profile.CustomerId = payload.CustomerId;
      state.profile.Title = payload.Title;
      state.profile.FirstName = payload.FirstName;
      state.profile.LastName = payload.LastName;
      state.profile.Email = payload.Email;
      state.profile.Phone = payload.Phone;
      state.profile.Mobile = payload.Mobile;
      state.profile.CountryId = payload.CountryId;
      state.profile.StateId = payload.StateId;
      state.profile.City = payload.City;
      state.profile.Pincode = payload.Pincode;
      state.profile.Address1 = payload.Address1;
      state.profile.Address2 = payload.Address2;
    },

    logout: (state) => {
      state.isLoggedIn = false;
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.phone = "";
      state.userId = null;
      state.affiliateId = null;
      state.isAgent = false;
      state.token = "";
      state.profile = initialState.user.profile;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    addNewBooking: (state, { payload }: PayloadAction<string>) => {
      state.previousBookings = [...state.previousBookings, payload];
    },
    updateLocation: (
      state,
      { payload }: PayloadAction<{ latitude: number; longitude: number }>
    ) => {
      state.location = payload;
    },
  },
});

export const { setUserData, logout, setProfileData } = userSlice.actions;

const userReducer = userSlice.reducer;
export default userReducer;
