import React, { useState } from "react";
import SectionTitle from "../Common/SectionTitle/SectionTitle";
import AccordionItem from "../Common/AccordionItem/AccordionItem";
import List from "@kiwicom/orbit-components/lib/List";
import ListItem from "@kiwicom/orbit-components/lib/List/ListItem";
import CardBase from "../Common/CardBase/CardBase";
import AccordionCard from "../Common/AccordionCard/AccordionCard";

interface Props {}

const Faq = (props: Props) => {
  const [openedSection, setOpenedSection] = useState<number | string>(-1);
  const openSection = (index: number) => {
    openedSection === index ? setOpenedSection(-1) : setOpenedSection(index);
  };
  return (
    <div>
      <SectionTitle
        title="Frequently asked questions"
        subTitle="Answers for some of the most common queries"
      />
      <div className="row">
        <div className="col-md-6">
          <AccordionItem
            title="How to search for cheap flights?"
            body={
              <>
                Just enter your choice of cities and when to travel and our web
                app will find all the flights available which can then be
                filtered and sorted out by you as per your likings.
              </>
            }
            onOpen={() => openSection(0)}
            isCollapsed={openedSection !== 0}
          />
          <AccordionItem
            title="What's an e-ticket?"
            body={
              <>
                An e-ticket (electronic ticket) is a paperless electronic
                document with a unique confirmation number that neatly replaces
                the hassles of a paper ticket. When you purchase an e-ticket, we
                email it to you after the booking is complete. Simply print it
                out and bring it with you - along with a valid photo ID to the
                airline counter when checking in for your flight.
              </>
            }
            onOpen={() => openSection(1)}
            isCollapsed={openedSection !== 1}
          />
          <AccordionItem
            title="Getting zero flight results on the search page?"
            body={
              <>
                Clear your browser cache and try the search again. If you still
                don't get any flight results, it may either be because we can't
                find flights for that route or because there's no availability
                of flights for your dates.
              </>
            }
            onOpen={() => openSection(2)}
            isCollapsed={openedSection !== 2}
          />
        </div>
        <div className="col-md-6">
          <AccordionItem
            title="How do I know my ticket was booked?"
            body={
              <>
                We'll send you an SMS and an email to confirm your flight
                booking.
              </>
            }
            onOpen={() => openSection(3)}
            isCollapsed={openedSection !== 3}
          />
          <AccordionItem
            title="Do I have to show ID proof at the time of check-in?"
            body={
              <>
                Yes, for domestic flights within India, below are the applicable
                government issued photo-ids accepted by the airlines -
                <div className="px-3">
                  <List>
                    <ListItem>Voter ID</ListItem>
                    <ListItem>Aadhar Card</ListItem>
                    <ListItem>Pan Card</ListItem>
                    <ListItem>Driving License</ListItem>
                    <ListItem>Passport</ListItem>
                  </List>
                </div>
              </>
            }
            onOpen={() => openSection(4)}
            isCollapsed={openedSection !== 4}
          />
          <AccordionItem
            title="Is it possible to book tickets for another person through my account?"
            body={
              <>
                Yes, just enter the details of the passengers you want to book
                for when you’re asked to enter traveller–details at the time of
                booking.
              </>
            }
            onOpen={() => openSection(5)}
            isCollapsed={openedSection !== 5}
          />
        </div>
      </div>
    </div>
  );
};

export default Faq;
