import Bus from "../pages/Bus/Bus";
import FlightBooking from "../pages/Flight/FlightBooking/FlightBooking";
import Flight from "./../pages/Flight/Flight";
import Hotel from "./../pages/Hotel/Hotel";
import App from "../App";
import HotelBooking from "../pages/Hotel/HotelBeds/Pages/HotelBooking/HotelBooking";
import PMLPrivacyPolicy from "../components/PrivacyPolicy/PMLPrivacyPolicy";
import HotelDetails from "../pages/Hotel/HotelBeds/Pages/HotelBooking/HotelDetails";
import THotelDetails from "../pages/Hotel/TBO/Pages/HotelBooking/THotelDetails";
import THotelBooking from "../pages/Hotel/TBO/Pages/HotelBooking/THotelBooking";
import BookingHistory from "../pages/Profile/BookingHistory";
import ProfilePage from "../pages/Profile/ProfilePage";
import FlightBookingDetails from "../pages/Profile/Pages/Flight/FlightBookingDetails";
import HotelBookingDetails from "../pages/Profile/Pages/Hotel/HotelBookingDetails";

export interface routeType {
  slug: string;
  label: string;
  route: string;
  component: React.ReactElement;
  isAuthenticated?: boolean;
}

const CommonRoute: routeType[] = [
  {
    slug: "app",
    label: "App",
    route: "/",
    component: <App />,
  },
  {
    slug: "privacy-policy",
    label: "Privacy Policy",
    route: "/privacy-policy",
    component: <PMLPrivacyPolicy />,
  },
];
const FlightRoute: routeType[] = [
  {
    slug: "flight",
    label: "Flight",
    route: "/flight",
    component: <Flight />,
  },
  {
    slug: "booking",
    label: "Flight Booking",
    route: "/flight/booking",
    component: <FlightBooking />,
  },
];
const BusRoute: routeType[] = [
  {
    slug: "bus",
    label: "Bus",
    route: "/bus",
    component: <Bus />,
  },
];
const HotelRoute: routeType[] = [
  {
    slug: "hotel",
    label: "Hotel",
    route: "/hotel",
    component: <Hotel />,
  },
  // HOTELBEDS
  {
    slug: "details",
    label: "Hotel Details",
    route: "/hotel/HB/details",
    component: <HotelDetails />,
  },
  {
    slug: "booking",
    label: "Hotel Booking",
    route: "/hotel/HB/booking",
    component: <HotelBooking />,
  },
  // TBO
  {
    slug: "details",
    label: "Hotel Details",
    route: "/hotel/HT/details/:id/:resultIndex",
    component: <THotelDetails />,
  },
  {
    slug: "booking",
    label: "Hotel Booking",
    route: "/hotel/HT/booking",
    component: <THotelBooking />,
  },
];

const ProfileRoute: routeType[] = [
  {
    slug: "profile",
    label: "Profile",
    route: "/profile",
    component: <ProfilePage />,
    isAuthenticated: true,
  },
  {
    slug: "booking",
    label: "Booking",
    route: "/profile/booking/:type",
    component: <ProfilePage />,
    isAuthenticated: true,
  },
  {
    slug: "travelers list",
    label: "Travelers",
    route: "/profile/travelers",
    component: <ProfilePage />,
    isAuthenticated: true,
  },
  {
    slug: "flight ticket details",
    label: "FLight Ticket Details",
    route: "/profile/flight/ticket-details/:ticketID",
    component: <FlightBookingDetails />,
    isAuthenticated: true,
  },
  {
    slug: "hotel ticket details",
    label: "Hotel Ticket Details",
    route: "/profile/hotel/ticket-details/:ticketID",
    component: <HotelBookingDetails />,
    isAuthenticated: true,
  }
];
const Path = [CommonRoute, FlightRoute, BusRoute, HotelRoute, ProfileRoute];

export default Path;
