import { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';

import { hotelSlice } from '../../../redux/reducers/hotel';
import { searchHotelTBO } from "../../../Interfaces/SearchHotel/SearchHotel";
// import searchHotelBeds from "../../../Interfaces/SearchHotel/SearchHotel";

import {
    ICreateBooking,
    IBookHotelResponse,
    IBlocakRoomResponse,
    IBlockRoom,
    IBookHotel,
    ITBOAvailabilityPayload
} from "../../types/hotelTypes";
import {
    ICommon,
    IHotel,
    IHotelCharges,
    IPassenger,
    IHotelDetails,
    IRoom,
    ISelectedHotel,
    State,
    ITBORoom,
    IUser
} from './../../../redux/types';
import {
    formatHotelAvailabilityList,
    formatPayloadForTBOBlockRoom,
    formatRoomsToCombinationArr
} from '../../utilityFunctions/hotelUtitlityFunctions';
import useHotel from './useHotel';
import useHotelMetaData from './useHotelMetaData';
import { commonStateSlice } from '../../../redux/reducers/commonState';
import { tboPostBookingHotel } from '../../../Interfaces/SearchHotel/PostBookHotel';

export default function useHotelDetails() {

    const history = useHistory();
    const dispatch = useDispatch();

    const [
        { userId },
        { searchHotel, filters, passenger, metaData, TBO },
        { Client, ClientData }
    ]: [IUser, IHotel, ICommon] = useSelector((state: State) => [
        state.user,
        state.hotel,
        state.commonState
    ]);

    const {
        updateSearchedHotelList,
        removeSelectedHotel,
        handleShowToast,
        updateTraceId,
        handleHotelListingInfo,
        updateSelectedHotel,
        updatePaginationInfo,
        resetPaginationInfo,
        updateSelectedRoom
    } = useHotel();
    const { handlePostMarkup, handleCreateBooking } = useHotelMetaData();


    const [hotels, setHotels] = useState<ISelectedHotel[]>([]); // stores the list of hotels
    const [hotelInfo, setHotelInfo] = useState<IHotelDetails>(); // stores the information of selected hotel
    const [roomsByCombination, setRoomsByCombination] = useState<ITBORoom[][]>([]) // stores the information of selected room

    // stores response of blockRoom request
    const [blockRoomResponse, setBlockRoomResponse] = useState<IBlocakRoomResponse>();
    // stores response of room details request
    const [roomDetailsResponse, setRoomDetailsResponse] = useState<any>();
    // for booking status
    const [isHotelBookingSuccess, setIsHotelBookingSuccess] = useState<"Confirmed" | "Failed" | "">("");

    // show modal for price | policy changes
    const [showModal, setShowModal] = useState<boolean>(false);
    // show modal for AvailabilityType - when type === 'Available'
    const [showModalAvailabilityType, setShowModalAvailabilityType] = useState<boolean>(false);

    // loading
    const [isInfoLoading, setIsInfoLoading] = useState<boolean>(false);
    const [isRoomsLoading, setIsRoomsLoading] = useState<boolean>(false);
    const [isBlockRoomInProgress, setIsBlockRoomInProgress] = useState<boolean>(false);
    const [isBookingInProgress, setIsBookingInProgress] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false); // for search hotel


    /* --------------------------------------------------
    |   Get hotel details
    -------------------------------------------------- */
    // fetch country list
    const fetchCountryList = () => {
        searchHotelTBO.getCountryList().then(
            (response: AxiosResponse) => {
                console.log("Response for available countries: ", response?.data);
            },
            (error: AxiosError) => {
                console.log("Error for available countries: ", error?.response);
            }
        );
    };

    // fetch top destinations
    const fetchTopDestinations = () => {
        searchHotelTBO.getTopDestinationList().then(
            (response: AxiosResponse) => {
                console.log("Response for top destinations: ", response?.data);
            },
            (error: AxiosError) => {
                console.log("Error for top destinations: ", error?.response);
            }
        );
    };

    // fetch destination list
    const fetchDestinationSearch = (country: string, type: "1" | "2") => {
        searchHotelTBO.getDestinatioSearch(country, "1").then(
            (response: AxiosResponse) => {
                console.log("Destinations from TBO: ", response.data?.data?.Destinations)
                let delhi = response.data?.data?.Destinations.filter((destination: any) => (
                    destination.CityName?.toLowerCase()?.includes("mumbai")
                ));
                console.log("Delhi: ", delhi);
            },
            (error: AxiosError) => {
                console.log("Error for available destinations: ", error?.response);
            }
        );
    };

    /* --------------------------------------------------
    |   Get Hotel List from TBO API
    -------------------------------------------------- */
    // search hotels based on earch parameters
    const searchHotelHandlerTBO = () => {
        removeSelectedHotel("TBO");

        let checkIn = moment(searchHotel.checkInDate).format(
            "YYYY-MM-DD"
        );
        let checkOut = moment(searchHotel.checkOutDate).format(
            "YYYY-MM-DD"
        );

        if (moment(checkOut).isAfter(checkIn)) {
            // checking whether destination is selected or not
            if (searchHotel.whereTo.HT_DestinationNumber !== "") {
                updateSearchedHotelList([], "TBO");
                updateTraceId("");

                let payload: ITBOAvailabilityPayload = {
                    checkInDate: moment(searchHotel.checkInDate).format(
                        "DD/MM/YYYY"
                    ),
                    noOfNights: String(
                        moment(searchHotel.checkOutDate).diff(
                            searchHotel.checkInDate,
                            "days"
                        )
                    ),
                    cityId: String(searchHotel.whereTo.HT_DestinationNumber), // String(144306)
                    roomGuest: searchHotel.RoomGuest,
                    noOfRooms: String(searchHotel.RoomGuest?.length),
                    maxRating: "5",
                    minRating: filters?.StarRating[0] || String(1),
                };
                // resetPaginationInfo("TBO");

                searchHotelTBO.getAvailability(payload).then(
                    (response: AxiosResponse) => {
                        updateSearchedHotelList([], "TBO");
                        // console.log("Response for available hotels: ", response?.data?.data);
                        // console.log("Hotel list for TBO: ", formatHotelAvailabilityList(
                        //     response?.data?.data?.HotelSearchResult?.HotelResults,
                        //     checkIn,
                        //     checkOut,
                        //     "TBO"
                        // ));
                        handleHotelListingInfo(
                            formatHotelAvailabilityList(
                                response?.data?.data?.HotelSearchResult?.HotelResults,
                                checkIn,
                                checkOut,
                                "TBO"
                            )?.slice(0, TBO.paginationInfo.pageSize)
                        );
                        updatePaginationInfo(
                            "totalPages",
                            Math.ceil(response?.data?.data?.HotelSearchResult?.HotelResults.length / TBO.paginationInfo.pageSize),
                            "TBO"
                        );
                        updateSearchedHotelList(response?.data?.data?.HotelSearchResult?.HotelResults, "TBO");
                        console.log("Trace ID: ", response?.data?.data?.HotelSearchResult?.TraceId);
                        updateTraceId(response?.data?.data?.HotelSearchResult?.TraceId);
                    }, (error: AxiosError) => {
                        console.log("Error for available hotels: ", error?.response);
                    }
                );

            } else {
                handleShowToast({
                    title: "City Not Selected!",
                    subTitle: "Please select a city to search hotels",
                    type: "error",
                });
            }
        } else {
            handleShowToast({
                title: "Invalid Dates!",
                subTitle: "Check-out date should be greater than check-in date",
                type: "error",
            });
        }
    };

    // sets searched hotel result list by removing duplicate hotels
    // const handleHotelList = (payload: ISelectedHotel[]) => {
    //     // we are removing duplicate hotels
    //     let unique_hotels: ISelectedHotel[] = payload?.filter((value, index, self) =>
    //         index === self.findIndex((t) => (
    //             t?.HotelName === value?.HotelName && t?.HotelName === value?.HotelName
    //         ))
    //     );
    //     // console.log("Original length: ", payload.length, "Unique length: ", unique_hotels.length);
    //     // setHotels(payload);
    //     updateSearchedHotelList(unique_hotels);
    //     // setHotels(unique_hotels);
    //     handleHotelFilters('price', "",
    //         String(Math.ceil(unique_hotels?.reduce((acc, shot) => acc = acc > shot.Price.OfferedPrice ? acc : shot.Price.OfferedPrice, 0) || 0))
    //     );
    // };


    /* --------------------------------------------------
    |              For Selected Hotel Details
    -------------------------------------------------- */
    // fetch selected hotel details
    const handleHotelDetails = (resultIndex: string, hotelCode: string, traceId: string) => {
        setIsInfoLoading(true);
        searchHotelTBO.getParticularHotelDetail(resultIndex, hotelCode, traceId).then(
            (response: AxiosResponse) => {
                setHotelInfo(response?.data?.data?.HotelInfoResult?.HotelDetails);
                // updateSelectedHotel(response?.data?.data?.HotelInfoResult?.HotelDetails, "TBO");
                dispatch(hotelSlice.actions.updateTraceId(response?.data?.data?.HotelInfoResult?.TraceId));
            }, (error: AxiosError) => {
                console.log("Error for hotel detaild: ", error?.response);
                if (error?.response?.data?.message === "Your session (TraceId) is expired.") {
                    setTimeout(() => { window.close() }, 3000);
                    handleShowToast({ title: "Session Expired!", subTitle: "Your session has expired. Please search hotel again.", type: "error" });
                } else {
                    handleShowToast({ title: "Error!", subTitle: error?.response?.data?.message, type: "error" });
                }
            }
        ).finally(() => setIsInfoLoading(false));
    };

    // fetch selected hotel room information
    const handleHotelRoomDetails = (resultIndex: string, hotelCode: string, traceId: string) => {
        setIsRoomsLoading(true);
        searchHotelTBO.getSelectedHotelRooms(resultIndex, hotelCode, traceId).then(
            (response: AxiosResponse) => {
                // console.log("Response for hotel room: ", response?.data?.data?.GetHotelRoomResult?.HotelRoomsDetails);
                // setRooms(response?.data?.data?.GetHotelRoomResult?.HotelRoomsDetails);
                setRoomDetailsResponse(response?.data?.data);
                setRoomsByCombination(formatRoomsToCombinationArr(response?.data?.data?.GetHotelRoomResult));
                // console.log("Room Combination for TBO: ", formatRoomsToCombinationArr(response?.data?.data?.GetHotelRoomResult))
            }, (error: AxiosError) => {
                console.log("Error for hotel room: ", error?.response);
            }
        ).finally(() => setIsRoomsLoading(false));
    };

    /* --------------------------------------------------
    |              For Block Room & Booking Hotel
    -------------------------------------------------- */
    // block rooms for selected hotel
    const handleBlockRooms = async (payload: IBlockRoom, booking_payload: IBookHotel) => {
        setIsBlockRoomInProgress(true);

        let obj: {
            IsSuccess: boolean,
            data: IBlocakRoomResponse | null,
            error: AxiosError | null
        };

        return searchHotelTBO.blockRoom(payload).then(
            (response: AxiosResponse) => {
                setBlockRoomResponse(response?.data?.data?.BlockRoomResult);
                obj = {
                    IsSuccess: true,
                    data: response?.data?.data?.BlockRoomResult,
                    error: null
                };
                return obj;
            }, (error: AxiosError) => {
                console.log("Error for block room: ", error?.response);
                obj = {
                    IsSuccess: false,
                    data: null,
                    error: error
                };
                return obj;
            }
        ).finally(() => {
            setIsBlockRoomInProgress(false);
        });
    };

    // book hotel
    const handleBookHotel = (payload: IBookHotel) => {
        setIsBookingInProgress(true);

        searchHotelTBO.bookHotel(payload).then(
            (response: AxiosResponse) => {
                // console.log("Response for block room: ", response?.data);
                let res: IBookHotelResponse = response?.data;

                // check Status of booking request
                if (res.data.BookResult.Status === 0) {
                    setIsHotelBookingSuccess("Failed");
                    handleShowToast({
                        title: "Booking Failed!",
                        subTitle: "Sorry, your booking has failed. Please try again.",
                        type: "warning",
                    });
                } else if (res.data.BookResult.Status === 1) {
                    console.log("Booking Confirmed!");
                    setIsHotelBookingSuccess("Confirmed");
                    // cancelBooking(response.data?.data?.BookResult?.BookingId); // for testing
                } else if (res.data.BookResult.Status === 3) {
                    console.log("Verify Price!");
                } else if (res.data.BookResult.Status === 6) {
                    console.log("Booking Cancelled!");
                }

                // checking whether price or policy has been changed
                if (res?.data?.BookResult?.IsPriceChanged || res.data?.BookResult?.IsCancellationPolicyChanged) {
                    updateSelectedRoom(res?.data?.BookResult?.HotelRoomDetails || [], "TBO");
                    setShowModal(true);
                } else {
                    // handleCreateHotelBooking(response?.data);
                    handleCreateHotelBooking(
                        response.data?.data?.BookResult?.BookingId,
                        response.data?.data?.BookResult?.BookingRefNo
                    );
                }

            }, (error: AxiosError) => {
                console.log("Error for block room: ", error?.response);
                if (error?.response?.data?.message === "PAN is mandatory") {
                    handleShowToast({
                        title: "PAN Mandatory!",
                        subTitle: "Please provide PAN for all guests.",
                        type: "warning",
                    });
                } else {
                    handleShowToast({
                        title: "Issue!",
                        subTitle: error?.response?.data?.message,
                        type: "warning",
                    });
                }
            }
        ).finally(() => setIsBookingInProgress(false));
    };

    /* --------------------------------------------------
    |   Requests for block room and book hotel after block room success
    |   This function uses the handleBlockRooms and handleBookHotel functions from above
     ---------------------------------------------------- */
    const blockRoom = async () => {

        /* 
        The below code is validating the guest details (FirstName) 
        If any one of the guest first name is empty then it will 
        assign validateGuestDetails as true
        */
        let validateGuestDetails = false;
        passenger.forEach((eachRoom) => {
            eachRoom.forEach((eachPassengerDetail) => {
                eachPassengerDetail.FirstName.trim().length === 0 && (validateGuestDetails = true)
            })
        });
        if (validateGuestDetails) {
            handleShowToast({
                title: "Invalid Guest Info!",
                subTitle: "Please provide the guest information correctly for all the guests.",
                type: "warning",
            });
        } else {
            let { blockRoomPayload, bookingPayload } = formatPayloadForTBOBlockRoom(
                TBO.searchedHotel,
                searchHotel,
                TBO.selectedRoom,
                passenger,
                TBO.TraceId
            );

            const response = await handleBlockRooms(blockRoomPayload, bookingPayload);

            if (response.IsSuccess) {
                // checking IsPackageFare is true or false for book request
                response?.data?.IsPackageFare
                    ? (bookingPayload.IsPackageFare = "true")
                    : (bookingPayload.IsPackageFare = "false");
                // checking IsPackageDetailsMandatory is true or false for book request
                response?.data?.IsPackageDetailsMandatory
                    ? (bookingPayload.IsPackageDetailsMandatory = "true")
                    : (bookingPayload.IsPackageDetailsMandatory = "false");

                /* checking booking for selected hotel is available or not
                If 'Confirm' then booking can be done for selected hotel */
                if (response.data?.AvailabilityType === "Confirm") {
                    /* checking whether price or cancelation policy has been updated or not.
                     if price or cancelation policy has been updated, then update room details in redux store
                     and open a modal to inform the customer about the changes.
                     The customer desides whether they want to proceed with the booking after
                     reviewing the changed price & polocy */
                    if (
                        response.data?.IsPriceChanged ||
                        response.data?.IsCancellationPolicyChanged
                    ) {
                        updateSelectedRoom(response.data?.HotelRoomsDetails, "TBO");
                        setShowModal(true);
                    } else {
                        // booking when selected hotel is available
                        handleBookHotel(bookingPayload);
                    }
                } else {
                    setShowModalAvailabilityType(true);
                    console.log("Hotel is not confirmed!!!");
                }
            } else {
                console.log("Error in room hold: ", response?.error);
                if (
                    response?.error?.response?.data?.message === "Your session (TraceId) is expired."
                ) {
                    handleShowToast({
                        title: "Session Expired!",
                        subTitle: "Your session has expired. Please search hotel again.",
                        type: "error",
                    });
                    setTimeout(() => window.close(), 3000);
                } else if (
                    response?.error?.response?.data?.message ===
                    "No of Room mismatched with HotelRoomsDetails."
                ) {
                    handleShowToast({
                        title: "Room Not Selected!",
                        subTitle: "Please select room type first to continue booking.",
                        type: "warning",
                    });
                } else {
                    handleShowToast({
                        title: "Issue!",
                        subTitle: response?.error?.response?.data?.message,
                        type: "error",
                    });
                }
            }

        }

    };

    /**
     * It cancels the booking.
     * @param {number} bookingID - The ID of the booking to be cancelled.
     */
    const cancelBooking = (bookingID: number) => {
        searchHotelTBO.cancelBooking(bookingID).then(
            (response: AxiosResponse) => {
                console.log("Response for cancel booking: ", response?.data);
                getHotelCancelStaus(response.data?.data?.HotelChangeRequestResult?.ChangeRequestId);
            }, (error: AxiosError) => {
                console.log("Error for cancel booking: ", error?.response);
            }
        )
    };

    /**
     * It gets the status of the booking cancellation.
     * @param {number} changeRequestID - The ID of the change request that you want to cancel.
     */
    const getHotelCancelStaus = (changeRequestID: number) => {
        searchHotelTBO.getHotelCancelDetails(changeRequestID).then(
            (response: AxiosResponse) => {
                console.log("Response for cancel booking: ", response?.data);
                if (response.data?.data?.HotelChangeRequestStatusResult?.ChangeRequestStatus === 3) {
                    console.log("Booking Cancelled!");
                }
            }, (error: AxiosError) => {
                console.log("Error for cancel booking: ", error?.response);
            }
        );
    };

    // creates booking at server side(.NET - Admin Portal)
    const handleCreateHotelBooking = (referenceId: string, BookingRefNo: string) => {
        tboPostBookingHotel.createBooking(
            ClientData,
            Client,
            userId!==null? +userId:0,
            metaData,
            TBO.selectedRoom,
            searchHotel,
            TBO.searchedHotel,
            passenger,
            referenceId,
            BookingRefNo
        ).then((response: AxiosResponse) => {
            console.log("Response for CreateBooking: ", response?.data);
        },
            (error: AxiosError) => {
                console.log("Error for CreateBooking: ", error?.response);
            }
        );
    };

    return {
        fetchTopDestinations,
        fetchDestinationSearch,
        hotels,
        searchHotelHandlerTBO,
        isLoading,
        hotelInfo,
        handleHotelDetails,
        handleHotelRoomDetails,
        // rooms,
        roomsByCombination,
        isInfoLoading,
        isRoomsLoading,
        isBlockRoomInProgress,
        isBookingInProgress,
        blockRoomResponse,
        roomDetailsResponse,
        isHotelBookingSuccess,
        setIsHotelBookingSuccess,
        showModal,
        setShowModal,
        showModalAvailabilityType,
        setShowModalAvailabilityType,
        blockRoom
    };

}
