import moment from "moment";
import { Moment } from "moment";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import "../flight.scss";

interface Props {
  departureDate: Moment;
  minDate: Moment;
  maxDate?: Moment;
  minPrice?: number;
  onSelect: (date: string) => void;
}
interface PropsTab {
  date: string;
  active?: boolean;
  price?: number;
  onSelect: () => void;
}
const Tab = ({ active, price, date, onSelect }: PropsTab) => {
  return (
    <div
      className={`date-selector align-self-start ${active ? "selected" : ""}`}
      onClick={() => onSelect()}
    >
      <div className="text-dark small mb-1">{date}</div>
      {price && <div className="fw-bold small">&#8377;{price}</div>}
    </div>
  );
};

export default function DateChangeTab({
  minPrice,
  departureDate,
  minDate,
  maxDate,
  onSelect,
}: Props) {
  const handleSelect = (dateType: "yesterday" | "tomorrow") => {
    let date = departureDate;
    if (dateType === "yesterday") {
      date = date.subtract(1, "days");
    } else if (dateType === "tomorrow") {
      date = date.add(1, "days");
    }
    if (
      date.isSameOrAfter(minDate) &&
      (maxDate ? date.isSameOrBefore(maxDate) : true)
    ) {
      onSelect(date.format("YYYY-MM-DD"));
    }
  };
  return (
    <div className="w-100 d-flex justify-content-evenly align-items-center  pt-2 mb-3 bg-white rounded ">
      <div
        className="text-muted mb-3 pointer"
        onClick={() => handleSelect("yesterday")}
      >
        <IoChevronBack />
      </div>
      <Tab
        date={"Prev Day"}
        // date={moment(departureDate).subtract(1, "days").format("ddd, DD MMM")}
        onSelect={() => handleSelect("yesterday")}
      />
      <Tab
        date={moment(departureDate).format("ddd, DD MMM")}
        onSelect={() => {}}
        active
        price={minPrice}
      />
      <Tab
        // date={moment(departureDate).add(1, "days").format("ddd, DD MMM")}
        date={"Next Day"}
        onSelect={() => handleSelect("tomorrow")}
      />
      <div
        className="text-muted mb-3 pointer"
        onClick={() => {
          handleSelect("tomorrow");
        }}
      >
        <IoChevronForward />
      </div>
    </div>
  );
}
