import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import moment from 'moment';

import Tab from '../../../../../components/Common/Tab/Tab';
import BsModal from '../../../../../components/Modal/BSModal';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import Image from "../../../../../components/Common/Image/Image";
import { ChevronRight, StarFull } from '@kiwicom/orbit-components/lib/icons';
import Button from '../../../../../components/Common/Button/Button';
import CardBase from '../../../../../components/Common/CardBase/CardBase';
import THotelDetailsRoomInfo from '../../Components/THotelDetailsRoomInfo';
import ListCheck from '../../../../../components/Common/ListCheck/ListCheck';

import { ICommon, IHotel, ITBORoom, State } from '../../../../../redux/types';
import useTBO from '../../../../../utils/hooks/Hotel/useTBO';
import useHotel from '../../../../../utils/hooks/Hotel/useHotel';
import { useElementOnScreen } from '../../../../../utils/hooks/useElementOnScreen';
import { calculateMarkedupBaseCharge, splitString } from '../../../../../utils/utilityFunctions/utilityFunction';
import { getTotalGuestCountForHotel } from '../../../../../utils/utilityFunctions/hotelUtitlityFunctions';

export default function THotelDetails() {

    const history = useHistory();
    const params: { id: string, resultIndex: string } = useParams();
    const [
        {searchHotel, TBO, metaData},
        {ClientData}
    ]: [IHotel, ICommon] = useSelector((state: State) => [
        state.hotel,
        state.commonState
    ]);

    const {
        hotelInfo,
        handleHotelDetails,
        // rooms,
        // blockRoomResponse,
        roomsByCombination,
        handleHotelRoomDetails,
        isInfoLoading,
        isRoomsLoading,
        roomDetailsResponse
    } = useTBO();
    const { updateSelectedRoom, removeSelectedRoom } = useHotel();

    const [activeImageIndex, setActiveImageIndex] = useState<number>(1); // for hotel image slider
    const [showAllAmenities, setShowAllAmenities] = useState<boolean>(false); // to show all available amenities
    const [showFullDescription, setShowFullDescription] = useState<boolean>(false); // to show full description
    const [showImageModal, setShowImageModal] = useState<boolean>(false); // to show modal for hotel images

    const scrollToTop = useRef<HTMLDivElement>(null); // for scroll to top
    // for intersaction observer - For sticky tabs(Overview, Room)
    const [containerRef, isVisible] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0.001,
    });
    const [activeTab, setActiveTab] = useState<number>(0); // for active tab
    // go to overview tab
    const overview = useRef<HTMLDivElement>(null);
    const scrollToOverview = () => { overview?.current?.scrollIntoView({ behavior: "smooth" }); setActiveTab(0); };
    // go to rooms tab
    const room = useRef<HTMLDivElement>(null);
    const scrollToRooms = () => { room?.current?.scrollIntoView({ behavior: "smooth" }); setActiveTab(1); };

    const [selectedRooms, setSelectedRooms] = useState<ITBORoom[]>([]); // selectedRooms for hotel booking based on room combination

    useEffect(() => {
        scrollToTop?.current?.scrollIntoView({ behavior: "smooth" });
        /* Setting the title of the page to the name of the hotel. */
        document.title = TBO.searchedHotel.HotelName;
        handleHotelDetails(String(params?.resultIndex), params?.id, TBO.TraceId);
        handleHotelRoomDetails(String(params?.resultIndex), params?.id, TBO.TraceId);
    }, [params.id, params.resultIndex]);

    useEffect(() => {
        console.log("Selecetd rooms: ", selectedRooms);
        // if selectedRooms.length is equal to no. of searched rooms then all rooms are selected
        if (selectedRooms.length === searchHotel.noOfRooms) {
            // push to redux
            updateSelectedRoom(selectedRooms, "TBO");
            setSelectedRooms([]);
        }
    }, [selectedRooms]);

    // selects all rooms based on room combination
    const handleRoomSelect = (combinationType: 'FixedCombination' | 'OpenCombination' | string, selectedCombinationIndex: number) => {
        if (combinationType === 'FixedCombination') {
            setSelectedRooms(roomsByCombination[selectedCombinationIndex]);
        } else {
            // OpenCombination
            setSelectedRooms(prev => ([...prev, roomsByCombination[selectedCombinationIndex][0]]));
        }
    }

    return (
        <React.Fragment>
            <div className="searched-hotel-container h-100">
                <div className="container">
                    <CardBase title="">
                        <div className="hotel-details" ref={scrollToTop}>

                            {/* HEADER */}
                            <div className="header">
                                <div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <h2 className="mb-0">{hotelInfo?.HotelName}</h2>
                                        <div className="star-rating">
                                            {[...Array(TBO.searchedHotel?.StarRating)].map((_, index) => (
                                                <StarFull size="small" key={index} />
                                            ))}
                                        </div>
                                    </div>
                                    <p className="text-muted mb-0 mt-1">{hotelInfo?.Address}</p>
                                    <span><span className="fw-bold">Email: </span> {hotelInfo?.Email || <span className="text-muted">Not Available</span>}</span><br />
                                    <span><span className="fw-bold">Contact: </span> {hotelInfo?.HotelContactNo || <span className="text-muted">Not Available</span>}</span><br />
                                    <span><span className="fw-bold">Fax: </span> {hotelInfo?.FaxNumber || <span className="text-muted">Not Available</span>}</span>
                                </div>

                                <div className="text-end d-flex flex-column align-items-center gap-2">
                                    <div>
                                        <small className="text-muted">
                                            For {moment(searchHotel.checkOutDate).diff(searchHotel.checkInDate, "days") > 1
                                                ?
                                                moment(searchHotel.checkOutDate).diff(searchHotel.checkInDate, "days") + " nights"
                                                :
                                                moment(searchHotel.checkOutDate).diff(searchHotel.checkInDate, "days") + " night"}
                                        </small>
                                    </div>
                                </div>
                            </div>

                            {/* TABS */}
                            <div className="m-2 mb-0 mx-0 sticky-container">
                                <div className={`d-flex px-2 ${isVisible && "hotel-details-tabs"}`}>
                                    <Tab title="Overview" isActive={activeTab === 0 ? true : false} goTo={() => scrollToOverview()} />
                                    <Tab title="Rooms" isActive={activeTab === 1 ? true : false} goTo={() => scrollToRooms()} />
                                </div>
                            </div>
                            <div className="spacer spacer--sm"></div>

                            <div ref={containerRef as React.RefObject<HTMLDivElement>}> {/* reference for intersaction observer*/}
                                {/* OVERVIEW */}
                                <div ref={overview}>
                                    <div className="image-grid-container">
                                        {/* IMAGE GRID ON WEB VIEW */}
                                        <div className="image-grid mt-2">
                                            <div className="row g-1">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="image">
                                                        <img
                                                            src={hotelInfo?.Images && hotelInfo?.Images[0]}
                                                            alt=""
                                                            onClick={() => {
                                                                if (hotelInfo?.Images[0]) {
                                                                    setActiveImageIndex(0);
                                                                    setShowImageModal(true);
                                                                }
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12">
                                                    <div className="image">
                                                        <img
                                                            src={hotelInfo?.Images && hotelInfo?.Images[1]}
                                                            alt=""
                                                            onClick={() => {
                                                                if (hotelInfo?.Images[1]) {
                                                                    setActiveImageIndex(1);
                                                                    setShowImageModal(true);
                                                                }
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-12">
                                                    <div className="image">
                                                        <div className="image-count">
                                                            <img
                                                                src={hotelInfo?.Images && hotelInfo?.Images[2]}
                                                                alt=""
                                                                onClick={() => {
                                                                    if (hotelInfo?.Images[2]) {
                                                                        setActiveImageIndex(2);
                                                                        setShowImageModal(true);
                                                                    }
                                                                }} />
                                                            {hotelInfo?.Images && hotelInfo?.Images?.length > 3 ? <div
                                                                className="count"
                                                                onClick={() => {
                                                                    if (hotelInfo?.Images[2]) {
                                                                        setActiveImageIndex(2);
                                                                        setShowImageModal(true);
                                                                    }
                                                                }}
                                                            >
                                                                +{hotelInfo?.Images?.length - 3}
                                                            </div> : <></>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* IMAGE CAROUSEL ON MOBILE VIEW */}
                                        <div id="carouselExampleControls" className="carousel slide image-slider" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                                {
                                                    hotelInfo?.Images && hotelInfo?.Images?.map((image, index) => (
                                                        <div className={`carousel-item ${activeImageIndex === index && 'active'}`} key={index}>
                                                            <Image
                                                                src={image}
                                                                className={"rounded"}
                                                                style={{ height: 200, width: "100%", objectFit: "contain" }}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {activeImageIndex ? <button
                                                className="carousel-control-prev"
                                                type="button"
                                                data-bs-target="#carouselExampleControls"
                                                data-bs-slide="prev"
                                                onClick={() => setActiveImageIndex(activeImageIndex - 1)}
                                            >
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </button> : <></>}
                                            {(activeImageIndex + 1) !== hotelInfo?.Images?.length ? <button
                                                className="carousel-control-next"
                                                type="button"
                                                data-bs-target="#carouselExampleControls"
                                                data-bs-slide="next"
                                                onClick={() => setActiveImageIndex(activeImageIndex + 1)}
                                            >
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button> : <></>}
                                        </div>

                                        <div className="my-3">
                                            <div className="row d-flex justify-content-between">
                                                {/* Description */}
                                                {!isInfoLoading ? <React.Fragment>
                                                    <div className="col-12">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: String(showFullDescription && hotelInfo?.Description
                                                                    ?
                                                                    hotelInfo?.Description
                                                                    :
                                                                    splitString(hotelInfo?.Description || "", 500) + " ...")
                                                            }} />
                                                        <span
                                                            className="show-hide-text"
                                                            onClick={() => setShowFullDescription(!showFullDescription)}
                                                        >{showFullDescription ? 'Hide' : 'View more'}
                                                        </span>
                                                        <div className="spacer spacer--sm"></div>
                                                        <div className="row">
                                                            {/* Amenities */}
                                                            <div className="col-md-6">
                                                                <h4>Amenities</h4>
                                                                {/* {
                        showAllAmenities ? hotelInfo?.HotelFacilities?.map((amenity, index) => (
                          amenity.includes(",") ? amenity.split(",").map((item, index) => (
                            <ListCheck title={item} key={index} />
                          )) : <ListCheck title={amenity} key={index} />
                        )) : hotelInfo?.HotelFacilities?.slice(0, 5).map((amenity, index) => (
                          amenity.includes(",") ? amenity.split(",").map((item, index) => (
                            <ListCheck title={item} key={index} />
                          )) : <ListCheck title={amenity} key={index} />
                        ))
                      } */}
                                                                {
                                                                    hotelInfo?.HotelFacilities?.slice(0, 15).map((amenity, index) => (
                                                                        hotelInfo?.HotelFacilities?.length === 1 && amenity.includes(",")
                                                                            ?
                                                                            amenity.split(",").slice(0, 15).map((item, index) => (
                                                                                <ListCheck title={item} key={index} />
                                                                            ))
                                                                            :
                                                                            <ListCheck title={amenity} key={index} />
                                                                    ))
                                                                }
                                                                <div className="spacer spacer--sm"></div>
                                                                <span
                                                                    className="show-hide-text"
                                                                    onClick={() => setShowAllAmenities(!showAllAmenities)}>
                                                                    {showAllAmenities ? 'Hide' : 'Show all'}
                                                                </span>
                                                            </div>
                                                            <div className="col-md-6">
                                                                {hotelInfo?.Attractions && <React.Fragment>
                                                                    <h4>Attractions</h4>
                                                                    {hotelInfo?.Attractions?.map((each: { key: string | number, Value: string }, index: number) => (
                                                                        <React.Fragment key={index}>
                                                                            <div dangerouslySetInnerHTML={{
                                                                                __html: each?.Value ? each?.Value : ""
                                                                            }} />
                                                                        </React.Fragment>
                                                                    ))}</React.Fragment>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment> : <Loading
                                                    dataTest="test"
                                                    loading
                                                    text="Please wait, we are loading details..."
                                                    type="pageLoader"
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Rooms */}
                                <div ref={room}>
                                    <div className="section-rooms">
                                        <div className="section-title">Rooms</div>
                                        <div className="row g-2">
                                            {!isRoomsLoading
                                                ?
                                                roomsByCombination.length > 0 && roomsByCombination?.map((room, index) => (
                                                    <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                                                        <THotelDetailsRoomInfo
                                                            room={room[0]}
                                                            onSelect={() => handleRoomSelect(String(roomDetailsResponse?.GetHotelRoomResult?.RoomCombinations?.InfoSource), index)}
                                                            onRemove={() => removeSelectedRoom("TBO")}
                                                        />
                                                    </div>
                                                ))
                                                :
                                                <Loading
                                                    dataTest="test"
                                                    loading
                                                    text="Please wait, we are loading rooms..."
                                                    type="pageLoader"
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </CardBase>
                </div>
            </div>

            {/* SELECTED COMBINATION SECTION */}
            {TBO.selectedRoom.length > 0 ? <motion.div
                className="selected-room-combination"
                initial={{ translateY: "-50%", scale: 0.5, opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                animate={{
                    translateY: 0,
                    scale: 1,
                    opacity: 1,
                }}
            >
                <div className="container">
                    <div className="selected-container">
                        <div className="d-flex flex-column">
                            <span className="small">
                                {moment(searchHotel.checkOutDate).diff(
                                    searchHotel.checkInDate,
                                    "days"
                                )}{" "}
                                night stay
                            </span>
                            <h3 className="mb-0">
                                {calculateMarkedupBaseCharge(
                                    metaData?.markUps,
                                    metaData?.affiliateMarkup,
                                    TBO.selectedRoom,
                                    "TBO",
                                    "INR",
                                    ClientData.GSTRates[0]?.Value,
                                    searchHotel.RoomGuest,
                                    "TBO"
                                )}
                            </h3>
                            <span className="small">Exclusive taxes</span>
                        </div>
                        <Button
                            type={"button"}
                            color={"light"}
                            className={"text-primary"}
                            size={"sm"}
                            label={<span className="d-flex align-items-center">Book Now <ChevronRight /></span>}
                            onClick={(e) => { history.push("/hotel/HT/booking") }}
                        />
                    </div>
                </div>
            </motion.div> : <></>}

            {/* Modal for all amenities */}
            {showAllAmenities ? <React.Fragment>
                <BsModal
                    title={"Amenities"}
                    scrollable={true}
                    onClose={() => setShowAllAmenities(false)}
                    body={<div>
                        {
                            hotelInfo?.HotelFacilities?.map((amenity, index) => (
                                hotelInfo?.HotelFacilities?.length === 1 && amenity.includes(",")
                                    ?
                                    amenity.split(",")?.map((item, index) => (
                                        <ListCheck title={item} key={index} />
                                    ))
                                    :
                                    <ListCheck title={amenity} key={index} />
                            ))
                        }
                    </div>}
                    footer={<Button
                        type="button"
                        color="primary"
                        size="sm"
                        label="Close"
                        onClick={() => setShowAllAmenities(false)}
                    />}
                ></BsModal>
                <div className="modal-backdrop fade show"></div>
            </React.Fragment> : <></>}

            {/* Modal for images */}
            {showImageModal ? <React.Fragment>
                <motion.div
                    className="modal show d-block image-modal"
                    tabIndex={-1}
                    aria-labelledby="filtersModal"
                    aria-hidden="true"
                    initial={{ translateY: "-50%", scale: 0.5, opacity: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                    animate={{
                        translateY: 0,
                        scale: 1,
                        opacity: 1,
                    }}
                >
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content shadow-lg border-0 rounded">
                            <div className="modal-body p-0">
                                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        {
                                            hotelInfo?.Images && hotelInfo?.Images?.map((image, index) => (
                                                <div className={`carousel-item ${activeImageIndex === index && 'active'}`} key={index}>
                                                    <img
                                                        src={image}
                                                        className="rounded"
                                                        alt="..."
                                                        style={{ height: "80vh", width: "100%", objectFit: "cover" }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {activeImageIndex ? <button
                                        className="carousel-control-prev"
                                        type="button"
                                        data-bs-target="#carouselExampleControls"
                                        data-bs-slide="prev"
                                        onClick={() => setActiveImageIndex(activeImageIndex - 1)}
                                    >
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button> : <></>}
                                    {(activeImageIndex + 1) !== hotelInfo?.Images?.length ? <button
                                        className="carousel-control-next"
                                        type="button"
                                        data-bs-target="#carouselExampleControls"
                                        data-bs-slide="next"
                                        onClick={() => setActiveImageIndex(activeImageIndex + 1)}
                                    >
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
                <div className="modal-backdrop fade show" onClick={() => setShowImageModal(false)}></div>
            </React.Fragment> : <></>}

        </React.Fragment>
    )
}
