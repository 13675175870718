import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Illustration from "@kiwicom/orbit-components/lib/Illustration";
import { InformationCircle } from "@kiwicom/orbit-components/lib/icons";

import PricingSummary from "./PricingSummary";
import BsModal from "../../../../../components/Modal/BSModal";
import Button from "../../../../../components/Common/Button/Button";
import BsInfoModal from "../../../../../components/Modal/BsInfoModal";
import CardBase from "../../../../../components/Common/CardBase/CardBase";
import ListCheck from "../../../../../components/Common/ListCheck/ListCheck";
import { Skeleton } from "../../../../../components/Common/LoadingSkeleton/Skeleton";
import HotelPassengerDetails from "../../../Components/PassengerDetails/HotelPassengerDetails";

import "../../../hotel.scss";

import useHotel from "../../../../../utils/hooks/Hotel/useHotel";
import { IBillingBreakUps } from "../../../../../utils/types/hotelTypes";
import { commonStateSlice } from "../../../../../redux/reducers/commonState";
import useHotelDetails from "../../../../../utils/hooks/Hotel/useHotelDetails";
import useHotelMetaData from "../../../../../utils/hooks/Hotel/useHotelMetaData";
import moment from "moment";
import { priceFormatter } from "../../../../../utils/utilityFunctions/utilityFunction";
import { IDynamicHotelRate, IStaticHotelRoomInfo, State } from "../../../../../redux/types";
import { calculateDateForCancellation, calculateDateForFreeCancellation, getTotalGuestCountForHotel, handleGetCharges, isDateAfter } from "../../../../../utils/utilityFunctions/hotelUtitlityFunctions";

interface Props {
  active?: boolean;
  coverImg?: string;
  scrollToRoom?: () => void;
}

export default function HotelBooking({
  active,
  coverImg,
  scrollToRoom,
}: Props) {

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    searchHotel,
    metaData,
    HotelBeds,
    TBO,
    bookingPassenger,
    passenger
  } = useSelector((state: State) => state.hotel);
  const { isLoggedIn, affiliateId } = useSelector((state: State) => state.user);
  const { ClientData } = useSelector((state: State) => state.commonState);

  const {
    paymentForm,
    addPayment,
    isPaymentDone,
    setIsPaymentDone,
    isPaymentFormVisible,
    setIsPaymentFormVisible,
    isGeneratingOrder,
    confirmCancelPayment,
    setConfirmCancelPayment,

    isBookingInProgress,
    isHotelBookingSuccess,
    setIsHotelBookingSuccess,
    showModalAvailabilityType,
    setShowModalAvailabilityType,
    handleCheckRate,
    handleBookingForHotelBeds
  } = useHotelDetails();
  const {
    handleUpdateHotelData,
    removeSelectedHotel,
    removeSelectedRoom,
  } = useHotel();
  const { getChargesList } = useHotelMetaData();
  const { handleCreateHotelBooking } = useHotelDetails();
  const { resetMetadataCharges, updateBookingPassenger } = useHotel();
  // const { getChargesByService } = useClientData();

  const scroll = useRef<HTMLDivElement>(null);


  const [staticRooms, setStaticRooms] = useState<IStaticHotelRoomInfo[]>([]);
  const [billingInfo, setBillingInfo] = useState<{
    markedUpBasePrice: number;
    Tax: number;
    GST: number;
    otherMarkUpCharges: number;
    myCharges: number;
  }>({
    markedUpBasePrice: 0,
    Tax: 0,
    GST: 0,
    otherMarkUpCharges: 0,
    myCharges: 0
  });

  const [guestCount, setGuestCount] = useState<{
    rooms: number;
    adults: number;
    children: number;
  }>({
    rooms: 0,
    adults: 0,
    children: 0,
  });

  // // show modal for price | policy changes
  // const [showModal, setShowModal] = useState<boolean>(false);
  // // show modal for AvailabilityType - when type === 'Available'
  // const [showModalAvailabilityType, setShowModalAvailabilityType] = useState<boolean>(false);

  // useEffect(() => {
  //   scroll.current?.scrollIntoView({ behavior: "smooth" });
  //   if (
  //     Client !== null
  //   ) {
  //     let clientId = ClientData.Services?.find((service) => service.ServiceName?.toLocaleLowerCase() === "hotel")?.ServiceId || 2;
  //     getChargesByService(clientId);
  //   }
  // }, []);

  useEffect(() => {
    let staticRoom: IStaticHotelRoomInfo[] = [];
    HotelBeds.selectedRoomCombinations.combination.forEach((eachCombination: IDynamicHotelRate) => {
      staticRoom.push(HotelBeds.searchedHotel.hotel_static_data.rooms?.filter(eachRoom => eachCombination.rateKey.includes(eachRoom.roomCode))[0] || []);
    });
    setStaticRooms(staticRoom.flat());
    // console.log("staticRooms", staticRooms.flat());

    // console.group("Room Combination");
    // console.log("Rate Combination: ", rate);
    // console.log("Static rooms: ", staticRoom);
    // console.log("Images: ", img);
    // console.groupEnd();
  }, []);

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
    affiliateId ? getChargesList(true) : getChargesList();
    handleCheckRate();
    handleChargeBreakDown();
    // handleCreateHotelBooking("TESTING");
    return () => resetMetadataCharges();
  }, [ClientData]);


  const handleChargeBreakDown = () => {
    let charges: IBillingBreakUps = {} as IBillingBreakUps;
    handleGetCharges(
      searchHotel,
      metaData,
      ClientData,
      "HOTELBEDS",
      HotelBeds.selectedRoomCombinations.combination,
      TBO.selectedRoom
    ).then(res => {
      charges = res;
      setGuestCount({
        rooms: charges.rooms,
        adults: charges.adults,
        children: charges.children
      });
      setBillingInfo({
        markedUpBasePrice: charges.billingCharges.markedUpBasePrice,
        Tax: charges.billingCharges.Tax,
        GST: charges.billingCharges.GST,
        otherMarkUpCharges: charges.billingCharges.otherMarkUpCharges,
        myCharges: charges.billingCharges.myCharges
      });
      console.table(charges.billingCharges);
    });
  };

  const showLoginModal = () => {
    dispatch(
      commonStateSlice.actions.toggle("showSignInModal")
    );
  };



  return (
    <div className="searched-hotel-container" ref={scroll}>

      {/* searched-hotel-container */}
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          {/* <div className="spacer spacer--sm"></div> */}
          <h2>Booking Summary</h2>
          <Button
            type={"button"}
            size={"sm"}
            color={"primary"}
            outline={true}
            className={"py-1 px-2 rounded-3"}
            label={
              <div className="d-flex align-items-center">
                <FaLongArrowAltLeft />
                <span className="small ms-1">Go back</span>
              </div>
            }
            onClick={() => history.goBack()}
          />
        </div>

        <div className="row my-3">
          {/* Left Panels */}
          <div className="col-md-8 col-sm-12">

            <CardBase title="Room Details">
              <div className="d-flex flex-column gap-2">
                {
                  staticRooms?.map((eachRoom: IStaticHotelRoomInfo, index) => (
                    <React.Fragment key={index}>
                      {/* ROOM TYPE INFO */}
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="my-0">Room {index + 1}</h5>
                        {/* <div className="d-flex align-items-center">
                          <div
                            className="bg-dark mx-1"
                            style={{ width: 4, height: 4, borderRadius: 4 }}
                          ></div>
                          <span className="small">
                            {eachRoom?.roomCode && eachRoom?.roomType && eachRoom?.roomTypeName
                              &&
                              eachRoom?.roomTypeName}
                          </span>
                        </div> */}
                        <span className="small">
                          {+searchHotel.RoomGuest[index].NoOfAdults > 1
                            ?
                            searchHotel.RoomGuest[index].NoOfAdults + " Adults "
                            :
                            searchHotel.RoomGuest[index].NoOfAdults + " Adult "
                          }{+searchHotel.RoomGuest[index].NoOfChild > 0
                            ?
                            searchHotel.RoomGuest[index].NoOfChild
                              ?
                              searchHotel.RoomGuest[index].NoOfChild + ", Children"
                              :
                              searchHotel.RoomGuest[index].NoOfChild + ", Child"
                            :
                            <></>}
                        </span>
                      </div>
                      <hr className="m-0" />
                      {/* ROOM IMAGES */}
                      {/* <div id="carouselExampleControls" className="carousel slide image-slider" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            {
                                                images?.map((image, index) => (
                                                    <div className={`carousel-item ${activeImageIndex === index && 'active'}`} key={index}>
                                                        <Image
                                                            src={HOTEL_BEDS_FILE_BASE_URL + "bigger/" + image || RoomPlaceholder}
                                                            className={"rounded"}
                                                            style={{ height: "60%", width: "100%", objectFit: "cover" }}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {activeImageIndex ? <button
                                            className="carousel-control-prev"
                                            type="button"
                                            data-bs-target="#carouselExampleControls"
                                            data-bs-slide="prev"
                                            onClick={() => setActiveImageIndex(activeImageIndex - 1)}
                                        >
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button> : <></>}
                                        {(activeImageIndex + 1) !== images?.length ? <button
                                            className="carousel-control-next"
                                            type="button"
                                            data-bs-target="#carouselExampleControls"
                                            data-bs-slide="next"
                                            onClick={() =>
                                                setActiveImageIndex(activeImageIndex + 1)
                                            }
                                        >
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button> : <></>}
                                    </div> */}

                      {/* AMENITIES */}
                      <div className="spacer spacer--sm"></div>
                      <div className="row">
                        <div className="col-sm-12">
                          <h5>Amenities:</h5>
                          <div className="row">
                            <div className="col-12">
                              {
                                eachRoom
                                  &&
                                  eachRoom?.roomFacilities && eachRoom.roomFacilities?.length < 5
                                  ?
                                  <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                      {eachRoom?.roomFacilities?.slice(0, Math.abs(eachRoom?.roomFacilities?.length / 2))?.map((facility, index) => facility?.facilityName
                                        ?
                                        (
                                          <span className="d-flex align-items-center" key={index}>
                                            <ListCheck title={facility?.facilityName} />
                                            {facility?.number && <span>{" - " + facility?.number}</span>}
                                          </span>
                                        )
                                        :
                                        <></>)}
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                      {eachRoom?.roomFacilities?.slice(Math.abs(eachRoom?.roomFacilities?.length / 2))?.map((facility, index) => facility?.facilityName
                                        ?
                                        (
                                          <span className="d-flex align-items-center" key={index}>
                                            <ListCheck title={facility?.facilityName} />
                                            {facility?.number && <span>{" - " + facility?.number}</span>}
                                          </span>
                                        )
                                        :
                                        <></>)}
                                    </div>
                                  </div>
                                  :
                                  <>
                                    {eachRoom?.roomFacilities?.map((facility, index) => facility?.facilityName
                                      ?
                                      <span className="d-flex align-items-center" key={index}>
                                        <ListCheck title={facility?.facilityName} />
                                        {facility?.number && <span>{" - " + facility?.number}</span>}
                                      </span>
                                      :
                                      <></>)}
                                  </>
                              }
                              {(eachRoom && !eachRoom.roomFacilities?.length)
                                ?
                                <div className="h-25 d-flex justify-content-center align-items-center">
                                  <span className="text-muted">Amenities is not available to show for this room</span>
                                </div>
                                :
                                <></>}
                            </div>
                          </div>
                        </div>
                      </div>


                      {/* CANCELATION POLICY */}
                      <div className="spacer spacer--sm"></div>
                      <div className="row">
                        <div className="col-md-8 col-sm-12">
                          <div>
                            <h5>Cancellation Policy:</h5>
                            <ul className="list-group list-group-flush">
                              {/*  list-group-numbered */}
                              {
                                HotelBeds.selectedRoomCombinations.combination[index]?.cancellationPolicies?.length > 0
                                  ?
                                  HotelBeds.selectedRoomCombinations.combination[index]?.cancellationPolicies?.map((policy, i) => (
                                    <li className="list-group-item justify-content-center px-0" key={i}>
                                      {/* <div className="bg-info d-flex gap-5 text-success small"> */}
                                      {
                                        isDateAfter(
                                          moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                                          calculateDateForFreeCancellation(policy?.from)
                                        )
                                          ?
                                          <div className="badge rounded-pill bg-danger">Refund is Not Available</div>
                                          :
                                          <></>
                                      }
                                      {i === 0
                                        ?
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <div className="text-success">
                                            <span>Free until <span className="fw-bold small">
                                              {calculateDateForFreeCancellation(policy?.from)}
                                            </span>
                                            </span>
                                          </div>
                                        </div>
                                        :
                                        <></>}
                                      {/* </div> */}
                                      <div className="d-flex align-items-center justify-content-between text-danger small">
                                        <span>After <span className="fw-bold">
                                          {calculateDateForFreeCancellation(policy?.from)}
                                        </span>
                                        </span>
                                        <span className="small">
                                          <label className="fw-bold small">Charges:</label>
                                          {policy?.amount && <span>{" "}{priceFormatter(+policy?.amount, HotelBeds.searchedHotel.hotel_dynamic_data.currency)}</span>}
                                          {policy?.percent && <span>{policy?.percent}%</span>}
                                          {policy?.numberOfNights && <span>{policy?.numberOfNights} Night</span>}
                                        </span>
                                      </div>
                                    </li>
                                  ))
                                  :
                                  <div className="h-25 d-flec justify-content-center align-items-center">
                                    <span className="text-muted">Cancellation policy is not available to show for this room</span>
                                  </div>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                }

                {/* PROMOTIONS */}
                <div>
                  <h5>Promotions:</h5>
                  {
                    HotelBeds.selectedRoomCombinations.combination?.map((eachCombination, index) => (
                      <>
                        <ul key={index + "_promotion"}>
                          {
                            eachCombination?.promotions?.length
                              ?
                              eachCombination?.promotions?.map((eachPromotion, i) => (
                                <li>{eachPromotion?.name} ({eachPromotion?.remark})</li>
                              ))
                              :
                              <li className="text-muted">Promotion not available!</li>
                          }
                        </ul>
                      </>
                    ))
                  }
                </div>

                {/* REMARKS */}
                <div>
                  <h5>Remarks:</h5>
                  {
                    HotelBeds.selectedRoomCombinations.combination?.map((eachCombination, index) => (
                      <>
                        <ul key={index + "_promotion"}>
                          {
                            eachCombination?.rateComments
                              ?
                              <li className="text-muted">{eachCombination?.rateComments || ""}</li>
                              :
                              <li className="text-muted">Remarks not available!</li>
                          }
                        </ul>
                      </>
                    ))
                  }
                </div>
              </div>
            </CardBase>

            {/* Hotel Guest Details Forms Based on Rooms*/}
            <HotelPassengerDetails />

            <CardBase title="Additional Information">
              <div>
                <label
                  htmlFor="additionalRequests"
                  className="form-label"
                >Special Service Request</label>
                <textarea
                  className="form-control"
                  id="additionalRequests"
                  rows={3}
                  value={bookingPassenger.additionalRemarks}
                  onChange={e => updateBookingPassenger("additionalRemarks", e.target.value)}
                ></textarea>
              </div>
              {passenger[0][0]?.InternationalTraveler === "No"
                ?
                <div className="mt-2">
                  <label
                    htmlFor="gstNumber"
                    className="form-label"
                  >GST Number</label>
                  <input
                    className="form-control"
                    id="gstNumber"
                    value={bookingPassenger.GSTNumber}
                    onChange={e => updateBookingPassenger("GSTNumber", e.target.value)}
                  />
                </div>
                :
                <></>}
            </CardBase>

            {HotelBeds.selectedRoomCombinations.price === 0 ? (
              <div className="alert alert-warning d-flex align-items-center">
                <InformationCircle />
                <p className="px-2 my-auto">
                  You have not selected any room. Please select a room type
                  from the above list to continue.
                  <br />
                  <span
                    className="text-primary small pointer"
                    onClick={() => {
                      history.goBack();
                      scrollToRoom && scrollToRoom();
                    }}
                  >
                    <u>Go back</u>
                  </span>
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* Right Panels */}
          <div className="col-md-4 col-sm-12">
            <PricingSummary
              addPayment={addPayment}
              isGeneratingOrder={isGeneratingOrder}
              isBookingInProgress={isBookingInProgress}
              isLoggedIn={isLoggedIn}
              searchedHotel={HotelBeds.searchedHotel}
              searchHotel={searchHotel}
              guestCount={guestCount}
              billingInfo={billingInfo}
              // blockRoom={blockRoom}
              blockRoom={handleBookingForHotelBeds}
              toggleLoginModal={showLoginModal}
            />
          </div>

        </div>
        {/* </CardBase> */}
      </div>

      {/* Modal for AvailabilityType === "Available" */}
      {showModalAvailabilityType ? (
        <BsInfoModal
          title="Room is not confirmed"
          subTitle="The room that you have selected is not confirmed. You will be redirected to the selected hotel details page where you will be able to select another room."
          onConfirm={() => {
            setShowModalAvailabilityType(false);
            history.goBack();
          }}
          icon={<Illustration name="NoResults" size="small" />}
          confirmText="Done"
        />
      ) : (
        <></>
      )}

      {/* Alert when booking is successful */}
      {isHotelBookingSuccess !== "" ? (
        <BsInfoModal
          title={isHotelBookingSuccess === "Confirmed" ? "Booking Success" : "Booking Failed"}
          subTitle={isHotelBookingSuccess === "Confirmed"
            ?
            "Your booking is successfull. You will be redirected to the home page."
            :
            "Your booking has failed. Please try again."}
          // onClose={() => {
          //   removeSelectedHotel();
          //   removeSelectedRoom();
          //   handleUpdateHotelData(false);
          //   history.push("/");
          // }}
          onConfirm={() => {
            if (isHotelBookingSuccess === "Confirmed") {
              removeSelectedHotel("HOTELBEDS");
              removeSelectedRoom("HOTELBEDS");
              handleUpdateHotelData(false);
              setIsHotelBookingSuccess("");
              // window.close();
              history.push("/");
            } else {
              setIsHotelBookingSuccess("");
            }
          }}
          icon={<Illustration
            size="small"
            name={isHotelBookingSuccess === "Confirmed" ? "Success" : "Error404"}
          />}
          confirmText="Done"
        />
      ) : (
        <></>
      )}

      {/* PAYMENT MODAL */}
      {isPaymentFormVisible
        ?
        <BsModal
          title={""}
          subTitle="Payment is in process. Don't close this screen."
          onClose={() => setConfirmCancelPayment(true)}
          scrollable={false}
          size={"fullscreen"}
          body={<div className="d-flex justify-content-center align-items-center">
            {/* {isGeneratingOrder
              ?
              <Skeleton
                typeArray={["box"]}
                count={2}
                elementStyle={{ height: "150px" }}
              />
              : */}
            <div
              ref={paymentForm}
              className="my-5 w-50"
            />
            {/* } */}
          </div>}
        />
        :
        <></>}

      {/* CANCEL PAYMENT PROCESS */}
      {confirmCancelPayment
        ?
        <BsInfoModal
          title="Are you sure?"
          subTitle="This action will terminate the payment process."
          icon={<Illustration name="TimelineLeave" size="small" />}
          confirmText="Yes"
          // cancelText="Return"
          // cancelTextColor="primary"
          // onClose={() => {
          //   // setIsPaymentDone(false);
          //   // setIsPaymentFormVisible(false)
          // }}
          onConfirm={() => {
            setIsPaymentDone(false);
            setIsPaymentFormVisible(false);
            setConfirmCancelPayment(false);
          }}
        />
        :
        <></>}

    </div>
  );
}
