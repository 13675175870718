import { TypeMetaDataFlights } from "../../types/flightTypes";
import { State } from "../../../redux/types";
import { IServerResponse } from "../../types/hotelTypes";
import { SERVER_API_URL } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
// import useFetch from "../useFetch";
import { updateFlightMetaData } from "../../../redux/reducers/flight";
import axios from "axios";
import moment from "moment";
// import { formatDateToISO } from "../../utilityFunctions/utilityFunction";

const useFlightMetaData = () => {
  const dispatch = useDispatch();
  const { Client } = useSelector((state: State) => state.commonState);
  const { affiliateId } = useSelector((state: State) => state.user);
  const { metaData } = useSelector((state: State) => state.flight);
  const URL = `${SERVER_API_URL}Flight/`;
  let metaApiEndPoints: { api: string; reduxStateKey: TypeMetaDataFlights }[] =
    [
      { api: "GetOrigin", reduxStateKey: "Origins" },
      { api: "GetDestination", reduxStateKey: "Destinations" },
      { api: "GetMarkupFeeType", reduxStateKey: "MarkupFeeType" },
      { api: "GetTripTypes", reduxStateKey: "TripTypes" },
      { api: "GetBookingSupplier", reduxStateKey: "BookingSupplier" },
      { api: "GetTicketedSupplier", reduxStateKey: "TicketedSupplier" },
      { api: "GetFlightAirline", reduxStateKey: "FlightAirline" },
      { api: "GetFlightClass", reduxStateKey: "FlightClass" },
      { api: "GetFlightCabinClass", reduxStateKey: "FlightCabinClass" },
      { api: "GetFlightRouteOriginByClient", reduxStateKey: "FlightOrigins" },
      {
        api: "GetFlightRouteDestinationByClient",
        reduxStateKey: "FlightDestinations",
      },
      { api: "GetTitleMaster", reduxStateKey: "TitleMaster" },
      { api: "GetMealType", reduxStateKey: "MealType" },
      { api: "GetTravellerTypes", reduxStateKey: "TravellerTypes" },
      // { api: "GetCharges", reduxStateKey: "Charges" },
      { api: "GetFlightApplyFor", reduxStateKey: "FlightApplyFor" },
      { api: "GetFlightMarkupType", reduxStateKey: "FlightMarkupType" },
      { api: "GetFlightFareType", reduxStateKey: "FlightFareType" },
      { api: "GetFlightApplyOn", reduxStateKey: "FlightApplyOn" },
      { api: "GetFlightApplyType", reduxStateKey: "FlightApplyType" },
    ];
  const refreshFlightMetaData = () => {
    if (Client !== null && metaData) {
      let promises = metaApiEndPoints
        ?.filter((endpoints) => metaData[endpoints.reduxStateKey]?.length === 0)
        .map((endpoints) => {
          return axios.get(`${URL}${endpoints.api}/${Client?.id}`);
        });
      Promise.allSettled(promises).then((response) => {
        response?.forEach((res, index) => {
          if (res.status === "fulfilled") {
            const { ResponseData, IsSuccess }: IServerResponse =
              res.value?.data;
            if (IsSuccess) {
              dispatch(
                updateFlightMetaData({
                  key: metaApiEndPoints[index]?.reduxStateKey,
                  value: ResponseData,
                })
              );
            }
          } else {
            console.log(res.reason);
          }
        });
      });
    }
  };
  const getMarkUp = async (
    origin: number,
    dest: number,
    date: string,
    isAgent: boolean
  ) => {
    try {
      const payload: any = {
        ClientId: Client?.id,
        OriginId: origin,
        DestinationId: dest,
        SearchTypeId: 1,
        FromDate: moment(date).add(1, "d").toISOString(),
        ToDate: moment(date).add(1, "d").toISOString(),
      };
      if (isAgent) {
        payload.AffiliateId = affiliateId;
      }
      const response = await axios.post(
        `${SERVER_API_URL}Flight/GetMarkup${isAgent ? "B2B" : ""}`,
        payload
      );
      if (response?.data) {
        dispatch(
          updateFlightMetaData({
            key: "Markup",
            value: response.data?.ResponseData,
          })
        );
        return response?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getChargesForCustomer = async () => {
    try {
      const response = await axios.get(
        `${SERVER_API_URL}Flight/GetCharges/${Client?.id}`
      );
      if (response?.data) {
        dispatch(
          updateFlightMetaData({
            key: "Charges",
            value: response.data?.ResponseData,
          })
        );
      }
    } catch (error) {
      console.log("Agent charge get error", error);
    }
  };
  const getAffiliateMarkUp = async (
    origin: number,
    dest: number,
    date: string
    // returnDate?: string
  ) => {
    try {
      const response = await axios.post(
        `${SERVER_API_URL}Agent/GetFlightMarkup`,
        {
          ClientId: Client?.id,
          AffiliateID: affiliateId,
          OriginId: origin,
          DestinationId: dest,
          // SearchTypeId: searchInfo.travelType?.SearchTypeId,
          SearchTypeId: 1,
          FromDate: moment(date).toISOString(),
          ToDate: moment(date).toISOString(),
        }
      );
      if (response?.data) {
        dispatch(
          updateFlightMetaData({
            key: "AffiliateMarkup",
            value: response.data?.ResponseData,
          })
        );
        return response?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChargesForAffiliate = async () => {
    try {
      const response = await axios.get(
        `${SERVER_API_URL}Agent/GetFlightCharges/${Client?.id}/${affiliateId}`
      );
      if (response?.data) {
        dispatch(
          updateFlightMetaData({
            key: "Charges",
            value: response.data?.ResponseData,
          })
        );
      }
    } catch (error) {
      console.log("Agent charge get error", error);
    }
  };

  return {
    refreshFlightMetaData,
    getMarkUp,
    getAffiliateMarkUp,
    getChargesForAffiliate,
    getChargesForCustomer,
  };
};
export default useFlightMetaData;

//   getDestination(({ data, error }) => {
//     if (data?.length > 0) {
//       dispatch(updateFlightMetaData({ key: "Destinations", value: data }));
//     }
//   });
// };
