import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
interface Props {
  title: string;
  type?: "success" | "error";
}

const ListCheck = (props: Props) => {
  return (
    <div className="list list--check">
      <div className="list-item">
        <div className="icon text-success">
          {props.type && props.type === "error" ? (
            <AiFillCloseCircle color={"critical"} size={24} />
          ) : (
            <AiFillCheckCircle size={24} />
          )}
        </div>
        <div style={{ textTransform: "capitalize" }}>{props.title}</div>
      </div>
    </div>
  );
};

export default ListCheck;
