import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../utils/constants";

export interface IPostBookFlight {
  getBooking: (
    recordLocator: string
    // userId?: string
  ) => Promise<AxiosResponse<any>>;
  cancelTicket: (
    // userId: string,
    cancelBy?: string
  ) => Promise<AxiosResponse<any>>;
}

class PostBookFlight implements IPostBookFlight {
  private baseUrl: string;
  constructor(carrierCode: string) {
    this.baseUrl = `${API_URL}${carrierCode}/`;
  }
  getBooking(
    recordLocator: string
    // userId?: string
  ): Promise<AxiosResponse<any, any>> {
    let userId = localStorage.getItem("sessionId");
    if (userId !== null) {
      let body: {
        GetBookingBy: string;
        RecordLocator: string;
        userId?: string;
      } = {
        GetBookingBy: "RecordLocator",
        RecordLocator: recordLocator,
        userId,
      };
      // if (userId) {
      //   body.userId = userId;
      // }
      return axios.post(`${this.baseUrl}booking/getBooking`, body);
    } else {
      return Promise.reject("User Not LoggedIn");
    }
  }
  cancelTicket(CancelBy?: string): Promise<AxiosResponse<any, any>> {
    return axios.post(`${this.baseUrl}booking/cancel`, {
      userId: localStorage.getItem("sessionId"),
      CancelBy: "All",
    });
  }
}

const spicejetObj = new PostBookFlight("sg");
const goAirObj = new PostBookFlight("g8");
const indigoObj = new PostBookFlight("6E");

export const PostBookFlights: IPostBookFlight[] = [spicejetObj, indigoObj];
