import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "@kiwicom/orbit-components/lib/Text";
import Button from "@kiwicom/orbit-components/lib/Button";
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox";
import { ChevronDown } from "@kiwicom/orbit-components/lib/icons";
import Popover from "@kiwicom/orbit-components/lib/Popover";
import Stack from "@kiwicom/orbit-components/lib/Stack";

import IStepper from "../../../components/Common/Stepper/Stepper";

import "../flight.scss";

import { State } from "../../../redux/types";
import useFlight from "../../../utils/hooks/Flight/useFlight";
import { showToast } from "../../../redux/reducers/commonState";

interface ITopFilters {
  isPreSearch: boolean;
};

const TopFilters = ({ isPreSearch }: ITopFilters) => {

  const dispatch = useDispatch();

  const { searchInfo, metaData } = useSelector((state: State) => state.flight);

  const { updateSearch } = useFlight();
  

  const TripTypes = useMemo(() => {
    const temp =
      metaData?.TripTypes.length > 0
        ? (JSON.parse(JSON.stringify(metaData?.TripTypes)) as any[])
        : [];
    return temp?.sort((a, b) => (a.SearchTypeId > b.SearchTypeId ? 1 : -1));
  }, [metaData.TripTypes]);



  return (
    <div className="top-filter">
      {isPreSearch ? (
        <div className="d-flex justify-content-between gap-1">
          {TripTypes?.map((trip, index) => (
            <Button
              key={index}
              onClick={() => {
                updateSearch("travelType", trip);
              }}
              // size="small"
              type={
                searchInfo?.travelType?.SearchTypeName === trip?.SearchTypeName
                  ? "primarySubtle"
                  : "white"
              }
            >
              <span className="fw-semibold">{trip?.SearchTypeName}</span>
            </Button>
          ))}
        </div>
      ) : (
        <Popover
          overlapped
          content={
            <Stack>
              <React.Fragment key=".0">
                {TripTypes?.map((trip, index) => (
                  <Stack align="center" key={index}>
                    <Stack spacing="none">
                      <Checkbox
                        label={trip?.SearchTypeName}
                        checked={
                          searchInfo?.travelType?.SearchTypeName ===
                          trip?.SearchTypeName
                        }
                        onChange={() => {
                          updateSearch("travelType", trip);
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </React.Fragment>
            </Stack>
          }
        >
          <Button iconRight={<ChevronDown />} type="white">
            <span className="text-primary fw-semibold">
              {searchInfo?.travelType?.SearchTypeName}
            </span>
          </Button>
        </Popover>
      )}
      <div className={`${isPreSearch ? "ms-auto" : ""} top-filter--traveller`}>
        <Popover
          overlapped
          content={
            <Stack>
              <React.Fragment key=".0">
                {metaData?.TravellerTypes?.map((traveller, index) => {
                  const id = traveller?.TravellerTypeId;
                  return (
                    <Stack align="center" key={index}>
                      <Stack spacing="none">
                        <Text>{traveller?.TypeName}</Text>
                        <Text type="secondary" size="small">
                          {id === 1
                            ? "12+ years"
                            : id === 2
                              ? "2-11 years"
                              : "0-2 years"}
                        </Text>
                      </Stack>
                      <IStepper
                        minValue={id === 1 ? 1 : 0}
                        maxValue={
                          id === 3
                            ? searchInfo?.adult
                            : id === 2
                              ? 9 - searchInfo?.adult
                              : 9 - searchInfo?.child
                        }
                        value={
                          id === 1
                            ? searchInfo?.adult
                            : id === 2
                              ? searchInfo?.child
                              : searchInfo?.infant
                        }
                        onChange={(value) => {
                          if (id === 3) {
                            updateSearch("infant", value);
                          } else if (id === 2) {
                            updateSearch("child", value);
                          } else if (id === 1) {
                            if (value < searchInfo?.infant) {
                              updateSearch("infant", value);
                            }
                            updateSearch("adult", value);
                          }
                        }}
                        onDisabledClick={(type) => {
                          if (type === "add") {
                            if (id !== 3) {
                              dispatch(
                                showToast({
                                  type: "error",
                                  title: "",
                                  subTitle: "Maximum 9 passengers allowed",
                                })
                              );
                            } else {
                              dispatch(
                                showToast({
                                  type: "error",
                                  title: "",
                                  subTitle: "1 infant per passenger allowed.",
                                })
                              );
                            }
                          }
                        }}
                      />
                    </Stack>
                  );
                })}
                {searchInfo?.infant > searchInfo?.adult ? (
                  <Text type="critical" size="small">
                    Infants can't be more than adults.
                  </Text>
                ) : (
                  <></>
                )}
              </React.Fragment>
            </Stack>
          }
        >
          <Button iconRight={<ChevronDown />} type="white">
            <span className="text-primary fw-semibold">
              {searchInfo?.adult + searchInfo?.child + searchInfo?.infant}{" "}
              Traveller
            </span>
          </Button>
        </Popover>
        <Popover
          overlapped
          content={
            <Stack>
              <React.Fragment key=".0">
                {metaData?.FlightClass?.map((traveller, index) => (
                  <Stack align="center" key={index}>
                    <Stack spacing="none">
                      <Checkbox
                        disabled
                        checked={
                          searchInfo?.class?.FlightClassId ===
                          traveller?.FlightClassId
                        }
                        label={traveller?.FlightClassName}
                        onChange={() => {
                          updateSearch("class", traveller);
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </React.Fragment>
            </Stack>
          }
        >
          <Button iconRight={<ChevronDown />} type="white">
            <span className="text-primary fw-semibold">
              {searchInfo?.class?.FlightClassName}
            </span>
          </Button>
        </Popover>

        {/* Airlines filter */}
        <Popover
          overlapped
          content={
            <Stack>
              <React.Fragment key=".0">
                {[
                  { key: "Spicejet", value: 0 },
                  { key: "Indigo", value: 1 },
                ]?.map((airline, index) => (
                  <Stack align="center" key={index}>
                    <Stack spacing="none">
                      <Checkbox
                        checked={searchInfo.airlines.includes(airline.value)}
                        label={airline?.key}
                        onChange={() => {
                          if (searchInfo.airlines.includes(airline.value)) {
                            updateSearch(
                              "airlines",
                              searchInfo.airlines.filter(
                                (item) => item !== airline.value
                              )
                            );
                          } else {
                            updateSearch("airlines", [
                              ...searchInfo.airlines,
                              airline.value,
                            ]);
                          }
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </React.Fragment>
            </Stack>
          }
        >
          <Button iconRight={<ChevronDown />} type="white">
            <span className="text-primary fw-semibold">
              {searchInfo?.airlines.length === 0
                ? "Airlines"
                : `${searchInfo?.airlines.length} airline(s) selected`}
            </span>
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default TopFilters;
