import React, { FC, useEffect, useState } from "react";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
  FaPlane,
  FaRadiationAlt,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { State } from "../../../redux/types";
import { hideToast } from "../../../redux/reducers/commonState";
import { MdCancel } from "react-icons/md";

interface Props { };

const Toast: FC = () => {

  const dispatch = useDispatch();

  const { toast } = useSelector((state: State) => state.commonState);


  useEffect(() => {
    let timer = toast.map((e, index) =>
      setTimeout(() => {
        //   hide Toast
        dispatch(hideToast(index));
      }, (toast[index].time || 5) * 1000)
    );
    return () => {
      timer.map((t) => clearTimeout(t));
    };
  }, [toast]);


  return (
    <>
      {toast?.map((item, index) => (
        <div
          className={`toast-container toast-type-${item.type || "primary"}`}
          style={{
            right: "2vw",
            top: `${3 + index * 6}vw`,
          }}
          key={index}
        >
          <div className="content">
            {item.type === "info" ? (
              <FaInfoCircle className="icon" size={25} />
            ) : item.type === "success" ? (
              <FaCheckCircle className="icon" size={25} />
            ) : item.type === "primary" ? (
              <FaPlane className="icon" size={25} />
            ) : item.type === "error" ? (
              <FaExclamationCircle className="icon" size={25} />
            ) : item.type === "warning" ? (
              <FaRadiationAlt className="icon" size={25} />
            ) : (
              <></>
            )}
            <div className="text">
              <div className="d-flex justify-content-between">
                <h3 className="title">{item.title}</h3>
                <MdCancel size={25} color="orange" className="pointer" />
              </div>
              <p className="subtitle">{item.subTitle}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Toast;
