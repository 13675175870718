import { motion } from "framer-motion";
import { ReactElement, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { commonStateSlice, toggle } from "../../../redux/reducers/commonState";
import { State } from "../../../redux/types";
import useAuth from "../../../utils/hooks/useAuth";
import useClickOutSide from "../../../utils/hooks/useClickOutSide";
import Button from "../Button/Button";
import InputWithIcon from "../Input/InputWithIcon";
import SignUp from "./SignUp";

export default function SignInModal(): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [isSignIn, setIsSignIn] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { login, isLoggingIn } = useAuth();
  const { Client } = useSelector((state: State) => state.commonState);
  const [formData, setFormData] = useState<{
    Email: string;
    Password: string;
  }>({
    Email: "",
    Password: "",
  });
  const [isAgent, setIsAgent] = useState<boolean>(false);
  useClickOutSide(modalRef, () => {
    dispatch(toggle("showSignInModal"));
  });
  return (
    <>
      <motion.div
        // style={{ opacity: 0.5 }}
        onClick={() => {
          dispatch(toggle("showSignInModal"));
        }}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 0.65,
        }}
        // exit={{ opacity: 0, height: 0 }}
        className="modal-backdrop"
      ></motion.div>
      <motion.div
        className="modal"
        style={{ display: "block" }}
        initial={{ translateY: -30, opacity: 0 }}
        transition={{ duration: 0.5 }}
        animate={{
          translateY: 0,
          opacity: 1,
        }}
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content card">
            <div className="card card--modal">
              <div className="card-body">
                {isSignIn ? (
                  <>
                    <div className="d-flex justify-content-between mb-4">
                      <h2>Sign in to {Client?.name}</h2>

                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          dispatch(toggle("showSignInModal"));
                        }}
                      ></button>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        login(formData, isAgent);
                      }}
                    >
                      <div className="mb-2">
                        <input
                          // type="email"
                          className="form-control"
                          placeholder="jon.doe@mail.com"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFormData((prev) => ({ ...prev, Email: value }));
                          }}
                          value={formData?.Email}
                        />
                      </div>
                      {/* <div className="input-group  rounded border">
                        <input
                          type={passwordType}
                          className={`form-control border-0`}
                          placeholder="Password"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFormData((prev) => ({
                              ...prev,
                              Password: value,
                            }));
                          }}
                          value={formData?.Password}
                          />
                          <div
                          className="input-group-text bg-transparent border-0 pointer"
                          onClick={() =>
                            setPasswordType((prev) =>
                            prev === "password" ? "text" : "password"
                            )
                          }
                            >
                            {passwordType === "password" ? (
                            <FaEyeSlash />
                            ) : (
                              <FaEye />
                              )}
                              </div>
                            </div> */}
                      <InputWithIcon
                        type={showPassword ? "text" : "password"}
                        value={formData?.Password}
                        onChange={(value) => {
                          setFormData((prev) => ({
                            ...prev,
                            Password: value,
                          }));
                        }}
                        icon={showPassword ? <FaEye /> : <FaEyeSlash />}
                        onIconClick={() => {
                          setShowPassword((prev) => !prev);
                        }}
                        placeholder="Password"
                      />
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="rememberme"
                          onChange={(e) => {
                            setIsAgent(e.target.checked);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rememberme"
                        >
                          I am an Agent.
                        </label>
                      </div>
                      <p className="small text-muted mt-3">
                        By signing in, you agree to our{" "}
                        <button
                          className="btn btn-link btn-sm p-0 text-decoration-none"
                          onClick={() => {
                            window.open("/privacy-policy", "blank");
                            dispatch(toggle("showSignInModal"));
                          }}
                          type="button"
                        >
                          Terms of Use
                        </button>{" "}
                        and{" "}
                        <button
                          type="button"
                          className="btn btn-link btn-sm p-0 text-decoration-none"
                          onClick={() => {
                            window.open("/privacy-policy", "blank");
                            dispatch(toggle("showSignInModal"));
                          }}
                        >
                          Privacy Policy
                        </button>
                        .
                      </p>
                      <Button
                        type="submit"
                        className="btn btn-primary w-100"
                        color="primary"
                        label="Sign In"
                        isLoading={isLoggingIn}
                        disabled={
                          isLoggingIn ||
                          formData?.Email.length < 5 ||
                          formData?.Password.length < 3
                        }
                      />
                    </form>
                  </>
                ) : (
                  <SignUp />
                )}
                <div className="spacer spacer--sm"></div>
                <p className="small mb-0 text-center">
                  {isSignIn
                    ? "Don't have an acccount? "
                    : "Already have an acccount? "}
                  <button
                    className="btn btn-link btn-sm p-0 text-decoration-none"
                    onClick={() => {
                      setIsSignIn((a) => !a);
                    }}
                  >
                    {isSignIn ? "Sign Up" : "Sign In"}
                  </button>
                </p>
                <div className="text-center">
                  <span>Are you an agent?</span>
                  <button className="btn btn-link btn-sm p-0 text-decoration-none">
                    Agent Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
