import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "../initialState";

import {
  ICombination,
  IHotelDetails,
  IHotelWhereTo,
  IPassenger,
  IRoom,
  IRoomCombinations,
  ISelectedHotel,
  ITBOHotelDetails,
  ITBORoom
} from "../types";
import {
  IHotelListingInfo,
  INearbySearchGeoLocation,
  TypeBookingPassenger,
  TypeHotelServices,
  TypeMetaDataHotels,
  TypePagination,
  TypePassenger,
  TypeSearchHotelFilters,
  TypeSearchHotelKeys,
  TypeSearchHotelRoomGuestKeys
} from "./../../utils/types/hotelTypes";


/* This is a way to create a default object of type IPassenger. */
let passenger_details_obj: IPassenger = {
  InternationalTraveler: "No",
  Title: "Mr",
  FirstName: "",
  LastName: "",
  CountryCode: "+91",
  CountryName: "",
  Phoneno: "",
  Email: "",
  PaxType: "1",
  Age: "",
  PassportNo: "",
  PassportIssueDate: "",
  PassportExpDate: "",
  PAN: "",
  LeadPassenger: false,
};


export const hotelSlice = createSlice({
  name: "hotel",
  initialState: initialState.hotel,
  reducers: {
    /* --------------------------------------------------
        |              For Search Hotel Filters
        -------------------------------------------------- */

    /*
      storing arrays of strings in filters state as there can be multiple filters of one type
      checking below if the payload.value already exists in the filters then remove it else add it
    */
    updateHotelFilters: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: TypeSearchHotelFilters;
        value: string;
        priceStart?: string;
        priceEnd?: string;
      }>
    ) => {
      if (payload.key === "price") {

        payload.priceStart
          ? (state.filters.price[0] = payload.priceStart)
          : (state.filters.price[0] = "");

        payload.priceEnd
          ? (state.filters.price[1] = payload.priceEnd)
          : (state.filters.price[1] = "");

      } else if (payload.key === "HotelBoards") {

        if (
          state.filters.HotelBoards.findIndex(e => e === payload.value) === -1
        ) {
          state.filters.HotelBoards.push(payload.value);
        } else {
          state.filters.HotelBoards = state.filters.HotelBoards.filter(e => e !== payload.value);
        };

      } else if (payload.key === "zones") {

        if (
          state.filters.zones.findIndex(e => e === payload.value) === -1
        ) {
          state.filters.zones.push(payload.value);
        } else {
          state.filters.zones = state.filters.zones.filter(e => e !== payload.value);
        };

      } else if (payload.key === "selectedNearbySearchGeoLocation") {


        const existingGeoLocation = state.filters.selectedNearbySearchGeoLocation;
        let selectedGeoLocation = state.nearbySearchGeoLocations.find(each => each.index === +payload.value);

        if (selectedGeoLocation) {
          if (selectedGeoLocation.index === existingGeoLocation?.index) {
            state.searchHotel.geoLocation = initialState.hotel.searchHotel.geoLocation;
            state.filters.selectedNearbySearchGeoLocation = initialState.hotel.filters.selectedNearbySearchGeoLocation;
          } else {
            state.searchHotel.geoLocation = selectedGeoLocation;
            state.filters.selectedNearbySearchGeoLocation = selectedGeoLocation;
          };
        } else {
          state.searchHotel.geoLocation = initialState.hotel.searchHotel.geoLocation;
          state.filters.selectedNearbySearchGeoLocation = initialState.hotel.filters.selectedNearbySearchGeoLocation;
        };

      } else if (payload.key === "accommodationTypes") {

        if (
          state.filters.accommodationTypes.findIndex(e => e === payload.value) === -1
        ) {
          state.filters.accommodationTypes.push(payload.value);
        } else {
          state.filters.accommodationTypes = state.filters.accommodationTypes.filter(e => e !== payload.value);
        };

      } else if (payload.key !== "hotelName") {

        if (
          state.filters[payload.key]?.findIndex((e: any) => e === payload.value) ===
          -1
        ) {
          // state.filters[payload.key] = [
          //     ...state.filters[payload.key],
          //     payload.value,
          // ];
          state.filters[payload.key][0] = payload.value;
          // console.log("star", payload.value);
        } else if (payload.key === "HotelCategory") {

          state.filters[payload.key] = [payload.value]
          // state.filters[payload.key].filter(
          //   (e: string) => e !== payload.value
          // );

        } else {
          state.filters[payload.key] = state.filters[payload.key].filter(
            (e: string) => e !== payload.value
          );
        };

      } else {

        state.filters.hotelName = payload.value;

      };
    },

    resetHotelFilters: (state) => {
      state.filters = initialState.hotel.filters;
    },

    updateHotelFilterSelectedNearbySearchGeoLocation: (
      state,
      { payload }: PayloadAction<INearbySearchGeoLocation>
    ) => {

      const selectedGeoLocation = payload;
      const existingGeoLocation = state.filters.selectedNearbySearchGeoLocation;

      if (selectedGeoLocation) {
        if (selectedGeoLocation.index === existingGeoLocation?.index) {
          state.searchHotel.geoLocation = initialState.hotel.searchHotel.geoLocation;
          state.filters.selectedNearbySearchGeoLocation = initialState.hotel.filters.selectedNearbySearchGeoLocation;
        } else {
          state.searchHotel.geoLocation = selectedGeoLocation;
          state.filters.selectedNearbySearchGeoLocation = selectedGeoLocation;
        };
      };

    },

    resetSelectedNearbySearchGeoLocationFromFilters: (state) => {
      state.filters.selectedNearbySearchGeoLocation = initialState.hotel.filters.selectedNearbySearchGeoLocation;
    },

    // remove filters
    removeHotelFilters: (state) => {
      state.filters = initialState.hotel.filters;
    },

    // reset specific hotel filter
    resetSpecificHotelFilter: (state, { payload }: PayloadAction<TypeSearchHotelFilters>) => {

      if (payload === "HotelCategory") {
        state.filters[payload] = initialState.hotel.filters[payload];
      } else if (payload === "StarRating") {
        state.filters[payload] = initialState.hotel.filters[payload];
      } else if (payload === "HotelBoards") {
        state.filters[payload] = initialState.hotel.filters[payload];
      } else if (payload === "price") {
        state.filters[payload] = initialState.hotel.filters[payload];
      } else if (payload === "hotelName") {
        state.filters[payload] = initialState.hotel.filters[payload];
      } else if (payload === "zones") {
        state.filters[payload] = initialState.hotel.filters[payload];
      } else if (payload === "accommodationTypes") {
        state.filters[payload] = initialState.hotel.filters[payload];
      };

    },

    /* --------------------------------------------------
        |              For Search Hotel Form Inputs
        -------------------------------------------------- */

    // stores trace id for each search hotel request
    updateTraceId: (state, { payload }: PayloadAction<string>) => {
      state.TBO.TraceId = payload;
    },

    // update searched city information
    updateWhereTo: (state, { payload }: PayloadAction<IHotelWhereTo>) => {
      state.searchHotel.whereTo = payload;
    },

    // remove searched city information
    removeWhereTo: (state) => {
      state.searchHotel.whereTo = initialState.hotel.searchHotel.whereTo;
      // {
      //   CityName: "",
      //   CountryCode: "",
      //   CountryName: "",
      //   DestinationId: "",
      //   StateProvince: "",
      //   Type: "",
      //   DestinationName: "",
      //   DestinationNumber: ""
      // };
    },

    updateGeoLocation: (state, { payload }: PayloadAction<INearbySearchGeoLocation>) => {
      state.searchHotel.geoLocation = payload;
    },

    removeGeoLocation: (state) => {
      state.searchHotel.geoLocation = initialState.hotel.searchHotel.geoLocation;
    },

    // update no of rooms
    updateNoOfRooms: (
      state,
      { payload }: PayloadAction<{ key: "add" | "remove" }>
    ) => {
      if (payload.key === "add") {
        state.searchHotel.noOfRooms += 1;
        state.searchHotel.RoomGuest.push({
          NoOfAdults: 1,
          NoOfChild: 0,
          ChildAge: [],
        });
        state.passenger.push([passenger_details_obj]);
      } else {
        state.searchHotel.noOfRooms -= 1;
      }
    },


    /* This is the reducer for updating the room guest details based on room (index) */
    // the optional paramenter - i, is used to update the child age at index i
    // the child age array will be updated in the RoomGuest array
    // when i = undefined, it means new child age is getting added. Thus, array.push() is used
    // when i is defined, it means child age is getting updated. Thus, we are re-assigning that index with latest value (payload.value)
    updateRoomGuest: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        key: TypeSearchHotelRoomGuestKeys;
        value: string | number;
        i?: number;
      }>
    ) => {
      // when key is not ChildAge, we are updating the value of existing property at specifix index(payload.index)
      if (payload.key !== "ChildAge") {
        if (payload.key === "NoOfChild") {
          if (
            state.searchHotel.RoomGuest[payload.index].NoOfChild >
            +payload.value
          ) {
            state.searchHotel.RoomGuest[payload.index].ChildAge.pop();
            state.passenger[payload.index].pop();
          } else {
            state.passenger[payload.index].push({
              ...passenger_details_obj,
              PaxType: "2",
            });
          }
        } else {
          if (
            state.searchHotel.RoomGuest[payload.index].NoOfAdults >
            +payload.value
          ) {
            state.passenger[payload.index].splice(0, 1);
          } else {
            state.passenger[payload.index].unshift(passenger_details_obj);
          }
        }
        state.searchHotel.RoomGuest[payload.index][payload.key] = payload.value;
      } else {
        // existing child age is getting updated
        let totalAdultPassengersOfRoom =
          +state.searchHotel.RoomGuest[payload.index].NoOfAdults;
        if (payload.i !== undefined) {
          // console.log("Child age is getting updated!!!");
          state.searchHotel.RoomGuest[payload.index][payload.key][payload.i] =
            +payload.value;

          state.passenger[payload.index][
            totalAdultPassengersOfRoom + payload.i
          ].Age = String(payload.value);
        }
      }
    },


    /* This is used to remove the room guest at index `payload` from the `state.passenger` array. */
    removeRoomGuest: (state, { payload }: PayloadAction<number>) => {
      state.searchHotel.RoomGuest.splice(payload, 1);
      state.passenger.splice(payload, 1);
      state.searchHotel.noOfRooms -= 1;
    },

    resetRoomGuestInfo: (state) => {
      state.passenger = initialState.hotel.passenger;
    },

    // updates checkin, checkout information
    updateFilters: (
      state,
      { payload }: PayloadAction<{ key: TypeSearchHotelKeys; value: string }>
    ) => {
      state.searchHotel[payload.key] = payload.value;
    },

    // reset search hotel parameters
    resetFilters: (state) => {
      state.searchHotel = initialState.hotel.searchHotel;
    },

    // reset search hotel roomGuest array
    resetRoomGuest: (state) => {
      state.searchHotel.noOfRooms = initialState.hotel.searchHotel.noOfRooms;
      state.searchHotel.RoomGuest = initialState.hotel.searchHotel.RoomGuest;
    },

    /* --------------------------------------------------
        |      For Selected Hotel From Searched Results
        -------------------------------------------------- */

    // update hotel listing
    updateHotelList: (state, { payload }: PayloadAction<IHotelListingInfo[]>) => {
      if (payload?.length) {
        state.hotelListing = [...state.hotelListing, ...payload];
      }
    },

    // reset hotel listing
    resetHotelList: (state) => {
      state.hotelListing = []; // initialState.hotel.hotelListing;
    },

    // update searched hotel list 
    updateSearchedHotelList: (
      state,
      { payload }: PayloadAction<{ hotels: ISelectedHotel[] | ITBOHotelDetails[]; service: TypeHotelServices }>
    ) => {
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.searchedHotelList = payload.hotels as ISelectedHotel[]
        :
        state.TBO.searchedHotelList = payload.hotels as ITBOHotelDetails[];
    },

    resetSearchedHotelList: (
      state,
      { payload }: PayloadAction<{ service: TypeHotelServices }>
    ) => {

      state.hotelListing = initialState.hotel.hotelListing;
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.searchedHotelList = initialState.hotel.HotelBeds.searchedHotelList
        :
        state.TBO.searchedHotelList = initialState.hotel.TBO.searchedHotelList;

    },

    // store selected hotel information
    updateSelectedHotel: (
      state,
      { payload }: PayloadAction<{ hotel: ISelectedHotel | ITBOHotelDetails; service: TypeHotelServices }>
    ) => {
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.searchedHotel = payload.hotel as ISelectedHotel
        :
        state.TBO.searchedHotel = payload.hotel as ITBOHotelDetails
    },

    // remove selected hotel information
    removeSelectedHotel: (state, { payload }: PayloadAction<{ service: TypeHotelServices }>) => {
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.searchedHotel = initialState.hotel.HotelBeds.searchedHotel
        :
        state.TBO.searchedHotel = initialState.hotel.TBO.searchedHotel
    },

    /* --------------------------------------------------
        |      For Selected Room
        -------------------------------------------------- */

    // store selected room information
    updateSelectedRoom: (
      state,
      { payload }: PayloadAction<{ rooms: ICombination | ITBORoom[]; service: TypeHotelServices }>
    ) => {
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.selectedRoomCombinations = payload.rooms as ICombination
        :
        state.TBO.selectedRoom = payload.rooms as ITBORoom[];
    },

    updateHotelBedsRoomType: (
      state,
      { payload }: PayloadAction<{ roomType: { [x: string]: number } }>
    ) => {
      state.HotelBeds.selectedRoomCombinations.roomTypes = payload.roomType
      // console.log("Room types: ", payload.roomType);
    },

    // reset the selected room
    resetSelectedRoom: (
      state,
      { payload }: PayloadAction<{ service: TypeHotelServices }>
    ) => {
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.selectedRoomCombinations = initialState.hotel.HotelBeds.selectedRoomCombinations
        :
        state.TBO.selectedRoom = initialState.hotel.TBO.selectedRoom;
    },

    /* --------------------------------------------------
        |      For Passenger Information
        -------------------------------------------------- */

    // store guest information
    updatePassengerInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: TypePassenger;
        value: string;
        roomNo: number;
        passengerIndex: number;
      }>
    ) => {

      if (payload.key === "InternationalTraveler" && payload.value === "Yes") {
        state.passenger[payload.roomNo][payload.passengerIndex].PAN = initialState.hotel.passenger[0][0]?.PAN;
        state.passenger[payload.roomNo][payload.passengerIndex][payload.key] = payload.value;
      } else if (
        payload.key === "InternationalTraveler" && payload.value === "No"
      ) {
        state.passenger[payload.roomNo][payload.passengerIndex].PassportNo = initialState.hotel.passenger[0][0].PassportNo;
        state.passenger[payload.roomNo][payload.passengerIndex].PassportIssueDate = initialState.hotel.passenger[0][0].PassportIssueDate;
        state.passenger[payload.roomNo][payload.passengerIndex].PassportExpDate = initialState.hotel.passenger[0][0].PassportExpDate;
        state.passenger[payload.roomNo][payload.passengerIndex][payload.key] = payload.value;
      };
      state.passenger[payload.roomNo][payload.passengerIndex][payload.key] = payload.value;

    },

    // reset guest info
    resetPassengerInfo: (state) => {
      state.passenger = initialState.hotel.passenger;
    },

    // update the booking guest info
    updateBookingGuestInfo: (state, { payload }: PayloadAction<{ key: TypeBookingPassenger, value: string }>) => {
      state.bookingPassenger[payload.key] = payload.value;
    },

    // reset the booking guest info
    resetBookingGuestInfo: (state) => {
      state.bookingPassenger = initialState.hotel.bookingPassenger;
    },


    /* --------------------------------------------------
        |      For MetaData
        -------------------------------------------------- */
    updateHotelMetaData: (
      state,
      { payload }: PayloadAction<{ key: TypeMetaDataHotels; value: any[] }>
    ) => {
      state.metaData[payload.key] = payload.value;
    },

    resetHotelMarkups: (state) => {
      state.metaData.markUps = [];
      state.metaData.affiliateMarkup = [];
    },

    resetHotelMetaDataCharges: (state) => {
      state.metaData.Charges = [];
    },

    /* --------------------------------------------------
        |      For Pagination
        -------------------------------------------------- */
    updatePagination: (
      state,
      { payload }: PayloadAction<{ key: TypePagination; value: number; service: "HOTELBEDS" | "TBO" }>
    ) => {
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.paginationInfo[payload.key] = payload.value
        :
        state.TBO.paginationInfo[payload.key] = payload.value;
    },

    resetPagination: (state, { payload }: PayloadAction<{ service: "HOTELBEDS" | "TBO" }>) => {
      payload.service === "HOTELBEDS"
        ?
        state.HotelBeds.paginationInfo = initialState.hotel.HotelBeds.paginationInfo
        :
        state.TBO.paginationInfo = initialState.hotel.TBO.paginationInfo;
    },

    /* --------------------------------------------------
      |      For Nearby Places - Geo Locations
      -------------------------------------------------- */
    updateNearbyGeoLocations: (state, { payload }: PayloadAction<INearbySearchGeoLocation[]>) => {
      state.nearbySearchGeoLocations = payload;
    },

    resetNearbyGeoLocations: (state) => {
      state.nearbySearchGeoLocations = initialState.hotel.nearbySearchGeoLocations;
    }
  }



});

export const { updateHotelMetaData } = hotelSlice.actions;

const userReducer = hotelSlice.reducer;
export default userReducer;
