import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaLandmark } from "react-icons/fa";
import { GiPathDistance } from "react-icons/gi";
import {
  AirConditioning,
  Car,
  Gym,
  Lounge,
  Meal,
  Pool,
  StarFull,
  Wifi,
} from "@kiwicom/orbit-components/lib/icons";

import "../hotel.scss";
import img1 from "../../../assets/images/hotel-placeholder.png";

import Image from "../../../components/Common/Image/Image";
import Button from "../../../components/Common/Button/Button";
import ListCheck from "../../../components/Common/ListCheck/ListCheck";
import BsModal from "../../../components/Modal/BSModal";
import { ICommon, IHotel, IHotelFacillity, ISelectedHotel, State } from "../../../redux/types";
import { priceFormatter, showInMapClicked, splitString } from "../../../utils/utilityFunctions/utilityFunction";
import { HOTEL_BEDS_FILE_BASE_URL } from "../../../utils/constants";
import { BsArrowRight } from "react-icons/bs";

interface Props {
  code: string;
  title: string;
  address: string;
  description?: string;
  coverImg: string;
  starRating: number;
  noOfNights: number;
  price: { amount: number; currency: string };
  facilities: string[];
  onSelectHotel: () => void;
  isSelected?: boolean;
}

const HotelListItem = ({
  code,
  title,
  price,
  facilities,
  address,
  description,
  coverImg,
  starRating,
  noOfNights,
  onSelectHotel,
  isSelected = false
}: Props) => {


  const [
    { HotelBeds, searchHotel, metaData },
    { ClientData }
  ]: [IHotel, ICommon] = useSelector((state: State) => [
    state.hotel,
    state.commonState
  ]);


  const [hotel, setHotel] = useState<ISelectedHotel>();
  const [amenities, setAmenities] = useState<IHotelFacillity[]>([]);
  const [showHotelDetails, setShowHotelDetails] = useState<boolean>(false); // to show all available amenities
  const [activeImageIndex, setActiveImageIndex] = useState<number>(0); // for hotel image slider
  const [showImageModal, setShowImageModal] = useState<boolean>(false); // to show modal for hotel images


  useEffect(() => {

    let hotel = HotelBeds.searchedHotelList?.filter((hotel: ISelectedHotel) => Number(hotel.hotel_dynamic_data.code) === Number(code))[0];
    setHotel(hotel);
    // console.log("HotelBeds.searchedHotel?.hotel_static_data: ", hotel);

    let facility = hotel?.hotel_static_data?.facilities?.filter((facility) => {
      if (facility?.facilityName) {
        if (facility.indLogic) return facility;
        else if (facility.indYesOrNo) return facility;
        else if (facility.facilityName?.toLocaleLowerCase() === "wheelchair-accessible" && !facility.indYesOrNo) return facility;
        else if (!facility.hasOwnProperty("indYesOrNo") && !facility.hasOwnProperty("indLogic")) return facility;
      };
    });
    // console.log("facility: ", facility);
    setAmenities(facility);

  }, []);


  return (
    <>
      <div
        className={`card card--hotel-list-item-custom`}
      // onClick={() => onSelectHotel()}
      >
        <div className="card-img">
          <Image src={coverImg} className="card-img" />
        </div>
        <div className="card-body">
          {/* Left side */}
          <div className="left">
            <div>
              <h2 className="hotel-name">{title}</h2>
              <div className="star-rating mb-2">
                {
                  !Number.isNaN(starRating)
                    ?
                    [...Array(starRating)].map((_, index) => (
                      <StarFull size="small" key={index} />
                    ))
                    :
                    <></>
                }
              </div>
              <p className="text-muted mb-1">{address}</p>
              <div className="small">{splitString(description || "", 100) ? splitString(description || "", 100) + " ..." : ""}</div>
              {/* <div className="spacer spacer--sm"></div> */}
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </div>

            <div className="amenities-icons">
              {facilities?.find((item) => item?.toLowerCase().includes("wi-fi")) && <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Wi-Fi">
                <Wifi />
              </div>}
              {facilities?.find((item) => item?.toLowerCase().includes("breakfast")) && <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Brekfast">
                <Meal />
              </div>}
              {facilities?.find((item) => item?.toLowerCase().includes("pool")) && <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Pool">
                <Pool />
              </div>}
              {facilities?.find((item) => item?.toLowerCase().includes("lounge")) && <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Lounge">
                <Lounge />
              </div>}
              {facilities?.find((item) => item?.toLowerCase().includes("air conditioning")) && <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Air Conditioning">
                <AirConditioning />
              </div>}
              {facilities?.find((item) => item?.toLowerCase().includes("gym")) && <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Gym">
                <Gym />
              </div>}
              {facilities?.find((item) => item?.toLowerCase().includes("parking")) && <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Parking">
                <Car />
              </div>}
            </div>
          </div>

          <div className="right">
            <div>
              <div className="price">{priceFormatter(price.amount, price.currency)}</div>
              <p className="small text-muted">for {noOfNights > 1 ? noOfNights + " nights" : noOfNights + " night"}</p>
            </div>

            <div className="d-flex flex-column gap-2">
              <Button
                type={"button"}
                // color={"link"}
                size={"xs"}
                label={"Details"}
                // className={"text-decoration-none px-0 py-0"}
                onClick={() => setShowHotelDetails(true)}
              />
              <Button
                type={"button"}
                // color={"link"}
                size={"xs"}
                label={
                  <div className="d-flex align-items-center">
                    <span>Rooms</span>
                    <BsArrowRight />
                  </div>
                }
                // className={"text-decoration-none px-0 py-0"}
                onClick={() => onSelectHotel()}
              />
            </div>
            {/* <p onClick={() => setShowAllAmenities(true)}>View Details</p>
            <p onClick={() => setShowAllAmenities(true)}>See Rooms</p> */}

            {/* <Button
            type={"button"}
            color={"primary"}
            className={"details-btn"}
            size={"sm"}
            label={"Details"}
            onClick={() => onSelectHotel()}
          /> */}
          </div>
        </div>
      </div>

      {/* Modal for hotel details */}
      {showHotelDetails
        ?
        <React.Fragment>
          <BsModal
            title={hotel?.hotel_dynamic_data.name || ""}
            scrollable={true}
            size="lg"
            onClose={() => setShowHotelDetails(false)}
            body={
              <div>

                {/* IMAGES */}
                {/* <div className="image-grid mt-2">
                  <div className="row g-1">
                    <div className="col-md-6 col-sm-12">
                      <div className="image">
                        <img
                          src={hotel?.hotel_static_data?.images && (HOTEL_BEDS_FILE_BASE_URL + "bigger/" + hotel?.hotel_static_data?.images[0]?.path) || img1}
                          alt=""
                        // onClick={() => {
                        //   if (hotel?.hotel_static_data?.images[0].path) {
                        //     setActiveImageIndex(0);
                        //     setShowImageModal(true);
                        //   }
                        // }} 
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="image">
                        <img
                          src={hotel?.hotel_static_data?.images && (HOTEL_BEDS_FILE_BASE_URL + "bigger/" + hotel?.hotel_static_data?.images[1]?.path) || img1}
                          alt=""
                        // onClick={() => {
                        //   if (hotel?.hotel_static_data?.images[1].path) {
                        //     setActiveImageIndex(1);
                        //     setShowImageModal(true);
                        //   }
                        // }} 
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                      <div className="image">
                        <div className="image-count">
                          <img
                            src={hotel?.hotel_static_data?.images && (HOTEL_BEDS_FILE_BASE_URL + "bigger/" + hotel?.hotel_static_data?.images[2]?.path) || img1}
                            alt=""
                            onClick={() => {
                              if (hotel?.hotel_static_data?.images[2].path) {
                                setActiveImageIndex(2);
                                setShowImageModal(true);
                              }
                            }} />
                          {hotel?.hotel_static_data?.images && hotel?.hotel_static_data?.images?.length > 3 ? <div
                            className="count"
                            onClick={() => {
                              if (hotel?.hotel_static_data?.images[2]) {
                                setActiveImageIndex(2);
                                setShowImageModal(true);
                              }
                            }}
                          >
                            +{hotel?.hotel_static_data?.images?.length - 3}
                          </div> : <></>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="row d-flex justify-content-between">
                  {/* Description & Facilities & Location */}
                  <div className="col-12">
                    <h4>Description</h4>
                    <div style={{ textAlign: "justify" }}>
                      {hotel?.hotel_static_data?.description?.content}
                    </div>
                    <div className="spacer spacer--sm"></div>
                    <div className="row">

                      {/* KEY LOCATION */}
                      <div className="col-md-4 col-sm-12">
                        {hotel?.hotel_static_data?.interestPoints?.length
                          ?
                          <>
                            <h4>Around this property</h4>
                            <div className="list-group list-group-flush">
                              {
                                hotel?.hotel_static_data?.interestPoints?.map((each, index) => (
                                  <div className="border-bottom d-flex flex-column py-2" key={index + "_key_location"}>
                                    <div className="d-flex gap-1">
                                      <span><FaLandmark /></span>
                                      <p className="mb-0 pb-0 fw-semibold">{each.poiName || " - "}</p>
                                    </div>
                                    <div className="d-flex gap-1">
                                      <span><GiPathDistance /></span>
                                      <p className="mb-0 pb-0 text-muted text-sm">{each.distance ? (+each.distance / 1000) + " km" : ""}</p>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </>
                          :
                          <></>}
                      </div>

                      {/* AMENITIES */}
                      <div className="col-md-5 col-sm-12">
                        <h4>Amenities</h4>
                        <div className="amenity-grid">
                          {
                            !amenities.find((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")?.indYesOrNo
                              ?
                              <ListCheck
                                type={"error"}
                                title={amenities.filter((facility) => facility?.facilityName?.toLocaleLowerCase() === "wheelchair-accessible")[0]?.facilityName}
                              />
                              :
                              <></>
                          }
                          {
                            amenities?.slice(0, 10).map((facility, index) => (
                              <div className="d-flex" key={"facility_" + index}>
                                <ListCheck title={facility?.facilityName} key={"facility_name" + index} />
                                {facility.indFee && <span className="d-flex flex-column align-items-start ms-1">*</span>}
                              </div>
                            ))
                          }
                        </div>
                        <div className="spacer spacer--sm"></div>
                        <p className="muted-text mb-0">* These are paid facilities</p>
                      </div>

                      {/* LOCATION */}
                      <div className="col-md-3 col-sm-12">
                        <h4>Location</h4>
                        {hotel?.hotel_dynamic_data?.latitude && hotel?.hotel_dynamic_data?.longitude
                          ?
                          <div
                            className="map-placeholder mt-3"
                            onClick={() =>
                              showInMapClicked(+hotel?.hotel_dynamic_data?.latitude, +hotel?.hotel_dynamic_data?.longitude)
                            }
                          >
                            <Image
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD9YSkJq_hgJvYhMvVatqATlw-IqugNFVDHA&usqp=CAU"
                              className="rounded w-100"
                            />
                            <div className="text">View on map</div>
                          </div>
                          :
                          <></>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          // footer={<Button
          //   type="button"
          //   color="primary"
          //   size="sm"
          //   label="Close"
          //   onClick={() => setShowAllAmenities(false)} />}
          />
        </React.Fragment>
        :
        <></>}
    </>
  );
};

export default HotelListItem;