import { IFlightItinerary } from "./../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "../initialState";
import {
  ISSR,
  IItineraryBookingSum,
  IItineraryMeta,
  TypeFlightFiltersKeys,
  TypeFlightSearchKeys,
  TypeMetaDataFlights,
  TypePassengerData,
  TypePersonalInfo,
  ISelectedAddOns,
  ISeat,
  IFare,
} from "../../utils/types/flightTypes";

const newPassengerObj = {
  Title: "Mr.",
  firstName: "",
  lastName: "",
  gender: "Male",
  dateOfBirth: "",
};
export const flightSlice = createSlice({
  name: "flight",
  initialState: initialState.flight,
  reducers: {
    resetPassengers: (state) => {

      // console.log("Passenger Updated!", initialState.flight.personalInfo.passengers);
      state.personalInfo.passengers = initialState.flight.personalInfo.passengers;
      state.searchInfo = initialState.flight.searchInfo;

    },
    updateSearch: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: TypeFlightSearchKeys;
        value: any;
        index?: number;
      }>
    ) => {
      // console.log("-------------------------------------> updateSearch: ", payload);
      // reset selected flights after any changes in the search params
      state.selectedFlights = [];
      state.Itinerary = initialState.flight.Itinerary;

      if (
        payload.key === "adult" ||
        payload.key === "child" ||
        payload.key === "infant"
      ) {
        if (state.searchInfo[payload.key] < Number(payload.value)) {
          // add new objects in personal info state for the new passenger
          state.personalInfo.passengers[payload.key] = [
            ...state.personalInfo.passengers[payload.key],
            newPassengerObj,
          ];
        } else if (state.searchInfo[payload.key] > Number(payload.value)) {
          state.personalInfo.passengers[payload.key].splice(
            Number(payload.value),
            1
          );
        }
      } else if (
        payload.key === "travelType" &&
        payload.value?.SearchTypeId !==
          state.searchInfo.travelType?.SearchTypeId
      ) {
        // reset inputs if travel type changes
        state.searchInfo.departureDate = [];
        state.searchInfo.fromCity = [];
        state.searchInfo.toCity = [];
      }

      // condition to push into array or push into direct values coz fromCity toCity and Dep date is of type []
      if (
        payload.key === "fromCity" ||
        payload.key === "toCity" ||
        payload.key === "departureDate"
      ) {
        state.searchInfo[payload.key][payload.index || 0] = payload.value;
      } else {
        state.searchInfo[payload.key] = payload.value;
      }
    },

    /*
    /storing arrays of strings in filters state as there can be multiple filters of one type
    checking below if the payload.value already exists in the filters then remove it else add it
    */
    updateFilters: (
      state,
      { payload }: PayloadAction<{ key: TypeFlightFiltersKeys; value: string }>
    ) => {
      if (payload.key === "priceRange" || payload.key === "duration") {
        state.filters[payload.key][0] = Number(payload.value);
      } else {
        if (
          state.filters[payload.key].findIndex((e) => e === payload.value) ===
          -1
        ) {
          state.filters[payload.key] = [
            ...state.filters[payload.key],
            payload.value,
          ];
        } else {
          state.filters[payload.key] = state.filters[payload.key].filter(
            (e: string) => e !== payload.value
          );
        }
      }
    },
    setSelectedFlights: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number; //key is index of selected flight
        value: any;
      }>
    ) => {
      state.selectedFlights[payload.index] = payload.value;
    },
    resetSelectedFlights: (state) => {
      state.selectedFlights = [];
    },

    // add to itinerary
    updateItinerary: (state, { payload }: PayloadAction<IFlightItinerary>) => {
      state.Itinerary = payload;
    },

    // Using this for updating itinerary prices when user selects ssr
    // calculate all the charges again and update them in redux
    updateItineraryMeta: (
      state,
      { payload }: PayloadAction<{ index: number; data: IItineraryMeta }>
    ) => {
      state.Itinerary.Journeys[payload.index].meta = payload.data;
    },

    // Using this for updating itinerary total sum
    // when we sell ssrs we get updated total booking sum
    // which is to be sent while addPaymentToBooking
    // Update: Now booking sum is [] since we might have multiple carriers selected for booking
    // and bookingSUm[i] indicates the total sum of the ith carrier
    updateItineraryBookingSum: (
      state,
      {
        payload,
      }: PayloadAction<{
        bookingSum: IItineraryBookingSum;
        carrierCode: string;
      }>
    ) => {
      const index = state.Itinerary.BookingSum.findIndex(
        (e) => e.CarrierCode === payload.carrierCode
      );
      state.Itinerary.BookingSum[index] = {
        ...payload.bookingSum,
        CarrierCode: payload.carrierCode,
      };
    },
    // add to itinerary
    resetItinerary: (state) => {
      state.Itinerary = initialState.flight.Itinerary;
    },

    updatePersonalInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: TypePersonalInfo;
        value: string;
      }>
    ) => {
      state.personalInfo[payload.key] = payload.value;
    },
    updatePassengerData: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: "adult" | "child" | "infant";
        index: number;
        key: TypePassengerData;
        value: string;
      }>
    ) => {
      state.personalInfo.passengers[payload.type][payload.index][payload.key] =
        payload.value;
    },
    resetFlightData: (state) => {
      // reset
      state.Itinerary = initialState.flight.Itinerary;
      state.filters = initialState.flight.filters;
      // state.personalInfo = initialState.flight.personalInfo;
      state.searchInfo = initialState.flight.searchInfo;
      state.selectedFlights = initialState.flight.selectedFlights;
    },
    updateFlightMetaData: (
      state,
      { payload }: PayloadAction<{ key: TypeMetaDataFlights; value: any[] }>
    ) => {
      if (payload.key === "Markup") {
        state.metaData.Markup = [...state.metaData.Markup, payload.value];
      } else if (payload.key === "AffiliateMarkup") {
        state.metaData.AffiliateMarkup = [
          ...state.metaData.AffiliateMarkup,
          payload.value,
        ];
      } else {
        state.metaData[payload.key] = payload.value;
      }
    },
    resetMarkup: (state) => {
      state.metaData.Markup = initialState.flight.metaData.Markup;
      state.metaData.AffiliateMarkup =
        initialState.flight.metaData.AffiliateMarkup;
    },

    // add SelectedSSR list inital data
    setSelectedAddons: (
      state,
      { payload }: PayloadAction<ISelectedAddOns[]>
    ) => {
      state.SelectedAddOns = payload;
    },

    // // update SelectedSSR list data
    // updateSelectedSSRList: (
    //   state,
    //   {
    //     payload,
    //   }: PayloadAction<
    //     {
    //       segIndex: number;
    //       passengerIndex: number;
    //       ssrData: ISSR;
    //     }[]
    //   >
    // ) => {
    //   payload.forEach((item) => {
    //     if (state.SelectedSSRList) {
    //       const isAlreadySelected = state.SelectedSSRList[
    //         item.segIndex
    //       ].passengers[item.passengerIndex].findIndex(
    //         (e) => e.SSRCode === item.ssrData.SSRCode
    //       );
    //       if (isAlreadySelected === -1) {
    //         state.SelectedSSRList[item.segIndex].passengers[
    //           item.passengerIndex
    //         ].push(item.ssrData);
    //       } else {
    //         // state.SelectedSSRList[item.segIndex].passengers[
    //         //   item.passengerIndex
    //         // ].splice(isAlreadySelected, 1);
    //       }
    //     }
    //   });
    // },

    // update SelectedSSR list data
    updateSelectedSSR: (
      state,
      {
        payload,
      }: PayloadAction<
        { segIndex: number; passengerIndex: number; ssrData: ISSR }[]
      >
    ) => {
      payload.forEach((item) => {
        if (state.SelectedAddOns) {
          // const isAlreadySelected = state.SelectedAddOns[item.segIndex].SSR[
          //   item.passengerIndex
          // ].findIndex((e) => e.SSRCode === item.ssrData.SSRCode);
          // if (isAlreadySelected === -1) {
          //   state.SelectedAddOns[item.segIndex].SSR[item.passengerIndex].push(
          //     item.ssrData
          //   );
          // } else {
          //   // state.SelectedSSRList[item.segIndex].passengers[
          //   //   item.passengerIndex
          //   // ].splice(isAlreadySelected, 1);
          // }

          state.SelectedAddOns[item.segIndex].SSR[item.passengerIndex] = [
            ...state.SelectedAddOns[item.segIndex].SSR[
              item.passengerIndex
            ].filter(
              (e) => (e.type.length > 0 ? e.type !== item.ssrData.type : true)
              //ssr data other than meal and bag have type "" so a new ssr with type '' replaced old one hence skipping those
            ),
            item.ssrData,
          ];
          //  .filter((e) => e.type !== item.ssrData.type); //remove previous selected ssr of same type for same seg and same pax
          // temp.push(item.ssrData);
        }
      });
    },
    clearSelectedSSR: (
      state,
      { payload }: PayloadAction<{ type: "MEAL" | "EB" }>
    ) => {
      state.SelectedAddOns = state.SelectedAddOns?.filter((seg) =>
        seg.SSR.filter((pax) => pax.filter((ssr) => ssr.type !== payload.type))
      );
    },
    // update Selected seat list data
    updateSelectedSeat: (
      state,
      {
        payload,
      }: PayloadAction<
        { segIndex: number; passengerIndex: number; selectedSeat: ISeat }[]
      >
    ) => {
      payload.forEach((item) => {
        if (state.SelectedAddOns) {
          state.SelectedAddOns[item.segIndex].Seats[item.passengerIndex] = [
            item.selectedSeat,
          ];
        }
      });
    },

    // update Fare

    setFare: (state, { payload }: PayloadAction<IFare>) => {
      state.Fare = payload;
    },
  },
});

export const {
  resetPassengers,
  updateSearch,
  updatePersonalInfo,
  updatePassengerData,
  updateFlightMetaData,
} = flightSlice.actions;

const flightReducer = flightSlice.reducer;
export default flightReducer;
