import axios, { AxiosResponse } from 'axios';

import {
    IBlockRoom,
    IBookHotel,
    IHotelbedsBooking,
    ISearchHotelPayload,
    ITBOAvailabilityPayload,
    ITBOHotelCancelPayload,
    ITBOHotelCancelResponse
} from "../../utils/types/hotelTypes";
import { IDynamicHotelRate } from '../../redux/types';
import { HOTEL_API_URL, TBO_HOTEL_API_URL } from './../../utils/constants';

export interface ISearchTBOHotel {
    getCountryList: () => Promise<AxiosResponse>;
    getTopDestinationList: () => Promise<AxiosResponse>;

    getDestinatioSearch: (
        country: string,
        type: "1" | "2"
    ) => Promise<AxiosResponse>;

    getAvailability: (
        payload: ITBOAvailabilityPayload
    ) => Promise<AxiosResponse>;

    getParticularHotelDetail: (
        ResultIndex: string,
        HotelCode: string,
        TraceId: string
    ) => Promise<AxiosResponse>;

    getSelectedHotelRooms: (
        ResultIndex: string,
        HotelCode: string,
        traceId: string
    ) => Promise<AxiosResponse>;

    blockRoom: (payload: IBlockRoom) => Promise<AxiosResponse>;

    bookHotel: (payload: IBookHotel) => Promise<AxiosResponse>;

    getHotelBookingDetails: (reservationId: number) => Promise<AxiosResponse>;

    cancelBooking: (bookingID: number) => Promise<AxiosResponse>;

    getHotelCancelDetails: (changeRequestID: number) => Promise<AxiosResponse>;
};

export interface ISearchHotelBedsHotel {
    getAvailability: (
        payload: ISearchHotelPayload,
        whereTo: string,
        pageNumber: number,
        isUsingGeoLocation: boolean,
        chunk_size?: number
    ) => Promise<AxiosResponse>;

    getDestinations: (country: string) => Promise<AxiosResponse>;

    checkRate: (roomCombinations: IDynamicHotelRate[]) => Promise<AxiosResponse>;

    bookRoom: (payload: IHotelbedsBooking) => Promise<AxiosResponse>;

    cancelPreviousBooking: (bookingReference: string) => Promise<AxiosResponse>;

    fetchBookingDetails: (bookingReference: string) => Promise<AxiosResponse>;
};

export interface IHotelBedsContentTypes {

    getAccommodationTypes: () => Promise<AxiosResponse>;

};




class SearchTBOHotel implements ISearchTBOHotel {

    header = {
        // "content-type": "application/json",
        // "api-key": "5b5401d2-5f82-4605-a54f-af2e209da710",
        "ClientId": "ApiIntegrationNew",
        "EndUserIp": "192.168.11.120"
    };

    // fetching available countries
    getCountryList(): Promise<AxiosResponse> {
        return axios.get(`${TBO_HOTEL_API_URL}auth/CountryList`, { headers: this.header });
    };

    // fetching top destination
    getTopDestinationList(): Promise<AxiosResponse> {
        return axios.get(`${TBO_HOTEL_API_URL}booking/TopDestinationList`, { headers: this.header });
    };

    // fetching available destinationbased on searchType(1 | 2)
    // 1 -> city, returns city list
    // 2 -> hotel, returns hotel list
    getDestinatioSearch(country: string, type: "1" | "2"): Promise<AxiosResponse> {
        let body = {
            CountryCode: country,
            SearchType: type
        };
        return axios.post(`${TBO_HOTEL_API_URL}booking/GetDestinationSearch`, body, { headers: this.header });
    };

    // fetching hotel list based on search parameters
    getAvailability(
        payload: ITBOAvailabilityPayload
    ): Promise<AxiosResponse> {
        let header = {
            "EndUserIp": "192.168.11.120"
        };
        let body = {
            CheckInDate: payload.checkInDate,
            NoOfNights: payload.noOfNights,
            CountryCode: "IN",
            CityId: payload.cityId,
            PreferredCurrency: "INR",
            GuestNationality: "IN",
            NoOfRooms: payload.noOfRooms,
            RoomGuests: payload.roomGuest,
            MaxRating: payload.maxRating,
            MinRating: payload.minRating,
            ResultCount: payload.resultCount
        };
        return axios.post(`${TBO_HOTEL_API_URL}booking/GetHotel`, body, { headers: header });
    };

    // fetching selected hotel details
    getParticularHotelDetail(
        resultIndex: string,
        hotelCode: string,
        traceId: string
    ): Promise<AxiosResponse> {
        let header = {
            // "api-key": "5b5401d2-5f82-4605-a54f-af2e209da710",
            "TraceId": traceId,
        };
        let body = {
            ResultIndex: resultIndex,
            HotelCode: hotelCode,
            EndUserIp: "123.1.1.1",
        };
        return axios.post(`${TBO_HOTEL_API_URL}booking/GetHotelInfo`, body, { headers: header });
    };

    // fetching selected hotel rooms
    getSelectedHotelRooms(
        resultIndex: string,
        hotelCode: string,
        traceId: string
    ): Promise<AxiosResponse> {
        let header = {
            "TraceId": traceId,
        };
        let body = {
            ResultIndex: resultIndex,
            HotelCode: hotelCode,
            EndUserIp: "192.168.11.120"
        };
        return axios.post(`${TBO_HOTEL_API_URL}booking/GetHotelRoom`, body, { headers: header });
    };

    /* The `blockRoom` method is used to block rooms for a particular hotel. */
    blockRoom(payload: IBlockRoom): Promise<AxiosResponse> {
        let header = {
            "TraceId": payload.traceId
        };
        let body = {
            ResultIndex: payload.resultIndex,
            HotelCode: payload.hotelCode,
            HotelName: payload.hotelName,
            GuestNationality: payload.guestNationality,
            NoOfRooms: payload.noOfRooms,
            ClientReferenceNo: payload.clientReferenceNo,
            IsVoucherBooking: payload.isVoucherBooking,
            HotelRoomDetails: payload.hotelRoomDetails,
            EndUserIp: "123.1.1.1"
        };
        return axios.post(`${TBO_HOTEL_API_URL}booking/BlockRoom`, body, { headers: header });
    };

    // book hotel
    bookHotel(payload: IBookHotel): Promise<AxiosResponse> {
        let header = {
            "TraceId": payload.traceId
        };
        let body = {
            ResultIndex: payload.resultIndex,
            HotelCode: payload.hotelCode,
            HotelName: payload.hotelName,
            GuestNationality: payload.guestNationality,
            NoOfRooms: payload.noOfRooms,
            ClientReferenceNo: payload.clientReferenceNo,
            IsVoucherBooking: payload.isVoucherBooking,
            IsPackageFare: payload.IsPackageFare,
            IsPackageDetailsMandatory: payload.IsPackageDetailsMandatory,
            HotelRoomDetails: payload.hotelRoomDetails,
            EndUserIp: "123.1.1.1"
        };
        return axios.post(`${TBO_HOTEL_API_URL}booking/Book`, body, { headers: header });
    };

    // fetching booked hotel details based on reservationId
    getHotelBookingDetails(reservationId: number): Promise<AxiosResponse> {
        return axios.get(`${TBO_HOTEL_API_URL}booking/GetBookingDetails/${reservationId}`);
    }

    cancelBooking(bookingID: number): Promise<AxiosResponse> {
        let cancelationPayload: ITBOHotelCancelPayload = Object.assign({
            BookingMode: "5",
            RequestType: "4",
            Remarks: "Booking Cancellation",
            BookingId: bookingID,
            EndUserIp: "123.1.1.1"
        });
        return axios.post(`${TBO_HOTEL_API_URL}booking/Cancel/SendChangeRequest`, cancelationPayload);
    }

    getHotelCancelDetails(changeRequestID: number): Promise<AxiosResponse> {
        let payload: ITBOHotelCancelResponse = Object.assign({
            BookingMode: "5",
            ChangeRequestId: changeRequestID,
            EndUserIp: "123.1.1.1"
        });
        return axios.post(`${TBO_HOTEL_API_URL}booking/Cancel/GetChangeRequestStatus`, payload);
    }

};

class SearchHotelBedsHotel implements ISearchHotelBedsHotel {

    /* Fetching available destinations(city) based on country code. */
    getDestinations = (country: string): Promise<AxiosResponse> => {
        return axios.get(`${HOTEL_API_URL}content/locations/destinations?countryCode=${country}`);
    };

    /* Fetching available hotels based on destination code. */
    getAvailability = (
        payload: ISearchHotelPayload,
        whereTo: string,
        pageNumber: number,
        isUsingGeoLocation: boolean,
        chunk_size?: number
    ): Promise<AxiosResponse> => {

        let url = `${HOTEL_API_URL}booking/hotels?page=${pageNumber}`;

        // if we are not using geo location then we need to pass destination code
        if (!isUsingGeoLocation) url += `&destinationCode=${whereTo}`;
        // mentioning chunk size (items per page)
        if (chunk_size) url += `&chunk_size=${chunk_size}`;

        return axios.post(url, payload);

    };

    /* Checks rates for selected rate(room) combinations */
    checkRate = (roomCombinations: IDynamicHotelRate[]): Promise<AxiosResponse> => {
        let payload = roomCombinations?.map((eachRate) => {
            return Object.assign({
                rateKey: eachRate.rateKey
            })
        });
        let body = { rooms: payload };
        return axios.post(`${HOTEL_API_URL}booking/checkrates`, body);
    };

    /* Booking hotel */
    bookRoom = (payload: IHotelbedsBooking) => {
        return axios.post(`${HOTEL_API_URL}booking/bookings`, payload);
    };

    /*
    This method is used to cancel the previous booking.
    bookingReference - is the reference for the booking to be deleted 
    */
    cancelPreviousBooking = (bookingReference: string): Promise<AxiosResponse> => {
        return axios.delete(`${HOTEL_API_URL}booking/bookings/${bookingReference}?cancellationFlag=CANCELLATION`);
    };

    fetchBookingDetails = (bookingReference: string) => {
        return axios.get(`${HOTEL_API_URL}booking/bookings/${bookingReference}`);
    };

};

class GetContentsForHotelBeds implements IHotelBedsContentTypes {

    getAccommodationTypes = (): Promise<AxiosResponse> => {
        return axios.get(`${HOTEL_API_URL}content/types/accommodations`);
    };

};



const searchHotelTBO = new SearchTBOHotel();
const searchHotelBeds = new SearchHotelBedsHotel();
const hotelBedsContentTypes = new GetContentsForHotelBeds();



export {
    searchHotelTBO,
    searchHotelBeds,
    hotelBedsContentTypes
};