import { useRef, useEffect } from "react";
const useClickOutSide = (
  ref: React.MutableRefObject<HTMLElement | null>,
  callback: () => void
) => {
  const callbackRef = useRef<() => void>();
  callbackRef.current = callback;
  useEffect(() => {
    const handleClickOutSide = (e: MouseEvent) => {
      if (!ref?.current?.contains(e.target as Node) && callbackRef.current)
        callbackRef.current();
    };
    document.addEventListener("click", handleClickOutSide, true);

    return () =>
      document.removeEventListener("click", handleClickOutSide, true);
  }, [callbackRef, ref]);
};
export default useClickOutSide;

// usages
/*
/ const bodyRef = useRef<HTMLDivElement |null>(null);
/ useClickOutSide(bodyRef,()=>{
/    console.log('clicked outside the referred div');
/ })
/ <div ref={bodyRef}>
/ </div>
/
*/
