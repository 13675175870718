import React, { FC, useEffect, useState } from "react";
import Button from "../../../components/Common/Button/Button";
import useFlight from "../../../utils/hooks/Flight/useFlight";
import { useSelector } from "react-redux";
import { State } from "../../../redux/types";
import { getDistance } from "../../../utils/utilityFunctions/utilityFunction";
import DatePicker from "../../../components/FlightModal/DatePicker";
import moment from "moment";
import Input2 from "../../../components/Common/Input/Input2";

interface Props {
  isPreSearch?: boolean;
}
interface IRow {
  onClearRow: () => void;
  isFirst: boolean;
  index: number;
  minDate?: string;
}

const Row: FC<IRow> = ({ onClearRow, isFirst, index, minDate }) => {

  const { updateSearch } = useFlight();

  const { searchInfo, metaData } = useSelector((state: State) => state.flight);
  const { location } = useSelector((state: State) => state.user);

  return (
    <div className="search-flight-container">
      <Input2
        onChange={(value) => {
          updateSearch(
            "fromCity",
            metaData.FlightOrigins?.filter(
              (e) => e.RouteDestinationCode === value
            )[0],
            index
          );
        }}
        label="From"
        value={
          searchInfo?.fromCity[index]?.City || ""
          // (index !== 0 && searchInfo?.toCity[index - 1] !== null
          //   ? searchInfo?.toCity[index - 1]?.City
          //   : "")
        }
        placeholder="Select city"
        suggestionArray={metaData?.FlightOrigins?.map((air) => ({
          title: air.City,
          airportCode: air.RouteDestinationCode,
          subtitle: air.RouteDestinationName,
          value: air.RouteDestinationCode,
          icon: air.RouteDestinationCode,
          label: air.RouteDestinationCode
          // getDistance(
          //   {
          //     lat: Number(air.Coordinate1),
          //     lon: Number(air.Coordinate2),
          //   },
          //   { lat: location?.latitude, lon: location?.longitude }
          // ),
        }))}
      />
      {/* To City Inputs */}
      <Input2
        label="To"
        onChange={(value) => {
          updateSearch(
            "toCity",
            metaData.FlightDestinations?.filter(
              (e) => e.RouteDestinationCode === value
            )[0],
            index
          );
          if (index !== 2) {
            updateSearch(
              "fromCity",
              metaData.FlightOrigins?.filter(
                (e) => e.RouteDestinationCode === value
              )[0],
              index + 1
            );
          }
        }}
        value={searchInfo?.toCity[index]?.City || ""}
        placeholder="Select city"
        suggestionArray={metaData?.FlightDestinations?.filter(
          (airport) =>
            airport.RouteDestinationCode !==
            searchInfo.fromCity[index]?.RouteDestinationCode
        )?.map((air) => ({
          title: air.City,
          airportCode: air.RouteDestinationCode,
          subtitle: air.RouteDestinationName,
          value: air.RouteDestinationCode,
          icon: air.RouteDestinationCode,
          label: air.RouteDestinationCode
          // getDistance(
          //   {
          //     lat: Number(air.Coordinate1),
          //     lon: Number(air.Coordinate2),
          //   },
          //   { lat: location?.latitude, lon: location?.longitude }
          // ),
        }))}
      />

      {/* departure date */}
      <DatePicker
        onChange={(value) => {
          updateSearch("departureDate", value, index);
        }}
        minDate={moment(minDate).format()}
        value={searchInfo?.departureDate[index]}
        label={"Departure"}
      />
    </div>
  );
};

const MultiCityInputs = (props: Props) => {

  const { searchInfo } = useSelector((state: State) => state.flight);

  const [numberOfTravels, setNumberOfTravels] = useState<number>(2);



  useEffect(() => {
    if (searchInfo?.toCity.length > 2) {
      setNumberOfTravels(3);
    }
  }, [searchInfo?.toCity]);



  return (
    <div>
      {[...new Array(numberOfTravels)].map((r, index) => (
        <div className="py-2" key={index}>
          <Row
            onClearRow={() => setNumberOfTravels((prev) => prev - 1)}
            isFirst={numberOfTravels <= 1}
            index={index}
            minDate={searchInfo?.departureDate[index - 1]}
          />
        </div>
      ))}
      <div className="d-flex">
        {numberOfTravels < 3 ? (
          <Button
            onClick={() => {
              setNumberOfTravels((prev) => prev + 1);
            }}
            color="link"
            className="text-decoration-none"
            label={"+ Add Destination"}
          />
        ) : (
          <></>
        )}
        {!props?.isPreSearch ? (
          <Button label={"Search"} className="ms-auto px-4" />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MultiCityInputs;
