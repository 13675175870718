import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { BsInfoCircle } from 'react-icons/bs';

import BsModal from '../../../../components/Modal/BSModal';
import Button from '../../../../components/Common/Button/Button';
import ListCheck from '../../../../components/Common/ListCheck/ListCheck';

import useHotel from '../../../../utils/hooks/Hotel/useHotel';
import { ICombination, ICommon, IDynamicHotelRate, IHotel, IStaticHotelRoomInfo, State } from '../../../../redux/types';
import { calculateDateForFreeCancellation, isDateAfter } from '../../../../utils/utilityFunctions/hotelUtitlityFunctions';
import { calculateMarkedupBaseCharge, findOccuranceCount, findOccurrenceCountForRates, priceFormatter } from '../../../../utils/utilityFunctions/utilityFunction';

interface Props {
    boardCode: string;
    currency: string;
    rate: ICombination;
    onSelect?: () => void;
    onRemove: () => void;
};

export default function RoomCombination({
    rate,
    boardCode,
    currency,
    onSelect,
    onRemove
}: Props) {

    const [
        { searchHotel, metaData, HotelBeds },
        { ClientData }]: [IHotel, ICommon] = useSelector((state: State) => [
            state.hotel,
            state.commonState
        ]);
    const { updateHotelBedsRoomTypes } = useHotel();

    // console.log("Marked up price: ", calculateMarkedupBaseCharge(
    //     metaData?.markUps,
    //     metaData?.affiliateMarkup,
    //     rate.combination,
    //     // metaData?.BookingSupplier[0]?.SupplierName,
    //     "Hotelbeds",
    //     currency,
    //     ClientData.GSTRates[0]?.Value,
    //     searchHotel.RoomGuest
    // ));

    // const [roomDetails, setRoomDetails] = useState<IHotelRoom | null>(null); // stores the room details -> static && dynamin data
    const [staticRooms, setStaticRooms] = useState<IStaticHotelRoomInfo[]>([]);
    const [images, setImages] = useState<string[]>([]); // stores the room images
    const [viewDetails, setViewDetails] = useState<boolean>(false); // to render details of room in a modal
    const [activeImageIndex, setActiveImageIndex] = useState<number>(0); // for hotel image slider



    useEffect(() => {

        let staticRoom: IStaticHotelRoomInfo[] = [];
        rate.combination.forEach((eachCombination: IDynamicHotelRate) => {
            staticRoom.push(HotelBeds.searchedHotel.hotel_static_data.rooms?.filter(eachRoom => eachCombination.rateKey.includes(eachRoom.roomCode))[0] || []);
        });
        setStaticRooms(staticRoom.flat());
        // console.log("staticRooms", staticRooms.flat());

        let img = HotelBeds.searchedHotel?.hotel_static_data?.images?.filter((image) => {
            return staticRoom.filter(eachRoom => eachRoom?.roomCode === image?.roomCode).length > 0;
        })?.map((image) => image?.path);
        setImages(img);

        // console.group("Room Combination");
        // console.log("Rate Combination: ", rate);
        // console.log("Static rooms: ", staticRoom);
        // console.log("Images: ", img);
        // console.groupEnd();

    }, []);



    return (
        <React.Fragment>

            {/* NEW DESIGN */}
            <div className="room-container">
                <div className="room-type">
                    <div className="room-info">
                        <span
                            className="fw-bold"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={boardCode}
                        >
                            {rate?.combination[0]?.boardName}
                        </span>
                        {staticRooms.length
                            ?
                            <BsInfoCircle
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="View Details"
                                className="pointer"
                                onClick={() => setViewDetails(true)}
                            />
                            :
                            <span className="text-muted small">Details not available!</span>}
                    </div>
                    {rate?.combination[0]?.cancellationPolicies?.length > 0
                        ?
                        <div className="text-success small">
                            Cancelation is free till {calculateDateForFreeCancellation(rate?.combination[0]?.cancellationPolicies[0]?.from)}
                            {/* {moment(rate?.combination[0]?.cancellationPolicies[0]?.from).subtract(1, 'd').format("Do MMM YYYY")} */}
                        </div>
                        : <></>}
                </div>

                {/* <div className="room-count">
                    {staticRooms.length > 0 && Object.keys(findOccuranceCount(staticRooms))?.map((each, index) => (
                        <span className="text-capitalize text-muted" key={index + "_room"}>
                            {each ? <>{findOccuranceCount(staticRooms)[each]} - {each}</> : <></>}
                        </span>
                    ))}
                </div> */}
                <div className="room-count">
                    {rate.combination?.length > 0 && Object.keys(findOccurrenceCountForRates(rate.combination))?.map((each, index) => (
                        <span className="text-capitalize text-muted" key={index + "_room"}>
                            {each ? <>{findOccurrenceCountForRates(rate.combination)[each]} - {each}</> : <></>}
                        </span>
                    ))}
                </div>

                <div className="price-selection">
                    <div className="price-details">
                        <span className="fw-bold">
                            {calculateMarkedupBaseCharge(
                                metaData?.markUps,
                                metaData?.affiliateMarkup,
                                rate.combination,
                                // metaData?.BookingSupplier[0]?.SupplierName,
                                "Hotelbeds",
                                currency,
                                ClientData.GSTRates[0]?.Value,
                                searchHotel.RoomGuest
                            )}
                        </span>
                        <span className="text-muted" style={{ fontSize: "12px" }}>Exclusive taxes</span>
                        {/* {staticRooms.length ? 
                        // <Button
                        //     type={"button"}
                        //     color={"link"}
                        //     size={"sm"}
                        //     label={"View Details"}
                        //     className={"text-decoration-none px-0 py-0"}
                        //     onClick={() => setViewDetails(true)}
                        // /> 
                        <span
                        className="small text-primary fw-bold pointer"
                        onClick={() => setViewDetails(true)}
                        style={{ fontSize: "12px" }}
                        >
                            View Details
                        </span>
                        : 
                        <span className="text-muted small">Details not available!</span>} */}
                    </div>
                    {
                        // rate.combination.filter((each) => selectedRoomCombinations.combination.find(item => item.rateKey === each.rateKey)).length !== 0
                        (rate.id === HotelBeds.selectedRoomCombinations.id && rate.price === HotelBeds.selectedRoomCombinations.price)
                            ?
                            <Button
                                type={"button"}
                                className={"btn-success"}
                                size={"sm"}
                                label={"Selected"}
                                onClick={() => {
                                    onRemove();
                                }} />
                            // <button
                            //     type="button"
                            //     className="btn btn-sm btn-success"
                            //     onClick={() => onRemove()}
                            // >
                            //     <MdDone className="me-1" />Seleceted
                            // </button>
                            :
                            <Button
                                type={"button"}
                                color={"primary"}
                                outline={true}
                                size={"sm"}
                                label={"Select Room"}
                                onClick={() => {
                                    onSelect && onSelect();
                                    staticRooms.length > 0 && updateHotelBedsRoomTypes(
                                        Object.keys(findOccurrenceCountForRates(rate.combination))?.map((each, index) => (
                                            findOccurrenceCountForRates(rate.combination)
                                        ))[0]
                                    );
                                }} />
                    }
                </div>
            </div>

            {/* VIEW DETAILS MODAL FOR ROOMS */}
            {viewDetails
                ?
                <BsModal
                    size={"lg"}
                    title={"Information"}
                    subTitle={"Detailed information of each room"}
                    scrollable={true}
                    onClose={() => setViewDetails(false)}
                    body={
                        <React.Fragment>
                            <div className="d-flex flex-column gap-2">
                                {
                                    staticRooms?.map((eachRoom: IStaticHotelRoomInfo, index) => (
                                        <React.Fragment key={index}>
                                            {/* ROOM TYPE INFO */}
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="my-0">Room {index + 1}</h5>
                                                {/* <div className="d-flex align-items-center">
                                                    <div
                                                        className="bg-dark mx-1"
                                                        style={{ width: 4, height: 4, borderRadius: 4 }}
                                                    ></div>
                                                    <span className="small">
                                                        {eachRoom?.roomCode && eachRoom?.roomType && eachRoom?.roomTypeName
                                                            &&
                                                            eachRoom?.roomTypeName}
                                                    </span>
                                                </div> */}
                                                <span className="small">
                                                    {+searchHotel.RoomGuest[index].NoOfAdults > 1
                                                        ?
                                                        searchHotel.RoomGuest[index].NoOfAdults + " Adults "
                                                        :
                                                        searchHotel.RoomGuest[index].NoOfAdults + " Adult "
                                                    }{+searchHotel.RoomGuest[index].NoOfChild > 0
                                                        ?
                                                        searchHotel.RoomGuest[index].NoOfChild
                                                            ?
                                                            searchHotel.RoomGuest[index].NoOfChild + ", Children"
                                                            :
                                                            searchHotel.RoomGuest[index].NoOfChild + ", Child"
                                                        :
                                                        <></>}
                                                </span>
                                            </div>
                                            <hr className="m-0" />
                                            {/* ROOM IMAGES */}
                                            {/* <div id="carouselExampleControls" className="carousel slide image-slider" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            {
                                                images?.map((image, index) => (
                                                    <div className={`carousel-item ${activeImageIndex === index && 'active'}`} key={index}>
                                                        <Image
                                                            src={HOTEL_BEDS_FILE_BASE_URL + "bigger/" + image || RoomPlaceholder}
                                                            className={"rounded"}
                                                            style={{ height: "60%", width: "100%", objectFit: "cover" }}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {activeImageIndex ? <button
                                            className="carousel-control-prev"
                                            type="button"
                                            data-bs-target="#carouselExampleControls"
                                            data-bs-slide="prev"
                                            onClick={() => setActiveImageIndex(activeImageIndex - 1)}
                                        >
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button> : <></>}
                                        {(activeImageIndex + 1) !== images?.length ? <button
                                            className="carousel-control-next"
                                            type="button"
                                            data-bs-target="#carouselExampleControls"
                                            data-bs-slide="next"
                                            onClick={() =>
                                                setActiveImageIndex(activeImageIndex + 1)
                                            }
                                        >
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button> : <></>}
                                    </div> */}

                                            {/* AMENITIES */}
                                            <div className="spacer spacer--sm"></div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h5>Amenities:</h5>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {
                                                                eachRoom
                                                                    &&
                                                                    eachRoom?.roomFacilities && eachRoom.roomFacilities?.length < 5
                                                                    ?
                                                                    <div className="row">
                                                                        <div className="col-md-6 col-sm-12">
                                                                            {eachRoom?.roomFacilities?.slice(0, Math.abs(eachRoom?.roomFacilities?.length / 2))?.map((facility, index) => (
                                                                                <span className="d-flex align-items-center" key={index}>
                                                                                    <ListCheck title={facility?.facilityName} />
                                                                                    {facility?.number && <span>{" - " + facility?.number}</span>}
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                        <div className="col-md-6 col-sm-12">
                                                                            {eachRoom?.roomFacilities?.slice(Math.abs(eachRoom?.roomFacilities?.length / 2))?.map((facility, index) => (
                                                                                <span className="d-flex align-items-center" key={index}>
                                                                                    <ListCheck title={facility?.facilityName} />
                                                                                    {facility?.number && <span>{" - " + facility?.number}</span>}
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {eachRoom?.roomFacilities?.map((facility, index) => (
                                                                            <span className="d-flex align-items-center" key={index}>
                                                                                <ListCheck title={facility?.facilityName} />
                                                                                {facility?.number && <span>{" - " + facility?.number}</span>}
                                                                            </span>
                                                                        ))}
                                                                    </>
                                                            }
                                                            {(eachRoom && !eachRoom.roomFacilities?.length)
                                                                ?
                                                                <div className="h-25 d-flex justify-content-center align-items-center">
                                                                    <span className="text-muted">Amenities is not available to show for this room</span>
                                                                </div>
                                                                :
                                                                <></>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* CANCELATION POLICY */}
                                            <div className="spacer spacer--sm"></div>
                                            <div className="row">
                                                <div className="col-md-8 col-sm-12">
                                                    <div>
                                                        <h5>Cancellation Policy:</h5>
                                                        <ul className="list-group list-group-flush">
                                                            {/*  list-group-numbered */}
                                                            {
                                                                rate.combination[index]?.cancellationPolicies?.length > 0
                                                                    ?
                                                                    rate.combination[index]?.cancellationPolicies?.map((policy, i) => (
                                                                        <li className="list-group-item justify-content-center px-0" key={i}>
                                                                            {/* <div className="bg-info d-flex gap-5 text-success small"> */}
                                                                            {
                                                                                isDateAfter(
                                                                                    moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                                                                                    calculateDateForFreeCancellation(policy?.from)
                                                                                )
                                                                                    ?
                                                                                    <div className="badge rounded-pill bg-danger">Refund is Not Available</div>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                            {i === 0
                                                                                ?
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className="text-success">
                                                                                        <span>Free until <span className="fw-bold small">
                                                                                            {calculateDateForFreeCancellation(policy?.from)}
                                                                                        </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <></>}
                                                                            {/* </div> */}
                                                                            <div className="d-flex align-items-center justify-content-between text-danger small">
                                                                                <span>After <span className="fw-bold">
                                                                                    {calculateDateForFreeCancellation(policy?.from)}
                                                                                </span>
                                                                                </span>
                                                                                <span className="small">
                                                                                    <label className="fw-bold small">Charges:</label>
                                                                                    {policy?.amount && <span>{" "}{priceFormatter(+policy?.amount, HotelBeds.searchedHotel.hotel_dynamic_data.currency)}</span>}
                                                                                    {policy?.percent && <span>{policy?.percent}%</span>}
                                                                                    {policy?.numberOfNights && <span>{policy?.numberOfNights} Night</span>}
                                                                                </span>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                    :
                                                                    <div className="h-25 d-flec justify-content-center align-items-center">
                                                                        <span className="text-muted">Cancellation policy is not available to show for this room</span>
                                                                    </div>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                        </React.Fragment>
                    }
                    footer={
                        <div className="w-100">
                            {/* PROMOTIONS */}
                            <div>
                                <h5>Promotions:</h5>
                                {
                                    rate.combination?.map((eachCombination, index) => (
                                        <>
                                            <ul key={index + "_promotion"}>
                                                {
                                                    eachCombination?.promotions?.length
                                                        ?
                                                        eachCombination?.promotions?.map((eachPromotion, i) => (
                                                            <li>{eachPromotion?.name} ({eachPromotion?.remark})</li>
                                                        ))
                                                        :
                                                        <li className="text-muted">Promotion not available!</li>
                                                }
                                            </ul>
                                        </>
                                    ))
                                }
                            </div>

                            {/* PRICING & ACTION BUTTONS */}
                            <div className="spacer spacer--sm"></div>
                            <div className="w-100 d-flex justify-content-between">
                                <div>
                                    <h3 className="mb-0">
                                        {calculateMarkedupBaseCharge(
                                            metaData?.markUps,
                                            metaData?.affiliateMarkup,
                                            rate.combination,
                                            // metaData.BookingSupplier[0].SupplierName,
                                            "Hotelbeds",
                                            currency,
                                            ClientData.GSTRates[0]?.Value,
                                            searchHotel.RoomGuest
                                        )}
                                    </h3>
                                    <div className="small text-muted">Excluding tax</div>
                                    {/* <div className="small">+ {0} taxes & fees</div>
                        <div className="small">+ {0} GST</div> */}
                                </div>

                                <div>
                                    {
                                        (rate.id === HotelBeds.selectedRoomCombinations.id && rate.price === HotelBeds.selectedRoomCombinations.price)
                                            ?
                                            <Button
                                                type={"button"}
                                                // color={"success"}
                                                // outline={true}
                                                className={"btn-success"}
                                                size={"sm"}
                                                label={"Selected"}
                                                onClick={() => {
                                                    onRemove();
                                                    // setViewDetails(false);
                                                }}
                                            />
                                            :
                                            <Button
                                                type={"button"}
                                                color={"primary"}
                                                outline={true}
                                                size={"sm"}
                                                label={"Select"}
                                                onClick={() => {
                                                    onSelect && onSelect();
                                                }}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                />
                :
                <></>}
        </React.Fragment>
    )
}
