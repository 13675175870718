import { useCallback } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios, { AxiosError, AxiosResponse } from "axios";

import { IBookingInfo, State } from "../../../redux/types";
import { SERVER_API_URL } from "../../constants";

export default function useBookingDetails() {
  const customerId = useSelector((state: State) => state.user.userId);
  const clientId = useSelector((state: State) => state.commonState.Client?.id);
  const currentDate = moment().format("YYYY-MM-DD");

  const [allAvailableBookings, setAllAvailableBookings] = useState<
    IBookingInfo[]
  >([]);
  const [allUpcomingBookings, setAllUpcomingBookings] = useState<
    IBookingInfo[]
  >([]);

  const [flightBookings, setFlightBookings] = useState<{
    upcoming: IBookingInfo[];
    all: IBookingInfo[];
  }>({
    upcoming: [],
    all: [],
  });
  const [hotelBookings, setHotelBookings] = useState<{
    upcoming: IBookingInfo[];
    all: IBookingInfo[];
  }>({
    upcoming: [],
    all: [],
  });

  const getAllCustomerBookingDetails = useCallback(() => {
    axios
      .get(
        `${SERVER_API_URL}Customer/GetCustomerBooking/${clientId}/${customerId}/${currentDate}`
      )
      .then(
        (response: AxiosResponse) => {
          handleAllBookings(response.data?.ResponseData);
        },
        (error: AxiosError) => {
          console.log(error);
        }
      );
  }, [clientId, customerId, currentDate]);
  const getCustomerUpcomingBookingDetails = useCallback(() => {
    axios
      .get(
        `${SERVER_API_URL}Customer/GetCustomerupcomingBooking/${clientId}/${customerId}/${currentDate}`
      )
      .then(
        (response: AxiosResponse) => {
          handleUpcomingBookings(response.data?.ResponseData);
        },
        (error: AxiosError) => {
          console.log(error);
        }
      );
  }, [clientId, customerId, currentDate]);

  const getTicketDetails = (TicketId: number) => {
    axios
      .get(`${SERVER_API_URL}Customer/GetTicketDetails/${clientId}/${TicketId}`)
      .then(
        (response: AxiosResponse) => {
          console.log(response.data?.ResponseData);
        },
        (error: AxiosError) => {
          console.log(error);
        }
      );
  };

  const handleAllBookings = (bookings: IBookingInfo[]) => {
    setAllAvailableBookings(bookings);
    if (bookings.length > 0) {
      setFlightBookings((prev) => ({
        ...prev,
        all: bookings?.filter(
          (booking: any) => booking.ServiceName.toLowerCase() === "flight"
        ),
      }));
      setHotelBookings((prev) => ({
        ...prev,
        all: bookings?.filter(
          (booking: any) => booking.ServiceName.toLowerCase() === "hotel"
        ),
      }));
    }
  };

  const handleUpcomingBookings = (bookings: IBookingInfo[]) => {
    setAllUpcomingBookings(bookings);
    if (bookings.length > 0) {
      setFlightBookings((prev) => ({
        ...prev,
        upcoming: bookings?.filter(
          (booking: any) => booking.ServiceName.toLowerCase() === "flight"
        ),
      }));
      setHotelBookings((prev) => ({
        ...prev,
        upcoming: bookings?.filter(
          (booking: any) => booking.ServiceName.toLowerCase() === "hotel"
        ),
      }));
    }
  };

  return {
    getAllCustomerBookingDetails,
    getCustomerUpcomingBookingDetails,
    getTicketDetails,
    flightBookings,
    hotelBookings,
  };
}
