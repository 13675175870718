import { useEffect, useState } from "react";

import SearchFlightFilters from "./Components/SearchFlightFilters";
import SearchFlightForm from "./FlightSearch/SearchFlightForm";

import "./flight.scss";
import { AnimatePresence, motion } from "framer-motion";
import useFlightSearch from "../../utils/hooks/Flight/useFlightSearch";
import { State } from "../../redux/types";
import { useSelector } from "react-redux";
import useFlight from "../../utils/hooks/Flight/useFlight";
import FlightResultSection from "./Components/FlightResultSection";
import MultiCityTab from "./Components/MultiCityTab";
import Coupon from "@kiwicom/orbit-components/lib/Coupon";
import SelectedFlightCard2 from "./Components/SelectedFlightCard2";
import LoadingSearchFlight from "../../components/Common/LoadingSkeleton/LoadingSearchFlight";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
// import LottieFlight from "../../assets/LottieFiles/FlightLoading.json";
import LottieFlight from "../../assets/LottieFiles/FlightLoadingNew.json";
import FiltersOnModal from "./Components/FiltersOnModal";
import { FaFilter } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import useClientData from "../../utils/hooks/useClientData";
import useFlightMetaData from "../../utils/hooks/Flight/useFlightMetaData";
interface Props { }

const Flight = (props: Props) => {

  const history = useHistory();

  const { isFlightDataFetched } = useSelector((state: State) => state.commonState);
  const { searchInfo, selectedFlights } = useSelector((state: State) => state.flight);

  const {
    getCharges,
    searchFlight,
    getFlights,
    isLoading,
    flightSearchData
  } = useFlightSearch();
  const { filterFlightsCardData } = useFlight();

  // used for multicity tab to switch between the tabs of flight sections
  const [selectedCityIndex, setSelectedCityIndex] = useState<number>(0);

  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
  //auto search on landing this component if all the required inputs are filled

  useEffect(() => {
    if (
      searchInfo?.fromCity[0]?.RouteDestinationCode?.length > 2 &&
      searchInfo?.toCity[0]?.RouteDestinationCode?.length > 2 &&
      searchInfo?.departureDate[0]?.length > 0 &&
      Number(searchInfo?.adult) > 0 &&
      (searchInfo?.travelType?.SearchTypeId === 2
        ? searchInfo?.departureDate[1]?.length > 0
        : true)
    ) {
      getCharges().then(
        res => getFlights()
      );
    } else {
      history.push("/");
    };
  }, [searchInfo, history]);

  return (
    <>
      {/* SEARCH FLIGHT SECTION */}
      <div className="container">
        <div className="spacer--md"></div>

        <SearchFlightForm
          onSearch={() => getFlights()}
          isPreSearch={false}
          isSearching={isLoading}
        />
      </div>

      {/* SEARCH FLIGHT RESULT */}

      <div className="spacer--md"></div>
      {isLoading ? (
        <div className="d-flex justify-content-center flex-column">
          <div className="d-flex w-100 flex-column align-items-center py-0">
            <Lottie
              loop
              animationData={LottieFlight}
              play
              style={{ width: 350, height: 250 }}
            />
            <span>Please wait, while we get you the best flights...</span>
          </div>
          <LoadingSearchFlight />
        </div>
      ) : isFlightDataFetched ? (
        <div className="searched-flight-container">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3">
                <h6 className="fw-bold p-2">
                  Found {flightSearchData?.flat().length} flights
                </h6>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-9">
                <div className="alert p-2 bg-warning bg-opacity-25 sticky-container">
                  <div className="bg-warning d-inline-flex rounded-pill p-1 px-2 fw-bold text-white small">
                    OFFER
                  </div>
                  <span className="ms-3">
                    Get flat 10% off using <Coupon>SUMMER10</Coupon> on all
                    domestic flight bookings.
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              {/* FLIGHT FILTERS */}
              <div className="col-sm-12 col-md-4 col-lg-3 hide-filter-on-desktop">
                {flightSearchData[0].length > 0
                  ?
                  (
                    <SearchFlightFilters
                      price={[2000, 7000]}
                      maxStops={Math.max(
                        ...(flightSearchData
                          ?.flat()
                          ?.map((e) => e?.SegmentData?.length) || [1])
                      )}
                    />
                  )
                  :
                  <></>}
              </div>

              {/* SEARCHED FLIGHT RESULT */}
              <div className="col-sm-12 col-md-8 col-lg-9">
                {/* Flight selection toggle for multi_city search more than 2 cities */}
                {searchInfo?.toCity?.filter(
                  (to) =>
                    to && to.RouteDestinationCode.length >= 3 && to !== null
                )?.length > 2 ? (
                  <MultiCityTab
                    cities={searchInfo?.toCity?.map((e, i) => ({
                      to: e?.City,
                      from: searchInfo.fromCity[i]?.City,
                      date: searchInfo.departureDate[i],
                    }))}
                    onChange={(index) => setSelectedCityIndex(index)}
                    selectedCityIndex={selectedCityIndex}
                  />
                ) : (
                  <></>
                )}
                {/* Selected flight card section */}
                {/* <AnimatePresence>
                    <motion.div
                      className="sticky-container"
                      initial={{ height: 0 }}
                      animate={{
                        height: "auto",
                      }}
                    >
                      {selectedFlights.length>0? (
                        <SelectedFlightCard
                          selectedFlights={selectedFlights}
                        />
                      ) : (
                        <></>
                      )}
                    </motion.div>
                  </AnimatePresence>  */}

                {/* <div className="spacer--sm"></div> */}

                {/* AVAILABLE FLIGHTS */}
                <div className="row g-3">
                  {/* multi city flights more than 2 trips */}
                  {searchInfo?.toCity?.filter(
                    (to) =>
                      to && to.RouteDestinationCode.length >= 3 && to !== null
                  )?.length > 2 ? (
                    <FlightResultSection
                      flightIndex={selectedCityIndex}
                      selectedTraveType="multi_city"
                      flightData={filterFlightsCardData(
                        flightSearchData[selectedCityIndex] || []
                      )?.sort(
                        (a, b) =>
                          a.supplier === "navitaire"
                            ?
                            a!.FareToShow!.markedUpPrice!.markedupTotalPrice - b!.FareToShow!.markedUpPrice!.markedupTotalPrice
                            :
                            a.supplier === "airiq"
                              ?
                              a!.fare_data!.markedUpPrice.markedupTotalPrice - b!.fare_data!.markedUpPrice.markedupTotalPrice
                              :
                              0 - 0
                      )}
                    />
                  ) : (
                    flightSearchData
                      ?.filter((j) => j.length > 0)
                      ?.map((journey, journeyIndex) => {
                        const flightData = filterFlightsCardData(journey)?.sort(
                          (a, b) =>
                            a.supplier === "navitaire"
                              ?
                              a?.FareToShow?.markedUpPrice?.markedupTotalPrice - b?.FareToShow?.markedUpPrice?.markedupTotalPrice
                              :
                              a.supplier === "airiq"
                                ?
                                a?.fare_data?.markedUpPrice?.markedupTotalPrice - b?.fare_data?.markedUpPrice?.markedupTotalPrice
                                :
                                0 - 0
                        );
                        return (
                          <FlightResultSection
                            key={journeyIndex}
                            flightIndex={journeyIndex}
                            flightData={flightData}
                          />
                        );
                      })
                  )}
                </div>
              </div>
            </div>

            {flightSearchData[0]?.length > 0 && (
              <button
                className="btn btn-primary show-filters-on-mobile"
                onClick={() => setShowFiltersModal(true)}
                style={{ zIndex: 2 }}
              >
                Filters <FaFilter />
              </button>
            )}

            {/* Selected FLight Card */}
            <AnimatePresence>
              <motion.div
                className={`sticky-container_bottom ${selectedFlights.length > 2
                  ? "w-50 ms-auto"
                  : selectedFlights.length === 1
                    ? "w-75 ms-auto"
                    : ""
                  }`}
                initial={{ height: 0 }}
                animate={{
                  height: "auto",
                }}
              >
                {selectedFlights?.filter((a) => a !== null).length > 0 ? (
                  <SelectedFlightCard2 selectedFlights={selectedFlights} />
                ) : (
                  <></>
                )}
              </motion.div>
            </AnimatePresence>
          </div>

          {showFiltersModal
            ?
            <FiltersOnModal
              onHideModal={() => setShowFiltersModal(false)}
              price={[2000, 7000]}
            />
            :
            <></>}

        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Flight;
