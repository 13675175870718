import axios from "axios";

export const getFlightItinerary = (
  keys: {
    JourneySellKey: string;
    FareSellKey: string;
  }[],
  passenger: {
    child: number;
    adult: number;
  },
  endPoint: string
) => {
  let PaxPriceType: { PaxType: "ADT" | "CHD"; PaxCount: number }[] = [
    {
      PaxType: "ADT",
      PaxCount: passenger.adult,
    },
  ];
  passenger.child > 0 &&
    PaxPriceType.push({
      PaxType: "CHD",
      PaxCount: passenger.child,
    });
  return axios.post(endPoint, {
    ActionStatusCode: "NN",
    SellKeyList: keys,
    PaxPriceType,
    CurrencyCode: "INR",
  });
};
export const sellFlightTicket = (
  keys: {
    JourneySellKey: string;
    FareSellKey: string;
  }[],
  adultCount: number,
  childCount: number,
  endPoint: string
) => {
  let PaxPriceType: { PaxType: "ADT" | "CHD"; PaxCount: number }[] = [
    {
      PaxType: "ADT",
      PaxCount: adultCount,
    },
  ];
  childCount > 0 &&
    PaxPriceType.push({
      PaxType: "CHD",
      PaxCount: childCount,
    });
  return axios.post(endPoint, {
    userId: localStorage.getItem("sessionId"),
    SellBy: "JourneyBySellKey",
    ActionStatusCode: "NN",
    SegmentSellKey: keys,
    PaxPriceType,
    CurrencyCode: "INR",
  });
};
