import React, { useEffect, useMemo, useState } from "react";
import {
  ISeat,
  ISeatAvailability,
  ISelectedSeat,
} from "../../../../../utils/types/flightTypes";
import "./seatSelection.scss";

import moment from "moment";
import { BsArrowRight } from "react-icons/bs";
import { FaAngleRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import Button from "../../../../../components/Common/Button/Button";
import BsModal from "../../../../../components/Modal/BSModal";
import { State } from "../../../../../redux/types";
import "../../../flight.scss";
import SeatLegends from "./Components/SeatLegends";
import Aircraft from "./AirCrafts/Aircraft";
import airCraftData from "../../../../../Data/AircraftConfigs.json";
type Props = {
  seatList: ISeatAvailability[];
  onClose: () => void;
  onSubmit: (selectedSeats: ISelectedSeat[]) => void;
};

type airCraftNameList =
  | "Q-400 (78) (SUQ)"
  | "Q-400 (78)"
  | "Q-400 (90) (SQD-J)"
  | "Q-400 (90) (SQA B C)"
  | "B-737-700 (144) (SLP)"
  | "B-737-700 (Y144) (4MAX)"
  | "B-737-700 (149) (SLA OR SLB)"
  | "B-737-800 (AE) (Y168)"
  | "B-737-800 (Y189)"
  | "B-737-800 (Y186) (4MAX)"
  | "B-737-800 (Y186) (1MAX)"
  | "B-737-900 (Y189)"
  | "B-737-900 (Y212)"
  | "TC-737-800 (Y189) (TJO)"
  | "Airbus A320"
  | "A320 NEO"
  | "A320 CEO"
  | "A320-186"
  | "A321-232"
  | "A320-180"
  | "ATR 78";
  
const SeatSelection = ({ seatList, onClose, onSubmit }: Props) => {
  const { metaData, searchInfo, SelectedAddOns } = useSelector(
    (state: State) => state.flight
  );
  const [activeSegment, setActiveSegment] = useState<number>(0);
  const [selectedPax, setSelectedPax] = useState<number>(0);

  const [tempSelectedSeat, setTempSelectedSeat] = useState<ISelectedSeat[]>([]);



  useEffect(() => {
    let allSelected = SelectedAddOns?.map((seg, index) =>
      seg.Seats.map((pax, idx) =>
        pax.map((seat) => ({
          segIndex: index,
          passengerIndex: idx,
          selectedSeat: seat,
        }))
      )
    ).flat(2);
    setTempSelectedSeat(allSelected || []);
  }, []);



  const handleSeatSelect = (selectedSeat: ISeat) => {
    let selectedIndex = tempSelectedSeat.findIndex(
      (t) => t.passengerIndex === selectedPax && t.segIndex === activeSegment
    );

    let newSelectionData = {
      passengerIndex: selectedPax,
      segIndex: activeSegment,
      selectedSeat,
    };
    setTempSelectedSeat((prev) =>
      selectedIndex === -1
        ? [...prev, newSelectionData]
        : prev.map((p, i) => (i === selectedIndex ? { ...p, selectedSeat } : p))
    );
  };

  const airCraftName = useMemo(() => {
    return seatList[activeSegment].EquipmentInfo.Name as airCraftNameList;
  }, [activeSegment, seatList]);



  return (
    <div>
      <BsModal
        title={`Select Seats`}
        subTitle={"Choose your preferred seat."}
        size={"xl"}
        // scrollable
        body={
          <>
            {/* <p>{seatList[activeSegment].EquipmentInfo?.Name}</p> */}
            <div className="ssr-segment">
              {seatList.map((seg, index) => {
                let departureCityName =
                  metaData?.FlightOrigins.find(
                    (airport) =>
                      airport?.RouteDestinationCode ===
                      seg?.LegInfo?.DepartureStation
                  )?.City || seg?.LegInfo?.DepartureStation;
                let arrivalCityName =
                  metaData?.FlightDestinations.find(
                    (airport) =>
                      airport.RouteDestinationCode ===
                      seg?.LegInfo?.ArrivalStation
                  )?.City || seg?.LegInfo?.ArrivalStation;

                return (
                  <div
                    className={`ssr-segment-item ${activeSegment === index && "active"
                      }`}
                    onClick={() => setActiveSegment(index)}
                  >
                    <span className="city">
                      <span>{departureCityName}</span>
                      <BsArrowRight />
                      <span>{arrivalCityName}</span>
                    </span>
                    <p className="date">
                      {moment(seg?.LegInfo?.STD).format("ddd DD MMM, YYYY")}
                    </p>
                  </div>
                );
              })}
              <SeatLegends />
            </div>
            <div className="ssr-body">
              {searchInfo?.adult + searchInfo?.child >= 2 && (
                <div className="passengers">
                  {[...Array(searchInfo?.adult)].fill("").map((e, i) => {
                    const selectedSeat = tempSelectedSeat.find(
                      (s) =>
                        s.passengerIndex === i && s.segIndex === activeSegment
                    )?.selectedSeat?.SeatDesignator;
                    return (
                      <div
                        className={`passenger ${selectedPax === i && "selectedPax"
                          }`}
                        onClick={() => setSelectedPax(i)}
                      >
                        <span className="fw-semibold">
                          Adult Passenger {i + 1}
                        </span>
                        {selectedSeat && (
                          <span className="fs-8">
                            <span className="text-muted">Selected Seat:</span>{" "}
                            {selectedSeat}
                          </span>
                        )}
                      </div>
                    );
                  })}
                  {[...Array(searchInfo?.child)].fill("").map((e, i) => {
                    const selectedSeat = tempSelectedSeat.find(
                      (s) =>
                        s.passengerIndex === i + searchInfo?.adult &&
                        s.segIndex === activeSegment
                    )?.selectedSeat?.SeatDesignator;

                    return (
                      <div
                        className={`passenger ${selectedPax === searchInfo?.adult + i && "selectedPax"
                          }`}
                        onClick={() => setSelectedPax(searchInfo?.adult + i)}
                      >
                        <span className="fw-semibold">
                          Child Passenger {i + 1}
                        </span>
                        {selectedSeat && (
                          <span className="fs-8">
                            <span className="text-muted">Selected Seat:</span>{" "}
                            {selectedSeat}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              <div
                className="ssr-sections"
                style={{
                  padding: "30px 0",
                }}
              >
                <Aircraft
                  seats={seatList[activeSegment].Seats.filter(
                    (s) => s.Assignable
                  )}
                  onSeatSelect={(seat: ISeat) => {
                    handleSeatSelect(seat);
                  }}
                  selectedPax={selectedPax}
                  selectedSeats={tempSelectedSeat.filter(
                    (temp) => temp.segIndex === activeSegment
                  )}
                  totalRows={airCraftData[airCraftName]?.totalRows}
                  maxSeatRows={airCraftData[airCraftName]?.maxSeatRows}
                  rowsNotAllowed={airCraftData[airCraftName]?.rowsNotAllowed}
                  seatsNotAllowed={airCraftData[airCraftName]?.seatsNotAllowed}
                  airCraftName={airCraftName}
                />
              </div>
            </div>
          </>
        }
        footer={
          <div className="d-flex gap-3 align-items-center fw-bold">
            {/* {totalAddedPrice > 0 && (
              <span>+ {priceFormatter(totalAddedPrice)}</span>
            )} */}
            <Button
              label={`Cancel`}
              onClick={() => {
                onClose();
              }}
              outline
            />
            <Button
              label={
                <span>
                  {`Save & Continue`}
                  <FaAngleRight className="ps-1" />
                </span>
              }
              onClick={() => {
                console.log("tempSelectedSeat: ", tempSelectedSeat);
                onSubmit(tempSelectedSeat);
              }}
            />
          </div>
        }
        onClose={() => {
          onClose();
        }}
      />
    </div>
  );
};

export default SeatSelection;
