import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

import useClientData from "../useClientData";
import { State } from "./../../../redux/types";
import useFlightMetaData from "./useFlightMetaData";
import { flightSlice } from "../../../redux/reducers/flight";
import { airIQFlight, searchFlights, uAPIFlight } from "../../../Interfaces/SearchFlights/SearchFlights";
import { IAirIQSearchAvailabilityResponse, ISelectedFaresForItinerary, IuAPIFlightSearchPayload, TFlightResultList, TuAPIPax, TypeCarrierCode } from "./../../types/flightTypes";
import {
  formatToArr,
  getSellAndItineraryPayload,
} from "../../utilityFunctions/flight/flightUtilityFunctions";
import {
  formatSJToFlightCardData,
  formatItineraryData,
  formatAirIQFlightCardData,
  formatUAPIJourney,
} from "../../utilityFunctions/flight/SGUtilityFunctions";
import {
  commonStateSlice,
  showToast,
  updateState,
} from "../../../redux/reducers/commonState";
import {
  AirLineServices,
  IFare
} from "../../types/flightTypes";


const useFlightSearch = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { isAgent } = useSelector((state: State) => state.user);
  const { Client } = useSelector((state: State) => state.commonState);
  const { searchInfo, metaData } = useSelector((state: State) => state.flight);
  const { MyCharges, GSTRates } = useSelector((state: State) => state.commonState.ClientData);

  const { getChargesByService } = useClientData();
  const { getMarkUp, getAffiliateMarkUp } = useFlightMetaData();
  const { getChargesForAffiliate, getChargesForCustomer } = useFlightMetaData();

  // loading for search flight
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startSearching, setStartSearching] = useState<boolean>(false);

  const [flightSearchData, setFlightSearchData] = useState<TFlightResultList[][]>([[], [], []]); // IFlightSearchData
  const [itineraryLoading, setItineraryLoading] = useState<boolean>(false);
  // console.log("flightSearchData: ", flightSearchData);


  const resetSearchDataBeforeSearch = () => {
    setFlightSearchData([[], [], []]);
    searchInfo?.toCity.forEach((_, index) => {
      dispatch(flightSlice.actions.setSelectedFlights({ index, value: null }));
    });
    dispatch(flightSlice.actions.resetItinerary());
    dispatch(flightSlice.actions.resetMarkup());
    dispatch(
      commonStateSlice.actions.updateState({
        key: "isFlightDataFetched",
        value: false,
      })
    );
  };

  const getCharges = async () => {

    setIsLoading(true);
    //get data from mycharges API on  reaching the page.
    // if MyCharges Arr doesn't have any obj with service id 1 i.e flight
    if (
      Client !== null
      // &&
      // ClientData?.MyCharges?.findIndex(
      //   (charges) => charges?.ServiceId === 1
      // ) === -1
    ) {
      await getChargesByService(1); //1 is serviceId for flight
      if (isAgent) {
        await getChargesForAffiliate();
      } else {
        await getChargesForCustomer();
      };
    };
    setIsLoading(false);

  };

  // search Flights
  const searchFlight = useCallback(async () => {

    setIsLoading(true);

    const searchData = searchInfo?.toCity
      ?.filter((to) => to !== null)
      ?.map((trip, index) => ({
        from: searchInfo.fromCity[index]?.RouteDestinationCode,
        to: trip?.RouteDestinationCode,
        depDate: searchInfo.departureDate[index],
      }));

    let markupPromise = searchData?.map(async (_, index) => {
      const res = await getMarkUp(
        searchInfo?.fromCity[index]?.RouteDestinationId,
        searchInfo?.toCity[index]?.RouteDestinationId,
        searchInfo?.departureDate[index],
        isAgent //to call B2B getMarkup if agent is logged in
      );
      return res?.ResponseData;
    });
    let affiliateMarkupPromise: Promise<any>[] = [];
    if (isAgent) {
      affiliateMarkupPromise = searchData?.map(async (_, index) => {
        const res: any = await getAffiliateMarkUp(
          searchInfo?.fromCity[index]?.RouteDestinationId,
          searchInfo?.toCity[index]?.RouteDestinationId,
          searchInfo?.departureDate[index]
        );
        return res?.ResponseData;
      });
    };

    const markupResponseArray = await Promise.allSettled(markupPromise);
    let affiliateMarkupResponseArray = await Promise.allSettled(affiliateMarkupPromise);

    /*
    * Navitaire flight search with selected airlines
    */
    const flightServicesToSearchWith = searchFlights?.filter((_, serviceIndex) =>
      searchInfo.airlines.length > 0
        ? searchInfo.airlines.includes(serviceIndex)
        : true
    );
    flightServicesToSearchWith.forEach((service, serviceIdx) => {

      service.getAvailability(searchData, {
        child: Number(searchInfo?.child),
        adult: Number(searchInfo?.adult),
      }).then(async (response) => {

        const { data } = response?.data;
        const journeys = formatToArr(
          data?.Schedules?.ArrayOfJourneyDateMarketVer2
        );
        // console.log("------------------------", journeys);
        if (data?.Fares === null && journeys.length === 0) {
          // dispatch(
          //   showToast({
          //     subTitle: "No Flights Found",
          //     type: "error",
          //     title: "",
          //   })
          // );
          return;
        };

        const fare = formatToArr(data?.Fares?.Fare);
        // const markupResponseArray = await Promise.allSettled(markupPromise);
        // let affiliateMarkupResponseArray = await Promise.allSettled(affiliateMarkupPromise);

        journeys.forEach((journey, journeyIndex) => {

          let responseAtIndex = markupResponseArray[journeyIndex];
          let affiliateMarkupResponseAtIndex = affiliateMarkupResponseArray[journeyIndex];
          let markupResponse = responseAtIndex?.status === "fulfilled"
            ? responseAtIndex?.value
            : [];
          let affiliateMarkupResponse = affiliateMarkupResponseAtIndex?.status === "fulfilled"
            ? affiliateMarkupResponseAtIndex?.value
            : [];
          const formattedFlightData = formatSJToFlightCardData(
            fare,
            formatToArr(journey?.JourneyDateMarketVer2?.AvailableJourneys?.AvailableJourney),
            markupResponse,
            searchInfo,
            metaData?.FlightOrigins,
            isAgent,
            GSTRates,
            affiliateMarkupResponse
          );

          // FIXED: This is a hack to fix the issue by calling the main function on dependency of a boolean state
          // that is reset after the state of search result are reset and before the search is called
          // BUG:Searching again appends the result to prev result instead of replacing it
          // TODO: append search result from various carrier without duplication/append of prev results
          // setFlightSearchData((prev) => [
          //   ...prev,
          //   prev[journeyIndex].concat(formattedFlightData),
          // ]);

          let temp = flightSearchData[journeyIndex];
          temp.push(...formattedFlightData);
          setFlightSearchData(
            flightSearchData.map((e, i) => (i === journeyIndex ? temp : e))
          );

        });
        dispatch(updateState({ key: "isFlightDataFetched", value: true }));

      }).catch((err) => {
        console.log("Error while fetching flights: ", err);
        // dispatch(
        //   showToast({
        //     subTitle: "No Flights Found",
        //     type: "error",
        //     title: "",
        //   })
        // );
      }).finally(() => {
        setIsLoading(false);
      });

    });


    /*
    * uAPI availability search request
    */
    const legsInfo = searchInfo?.toCity
      ?.filter((to) => to !== null)
      ?.map((trip, index) => ({
        Origin: {
          Airport: searchInfo.fromCity[index]?.RouteDestinationCode,
        },
        Destination: {
          Airport: trip?.RouteDestinationCode,
        },
        DepartureTime: {
          PreferredTime: searchInfo.departureDate[index]
        }
      }));

    const adultPax: Array<{ Code: TuAPIPax }> = Array(searchInfo.adult).fill(1).map(_ => ({ Code: "ADT" }));
    const childPax: Array<{ Code: TuAPIPax }> = Array(searchInfo.child).fill(1).map(_ => ({ Code: "CHD" }));
    const infantPax: Array<{ Code: TuAPIPax }> = Array(searchInfo.infant).fill(1).map(_ => ({ Code: "INF" }));
    const passengers = [...adultPax, ...childPax, ...infantPax];

    const uAPIPayload: IuAPIFlightSearchPayload = {
      LegsInfo: legsInfo,
      Passengers: passengers,
      AirPricingModifiers: [{
        CurrencyType: "INR"
      }]
    };

    uAPIFlight.searchTickets(uAPIPayload).then(
      (response) => {

        const data = response.data?.data;
        console.log("----------------------------------------------------------------");
        console.log("Response for uAPI availability: ", data);

        let responseAtIndex = markupResponseArray[0];
        let affiliateMarkupResponseAtIndex = affiliateMarkupResponseArray[0];
        let markupResponse = responseAtIndex?.status === "fulfilled"
          ? responseAtIndex?.value
          : [];
        let affiliateMarkupResponse = affiliateMarkupResponseAtIndex?.status === "fulfilled"
          ? affiliateMarkupResponseAtIndex?.value
          : [];

        const formattedAirIQFlightData = formatUAPIJourney(
          data,
          searchInfo,
          metaData?.FlightOrigins,
          isAgent,
          GSTRates,
          markupResponse,
          affiliateMarkupResponse
        );

        console.log("formatteduAPIFlightData: ", formattedAirIQFlightData);
        console.log("----------------------------------------------------------------");

        formattedAirIQFlightData.forEach((journey, journeyIndex) => {
          let temp = flightSearchData[journeyIndex];
          temp.push(...journey);
          setFlightSearchData(
            flightSearchData.map((e, i) => (i === journeyIndex ? temp : e))
          );
        });
        dispatch(updateState({ key: "isFlightDataFetched", value: true }));

      }, (error) => {

        console.log("Error while fetching uAPI availability: ", error);
        dispatch(
          showToast({
            subTitle: "No Flights Found",
            type: "error",
            title: "",
          })
        );

      }
    );


    /*
    * AirIQ availability search request
    */
    // const payload = searchInfo?.toCity
    //   ?.filter((to) => to !== null)
    //   ?.map((trip, index) => ({
    //     origin: searchInfo.fromCity[index]?.RouteDestinationCode,
    //     destination: trip.RouteDestinationCode,
    //     departure_date: moment(searchInfo.departureDate[index]).format("YYYY/MM/DD"),
    //     adult: String(searchInfo.adult),
    //     child: searchInfo.child ? String(searchInfo.child) : "0",
    //     infant: searchInfo.infant ? String(searchInfo.infant) : "0",
    //     // airline_code?: "",
    //   }));

    // airIQFlight.searchTickets(payload).then(
    //   (response) => {

    //     const data: Array<{
    //       departure_date: string;
    //       destination: string;
    //       origin: string;
    //       data: IAirIQSearchAvailabilityResponse[];
    //     }> = response?.data?.data || [];

    //     if (data && data.length) {

    //       data.forEach((journey, journeyIndex) => {

    //         if (journey.data.length !== 0) {

    //           let responseAtIndex = markupResponseArray[journeyIndex];
    //           let affiliateMarkupResponseAtIndex = affiliateMarkupResponseArray[journeyIndex];
    //           let markupResponse = responseAtIndex?.status === "fulfilled"
    //             ? responseAtIndex?.value
    //             : [];
    //           let affiliateMarkupResponse = affiliateMarkupResponseAtIndex?.status === "fulfilled"
    //             ? affiliateMarkupResponseAtIndex?.value
    //             : [];

    //           const formattedAirIQFlightData = formatAirIQFlightCardData(
    //             formatToArr(journey.data),
    //             searchInfo,
    //             metaData?.FlightOrigins,
    //             isAgent,
    //             GSTRates,
    //             markupResponse,
    //             affiliateMarkupResponse
    //           );

    //           let temp = flightSearchData[journeyIndex];
    //           temp.push(...formattedAirIQFlightData);
    //           setFlightSearchData(
    //             flightSearchData.map((e, i) => (i === journeyIndex ? temp : e))
    //           );

    //           // console.log("----------------------------------------------------");
    //           // console.log("journey.data: ", journey.data);
    //           // console.log("formattedAirIQFlightData: ", formattedAirIQFlightData);
    //           // console.log("----------------------------------------------------");

    //         };

    //       });

    //     };

    //   }, (error) => {
    //     console.log("Error while fetching flights: ", error?.response?.data);
    //     const errorData = error?.response?.data;
    //     dispatch(
    //       showToast({
    //         type: "error",
    //         title: "",
    //         subTitle: errorData?.message ? errorData?.message?.join(", ") : "No Flights Found from AirIQ",
    //       })
    //     );
    //   }
    // );


  }, [searchInfo, dispatch, getMarkUp, getAffiliateMarkUp, flightSearchData]);

  // usage: to separately search for each trip for each carrier
  const searchFlight2 = useCallback(() => {
    setIsLoading(true);

    const searchData = searchInfo?.toCity
      ?.filter((to) => to !== null)
      ?.map((trip, index) => ({
        from: searchInfo.fromCity[index]?.RouteDestinationCode,
        to: trip?.RouteDestinationCode,
        depDate: searchInfo.departureDate[index],
      }));
    searchData?.forEach(async (trip, tripIndex) => {
      const res = await getMarkUp(
        searchInfo?.fromCity[tripIndex]?.RouteDestinationId,
        searchInfo?.toCity[tripIndex]?.RouteDestinationId,
        searchInfo?.departureDate[tripIndex],
        isAgent //to call B2B getMarkup if agent is logged in
      );
      let markup = res?.ResponseData;
      let affiliateMarkup: any = [];
      if (isAgent) {
        const res: any = await getAffiliateMarkUp(
          searchInfo?.fromCity[tripIndex]?.RouteDestinationId,
          searchInfo?.toCity[tripIndex]?.RouteDestinationId,
          searchInfo?.departureDate[tripIndex]
        );
        affiliateMarkup = res?.ResponseData;
      }
      searchFlights?.forEach((service, serviceIndex) => {
        service
          .getAvailability([trip], {
            child: Number(searchInfo?.child),
            adult: Number(searchInfo?.adult),
          })
          .then(async (response) => {
            const { data } = response?.data;
            const journeys = formatToArr(
              data?.Schedules?.ArrayOfJourneyDateMarketVer2
            );
            const fare = formatToArr(data?.Fares?.Fare);
            journeys.forEach((journey, journeyIndex) => {
              const formattedFlightData = formatSJToFlightCardData(
                fare,
                formatToArr(
                  journey?.JourneyDateMarketVer2?.AvailableJourneys
                    ?.AvailableJourney
                ),
                markup,
                searchInfo,
                metaData?.FlightOrigins,
                isAgent,
                affiliateMarkup
              );
              let temp = flightSearchData[tripIndex];
              temp.push(...formattedFlightData);
              setFlightSearchData(
                flightSearchData.map((e, i) => (i === tripIndex ? temp : e))
              );
            });
            dispatch(updateState({ key: "isFlightDataFetched", value: true }));
          })
          .catch((err) => {
            dispatch(
              showToast({
                subTitle: "No Flights Found",
                type: "error",
                title: "",
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    });
  }, [searchInfo, dispatch, getMarkUp, getAffiliateMarkUp, flightSearchData]);

  const getFlights = () => {
    resetSearchDataBeforeSearch();
    setStartSearching(true);
  };

  // when startSearching is true means prev search is cleared, searchFlight is called
  // need to FIX this to call the searchFlight directly without toggling a boolean and then calling it
  // this was done as the state wasn't resetting properly before searching and flight data was getting appended when we search for
  // multiple carrier(appending result in array instead of replacing it).
  useEffect(() => {
    if (
      startSearching &&
      flightSearchData.filter((e) => e.length === 0).length === 3
    ) {
      searchFlight();
      setStartSearching(false);
    }
  }, [flightSearchData, startSearching, searchFlight]);

  const getItinerary = async (
    selectedFares: ISelectedFaresForItinerary[]
  ) => {

    let isSold = false;
    setItineraryLoading(true);

    try {

      // filter out Navitaire based journeys to prepare for itinerary payload
      const filteredNavitaireJourneys = selectedFares.filter(
        journey => journey.supplier === "navitaire"
      );
      if (filteredNavitaireJourneys.length === 0) {
        setItineraryLoading(false);
        history.push("/flight/booking");
        return isSold;
      };

      const journeys = getSellAndItineraryPayload(filteredNavitaireJourneys);
      let itineraryPromises = Object.keys(journeys).map((carrierCode) =>
        searchFlights[
          AirLineServices[carrierCode as TypeCarrierCode]
        ]?.getItineraryPrice(journeys[carrierCode], {
          adult: searchInfo?.adult,
          child: searchInfo?.child,
        })
      );

      // console.log("journeys: ", journeys);
      // console.log("searchFlights: ", searchFlights);
      // console.log("AirLineServices[carrierCode as TypeCarrierCode]: ", AirLineServices["6E" as TypeCarrierCode]);
      // console.log("searchFlights - index: ", searchFlights[1]);
      // console.log("itineraryPromises: ", itineraryPromises);

      const response = await Promise.all(itineraryPromises);
      if (response) {
        //more than one carrier flights are selected
        let itineraryData = response.map((res) => res.data?.data);
        // TODO: Update refactor Itinerary format function to handle more than one
        // itinerary(An array of itinerary containing result of different carriers) function
        //  and create one single data of itinerary.
        // if same carrier flights are selected then only one response is returned

        const itinerary = formatItineraryData(
          itineraryData,
          metaData,
          searchInfo,
          MyCharges,
          GSTRates
        );
        let fare: IFare = {
          adult: Array(searchInfo?.adult)
            .fill("")
            .map((e) => ({
              fare: {
                base: itinerary.Journeys.reduce(
                  (acc, each) => each.meta.adultBasePrice + acc,
                  0
                ),
                markedUpBase: itinerary.Journeys.reduce(
                  (acc, each) =>
                    each.meta.markupPrice.adult.markedupBasePrice + acc,
                  0
                ),
                selectedMeal: 0,
                selectedSeat: 0,
                selectedBaggage: 0,
                markedUpSelectedMeal: 0,
                markedUpSelectedSeat: 0,
                markedUpSelectedBaggage: 0,
              },
              charges: {
                tax: itinerary.Journeys.reduce(
                  (acc, each) => each.meta.adultTravelFee + acc,
                  0
                ),
                markedUpTax: itinerary.Journeys.reduce(
                  (acc, each) =>
                    each.meta.markupPrice.adult.markedUpTaxPrice + acc,
                  0
                ),
                selectedMeal: 0,
                selectedSeat: 0,
                selectedBaggage: 0,
                markedUpSelectedMeal: 0,
                markedUpSelectedSeat: 0,
                markedUpSelectedBaggage: 0,
              },
            })),
          child: Array(searchInfo?.child)
            .fill("")
            .map((e) => ({
              fare: {
                base: itinerary.Journeys.reduce(
                  (acc, each) => (each.meta.childBasePrice || 0) + acc,
                  0
                ),
                markedUpBase: itinerary.Journeys.reduce(
                  (acc, each) =>
                    each.meta.markupPrice.child.markedupBasePrice + acc,
                  0
                ),
                selectedMeal: 0,
                selectedSeat: 0,
                selectedBaggage: 0,
                markedUpSelectedMeal: 0,
                markedUpSelectedSeat: 0,
                markedUpSelectedBaggage: 0,
              },
              charges: {
                tax: itinerary.Journeys.reduce(
                  (acc, each) => (each.meta.childTravelFee || 0) + acc,
                  0
                ),
                markedUpTax: itinerary.Journeys.reduce(
                  (acc, each) =>
                    each.meta.markupPrice.child.markedUpTaxPrice + acc,
                  0
                ),
                selectedMeal: 0,
                selectedSeat: 0,
                selectedBaggage: 0,
                markedUpSelectedMeal: 0,
                markedUpSelectedSeat: 0,
                markedUpSelectedBaggage: 0,
              },
            })),
          total: {
            base: 0,
            tax: 0,
            markedUpBase: 0,
            markedUpTax: 0,
          },
        };
        dispatch(flightSlice.actions.setFare(fare));
        dispatch(flightSlice.actions.updateItinerary(itinerary));
        isSold = true;
        // history.push("/flight/booking");
      } else {
        console.log("Error while fetching flight itinerary: ", response);
        dispatch(
          showToast({
            title: "Something went wrong!",
            subTitle: "try again",
            type: "error",
            time: 3,
          })
        );
      };

    } catch (error) {

      dispatch(
        showToast({
          title: "Something went wrong!",
          subTitle: "try again",
          type: "error",
          time: 3,
        })
      );
      console.log(error);

    };
    setItineraryLoading(false);

    return isSold;

  };



  return {
    getCharges,
    getFlights,
    searchFlight,
    isLoading,
    // flightSearchData,
    flightSearchData,
    getItinerary,
    itineraryLoading,
  };
};

export default useFlightSearch;
