import moment from "moment";
import { IBookingInfo } from "../../../redux/types";
import { AiOutlineArrowRight } from "react-icons/ai";
import { priceFormatter } from "../../../utils/utilityFunctions/utilityFunction";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Common/Button/Button";
import { FaHotel } from "react-icons/fa";

interface IProps {
  bookingInfo: IBookingInfo;
  type: "flight" | "hotel";
}

const flightIconSG = require("../../../assets/images/spicejet.png").default;
const flightIconG8 = require("../../../assets/images/goAir.jpg").default;
const indigoIcon = require("../../../assets/images/indigo.png").default;

export default function BookingDetailsCard({ bookingInfo, type }: IProps) {

  const history = useHistory();

  return (
    <div
      className="booking-details-card mt-3 py-4 p-3"
    >
      <div className="d-flex justify-content-between ">
        <div>
          {type === "flight" ? (
            <div className="d-flex align-items-center gap-2">
              {" "}
              <div className="heading-from-to">
                {bookingInfo?.FromRouteCode}
              </div>
              <AiOutlineArrowRight />
              <div className="heading-from-to">{bookingInfo?.ToRouteCode}</div>
            </div>
          ) : (
            <div className="heading-from-to">
              {(bookingInfo?.FirstName ?? "") + " " + (bookingInfo?.Lastname ?? "")}
            </div>
          )}
          <div className="flight-detail d-flex gap-3 align-items-center pt-2">
            {type === "flight" ? (
              <div className="d-flex gap-2">
                <div>
                  <img
                    alt="icon"
                    height={16}
                    width={16}
                    src={bookingInfo?.BookedSupplierName.toLowerCase() === "spicejet" ? flightIconSG : bookingInfo?.BookedSupplierName.toLowerCase() === "indigo" ? indigoIcon : flightIconG8}
                  />
                </div>{" "}
                <div className="name">{bookingInfo?.BookedSupplierName}</div>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <FaHotel />{" "}
                <div className="name">{"Hotel"}</div>
              </div>
            )}
            {/* <div className="dot"></div>
            <div className="name">{"Round Trip"}</div>
            <div className="dot"></div>
            <div className="name">{"Economy"}</div>
            <div className="dot"></div>
            <div className="name text-primary">{"1 Adult"}</div> */}
          </div>


        </div>
        {/* <Button
          label={"View Details"}
          size="sm"
          color="link"
          onClick={() => {
            history.push(`/profile/flight/ticket-details/${bookingInfo.TicketId}`);
          }}
        /> */}
      </div>

      <div className="border-bottom my-4"></div>
      <div className=" d-flex justify-content-between">
        <div>
          <div className="label">FROM</div>
          <div className="label-text">
            {moment(bookingInfo?.TravelFromDate).format("MMM Do YYYY")}
          </div>
        </div>
        <div>
          <div className="label">To</div>
          <div className="label-text">
            {moment(bookingInfo?.TravelToDate).format("MMM Do YYYY")}
          </div>
        </div>
        <div>
          <div className="label">Amount</div>
          <div className="label-text">
            {priceFormatter(bookingInfo?.NetPayableAmount)}
          </div>
        </div>
        <div>
          <div className="label">
            {type === "flight" ? "BOOKING PNR" : "BOOKING REF NO"}
          </div>
          <div
            className="text-primary label-text pointer"
            onClick={() => {
              history.push(`/profile/${type === "flight" ? "flight" : "hotel"}/ticket-details/${bookingInfo.TicketId}`);
            }}
          >
            {type === "flight" ? bookingInfo?.PNR : bookingInfo?.BookingRefNumber}
          </div>
        </div>
      </div>
    </div>
  );
}
