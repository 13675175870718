import React from "react";

interface Props {
  title: string;
  isActive?: Boolean;
  goTo: () => void;
}

const Tab = ({ title, isActive, goTo }: Props) => {
  return (
    <div className={isActive ? "tab active" : "tab "} onClick={() => goTo()}>{title}</div>
  );
};

export default Tab;
