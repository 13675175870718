import React, { useState } from "react";
import Button from "../Button/Button";
import { FaAngleLeft, FaAngleRight, FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { showToast, toggle } from "../../../redux/reducers/commonState";
import { handleFormValidation } from "../../../utils/utilityFunctions/utilityFunction";
import useAuth from "../../../utils/hooks/useAuth";
import InputWithIcon from "../Input/InputWithIcon";

interface Props {}

const SignUp = (props: Props) => {
  const [step, setStep] = useState<number>(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const { registerUser, isRegistering } = useAuth();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<{
    FirstName: string;
    LastName: string;
    Email: string;
    Mobile: string;
    Password: string;
    confirmPassword: string;
  }>({
    FirstName: "",
    LastName: "",
    Email: "",
    Mobile: "",
    Password: "",
    confirmPassword: "",
  });

  const handleChange = (
    key:
      | "FirstName"
      | "LastName"
      | "Email"
      | "Mobile"
      | "Password"
      | "confirmPassword",
    value: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleSubmit = () => {
    const formStatus = handleFormValidation(formData);
    if (formStatus.isValidated) {
      registerUser(formData);
    } else {
      const message = formStatus?.errorMessage;
      dispatch(
        showToast({
          title: "",
          subTitle: message || "Please check input again",
          type: "error",
          time: 3,
        })
      );
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <h2>{"Sign Up"}</h2>
        </div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            dispatch(toggle("showSignInModal"));
          }}
        ></button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {step === 0 ? (
          <>
            <div className="d-flex justify-content-between">
              <div>
                <label htmlFor="firstname" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Jon"
                  id="firstName"
                  required
                  value={formData.FirstName}
                  onChange={(e) => {
                    const { value } = e.target;
                    handleChange("FirstName", value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="lastname" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Doe"
                  id="lastName"
                  value={formData.LastName}
                  onChange={(e) => {
                    const { value } = e.target;
                    handleChange("LastName", value);
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor="email">Email id</label>
              <input
                type="email"
                required
                className="form-control mb-4"
                placeholder="jon.doe@mail.com"
                id="email"
                value={formData.Email}
                onChange={(e) => {
                  const { value } = e.target;
                  handleChange("Email", value);
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                label={
                  <span>
                    {" "}
                    Next
                    <FaAngleRight size={30} style={{ marginBottom: "2px" }} />
                  </span>
                }
                onClick={() => setStep(1)}
                className="p-2 px-4 w-100"
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <label htmlFor="mobile">Mobile Number</label>
              <input
                type="text"
                required
                className="form-control mb-2"
                placeholder="999 999 9999"
                id="mobile"
                value={formData.Mobile}
                onChange={(e) => {
                  const { value } = e.target;
                  handleChange("Mobile", value);
                }}
              />
            </div>
            <InputWithIcon
              type={showPassword ? "text" : "password"}
              value={formData?.Password}
              onChange={(value) => {
                setFormData((prev) => ({
                  ...prev,
                  Password: value,
                }));
              }}
              label="Password"
              placeholder="Password"
              icon={showPassword ? <FaEye /> : <FaEyeSlash />}
              onIconClick={() => {
                setShowPassword((prev) => !prev);
              }}
            />
            <InputWithIcon
              type={showConfirmPassword ? "text" : "password"}
              value={formData?.confirmPassword}
              label="Confirm Password"
              placeholder="Confirm Password"
              onChange={(value) => {
                setFormData((prev) => ({
                  ...prev,
                  confirmPassword: value,
                }));
              }}
              icon={showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              onIconClick={() => {
                setShowConfirmPassword((prev) => !prev);
              }}
            />
            <p className="small text-muted mt-3">
              By signing up, you agree to our <a href="#">Terms of Use</a> and{" "}
              <a href="#">Privacy Policy</a>.
            </p>

            <div className="d-flex gap-2">
              <Button
                color="primary"
                label={
                  <span>
                    <FaAngleLeft size={30} style={{ marginBottom: "2px" }} />
                    Previous
                  </span>
                }
                onClick={() => setStep(0)}
                className="p-2 px-4 w-100"
              />
              <Button
                type="submit"
                className="w-100"
                color="primary"
                label="Sign up"
                isLoading={isRegistering}
              />
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default SignUp;
