import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { State } from '../../../../redux/types';
import { SERVER_API_URL } from '../../../../utils/constants';
import useHotel from '../../../../utils/hooks/Hotel/useHotel';
import { ITicketDetails } from '../../../../utils/types/flightTypes';
import { IHotelDetailsToBeStored } from '../../../../utils/types/hotelTypes';
import { initialBookedHotelInformation } from '../../../../redux/initialState';
import { postBookingHotel } from '../../../../Interfaces/SearchHotel/PostBookHotel';
import { searchHotelBeds } from '../../../../Interfaces/SearchHotel/SearchHotel';

export default function useHotelBookingDetails() {

  const params: { ticketID: string } = useParams();

  const history = useHistory();

  const clientId = useSelector((state: State) => state.commonState.Client?.id);

  const { handleShowToast } = useHotel();

  // const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHotelBedsBookingDetailsLoading, setIsHotelBedsBookingDetailsLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState<ITicketDetails | undefined>(undefined);
  const [bookedHotelInformation, setBookedHotelInformation] = useState<IHotelDetailsToBeStored>(initialBookedHotelInformation);
  const [bookingDetailsFromHotelBeds, setBookingDetailsFromHotelBeds] = useState<any>(null);
  const [isCancellationAvailable, setIsCancellationAvailable] = useState<boolean>(false);
  const [netPayable, setNetPayable] = useState<{
    totalTravelerAmount: number;
    netAmount: number;
  }>({
    totalTravelerAmount: 0,
    netAmount: 0
  });


  useEffect(() => {

    if (clientId && params?.ticketID) {

      fetchTicketDetails(clientId, +params?.ticketID);
      fetchBookedHotelInformation(+params?.ticketID);

    };

  }, []);


  // HANDLER FUNCTIONS
  const calculateTotalPaidAmount = (ticketDetails: ITicketDetails) => {

    let netPayable = 0;

    const totalTravelerAmount = ticketDetails?.lstTicketTravellerDetails?.reduce(
      (accumulator, currentObject) => {
        return accumulator + currentObject.TotalAmount;
      }, 0
    ) || 0;

    let totalCharges = 0;
    for (const eachCharge of ticketDetails?.lstTicketCharges) {

      if (eachCharge.ChargeName == "My Markup") continue;
      else totalCharges += eachCharge.Amount;

    };

    netPayable = totalTravelerAmount + totalCharges;
    setNetPayable({
      totalTravelerAmount: totalTravelerAmount,
      netAmount: netPayable
    });

  };

  const removeDuplicatesPax = (ticketDetails: ITicketDetails) => {
    const uniqueEntries: any = [];
    const keySet = new Set();

    ticketDetails?.lstTicketTravellerDetails.forEach((entry) => {
      const key = entry.AirlinePNR + entry.TravellerFirstName + entry.TravellerLastName || "";
      if (!keySet.has(key)) {
        uniqueEntries.push(entry);
        keySet.add(key);
      }
    });
    setTicketDetails({ ...ticketDetails, lstTicketTravellerDetails: uniqueEntries })
    return uniqueEntries;
  };


  // API CALLS
  const fetchHotelBookingDetailsFromHotelBeds = (bookingReference: string) => {

    setIsHotelBedsBookingDetailsLoading(true);
    searchHotelBeds.fetchBookingDetails(bookingReference).then(
      (response) => {

        console.log("Response for booking details from HotelBeds: ", response.data?.res?.booking);
        setBookingDetailsFromHotelBeds(response.data?.res?.booking);

      }, (error) => {

        console.log("Error while fetching booking details from HotelBeds: ", error?.response?.data);
        handleShowToast({
          title: "Something went wrong!",
          subTitle: error?.response?.data?.message?.error?.message ? String(error?.response?.data?.message?.error?.message) : "It seems like something went wrong. Please try again later.",
          type: "error",
          // time: 4000
        });

      }
    ).finally(() => setIsHotelBedsBookingDetailsLoading(false));

  };

  const fetchTicketDetails = async (clientID: number, ticketID: number) => {

    setIsLoading(true);
    let res = await postBookingHotel.fetchBookingInformation(clientID, ticketID).catch(
      (error: any) => {
        console.log("Error in fetching ticket details: ", error?.response);
        setIsLoading(false);

      }
    );

    if (res?.data?.IsSuccess) {
      const bookingDetails = res?.data?.ResponseData;
      setTicketDetails(bookingDetails);
      calculateTotalPaidAmount(bookingDetails);
      removeDuplicatesPax(bookingDetails);
    };

  };

  const fetchBookedHotelInformation = async (ticketID: number) => {

    setIsLoading(true);
    let res = await postBookingHotel.fetchBookedHotelDetails(ticketID).catch(
      (error: any) => {
        console.log("Error in fetching ticket details: ", error?.response);
        setIsLoading(false);

      }
    );

    if (res?.data?.IsSuccess) {

      // setIsCancellationAvailable();

      const hotelDetails: IHotelDetailsToBeStored = res?.data?.ResponseData?.[0];
      // console.log("hotelDetails: ", hotelDetails);
      setBookedHotelInformation(hotelDetails);
      hotelDetails.BookingReferenceNumber && fetchHotelBookingDetailsFromHotelBeds(hotelDetails.BookingReferenceNumber);
      // setTicketDetails(bookingDetails);
      // calculateTotalPaidAmount(bookingDetails);
      // removeDuplicatesPax(bookingDetails);

    };

  };


  const cancelBooking = (referenceNumber: string, ticketID: number) => {

    if (!referenceNumber) {

      handleShowToast({
        title: "Missing!",
        subTitle: "This ticket can not be cancelled since it does not have a reference number.",
        type: "warning"
      });
      return;

    };

    if (!ticketID) {

      handleShowToast({
        title: "Missing!",
        subTitle: "This ticket can not be cancelled since it does not have a ticket ID.",
        type: "warning"
      });
      return;

    };

    // cancelHotelBookingAtAdmin(referenceNumber, ticketID);

    setIsLoading(true);
    searchHotelBeds.cancelPreviousBooking(referenceNumber).then(
      (response: AxiosResponse) => {

        console.log("Response for HotelBed's booking cancelation: ", response?.data.data);
        response?.data?.data?.booking?.status.toLowerCase() === "cancelled"
          ?
          cancelHotelBookingAtAdmin(referenceNumber, ticketID)
          // handleShowToast({
          //     title: "Booking Cancelled!",
          //     subTitle: "You have successfully cancelled your booking.",
          //     type: "info",
          // })
          :
          handleShowToast({
            title: "Something went wrong!",
            subTitle: "It seems like something went wrong. Please try again later.",
            type: "error"
          });

      }, (error: AxiosError) => {

        setIsLoading(false);
        console.log("Error for hotel bed booking cancelation: ", error?.response);
        handleShowToast({
          title: "Something went wrong!",
          subTitle: error?.response?.data?.message?.error?.message ? String(error?.response?.data?.message?.error?.message) : "It seems like something went wrong. Please try again later.",
          type: "error"
        });

      }
    );

  };

  const cancelHotelBookingAtAdmin = async (referenceNumber: string, ticketID: number) => {

    // setIsLoading(true);
    let res = await postBookingHotel.cancelBookedHotel(ticketID).catch(
      (error: any) => {

        console.log("Error while cancelling admin ticket: ", error?.response);
        setIsLoading(false);

      }
    );

    if (res?.data?.IsSuccess) {

      setIsLoading(false);
      console.log("Response for cancel admin booking: ", res?.data);
      handleShowToast({
        title: "Booking Cancelled!",
        subTitle: "Your booking has been cancelled successfully.",
        type: "success"
      });

    } else {

      setIsLoading(false);
      console.log("Error for cancel booking: ", res?.data);
      handleShowToast({
        title: "Something went wrong!",
        subTitle: "It seems like something went wrong. Please try again later.",
        type: "error"
      });

    };

  };


  return {
    history,
    isLoading,
    isHotelBedsBookingDetailsLoading,
    netPayable,
    ticketDetails,
    isOpenModal,
    setIsOpenModal,
    isOpenInvoiceModal,
    setIsOpenInvoiceModal,
    bookedHotelInformation,
    bookingDetailsFromHotelBeds,
    cancelBooking
  };

};