import Skeleton from "./Skeleton";

const LoadingSearchFlight = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <Skeleton typeArray={["title"]} />
          <Skeleton
            typeArray={["box"]}
            count={5}
            elementStyle={{ height: "90px" }}
          />
        </div>
        <div className="col-md-9">
          <Skeleton typeArray={["text"]} />
          <div className="row">
            <div className="col">
              <Skeleton typeArray={["title", "text", "text"]} count={6} />
            </div>
            <div className="col">
              <Skeleton typeArray={["title", "text", "text"]} count={6} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSearchFlight;
