import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../redux/reducers/commonState";
import { State } from "../../../redux/types";
import { SERVER_API_URL } from "../../constants";


declare global {
    interface Window {
        Cashfree?: any;
    }
};

type TPaymentStatus = "ACTIVE" | "PAID" | "EXPIRED";

interface ISuccessPaymentResponse {
    order: {
        status: TPaymentStatus;
        orderId: string;
        message: string;
        errorText: string | null;
        activePaymentMethod: string;
    };
    transaction: {
        txStatus: string;
        txMsg: string | null;
        transactionId: string;
        transactionAmount: string;
    };
};

interface IFailedPaymentResponse {
    order: {
        status: "ERROR" | string;
        errorText: string;
        activePaymentMethod: string | null;
        message: string | null;
        orderId: string;
    },
    transaction: any;
};

export interface ICreateOrderResponse {
    order_amount: string;
    order_id: string;
    payment_session_id: string;
};

export interface IVerifyOrderResponse {
    order_amount: string;
    order_id: string;
    order_status: TPaymentStatus | null;
    payment_session_id: string | null;
};

interface IPayloadForGenerateOrder {
    CustomerId: string | null;
    CustomerName?: string;
    EmailId?: string;
    PhoneNo?: string;
    OrderAmount: number;
    OrderCurrency: string;
    OrderNote: string;
    ReturnUrl: string;
};


interface IProps {
    paymentForm: React.RefObject<HTMLDivElement>;
    handlePaymentSuccess: (onSuccessDetails: any) => void;
    handlePaymentFailure: (
        onFailureDetails: ICreateOrderResponse,
        isPaymentDone: boolean
    ) => void;
};

export default function usePayment({
    paymentForm,
    handlePaymentSuccess,
    handlePaymentFailure
}: IProps) {

    const dispatch = useDispatch();

    const user = useSelector((state: State) => state.user);

    const [isPaymentFormVisible, setIsPaymentFormVisible] = useState<boolean>(false);
    const [isGeneratingOrder, setIsGeneratingOrder] = useState<boolean>(false);
    const [netPayable, setNetPayable] = useState<string>("");
    const [onSuccessDetails, setOnSuccessDetails] = useState<any>();
    const [createOrderResponse, setCreateOrderResponse] = useState<ICreateOrderResponse>({
        order_amount: "",
        order_id: "",
        payment_session_id: ""
    });


    useEffect(() => {
        // verifyPaymentStatus("order_12345678913");
        if (isPaymentFormVisible && netPayable) {
            generateNewOrder(netPayable);
        };
    }, [isPaymentFormVisible, netPayable]);


    /* HANDLER FUNCTIONS */
    const validatePayloadForGenerateOrder = (payload: IPayloadForGenerateOrder) => {

        let validator: {
            isValidated: boolean;
            message: string | undefined;
        } = {
            isValidated: false,
            message: undefined
        };

        if (!payload.CustomerId) {
            validator.message = "CustomerId is required!";
            return validator;
        };

        // if (!payload.CustomerName) {
        //     validator.message = "CustomerName is required!";
        //     return validator;
        // };

        // if (!payload.EmailId) {
        //     validator.message = "EmailId is required!";
        //     return validator;
        // };

        // if (!payload.PhoneNo) {
        //     validator.message = "PhoneNo is required!";
        //     return validator;
        // };

        if (!payload.OrderAmount) {
            validator.message = "OrderAmount is required!";
            return validator;
        };

        if (!payload.OrderCurrency) {
            validator.message = "OrderCurrency is required!";
            return validator;
        };

        if (!payload.OrderNote) {
            validator.message = "OrderNote is required!";
            return validator;
        };

        validator.isValidated = true;
        return validator;

    };


    /* CASHFREE SDK */
    const handleCashfreeDropSDK = (newPaymentSessionId: string) => {
        setIsPaymentFormVisible(true);

        if (newPaymentSessionId) {
            // Cashfree Drop configuration
            const dropinConfig = {
                components: [
                    "order-details",
                    "card",
                    "netbanking",
                    // "app",
                    "upi",
                ],
                onSuccess: function (data: ISuccessPaymentResponse) {
                    //on success
                    if (data.order && data.order.status == "PAID") {

                        console.log("Payment Success: ", data);
                        setIsPaymentFormVisible(false);
                        // verify order status and create booking at admin
                        verifyPaymentStatus(data.order.orderId);

                    } else {

                        alert("We could not receive the PAID confirmation from your payment.");
                        handlePaymentFailure(createOrderResponse, false);

                    };

                },
                onFailure: function (data: IFailedPaymentResponse) {
                    //on success
                    console.error("Payment Failed: ", data);
                    handlePaymentFailure(createOrderResponse, false);
                    alert(data?.order.errorText);
                    setIsPaymentFormVisible(false);
                    dispatch(
                        showToast({
                            title: data?.order?.status || "Payment Failed!",
                            subTitle: data?.order?.errorText || "Payment failed",
                            type: "error",
                            // time: 3,
                        })
                    );
                },
                //to be replaced by the desired values
                // style: {{
                //       backgroundColor: "#ffffff",
                //       color: "#11385b", 
                //       fontFamily: "Lato",
                //       fontSize: "14px",
                //       errorColor: "#ff0000",
                //       theme: "light", (or dark)
                // }}
            };

            // initiate the SDK
            const paymentSessionId = newPaymentSessionId;
            const cashfree = new window.Cashfree(paymentSessionId);

            try {
                console.log("Initiating DROP");
                const element = paymentForm.current; // document.getElementById("paymentForm");
                cashfree.drop(element, dropinConfig);
                console.log("Drop Initiated");
            } catch (error) {
                console.error("Error in Cashfree Drop SDK: ", error);
            };

        } else {
            console.error("Session id is missing");
            setIsPaymentFormVisible(false);
        };

    };


    /* API CALLS */
    const generateNewOrder = (netPayable: string) => {
        // console.log("Net Payable: ", netPayable);
        // call create order API at admin


        const payload: IPayloadForGenerateOrder = {
            CustomerId: user.isAgent ? user.affiliateId : user.userId,
            // CustomerName: user.firstName || "" + user.lastName || "",
            // EmailId: user.email || "",
            // PhoneNo: user.phone || "",
            OrderAmount: Number(Number(netPayable).toFixed(2)),
            OrderCurrency: "INR",
            OrderNote: "Testing Transaction",
            ReturnUrl: ""
        };

        const isValidated = validatePayloadForGenerateOrder(payload);

        if (!isValidated.isValidated) {
            dispatch(
                showToast({
                    title: "Order Generation Failed!",
                    subTitle: isValidated.message || "Something Went Wrong!",
                    type: "error",
                    // time: 3,
                })
            );
            return;
        };

        setIsGeneratingOrder(true);
        axios.post(`${SERVER_API_URL}Flight/CreateOrder`, payload).then(
            response => {

                // console.log("Response for order generate: ", response.data);
                const data = response.data;
                if (data?.IsSuccess) {
                    const res: ICreateOrderResponse = JSON.parse(response.data?.ResponseData);
                    setCreateOrderResponse(res);
                    handleCashfreeDropSDK(res?.payment_session_id);
                    // console.log("response.data?.ResponseData: ", response.data?.ResponseData);
                    // console.log("res?.payment_session_id: ", res?.payment_session_id);
                } else {
                    setIsPaymentFormVisible(false);
                    dispatch(
                        showToast({
                            title: "Order Generation Failed!",
                            subTitle: "Could not create order. Please try again later",
                            type: "error",
                            // time: 3,
                        })
                    );
                };

            }, error => {

                console.error("Error in order generate: ", error?.response);
                setIsPaymentFormVisible(false);
                dispatch(
                    showToast({
                        title: "Order Generation Failed!",
                        subTitle: "Please try again to proceed to payment",
                        type: "error",
                        // time: 3,
                    })
                );

            }
        ).finally(() => setIsGeneratingOrder(false));
    };

    const verifyPaymentStatus = (orderID: string) => {

        axios.get(`${SERVER_API_URL}Flight/ConfirmOrder/${orderID}`).then(
            response => {

                // console.log("Response for order verification: ", response.data);

                if (response.data?.IsSuccess) {

                    const res: IVerifyOrderResponse = JSON.parse(response.data?.ResponseData);
                    // console.log("Response for verify order: ", res);

                    const STATUS = res?.order_status ?? "NOT FOUND";
                    if (STATUS === "PAID") {

                        // call API to create new booking at admin
                        handlePaymentSuccess(res);

                    } else if (STATUS === "EXPIRED") {

                        handlePaymentFailure(createOrderResponse, false);
                        dispatch(
                            showToast({
                                title: `Order Status - ${STATUS}!`,
                                subTitle: "This order has expired. Please try again.",
                                type: "error",
                                // time: 3,
                            })
                        );

                    } else {

                        handlePaymentFailure(createOrderResponse, false);
                        dispatch(
                            showToast({
                                title: `Order Status - ${STATUS}!`,
                                subTitle: "If any amount is debited from your account. We will look into it.",
                                type: "error",
                                // time: 3,
                            })
                        );

                    };

                } else {

                    handlePaymentFailure(createOrderResponse, false);
                    dispatch(
                        showToast({
                            title: "Order Verification Failed!",
                            subTitle: "If any amount is debited from your account. We will look into it.",
                            type: "error",
                            // time: 3,
                        })
                    );

                };

            }, error => {

                console.error("Error in order confirmation: ", error?.response);
                handlePaymentFailure(createOrderResponse, false);
                dispatch(
                    showToast({
                        title: "Order Verification Failed!",
                        subTitle: "If any amount is debited from your account. We will look into it.",
                        type: "error",
                        // time: 3,
                    })
                );

            }
        );

    };


    return {
        isGeneratingOrder,
        onSuccessDetails,
        setOnSuccessDetails,
        isPaymentFormVisible,
        setIsPaymentFormVisible,
        setNetPayable,
        generateNewOrder
    };
}
