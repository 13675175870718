import Badge from "@kiwicom/orbit-components/lib/Badge";
import { FlightDirect } from "@kiwicom/orbit-components/lib/icons";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { IJourney } from "../../../../../utils/types/flightTypes";
import { State } from "../../../../../redux/types";
import useFlight from "../../../../../utils/hooks/Flight/useFlight";
import DetailedInfo from "./DetailedInfo";

interface Props {
  journey: IJourney;
  label?: string;
  isDetailedShown: boolean;
  departureDate: string;
};

const  SingleTrip = ({
  journey,
  label,
  isDetailedShown,
  departureDate,
}: Props) => {

  const { searchInfo, metaData } = useSelector((state: State) => state.flight);

  // console.log("journey: ", journey);

  return (
    <React.Fragment>
      <p className="text-muted small mb-1 text-uppercase">{label}</p>
      <h4>
        {journey?.meta?.airports?.map((seg: any, index: number) => (
          <span key={index}>
            {" "}
            {
              metaData?.FlightOrigins.find(
                (airport) => airport.RouteDestinationCode === seg
              )?.City
            }
            {index <
              (journey?.meta?.airports && journey?.meta?.airports?.length - 1) ? (
              <FlightDirect />
            ) : (
              <></>
            )}
          </span>
        ))}
      </h4>
      <div className="d-flex align-items-center gap-1">
        <img
          src={
            require(`../../../../../assets/${journey?.meta?.carrierCode === "SG" ? "spicejet" : journey?.meta?.carrierCode === "6E" ? "indigo" : journey?.meta?.carrierCode === "QP" ? "akasaair" : "goair"
              }.png`).default
          }
          className="img rounded"
          height={20}
          width={20}
          alt="airline"
        />
        <div className="d-flex align-items-center"></div>
        {journey?.meta?.bookingSupplier?.SupplierName}
        <div
          className="bg-dark mx-2"
          style={{ width: 4, height: 4, borderRadius: 4 }}
        ></div>
        {moment(departureDate).format("DD, MMM YYYY")}
      </div>
      {isDetailedShown ? (
        <DetailedInfo journey={journey} />
      ) : (
        <>
          {/* Time */}
          <div className="pt-4">
            <p className="mb-1 fw-semibold">
              {moment(journey?.meta?.departureTime).format("HH:mm")} -{" "}
              {moment(journey?.meta?.arrivalTime).format("HH:mm")}
            </p>
            <p className="small text-muted d-flex align-items-center">
              {journey?.meta?.duration}
              <span
                className="bg-dark mx-2"
                style={{ width: 4, height: 4, borderRadius: 4 }}
              ></span>
              {journey?.meta?.airports &&
                journey?.meta?.airports?.length - 2 > 0
                ? `${journey?.meta?.airports?.length - 2} Stop`
                : "Non Stop"}{" "}
              {/* {1 Stop in Ahmedabad (1h 50m)} */}
            </p>
          </div>
          <div className=" d-flex justify-content-start">
            <div className="me-1">
              <Badge type="white">{journey?.meta?.flightCode}</Badge>
            </div>

            <Badge type="info">
              <span className="text-capitalize">
                {searchInfo?.class?.FlightClassName}
              </span>
            </Badge>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default SingleTrip;