import React, { PropsWithChildren } from "react";
interface Props {
  title?: string;
  footer?: boolean;
}

const CardBase = (props: PropsWithChildren<Props>) => {
  return (
    <div className="card ">
      {props.title ? <div className="card-header">{props.title}</div> : <></>}
      <div className="card-body">{props.children}</div>
      {props.footer ? <div className="card-footer"></div> : null}
    </div>
  );
};

export default CardBase;
