import React, { useState } from "react";

type Props = {
  onSearch: (value: string) => void;
  type?: string;
  iconEnd?: JSX.Element;
  searchLabel?: string;
  placeHolder?: string;
};

const InputSearch = ({
  type,
  onSearch,
  iconEnd,
  searchLabel,
  placeHolder,
}: Props) => {
  const [value, setValue] = useState<string>("");
  let wrapperStyle = {
    border: "0.5px solid #00000040",
    display: "flex",
    "align-items": "center",
    "justify-content": "space-between",
    "border-radius": "6px",
    overflow: "hidden",
    padding: 0,
    margin: 0,
  };

  const inputStyle = {
    padding: "8px",
    border: "none",
    outline: "none",
  };
  const buttonStyle = {
    padding: "8px",
    border: "none",
    outline: "none",
    background: `#4337be${value.length > 0 ? "90" : "60"}`,
    color: `#FFFFFF${value.length > 0 ? "" : "70"}`,
    "border-top-right-radius": "6px",
    "border-bottom-right-radius": "6px",
  };
  return (
    <div style={wrapperStyle}>
      <input
        style={inputStyle}
        type={type || "text"}
        placeholder={placeHolder || "Enter here..."}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <button
        onClick={() => value.length > 0 && onSearch(value)}
        style={buttonStyle}
      >
        {searchLabel || "Search"}
      </button>
    </div>
  );
};
export default InputSearch;
