import { IHotelListingInfo, INearbySearchGeoLocation, TypeHotelServices, TypePagination } from './../../types/hotelTypes';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { hotelSlice, updateHotelMetaData } from "../../../redux/reducers/hotel";
import { commonStateSlice } from "../../../redux/reducers/commonState";
import { IHotelWhereTo, IRoom, ISelectedHotel, State, IToast, IRoomCombinations, ICombination, ITBOHotelDetails, ITBORoom, IHotelDetails } from "../../../redux/types";
import {
    IHotelMarkup,
    TypePassenger,
    TypeSearchHotelFilters,
    TypeSearchHotelKeys,
    TypeBookingPassenger,
    TypeSearchHotelRoomGuestKeys
} from "../../../utils/types/hotelTypes";

export default function useHotel() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { filters, HotelBeds, TBO } = useSelector((state: State) => state.hotel);

    /* --------------------------------------------------
        |              For Common             |
        -------------------------------------------------- */

    // toast message
    const handleShowToast = (payload: IToast) => {
        dispatch(commonStateSlice.actions.showToast(payload));
    };

    // update hotel data in redux
    const handleUpdateHotelData = (payload: boolean) => {
        dispatch(commonStateSlice.actions.updateState({ key: "isHotelDataFetched", value: payload }));
    };


    /* --------------------------------------------------
    |              For Search Hotel Filters
    -------------------------------------------------- */

    // stores arrays of strings in filters state as there can be multiple filters of one type
    const handleHotelFilters = (key: TypeSearchHotelFilters, value: string, priceStart?: string, priceEnd?: string) => {
        dispatch(hotelSlice.actions.updateHotelFilters({ key, value, priceStart, priceEnd }));
    };

    const handleResetHotelFilters = () => {
        dispatch(hotelSlice.actions.resetHotelFilters());
    };

    const updateHotelFilterSelectedNearbySearchGeoLocation = (value: INearbySearchGeoLocation) => {
        dispatch(hotelSlice.actions.updateHotelFilterSelectedNearbySearchGeoLocation(value));
    };

    /**
     * Filter the hotels based on the parameters provided by the user
     * @param {ISelectedHotel[]} hotels - The hotels that are currently being filtered.
     * @returns The hotels that match the search criteria.
     */
    const handleHotelFilterSearch = (hotels: IHotelListingInfo[]) => {

        // const filteredHotels = hotels?.filter(hotel =>
        //     (filters.hotelName.length > 3 ? hotel.hotelName.toLowerCase().includes(filters.hotelName.toLowerCase()) : true)
        //         &&
        //         (filters?.StarRating.length > 0 ? filters.StarRating.findIndex(rating => Number(rating) <= Number(hotel.starRating)) !== -1 : true)
        //         // &&
        //         // (filters?.price.length > 0 ? (+hotel.price <= Number(filters.price[0])
        //         //     // &&
        //         //     // hotel.Price.OfferedPrice <= Number(filters.price[1])
        //         // ) : true)
        //         // && filters.HotelCategory.length>0?hotel.HotelCategory.includes(filters.HotelCategory.toLowerCase())
        //         &&
        //         filters.zones.length && hotel?.zoneCode
        //         ?
        //         filters.zones.includes(String(hotel.zoneCode))
        //         :
        //         true
        // );

        const filteredHotels = hotels?.filter(hotel =>
            (filters.hotelName.length > 0 ? hotel.hotelName.toLowerCase().includes(filters.hotelName.toLowerCase()) : true)
            // &&
            // (filters?.StarRating.length > 0 ? filters.StarRating.findIndex(rating => Number(rating) <= Number(hotel.starRating)) !== -1 : true)
            // // &&
            // // (filters?.price.length > 0 ? (+hotel.price <= Number(filters.price[0])
            // //     // &&
            // //     // hotel.Price.OfferedPrice <= Number(filters.price[1])
            // // ) : true)
            // // && filters.HotelCategory.length>0?hotel.HotelCategory.includes(filters.HotelCategory.toLowerCase())
            // &&
            // filters.zones.length && hotel?.zoneCode
            // ?
            // filters.zones.includes(String(hotel.zoneCode))
            // :
            // true
        );

        // console.log("filteredHotels: ", filteredHotels);
        return filteredHotels;

    };
    // reset filters
    const handleHotelFilterReset = () => {
        dispatch(hotelSlice.actions.removeHotelFilters());
    };

    const resetSelectedNearbySearchGeoLocationFromFilters = () => {
        dispatch(hotelSlice.actions.resetSelectedNearbySearchGeoLocationFromFilters());
    };

    // clear specific filter
    const handleSpecificHotelFilterReset = (key: TypeSearchHotelFilters) => {
        dispatch(hotelSlice.actions.resetSpecificHotelFilter(key));
    };


    /* --------------------------------------------------
        |              For Search Hotel
        -------------------------------------------------- */

    // stores the hotel listing info
    const handleHotelListingInfo = (payload: IHotelListingInfo[]) => {
        dispatch(hotelSlice.actions.updateHotelList(payload));
    };

    // reset hotel listing info
    const handleHotelListingInfoReset = () => {
        dispatch(hotelSlice.actions.resetHotelList());
    };

    // stores searched hotel list received from search hotel api request
    const updateSearchedHotelList = (hotels: ISelectedHotel[] | ITBOHotelDetails[], service: TypeHotelServices) => {
        dispatch(hotelSlice.actions.updateSearchedHotelList({ hotels, service }));
    };

    const resetSearchedHotelList = (service: TypeHotelServices) => {
        dispatch(hotelSlice.actions.resetSearchedHotelList({ service }));
    };

    // stores trace id for each search hotel request
    const updateTraceId = (traceId: string) => {
        dispatch(hotelSlice.actions.updateTraceId(traceId));
    };

    // updates city information
    const updateWhereTo = (payload: IHotelWhereTo) => {
        dispatch(hotelSlice.actions.updateWhereTo(payload));
    };

    // remove city information
    const removeWhereTo = () => {
        dispatch(hotelSlice.actions.removeWhereTo());
    };

    // update geo location information
    const updateGeoLocation = (payload: INearbySearchGeoLocation) => {
        dispatch(hotelSlice.actions.updateGeoLocation(payload));
    };

    // remove city information
    const removeGeoLocation = () => {
        dispatch(hotelSlice.actions.removeGeoLocation());
    };

    // updates checkin, checkout, room, guest information
    const updateHotelSearch = (key: TypeSearchHotelKeys, value: any) => {
        dispatch(hotelSlice.actions.updateFilters({ key, value }));
    };

    // updates no of rooms
    const updateNoOfRooms = (key: "add" | "remove") => {
        dispatch(hotelSlice.actions.updateNoOfRooms({ key }));
    };

    // updates no of rooms & guest info based on room
    const updateRoomGuest = (index: number, key: TypeSearchHotelRoomGuestKeys, value: string | number, i?: number) => {
        dispatch(hotelSlice.actions.updateRoomGuest({ index, key, value, i }));
    };

    // remove a room & guest info
    const removeRoomGuest = (index: number) => {
        dispatch(hotelSlice.actions.removeRoomGuest(index));
    };

    const resetRoomGuestInfo = () => {
        dispatch(hotelSlice.actions.resetRoomGuestInfo());
    };

    // reser search filters
    const resetFilters = () => {
        dispatch(hotelSlice.actions.resetFilters());
    };

    // resets search hotel roomGuest array
    const resetRoomGuest = () => {
        dispatch(hotelSlice.actions.resetRoomGuest());
    };


    /* --------------------------------------------------
        |      For Selected Hotel From Searched Hotel Result
        -------------------------------------------------- */

    // updates selected hotel & navigates to the hotel details page
    const updateSelectedHotel = (
        payload: IHotelListingInfo | ITBOHotelDetails,
        service: TypeHotelServices
    ) => {
        removeSelectedRoom("HOTELBEDS");
        removeSelectedRoom("TBO");
        removeSelectedRoom("HOTELBEDS");
        removeSelectedRoom("TBO");
        if (service === "HOTELBEDS") {

            let typeHotel = payload as IHotelListingInfo;
            let hotel = HotelBeds.searchedHotelList?.filter((hotel: ISelectedHotel) => Number(hotel.hotel_dynamic_data.code) === Number(typeHotel.hotelCode))[0];

            dispatch(hotelSlice.actions.updateSelectedHotel({ hotel, service }));
            if (hotel?.hotel_dynamic_data.code) {
                // window.open("/hotel/HB/details", "_blank");
                history.push("/hotel/HB/details");
            } else {
                handleShowToast({
                    title: "Oops !",
                    subTitle: "Seems like we could not find this hotel. Please search for hotel again.",
                    type: "info",
                });
            };

        } else {

            let typeHotel = payload as IHotelListingInfo;
            if (typeHotel.hotelCode) {
                let hotel = TBO.searchedHotelList?.filter((hotel: ITBOHotelDetails) => Number(hotel.HotelCode) === Number(typeHotel.hotelCode))[0];
                handleUpdateTBOSelectedHotel(hotel, service);
                /* Opening a new window with the url /hotel/HB/details */
                window.open(`/hotel/HT/details/${typeHotel?.hotelCode}/${typeHotel?.hotelResultIndex}`, "blank");
                // history.push(`/hotel/HT/details/${typeHotel?.hotelCode}/${typeHotel?.hotelResultIndex}`);
            } else {
                let hotel = payload as ITBOHotelDetails;
                handleUpdateTBOSelectedHotel(hotel, service);
            };

        }
    };

    /**
     * This function is used to update the selected hotel in the store under TBO
     * @param {ITBOHotelDetails} hotel - IHotelDetails
     * @param {TypeHotelServices} service - TypeHotelServices
     */
    const handleUpdateTBOSelectedHotel = (hotel: ITBOHotelDetails, service: TypeHotelServices) => {
        hotel.HotelCode
            ?
            dispatch(hotelSlice.actions.updateSelectedHotel({ hotel, service }))
            :
            handleShowToast({
                title: "Oops !",
                subTitle: "Seems like we could not find this hotel. Please search for hotel again.",
                type: "info",
            });
    };

    // remove selected hotel
    const removeSelectedHotel = (service: TypeHotelServices) => {
        dispatch(hotelSlice.actions.removeSelectedHotel({ service }));
    };

    // for selecteing rooms based on room combination
    const updateSelectedRoom = (rooms: ICombination | ITBORoom[], service: TypeHotelServices) => {
        dispatch(hotelSlice.actions.updateSelectedRoom({ rooms, service }));
    };

    const updateHotelBedsRoomTypes = (roomType: { [x: string]: number }) => {
        dispatch(hotelSlice.actions.updateHotelBedsRoomType({ roomType }));
    };

    // for removing selected room
    const removeSelectedRoom = (service: TypeHotelServices) => {
        dispatch(hotelSlice.actions.resetSelectedRoom({ service }));
    };

    /* --------------------------------------------------
        |      For Passengers
        -------------------------------------------------- */

    // for updating passengers info
    const updateLeadPassenger = (key: TypePassenger, value: string, roomNo: number, passengerIndex: number) => {
        dispatch(hotelSlice.actions.updatePassengerInfo({ key, value, roomNo, passengerIndex }));
    };

    // reset passengers info
    const resetPassengerInfo = () => {
        dispatch(hotelSlice.actions.resetPassengerInfo());
    };

    // for updating booking passenger info
    const updateBookingPassenger = (key: TypeBookingPassenger, value: string) => {
        dispatch(hotelSlice.actions.updateBookingGuestInfo({ key, value }));
    };

    // reset booking passenger info
    const resetBookingPassenger = () => {
        dispatch(hotelSlice.actions.resetBookingGuestInfo());
    };

    /* --------------------------------------------------
        |      For MarkUp Data
        -------------------------------------------------- */

    // update admin markups
    const updateMarkup = (payload: IHotelMarkup[]) => {
        dispatch(hotelSlice.actions.updateHotelMetaData({ key: "markUps", value: payload }));
    };

    // update affiliate markups
    const updateAffiliateMarkup = (payload: IHotelMarkup[]) => {
        dispatch(hotelSlice.actions.updateHotelMetaData({ key: "affiliateMarkup", value: payload }));
    };

    // reset markups (Admin/B2B and Affiliate)
    const resetMarkups = () => {
        dispatch(hotelSlice.actions.resetHotelMarkups());
    };

    // reset charges under markup section
    const resetMetadataCharges = () => {
        dispatch(hotelSlice.actions.resetHotelMetaDataCharges());
    };

    // update destination
    const updateDestinations = (payload: any) => {
        dispatch(updateHotelMetaData({ key: "Destination", value: payload }));
    };

    // update pagination information
    const updatePaginationInfo = (key: TypePagination, value: number, service: "HOTELBEDS" | "TBO") => {
        dispatch(hotelSlice.actions.updatePagination({ key, value, service }));
    };

    // reset pagination information
    const resetPaginationInfo = (service: "HOTELBEDS" | "TBO") => {
        dispatch(hotelSlice.actions.resetPagination({ service }));
    };

    // update nearby places - geo locations
    const updateNearbyGeoLocations = (payload: INearbySearchGeoLocation[]) => {
        dispatch(hotelSlice.actions.updateNearbyGeoLocations(payload));
    };
    // reset nearby places - geo locations
    const resetNearbyGeoLocations = () => {
        dispatch(hotelSlice.actions.resetNearbyGeoLocations());
    };


    return {
        handleShowToast,
        handleUpdateHotelData,
        updateSearchedHotelList,
        handleHotelFilters,
        handleResetHotelFilters,
        updateHotelFilterSelectedNearbySearchGeoLocation,
        handleHotelFilterSearch,
        updateTraceId,
        updateNoOfRooms,
        removeRoomGuest,
        resetRoomGuestInfo,
        updateRoomGuest,
        updateWhereTo,
        removeWhereTo,
        updateGeoLocation,
        removeGeoLocation,
        updateHotelSearch,
        resetFilters,
        resetRoomGuest,
        updateSelectedHotel,
        removeSelectedHotel,
        updateSelectedRoom,
        removeSelectedRoom,
        updateHotelBedsRoomTypes,
        updateLeadPassenger,
        handleHotelFilterReset,
        handleSpecificHotelFilterReset,
        resetPassengerInfo,
        updateBookingPassenger,
        resetBookingPassenger,
        updateMarkup,
        updateAffiliateMarkup,
        resetMarkups,
        resetMetadataCharges,
        updateDestinations,
        updatePaginationInfo,
        resetPaginationInfo,
        handleHotelListingInfo,
        handleHotelListingInfoReset,
        updateNearbyGeoLocations,
        resetNearbyGeoLocations,
        resetSelectedNearbySearchGeoLocationFromFilters,
        resetSearchedHotelList
    };

};
