import { ILstCharges, IMarkup, TypeHotelServices } from "./../types/hotelTypes";
import {
  ICombination,
  IDynamicHotelRate,
  IMyCharges,
  IRoomGuests,
  IStaticHotelRoomInfo,
  ITBORoom,
} from "./../../redux/types";
import moment from "moment";
import { getTotalGuestCountForHotel } from "./hotelUtitlityFunctions";
/* 
  check distance of two co-ordinates
  0.1 is equal to 100 meters
  */
export const getDistance = (
  coords1: { lat: number; lon: number },
  coords2: { lat: number; lon: number }
) => {
  let { lat: lat1, lon: lon1 } = coords1;
  let { lat: lat2, lon: lon2 } = coords2;

  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  const degToRad = (x: number) => (x * Math.PI) / 180;

  // Haversine formula
  let halfdlon = degToRad(lon2 - lon1) / 2;
  let halfdlat = degToRad(lat2 - lat1) / 2;
  let a =
    Math.pow(Math.sin(halfdlat), 2) +
    Math.cos(degToRad(lat1)) *
    Math.cos(degToRad(lat2)) *
    Math.pow(Math.sin(halfdlon), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956 for miles
  let R = 6371;

  // return the result
  return `${Math.round(R * c)}km`;
};

// generates a random id of length(param) values;
export const randomIdGenerator = (length: number) => {
  let id = "";
  for (let i = 0; i < length; i++) {
    let keys = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    id += keys[Math.floor(Math.random() * (62 - 0) + 0)];
  }
  return id;
};
// creates html string
export const createMarkup = (html: string | undefined) => {
  return { __html: html };
};

export const handleFormValidation = (formData: {
  FirstName: string;
  LastName: string;
  Email: string;
  Mobile: string;
  Password: string;
  confirmPassword: string;
}) => {
  let result: {
    isValidated: Boolean;
    errorMessage?: string;
  } = {
    isValidated: true,
  };
  if (formData.FirstName.length < 3) {
    result = {
      isValidated: false,
      errorMessage: "First Name must be of 3 characters",
    };
    return result;
  }
  if (formData.LastName.length < 3) {
    result = {
      isValidated: false,
      errorMessage: "Last Name must be of 3 characters",
    };
    return result;
  }
  if (
    formData.Email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      // /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) === null
  ) {
    result = {
      isValidated: false,
      errorMessage: "Please provide a valid email.",
    };
    return result;
  }
  if (formData.Password !== formData.confirmPassword) {
    result = {
      isValidated: false,
      errorMessage: "Passwords do not match.",
    };
    return result;
  }
  // result.isValidated =
  //   isFirstNameValidated &&
  //   formData.LastName.length > 3 &&
  //   formData.Email.match(
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //     // /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //   ) !== null &&
  //   formData.Password === formData.confirmPassword;

  return result;
};

// Create our price formatter
export const priceFormatter = (value: number, currencyType?: string) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyType ? currencyType : "INR",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
};

// slice string upto specific length and end of a word
export const splitString = (str: string, length: number) => {
  if (str?.length <= length) {
    return str;
  }
  return str?.slice(
    0,
    Math.min(
      str?.slice(0, length).length,
      str?.slice(0, length).lastIndexOf(" ")
    )
  );
};

// remove special characters from string
export const removeSpecialCharacters = (str: string) => {
  return str.replace(/[`~!@#$%^&*()_|=;<>]/gi, "");
};


/*
* MARKUP CALCULATION STARTS
* Calculates base price, service price and booking price and GST based on markup charges data
*/
export const getMarkedUpPrice = (
  markup: any[], //response data []
  price: number, //price to be marked up, i.e actual price from supplier
  supplier: string, //supplier code such as "Hotelbeds" || "TBO"
  GSTRate: number,
  affiliateMarkup: any[], // affiliate markup response data,
  RoomGuest: IRoomGuests[]
) => {

  let lstCharges: ILstCharges[] = [];
  let markedUpPrice: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };
  let calculatedAdminMarkup: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };
  let calculatedAffiliateMarkup: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };
  let adminMarkupBreakup: IMarkup[] = [];
  let affiliateMarkupBreakup: IMarkup[] = [];

  /*
  * checking whether the "price" is greater than "GrossPriceFrom"
  * and less than "GrossPriceTo". We consider one markup that fulfills this
  * condition from the "lstCharges" array. "lstCharges" may contain more 
  * than one markup since some destinations may have multiple markups set to it 
  */

  // For B2C and Admin Markup
  if (markup.length !== 0) {
    lstCharges = [];
    lstCharges = markup?.filter(
      (mark) =>
        String(mark?.SupplierNames).split(",").includes(supplier)
        &&
        mark?.GrossPriceFrom < price
        &&
        mark?.GrossPriceTo > price
    )?.[0]?.lstCharges;
    calculatedAdminMarkup = calculateMarkup(price, GSTRate, RoomGuest, markup, lstCharges);
    adminMarkupBreakup = calculateMarkupPerPax(calculatedAdminMarkup, RoomGuest);
  }

  // For Affiliate Markup
  if (affiliateMarkup && affiliateMarkup.length !== 0) {
    lstCharges = [];
    lstCharges = affiliateMarkup.filter(
      (mark) =>
        String(mark?.SupplierNames).split(",").includes(supplier)
        &&
        mark?.GrossPriceFrom < price
        &&
        mark?.GrossPriceTo > price
    )?.[0]?.lstCharges;
    calculatedAffiliateMarkup = calculateMarkup(price, GSTRate, RoomGuest, affiliateMarkup, lstCharges);
    affiliateMarkupBreakup = calculateMarkupPerPax(calculatedAffiliateMarkup, RoomGuest);
  }

  let markupIterator = adminMarkupBreakup.length
    ?
    adminMarkupBreakup.length
    :
    affiliateMarkupBreakup.length
      ?
      affiliateMarkupBreakup.length
      :
      0;

  let markupBreakUp: IMarkup[] = Array.from(Array(markupIterator).keys())?.map(
    (_, index) => Object.assign({
      baseMarkup: (adminMarkupBreakup[index]?.baseMarkup || 0) + (affiliateMarkupBreakup[index]?.baseMarkup || 0),
      Service: (adminMarkupBreakup[index]?.Service || 0) + (affiliateMarkupBreakup[index]?.Service || 0),
      Booking: (adminMarkupBreakup[index]?.Booking || 0) + (affiliateMarkupBreakup[index]?.Booking || 0),
      GST: (adminMarkupBreakup[index]?.GST || 0) + (affiliateMarkupBreakup[index]?.GST || 0)
    }));

  // let calculatedMarkupBreakup: IMarkup = {
  //   Base: price,
  //   baseMarkup: 0,
  //   Service: 0,
  //   Booking: 0,
  //   GST: 0
  // };
  // markupBreakUp?.forEach(each => {
  //   calculatedMarkupBreakup.Base += (each.baseMarkup + each.GST);
  //   calculatedMarkupBreakup.baseMarkup += each.baseMarkup;
  //   calculatedMarkupBreakup.Booking += each.Booking;
  //   calculatedMarkupBreakup.Service += each.Service;
  //   calculatedMarkupBreakup.GST += each.GST;
  // });

  // console.group("Markup -> ");
  // // console.table(adminMarkupBreakup);
  // // console.table(affiliateMarkupBreakup);
  // console.table(markupBreakUp);
  // // console.table(calculatedMarkupBreakup);
  // console.groupEnd();

  markedUpPrice.Base += (calculatedAdminMarkup.baseMarkup + calculatedAffiliateMarkup.baseMarkup + calculatedAdminMarkup.GST + calculatedAffiliateMarkup.GST);
  markedUpPrice.baseMarkup = calculatedAdminMarkup.baseMarkup + calculatedAffiliateMarkup.baseMarkup;
  markedUpPrice.Service = calculatedAdminMarkup.Service + calculatedAffiliateMarkup.Service;
  markedUpPrice.Booking = calculatedAdminMarkup.Booking + calculatedAffiliateMarkup.Booking;
  markedUpPrice.GST = calculatedAdminMarkup.GST + calculatedAffiliateMarkup.GST;

  // console.group("Calculate Markup: ");
  // console.log("Admin Markup: ", calculatedAdminMarkup);
  // console.log("Affiliate Markup: ", calculatedAffiliateMarkup);
  // console.table(markedUpPrice);
  // console.groupEnd();

  return {
    markedUpPrice,
    markupBreakUp
  };
};

const calculateMarkup = (
  price: number,
  GSTRate: number,
  RoomGuest: IRoomGuests[],
  markup: any[],
  lstCharges: ILstCharges[],
): IMarkup => {
  const obj: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };

  if (markup?.length > 0 && lstCharges?.length > 0) {
    lstCharges?.forEach((charges) => {

      let chargeToBeAdded: number = 0;

      // calculating price based on fee type (Fixed or Percentage)
      // 1 - Fixed amount
      // 2 - Percentage
      if (charges?.MarkupFeeTypeId === 1) {
        chargeToBeAdded = charges?.FeeValue;
      } else {
        chargeToBeAdded = +price * (charges?.FeeValue / 100);
      };

      // ApplyForId = 1 (Per Booking), if 1, no need to do anything
      // ApplyForId = 2 (Per Passenger)
      if (markup[0]?.ApplyForId === 2) {
        chargeToBeAdded *= getTotalGuestCountForHotel(RoomGuest);
      };

      // calculate GST on "chargeToBeAdded"
      if (!charges?.IsGSTAppplied) {
        obj.GST += chargeToBeAdded * (GSTRate / 100);
      };

      if (charges?.MarkupFeeId === 1) {
        // Base charges
        obj.Base += chargeToBeAdded;
        obj.baseMarkup = chargeToBeAdded;
      } else if (charges?.MarkupFeeId === 2) {
        // Service Charges
        obj.Service = chargeToBeAdded;
      } else if (charges?.MarkupFeeId === 3) {
        // Booking charges
        obj.Booking = chargeToBeAdded;
      };

    });
    // calculated GST is added to the Base
    // obj.Base += obj.GST;
    // console.log("Price: ", price, "Markup: ", obj);
  }
  return obj;
};

const calculateMarkupPerPax = (
  calculatedMarkup: IMarkup,
  roomGuest: IRoomGuests[]
) => {
  let totalGuest = getTotalGuestCountForHotel(roomGuest);
  let markupBreakupPerPax: IMarkup[] = roomGuest.map(eachRoom => Object.assign({
    Base: calculatedMarkup.Base,
    baseMarkup: (calculatedMarkup.baseMarkup / totalGuest) * getTotalGuestCountForHotel([eachRoom]),
    Service: (calculatedMarkup.Service / totalGuest) * getTotalGuestCountForHotel([eachRoom]),
    Booking: (calculatedMarkup.Booking / totalGuest) * getTotalGuestCountForHotel([eachRoom]),
    GST: (calculatedMarkup.GST / totalGuest) * getTotalGuestCountForHotel([eachRoom])
  }));
  // console.table(calculatedMarkup);
  // console.table(markupBreakupPerPax);
  return markupBreakupPerPax;
};



export const getMarkedUpPriceForCreateBooking = (
  markup: any[], //response data []
  price: number, //price to be markedup i.e actual price from supplier
  supplier: string, //supplier code such as "Hotelbeds" || "TBO"
  GSTRate: number,
  affiliateMarkup: any[], // affiliate markup response data,
  noOfPassenger: number,
  noOfRooms: number
) => {

  let lstCharges: ILstCharges[] = [];
  let markedUpPrice: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };
  let calculatedAdminMarkup: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };
  let calculatedAffiliateMarkup: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };

  /*
  * checking whether the "price" is greater than "GrossPriceFrom"
  * and less than "GrossPriceTo". "lstCharges" may contain more 
  * than one markup since some destinations may have multiple markups set to it.
  * We consider one markup that fulfills this condition from the "lstCharges" array.
  */

  // For B2C and Admin Markup
  if (markup.length !== 0) {
    lstCharges = [];
    lstCharges = markup?.filter(
      (mark) =>
        String(mark?.SupplierNames).split(",").includes(supplier)
        &&
        mark?.GrossPriceFrom < price
        &&
        mark?.GrossPriceTo > price
    )?.[0]?.lstCharges;
    calculatedAdminMarkup = calculateMarkupForCreateBooking(price, GSTRate, noOfPassenger, noOfRooms, markup, lstCharges);
  }

  // For Affiliate Markup
  if (affiliateMarkup && affiliateMarkup.length !== 0) {
    lstCharges = [];
    lstCharges = affiliateMarkup.filter(
      (mark) =>
        String(mark?.SupplierNames).split(",").includes(supplier)
        &&
        mark?.GrossPriceFrom < price
        &&
        mark?.GrossPriceTo > price
    )?.[0]?.lstCharges;
    calculatedAffiliateMarkup = calculateMarkupForCreateBooking(price, GSTRate, noOfPassenger, noOfRooms, affiliateMarkup, lstCharges);
  }

  markedUpPrice.Base += (calculatedAdminMarkup.baseMarkup + calculatedAffiliateMarkup.baseMarkup + calculatedAdminMarkup.GST + calculatedAffiliateMarkup.GST);
  markedUpPrice.baseMarkup = calculatedAdminMarkup.baseMarkup + calculatedAffiliateMarkup.baseMarkup;
  markedUpPrice.Service = calculatedAdminMarkup.Service + calculatedAffiliateMarkup.Service;
  markedUpPrice.Booking = calculatedAdminMarkup.Booking + calculatedAffiliateMarkup.Booking;
  markedUpPrice.GST = calculatedAdminMarkup.GST + calculatedAffiliateMarkup.GST;

  console.group("Calculate Markup: ");
  console.log("Price: ", price);
  console.log("Admin Markup: ", calculatedAdminMarkup);
  console.log("Affiliate Markup: ", calculatedAffiliateMarkup);
  console.log("Calculated Markup: ", markedUpPrice);
  console.groupEnd();

  return markedUpPrice;
};

const calculateMarkupForCreateBooking = (
  price: number,
  GSTRate: number,
  noOfPassenger: number,
  noOfRooms: number,
  markup: any[],
  lstCharges: ILstCharges[],
): IMarkup => {
  const obj: IMarkup = {
    Base: price,
    baseMarkup: 0,
    Service: 0,
    Booking: 0,
    GST: 0
  };

  if (markup.length > 0 && lstCharges.length > 0) {
    lstCharges?.forEach((charges) => {

      let chargeToBeAdded: number = 0;

      // calculating price based on fee type (Fixed or Percentage)
      // 1 - Fixed amount
      // 2 - Percentage
      if (charges?.MarkupFeeTypeId === 1) {
        chargeToBeAdded = charges?.FeeValue;
      } else {
        chargeToBeAdded = +price * (charges?.FeeValue / 100);
      }

      // ApplyForId = 1 (Per Booking), if 1, no need to do anything
      // ApplyForId = 2 (Per Passenger)
      if (markup[0]?.ApplyForId === 2) {// 2
        chargeToBeAdded *= noOfPassenger;
      } else if (markup[0]?.ApplyForId === 1) {// 1
        chargeToBeAdded /= noOfRooms;
      }

      // calculate GST on "chargeToBeAdded"
      if (!charges?.IsGSTAppplied) {
        obj.GST += chargeToBeAdded * (GSTRate / 100);
      }

      if (charges?.MarkupFeeId === 1) {
        // Base charges
        obj.Base += chargeToBeAdded;
        obj.baseMarkup = chargeToBeAdded;
      } else if (charges?.MarkupFeeId === 2) {
        // Service Charges
        obj.Service = chargeToBeAdded;
      } else if (charges?.MarkupFeeId === 3) {
        // Booking charges
        obj.Booking = chargeToBeAdded;
      }

    });
    // calculated GST is added to the Base
    obj.Base += obj.GST;
    // console.log("Price: ", price, "Markup: ", obj);
  }
  return obj;
};

export const handleRoomWiseMarkupBreakdownForCreateBooking = (
  calculatedMarkup: IMarkup,
  noOfRooms: number,
  roomGuest: IRoomGuests[]
) => {
  return 1;
};
/*
* MARKUP CALCULATION ENDS
*/


export const getMyChargesAmount = (
  MyCharges: IMyCharges[],
  price: number,
  numberOfPassenger: number, // number of guests per room for hotel
  numberOfJourney: number, // number of rooms for hotel
  isDomestic: boolean = true,
  isCustomer: boolean = true
) => {
  let amountsToBeAdded: number[] = [];
  if (isDomestic && isCustomer) {
    amountsToBeAdded = MyCharges.map((charges, index) => {
      return charges?.CustomerDomesticAmountTypeId === 2 //percentage
        ? price * (charges?.CustomerDomesticAmount / 100)
        : charges?.CustomerDomesticAmountTypeId === 1 //fixed
          ? charges?.CustomerDomesticApplyForId === 2 //perPassenger
            ? charges?.CustomerDomesticAmount
            : // booking
            Math.floor(
              charges?.CustomerDomesticAmount /
              (numberOfPassenger * numberOfJourney)
            )
          : 0;
    });
  }
  return amountsToBeAdded.reduce((sum, a) => sum + a, 0);
};

export const formatDateToISO = (date: string) => {
  return moment(date).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
};

// make it work for nested keys
export const groupByKeys = (array: any[], keys: string[]) => {
  return array.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[keys[0]]]: (hash[obj[keys[0]]] || []).concat(obj),
    }),
    {}
  );
};

export const filterObjects = (object: any, filterKeys: string[]) => {
  const allKeys = Object.keys(object);
  let result: any;
  allKeys.forEach((key) => {
    if (filterKeys.includes(key)) {
      result = { ...result, [key]: object[key] };
    }
  });
  return result;
};

/**
 * `showInMapClicked` is a function that takes in two numbers and opens a new tab in the browser to the
 * Google Maps page for those numbers
 * @param {number} lat - latitude of the location
 * @param {number} lng - The longitude of the location.
 */
export const showInMapClicked = (lat: number, lng: number) => {
  window.open("https://maps.google.com?q=" + lat + "," + lng);
};

export const createUniqueId = (length?: number) => {
  let d = Date.now();
  return (
    [...Array(16)]
      .map((e) => ((Math.random() * 36) | 0).toString(36))
      .join("") + d
  );
};

/**
 * Calculate the base charge for the given rate combination
 * @param {any[]} markup - an array of objects that contains the markup information.
 * @param {IDynamicHotelRate[]} rateCombination - IDynamicHotelRate[]
 * @param {string} supplier - The supplier for which the markup is being calculated.
 * @param {string} currency - string
 * @returns The base price of the hotel.
 */
export const calculateMarkedupBaseCharge = (
  markup: any[],
  affiliateMarkup: any[],
  rateCombination: IDynamicHotelRate[] | ITBORoom[],
  supplier: string,
  currency: string,
  GSTPercentage: number,
  RoomGuest: IRoomGuests[],
  service?: TypeHotelServices,
) => {
  // if service !== null, then called for HotelBeds. Else, called for TBO
  if (!service) {
    let combination = rateCombination as IDynamicHotelRate[];
    // let markup_data = combination?.map((rate: IDynamicHotelRate) => {
    //   return getMarkedUpPrice(markup, +rate.net, supplier, +GSTPercentage, affiliateMarkup, noOfPassenger);
    // });
    // let base_price = markup_data.reduce((accu, curr) => (accu += Number(curr.Base)), 0);
    let rate = combination?.reduce((accu, curr) => accu += Number(curr?.net), 0);
    let base_price = getMarkedUpPrice(markup, rate, supplier, +GSTPercentage, affiliateMarkup, RoomGuest).markedUpPrice
    return priceFormatter(base_price.Base, currency);
  } else {
    let combination = rateCombination as ITBORoom[];
    // let markup_data = combination?.map((rate: ITBORoom) => {
    //   return getMarkedUpPrice(markup, +rate.Price.OfferedPrice, supplier, GSTPercentage, affiliateMarkup, RoomGuest).markedUpPrice;
    // });
    // let base_price = markup_data.reduce((accu, curr) => (accu += curr.Base), 0);
    // console.log("base_price", base_price, markup_data);
    let rate = combination?.reduce((accu, curr) => accu += Number(curr?.Price?.OfferedPrice), 0);
    let base_price = getMarkedUpPrice(markup, rate, supplier, +GSTPercentage, affiliateMarkup, RoomGuest).markedUpPrice
    return priceFormatter(base_price.Base, currency);
  }
};

/**
 * Given an array of hotel room info, return a dictionary of room type and the number of times it
 * occurs in the array
 * @param {IStaticHotelRoomInfo[]} rateCombination - IStaticHotelRoomInfo[]
 * @returns A dictionary with the room type as the key and the number of times it occurs as the value.
 */
export const findOccuranceCount = (rateCombination: IStaticHotelRoomInfo[]) => {
  const arr = rateCombination.map((each) =>
    each?.roomTypeName?.toLocaleLowerCase() || each?.roomType
  );

  const count: { [x: string]: number } = {};

  for (const element of arr) {
    if (count[element]) {
      count[element] += 1;
    } else {
      count[element] = 1;
    }
  };

  return count;
};


/**
 * Given an array of hotel room info, return a dictionary of room type and the number of times it
 * occurs in the array
 * @param {IStaticHotelRoomInfo[]} rateCombination - IStaticHotelRoomInfo[]
 * @returns A dictionary with the room type as the key and the number of times it occurs as the value.
 */
export const findOccurrenceCountForRates = (rateCombinations: IDynamicHotelRate[]) => {

  const arr = rateCombinations?.map((each) =>
    each.roomName.toLocaleLowerCase()
  );

  const count: { [x: string]: number } = {};

  for (const element of arr) {
    if (count[element]) {
      count[element] += 1;
    } else {
      count[element] = 1;
    }
  };

  return count;

};

/**
 * Given a current page, total number of pages, and a range, return an array of page numbers that are
 * within the range of the current page
 * @param {number} currentPage - The current page number.
 * @param {number} totalPage - The total number of pages.
 * @param {number} range - The number of pages to show on each side of the current page.
 * @returns The array of pages that are within the range of the current page.
 */
export const createPaginatedArray = (
  currentPage: number,
  totalPage: number,
  range: number
) => {
  let pages = [
    ...Array(totalPage)
      .fill("")
      .map((_, i) => i + 1),
  ];
  let startIndex = 0,
    endIndex = totalPage - 1;
  let remainingStartIndex = 0,
    remainingEndIndex = 0;

  if (2 * range + 1 > totalPage) {
    return pages;
  }

  if (range - currentPage >= 0) {
    remainingStartIndex = range - currentPage;
  } else {
    startIndex = currentPage - range;
  }

  if (range + currentPage >= totalPage) {
    remainingEndIndex = currentPage + range - totalPage + 1;
  } else {
    endIndex = currentPage + range;
  }

  return pages.slice(
    startIndex - remainingEndIndex,
    endIndex + remainingStartIndex + 1
  );
};

export const isValidEmail = (str: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g.test(str);
}
export const getUniqueValuedArray = (array: any[]) => {
  return array.filter((value, index, self) => self.indexOf(value) === index);
};

export const combineAndFormatDateTime = (
  date: string,
  time: string
) => {

  if (!date || !time) {
    return "";
  };

  // Merge date and time into the required format
  const formattedDateTime = moment(`${date} ${time}`, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');

  return formattedDateTime;

};


export function isObject(value: any) {
  // Check if the value is not null and its type is 'object'
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};