import React, { useState } from "react";
import { useHistory } from "react-router";

import Text from "@kiwicom/orbit-components/lib/Text";
import Alert, { AlertButton } from "@kiwicom/orbit-components/lib/Alert";
import Badge from "@kiwicom/orbit-components/lib/Badge";
import Slider from "@kiwicom/orbit-components/lib/Slider";
import Stack from "@kiwicom/orbit-components/lib/Stack";
import InputField from "@kiwicom/orbit-components/lib/InputField";
import InputFile from "@kiwicom/orbit-components/lib/InputFile";
import ChoiceGroup from "@kiwicom/orbit-components/lib/ChoiceGroup";
import HorizontalScroll from "@kiwicom/orbit-components/lib/HorizontalScroll";
import Collapse from "@kiwicom/orbit-components/lib/Collapse";
import Dialog from "@kiwicom/orbit-components/lib/Dialog";
import InputGroup from "@kiwicom/orbit-components/lib/InputGroup";
import Select from "@kiwicom/orbit-components/lib/Select";
import CountryFlag from "@kiwicom/orbit-components/lib/CountryFlag";
import InputStepper from "@kiwicom/orbit-components/lib/InputStepper";
import NotificationBadge from "@kiwicom/orbit-components/lib/NotificationBadge";
import Pagination from "@kiwicom/orbit-components/lib/Pagination";
import PictureCard from "@kiwicom/orbit-components/lib/PictureCard";
import Popover from "@kiwicom/orbit-components/lib/Popover";
import Stepper from "@kiwicom/orbit-components/lib/Stepper";
import ListChoice from "@kiwicom/orbit-components/lib/ListChoice";
import Radio from "@kiwicom/orbit-components/lib/Radio/index";
import Seat from "@kiwicom/orbit-components/lib/Seat/index";
import Breadcrumbs from "@kiwicom/orbit-components/lib/Breadcrumbs/index";
import BreadcrumbsItem from "@kiwicom/orbit-components/lib/Breadcrumbs/BreadcrumbsItem/index";
import Switch from "@kiwicom/orbit-components/lib/Switch/index";
import Table from "@kiwicom/orbit-components/lib/Table/index";
import TableBody from "@kiwicom/orbit-components/lib/Table/TableBody/index";
import TableCell from "@kiwicom/orbit-components/lib/Table/TableCell/index";
import TableRow from "@kiwicom/orbit-components/lib/Table/TableRow/index";
import TableHead from "@kiwicom/orbit-components/lib/Table/TableHead/index";
import Timeline from "@kiwicom/orbit-components/lib/Timeline/index";
import TimelineStep from "@kiwicom/orbit-components/lib/Timeline/TimelineStep/index";
import Wizard from "@kiwicom/orbit-components/lib/Wizard/index";
import WizardStep from "@kiwicom/orbit-components/lib/Wizard/WizardStep";
import BadgeList, {
  BadgeListItem,
} from "@kiwicom/orbit-components/lib/BadgeList";
import {
  Airplane,
  AlertCircle,
  CloseCircle,
  Passport,
  PlusCircle,
  SelfTransfer,
  Sightseeing,
  ChevronDoubleLeft,
  ChevronLeft,
  Search,
  ChevronDown,
  Accommodation,
  Lock,
} from "@kiwicom/orbit-components/lib/icons";

import Layout from "./Layout";
import Tooltip from "@kiwicom/orbit-components/lib/Tooltip";
import Button from "@kiwicom/orbit-components/lib/Button";
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox";
import TextLink from "@kiwicom/orbit-components/lib/TextLink";

import Wrapper from "./Wrapper";

export default function Components() {
  const [showDialog, setShowDialog] = React.useState<boolean>(false); // for showing dialog

  const [name, setName] = useState<string>(""); // for input field
  const handleSetName = (e: React.FormEvent<HTMLInputElement>) => {
    // setName(e || "");
  };

  const history = useHistory();
  return (
    <div className="container">
      <div className="d-flex p-2" style={{ alignItems: "center" }}>
        <button className="btn btn-none" onClick={() => history.goBack()}>
          <ChevronLeft />
        </button>
        <h3>Components</h3>
      </div>

      <div>
        {/* FOR ALERT */}
        <Layout title={"Alert"} subtitle={"Success Alert"}>
          <div className="my-2">
            <Alert icon title="Booking Successfull!" type="success">
              Your booking has been successfully processed.
            </Alert>
          </div>
          <div className="my-2">
            <Alert icon title="Payment Failed!" type="critical">
              Payment could not be completed.
            </Alert>
          </div>
          <div className="my-2">
            <Alert icon title="Select Payment method">
              You can select your preferred payment method below.
            </Alert>
          </div>
          <div className="my-2">
            <Alert icon title="Be quick!" type="warning">
              The seats are filling up fast.
            </Alert>
          </div>
          <div className="my-2">
            <Alert closable title="alert with close button." />
          </div>
        </Layout>

        {/* FOR BADGE */}
        <Layout title={"Badge"} subtitle={"Primary Badge with Icon"}>
          <div className="layout-wrapper">
            <Wrapper label={"Normal badge"}>
              <Badge
                ariaLabel="test"
                dataTest="test"
                icon={<Airplane />}
                type="info"
              >
                Badge
              </Badge>
            </Wrapper>
            <Wrapper label={"Neutral badge"}>
              <Badge icon={<Airplane />} type="neutral">
                Content here{" "}
              </Badge>
            </Wrapper>
            <Wrapper label={"Dark badge"}>
              <Badge icon={<Sightseeing />} type="dark">
                Badge
              </Badge>
            </Wrapper>
            <Wrapper label={"Critical badge"}>
              <Badge icon={<Passport />} type="critical">
                Badge
              </Badge>
            </Wrapper>
          </div>
        </Layout>

        {/* FOR Breadcrumbs*/}
        <Layout title="Breadcrumbs" subtitle="to show the progress">
          <Breadcrumbs onGoBack={() => {}}>
            <BreadcrumbsItem href="/" onClick={() => {}}>
              Flights
            </BreadcrumbsItem>
            <BreadcrumbsItem href="#1" onClick={() => {}}>
              Search
            </BreadcrumbsItem>
            <BreadcrumbsItem onClick={() => {}}>Personnal Info</BreadcrumbsItem>
            <BreadcrumbsItem href="#3">Payment</BreadcrumbsItem>
            <BreadcrumbsItem>Booking</BreadcrumbsItem>
          </Breadcrumbs>
        </Layout>

        {/* FOR BUTTONS */}
        <Layout title={"Buttons"} subtitle={"Different Types of Buttons"}>
          <div className="layout-wrapper">
            <Wrapper label={"Primary button"}>
              <Button onClick={() => {}} size="normal" type="primary">
                Primary Button
              </Button>
            </Wrapper>
            <Wrapper label={"Secondary button with icon"}>
              <Button
                iconLeft={<PlusCircle />}
                onClick={() => {}}
                size="normal"
                type="secondary"
              >
                Icon Button
              </Button>
            </Wrapper>
            <Wrapper label={"Subtle button"}>
              <Button
                iconLeft={<CloseCircle />}
                onClick={() => {}}
                size="normal"
                type="criticalSubtle"
              >
                Subtle Button
              </Button>
            </Wrapper>
            <Wrapper label={"Circular button"}>
              <Button
                circled
                iconLeft={<Airplane />}
                onClick={() => {}}
                size="normal"
                title="Button"
                type="primarySubtle"
              />
            </Wrapper>
          </div>
        </Layout>

        {/* FOR BADGELIST */}
        <Layout title={"BadgeList"} subtitle={""}>
          <BadgeList dataTest="test">
            <BadgeListItem icon={<AlertCircle />} type="neutral">
              Information can be shown here
            </BadgeListItem>
            <BadgeListItem icon={<SelfTransfer />} type="neutral">
              <Tooltip content="Additional information goes here">
                <Text>Some additional information</Text>
              </Tooltip>{" "}
              which can be shown here
            </BadgeListItem>
          </BadgeList>
        </Layout>

        {/* FOR CHECKBOXES */}
        <Layout title={"Checkboxes"} subtitle={""}>
          <div className="layout-wrapper">
            <Wrapper label={"Normal Chackbox"}>
              <Checkbox
                info="Additional information about this choice"
                label="Label"
                onChange={() => {}}
                value="value"
              />
            </Wrapper>
            <Wrapper label={"Checkbox with link"}>
              <Checkbox
                checked
                label={
                  <Text>
                    This is the section for some text{" "}
                    <TextLink
                      external
                      href="https://www.google.com/"
                      stopPropagation
                    >
                      Some Link
                    </TextLink>
                    .
                  </Text>
                }
                onChange={() => {}}
                value="value"
              />
            </Wrapper>
          </div>
        </Layout>

        {/* FOR COLLAPSES */}
        <Layout title={"Collapses"}>
          <div className="layout-wrapper">
            <Wrapper label={"Collapse with acion"}>
              <Collapse
                actions={
                  <TextLink onClick={() => {}} size="small" type="secondary">
                    Clear
                  </TextLink>
                }
                label="Transportation"
              >
                <ChoiceGroup
                  filter
                  onChange={() => {}}
                  onOnlySelection={() => {}}
                >
                  <Checkbox label="Flight" value="one" />
                  <Checkbox label="Bus" value="two" />
                  <Checkbox label="Train" value="three" />
                </ChoiceGroup>
              </Collapse>
            </Wrapper>
            <Wrapper label={"Normal Collapse"}>
              <Collapse label="Duration">
                <Slider
                  defaultValue={[1, 12]}
                  histogramData={[
                    11, 25, 37, 5, 21, 27, 24, 33, 16, 21, 22, 2, 11, 25, 37, 5,
                    21, 27, 24, 33, 16, 21, 22, 2,
                  ]}
                  label="Max travel time"
                  maxValue={24}
                  minValue={1}
                  valueDescription="00:00 - 24:00"
                />
              </Collapse>
            </Wrapper>
            <Wrapper label={"Multiple collapse"}>
              <Stack spacing="none">
                <Collapse label="Transportation">
                  <ChoiceGroup
                    filter
                    onChange={() => {}}
                    onOnlySelection={() => {}}
                  >
                    <Checkbox label="Flight" value="one" />
                    <Checkbox label="Bus" value="two" />
                    <Checkbox label="Train" value="three" />
                  </ChoiceGroup>
                </Collapse>
                <Collapse initialExpanded label="Transportation">
                  <ChoiceGroup
                    filter
                    onChange={() => {}}
                    onOnlySelection={() => {}}
                  >
                    <Checkbox label="Flight" value="one" />
                    <Checkbox label="Bus" value="two" />
                    <Checkbox label="Train" value="three" />
                  </ChoiceGroup>
                </Collapse>
                <Collapse label="Transportation">
                  <ChoiceGroup
                    filter
                    onChange={() => {}}
                    onOnlySelection={() => {}}
                  >
                    <Checkbox label="Flight" value="one" />
                    <Checkbox label="Bus" value="two" />
                    <Checkbox label="Train" value="three" />
                  </ChoiceGroup>
                </Collapse>
              </Stack>
            </Wrapper>
          </div>
        </Layout>

        {/* FOR DIALOG */}
        <Layout title={"Dialog"}>
          <Wrapper label={"Normal dialog"}>
            <Button type="primary" onClick={() => setShowDialog(true)}>
              Show Dialog
            </Button>
            {showDialog && (
              <Dialog
                dataTest="test"
                description="Are you sure you want to proceed?"
                primaryAction={
                  <Button type="primary" onClick={() => setShowDialog(false)}>
                    Yes
                  </Button>
                }
                secondaryAction={
                  <Button type="critical" onClick={() => setShowDialog(false)}>
                    No
                  </Button>
                }
                title="Confirmation!"
              />
            )}
          </Wrapper>
        </Layout>

        {/* FOR ERROR TYPE INPUTS */}
        <Layout title={"Error Inputs"}>
          {/* <div className="layout-wrapper"> */}
          <Wrapper label={"Text input"}>
            <InputField
              error={
                name === "" && (
                  <TextLink tabIndex={0}>Something went wrong.</TextLink>
                )
              }
              label="Label"
              onChange={(e) => {
                handleSetName(e);
              }}
              placeholder="Placeholder"
              size="normal"
            />
          </Wrapper>
          <Wrapper label={"File input"}>
            <InputFile
              error="Something went wrong."
              label="Label"
              onRemoveFile={() => {}}
            />
          </Wrapper>
          {/* </div> */}
        </Layout>

        {/* HORIZANTAL SCROLL */}
        <Layout title={"Horizantal Scroll"}>
          <HorizontalScroll scrollPadding={0} scrollSnap="mandatory">
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  0
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  1
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  2
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  3
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  4
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  5
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  6
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  7
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  8
                </div>
              </Text>
            </div>
            <div
              style={{
                background: "#ccc",
                display: "flex",
                height: "50px",
                justifyContent: "center",
                scrollSnapAlign: "start",
                width: "150px",
              }}
            >
              <Text as="div" size="large" weight="bold">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "50px",
                  }}
                >
                  9
                </div>
              </Text>
            </div>
          </HorizontalScroll>
        </Layout>

        {/* FOR INPUT FIELDS */}
        <Layout title={"Inputs"}>
          <Wrapper label={"Input with prefix"}>
            <InputField
              label="Label"
              onChange={() => {}}
              placeholder="Placeholder"
              prefix={<Search />}
            />
          </Wrapper>
          <Wrapper label={"Number input"}>
            <InputField
              label="Number"
              maxValue={3}
              minValue={1}
              onChange={() => {}}
              placeholder="Number"
              type="number"
              value={2}
            />
          </Wrapper>
          <Wrapper label={"Password input"}>
            <InputField
              label="Password"
              onChange={() => {}}
              placeholder="Password"
              type="password"
              // value="p422W0rd"
            />
          </Wrapper>
          <Wrapper label={"Email input"}>
            <InputField
              help={
                <div>
                  Input format{" "}
                  <TextLink onClick={() => {}}>name@example.com</TextLink>?
                </div>
              }
              label="Email"
              onChange={() => {}}
              placeholder="Email"
              type="email"
              // value="name@example.co"
            />
          </Wrapper>
          <Wrapper label={"File input"}>
            <InputFile
              label="Label"
              onBlur={() => {}}
              onChange={() => {}}
              onFocus={() => {}}
              onRemoveFile={() => {}}
            />
          </Wrapper>
          <Wrapper label={"Date input"}>
            <InputGroup
              flex={["0 0 60px", "1 1 100%", "0 0 90px"]}
              label="Date of birth"
              onBlur={() => {}}
              onBlurGroup={() => {}}
              onChange={() => {}}
              onFocus={() => {}}
            >
              <InputField
                onBlur={() => {}}
                onChange={() => {}}
                onFocus={() => {}}
                placeholder="DD"
              />
              <Select
                options={[
                  {
                    label: "January",
                    value: "January",
                  },
                  {
                    label: "February",
                    value: "February",
                  },
                  {
                    label: "March",
                    value: "March",
                  },
                  {
                    label: "April",
                    value: "April",
                  },
                  {
                    label: "May",
                    value: "May",
                  },
                  {
                    label: "June",
                    value: "June",
                  },
                  {
                    label: "July",
                    value: "July",
                  },
                  {
                    label: "August",
                    value: "August",
                  },
                  {
                    label: "September",
                    value: "September",
                  },
                  {
                    label: "October",
                    value: "October",
                  },
                  {
                    label: "November",
                    value: "November",
                  },
                  {
                    label: "December",
                    value: "December",
                  },
                ]}
                placeholder="Month"
              />
              <InputField placeholder="YYYY" />
            </InputGroup>
          </Wrapper>
          <Wrapper label={"Phone input"}>
            <InputGroup
              flex={["0 0 110px", "1 1 100%"]}
              onBlur={() => {}}
              onChange={() => {}}
              onFocus={() => {}}
            >
              <Select
                customValueText="+91"
                options={[
                  {
                    label: "India (+91)",
                    value: 0,
                  },
                  {
                    label: "Czech Republic (+420)",
                    value: 1,
                  },
                  {
                    label: "Slovak Republic (+421)",
                    value: 2,
                  },
                  {
                    label: "United States (+1)",
                    value: 3,
                  },
                ]}
                prefix={<CountryFlag code="in" />}
                value={0}
              />
              <InputField maxLength={11} placeholder="e.g. 123 456 789" />
            </InputGroup>
          </Wrapper>
        </Layout>

        {/* FOR INPUT STEPPER */}
        <Layout title={"Steppers"}>
          <div className="layout-wrapper">
            <Wrapper label={""}>
              <InputStepper
                help="You need to enter count of adults between 1 and 10"
                maxValue={10}
                minValue={1}
                label="Adults"
                onBlur={() => {}}
                onChange={() => {}}
                onFocus={() => {}}
                size="normal"
                titleDecrement="Remove a passenger"
                titleIncrement="Add a passenger"
              />
            </Wrapper>
          </div>
        </Layout>

        {/* FOR NOTIFICATION */}
        <Layout title={"Notification"}>
          <div className="layout-wrapper">
            <Wrapper label={"Info"}>
              <NotificationBadge type="info">10</NotificationBadge>
            </Wrapper>
            <Wrapper label={"Critical"}>
              <NotificationBadge type="critical">13</NotificationBadge>
            </Wrapper>
            <Wrapper label={"Info inverted"}>
              <NotificationBadge type="infoInverted">17</NotificationBadge>
            </Wrapper>
            <Wrapper label={"Dark"}>
              <NotificationBadge type="dark">6</NotificationBadge>
            </Wrapper>
            <Wrapper label={"With icon"}>
              <NotificationBadge
                ariaLabel="additional information for screen readers"
                dataTest="test"
                icon={<Airplane />}
                type="info"
              >
                2
              </NotificationBadge>
            </Wrapper>
          </div>
        </Layout>

        {/* FOR PAGINATION */}
        <Layout title={"Pagination"}>
          <Wrapper label={""}>
            <Pagination
              dataTest="test"
              onPageChange={() => {}}
              pageCount={6}
              selectedPage={2}
              size="normal"
            />
          </Wrapper>
        </Layout>

        {/* FOR PICTURED CARD */}
        <Layout title={"Card"}>
          <Wrapper label={"Plain card"}>
            <PictureCard
              image={{
                code: "athens_gr",
                name: "athens_gr",
                original: "385x320",
                placeholder: "385x320",
              }}
              // label="Moscow"
            >
              {/* from 2,563 Kč */}
            </PictureCard>
          </Wrapper>
          <div className="layout-wrapper">
            <Wrapper label={"Without action button"}>
              <PictureCard
                dataTest="test"
                height="300px"
                image={{
                  code: "london_ld",
                  name: "london_ld",
                  original: "385x320",
                  placeholder: "385x320",
                }}
                label="Family fun"
                onClick={() => {}}
                subTitle=""
                tabIndex="0"
                title="London"
                width="400px"
              >
                5,563 Kč
              </PictureCard>
            </Wrapper>
            <Wrapper label={"With some action button"}>
              <PictureCard
                actions={<Button type="secondary">Action</Button>}
                href="#"
                image={{
                  code: "moscow_ru",
                  name: "moscow_ru",
                  original: "385x320",
                  placeholder: "385x320",
                }}
                label="Moscow"
                onClick={() => {}}
                subTitle="Moscow"
                tabIndex="0"
                title="Moscow"
                width="400px"
              >
                from 2,563 Kč
              </PictureCard>
            </Wrapper>
          </div>
        </Layout>

        {/* FOR POPOVERS */}
        <Layout title={"Popovers"}>
          <div className="layout-wrapper">
            <Wrapper label={"Counter popover"}>
              <Popover
                content={
                  <Stack>
                    <React.Fragment key=".0">
                      <Stack align="center">
                        <Stack spacing="none">
                          <Text>Adult</Text>
                          <Text type="secondary">11+</Text>
                        </Stack>
                        <Stepper minValue={0} />
                      </Stack>
                      <Stack align="center">
                        <Stack spacing="none">
                          <Text>Child</Text>
                          <Text type="secondary">2-11</Text>
                        </Stack>
                        <Stepper minValue={0} />
                      </Stack>
                    </React.Fragment>
                  </Stack>
                }
              >
                <Button iconRight={<ChevronDown />} type="secondary">
                  Open popover
                </Button>
              </Popover>
            </Wrapper>
            <Wrapper label={"List popover"}>
              <Popover
                content={
                  <div>
                    <ListChoice
                      description="Further description"
                      icon={<Accommodation />}
                      onClick={() => {}}
                      selectable
                      title="Choice Title"
                    />
                    <ListChoice
                      description="Further description"
                      icon={<Accommodation />}
                      onClick={() => {}}
                      selectable
                      selected
                      title="Choice Title"
                    />
                    <ListChoice
                      description="Further description"
                      icon={<Accommodation />}
                      onClick={() => {}}
                      selectable
                      title="Choice Title"
                    />
                  </div>
                }
                noPadding
                placement="bottom-start"
                width="250px"
              >
                <Button iconRight={<ChevronDown />} type="secondary">
                  Open popover
                </Button>
              </Popover>
            </Wrapper>
          </div>
        </Layout>

        {/* Radio buttons */}
        <Layout title={"Radio"}>
          <div className="d-flex justify-content-start align-items-center">
            <Radio
              // info="Select your gender"
              label="Male"
              onChange={() => {}}
              value="Male"
            />
            <Radio
              info="Select your gender"
              label="Female"
              onChange={() => {}}
              value="Female"
            />
          </div>
        </Layout>

        {/* Seats */}
        {/* <Layout title={"Seats"}>
                    <Stack direction="column">
                        <Stack align="end">
                            <Seat label="XY" onClick={() => { }} price="12$" size="medium" />
                            <Seat
                                label="XY"
                                onClick={() => { }}
                                price="12$"
                                size="medium"
                                type="unavailable"
                            />
                            <Seat
                                label="XY"
                                onClick={() => { }}
                                price="12$"
                                selected
                                size="medium"
                                type="legroom"
                            />
                        </Stack>
                        <Stack align="end">
                            <Seat
                                label="XY"
                                onClick={() => { }}
                                price="12$"
                                size="medium"
                            // type="unavailable"
                            />
                            <Seat
                                label="XY"
                                onClick={() => { }}
                                price="12$"
                                selected
                                size="medium"
                            // type="legroom"
                            />
                            <Seat label="XY" onClick={() => { }} price="12$" size="medium" />
                        </Stack>
                        <Stack align="end">
                            <Seat
                                label="XY"
                                onClick={() => { }}
                                price="12$"
                                selected
                                size="medium"
                                type="legroom"
                            />
                            <Seat label="XY" onClick={() => { }} price="12$" size="medium" />
                            <Seat
                                label="XY"
                                onClick={() => { }}
                                price="12$"
                                size="medium"
                            // type="unavailable"
                            />
                        </Stack>
                    </Stack>
                </Layout> */}

        <Layout title={"Select"}>
          <Select
            label="Number of passengers"
            onChange={function () {}}
            options={[
              {
                label: "1 person",
                value: 0,
              },
              {
                label: "2 person",
                value: 1,
              },
              {
                label: "3 person",
                value: 2,
              },
              {
                label: "4 person",
                value: 3,
              },
            ]}
            prefix={<Airplane color="secondary" />}
          />
        </Layout>

        {/* Range slider */}
        <Layout title={"Range Slider"}>
          <div
            style={{
              backgroundColor: "#f1f5f7",
              padding: "24px",
            }}
          >
            <Slider
              defaultValue={[0, 24]}
              histogramData={[
                11, 25, 37, 5, 21, 27, 24, 33, 16, 21, 22, 2, 11, 25, 37, 5, 21,
                27, 24, 33, 16, 21, 22, 2, 11,
              ]}
              histogramDescription="20 of 133 flights"
              label="Depart from Prague"
              maxValue={48}
              minValue={0}
              onChange={function () {}}
              step={2}
              valueDescription="01:00 PM – 11:59 PM"
            />
          </div>
        </Layout>

        {/* Stepper */}
        {/* <Layout title={"Stepper"}>
                    <Stepper
                        onChange={function () { }}
                        titleDecrement="Remove a passenger"
                        titleIncrement="Add a passenger"
                    />
                </Layout> */}

        {/* Switch */}
        <Layout title={"Switch"}>
          <Wrapper label={"Normal Switch"}>
            <Switch checked onChange={function () {}} />
          </Wrapper>
          <Wrapper label="With custom Icon">
            <Switch checked={false} icon={<Lock />} onChange={function () {}} />
          </Wrapper>
        </Layout>

        {/* Table */}
        <Layout title={"Table"}>
          <Table type="secondary">
            <TableHead>
              <TableRow>
                <TableCell>Header 1</TableCell>
                <TableCell>Header 2</TableCell>
                <TableCell>Header 3</TableCell>
                <TableCell>Header 4</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
                <TableCell>Lorem ipsum dolor sit amet</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Layout>

        {/* Timeline */}
        <Layout title={"Timeline"}>
          <Timeline dataTest="test">
            <TimelineStep
              label="Requested"
              subLabel="3rd May 14:04"
              type="success"
            >
              We’ve received your request and will assign it to one of our
              agents.
            </TimelineStep>
            <TimelineStep
              label="In progress"
              subLabel="4th May 10:25"
              type="success"
            >
              We’ll review your request and apply for any available refund from
              the carrier(s)
            </TimelineStep>
            <TimelineStep
              label="Waiting for the carrier"
              subLabel="5th May 15:03"
            >
              We’ll wait for the carrier(s) to send us the refund and contact
              them again if necessary.
            </TimelineStep>
            <TimelineStep label="Carrier is refunding" subLabel="6th May 20:50">
              The carrier has sent us a refund. There might be more depending on
              their policy.
            </TimelineStep>
            <TimelineStep label="Refunded" subLabel="7th May 10:30">
              We’ll forward you all refunds from the carrier(s) after we receive
              it.
            </TimelineStep>
          </Timeline>
        </Layout>

        {/* Wizard */}
        <Layout title={"wizard"}>
          <Wizard
            activeStep={3}
            completedSteps={3}
            id="wizard"
            onChangeStep={function () {}}
          >
            <WizardStep title="Search" />
            <WizardStep title="Passenger details" />
            <WizardStep title="Ticket fare" />
            <WizardStep title="Customize your trip" />
            <WizardStep title="Overview & Payment" />
          </Wizard>
        </Layout>
      </div>
    </div>
  );
}
