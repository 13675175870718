import { useState } from "react";
// import Button from "@kiwicom/orbit-components/lib/Button";
import Coupon from "@kiwicom/orbit-components/lib/Coupon";
import Alert from "@kiwicom/orbit-components/lib/Alert";
import { Close } from "@kiwicom/orbit-components/lib/icons";
import InputField from "@kiwicom/orbit-components/lib/InputField";
import Radio from "@kiwicom/orbit-components/lib/Radio";
import Loader from "@kiwicom/orbit-components/lib/Loading/index";
import Text from "@kiwicom/orbit-components/lib/Text";
import CardBase from "../../../../components/Common/CardBase/CardBase";
import Button from "../../../../components/Common/Button/Button";
import InputWithIcon from "../../../../components/Common/Input/InputWithIcon";
import InputSearch from "../../../../components/Common/Input/InputSearch";
import { priceFormatter } from "../../../../utils/utilityFunctions/utilityFunction";

interface Props {
  onAddonMarkupSubmit: (amount: number) => void;
  addonMarkup: number;
}

const AffiliateAddOnMarkup = ({ onAddonMarkupSubmit, addonMarkup }: Props) => {
  return (
    <CardBase title="Apply Add-on markup.">
      {addonMarkup !== 0 ? (
        <Alert
          icon
          title={`Successfully applied ${
            addonMarkup > 0 ? "markup" : "discount"
          } of ${priceFormatter(Math.abs(addonMarkup))}`}
          type="success"
          inlineActions={
            <button
              className="btn btn-xs border-0"
              onClick={() => {
                onAddonMarkupSubmit(0);
              }}
            >
              {/* remove */}
              <Close />
            </button>
          }
        >
          {priceFormatter(addonMarkup)}
        </Alert>
      ) : (
        <>
          <p className="small text-muted">
            Please enter the amount of markup you want to apply.
          </p>
          <InputSearch
            onSearch={(value) => {
              //   setAddOnMarkupAmount(Number(value));
              onAddonMarkupSubmit(Number(value));
            }}
            placeHolder="Enter markup amount"
            searchLabel="Apply"
          />
          <p className="text-primary fs-8 pt-2 mb-0">
            You can enter -ve amount to add discount.
          </p>
        </>
      )}
    </CardBase>
  );
};

export default AffiliateAddOnMarkup;
