import { useEffect, useState } from "react";

import BookingDetailsCard from "./Component/BookingDetailsCard";

import { IBookingInfo } from "../../redux/types";

interface IProps {
  activeSection: "flight" | "hotel";
  bookings: {
    upcoming: IBookingInfo[];
    all: IBookingInfo[];
  };
};

export default function BookingHistory({ activeSection, bookings }: IProps) {

  const [active, setActive] = useState(0); // 0 for upcoming, 1 for past booking details and 2 for cancelled booking details
  const [sortByDate, setSortByDate] = useState("");

  return (
    <div>
      <div className="booking-details p-4 ">
        <div className="d-flex justify-content-between pb-5">
          <div className="heading-text">
            My Booking / {activeSection === "flight" ? "Flight" : "Hotel"}
          </div>
          <div className="w-50 d-flex justify-content-end gap-3">
            {/* <select
              className=" border shadow-none form-select cursor-pointer"
              style={{ maxWidth: "45%" }}
              value={sortByDate}
              onChange={(e: any) => setSortByDate(e.target.value)}
            >
              <option>{"Sort By Date"}</option>
              <option>{"Latest first"}</option>
              <option>{"Oldest first"}</option>
            </select> */}

            {/* <div className="d-flex justify-content-end align-items-center position-relative">
              <input
                type="search"
                id="form1"
                className="form-control shadow-none"
                placeholder="Search by booking ID"
              />
              <BsSearch className=" position-absolute me-3" />
            </div> */}
          </div>
        </div>

        <div className="status d-flex gap-5 border-bottom">
          <div
            onClick={() => {
              setActive(0);
            }}
            className={`p-3 pointer ${active === 0 ? "active" : ""}`}
          >
            {"Upcoming" + " (" + bookings.upcoming.length + ")"}
          </div>
          <div
            onClick={() => {
              setActive(1);
            }}
            className={`p-3 pointer ${active === 1 ? "active" : ""}`}
          >
            {"All trips"}
          </div>
          {/* <div
            onClick={() => {
              getAllCustomerBookingDetails();
              setActive(2);
            }}
            className={`p-3 cursor-pointer ${active === 2 ? "active" : ""}`}
          >
            {"Cancelled"}
          </div> */}
        </div>

        <div className="mt-3">
          {active === 1 ? (
            bookings.all.length > 0 ? (
              bookings.all?.map((booking) => (
                <BookingDetailsCard
                  bookingInfo={booking}
                  type={activeSection}
                />
              ))
            ) : (
              <div className="text-center text-muted fw-semibold">
                No Booking Is Available
              </div>
            )
          ) : bookings.upcoming.length > 0 ? (
            bookings.upcoming?.map((booking) => (
              <BookingDetailsCard
                bookingInfo={booking}
                type={activeSection}
              />
            ))
          ) : (
            <div className="text-center text-muted fw-semibold">
              No Booking Is Available
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
