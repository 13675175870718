import React from 'react'
type IProps ={
    label :string ;
    value :string | number ;
}
export default function SimpleCard({label ,value} :IProps) {
  return (
    <div>
      <p className='m-0 mt-1 text-muted text-bolder fs-9'>{label}</p>
      <p className='m-0 text-bold text-secondary fs-8'>{value}</p>
    </div>
  )
}
