import React from "react";
import "./SectionTitle.css";
interface Props {
  title: string;
  subTitle?: string;
  link?: JSX.Element;
}

const SectionTitle = (props: Props) => {
  return (
    <div className="section-title">
      <div>
        <h2>{props.title}</h2>
        <p className="text-muted">{props.subTitle}</p>
      </div>
      <div>{props.link}</div>
    </div>
  );
};

export default SectionTitle;
