import React, { FC } from 'react';

import ChoiceGroup from "@kiwicom/orbit-components/lib/ChoiceGroup";
import Checkbox from "@kiwicom/orbit-components/lib/Checkbox";
import AccordionCard from "../../../components/Common/AccordionCard/AccordionCard";

import useFlight from "../../../utils/hooks/Flight/useFlight";
import { useSelector } from "react-redux";
import { State } from "../../../redux/types";
import { priceFormatter } from "../../../utils/utilityFunctions/utilityFunction";
import { TypeFlightFiltersKeys } from "../../../utils/types/flightTypes";
import { motion } from 'framer-motion';
import { AiOutlineClose } from 'react-icons/ai';

interface PropFilters {
    onHideModal: () => void;
    price: number[];
};

interface IChoiceGroupProps {
    onChange: (value: string) => void;
    checkBoxProps: {
        title: string;
        value: string;
        subtitle?: string;
        isChecked?: boolean;
    }[];
}
interface ISearchFlightProps {
    onHideModal: () => void;
    price: number[];
}

const FiltersOnModal = ({ onHideModal, price }: PropFilters) => {
    return <React.Fragment>
        <motion.div
            className="modal show d-block"
            tabIndex={-1}
            aria-labelledby="filtersModal"
            aria-hidden="true"
            initial={{ translateY: -30, opacity: 0 }}
            transition={{ duration: 0.5 }}
            animate={{
                translateY: 0,
                opacity: 1,
            }}
        >
            <div className="modal-dialog mpdal-lg modal-dialog-centered modal-dialog-scrollable">
                {/* modal-fullscreen-xxl-down */}
                <div className="modal-content">
                    <div className="modal-header border-0 bg-dark text-white">
                        <h6 className="fw-bold mt-3">Filters</h6>
                        <button
                            type="button"
                            className="btn-modal-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => onHideModal()}><AiOutlineClose /></button>
                    </div>
                    <div className="modal-body">
                        <Filters
                            onHideModal={onHideModal}
                            price={price}
                        />

                    </div>
                </div>
            </div>
        </motion.div>
        <div className="modal-backdrop fade show"></div>
    </React.Fragment>;
}

const Filters: FC<ISearchFlightProps> = ({
    onHideModal,
    price
}) => {
    const { updateFilter } = useFlight();
    const { filters, searchInfo } = useSelector((state: State) => state.flight);

    const isChecked = (key: TypeFlightFiltersKeys, value: string) =>
        filters[key].findIndex((e) => e === value) !== -1;

    return <React.Fragment>
        <div className="sticky-container filter_section">
            {/* STOPS */}

            <AccordionCard title={"Stops"} size="sm">
                <ChoiceGroupComp
                    onChange={(value) => updateFilter("stops", value)}
                    checkBoxProps={[
                        {
                            title: "Non Stop",
                            value: "0",
                            isChecked: isChecked("stops", "0"),
                        },
                        {
                            title: "1 Stop",
                            value: "1",
                            isChecked: isChecked("stops", "1"),
                        },
                        // {
                        //   title: "2 Stop",
                        //   value: "2",
                        //   isChecked: isChecked("stops", "2"),
                        // },
                        // {
                        //   title: "3 Stop",
                        //   value: "3",
                        //   isChecked: isChecked("stops", "3"),
                        // },
                    ]}
                />
            </AccordionCard>
            <AccordionCard
                title={`Departure time from ${searchInfo.fromCity[0]?.City}`}
                size="sm"
            >
                <ChoiceGroupComp
                    onChange={(value) => updateFilter("departureTime", value)}
                    checkBoxProps={[
                        {
                            title: "Morning",
                            value: "morning",
                            subtitle: "Midnight - 12pm",
                            isChecked: isChecked("departureTime", "morning"),
                        },
                        {
                            title: "Afternoon",
                            value: "afternoon",
                            subtitle: "12pm - 4pm",
                            isChecked: isChecked("departureTime", "afternoon"),
                        },
                        {
                            title: "Evening",
                            value: "evening",
                            subtitle: "4pm - 8pm",
                            isChecked: isChecked("departureTime", "evening"),
                        },
                        {
                            title: "Night",
                            value: "night",
                            subtitle: "8pm - midnight",
                            isChecked: isChecked("departureTime", "night"),
                        },
                    ]}
                />
            </AccordionCard>
            <AccordionCard
                title={`Arrival time to ${searchInfo?.toCity[0]?.City}`}
                size="sm"
            >
                <ChoiceGroupComp
                    onChange={(value) => updateFilter("arrivalTime", value)}
                    checkBoxProps={[
                        {
                            title: "Morning",
                            value: "morning",
                            subtitle: "Midnight - 12pm",
                            isChecked: isChecked("arrivalTime", "morning"),
                        },
                        {
                            title: "Afternoon",
                            value: "afternoon",
                            subtitle: "12pm - 4pm",
                            isChecked: isChecked("arrivalTime", "afternoon"),
                        },
                        {
                            title: "Evening",
                            value: "evening",
                            subtitle: "4pm - 8pm",
                            isChecked: isChecked("arrivalTime", "evening"),
                        },
                        {
                            title: "Night",
                            value: "night",
                            subtitle: "8pm - midnight",
                            isChecked: isChecked("arrivalTime", "night"),
                        },
                    ]}
                />
            </AccordionCard>
            <AccordionCard title={"Price Range"} size="sm">
                <label htmlFor="price" className="px-1 text-end w-100">
                    upto {priceFormatter(filters?.priceRange[0])}
                </label>
                <input
                    type="range"
                    className="form-range"
                    min="2000"
                    max="15000"
                    value={filters?.priceRange[0]}
                    step="5"
                    onChange={(e) => {
                        const { value } = e.target;
                        updateFilter("priceRange", value);
                    }}
                    id="price"
                />
                {/* <Slider
          defaultValue={price}
          label={
            <span className="fw-normal fs-6">
              &#8377; {filters?.priceRange[0] || price[0]}
            </span>
          }
          histogramDescription={
            <span className="fw-normal fs-6">
              &#8377; {filters?.priceRange[1] || price[1]}
            </span>
          }
          maxValue={price[1]}
          minValue={price[0]}
          // maxValue={Number(filters?.priceRange[1]) || price[1]}
          // minValue={Number(filters?.priceRange[0]) || price[0]}
          onChange={(value) => {
            updateFilter("priceRange", [value].flat().join());
          }}
          step={5}
          // valueDescription={
          //   <div className="bg-dark">"From midnight to 12:00 pm"</div>
          // }
        /> */}
            </AccordionCard>

            <AccordionCard title={"Durartion"} size="sm">
                <label htmlFor="duration" className="px-1 text-end w-100">
                    upto {filters?.duration[0] || 15} hrs
                </label>
                <input
                    type="range"
                    className="form-range"
                    min="1"
                    max="24"
                    value={filters?.duration[0]}
                    onChange={(e) => {
                        const { value } = e.target;
                        updateFilter("duration", value);
                    }}
                    step="1"
                    id="duration"
                />
            </AccordionCard>
            <AccordionCard title={"Airline"} size="sm" isDisabled isCollapsed>
                <ChoiceGroupComp
                    onChange={(value) => updateFilter("airlines", value)}
                    checkBoxProps={[
                        {
                            title: "Indigo",
                            value: "indigo",
                        },
                        {
                            title: "Air India",
                            value: "airIndia",
                        },
                        {
                            title: "Vistara",
                            value: "vistara",
                        },
                        {
                            title: "Spice Jet",
                            value: "spicejet",
                        },
                    ]}
                />
            </AccordionCard>
            <AccordionCard title={"Layover"} size="sm" isCollapsed isDisabled>
                <ChoiceGroupComp
                    onChange={(value) => updateFilter("layover", value)}
                    checkBoxProps={[
                        {
                            title: "1",
                            value: "1",
                        },
                        {
                            title: "2",
                            value: "2",
                        },
                        {
                            title: "3",
                            value: "3",
                        },
                    ]}
                />
            </AccordionCard>
        </div>
    </React.Fragment>
};

const ChoiceGroupComp: FC<IChoiceGroupProps> = ({
    onChange,
    checkBoxProps,
}) => {
    return (
        <ChoiceGroup
            // filter
            onChange={(e: any) => {
                const { value } = e.target;
                onChange(value);
            }}
        >
            {checkBoxProps?.map((check, index) => (
                <Checkbox
                    key={index}
                    checked={check?.isChecked}
                    label={
                        <div className="d-flex justify-content-between ">
                            <span className="fw-semibold" style={{ opacity: 0.9 }}>
                                {check?.title}
                            </span>
                            <span className="text-muted" style={{ fontSize: "0.9em" }}>
                                {check?.subtitle}
                            </span>
                        </div>
                    }
                    value={check?.value}
                />
            ))}
        </ChoiceGroup>
    );
};

// const CardBase: FC<{ header?: string }> = ({ children, header }) => (
//     <div className="card">
//         {header && <div className="card-header">
//             <h6 className="fw-bold">{header}</h6>
//         </div>}

//         <div className={`card-body ${!header && "py-0 px-1"}`}>
//             {children}
//         </div>
//     </div>
// )

export default FiltersOnModal;
