import {
  commonStateSlice,
  setCouponBoxStatus,
} from "./../../redux/reducers/commonState";
import { State } from "./../../redux/types";
import { useSelector } from "react-redux";
import { SERVER_API_URL } from "./../constants";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setClientData, setClient } from "../../redux/reducers/commonState";
const useClientData = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { Client } = useSelector((state: State) => state.commonState);

  useEffect(() => {
    if (Client === null) getClient();
  }, []);

  const getClient = async () => {
    setLoading(true);
    try {
      // const currentUrl = window.location.origin;
      const currentUrl = "visitindya.com";
      // const currentUrl = "indialegacytours.com";
      const response = await axios.get(
        `${SERVER_API_URL}Domain/GetDomain?domain=${currentUrl}`
      );
      const { ResponseData, IsSuccess } = response?.data;
      if (IsSuccess) {
        dispatch(
          setClient({
            id: ResponseData?.ClientId,
            name: ResponseData?.ClientName,
          })
        );
        getClientServices(ResponseData?.ClientId);
        GetDiscountEnableDisableRule(ResponseData?.ClientId);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getClientServices = async (ClientId: number) => {
    try {
      const response = await axios.get(
        `${SERVER_API_URL}Admin/GetServices/${ClientId}`
      );
      const { ResponseData, IsSuccess } = response?.data;
      if (IsSuccess) {
        dispatch(setClientData({ key: "Services", value: ResponseData }));
        dispatch(commonStateSlice.actions.setActiveTab(ResponseData[0]));
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetDiscountEnableDisableRule = async (ClientId: number) => {
    try {
      const response = await axios.get(
        `${SERVER_API_URL}Admin/GetDiscountEnableDisableRule/${ClientId}`
      );
      const { ResponseData, IsSuccess } = response?.data;
      if (IsSuccess) {
        const { IsEnabledFlight, IsEnabledHotel, IsEnabledBus } =
          ResponseData?.[0];
        dispatch(
          setCouponBoxStatus({ IsEnabledBus, IsEnabledFlight, IsEnabledHotel })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getChargesByService = (serviceId: number) => {
    const apiEndpoints: {
      api: string;
      reduxKey: "GSTRates" | "MyCharges" | "OtherCharges";
    }[] = [
      {
        api: "GetGstRates",
        reduxKey: "GSTRates",
      },
      {
        api: "GetMyCharges",
        reduxKey: "MyCharges",
      },
      {
        api: "GetOtherCharges",
        reduxKey: "OtherCharges",
      },
    ];
    const promises = apiEndpoints.map((endpoints) =>
      axios.get(
        `${SERVER_API_URL}Admin/${endpoints.api}/${Client!.id}/${serviceId}`
      )
    );
    console.log("THESE ARE PROMISES")
    return Promise.allSettled(promises).then((response) => {
      response.forEach((res, index) => {
        if (res?.status === "fulfilled") {
          const { ResponseData } = res?.value?.data;
          dispatch(
            setClientData({
              key: apiEndpoints[index].reduxKey,
              value: ResponseData,
            })
          );
        } else {
          console.log("couldn't get charges", res.reason);
        }
      });
    });
  };

  return { loading, getChargesByService };
};
export default useClientData;
