import React, { FC } from "react";
import Button from "../Common/Button/Button";

interface Props {
  title: string;
  subTitle: string;
  icon?: JSX.Element | string;
  confirmText?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  cancelText?: string;
  size?: "sm" | "lg" | "xl" | "fullscreen";
  headerTitle?: string;
  showCloseOnTop?: boolean;
  cancelTextColor?:
    | "link"
    | "light"
    | "primary"
    | "secondary"
    | "dark"
    | "warning"
    | "danger";
}

const BsInfoModal: FC<Props> = ({
  title,
  subTitle,
  onClose,
  icon,
  cancelText,
  onConfirm,
  confirmText,
  size,
  headerTitle,
  showCloseOnTop,
  cancelTextColor,
}) => {
  return (
    <React.Fragment>
      <div className="modal  d-block" aria-hidden="true">
        <div
          className={`modal-dialog modal-dialog-centered modal-${size || "md"}`}
        >
          <div className="modal-content shadow-lg border-0 rounded ">
            <div className="modal-header border-0">
              {headerTitle && <h3 className="modal-title">{headerTitle}</h3>}
              {showCloseOnTop && (
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => onClose && onClose()}
                ></button>
              )}
            </div>
            <div className="modal-body p-4">
              {icon}
              <h1>{title}</h1>
              <p className="text-muted">{subTitle}</p>
              <div className="d-flex justify-content-between ">
                {cancelText && (
                  <Button
                    label={cancelText ? cancelText : ""}
                    type="button"
                    color={cancelTextColor || "danger"}
                    outline
                    // size="sm"
                    className="py-1"
                    onClick={() => onClose && onClose()}
                  />
                )}
                {confirmText && (
                  <Button
                    label={confirmText ? confirmText : ""}
                    type="button"
                    color="primary"
                    className="ms-auto py-1"
                    onClick={() => onConfirm && onConfirm()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </React.Fragment>
  );
};

export default BsInfoModal;
