// Journey types for Itinerary
export interface IJourney {
  JourneySellKey: string;
  Segments: ISegment[];
  meta: IItineraryMeta;
}
export interface ISearchFilter {
  stops: string[];
  departureTime: string[];
  arrivalTime: string[];
  priceRange: number[];
  airlines: string[];
  duration: number[];
  layover: string[];
}

/*
// fromCity toCity is array of objects 
and departureDate is array of strings
// containing the cities and dates of travels
/////
//   in case of travelType===return;
//   the fromCity and toCity at [1] will contain the reversed values of cities
//   and departureDate[1] will contain the return Date 
//  
*/
export interface ISearchInfo {
  travelType: {
    SearchTypeId: number;
    SearchTypeName: string;
  };
  adult: number;
  child: number;
  infant: number;
  class: {
    FlightClassId: number;
    FlightClassName: string;
  };
  fromCity: IFLightOrigins[];
  toCity: IFLightOrigins[];
  departureDate: string[];
  airlines: number[];
}
export interface IPersonalDetails {
  countryCode: string;
  mobileNo: string;
  email: string;
  passengers: {
    child: {
      Title: string;
      firstName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
    }[];
    adult: {
      Title: string;
      firstName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
    }[];
    infant: {
      Title: string;
      firstName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
    }[];
  };
};

// used for available flight list
export interface IFlightSearchData {
  // navitaire
  JourneySellKey: string;
  flightCode: string | string[];
  carrierCode: string;
  airports: string[];
  duration: string;
  durationInMins: number;
  departureTime: string;
  arrivalTime: string;
  FareData: IFareData[];
  FareToShow: IFareData;
  SegmentData: IFlightSegmentData[];
  // AirIQ
  flight_route?: string;
  fare_data: IAirIQFareData;
  // general
  supplier: "navitaire" | "airiq";
};

export type TFlightResultList = {
  // navitaire
  JourneySellKey: string;
  flightCode: string | string[];
  carrierCode: string;
  airports: string[];
  duration: string;
  durationInMins: number;
  departureTime: string;
  arrivalTime: string;
  FareData?: IFareData[];
  FareToShow: IFareData;
  SegmentData: IFlightSegmentData[];
  // general
  supplier: "navitaire" | "airiq" | "1G";
  // 1G - uAPI-Galileo
  journey?: any;
  "1GPricingSolutions": I1GPricingSolutions[];
  "1GSegmentData": I1GFlightSegmentData[];
  fareInfo: IAirIQFareData;
  totalFare: number;
  // AirIQ
  flight_route?: string;
  fare_data: IAirIQFareData;
};

export interface I1GPricingSolutions {
  Brand: any;
  FareInfo: any;
  PricingInfo: any;
  PricingSolution: any;
};

export interface IFareData {
  CarrierCode: string;
  ClassOfService: string;
  FareBasisCode: string;
  ProductClass: string;
  FareType: string;
  FareClassOfService: string;
  RuleNumber: number;
  FareSellKey: string;
  basePrice: number;
  taxPrice?: number;
  FareInfo: {
    features: string[];
    genericType: "SAVER" | "STANDARD" | "PREMIUM";
    type: string;
    typeDescription: string;
  };
  markedUpPrice: {
    markedupBasePrice: number;
    markedupTotalPrice: number;
    markedUpTaxPrice: number;
    baseMarkupAmount: number;
    taxMarkupAmount: number;
  };
}
export interface IFlightSegmentData {
  departure: {
    iata_code: string;
    city: string;
    scheduled_time: string;
    terminal: string;
  };
  arrival: {
    iata_code: string;
    city: string;
    scheduled_time: string;
    terminal: string;
  };
  carrierCode: string;
  flightNumber: string;
  duration: string;
  durationInMins: number;
};

export interface I1GFlightSegmentData {
  departure: {
    iata_code: string;
    city: string;
    scheduled_time: string;
    terminal: string;
  };
  arrival: {
    iata_code: string;
    city: string;
    scheduled_time: string;
    terminal: string;
  };
  carrierCode: string;
  flightNumber: string;
  duration: string;
  durationInMins: number;
  "1GPricingSolutions": Array<{
    Brand: any;
    FareInfo: any;
    PricingInfo: any;
    PricingSolution: any;
  }>;
  fareInfo: IAirIQFareData;
}

export interface IItineraryMeta {
  adultBasePrice: number;
  adultGSTPrice: number;
  adultTravelFee: number;
  childBasePrice?: number;
  childGSTPrice?: number;
  childTravelFee?: number;
  childDiscountPrice?: number;
  selectedFareType: string;
  flightCode: string;
  carrierCode: string;
  airports: string[];
  durationInMins: number;
  duration: string;
  arrivalTime: string;
  departureTime: string;
  priceBreakUp: {
    Amount: string | null;
    ChargeCode: string | null;
    ChargeDetail: string | null;
    ChargeType: string | null;
  }[][];
  markupPrice: IFlightMarkUpData;
  vendorCharges: {
    adult: { GSTAmount: number; myCharges: number; otherCharges: number };
    child: { GSTAmount: number; myCharges: number; otherCharges: number };
  };
  flightAirline: {
    AirlineId: number;
    AirlineNameCode: string;
  };
  bookingSupplier: {
    SupplierId: number;
    SupplierName: string;
  };
  ticketedSupplier: {
    TicketedSupplierID: number;
    SupplierName: string;
  };
}

export interface IItineraryBookingSum {
  AlternateCurrencyBalanceDue: string;
  AlternateCurrencyCode: string | null;
  AuthorizedBalanceDue: string;
  BalanceDue: string;
  PassiveSegmentCount: string;
  PointsBalanceDue: string;
  SegmentCount: string;
  TotalCost: string;
  TotalPointCost: string;
  CarrierCode: string;
}

export interface ISegment {
  ActionStatusCode: string;
  ArrivalStation: string;
  AvailabilitySourceCode: string | null;
  CabinOfService: string | null;
  ChangeReasonCode: string | null;
  ChannelType: string;
  DepartureStation: string;
  Fares: {
    CarrierCode: string;
    ClassOfService: string;
    ClassType: string | null;
    FareApplicationType: string | null;
    FareBasisCode: string | null;
    ProductClass: string;
    FareType: string;
    FareClassOfService: string | null;
    FareSellKey: string;
    FareSequence: string | null;
    FareStatus: string | null;
    InboundOutbound: string | null;
    IsAllotmentMarketFare: string | null;
    OriginalClassOfService: string | null;
    PaxFares: {
      FareDiscountCode: string | null;
      PaxDiscountCode: string | null;
      PaxType: string | null;
      ProofOfStatusRequired: string | null;
      ServiceCharges: {
        Amount: string | null;
        ChargeCode: string | null;
        ChargeDetail: string | null;
        ChargeType: string | null;
        CollectType: string | null;
        CurrencyCode: string | null;
        ForeignAmount: string | null;
        ForeignCurrencyCode: string | null;
        TicketCode: string | null;
      }[];
    }[];
    TravelClassCode: string | null;
    XrefClassOfService: string | null;
  }[];
  FlightDesignator: {
    CarrierCode: string | null;
    FlightNumber: string | null;
    OpSuffix: string | null;
  };
  International: string | null;
  InventorySourceCode: string | null;
  Legs: {
    ArrivalStation: string | null;
    DepartureStation: string | null;
    InventoryLegID: string | null;
    STA: string | null;
    STD: string | null;
  }[];
  PaxBags: string | null;
  PaxSSRs: string | null;
  PaxScores: string | null;
  STA: string | null;
  STD: string | null;
  SalesDate: string | null;
  SegmentSellKey: string | null;
  SegmentType: string | null;
}

export interface IFlightMetaData {
  // | "FlightApplyOn"
  // | "FlightApplyType";
  Origins: IAirPorts[];
  Destinations: IAirPorts[];
  MarkupFeeType: { MarkupFeeTypeId: number; MarkupFeeTypeName: string }[];
  // [][] for storing markup of multiple flight results
  Markup: IMarkUpResponse[][];
  AffiliateMarkup: IMarkUpResponse[][];
  TripTypes: {
    SearchTypeId: number;
    SearchTypeName: string;
  }[];
  BookingSupplier: {
    SupplierId: number;
    SupplierName: string;
  }[];
  TicketedSupplier: {
    TicketedSupplierID: number;
    SupplierName: string;
  }[];
  FlightAirline: {
    AirlineId: number;
    AirlineNameCode: string;
  }[];
  FlightClass: {
    FlightClassId: number;
    FlightClassName: string;
  }[];
  FlightCabinClass: {
    FlightCabinClassId: number;
    FlightCabinClassName: string;
  }[];
  FlightOrigins: IFLightOrigins[];
  FlightDestinations: IFLightOrigins[];
  TitleMaster: string[];
  MealType: string[];
  TravellerTypes: {
    TravellerTypeId: number;
    TypeName: string;
  }[];
  Charges: {
    TicketChargeID: number;
    TicketID: number;
    TicketChargeCalculationTypeId: number;
    TicketChargeTypeId: number;
    ChargeID: number;
    Amount: number;
    ChargeName: string;
    TicketChargeCalculationTypeName: string;
    TicketChargeTypeName: string;
  }[];
  FlightApplyFor: {
    ApplyForId: number;
    ApplyForName: string;
  }[];
  FlightMarkupType: {
    MarkupTypeId: number;
    MarkupTypeName: string;
  }[];
  FlightFareType: {
    FareTypeId: number;
    FareTypeName: string;
  }[];
  FlightApplyOn: {
    ApplyOnId: number;
    ApplyOnName: string;
  }[];
  FlightApplyType: {
    ApplyTypeId: number;
    ApplyTypeName: string;
  }[];
}
export interface IMarkUpResponse {
  MarkupId: number;
  TravelStartDate: string;
  TravelEndDate: string;
  GrossPriceFrom: number;
  GrossPriceTo: number;
  DestinationNames: string;
  SupplierNames: string;
  OriginNames: string;
  ApplyForId: number; // per passenger , booking or per sector
  MarkupTypeId: number; //domestic international
  FareTypeId: number; //Normal, corporate,Special fare
  ApplyOnId: number; //Base, YQ(Tax) , Base + YQ, totalPrice
  SearchTypeId: number; //oneway, return or multicity
  ApplyTypeId: number; //import Pnr or Online
  lstCharges: IListChargesOfMarkup[];
}
export interface IListChargesOfMarkup {
  Id: number;
  MarkupFeeId: number;
  MarkupFeeTypeId: number;
  MarkupId: number;
  FeeValue: number;
  IsGSTAppplied: boolean;
  MarkupFeeName: "Base Fee" | "Service Fee" | "Booking Fee";
}

// data for createBooking API of storing data to db after the booking is done

export interface ISaveFlightData {
  NetpayableAmount: number;
  ClientId: number;
  AffiliateID: string | null;
  CustomerId: string | null;
  TitleMasterId?: number;
  BookingAmount: number;
  TaxAmount: number;
  Duration: string;
  Baggage: string;
  NoOfStops: string;
  PaymentStatusId: number;
  Remarks: string;
  lstPNRDetails: {
    SupplierPNR: string;
    AirlinePNR: string;
    FlightAirlineID: string;
    DepartureRouteID: string;
    ArrivalRouteID: string;
    FlightNo: string;
    FromDate: string;
    ToDate: string;
    BookingSupplierId: string;
    TicketedSupplierId: string;
    TicketStatusId: string;
    Terminal: string;
    ArrivalTerminal: string;
    FlightCabinClassId: any;
    FlightClassId: string;
    TicketPNRDetailId: number;
  }[];
  lstTravellers: {
    TravellerTypeId: string;
    TravelerTitle: string;
    TravellerFirstName: string;
    TravellerLastName: string;
    TravellerDOB: string; //ISO formatted Date
    Gender: number;
    TicketNumber: string;
    Basefare: number;
    TaxesAndCharges: number;
    TotalAmount: number;
    TripType: number;
    SeatNumber: string;
    TicketPNRDetailId: number;
    Baggage: string;
    Meal: string;
    Age: string;
    SupplierPNR: string;
    AirlinePNR: string;
  }[];
  lstCharges: {
    TicketChargeCalculationTypeId: number;
    TicketChargeTypeId: number;
    Amount: number;
    ChargeName: string;
  }[];
}

export type IAirPorts = {
  Id: number;
  name: string;
  city: string;
  iso_country: string;
  continent: string;
  iata_code: string;
  coordinates: string;
};
// interface ISelectedFlight {
//   flight_1: IFlightSearchData | null;
//   flight_2: IFlightSearchData | null;
//   flight_3: IFlightSearchData | null;
// }
export type TypeFlightSearchKeys =
  | "travelType"
  | "adult"
  | "child"
  | "infant"
  | "class"
  | "fromCity"
  | "toCity"
  | "departureDate"
  | "airlines";
export type TypeFlightFiltersKeys =
  | "stops"
  | "departureTime"
  | "arrivalTime"
  | "priceRange"
  | "airlines"
  | "duration"
  | "layover";
export type TypePersonalInfo = "mobileNo" | "email" | "countryCode";
export type TypeFlightStateKeys = "flight_1" | "flight_2" | "flight_3";
export type TypePassengerData =
  | "Title"
  | "firstName"
  | "lastName"
  | "gender"
  | "dateOfBirth";
export type TypeMetaDataFlights =
  | "Origins"
  | "Destinations"
  | "MarkupFeeType"
  | "Markup"
  | "AffiliateMarkup"
  | "TripTypes"
  | "BookingSupplier"
  | "TicketedSupplier"
  | "FlightAirline"
  | "FlightClass"
  | "FlightCabinClass"
  | "FlightOrigins"
  | "FlightDestinations"
  | "TitleMaster"
  | "MealType"
  | "TravellerTypes"
  | "Charges"
  | "FlightApplyFor"
  | "FlightMarkupType"
  | "FlightFareType"
  | "FlightApplyOn"
  | "FlightApplyType";
export type IUpdatePassenger = {
  Title: string;
  FirstName: string;
  LastName: string;
  Gender: string;
  DOB: string;
  PaxType: string;
  Infant?: {
    DOB: string;
    Gender: string;
    FirstName: string;
    LastName: string;
    Title: string;
  };
}[];

export interface IFLightOrigins {
  RouteDestinationId: number;
  RouteDestinationName: string;
  RouteDestinationCode: string;
  ISOCountry: string;
  City: string;
  Coordinate1: string;
  Coordinate2: string;
}

export interface IFlightMarkUpData {
  adult: {
    markedupBasePrice: number;
    markedUpTaxPrice: number;
    markedupServicePrice: number;
    markedupBookingPrice: number;
    baseMarkupAmount: number;
    taxMarkupAmount: number;
    agentBaseMarkupAmount: number;
    agentTaxMarkupAmount: number;
    gstAmount: number;
  };
  child: {
    markedupBasePrice: number;
    markedUpTaxPrice: number;
    markedupServicePrice: number;
    markedupBookingPrice: number;
    baseMarkupAmount: number;
    taxMarkupAmount: number;
    agentBaseMarkupAmount: number;
    agentTaxMarkupAmount: number;
    gstAmount: number;
  };
}

//for prices to be shown at the booking page
export interface IFLightPricesToShow {
  Base: {
    TotalAdult: number;
    TotalChild: number;
    PerAdultByJourney: number[];
    PerChildByJourney: number[];
  };
  Charges: {
    FareTax: {
      // taxes on Fare excluding addons
      TotalAdult: number;
      TotalChild: number;
      PerAdultByJourney: number[];
      PerChildByJourney: number[];
    };
    TotalGST: number;
    // without GST
    Baggage: number;
    Meals: number;
    Seat: number;
    SSRChargesByPaxPerJourney: number[][];
  };
  ServiceCharges: number;
  AgentAddOnMarkup: number;
  Discounts: {
    Child: number;
    Coupon: number;
  };
  NetPayable: number;
};

// types for API request bodies

export interface ISellSSR {
  userId: string;
  SellBy: string; //SSR
  SegmentSSR: {
    CarrierCode: string; //SG
    FlightNumber: string;
    STD: string;
    DepartureStation: string;
    ArrivalStation: string;
    PaxSSR: {
      ActionStatusCode: string; //NN
      PassengerNumber: string;
      SSRCode: string;
      SSRNumber: number; //0
      SSRValue: number;
    }[];
  }[];
  CurrencyCode: string; //INR
}

export interface ISSR {
  Available: string;
  SeatDependent: boolean;
  InventoryControlled: boolean;
  SSRCode: string;
  PassengerNumberList: string[];
  PaxSSRPrice: {
    FeeApplicationType: string;
    FeeCode: string;
    FeeDetail: string | null;
    FeeNumber: string;
    FeeType: string;
    PaymentNumber: string;
    Fare: {
      Amount: string;
      ChargeCode: string;
      ChargeDetail: string;
      ChargeType: string;
      CollectType: string;
      CurrencyCode: string;
      ForeignAmount: string;
      ForeignCurrencyCode: string;
      TicketCode: string;
    }[];
  }[];
  TotalPrice: {
    Charge: number;
    GST: number;
  };
  type: string;
  description: string;
}
export interface ISSRLegInfo {
  ArrivalStation: string;
  CarrierCode: string;
  DepartureDate: string;
  DepartureStation: string;
  FlightNumber: string;
}

// selected ssr for each segment
// // contains passengers(adult and child) list with the ssr list selected for that passenger
// export interface ISelectedSSR {
//   isFirstSegmentOfJourney: boolean; //setting if the segment is first segment of a particular journey
//   journeyIndex: number;
//   passengers: ISSR[][]; //passengers[0] denotes array of ssr selected for first passenger
//   LegInfo: ISSRLegInfo;
// }
export interface ISelectedAddOns {
  isFirstSegmentOfJourney: boolean; //setting if the segment is first segment of a particular journey
  journeyIndex: number;
  SSR: ISSR[][]; //passengers[0] denotes array of ssr selected for first passenger
  Seats: ISeat[][]; //passengers[0] denotes array of seat selected for first passenger
  LegInfo: ISSRLegInfo;
}
// export interface ISelectedSSR2 {
//   segments: {
//     isFirstSegmentOfJourney: boolean; //setting if the segment is first segment of a particular journey
//     journeyIndex: number;
//     ArrivalStation: string;
//     CarrierCode: string;
//     DepartureDate: string;
//     DepartureStation: string;
//     FlightNumber: string;
//   }[];
//   passengers: {
//     [x: string]: { segmentIndex: number; ssrCode: string }[];
//   }[];
//   ssrs: ISSR[];
// }

export interface ISeatAvailability {
  LegInfo: {
    AircraftType: string;
    AircraftTypeSuffix: string;
    ArrivalStation: string;
    DepartureStation: string;
    EquipmentIndex: string;
    FlightNumber: string;
    CarrierCode: string;
    STA: string;
    STD: string;
  };
  EquipmentInfo: {
    AvailableUnits: string;
    EquipmentCategory: string;
    EquipmentType: string;
    EquipmentTypeSuffix: string;
    MarketingCode: string | null;
    Name: string;
  };
  Compartment: {
    AvailableUnits: string;
    CompartmentDesignator: string;
    Deck: string;
    Length: string;
    Orientation: string;
    EquipmentProperty: {
      TypeCode: string;
      Value: string;
    };
    Sequence: string;
    Width: string;
  }[];
  Seats: ISeat[];
}
export interface ISeat {
  Assignable: boolean;
  CabotageLevel: string;
  CarAvailableUnits: string;
  CompartmentDesignator: "Y";
  CriterionWeight: string;
  Height: string;
  ODPenalty: string;
  PremiumSeatIndicator: boolean;
  Priority: string;
  PropertyList: {
    TypeCode: string;
    Value: string;
  }[];
  SSRSeatMapCode: string | null;
  SeatAngle: string;
  SeatAvailability: string;
  SeatDesignator: string;
  SeatGroup: string;
  SeatSet: string;
  SeatSetAvailableUnits: string;
  SeatType: string;
  TerminalDisplayCharacter: string;
  Text: null;
  TravelClassCode: string;
  Width: string;
  X: string;
  Y: string;
  Zone: string;
  PriceList: ISeatPrice[];
  //array containing different prices for different pax as received in the api response
}
export interface ISeatPrice {
  PassengerNumber: number;
  SeatGroup: number;
  FeeApplicationType: string;
  FeeCode: string;
  FeeDetail: string | null;
  FeeNumber: string;
  FeeOverride: boolean;
  FeeType: string;
  FlightReference: string;
  IsProtected: boolean;
  Note: string | null;
  PaymentNumber: string;
  SSRCode: string;
  Price: {
    Amount: number;
    ChargeCode: string;
    ChargeType: string;
    TicketCode: string;
  }[];
  TotalPrice: {
    Charge: number;
    GST: number;
  };
}

export interface ISelectedSeat {
  segIndex: number;
  passengerIndex: number;
  selectedSeat: ISeat;
}

export interface IFare {
  adult: IFareByPax[];
  child: IFareByPax[];
  total: {
    base: number;
    tax: number;
    markedUpBase: number;
    markedUpTax: number;
  };
}
export interface IFareByPax {
  fare: {
    base: number;
    selectedMeal: number;
    selectedSeat: number;
    selectedBaggage: number;
    markedUpBase: number;
    markedUpSelectedMeal: number;
    markedUpSelectedSeat: number;
    markedUpSelectedBaggage: number;
  };
  charges: {
    tax: number;
    selectedMeal: number;
    selectedSeat: number;
    selectedBaggage: number;
    markedUpTax: number;
    markedUpSelectedMeal: number;
    markedUpSelectedSeat: number;
    markedUpSelectedBaggage: number;
  };
}

// stores the Airline Index as they are exported from the SearchFlights.ts or BookFlights.ts interfaces
export enum AirLineServices {
  SG,
  // G8,
  "6E"
}
export type TypeCarrierCode = "SG" | "6E"; // | "G8"

// Booking Details 

export interface ITicketDetails {
  "lstTicketBooking":
  {
    "TicketID": number;
    "ClientId": number;
    "ServiceID": number;
    "BookingDate": string;
    "TravelFromDate": string;
    "TravelToDate": string;
    "FirstName": string;
    "LastName": string;
    "BookingSupplier": number
    "TickedSupplier": number
    "PNR": string;
    "BookedBy": number;
    "BookingRefNumber": string;
    "InvoiceNumber": string;
    "TransactionID": string;
    "FromRouteID": number;
    "ToRouteID": number;
    "BookingAmount": number;
    "CustomerID": number;
    "HotelReservationID": null,
    "HotelID": null,
    "TotalAmount": number
    "TaxAmount": null,
    "NetPayableAmount": number
    "AffiliateID": null,
    "TicketTimeLimit": null,
    "InvoiceTypeId": 1,
    "SearchTypeId": null,
    "FlightNumber": string;
    "Remarks": null,
    "NoOfRooms": null,
    "OperatorName": null,
    "OperatorAddress": null,
    "BusCategoryId": null,
    "BusDepartOriginDestinationId": null,
    "BusArrivalOriginDestinationId": null,
    "DepartDateTime": null,
    "ArrivalDateTime": null,
    "BusBoardingPoint": null,
    "BusBoardingAddress": null,
    "BusBoardingTime": null,
    "BusReportingTime": null,
    "BusBoardingPhone": null,
    "PaymentStatusId": null,
    "TicketStatusId": number;
    "TicketStatusName": null,
    "IsSpecialFare": boolean;
    "itinaryXmlorJSON": string;
    "CreatedDate": string;
    "CreatedBy": number;
    "CreatedByIp": string;
    "UpdatedDate": null,
    "UpdatedBy": null,
    "UpdatedByIp": null,
    "IsActive": boolean;
    "IsDeleted": boolean;
    "BookedByName": null,
    "CompanyName": null,
    "ServiceName": null,
    "FromRouteName": null,
    "ToRouteName": null,
    "FromRouteCode": null,
    "ToRouteCode": null,
    "BookedSupplierName": null,
    "TicketedSupplierName": string;
    "CustomerFirstName": string;
    "CustomerLastName": string;
    "CustomerMobile": string;
    "CustomerAddress": null,
    "CustomerEmail": string;
    "UserTypeName": string;
    "TicketPaymentID": null,
    "Amount": null,
    "PaymentGatewayID": null,
    "TrnsactionRefnumber": null,
    "PaymentStatus": null,
    "TransactionDateTime": null,
    "TransactionStatus": null,
    "PaymentGatewayResponse": null,
    "IsTransactionCompleted": null,
    "IsTransacctionApplied": null,
    "PaymentMode": null,
    "PGTransactionID": null,
    "PaymentTransactionID": null,
    "PaymentGatewayName": null,
    "PaymentRemarks": null,
    "CustomerTitle": string
    "IsInternationalBooking": boolean;
    "NoOfStops": null,
    "Duration": null,
    "Baggage": null,
    "HotelName": string;
    "HotelAddress": string;
    "CustomerFullName": string;
    "CustomerName": string;
    "StayingFor": string;
    "VoucherNumber": string;
    "BusRouteOriginName": null,
    "BusRouteDestinationName": null,
    "IsOfflineBooking": boolean;
    "BookingType": string;
  }[];
  "lstTicketTravellerDetails": {
    "TicketDetailID": string
    "TicketID": string
    "TravellerTypeId": string
    "TravelerTitle": string
    "TravellerFirstName": string
    "TravellerLastName": string
    "TravellerDOB": string
    "Gender": number;
    "TicketStatusId": number;
    "TicketNumber": string;
    "Basefare": number;
    "TaxesAndCharges": number;
    "TotalAmount": number;
    "TripType": number;
    "SeatNumber": string;
    "CancellationSupplierCharges": null,
    "CancellationAirlineCharges": null,
    "CancellationMyCharges": null,
    "TravellerTypeName": string;
    "TicketStatus": string;
    "TicketPNRDetailId": number;
    "Dated": string;
    "Remarks": null,
    "chkChecked": boolean;
    "SupplierPNR": string;
    "AirlinePNR": string;
    "GenderName": string;
    "DepartureCode": string;
    "ArrivalCode": string;
    "Baggage": string;
    "Meal": string;
    "Age": string;
    "FromDate": string;
    "ToDate": string;
  }[];
  "lstTicketPNRDetails":
  {
    "TicketPNRDetailId": number;
    "TicketID": number;
    "SupplierPNR": string;
    "AirlinePNR": string;
    "FlightAirlineID": number;
    "DepartureRouteID": number;
    "ArrivalRouteID": number;
    "FlightNo": string;
    "FromDate": string;
    "ToDate": string;
    "AirlineName": string;
    "Departure": string;
    "Arrival": string;
    "DepartureCode": string;
    "ArrivalCode": string;
    "TicketedSupplierName": string;
    "TicketedSupplierId": number;
    "Terminal": string;
    "FlightCabinClassId": number;
    "FlightClassId": number;
    "FlightCabinClassName": string;
    "FlightClassName": string;
    "ArrivalTerminal": string;
  }[];
  "lstTicketCharges":
  {
    "TicketChargeID": number;
    "TicketID": number;
    "TicketChargeCalculationTypeId": number;
    "TicketChargeTypeId": number;
    "ChargeID": null,
    "Amount": number;
    "ChargeName": string
    "TicketChargeCalculationTypeName": string
    "TicketChargeTypeName": string
  }[];
  "lstTicketChargesForCancellation": any[];
  "lstTicketHotelBookingGuests": any[];
  "lstTicketHotelBookingDetails": any[];
  "lstTicketBusBookingDetails": any[];
  "lstTicketInvoice": {
    "InvoiceID": number;
    "ClientId": number;
    "ServiceID": number;
    "TicketID": number;
    "AffiliateID": null,
    "InvoiceTypeId": number;
    "AccountHead": string
    "Descriptions": string
    "Remarks": string
    "Amount": number;
    "TransactionStatus": string;
    "IsDeleted": false,
    "IsCancelled": false,
    "InvoiceNumber": string
    "InvoiceDate": string;
  }[];
  "lstTicketInvoiceDetail": [];
  "lstTicketCurrentStatus": {
    "TicketCurrentStatusID": number;
    "TicketID": number;
    "Dated": string;
    "TicketStatusId": number;
    "TypeOfCancellation": null,
    "IscancellationAllow": true,
    "FlightTicketNumber": string
    "HotelReservationID": string
    "BusTicketNumber": string;
  }[];
  "lstTicketStatusHistory": {
    "TicketStatusHistoryID": number;
    "TicketID": number;
    "Dated": string;
    "TicketStatusId": number;
    "Remarks": string;
  }[];
  "lstTicketCancelled": any[];
  "lstTicketPayments": any[];
  "lstTicketModificationRequests": any[];
  "lstTicketCancellationRequests": any[];
  "lstTicketsRefunded": any[];
  "lstTicketRefundCoupon": any[];
  "lstTicketRefundPaymentInformation": any[];
};


// AIRIQ
export interface IAirIQFlightSearchPayload {
  origin: string;
  destination: string;
  departure_date: string;
  adult: string;
  child: string;
  infant: string;
  airline_code?: string;
};

export interface IAirIQFlightBookingPayload {
  ticket_id: string;
  total_pax: string;
  adult: string;
  child: string | null;
  infant: string | null;
  adult_info: IAirIQFlightBookingPaxInfo[];
  child_info: IAirIQFlightBookingPaxInfo[];
  infant_info: IAirIQFlightBookingPaxInfo[];
};

export interface IAirIQFlightBookingPaxInfo {
  title: string;
  first_name: string;
  last_name: string;
  dob?: string; // mandatory for infant
  travel_with?: string; // mandatory for infant
};

export interface IAirIQSearchAvailabilityResponse {
  airline: string;
  arival_date: string;
  arival_time: string;
  departure_date: string;
  departure_time: string;
  destination: string;
  flight_number: string;
  flight_route: string;
  infant_price: number;
  origin: string;
  pax: number;
  price: number;
  ticket_id: string;
};

export interface IAirIQFlightSearchData {
  flight_route: string;
  fare_data: IAirIQFareData;
};

export interface IAirIQFareData {
  price: number;
  infant_price: number;
  markedUpPrice: {
    markedupBasePrice: number;
    markedUpTaxPrice: number;
    markedupTotalPrice: number;
    baseMarkupAmount: number;
    taxMarkupAmount: number;
    gstAmount: number;
  }
};

export interface ISelectedFaresForItinerary {
  FareSellKey: string;
  JourneySellKey: string;
  carrierCode: string;
  supplier: "navitaire" | "airiq" | "1G";
};


/*
* uAPI
*/

export interface IuAPIFlightSearchPayload {
  LegsInfo: {
    Origin: {
      Airport: string;
    };
    Destination: {
      Airport: string;
    };
    DepartureTime: {
      PreferredTime: string;
    };
  }[];
  Passengers: {
    /*
      ADT: adult
      CHD: child
      INF: infant without a seat
      INS: infant with a seat
      UNN: unaccompanied child
    */
    Code: "ADT" | "CHD" | "INF" | "INS" | "UNN";
    BookingTravelerRef?: string;
    Age?: number;
    DOB?: string;
  }[];
  AirPricingModifiers: {
    CurrencyType: "INR"
  }[];
};

export type TuAPIPax = | "ADT" | "CHD" | "INF" | "INS" | "UNN";