import React from 'react';
import { Button } from '@kiwicom/orbit-components';
import { FaAngleDown, FaAngleUp, FaAngleRight } from 'react-icons/fa';

import { toggle } from '../../../../redux/reducers/commonState';
import { priceFormatter } from '../../../../utils/utilityFunctions/utilityFunction';
import { IFLightPricesToShow, ISearchInfo } from '../../../../utils/types/flightTypes';

interface IProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    searchInfo: ISearchInfo;
    pricesToShow: IFLightPricesToShow;
};

export default function SummaryDetails({
    show,
    setShow,
    searchInfo,
    pricesToShow,
}: IProps) {

    let baseFareData = [
        {
            text: `${searchInfo.adult} Adult passenger${searchInfo.adult > 1 ? `s` : ""
                }`,
            price: pricesToShow?.Base.TotalAdult,
        },
    ];

    if (searchInfo?.child > 0) {
        baseFareData.push({
            text: `${searchInfo.child} Child passenger${searchInfo.adult > 1 ? `s` : ""
                }`,
            price: pricesToShow?.Base.TotalChild,
        });
    };
    let bagPrice = pricesToShow?.Charges?.Baggage;
    let mealPrice = pricesToShow?.Charges?.Meals;
    let seatPrice = pricesToShow?.Charges?.Seat;
    let addOnPriceData = [];
    if (bagPrice && bagPrice > 0) {
        addOnPriceData.push({ text: "Extra Baggage", price: bagPrice });
    };
    if (mealPrice && mealPrice > 0) {
        addOnPriceData.push({ text: "Added Meals", price: mealPrice });
    };
    if (seatPrice && seatPrice > 0) {
        addOnPriceData.push({ text: "Selected Seat", price: seatPrice });
    };

    return (
        <div>
            {show
                ?
                <div className="container expanded-summary pb-4">
                    <ExpandedRow title="Base Fare" data={[
                        { text: "Passenger", price: pricesToShow.Base.TotalAdult + pricesToShow.Base.TotalChild },
                    ]} />
                    {addOnPriceData.length > 0 ? (
                        <ExpandedRow title="Add-on Services" data={addOnPriceData} />
                        // <ExpandedRow
                        //   title="Add-on Services"
                        //   data={[
                        //     { text: "SSR", price: addOnPriceData.reduce((a, b) => a + b.price, 0) },
                        //   ]} />
                    ) : (
                        <></>
                    )}
                    <ExpandedRow
                        title="Taxes & Charges"
                        data={[
                            {
                                text: "Tax (includes GST)",
                                price:
                                    pricesToShow.Charges.FareTax.TotalAdult +
                                    pricesToShow.Charges.FareTax.TotalChild +
                                    pricesToShow.Charges.TotalGST,
                            },
                            {
                                text: "Booking charge (includes gateway charges)",
                                price: pricesToShow.ServiceCharges,
                            },
                        ]}
                    />
                    {pricesToShow?.AgentAddOnMarkup > 0
                        ?
                        <ExpandedRow
                            title="Agent Markup"
                            data={[{ text: "", price: pricesToShow.AgentAddOnMarkup }]}
                        />
                        :
                        <></>}
                </div>
                :
                <></>}

            <div
                className="container"
                onClick={() => {
                    setShow((prev) => !prev);
                }}
            >
                <div className="d-flex justify-content-between my-auto">
                    {/* <div className="d-flex flex-column">
                        <span className="fs-4">
                            Fare Summary{" "}
                            {show ? <FaAngleDown size={20} /> : <FaAngleUp size={20} />}
                        </span>
                    </div> */}
                    <div></div>
                    <div
                        className={`d-flex flex-col gap-4 align-items-center ${show && "flex-column"
                            }`}
                    >
                        {show
                            ?
                            <div className={`text-end ${show && "w-100"}`}>
                                {/* {!show && <span>Booking Total</span>} */}
                                <span>Journey Total</span>
                                <h5 className="fw-bold">
                                    {priceFormatter(pricesToShow?.NetPayable)}
                                </h5>
                            </div>
                            :
                            <></>}
                        {/* {isLoggedIn || activeStep < 3 ? (
                            <Button
                                color="light"
                                className="text-primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onStepChange();
                                }}
                                disabled={actionDisabled}
                                isLoading={isUpdatingPassengers}
                                label={
                                    <span>
                                        Continue {activeStep === 3 && isPassengerUpdated ? "to payment" : ""}{" "}
                                        <FaAngleRight className=" ps-2 mb-1" />
                                    </span>
                                }
                            />
                        ) : (
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(toggle("showSignInModal"));
                                }}
                                color="light"
                                className="text-primary"
                                label="Signin to Continue"
                            />
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    )
};



interface IExpandedRow {
    title: string;
    data: {
        text: string;
        price: number;
    }[];
};

const ExpandedRow = ({ title, data }: IExpandedRow) => {
    return (
        <div
            className="d-flex justify-content-between pb-2"
            style={{
                borderBottom: "1px solid #FFFFFF20",
            }}
        >
            <span className="fw-bold w-50">{title}</span>
            <div className="d-flex flex-column w-100 ms-auto">
                {data.map((d) => (
                    <div className="d-flex justify-content-between">
                        <span>{d.text}</span>
                        <span className="fw-bold">{priceFormatter(d.price)}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
