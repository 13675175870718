import React, { FC, useRef, useState } from "react";
import "./Input.scss";
import { motion } from "framer-motion";
interface IProps {
  onChange: (value: string | number) => void;
  label: string;
  value: string;
  placeholder?: string;
  suggestionArray?: ISuggestionData[];
}
interface ISuggestionData {
  title: string;
  airportCode: string;
  subtitle?: string;
  value: string | number;
  label: string | number;
  icon?: JSX.Element | string;
}
interface ISuggestionCard {
  onClick: () => void;
  title: string;
  label?: string;
  subtitle: string;
  icon?: JSX.Element | string;
}

const SuggestionCard: FC<ISuggestionCard> = ({
  onClick,
  title,
  label,
  subtitle,
  icon,
}) => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      className=" input_show-suggestion--card custom-scrollbar"
      onMouseDown={() => {
        onClick();
      }}
    >
      <div className="d-flex align-items-center">
        {/* {icon ? (
          typeof icon === "string" ? (
            <span style={{ fontSize: "0.8em" }}>{icon}</span>
          ) : (
            icon
          )
        ) : (
          <City />
        )} */}
        <div className="d-flex flex-column  pr-3">
          <span className="fw-bold">
            {title} {icon !== undefined ? `(${icon})` : ""}
          </span>
          <span className="text-muted" style={{ fontSize: "0.8em" }}>
            {subtitle}
          </span>
        </div>
      </div>
      <div className="small fw-bold text-muted">
        <span>{label}</span>
      </div>
    </motion.div>
  );
};

const Input2: FC<IProps> = ({
  onChange,
  label,
  value,
  placeholder,
  suggestionArray,
}) => {


  const inputFocus = useRef<HTMLInputElement>(null);

  const [show, setShow] = useState<boolean>(false);
  const [text, setText] = useState<string>("");


  // Filter suggestion
  let filteredSuggestion: ISuggestionData[] = React.useMemo(() => {

    let filteredCities: ISuggestionData[] = [];

    if (text?.length === 0) {

      filteredCities = suggestionArray?.filter(
        (sug) =>
          sug?.icon === "DEL" ||
          sug?.icon === "BOM" ||
          sug?.icon === "MAA" ||
          sug?.icon === "CCU"
      ) || [];

      return filteredCities?.slice(0, 15);

    } else {

      suggestionArray
        ?.forEach((item) => {

          let searchedByAirportCode = item.airportCode?.toLowerCase() == text.toLowerCase();
          if (searchedByAirportCode) filteredCities.push(item);

        });

      suggestionArray
        ?.forEach((item) => {

          let searchedByCityName = item.title?.toLowerCase()?.includes(text.toLowerCase());
          if (searchedByCityName) filteredCities.push(item);

        });

      return filteredCities?.slice(0, 15);

    };

  }, [text, suggestionArray]
  );

  return (
    <div className="input rounded w-100" onMouseLeave={() => setShow(false)}>
      <div
        className={`${show ? "input_show shadow-lg" : "rounded w-100"}`}
        // onFocus={() => {
        //     setShow(true);
        //   }}
        // onBlur={() => {
        //     setShow(false);
        //   }}
        onClick={() => {
          setShow(true);
          inputFocus.current?.focus();
        }}
      >
        {/* <div className="input-group border border-2 p-0 rounded">
          <span className="my-auto px-2 small fw-semibold">{label}</span>
          <span className="my-auto bg-primary text-white rounded">
            {String(value).length > 0 && value !== undefined && (
              <span className="badge bg-primary p-2 fs-7">{value}</span>
            )}
          </span>
          <input
            type="text"
            style={{ height: "40px" }}
            onChange={(e) => {
              const { value } = e.target;
              setText(String(value));
            }}
            className="form-control form-control-sm border-0 shadow-none"
          />
          <span className="my-auto ps-2 fw-semibold">
            {String(value).length > 0 && value !== undefined && (
              <span
                className="me-1 pointer"
                onClick={() => {
                  onChange("");
                }}
              >
                <Close />
              </span>
            )}
          </span>
        </div> */}
        {!show ? (
          <div
            className="py-1 px-2 overflow-hidden"
            style={{
              height: "56px",
            }}
          >
            <span className="small text-muted">{label}</span>
            {value ? (
              <p className="fw-bold">
                {value}
                {/* <span className="small">
                  {
                    suggestionArray?.find((sug) => sug?.title === value)
                      ?.subtitle
                  }
                </span> */}
              </p>
            ) : (
              <p className="text-muted ms-auto">Search City</p>
            )}
          </div>
        ) : (
          <div>
            <input
              className="form-control"
              tabIndex={0}
              type="text"
              ref={inputFocus}
              autoFocus
              onChange={(e) => {
                const { value } = e.target;
                setText(String(value));
              }}
              placeholder="Search city"
            />
            <div className="input_show-suggestion">
              {filteredSuggestion?.map((item, index: number) => (
                <SuggestionCard
                  key={index}
                  title={item.title}
                  onClick={() => {
                    onChange(item.value);
                    setText("");
                    setShow(false);
                  }}
                  subtitle={item?.subtitle || ""}
                  label={String(item.label)}
                  icon={item?.icon}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input2;
