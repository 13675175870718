import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RiHotelFill } from "react-icons/ri";
import { GiCommercialAirplane } from "react-icons/gi";
import { FaAngleRight, FaBusAlt } from "react-icons/fa";

import Button from "../Common/Button/Button";
import SearchHotelData from "../../pages/Hotel/Components/SeachHotel/SearchHotelData";

import { State } from "../../redux/types";
import { flightSlice } from "../../redux/reducers/flight";
import { commonStateSlice } from "../../redux/reducers/commonState";
import SearchFlightForm from "../../pages/Flight/FlightSearch/SearchFlightForm";

interface Props {
  searchForm?: JSX.Element;
  onSearch?: () => void;
};

const NavItem = ({
  isActive,
  onClick,
  label,
  icon,
  isDisabled,
}: {
  isActive?: boolean;
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
  isDisabled?: boolean;
}) => {
  return (
    <div
      className={`search-tab--bar__item ${isActive ? "active" : ""} ${isDisabled ? "disabled_field text-muted" : ""
        }`}
      onClick={() => !isDisabled && onClick()}
    >
      {icon ? <div className="icon">{icon}</div> : null}
      <h4>{label}</h4>
    </div>
  );
};

const SearchTab = (props: Props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { searchHotel } = useSelector((state: State) => state.hotel);
  const { searchInfo } = useSelector((state: State) => state.flight);
  const { ClientData, activeTab } = useSelector((state: State) => state.commonState);


  useEffect(() => {
    dispatch(flightSlice.actions.resetPassengers());
  }, []);

  const isValidated = useCallback(() => {
    return activeTab?.ServiceId === 1
      ? //  validate Flight
      searchInfo?.fromCity[0]?.RouteDestinationCode?.length === 3 &&
      searchInfo?.toCity[0]?.RouteDestinationCode?.length === 3 &&
      searchInfo?.departureDate[0]?.length > 0 &&
      Number(searchInfo?.adult) > 0 &&
      (searchInfo?.travelType?.SearchTypeId === 2
        ? searchInfo?.departureDate[1]?.length > 0
        : true) &&
      (searchInfo?.travelType?.SearchTypeId === 3
        ? searchInfo?.departureDate?.length === searchInfo?.toCity?.length
        : true)
      : //  validate Hotel
      !(
        (
          searchHotel.whereTo?.HB_DestinationNumber?.length === 0 && searchHotel.whereTo?.HT_DestinationNumber?.length === 0
          &&
          searchHotel.geoLocation.name?.length === 0
        )
        ||
        searchHotel.checkInDate === searchHotel.checkOutDate
      );
  }, [searchInfo, activeTab, searchHotel]);

  const handleSearchClick = () => {
    if (activeTab?.ServiceId === 1) {
      history.push("/flight");
    } else if (activeTab?.ServiceId === 2) {
      history.push("/hotel");
    }
  };



  return (
    <>
      <div className="hero">
        <div className="overlay-gradient"></div>
        <div
          className="background-img"
          style={{
            background: `url(${require("../../assets/images/bg-hero.jpg").default
              }) no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="overlay-text">
          <div className="inner">
            <div className="badge bg-primary h5">
              Great deals at best prices
            </div>
            <h1 className="display-5 fw-bold w-50">
              {activeTab?.ServiceId === 1
                ? "Fly with comfort and luxury at subpar prices"
                : "Search your perfect getaway Home."}
            </h1>
          </div>
        </div>
      </div>
      <div className="search-panel-container">
        <div className="container">
          <div className="tabs d-flex gap-1">
            {ClientData?.Services?.map((item, index: number) => {
              return (
                item?.ServiceName !== "Bus" && (
                  <div
                    className={`tab-item ${item?.ServiceId === activeTab?.ServiceId ? "active" : ""
                      } ${item.ServiceName === "Bus" ? "disabled" : ""}`}
                    key={index}
                    onClick={() => {
                      dispatch(commonStateSlice.actions.setActiveTab(item));
                      // history.replace("/");
                    }}
                  >
                    <div className="icon">
                      {
                        item.ServiceName === "Flight" ? (
                          <GiCommercialAirplane size={16} />
                        ) : (
                          <RiHotelFill size={16} />
                        )
                        // item.ServiceName === "Hotel" ? (
                        //   <RiHotelFill size={16} />
                        // ) : (
                        //   <FaBusAlt size={16} />
                        // )
                      }
                    </div>
                    {item?.ServiceName}
                  </div>
                )
              );
            })}
          </div>
          <div className="search-body">
            {activeTab?.ServiceId === 1 ? (
              <SearchFlightForm
                isPreSearch={true}
                isSearching={false}
                onSearch={() => { }}
              />
            ) : (
              <>
                <SearchHotelData />
              </>
            )}
            <Button
              className="mt-2 search-btn"
              disabled={!isValidated()}
              onClick={() => handleSearchClick()}
              label={
                <span>
                  Search {activeTab?.ServiceId === 1 ? "Flight" : "Hotel"}{" "}
                  <FaAngleRight size={16} />
                </span>
              }
            />
          </div>
        </div>
      </div>
      {/* <div
        className="search"
        style={{
          background: `url(${
            require("../../assets/images/bg-hero.png").default
          }) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <div className="search-tab container">
          <div className="search-tab--bar">
            {ClientData?.Services.map((item, index: number) => (
              <NavItem
                key={index}
                onClick={() => {
                  dispatch(commonStateSlice.actions.setActiveTab(item));
                  // history.replace("/");
                }}
                label={item?.ServiceName}
                isActive={item?.ServiceId === activeTab?.ServiceId}
                icon={
                  item.ServiceName === "Flight" ? (
                    <GiCommercialAirplane size={30} />
                  ) : item.ServiceName === "Hotel" ? (
                    <RiHotelFill size={30} />
                  ) : (
                    <FaBusAlt size={30} />
                  )
                }
                isDisabled={item.ServiceName === "Bus"}
              />
            ))}
          </div>
          <div className="search-tab--body">
            {activeTab?.ServiceId === 1 ? (
              <SearchFlightForm
                isPreSearch={true}
                isSearching={false}
                onSearch={() => {}}
              />
            ) : (
              <>
                <div className="spacer spacer--lg"></div>
                <SearchHotelForm />
              </>
            )}
          </div>

          <div className="search-tab--footer">
            <Button
              disabled={!isValidated()}
              label={
                <span className="ps-4 pe-3 py-2 pe-1 fs-5 d-flex align-items-center">
                  Search <FaAngleRight size={25} />
                </span>
              }
              onClick={() => handleSearchClick()}
              className="rounded-pill py-1"
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SearchTab;
