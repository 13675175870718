import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IModal } from "./InputModal";

const Modal = forwardRef(({ body = <div>Body</div> }: IModal, ref) => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const [show, setShow] = useState(false);
  const modalDiv = useRef() as MutableRefObject<HTMLDivElement>;
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => setShow(false);
  const setModalPosition = ({ x, y }: { x: number; y: number }) => {
    setPosition({
      x,
      y,
    });
  };

  useImperativeHandle(ref, () => ({
    showModal,
    setModalPosition,
    hideModal,
  }));

  useEffect(() => {
    if (show) {
      modalDiv.current?.focus();
    }
  }, [show]);

  return (
    <>
      <div
        ref={modalDiv}
        className="wrapper-modalbox"
        style={{
          position: "absolute",
          top: position.y,
          left: position.x,
          zIndex: 101,
          display: show ? "block" : "none",
          borderRadius: "3px",
        }}
        tabIndex={0}
        onMouseLeave={() => hideModal()}
      >
        <button className="d-none" onClick={() => setShow(false)} type="button">
          &times;
        </button>
        <div>{body}</div>
      </div>
    </>
  );
});

export default Modal;
