import React, { useRef } from 'react';
import moment from 'moment';
import Barcode from 'react-barcode';
import { FaHotel, FaPrint } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import { BsDownload } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { StarFull } from '@kiwicom/orbit-components/lib/icons';
import { BiLeftArrow, BiRightTopArrowCircle, BiRupee, BiUser } from 'react-icons/bi';
import { IoBagCheckSharp, IoCall, IoDownloadOutline, IoMail, IoTicketSharp } from 'react-icons/io5';

import "./BookingDetails.scss";

import TicketInvoice from '../../Component/TicketInvoice';
import BsModal from '../../../../components/Modal/BSModal';
import Image from '../../../../components/Common/Image/Image';
import Button from '../../../../components/Common/Button/Button';


// @ts-ignore
import html2pdf from 'html2pdf.js';
import useHotelDetails from '../../../../utils/hooks/Hotel/useHotelDetails';
import useHotelBookingDetails from '../../Hooks/Hotel/useHotelBookingDetails';
import { priceFormatter } from '../../../../utils/utilityFunctions/utilityFunction';
import { calculateDateForFreeCancellation, calculateDateForCancellation, isDateAfter } from '../../../../utils/utilityFunctions/hotelUtitlityFunctions';

export default function HotelBookingDetails() {

    const {
        history,
        isLoading,
        isHotelBedsBookingDetailsLoading,
        netPayable,
        ticketDetails,
        isOpenModal,
        setIsOpenModal,
        isOpenInvoiceModal,
        setIsOpenInvoiceModal,
        bookedHotelInformation,
        bookingDetailsFromHotelBeds,
        cancelBooking
    } = useHotelBookingDetails();

    const invoiceRef: any = useRef(null);
    const componentRef: any = useRef(null);

    const parseJSONString = (value: string) => {
        // console.log("parseJSONString(bookedHotelInformation.RateComments): ", bookedHotelInformation.RateComments && bookedHotelInformation.RateComments);
        return JSON.parse(value) as string[];

    };

    const handlePrint = () => {
        var element = componentRef.current;

        html2pdf()
            .set({ top: 2 })
            .from(element)
            .save(`e-Ticket_${ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || '--'}`);
    };

    const handlePrintInvoice = () => {
        var element = invoiceRef.current;

        html2pdf()
            .set(
                {
                    margin: 6
                    , top: 8,
                    bottom: 2
                })
            .from(element)
            .save(`Invoice${ticketDetails?.lstTicketBooking[0]?.InvoiceNumber || '--'}`);
    };



    return (
        <div className="booking-details p-4 fakeBody">

            <div className="container">

                <div className="d-flex gap-3 justify-content-end mb-3">
                    <Button
                        label={<div><BiLeftArrow />  Go Back</div>}
                        onClick={() => history.goBack()}
                    />
                </div>
                <div className='row gap-2'>
                    {/* LEFT PANEL */}
                    <div className='col'>
                        <div className='d-flex justify-content-between fw-bolder text-muted'>
                            <p className='m-0 p-1 pb-2'>
                                <span className='text-primary fw-bolder'>Booking Ref No : </span>
                                {ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || ""}
                            </p>
                            <p className='m-0 p-1 pb-2'>
                                <span className='text-primary fw-bolder'>Invoice No : </span>
                                {ticketDetails?.lstTicketBooking[0]?.InvoiceNumber || ""}
                            </p>
                            <p className='m-0 p-1 pb-2'>
                                <span className='text-primary fw-bolder'>Booking Date : </span>
                                {moment(ticketDetails?.lstTicketBooking[0]?.CreatedDate).format("DD-MM-YYYY  ( hh:mm a )")}
                            </p>
                        </div>

                        {/* TRIPS */}
                        {ticketDetails?.lstTicketBooking?.map((eachTrip, index) => (
                            <div className="flight-detail p-3 mb-3" key={index + "trip"}>
                                <div>
                                    <h4 className="mb-0">{eachTrip?.HotelName ?? ""}</h4>
                                    <span className="text-muted text-sm">{eachTrip?.HotelAddress ?? ""}</span>
                                </div>

                                <hr />

                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div>
                                        <p className="m-0 text-muted">Check In</p>
                                        <p className="m-0 text-muted fw-bolder fs-5">{moment(eachTrip?.TravelFromDate).format("MMM, DD YYYY")}</p>
                                    </div>
                                    <div>
                                        <span className="badge rounded-pill bg-secondary">
                                            {moment(eachTrip?.TravelToDate).diff(eachTrip?.TravelFromDate, "days")} {"NIGHTS"}
                                        </span>
                                    </div>
                                    <div>
                                        <p className="m-0 text-muted">Check Out</p>
                                        <p className="m-0 text-muted fw-bolder fs-5">{moment(eachTrip?.TravelToDate).format("MMM, DD YYYY")}</p>
                                    </div>
                                </div>

                                <hr />

                                {/* GUEST DETAILS */}
                                {ticketDetails?.lstTicketHotelBookingGuests?.map((eachTraveler, index) => (
                                    <div className='traveller-details row text-muted' key={index + "traveler"}>
                                        <div className='col col-lg-1 align-self-center mb-4'>
                                            <BiUser size={30} />
                                        </div>
                                        <div className='col col-lg-4'>
                                            <p className='text-secondary fw-bolder m-0'>
                                                TRAVELLER
                                            </p>
                                            <p className=' m-0 text-muted fs-8'>
                                                <span className='fw-bolder fs-7 text-dark'>
                                                    {eachTraveler?.GuestName || " - "}
                                                    {eachTraveler?.Age ? <div className="badge bg-light text-dark">Age - {eachTraveler?.Age}</div> : ""}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='col'>
                                            <p className='m-1 text-secondary fw-bolder'>
                                                Room
                                            </p>
                                            <p className='fs-8 m-1 text-muted ' >
                                                {eachTraveler?.RoomDetails}
                                            </p>
                                        </div>
                                        <div className='col col-lg-3'>
                                            <p className='  m-1 text-secondary fw-bolder'>
                                                BOOKING STATUS
                                            </p>
                                            <p className='fs-8 m-1 text-muted'>
                                                {eachTraveler?.BookingStatus}
                                            </p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        ))}

                        {/* <div className='info-detail p-3 mt-5'>
                            <h2 className='title-text'>IMPORTANT INFORMATION</h2>
                            <p className='fs-6 fw-bold m-0'>
                                <GoPrimitiveDot size={18} /> AGE/Id/PROOF :
                            </p>
                            <p className='fs-8'>
                                Only student above 12 years of age eligible for special fares and/or additional baggage allowances. Carrying valid student ID cards
                                and student visas (where application) is mandatory . else the passenger may be denied boarding or asked to pay for extra baggage
                            </p>
                            <p className='fs-6 fw-bold m-0'>
                                <GoPrimitiveDot size={18} /> Checked travel guidelines and baggage information below :
                            </p>
                            <p>
                                Wearing mask/face cover is no longer mandatory. However, all travellers are advised to wear them, in view of the threat posed by
                                COVID-!(. For the complete list of travel guidelines issued Indian States and UT's , Carry no more 1 check-in-baggage and 1 hand
                                baggage per passenger. If violated ,airline may levy extra charges)
                            </p>
                        </div>
                        <div className='ticket-detail mt-3'>
                            <p className='fs-6 fw-bold m-0'>
                                CANCELLATION
                            </p>
                            <p className='fs-7 text-muted'>
                                Your flight has already departed, online cancellation is not allowed.
                            </p>
                        </div> */}

                        {/* PRIMARY GUEST DETAILS */}
                        <div className='ticket-detail mt-3'>
                            <p className='fs-6 fw-bold m-0'>
                                CONTACT INFORMATION
                            </p>
                            <p className='fs-7 text-muted'>
                                Information about primary traveller. Any communication by airlines will be sent to these details
                            </p>
                            <div className='row'>
                                <div className='col'>
                                    <p className='text-muted fs-7'>
                                        <BiUser className='mx-4' />
                                        <span>
                                            {ticketDetails?.lstTicketBooking[0]?.FirstName}
                                            {" "}
                                            {ticketDetails?.lstTicketBooking[0]?.LastName
                                                ?
                                                " " + ticketDetails?.lstTicketBooking[0]?.LastName
                                                :
                                                ""}
                                        </span>
                                    </p>

                                    <p className='text-muted fs-7'>
                                        <IoMail className='mx-4' />
                                        <span>
                                            {ticketDetails?.lstTicketBooking[0]?.CustomerEmail
                                                ?
                                                ticketDetails?.lstTicketBooking[0]?.CustomerEmail
                                                :
                                                " - "}
                                        </span>
                                    </p>
                                    <p className='text-muted fs-7'>
                                        <IoCall className='mx-4' />
                                        <span>
                                            {ticketDetails?.lstTicketBooking[0]?.CustomerMobile
                                                ?
                                                ticketDetails?.lstTicketBooking[0]?.CustomerMobile
                                                :
                                                " - "}
                                        </span>
                                    </p>
                                </div>
                                <div className='col '>
                                    <p></p>
                                    {/* <p className='text-end pointer text-primary'><BiRightTopArrowCircle className='mx-3' /> Send E-Ticket on Email and Mobile No.</p> */}
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* RIGHT PANEL */}
                    <div className='col col-lg-3 mt-4'>
                        {/* DOWNLOAD TICKET */}
                        <div className='ticket-detail'>
                            <p className='fs-5 fw-bolder m-0'>Ticket(s)</p>
                            <div
                                className='row pointer m-1 '
                                onClick={() => setIsOpenModal(true)}
                            >
                                <div className='col col-lg-1 text-primary'><IoDownloadOutline /></div>
                                <div className='col text-primary '><p className='m-0 fw-bolder'>Download E-ticket(s) </p></div>
                            </div>
                            {/* <div className='row  m-1 '>
                                <div className='col col-lg-1 text-muted'><IoShareSocialOutline /></div>
                                <div className='col text-muted '><p className='m-0 fw-bolder'>Email/SMS E-ticket(s) </p></div>
                            </div> */}
                            {/* <div className='row pointer m-1 '
                                onClick={() => setIsOpenInvoiceModal(true)}>
                                <div className='col col-lg-1 text-primary'><IoDownloadOutline /></div>
                                <div className='col text-primary '><p className='m-0 fw-bolder'>Download Invoice(s) </p></div>
                            </div> */}
                        </div>

                        {/* <div className='ticket-detail mt-3'>
                            <div className='row'>
                                <p className='col fs-5 fw-bolder'>Airline Contact</p>
                                <IoCall size={23} className='col col-lg-2 text-end' />
                            </div>
                            <div className='row'>
                                <div className='col col-lg-2 text-end'>
                                    <Image width={30} src={require('../../../assets/images/takeOff.png').default} />
                                </div>
                                <div className='col text-start'>
                                    <p className='m-0'>IndiDo</p>
                                    <p className='fw-bolder fs-8 m-0'>0124-6173838 , 0124-4973838</p>
                                </div>

                            </div>
                        </div> */}

                        {/* PRICE BREAKUP */}
                        {/* <div className='price-detail mt-3'>
                            <p className='fw-bolder fs-5'>Price Breakup</p>
                            <div className='row border-bottom py-1 mt-2'>
                                <p className='col label '>{"Ticket Charge"}</p>
                                <p className='col value col-lg-4'>
                                    <BiRupee />
                                    <span>{netPayable.totalTravelerAmount ?? 0}</span>
                                </p>
                            </div>
                            {
                                ticketDetails?.lstTicketCharges?.map((eachCharge, index) => (
                                    eachCharge?.ChargeName !== "My Markup"
                                        ?
                                        <div className='row border-bottom py-1 my-2' key={index + "charge"}>
                                            <p className='col label '>{eachCharge?.ChargeName}</p>
                                            <p className='col value col-lg-4'>
                                                <BiRupee />
                                                <span>{eachCharge?.Amount ?? 0}</span>
                                            </p>
                                        </div>
                                        :
                                        <></>
                                ))
                            }
                            <div className='row'>
                                <p className='col value'>Total Amount</p>
                                <p className='col value col-lg-4'>
                                    <BiRupee />
                                    <span>
                                        {netPayable.netAmount}
                                    </span>
                                </p>
                            </div>
                        </div> */}

                        {/* CANCELLATION POLICIES */}
                        {/* <div>
                            <p className='dotted-border mt-4 fs-6 fw-bolder'>CANCELLATION POLICIES</p>
                            <ul className="list-group list-group-flush pl-3">
                                {
                                    bookedHotelInformation.CancellationPolicies && JSON.parse(bookedHotelInformation.CancellationPolicies)?.length > 0
                                        ?
                                        JSON.parse(bookedHotelInformation.CancellationPolicies)?.map(
                                            (
                                                eachPolicy: {
                                                    amount?: string | undefined;
                                                    hotelAmount?: string | undefined;
                                                    percent?: number | undefined;
                                                    numberOfNights?: number | undefined;
                                                    from: string;
                                                }[],
                                                index: number
                                            ) => <>
                                                    {
                                                        eachPolicy?.map(
                                                            (policy, i) => (
                                                                <li className="list-group-item justify-content-center px-0" key={i}>
                                                                    {
                                                                        isDateAfter(
                                                                            moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                                                                            calculateDateForFreeCancellation(policy?.from)
                                                                        )
                                                                            ?
                                                                            <div className="badge rounded-pill bg-danger">Refund is Not Available</div>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {i === 0
                                                                        ?
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div className="text-success">
                                                                                <span>Free until <span className="fw-bold small">
                                                                                    {calculateDateForFreeCancellation(policy?.from)}
                                                                                </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>}
                                                                    <div className="d-flex align-items-center justify-content-between text-danger small">
                                                                        <span>After <span className="fw-bold">
                                                                            {calculateDateForFreeCancellation(policy?.from)}
                                                                        </span>
                                                                        </span>
                                                                        <span className="small">
                                                                            <label className="fw-bold small">Charges:</label>
                                                                            {policy?.amount && <span>{" "}{priceFormatter(+policy?.amount, "INR")}</span>}
                                                                            {policy?.percent && <span>{policy?.percent}%</span>}
                                                                            {policy?.numberOfNights && <span>{policy?.numberOfNights} Night</span>}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                    }
                                                </>)
                                        :
                                        <div className="h-25 d-flec justify-content-center align-items-center">
                                            <span className="text-muted">Cancellation policy is not available to show for this booking</span>
                                        </div>
                                }
                            </ul>

                            <div className="mt-3 d-flex justify-content-end">
                                {
                                    isHotelBedsBookingDetailsLoading
                                        ?
                                        <></>
                                        :
                                        // ticketDetails?.lstTicketCurrentStatus[0]?.TicketStatusId == 2
                                        //     &&
                                        bookingDetailsFromHotelBeds?.status !== "CANCELLED"
                                            &&
                                            ticketDetails?.lstTicketBooking[0]?.TravelFromDate
                                            &&
                                            !moment(new Date()).isAfter(ticketDetails?.lstTicketBooking[0]?.TravelFromDate)
                                            ?
                                            <Button
                                                type={"button"}
                                                size={"sm"}
                                                color="danger"
                                                outline={true}
                                                // className={"btn-danger"}
                                                label={"Cancel Booking"}
                                                onClick={() => {
                                                    // onRemove();
                                                    // setViewDetails(false);
                                                    cancelBooking(
                                                        // ticketDetails?.lstTicketBooking[0]?.PNR || "0",
                                                        bookedHotelInformation.BookingReferenceNumber || "",
                                                        ticketDetails?.lstTicketBooking[0]?.TicketID || 0
                                                    )
                                                }}
                                            />
                                            :
                                            <></>
                                }
                            </div>
                            {bookingDetailsFromHotelBeds?.status == "CANCELLED"
                                ?
                                <p className="fw-bold font-danger">This booking has been canceled</p>
                                :
                                <></>}
                        </div> */}

                        {/* CANCELLATION POLICIES NEW */}
                        <div>
                            <p className='dotted-border mt-4 fs-6 fw-bolder'>CANCELLATION POLICIES</p>
                            <ul className="list-group list-group-flush pl-3">
                                {/*  list-group-numbered */}
                                {
                                    Array.isArray(bookingDetailsFromHotelBeds?.hotel?.rooms) && bookingDetailsFromHotelBeds?.hotel?.rooms?.length
                                        ?
                                        bookingDetailsFromHotelBeds?.hotel?.rooms?.map((eachRoom: any, index: number) =>
                                            <>
                                                {
                                                    eachRoom?.rates?.map((eachRates: any, idx: number) => (
                                                        <>
                                                            {
                                                                eachRates?.cancellationPolicies?.map((
                                                                    policy: {
                                                                        amount?: string | undefined;
                                                                        hotelAmount?: string | undefined;
                                                                        percent?: number | undefined;
                                                                        numberOfNights?: number | undefined;
                                                                        from: string;
                                                                    },
                                                                    i: number
                                                                ) => (
                                                                    <li className="list-group-item justify-content-center px-0" key={i}>
                                                                        {/* <div className="bg-info d-flex gap-5 text-success small"> */}
                                                                        {
                                                                            isDateAfter(
                                                                                moment().format("YYYY-MM-DDTHH:mm:ssZ"),
                                                                                calculateDateForFreeCancellation(policy?.from)
                                                                            )
                                                                                ?
                                                                                <div className="badge rounded-pill bg-danger">Refund is Not Available</div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {i === 0
                                                                            ?
                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                <div className="text-success">
                                                                                    <span>Free until <span className="fw-bold small">
                                                                                        {calculateDateForFreeCancellation(policy?.from)}
                                                                                    </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <></>}
                                                                        {/* </div> */}
                                                                        <div className="d-flex align-items-center justify-content-between text-danger small">
                                                                            <span>After <span className="fw-bold">
                                                                                {calculateDateForFreeCancellation(policy?.from)}
                                                                            </span>
                                                                            </span>
                                                                            <span className="small">
                                                                                <label className="fw-bold small">Charges:</label>
                                                                                {policy?.amount && <span>{" "}{priceFormatter(+policy?.amount, "INR")}</span>}
                                                                                {policy?.percent && <span>{policy?.percent}%</span>}
                                                                                {policy?.numberOfNights && <span>{policy?.numberOfNights} Night</span>}
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </>)
                                        :
                                        <div className="h-25 d-flec justify-content-center align-items-center">
                                            <span className="text-muted">Cancellation policy is not available to show for this booking</span>
                                        </div>
                                }
                            </ul>

                            <div className="mt-3 d-flex justify-content-end">
                                {
                                    isHotelBedsBookingDetailsLoading
                                        ?
                                        <></>
                                        :
                                        // ticketDetails?.lstTicketCurrentStatus[0]?.TicketStatusId == 2
                                        //     &&
                                        bookingDetailsFromHotelBeds?.status !== "CANCELLED"
                                            &&
                                            ticketDetails?.lstTicketBooking[0]?.TravelFromDate
                                            &&
                                            !moment(new Date()).isAfter(ticketDetails?.lstTicketBooking[0]?.TravelFromDate)
                                            ?
                                            <Button
                                                type={"button"}
                                                size={"sm"}
                                                color="danger"
                                                outline={true}
                                                // className={"btn-danger"}
                                                label={"Cancel Booking"}
                                                onClick={() => {
                                                    // onRemove();
                                                    // setViewDetails(false);
                                                    cancelBooking(
                                                        // ticketDetails?.lstTicketBooking[0]?.PNR || "0",
                                                        bookedHotelInformation.BookingReferenceNumber || "",
                                                        ticketDetails?.lstTicketBooking[0]?.TicketID || 0
                                                    )
                                                }}
                                            />
                                            :
                                            <></>
                                }
                            </div>
                            {bookingDetailsFromHotelBeds?.status == "CANCELLED"
                                ?
                                <p className="fw-bold font-danger">This booking has been canceled</p>
                                :
                                <></>}
                        </div>
                    </div>
                </div>

            </div>

            {/* E-TICKET/VOUCHER */}
            {isOpenModal
                ?
                <BsModal
                    onClose={() => setIsOpenModal(false)}
                    title={'E-Ticket'}
                    body={
                        <div>
                            <div
                                ref={componentRef}
                                className='download-ticket-modal'>
                                {/* <div className='ticketHeader'>
                            < p className='label'>e-Ticket</p>
                            < p className='label fs-6'>Booking Reference : <span className='fs-6'> {ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || ""} </span></p>
                        </div> */}

                                {/* COMPANY INFORMATION */}
                                <div className='header-with-logo'>
                                    <div>
                                        <p className='par fs-4 fw-bolder'>Paul Merchants Ltd</p>
                                        <p className='par fs-6 fw-bolder'>Corp.Off.PML House</p>
                                        <p className='par fs-6 fw-bolder'>S.C.O. 829830, Sector 22-A</p>
                                        <p className='par fs-6 fw-bolder'>Chandigarh</p>
                                        <p className='par fs-6 fw-bolder'>Phone: 9317714203</p>
                                    </div>
                                    <Image
                                        className='logo'
                                        height={80}
                                        src={require('../../../../assets/images/logo.png').default} />
                                    <div>
                                        <p className='w-full text-end fw-bold fs-5'>e-Ticket</p>
                                        <p className='par label fs-6 fw-bolder'>
                                            Booking Reference :
                                            <span className='fs-6'> {ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || ""}
                                            </span></p>
                                        {/* <p className='par fs-6 fw-bolder'>PNR :Qp-E5IFGM</p> */}
                                        <p className='par fs-6 fw-bolder'>Issued Date : {ticketDetails?.lstTicketBooking[0]?.CreatedDate ? moment(ticketDetails?.lstTicketBooking[0]?.CreatedDate).format("ddd DD MMM YYYY") : ""}</p>
                                    </div>
                                </div>

                                {/* HOTEL INFORMATION */}
                                <p className='dotted-border mt-4 fs-6 fw-bolder'>Hotel Information</p>
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-column flex-md-row gap-2 align-items-md-center">
                                        <h4 className="mb-0">{bookedHotelInformation?.HotelName ?? ""}</h4>
                                        <div className="star-rating">
                                            {
                                                bookedHotelInformation?.HotelCategory
                                                    &&
                                                    bookedHotelInformation?.HotelCategory?.split(" ")?.length > 1
                                                    ?
                                                    [...Array(+bookedHotelInformation?.HotelCategory?.split(" ")[0])].map((_, index) => (
                                                        <StarFull size="small" key={index} />
                                                    ))
                                                    :
                                                    bookedHotelInformation?.HotelCategory || ""
                                            }
                                        </div>
                                    </div>
                                    <span className="text-muted text-sm">{bookedHotelInformation?.HotelAddress ?? ""}</span>
                                    <span className="text-muted text-sm">{bookedHotelInformation?.HotelDescription ?? ""}</span>
                                    <span className="text-sm">Contact: {bookedHotelInformation?.HotelEmail ?? ""}</span>
                                    <span className="text-sm">Email: {bookedHotelInformation?.HotelContactNumber ?? ""}</span>
                                    <span className="text-sm">Postal Code: {bookedHotelInformation?.HotelPostalCode ?? ""}</span>
                                    <span className="text-sm">Website: {bookedHotelInformation?.Website ?? ""}</span>
                                </div>

                                {/* GUEST/PAX INFORMATION */}
                                <p className='dotted-border mt-4 fs-6 fw-bolder'>Guests</p>
                                <div>
                                    <table className='table table-hover'>
                                        <thead className='table-header'>
                                            <tr>
                                                <th>Guest Name</th>
                                                <th>Type</th >
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketDetails?.lstTicketHotelBookingGuests?.map(e =>
                                                <tr>
                                                    <td>
                                                        {e?.GuestName ?? " - "}
                                                        {e?.Age ? <div className="badge bg-light text-dark">Age - {e?.Age}</div> : ""}
                                                    </td>
                                                    <td>{e?.RoomDetails || "-"}</td>
                                                    <td>{e?.BookingStatus || "-"}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <p className='fw-bolder mb-0'>Note :</p>
                                    <p>This is an Electronic ticket. Please carry a positive identification for Check in.</p>
                                </div>

                                {/* TRAVEL ITINERARY */}
                                <p className='dotted-border mt-4 fs-6 fw-bolder'>Travel Itinerary</p>
                                <div>
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <div>
                                            <p className="m-0 text-muted">Check In</p>
                                            <p className="m-0 text-muted fw-bolder fs-5">{moment(ticketDetails?.lstTicketBooking?.[0]?.TravelFromDate).format("MMM, DD YYYY")}</p>
                                        </div>
                                        <div>
                                            <span className="badge rounded-pill bg-secondary">
                                                {moment(ticketDetails?.lstTicketBooking?.[0]?.TravelToDate).diff(ticketDetails?.lstTicketBooking?.[0]?.TravelFromDate, "days")} {"NIGHTS"}
                                            </span>
                                        </div>
                                        <div>
                                            <p className="m-0 text-muted">Check Out</p>
                                            <p className="m-0 text-muted fw-bolder fs-5">{moment(ticketDetails?.lstTicketBooking?.[0]?.TravelToDate).format("MMM, DD YYYY")}</p>
                                        </div>
                                    </div>

                                    {parseJSONString(bookedHotelInformation.RoomName).length
                                        ?
                                        <table className='table table-hover'>
                                            <thead className='table-header'>
                                                <tr>
                                                    <th>Room</th>
                                                    <th>Board</th >
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {parseJSONString(bookedHotelInformation.RoomName)?.map((each, index) =>
                                                    <tr key={index + "_info"}>
                                                        <td>{parseJSONString(bookedHotelInformation.RoomName)[index] ?? " - "}</td>
                                                        <td>
                                                            <>
                                                                {
                                                                    parseJSONString(bookedHotelInformation.BoardCode)[index] || "-"
                                                                }
                                                                :
                                                                {
                                                                    parseJSONString(bookedHotelInformation.BoardName)[index] || "-"
                                                                }
                                                            </>
                                                        </td>
                                                        <td>{bookedHotelInformation.RateComments ? parseJSONString(bookedHotelInformation.RateComments)?.[index] || "-" : "-"}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        :
                                        <></>}
                                </div>

                                <div className="html2pdf__page-break"></div>
                                {/* SUPPLIER INFORMATION */}
                                <p className='dotted-border mt-4 fs-6 fw-bolder'>Supplier Information</p>
                                <div className="d-flex flex-column">
                                    <span className="text-sm fw-semibold">{bookedHotelInformation?.SupplierName ?? ""}</span>
                                    <span className="text-sm">VAT Number: {bookedHotelInformation?.SupplierVatNumber ?? ""}</span>
                                    <span className="text-sm">Reference: {bookedHotelInformation?.BookingReferenceNumber ?? ""}</span>
                                </div>

                                {/* CANCELLATION POLICIES */}
                                <p className='dotted-border mt-4 fs-6 fw-bolder'>CANCELLATION POLICIES</p>
                                <ul className="list-group list-group-flush">
                                    {/*  list-group-numbered */}
                                    {
                                        bookedHotelInformation.CancellationPolicies && JSON.parse(bookedHotelInformation.CancellationPolicies)?.length > 0
                                            ?
                                            JSON.parse(bookedHotelInformation.CancellationPolicies)?.map(
                                                (
                                                    eachPolicy: {
                                                        amount?: string | undefined;
                                                        hotelAmount?: string | undefined;
                                                        percent?: number | undefined;
                                                        numberOfNights?: number | undefined;
                                                        from: string;
                                                    }[],
                                                    index: number
                                                ) => <>
                                                        {
                                                            eachPolicy?.map(
                                                                (policy, i) => (
                                                                    <li className="list-group-item justify-content-center px-0" key={index + i + "_each_policy"}>
                                                                        <div className="d-flex gap-5 text-success small">
                                                                            {i === 0
                                                                                ?
                                                                                <React.Fragment>
                                                                                    <span>Until <span className="fw-bold small">
                                                                                        {moment(policy?.from).subtract(1, 'd').format("MMM Do YYYY hh:mm a")}</span>
                                                                                    </span>
                                                                                    <span>Free</span>
                                                                                </React.Fragment>
                                                                                :
                                                                                <></>}
                                                                        </div>
                                                                        <div className="d-flex gap-5 text-danger small">
                                                                            <span>From <span className="fw-bold">
                                                                                {moment(policy?.from).format("MMM Do YYYY hh:mm a")}</span>
                                                                            </span>
                                                                            <span className="small">
                                                                                <label className="fw-bold small">Charges:</label>
                                                                                {policy?.amount ? <span>{" "}{priceFormatter(+policy?.amount, "INR")}</span> : <></>}
                                                                                {policy?.percent ? <span>{policy?.percent}%</span> : <></>}
                                                                                {policy?.numberOfNights ? <span>{policy?.numberOfNights} Night</span> : <></>}
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            )
                                                        }
                                                    </>)
                                            :
                                            <div className="h-25 d-flec justify-content-center align-items-center">
                                                <span className="text-muted">Cancellation policy is not available to show for this booking</span>
                                            </div>
                                    }
                                </ul>

                                {/* Booking Terms & Conditions */}
                                <p className='dotted-border mt-4 fs-6 fw-bolder'>Booking Terms & Conditions</p>
                                <ul>
                                    <li>You must present a photo ID at the time of check in. Hotel may ask for credit card or cash deposit for the extra services at the time of check in.</li>
                                    <li>All extra charges should be collected directly from clients prior to departure such as parking, phone calls, room service, city tax, etc.</li>
                                    <li>We don't accept any responsibility for additional expenses due to the changes or delays in air, road, rail, sea or indeed of any other causes, all such expenses will have to be borne by passengers.</li>
                                    <li>In case of wrong residency & nationality selected by user at the time of booking; the supplement charges may be applicable and need to be paid to the hotel by guest on check in/ check out.</li>
                                    <li>Any special request for bed type, early check in, late check out, smoking rooms, etc are not guaranteed as subject to availability at the time of check in.</li>
                                    <li>Early check out will attract full cancellation charges unless otherwise specified.</li>
                                </ul>

                                {/* <p className='dotted-border mt-4 fs-6 fw-bolder' >Bar code Details</p>
                                <table className='table table-hover text-center mt-2 table-bordered'>
                                    <thead className='table-header' >
                                        <tr>
                                            <th>Passenger Name</th>
                                            <th>Flight Details</th>
                                            <th>Bar code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ticketDetails?.lstTicketTravellerDetails?.map((e) =>
                                            <tr>
                                                <td>{`${e?.TravelerTitle || ""} ${e?.TravellerFirstName || " - "} ${e?.TravellerLastName || ""}`}</td>
                                                <td>
                                                    <p>{e?.DepartureCode || "-"} - {e?.ArrivalCode || "-"} </p>
                                                    <p>{ }</p>
                                                </td>
                                                <td>{
                                                    <div className='d-flex justify-content-center'>
                                                        <Barcode width={1} height={25} displayValue={false} value={`Your PRN No. is : ${e?.AirlinePNR || "--"}`} />
                                                    </div>
                                                }</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table> */}

                            </div>

                            {/* FOOTER */}
                            <div className='d-flex justify-content-end gap-3 m-0 px-4 fakeBody'>
                                <ReactToPrint
                                    documentTitle={`e-Ticket_${ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || '--'}`}
                                    bodyClass={""}
                                    removeAfterPrint={true}
                                    pageStyle="scale:9.4"
                                    trigger={() => (
                                        <Button
                                            label={<div className='d-flex gap-2'>
                                                <FaPrint size={20} />
                                                Print e-Ticket
                                            </div>}
                                        />
                                    )}
                                    content={() => componentRef.current!}
                                />
                                <Button
                                    label={<div className='d-flex gap-2'>
                                        <BsDownload size={22} />
                                        <span>Download</span>
                                    </div>}
                                    onClick={() => handlePrint()}
                                />

                            </div>
                        </div>
                    }
                    size='fullscreen'
                />
                :
                <></>}

            {/* INVOICE */}
            {isOpenInvoiceModal
                ?
                <BsModal
                    onClose={() => setIsOpenInvoiceModal(false)}
                    title={'Your E-Ticket Invoice: Ready for Download'}
                    body={
                        <div className='invoice-body'>
                            <div
                                ref={invoiceRef}
                                className=''>
                                <TicketInvoice ticketDetails={ticketDetails} netPayable={netPayable} />
                            </div>
                            {/* footer */}
                            <div className='d-flex justify-content-end m-0 px-4 gap-3'>
                                <ReactToPrint
                                    documentTitle={`Invoice${ticketDetails?.lstTicketBooking[0]?.InvoiceNumber || '--'}`}
                                    bodyClass={""}
                                    removeAfterPrint={true}
                                    pageStyle="scale:9.4"
                                    trigger={() => (
                                        <Button
                                            label={<div className='d-flex gap-2'>
                                                <FaPrint size={20} />
                                                Print Invoice
                                            </div>}
                                        />
                                    )}
                                    content={() => invoiceRef.current!}
                                />
                                <Button
                                    label={<div className='d-flex gap-2'>
                                        <BsDownload size={22} />
                                        <span>Download</span>
                                    </div>}
                                    onClick={() => handlePrintInvoice()}
                                />

                            </div>
                            {/* <p className='dotted-border mt-4 fs-6 fw-bolder' >Bar code Details</p>
                        <table className='table table-hover mt-2 table-bordered'>
                            <thead className='table-header'>
                                <tr>
                                    <th>Passenger Name</th>
                                    <th>Flight Details</th>
                                    <th>Bar code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> -</td>
                                    <td> -</td>
                                    <td> -</td>
                                </tr>

                            </tbody>
                        </table> */}

                        </div>
                    }
                    size='fullscreen'
                />
                :
                <></>}

        </div>
    )
}
