import { useEffect, useState } from "react";

import { ISSR } from "../../../../../utils/types/flightTypes";
import { priceFormatter } from "../../../../../utils/utilityFunctions/utilityFunction";

import SSRToBeSold from "../../../../../Data/SSRToBeSold.json";
import SSRToBeSoldIndigo from "../../../../../Data/SSRToBeSoldIndigo.json";

type Props = {
  CarrierCode: string;
  ssr: ISSR[];
  onSelect: (item: ISSR) => void;
  tempSelected: {
    segIndex: number;
    passengerIndex: number;
    ssrData: ISSR;
  }[];
  selectedPax: number;
  segIndex: number;
};

const MealsSSR = ({
  CarrierCode,
  onSelect,
  ssr,
  tempSelected,
  selectedPax,
  segIndex,
}: Props) => {

  const [SSRToBeSoldDetails, setSSRToBeSoldDetails] = useState<any>();

  useEffect(() => {
    // console.log("CarrierCode: ", CarrierCode);
    // console.log(CarrierCode === "6E" ? SSRToBeSoldIndigo : SSRToBeSold);
    // console.log("--------------------------------------------------------------")
    setSSRToBeSoldDetails(CarrierCode === "6E" ? SSRToBeSoldIndigo : SSRToBeSold);
  }, [CarrierCode]);



  return (
    <>
      {ssr.length > 0
        ?
        (
          ssr.map((item, index) => {

            const detailsToShow = SSRToBeSoldDetails?.MEAL.find(
              (s: any) => s?.SSRCode === item.SSRCode
            );

            const isSelected = tempSelected?.filter(
              (e) => e.passengerIndex === selectedPax && e.segIndex === segIndex
            ).find((e) => e.ssrData.SSRCode === item.SSRCode);

            let price = item.PaxSSRPrice[0].Fare.reduce(
              (total, e) => total + Number(e.Amount),
              0
            );

            // console.group("SSR");
            // console.log("SSR Item: ", item);
            // console.log("detailsToShow: ", detailsToShow);
            // console.log("isSelected: ", isSelected);
            // console.log("price: ", price);
            // console.groupEnd();
            // console.log("==================================================================");

            return (
              <>
                {
                  detailsToShow
                    ?
                    <div
                      key={index}
                      className={`card card--selectable baggage mb-2 ${isSelected
                        ? "border border-primary bg-primary bg-opacity-10"
                        : ""
                        }`}
                      onClick={() => onSelect(item)}
                    >
                      <div className="d-flex justify-content-between p-2 align-items-center">
                        <img
                          src={
                            detailsToShow?.imageUrl
                              ? detailsToShow.imageUrl
                              : "https://picsum.photos/60/60"
                          }
                          className="pe-4 me-3"
                          style={{
                            transform: "rotate(90deg)",
                            width: "60px",
                          }}
                          alt={detailsToShow?.type}
                        />

                        <div className="d-flex flex-column">
                          <span className="fw-bold">{`${detailsToShow?.description}`}</span>
                          <span
                            className={`small px-2 rounded my-2  ${detailsToShow?.mealType === 1
                              ? "text-danger"
                              : "text-success"
                              }`}
                            style={{ width: "fit-content" }}
                          >
                            {detailsToShow?.mealType
                              ? detailsToShow.mealType === 1
                                ? "Non-Vegetarian"
                                : detailsToShow.mealType === 2
                                  ? "Vegetarian"
                                  : ""
                              : ""}
                          </span>
                        </div>
                        <span className="ms-auto me-2">{priceFormatter(price)}</span>
                      </div>
                    </div>
                    :
                    <></>
                }
              </>
            );
          })
        ) : (
          <p className="text-center">No Meals found for this segment.</p>
        )}
    </>
  );
};

export default MealsSSR;
