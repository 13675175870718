import React from "react";
import { useSelector } from "react-redux";

import GroupedInput from "../../../../components/Common/Input/GroupedInput";

import { State } from "../../../../redux/types";
import useHotel from "../../../../utils/hooks/Hotel/useHotel";

import "../../hotel.scss";

interface Props {
    room: number;
    passengerIndex: number;
}

export default function HotelPersonalDetailsForm({ room, passengerIndex }: Props) {

    const { passenger, searchHotel } = useSelector((state: State) => state.hotel);

    const { updateLeadPassenger } = useHotel();

    const COUNTRY_LIST = [
        "Andorra",
        "United Arab Emirates",
        "Antigua & Barbuda",
        "Anguilla",
        "Albania",
        "Armenia",
        "Angola",
        "Argentina",
        "American Samoa",
        "Austria",
        "Australia",
        "Aruba",
        "Azerbaijan",
        "Bosnia & Herzegovina",
        "Barbados",
        "Bangladesh",
        "Belgium",
        "Burkina Faso",
        "Bulgaria",
        "Bahrain",
        "Burundi",
        "Benin",
        "Caribbean Netherlands",
        "St. BarthÃ©lemy",
        "Bermuda",
        "Brunei",
        "Bolivia",
        "Brazil",
        "Bahamas",
        "Bhutan",
        "Botswana",
        "Belarus",
        "Belize",
        "CuraÃ§ao",
        "Canada",
        "Congo (DRC)",
        "Congo - Brazzaville",
        "Switzerland",
        "Ivory Coast",
        "Cook Islands",
        "Chile",
        "Cameroon",
        "China",
        "Colombia",
        "Costa Rica",
        "Cuba",
        "Cape Verde",
        "Cyprus",
        "Czech Republic",
        "Germany",
        "Djibouti",
        "Denmark",
        "Dominica",
        "Dominican Republic",
        "Algeria",
        "Ecuador",
        "Estonia",
        "Egypt",
        "Eritrea",
        "Spain",
        "Ethiopia",
        "Finland",
        "Fiji",
        "Faroe Islands",
        "France",
        "Gabon",
        "United Kingdom",
        "Grenada",
        "Georgia",
        "French Guiana",
        "Ghana",
        "Gibraltar",
        "Greenland",
        "Gambia",
        "Guinea",
        "Guadeloupe",
        "Equatorial Guinea",
        "Greece",
        "Guatemala",
        "Guam",
        "Guinea-Bissau",
        "Guyana",
        "Hong Kong â€“ China",
        "Honduras",
        "Croatia",
        "Haiti",
        "Hungary",
        "Indonesia",
        "Ireland",
        "Israel",
        "India",
        "Iceland",
        "Italy",
        "Jamaica",
        "Jordan",
        "Japan",
        "Kenya",
        "Kyrgyzstan",
        "Cambodia",
        "Comoros",
        "St. Kitts & Nevis",
        "South Korea",
        "Kosovo",
        "Kuwait",
        "Cayman Islands",
        "Kazakhstan",
        "Laos",
        "Lebanon",
        "St. Lucia",
        "Liechtenstein",
        "Sri Lanka",
        "Liberia",
        "Lesotho",
        "Lithuania",
        "Luxembourg",
        "Latvia",
        "Libya",
        "Morocco",
        "Monaco",
        "Moldova",
        "Montenegro",
        "Madagascar",
        "Macedonia (FYROM)",
        "Mali",
        "Myanmar (Burma)",
        "Mongolia",
        "Macau - China",
        "Northern Mariana Islands",
        "Martinique",
        "Mauritania",
        "Malta",
        "Mauritius",
        "Maldives",
        "Malawi",
        "Mexico",
        "Malaysia",
        "Mozambique",
        "Namibia",
        "New Caledonia",
        "Niger",
        "Nigeria",
        "Nicaragua",
        "Netherlands",
        "Norway",
        "Nepal",
        "Niue",
        "Northern Cyprus",
        "New Zealand",
        "Oman",
        "Panama",
        "Peru",
        "French Polynesia",
        "Papua New Guinea",
        "Philippines",
        "Pakistan",
        "Poland",
        "Puerto Rico",
        "Portugal",
        "Palau",
        "Paraguay",
        "Qatar",
        "RÃ©union",
        "Romania",
        "Serbia",
        "Russia",
        "Rwanda",
        "Saudi Arabia",
        "Solomon Islands",
        "Seychelles",
        "Sweden",
        "Sint Maarten",
        "Singapore",
        "St. Helena",
        "Slovenia",
        "Svalbard & Jan Mayen",
        "Slovakia",
        "Sierra Leone",
        "San Marino",
        "Senegal",
        "Suriname",
        "SÃ£o TomÃ© & PrÃ­ncipe",
        "El Salvador",
        "Eswatini",
        "Turks & Caicos Islands",
        "Chad",
        "Togo",
        "Thailand",
        "Tajikistan",
        "Tunisia",
        "Tonga",
        "Turkey",
        "Trinidad & Tobago",
        "Taiwan â€“ China",
        "Tanzania",
        "Ukraine",
        "Uganda",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "St. Vincent & Grenadines",
        "Venezuela",
        "British Virgin Islands",
        "U.S. Virgin Islands",
        "Vietnam",
        "Vanuatu",
        "Samoa",
        "Mayotte",
        "South Africa",
        "Zambia",
        "Zimbabwe"
    ];

    return (
        <React.Fragment>

            <div className="row py-2">
                <div className="col-md-6 col-sm-12">
                    <GroupedInput
                        label="First Name"
                        inputs={[
                            {
                                body: (
                                    <select
                                        id="title"
                                        className="form-control border-0 shadow-none"
                                        style={{ maxWidth: "30%" }}
                                        aria-label="Select for title"
                                        value={passenger[room][passengerIndex].Title}
                                        onChange={function (e: any) {
                                            updateLeadPassenger('Title', e.target.value, room, passengerIndex);
                                        }}
                                    >
                                        <option value="Mr">Mr</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Mrs">Mrs</option>
                                    </select>
                                ),
                            },
                            {
                                body: (
                                    <input
                                        id="firstName"
                                        required
                                        className="form-control border-danger"
                                        placeholder="John"
                                        onChange={function (e: any) {
                                            updateLeadPassenger('FirstName', e.target.value, room, passengerIndex);
                                        }}
                                        value={passenger[room][passengerIndex].FirstName}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                            id="lastName"
                            className="form-control"
                            placeholder="Doe"
                            onChange={function (e: any) {
                                updateLeadPassenger('LastName', e.target.value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].LastName}
                        />
                    </div>
                </div>
            </div>

            {room === 0 && passengerIndex === 0 // show the PAN or Passport inputs for the lead pax only
                ?
                <>
                    {/* {searchHotel.whereTo.CountryCode !== "IN" && searchHotel.whereTo.CountryName.toLowerCase().trim() !== "india"
                // searchHotel.whereTo.CountryCode === "IN" && searchHotel.whereTo.CountryName === "India"
                ? */}
                    <div className="row py-2">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="pax_nationality">Select Your Nationality</label>
                                <select
                                    id="pax_nationality"
                                    className="form-select"
                                    aria-label="Select your nationality"
                                    value={passenger[room][passengerIndex].CountryName}
                                    onChange={function (e: any) {
                                        updateLeadPassenger(
                                            "InternationalTraveler",
                                            e.target.value === "India" ? "No" : "Yes",
                                            room,
                                            passengerIndex
                                        );
                                        updateLeadPassenger(
                                            "CountryName",
                                            e.target.value,
                                            room,
                                            passengerIndex
                                        );

                                        if (e.target.value !== "India") {
                                            updateLeadPassenger('PAN', "", room, passengerIndex);
                                        };
                                    }}
                                >
                                    {COUNTRY_LIST.map((each, index) => <option key={index + "_country"} value={each}>{each}</option>)}
                                </select>
                            </div>
                        </div>
                        {passenger[room][passengerIndex].InternationalTraveler === "No"
                            ?
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="lastName">PAN {passenger[room][passengerIndex].InternationalTraveler === "No" ? <span className="text-danger">*</span> : <></>}</label>
                                    <input
                                        id="pannumber"
                                        required={passenger[room][passengerIndex].InternationalTraveler === "No" ? true : false}
                                        className={`form-control ${passenger[room][passengerIndex].InternationalTraveler === "No" ? "border-danger" : ""}`}
                                        placeholder="Enter"
                                        onChange={function (e: any) {
                                            updateLeadPassenger('PAN', e.target.value, room, passengerIndex);
                                        }}
                                        value={passenger[room][passengerIndex]?.PAN}
                                    />
                                </div>
                            </div>
                            :
                            <></>}
                    </div>
                    {/* :
                 <></>} */}

                    <React.Fragment>
                        <div className="row py-2">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="passportNo">Passport Number {passenger[room][passengerIndex].InternationalTraveler === "Yes" ? <span className="text-danger">*</span> : <></>}</label>
                                    <input
                                        id="passportNo"
                                        className={`form-control ${passenger[room][passengerIndex].InternationalTraveler === "Yes" ? "border-danger" : ""}`}
                                        placeholder="Ex: AADFG1234L"
                                        onChange={function (e: any) {
                                            updateLeadPassenger('PassportNo', e.target.value, room, passengerIndex);
                                        }}
                                        value={passenger[room][passengerIndex].PassportNo}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="issueDate">Issue Date {passenger[room][passengerIndex].InternationalTraveler === "Yes" ? <span className="text-danger">*</span> : <></>}</label>
                                    <input
                                        type="date"
                                        id="issueDate"
                                        className={`form-control ${passenger[room][passengerIndex].InternationalTraveler === "Yes" ? "border-danger" : ""}`}
                                        onChange={function (e: any) {
                                            updateLeadPassenger('PassportIssueDate', e.target.value, room, passengerIndex);
                                        }}
                                        value={passenger[room][passengerIndex].PassportIssueDate}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="expDate">Expiry Date {passenger[room][passengerIndex].InternationalTraveler === "Yes" ? <span className="text-danger">*</span> : <></>}</label>
                                    <input
                                        type="date"
                                        id="expDate"
                                        className={`form-control ${passenger[room][passengerIndex].InternationalTraveler === "Yes" ? "border-danger" : ""}`}
                                        onChange={function (e: any) {
                                            updateLeadPassenger('PassportExpDate', e.target.value, room, passengerIndex);
                                        }}
                                        value={passenger[room][passengerIndex].PassportExpDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                </>
                :
                <></>}

            {/* Email & Phone no */}
            {/* <div className="flex-container py-2">
                <div className="input-item px-3">
                    <div className="form-group">
                        <label className="small mb-0" htmlFor="email">Email</label>
                        <input
                            id="email"
                            className="form-control form-control-sm"
                            placeholder="Ex: abc@gmail.com"
                            type="email"
                            onChange={function (e: any) {
                                updateLeadPassenger('Email', e.target.value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].Email}
                        />
                    </div>
                </div>
                <div className="input-item px-3">
                    <InputGroup
                        label={<span className="fw-semibold">Mobile Number</span>}
                    >
                        <Select
                            // label="Mobile Num"
                            onChange={(e: any) => {
                                updateLeadPassenger('CountryCode', e.target.value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].CountryCode}
                            options={[
                                {
                                    label: "+91 India",
                                    value: "+91",
                                },
                                {
                                    label: "+01 USA",
                                    value: "+01",
                                },
                                {
                                    label: "+12 Finland",
                                    value: "+12",
                                },
                                {
                                    label: "+03 Russia",
                                    value: "+03",
                                },
                            ]}
                        />
                        <InputField
                            placeholder="9999 999 999"
                            value={passenger[room][passengerIndex].Phoneno}
                            type="number"
                            minValue={0}
                            maxLength={10}
                            onChange={(e: any) => {
                                updateLeadPassenger('Phoneno', e.target.value, room, passengerIndex);
                            }}
                        />
                    </InputGroup>
                    <label htmlFor="Mobile" className="small mb-0">
                        Mobile Number
                    </label>
                    <div className="input-group mb-3 border p-0 rounded">
                        <select
                            className=" border-0 form-select shadow-none"
                            style={{ maxWidth: "30%" }}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                updateLeadPassenger('CountryCode', value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].CountryCode}
                        >
                            <option value="+91">+91 India</option>
                            <option value="+01">+01 USA</option>
                            <option value="+12">+12 Finland</option>
                            <option value="+06">+06 Russia</option>
                        </select>
                        <span className="px-1 text-muted my-auto">|</span>
                        <input
                            type="text"
                            id="Mobile"
                            className="form-control border-0 shadow-none"
                            placeholder="9999 999 999"
                            aria-label="9999 999 999"
                            value={passenger[room][passengerIndex].Phoneno}
                            min={0}
                            maxLength={10}
                            onChange={(e: any) => {
                                const { value } = e.target;
                                updateLeadPassenger('Phoneno', value, room, passengerIndex);
                            }}
                            required
                        />
                    </div>
                </div>
            </div> */}

            {/* {passenger[room][passengerIndex].PaxType == "2" ? <div className="row py-2">
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="age">Age</label>
                        <input
                            className={`form-control`}
                            id="age"
                            placeholder="Ex: 24"
                            type="text"
                            onChange={function (e: any) {
                                updateLeadPassenger('Age', e.target.value, room, passengerIndex);
                            }}
                            value={passenger[room][passengerIndex].Age}
                            readOnly={passenger[room][passengerIndex].PaxType == "2" ? true : false}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                        <label htmlFor="International Traveler">International Traveler</label>
                        <select
                            id="International Traveler"
                            className="form-select form-select-lg"
                            aria-label="Default select example"
                            value={passenger[room][passengerIndex].InternationalTraveler}
                            onChange={function (e: any) {
                                updateLeadPassenger('InternationalTraveler', e.target.value, room, passengerIndex);
                            }}
                        >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
            </div> : <></>} */}

            {/* For PAN or Passport details */}
            {/* {passenger[room][passengerIndex].InternationalTraveler === "No" ? <React.Fragment>
                {selectedRoom[0]?.IsPANMandatory && passenger[room][passengerIndex].PaxType === "1" ? <div className="row py-2">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <div className="form-group">
                                <label htmlFor="pan">PAN</label>
                                <input
                                    className={`form-control ${selectedRoom[0]?.IsPANMandatory && passenger[room][passengerIndex].PAN?.length !== 10 && 'border-danger'}`}
                                    id="pan"
                                    placeholder="Ex: AADFG1234L"
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PAN', e.target.value?.toUpperCase(), room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PAN?.toUpperCase()}
                                />
                            </div>
                        </div>
                    </div>
                </div> : <></>}
            </React.Fragment>
                :
                <React.Fragment>
                    <div className="row py-2">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="passportNo">Passport Number</label>
                                <input
                                    id="passportNo"
                                    className={`form-control ${selectedRoom[0]?.IsPassportMandatory && passenger[room][passengerIndex]?.PassportNo === "" ? 'border-danger' : ''}`}
                                    placeholder="Ex: AADFG1234L"
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PassportNo', e.target.value, room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PassportNo}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="issueDate">Issue Date</label>
                                <input
                                    type="date"
                                    id="issueDate"
                                    className={`form-control ${selectedRoom[0]?.IsPassportMandatory && passenger[room][passengerIndex]?.PassportIssueDate === "" ? 'border-danger' : ''}`}
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PassportIssueDate', e.target.value, room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PassportIssueDate}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="expDate">Expiry Date</label>
                                <input
                                    type="date"
                                    id="expDate"
                                    className={`form-control ${selectedRoom[0]?.IsPassportMandatory && passenger[room][passengerIndex]?.PassportExpDate === "" ? 'border-danger' : ''}`}
                                    onChange={function (e: any) {
                                        updateLeadPassenger('PassportExpDate', e.target.value, room, passengerIndex);
                                    }}
                                    value={passenger[room][passengerIndex].PassportExpDate}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>} */}

        </React.Fragment>
    )
}
