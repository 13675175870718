import moment from "moment";
import React, { useState } from "react";
import Lottie from "react-lottie-player";
import { useDispatch, useSelector } from "react-redux";

import FlightCard from "./FlightCard";
import FlightCartSortBar from "./FlightCartSortBar";

import { State } from "../../../redux/types";
import { flightSlice } from "../../../redux/reducers/flight";
import useFlight from "../../../utils/hooks/Flight/useFlight";
import { TFlightResultList } from "../../../utils/types/flightTypes";

import NoFlightLottie from "../../../assets/LottieFiles/no-flights.json";
import DateChangeTab from "./DateChangeTab";

interface Props {
  minPrice?: number;
  flightData: TFlightResultList[]; // IFlightSearchData[];
  flightIndex: number;
  selectedTraveType?: "one_way" | "return" | "multi_city";
};

const FlightResultSection = ({
  minPrice,
  flightData,
  flightIndex,
  selectedTraveType,
}: Props) => {

  const dispatch = useDispatch();

  const { updateSearch } = useFlight();

  // const [selectedFlightIndex, setSelectedFlightIndex] = useState<number>(-1);
  const [flightSortKey, setFlightSortKey] = useState<
    "totalPrice" | "durationInMins" | "airports" | "departureTime"
  >("totalPrice");
  const { selectedFlights, searchInfo } = useSelector(
    (state: State) => state.flight
  );

  return (
    <div className={`col-sm-12 col-md`}>
      <DateChangeTab
        departureDate={moment(searchInfo.departureDate[flightIndex])}
        // minPrice={3000}
        minDate={
          flightIndex > 0
            ? moment(searchInfo.departureDate[flightIndex - 1])
            : moment()
        }
        maxDate={
          flightIndex < searchInfo?.departureDate.length - 1
            ? moment(searchInfo.departureDate[flightIndex + 1])
            : undefined
        }
        onSelect={(date) => {
          updateSearch("departureDate", date, flightIndex);
        }}
      />

      <div className="card card--flight-select card--flight-select--header">
        <div className="card-body">
          <FlightCartSortBar
            onClick={(key) => setFlightSortKey(key)}
            selectedSortKey={flightSortKey}
          />
        </div>
      </div>

      {flightData?.length > 0 ? (
        flightData
          ?.sort((a, b) => {
            if (flightSortKey === "totalPrice") {
              return (
                a.supplier === "navitaire"
                  ?
                  a?.FareToShow?.markedUpPrice?.markedupTotalPrice - b?.FareToShow?.markedUpPrice?.markedupTotalPrice
                  :
                  a.supplier === "airiq"
                    ?
                    a?.fare_data?.markedUpPrice?.markedupTotalPrice - b?.fare_data?.markedUpPrice?.markedupTotalPrice
                    :
                    a.supplier === "1G"
                      ?
                      +a?.["1GSegmentData"].reduce((total, item) => total += item.fareInfo.markedUpPrice.markedupTotalPrice, 0)
                      :
                      0 - 0
              );
            }
            return +a[flightSortKey] - +b[flightSortKey];
          })
          ?.map((flight, index: number) => {
            const isSelected = selectedFlights.some(
              (selectedFlight) =>
                selectedFlight?.JourneySellKey === flight?.JourneySellKey
            );
            return (
              <div key={index} className="mb-2 flight_card">
                <FlightCard
                  flight={flight}
                  onSelect={() => {
                    if (flight.carrierCode === "1G") {
                      return;
                    };
                    if (isSelected) {
                      // deselect selected flight
                      dispatch(
                        flightSlice.actions.setSelectedFlights({
                          index: flightIndex,
                          value: null,
                        })
                      );
                    } else {
                      dispatch(
                        flightSlice.actions.setSelectedFlights({
                          index: flightIndex,
                          value: flightData[index],
                        })
                      );
                    }
                  }}
                  isSelected={isSelected}
                />
              </div>
            );
          })
      ) : (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <Lottie
            loop
            animationData={NoFlightLottie}
            play
            style={{ width: 350, height: 250 }}
          />
          <h5>No Flight Found</h5>
          <span className="text-muted">
            Please change filters or try again later .
          </span>
        </div>
      )}
    </div>
  );
};

export default FlightResultSection;
