import { IHotelDetailsToBeStored } from "../utils/types/hotelTypes";
import { State } from "./types";

export const initialState: State = {
  user: {
    userId: null,
    affiliateId: null,
    isAgent: false,
    isLoggedIn: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    token: "",
    countryCode: "INR",
    previousBookings: [],
    location: {
      latitude: -1,
      longitude: -1,
    },
    profile: {
      CustomerId: -1,
      Title: "",
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      Mobile: "",
      CountryId: "",
      StateId: "",
      City: "",
      Pincode: "",
      Address1: "",
      Address2: "",
    },
  },
  flight: {
    searchInfo: {
      travelType: {
        SearchTypeId: 2,
        SearchTypeName: "Round Trip",
      },
      adult: 1,
      child: 0,
      infant: 0,
      class: {
        FlightClassId: 2,
        FlightClassName: "Economy",
      },
      fromCity: [],
      toCity: [],
      departureDate: [],
      airlines: [],
    },
    filters: {
      stops: [],
      departureTime: [],
      arrivalTime: [],
      priceRange: [40000],
      airlines: [],
      duration: [24],
      layover: ["0", "1", "2", "3"],
    },
    personalInfo: {
      countryCode: "+91",
      mobileNo: "",
      email: "",
      passengers: {
        child: [],
        infant: [],
        adult: [
          {
            Title: "Mr.",
            firstName: "",
            lastName: "",
            gender: "Male",
            dateOfBirth: "",
          },
        ],
      },
    },
    selectedFlights: [],
    Itinerary: {
      BookingSum: [],
      Journeys: [],
      CurrencyCode: "",
      TotalCost: 0,
    },
    metaData: {
      Origins: [],
      Destinations: [],
      MarkupFeeType: [],
      Markup: [],
      AffiliateMarkup: [],
      TripTypes: [],
      BookingSupplier: [],
      TicketedSupplier: [],
      FlightAirline: [],
      FlightClass: [],
      FlightCabinClass: [],
      FlightOrigins: [],
      FlightDestinations: [],
      TitleMaster: [],
      MealType: [],
      TravellerTypes: [],
      Charges: [],
      FlightApplyFor: [],
      FlightMarkupType: [],
      FlightFareType: [],
      FlightApplyOn: [],
      FlightApplyType: [],
    },
    Fare: {
      adult: [],
      child: [],
      total: {
        base: 0,
        tax: 0,
        markedUpBase: 0,
        markedUpTax: 0,
      },
    },
  },

  hotel: {
    // for filters for hotel list
    filters: {
      hotelName: "",
      StarRating: [],
      price: [],
      HotelCategory: [],
      HotelBoards: [],
      zones: [],
      accommodationTypes: [],
      selectedNearbySearchGeoLocation: null
    },
    // for nearby places - geo locations (these are used as zones for hotel filters)
    nearbySearchGeoLocations: [],
    // for search hotel form
    searchHotel: {
      whereTo: {
        CityName: "",
        CountryCode: "",
        CountryName: "",
        DestinationId: 0,
        DestinationName: "",
        HB_DestinationNumber: "",
        HT_DestinationNumber: "",
        StateProvince: "",
        Type: "1",
        // code: "",
        // countryCode: "",
        // groupZones: [],
        // isoCode: "",
        // name: { content: "" },
        // zones: [{
        //   description: { content: "" },
        //   name: "",
        //   zoneCode: 0
        // }],
      },
      geoLocation: {
        index: 0,
        Address: "",
        location: {
          latitude: 0,
          longitude: 0
        },
        name: ""
      },
      checkInDate: "",
      checkOutDate: "",
      noOfRooms: 1,
      RoomGuest: [
        {
          NoOfAdults: 1,
          NoOfChild: 0,
          ChildAge: [],
        },
      ],
    },
    // hotel listing
    hotelListing: [],
    // lead passenger details
    passenger: [
      [
        {
          InternationalTraveler: "No",
          Title: "Mr",
          FirstName: "",
          LastName: "",
          CountryCode: "+91",
          CountryName: "",
          Phoneno: "",
          Email: "",
          PaxType: "1",
          Age: "",
          PassportNo: "",
          PassportIssueDate: "",
          PassportExpDate: "",
          PAN: "",
          LeadPassenger: false,
        },
      ],
    ],
    bookingPassenger: {
      countryCode: "+91",
      mobileNo: "",
      email: "",
      GSTNumber: "",
      additionalRemarks: ""
    },
    metaData: {
      Destination: [],
      BookingSupplier: [],
      TicketedSupplier: [],
      Charges: [],
      markUps: [],
      affiliateMarkup: [],
    },

    HotelBeds: {
      searchedHotelList: [],
      // for searched hotel
      searchedHotel: {
        noOfNights: 1,
        hotel_dynamic_data: {
          categoryCode: "",
          categoryName: "",
          code: 0,
          currency: "",
          destinationCode: "",
          destinationName: "",
          latitude: "",
          longitude: "",
          maxRate: "",
          minRate: "",
          name: "",
          rooms: [
            {
              code: "",
              name: "",
              rates: [
                {
                  adults: 0,
                  allotment: 0,
                  boardCode: "",
                  boardName: "",
                  cancellationPolicies: [
                    {
                      amount: "",
                      from: "",
                    },
                  ],
                  children: 0,
                  childrenAges: "",
                  net: "",
                  offers: [
                    {
                      amount: "",
                      code: "",
                      name: "",
                    },
                  ],
                  packaging: false,
                  paymentType: "",
                  rateClass: "",
                  rateKey: "",
                  rateType: "",
                  rooms: 0,
                  roomName: ""
                },
              ],
            },
          ],
          zoneCode: 0,
          zoneName: "",
        },
        hotel_static_data: {
          accommodationTypeCode: "",
          address: {
            content: "",
            street: "",
          },
          boardCodes: [""],
          categoryCode: "",
          categoryGroupCode: "",
          chainCode: "",
          city: { content: "" },
          code: 0,
          coordinates: {
            latitude: 0,
            longitude: 0,
          },
          countryCode: "",
          description: {
            content: "",
          },
          destinationCode: "",
          email: "",
          facilities: [
            {
              facilityCode: 0,
              facilityGroupCode: 0,
              facilityName: "",
              indYesOrNo: false,
              number: 0,
              order: 0,
              voucher: false,
            },
          ],
          images: [
            {
              imageTypeCode: "",
              imageTypeName: "",
              order: 0,
              path: "",
              visualOrder: 0,
            },
          ],
          issues: [
            {
              alternative: false,
              dateFrom: "",
              dateTo: "",
              issueCode: "",
              issueType: "",
              issueTypeDescription: "",
              order: 1,
            },
          ],
          lastUpdate: "",
          name: { content: "" },
          phones: [
            {
              phoneNumber: "",
              phoneType: "",
            },
          ],
          postalCode: "",
          ranking: 0,
          rooms: [
            {
              characteristicCode: "",
              isParentRoom: false,
              maxAdults: 0,
              maxChildren: 0,
              maxPax: 0,
              minAdults: 0,
              minPax: 0,
              roomCode: "",
              roomFacilities: [
                {
                  facilityCode: 0,
                  facilityName: "",
                  facilityGroupCode: 0,
                  indYesOrNo: false,
                  voucher: false,
                },
              ],
              roomStays: [
                {
                  description: "",
                  order: "",
                  stayType: "",
                  roomStayFacilities: [
                    {
                      facilityCode: 0,
                      facilityName: "",
                      facilityGroupCode: 0,
                      number: 0,
                    },
                  ],
                },
              ],
              roomType: "",
              roomTypeName: "",
            },
          ],
          segmentCodes: [],
          segmentNames: [],
          terminals: [
            {
              distance: 0,
              terminalCode: "",
              terminalName: "",
            },
          ],
          stateCode: "",
          web: "",
          zoneCode: 0,
        },
      },
      selectedRoomCombinations: {
        id: -1,
        price: 0,
        combination: [],
      },
      // selected room details
      selectedRoom: [],
      paginationInfo: {
        pageSize: 15,
        pageNumber: 1,
        totalPages: 1,
      },
    },

    TBO: {
      // trace id is received when we search hotels with the help of sear hotel filters
      TraceId: "",
      selectedRoom: [],
      // selected hotel details
      searchedHotel: {
        HotelAddress: "",
        HotelCategory: "",
        HotelCode: "",
        HotelContactNo: "",
        HotelDescription: "",
        HotelLocation: null,
        HotelMap: null,
        HotelName: "",
        HotelPicture: "",
        HotelPolicy: "",
        HotelPromotion: "",
        IsHotDeal: false,
        Latitude: "",
        Longitude: "",
        Price: {
          AgentCommission: 0,
          AgentMarkUp: 0,
          ChildCharge: 0,
          CurrencyCode: "",
          Discount: 0,
          ExtraGuestCharge: 0,
          GST: {
            CGSTAmount: 0,
            CGSTRate: 0,
            CessAmount: 0,
            CessRate: 0,
            IGSTAmount: 0,
            IGSTRate: 0,
            SGSTAmount: 0,
            SGSTRate: 0,
            TaxableAmount: 0,
          },
          OfferedPrice: 0,
          OfferedPriceRoundedOff: 0,
          OtherCharges: 0,
          PublishedPrice: 0,
          PublishedPriceRoundedOff: 0,
          RoomPrice: 0,
          ServiceCharge: 0,
          ServiceTax: 0,
          TCS: 0,
          TDS: 0,
          Tax: 0,
          TotalGSTAmount: 0,
        },
        ResultIndex: 0,
        RoomDetails: [],
        StarRating: 0,
        SupplierPrice: null,
      },
      searchedHotelList: [],
      paginationInfo: {
        pageSize: 15,
        pageNumber: 1,
        totalPages: 1,
      },
    },
  },

  commonState: {
    showSignInModal: false,
    isFlightDataFetched: false,
    isHotelDataFetched: false,
    activeTab: null,
    Client: null,
    ClientData: {
      Services: [],
      MyCharges: [],
      OtherCharges: [],
      GSTRates: [],
    },
    toast: [],
    CouponBox: {
      IsEnabledBus: false,
      IsEnabledFlight: false,
      IsEnabledHotel: false,
    },
  },
};


export const initialBookedHotelInformation: IHotelDetailsToBeStored = {
  OrderId: "",
  CancellationPolicies: "",
  SupplierName: "",
  SupplierVatNumber: "",
  BookingSupplier: "",
  BookingReferenceNumber: "",
  ClientReferenceNumber: "",
  HotelName: "",
  HotelAddress: null,
  HotelDescription: null,
  HotelEmail: "",
  HotelContactNumber: "",
  HotelPostalCode: null,
  HotelCategory: null,
  RoomName: "",
  BoardCode: "",
  BoardName: "",
  RateComments: null,
  PromotionCode: null,
  PromotionName: null,
  Website: null,
  TicketedSupplier: ""
};