import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaAngleRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import Button from "../../../../../components/Common/Button/Button";
import BsModal from "../../../../../components/Modal/BSModal";
import { State } from "../../../../../redux/types";
import { ISSR } from "../../../../../utils/types/flightTypes";
import { filterSSRByType } from "../../../../../utils/utilityFunctions/flight/flightUtilityFunctions";
import { priceFormatter } from "../../../../../utils/utilityFunctions/utilityFunction";
import "../../../flight.scss";
import { ISSRListPerLeg } from "../AddOns";
import BaggageSSR from "./BaggageSSR";
import MealsSSR from "./MealsSSR";

type Props = {
  type: "EB" | "MEAL";
  ssrList: ISSRListPerLeg[];
  onClose: () => void;
  onSubmit: (
    data: {
      segIndex: number;
      passengerIndex: number;
      ssrData: ISSR;
    }[]
  ) => void;
  onClear?: () => void;
};

const SSRSelectionModal = ({
  type,
  ssrList,
  onClose,
  onSubmit,
  onClear,
}: Props) => {

  const { metaData, SelectedAddOns, searchInfo, Itinerary } = useSelector((state: State) => state.flight);

  const [activeSegment, setActiveSegment] = useState<number>(0);
  const [selectedPax, setSelectedPax] = useState<number>(0);

  const [tempSelectedSSR, setTempSelectedSSR] = useState<
    {
      segIndex: number;
      passengerIndex: number;
      ssrData: ISSR;
    }[]
  >([]);



  useEffect(() => {
    let allSelected = SelectedAddOns?.map((seg, index) =>
      seg.SSR.map((pax, idx) =>
        pax
          .filter((ssr) => ssr.type === type)
          .map((ssr) => ({
            segIndex: index,
            passengerIndex: idx,
            ssrData: ssr,
          }))
      )
    ).flat(2);
    setTempSelectedSSR(allSelected || []);
  }, [SelectedAddOns, type]);


  const activeSegmentData: {
    CarrierCode: string;
    ssrList: ISSR[];
  } = useMemo(() => {
    let ssrAtIndex = ssrList[activeSegment];
    const formattedData = Object.assign({
      CarrierCode: ssrAtIndex.LegInfo.CarrierCode,
      ssrList: filterSSRByType(
        ssrAtIndex.AvailableSSR,
        type,
        ssrAtIndex.LegInfo.CarrierCode
      ),
    });
    // console.log("ssrAtIndex: ", formattedData);
    return formattedData;
  }, [activeSegment, ssrList, type]);

  const totalAddedPrice = useMemo(() => {
    return tempSelectedSSR
      ?.map((seg) => seg.ssrData.PaxSSRPrice[0].Fare)
      .flat()
      .reduce((acc, each) => acc + Number(each.Amount), 0);
  }, [tempSelectedSSR]);

  const handleSelection = (data: ISSR) => {
    const idx = tempSelectedSSR.findIndex(
      (item) =>
        item.passengerIndex === selectedPax &&
        item.segIndex === activeSegment &&
        item.ssrData.type === data.type
    );
    if (idx !== -1) {
      setTempSelectedSSR(
        tempSelectedSSR.map((e, i) =>
          i !== idx
            ? e
            : {
              ssrData: data,
              segIndex: activeSegment,
              passengerIndex: selectedPax,
            }
        )
      );
    } else {
      setTempSelectedSSR((prev) => [
        ...prev,
        { ssrData: data, segIndex: activeSegment, passengerIndex: selectedPax },
      ]);
      // setTempSelectedSSR(() => [
      //   { ssrData: data, segIndex: activeSegment, passengerIndex: selectedPax },
      // ]);
      // }
    }
  };



  return (
    <div>
      <BsModal
        title={`Add ${type === "EB" ? "Extra Baggage" : type === "MEAL" ? "Meal" : type
          }`}
        subTitle={"Select from below given options."}
        size={searchInfo?.adult + searchInfo?.child >= 2 ? "xl" : "lg"}
        // scrollable
        body={
          <>
            <div className="ssr-segment">
              {/* SSR TABS */}
              {ssrList.map((seg, index) => {
                let departureCityName =
                  metaData?.FlightOrigins.find(
                    (airport) =>
                      airport?.RouteDestinationCode === seg?.LegInfo?.DepartureStation
                  )?.City || seg?.LegInfo?.DepartureStation;
                let arrivalCityName =
                  metaData?.FlightDestinations.find(
                    (airport) =>
                      airport.RouteDestinationCode === seg?.LegInfo?.ArrivalStation
                  )?.City || seg?.LegInfo?.ArrivalStation;

                return (
                  <div
                    className={`ssr-segment-item ${activeSegment === index && "active"
                      }`}
                    onClick={() => setActiveSegment(index)}
                  >
                    <span className="city">
                      <span>{departureCityName}</span>
                      <BsArrowRight />
                      <span>{arrivalCityName}</span>
                    </span>
                    <p className="date">
                      {moment(seg?.LegInfo?.DepartureDate).format(
                        "ddd DD MMM, YYYY"
                      )}
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="ssr-body">
              {searchInfo?.adult + searchInfo?.child >= 2 && (
                <div className="passengers">
                  {[...Array(searchInfo?.adult)].fill("").map((e, i) => {
                    const selectedBaggage =
                      type === "EB"
                        ? tempSelectedSSR
                          .find(
                            (s) =>
                              s.segIndex === activeSegment &&
                              s.passengerIndex === i
                          )
                          ?.ssrData.description.split(" ")[0]
                        : 0;
                    const selectedMeal =
                      type === "MEAL" &&
                        tempSelectedSSR.find(
                          (s) =>
                            s.segIndex === activeSegment && s.passengerIndex === i
                        )
                        ? "1"
                        : "No";
                    return (
                      <div
                        className={`passenger ${selectedPax === i && "selectedPax"
                          }`}
                        onClick={() => setSelectedPax(i)}
                      >
                        <span className="fw-semibold">
                          Adult Passenger {i + 1}
                        </span>
                        {type === "EB" ? (
                          <span className="fs-8 text-muted">
                            {15 + Number(selectedBaggage || 0)} kg check-in, 7kg
                            carry-on
                          </span>
                        ) : (
                          <span className="fs-8 text-muted">
                            {selectedMeal} meal selected{" "}
                          </span>
                        )}
                      </div>
                    );
                  })}
                  {[...Array(searchInfo?.child)].fill("").map((e, i) => {
                    const selectedBaggage =
                      type === "EB"
                        ? tempSelectedSSR
                          .find(
                            (s) =>
                              s.segIndex === activeSegment &&
                              s.passengerIndex === i + searchInfo?.adult
                          )
                          ?.ssrData.description.split(" ")[0]
                        : 0;
                    const selectedMeal =
                      type === "MEAL" &&
                        tempSelectedSSR.find(
                          (s) =>
                            s.segIndex === activeSegment &&
                            s.passengerIndex === i + searchInfo?.adult
                        )
                        ? "1"
                        : "No";
                    return (
                      <div
                        className={`passenger ${selectedPax === searchInfo?.adult + i && "selectedPax"
                          }`}
                        onClick={() => setSelectedPax(searchInfo?.adult + i)}
                      >
                        <span className="fw-semibold">
                          Child Passenger {i + 1}
                        </span>
                        {type === "EB" ? (
                          <span className="fs-8 text-muted">
                            {15 + Number(selectedBaggage || 0)} kg check-in, 7kg
                            carry-on
                          </span>
                        ) : (
                          <span className="fs-8 text-muted">
                            {selectedMeal} meal selected{" "}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="ssr-sections">
                {type === "EB" ? (
                  <BaggageSSR
                    CarrierCode={activeSegmentData.CarrierCode}
                    ssr={activeSegmentData.ssrList}
                    onSelect={(item) => {
                      handleSelection(item);
                    }}
                    segIndex={activeSegment}
                    selectedPax={selectedPax}
                    tempSelected={tempSelectedSSR}
                  />
                ) : (
                  <MealsSSR
                    CarrierCode={activeSegmentData.CarrierCode}
                    ssr={activeSegmentData.ssrList}
                    onSelect={(item) => {
                      handleSelection(item);
                    }}
                    segIndex={activeSegment}
                    selectedPax={selectedPax}
                    tempSelected={tempSelectedSSR}
                  />
                )}
              </div>
            </div>
          </>
        }
        footer={
          <div className="d-flex justify-content-between w-100">
            <Button
              label={`Remove ${type === "EB" ? "Extra Baggage" : "Meal Selections"
                }`}
              onClick={() => {
                onClear && onClear();
              }}
              color="danger"
              outline
            />

            <div className="d-flex gap-3 align-items-center fw-bold">
              {totalAddedPrice > 0 && (
                <span>+ {priceFormatter(totalAddedPrice)}</span>
              )}
              <Button
                label={`Cancel`}
                onClick={() => {
                  onClose();
                }}
                outline
              />
              <Button
                label={
                  <span>
                    {`Save & Continue`}
                    <FaAngleRight className="ps-1" />
                  </span>
                }
                onClick={() => {
                  onSubmit(tempSelectedSSR);
                }}
              />
            </div>
          </div>
        }
        onClose={() => {
          onClose();
        }}
      />
    </div>
  );
};

export default SSRSelectionModal;
