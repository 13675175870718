import React from "react";

interface Props {
  label: string;
  inputs: {
    body: JSX.Element;
  }[];
}

const GroupedInput = ({ label, inputs }: Props) => {
  return (
    <>
      <label htmlFor="Mobile" className="form-label">
        {label} <span className="text-danger">*</span>
      </label>
      <div className="input-group mb-3 border p-0 rounded">
        {inputs?.map((input, index) => (
          <React.Fragment key={index}>
            {" "}
            {input.body}
            {index !== inputs?.length - 1 && (
              <span className="px-1 text-muted my-auto">|</span>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default GroupedInput;
