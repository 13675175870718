import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import { InformationCircle } from '@kiwicom/orbit-components/lib/icons';
import Illustration from "@kiwicom/orbit-components/lib/Illustration";

import TPricingSummary from './TPricingSummary';
import Image from '../../../../../components/Common/Image/Image';
import Button from '../../../../../components/Common/Button/Button';
import BsInfoModal from '../../../../../components/Modal/BsInfoModal';
import CardBase from '../../../../../components/Common/CardBase/CardBase';
import HotelPassengerDetails from '../../../Components/PassengerDetails/HotelPassengerDetails';

import useTBO from '../../../../../utils/hooks/Hotel/useTBO';
import useHotel from '../../../../../utils/hooks/Hotel/useHotel';
import useClientData from '../../../../../utils/hooks/useClientData';
import { commonStateSlice } from "../../../../../redux/reducers/commonState";
import { ICommon, IHotel, ITBORoom, IUser, State } from '../../../../../redux/types';
import { getMarkedUpPrice, getMyChargesAmount, priceFormatter, removeSpecialCharacters } from '../../../../../utils/utilityFunctions/utilityFunction';
import { getTotalGuestCountForHotel, handleGetCharges } from '../../../../../utils/utilityFunctions/hotelUtitlityFunctions';
import { IBillingBreakUps } from '../../../../../utils/types/hotelTypes';
import useHotelDetails from '../../../../../utils/hooks/Hotel/useHotelDetails';
import useHotelMetaData from '../../../../../utils/hooks/Hotel/useHotelMetaData';

interface Props {
  scrollToRoom?: () => void;
}

export default function THotelBooking({ scrollToRoom }: Props) {

  const [
    { searchHotel, TBO, HotelBeds, metaData },
    { isLoggedIn, affiliateId },
    { ClientData }
  ]: [IHotel, IUser, ICommon] = useSelector((state: State) => [
    state.hotel,
    state.user,
    state.commonState
  ]);

  const history = useHistory();
  const dispatch = useDispatch();
  // const { getChargesByService } = useClientData();
  const {
    isBookingInProgress,
    isBlockRoomInProgress,
    blockRoom,
    showModal,
    setShowModal,
    isHotelBookingSuccess,
    setIsHotelBookingSuccess
  } = useTBO();
  const {
    handleUpdateHotelData,
    removeSelectedHotel,
    removeSelectedRoom,
  } = useHotel();
  const {
    handleCreateHotelBooking
  } = useHotelDetails();
  const { getChargesList } = useHotelMetaData();
  const { resetMetadataCharges } = useHotel();

  const scroll = useRef<HTMLDivElement>(null);

  // sotres billing info
  const [billingInfo, setBillingInfo] = useState<{
    markedUpBasePrice: number;
    Tax: number;
    GST: number;
    otherMarkUpCharges: number;
    myCharges: number;
  }>({
    markedUpBasePrice: 0,
    Tax: 0,
    GST: 0,
    otherMarkUpCharges: 0,
    myCharges: 0
  });

  // stores room and guest count
  const [guestCount, setGuestCount] = useState<{
    rooms: number;
    adults: number;
    children: number;
  }>({
    rooms: 0,
    adults: 0,
    children: 0,
  });

  // useEffect(() => {
  //   scroll.current?.scrollIntoView({ behavior: "smooth" });
  //   if (
  //     Client !== null
  //   ) {
  //     let clientId = ClientData.Services?.find((service) => service.ServiceName?.toLocaleLowerCase() === "hotel")?.ServiceId || 2;
  //     getChargesByService(clientId);
  //   }
  // }, [TBO.selectedRoom]);

  useEffect(() => {

    scroll.current?.scrollIntoView({ behavior: "smooth" });
    affiliateId ? getChargesList(true) : getChargesList();
    handleChargeBreakDown();
    handleCreateHotelBooking(
      {
        order_amount: "",
        order_id: "",
        order_status: null,
        payment_session_id: null
      },
      "Payment_Failed",
      "TESTING",
      null,
      null,
      null
    );

    return () => resetMetadataCharges();

  }, [ClientData]);

  const handleChargeBreakDown = () => {
    let charges: IBillingBreakUps = {} as IBillingBreakUps;
    handleGetCharges(
      searchHotel,
      metaData,
      ClientData,
      "TBO",
      HotelBeds.selectedRoomCombinations.combination,
      TBO.selectedRoom
    ).then(res => {
      charges = res;
      setGuestCount({
        rooms: charges.rooms,
        adults: charges.adults,
        children: charges.children
      });
      setBillingInfo({
        markedUpBasePrice: charges.billingCharges.markedUpBasePrice,
        Tax: charges.billingCharges.Tax,
        GST: charges.billingCharges.GST,
        otherMarkUpCharges: charges.billingCharges.otherMarkUpCharges,
        myCharges: charges.billingCharges.myCharges
      });
      // console.log(charges);
    });
  };

  // const handleGetCharges = async () => {

  //   // calculating no. of rooms, adults and children
  //   setGuestCount({
  //     rooms: searchHotel?.noOfRooms,
  //     adults: searchHotel?.RoomGuest?.reduce(
  //       (accu, curr) => (accu += Number(curr?.NoOfAdults)),
  //       0
  //     ),
  //     children: searchHotel?.RoomGuest?.reduce(
  //       (accu, curr) => (accu += Number(curr?.NoOfChild)),
  //       0
  //     ),
  //   });

  //   // calculating billing info based on selected rooms
  //   // let total_Publishedprice: number = TBO.selectedRoom?.reduce(
  //   //   (acc, curr) => curr?.Price?.OfferedPrice + acc,
  //   //   0
  //   // ) || 0;
  //   let total_TAX: number = TBO.selectedRoom?.reduce((acc, curr) => curr?.Price?.Tax + acc, 0) || 0;
  //   let total_GST: number = TBO.selectedRoom?.reduce((acc, curr) => curr?.Price?.TotalGSTAmount + acc, 0) || 0;

  //   let supplierName = metaData.BookingSupplier?.find(
  //     each => each?.SupplierName?.toLowerCase() === "tbo")?.SupplierName
  //     ||
  //     metaData.BookingSupplier[0].SupplierName;

  //   let markup_charges: {
  //     Base: number;
  //     baseMarkup: number;
  //     Service: number;
  //     Booking: number;
  //     GST: number;
  //   }[] = TBO.selectedRoom?.map(
  //     (rate: ITBORoom) => {
  //       return getMarkedUpPrice(
  //         metaData.markUps,
  //         +rate.Price.OfferedPrice,
  //         supplierName,
  //         ClientData.GSTRates[0]?.Value,
  //         metaData.affiliateMarkup,
  //         searchHotel.RoomGuest
  //       ).markedUpPrice
  //     });

  //   let markup: {
  //     Base: number;
  //     baseMarkup: number;
  //     Service: number;
  //     Booking: number;
  //     GST: number;
  //   } = {
  //     Base: 0,
  //     baseMarkup: 0,
  //     Service: 0,
  //     Booking: 0,
  //     GST: 0
  //   };
  //   markup_charges.forEach((eachMarkup) => {
  //     markup.Base += eachMarkup.Base;
  //     markup.baseMarkup = eachMarkup.baseMarkup;
  //     markup.Service += eachMarkup.Service;
  //     markup.Booking += eachMarkup.Booking;
  //     markup.GST += eachMarkup.GST;
  //   });

  //   // calculation my charges based on data received from my charges API request
  //   let myCharges: number[] = TBO.selectedRoom?.map((room: ITBORoom, index) => {
  //     return getMyChargesAmount(
  //       ClientData.MyCharges,
  //       room.Price.OfferedPrice,
  //       Number(searchHotel.RoomGuest[index].NoOfAdults) + Number(searchHotel.RoomGuest[index].NoOfChild),
  //       TBO.selectedRoom.length
  //     );
  //   });

  //   setBillingInfo({
  //     markedUpBasePrice: markup.Base - markup.GST,
  //     Tax: total_TAX,
  //     GST: total_GST + markup.GST,
  //     otherMarkUpCharges: markup.Service + markup.Booking,
  //     myCharges: myCharges.reduce((acc, curr) => acc + curr, 0)
  //   });

  // };

  const showLoginModal = () => {
    dispatch(
      commonStateSlice.actions.toggle("showSignInModal")
    );
  };

  return (
    <div className="searched-hotel-container" ref={scroll}>
      <div className="container">

        <div>
          <Button
            type={"button"}
            size={"sm"}
            color={"primary"}
            outline={true}
            className={"py-1 px-2 rounded-3"}
            label={
              <>
                <FaLongArrowAltLeft />
                <span className="small ms-1">Go back</span>
              </>
            }
            onClick={() => history.goBack()}
          />
          <div className="spacer spacer--sm"></div>
          <h2>Booking Summary</h2>
        </div>

        <div className="row my-3">
          {/* Left Panels */}
          <div className="col-md-8 col-sm-12">
            {/* Hotel Guest Details Forms Based on Rooms*/}
            <HotelPassengerDetails />

            {TBO.selectedRoom.length === 0 ? (
              <div className="alert alert-warning d-flex align-items-center">
                <InformationCircle />
                <p className="px-2 my-auto">
                  You have not selected any room. Please select a room type
                  from the above list to continue.
                  <br />
                  <span
                    className="text-primary small pointer"
                    onClick={() => {
                      history.goBack();
                      scrollToRoom && scrollToRoom();
                    }}
                  >
                    <u>Go back</u>
                  </span>
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* Right Panels */}
          <div className="col-md-4 col-sm-12">
            <TPricingSummary
              isBookingInProgress={isBookingInProgress}
              isBlockRoomInProgress={isBlockRoomInProgress}
              isLoggedIn={isLoggedIn}
              searchedHotel={TBO.searchedHotel}
              searchHotel={searchHotel}
              guestCount={guestCount}
              billingInfo={billingInfo}
              // blockRoom={blockRoom}
              blockRoom={blockRoom}
              toggleLoginModal={showLoginModal}
            />
          </div>

        </div>

      </div>

      {/* When Price & Policy has been updated */}
      {showModal ? (
        <React.Fragment>
          <div
            className="modal show d-block"
            id="exampleModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <div>
                    <h5
                      className="modal-title text-muted"
                      id="exampleModalLabel"
                    >
                      Updated Price and Cancelation Policy
                    </h5>
                    <span className="small text-muted">
                      Our booking price and cancelation policy has been
                      updated.{" "}
                    </span>
                    <span className="small text-muted">
                      Please review the updated price and cancelation policy
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <CardBase title={"Price"}>
                        <div className="mb-3">
                          <Image
                            src={TBO.searchedHotel.HotelPicture}
                            className="card-img"
                          />
                        </div>

                        {/* Room details */}
                        <div>
                          <h4 className="mb-1">{TBO.searchedHotel?.HotelName}</h4>
                          <p className="text-muted">
                            {moment(searchHotel.checkOutDate).diff(
                              searchHotel.checkInDate,
                              "days"
                            )}{" "}
                            night stay
                          </p>
                          <div className="">
                            <ul className="list list--hotel-summary">
                              <div className="list-item">
                                <b>{searchHotel?.noOfRooms} Room</b>
                              </div>
                              <div className="list-item">
                                <b>Check-In</b> -{" "}
                                {moment(searchHotel.checkInDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </div>
                              <div className="list-item">
                                <b>Check-Out</b> -{" "}
                                {moment(searchHotel.checkOutDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </div>
                            </ul>
                          </div>
                        </div>
                        <div className="spacer spacer--md"></div>
                        {/* <h4 className="">Price</h4> */}
                        <div className="d-flex justify-content-between mb-2">
                          <div className="fw-bold">
                            {searchHotel?.noOfRooms} room,{" "}
                            {moment(searchHotel.checkOutDate).diff(
                              searchHotel.checkInDate,
                              "days"
                            )}{" "}
                            nights
                          </div>
                          <div className="fw-bold pb-0">
                            &#8377; {billingInfo?.markedUpBasePrice}
                          </div>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                          <div>Taxes & fees</div>
                          <div>{priceFormatter(billingInfo?.Tax)}</div>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                          <div>GST</div>
                          <div>{priceFormatter(billingInfo?.GST)}</div>
                        </div>

                        <div className="d-flex justify-content-between mb-2">
                          <div>Other Charge</div>
                          <div>
                            {priceFormatter(billingInfo?.otherMarkUpCharges + billingInfo.myCharges)}
                          </div>
                        </div>

                        {/* <div className="d-flex justify-content-between mb-2">
                            <div>Taxes & fees</div>
                            <div>
                              &#8377; {billingInfo?.Tax + billingInfo?.GST}
                            </div>
                          </div> */}
                        <div className="d-flex justify-content-between pt-4 border-top">
                          <h3 className="fw-bold">Total</h3>
                          <h3 className="fw-bold">
                            {priceFormatter(
                              billingInfo?.markedUpBasePrice +
                              billingInfo?.Tax +
                              billingInfo?.GST +
                              billingInfo?.otherMarkUpCharges,
                              "INR"
                            )}
                          </h3>
                        </div>
                        <small className="mt-0 text-muted">
                          Rates are in Indian rupees
                        </small>
                      </CardBase>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <CardBase title={"Policy"}>
                        <ul className="list-group list-group-numbered list-group-flush">
                          {TBO.selectedRoom[0]?.CancellationPolicies?.map(
                            (policy, i) => (
                              <li
                                className="list-group-item justify-content-center px-0"
                                key={i}
                              >
                                <div className="d-flex">
                                  <div>
                                    <label className="fw-bold small">
                                      Charges:
                                    </label>
                                    {policy.ChargeType === 1 && (
                                      <span>&#x20B9;</span>
                                    )}
                                    {policy?.Charge}
                                    {policy.ChargeType === 2 && "%"}
                                    {policy.ChargeType === 3 && (
                                      <span>Night</span>
                                    )}
                                  </div>
                                  <div></div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <label className="fw-bold small">
                                      From:{" "}
                                    </label>{" "}
                                    {moment(policy?.FromDate).format(
                                      "MMM Do YYYY hh:mm a"
                                    )}
                                  </div>
                                  <div>
                                    <label className="fw-bold small">
                                      To:{" "}
                                    </label>{" "}
                                    {moment(policy?.ToDate).format(
                                      "MMM Do YYYY hh:mm a"
                                    )}
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                        <div className="mt-3">
                          <h5>Description:</h5>
                          <span>{removeSpecialCharacters(TBO.selectedRoom[0]?.CancellationPolicy)}</span>
                          {/* <div dangerouslySetInnerHTML={{ __html: String(selectedRoom[0]?.CancellationPolicy) }} /> */}
                        </div>
                      </CardBase>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  {!isBookingInProgress || !isBlockRoomInProgress ? (
                    <Button
                      label="Confirm Booking"
                      color="primary"
                      onClick={() => {
                        blockRoom();
                        setShowModal(false);
                      }}
                    />
                  ) : (
                    <Button
                      color="primary"
                      disabled={true}
                      size="sm"
                      label={<Loading type="inlineLoader" />}
                      onClick={() => { }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </React.Fragment>
      ) : (
        <></>
      )}

      {/* Alert when booking is successfull */}
      {isHotelBookingSuccess !== "" ? (
        <BsInfoModal
          title={isHotelBookingSuccess === "Confirmed" ? "Booking Success" : "Booking Failed"}
          subTitle={isHotelBookingSuccess === "Confirmed"
            ?
            "Your booking is successfull. You will be redirected to the home page."
            :
            "Your booking has failed. Please try again."}
          // onClose={() => {
          //   removeSelectedHotel();
          //   removeSelectedRoom();
          //   handleUpdateHotelData(false);
          //   history.push("/");
          // }}
          onConfirm={() => {
            if (isHotelBookingSuccess === "Confirmed") {
              removeSelectedHotel("TBO");
              removeSelectedRoom("HOTELBEDS");
              handleUpdateHotelData(false);
              setIsHotelBookingSuccess("");
              window.close();
            } else {
              setIsHotelBookingSuccess("");
            }
          }}
          icon={<Illustration
            size="small"
            name={isHotelBookingSuccess === "Confirmed" ? "Success" : "Error404"}
          />}
          confirmText="Done"
        />
      ) : (
        <></>
      )}

    </div>
  )
}
