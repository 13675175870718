import { motion, AnimatePresence } from "framer-motion";
import { PropsWithChildren, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoChevronDown } from "react-icons/io5";
interface Props {
  title: string;
  body?: JSX.Element;
  isCollapsed?: boolean;
  size?: "sm" | "default";
  isDisabled?: boolean;
  isChecked?: boolean;
  titleSize?: "md" | "lg" | "xl";
}

const AccordionCard = (props: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(!props.isCollapsed);
  return (
    <motion.div>
      <AnimatePresence>
        <motion.div
          key="header"
          className={
            "card card--accordion " + (props.size === "sm" ? "card--sm " : "")
          }
        >
          <div
            className="card-header"
            onClick={() => !props.isDisabled && setIsOpen(!isOpen)}
          >
            <span
              className={`fs-${
                props.titleSize === "md"
                  ? "5"
                  : props.titleSize === "lg"
                  ? "4"
                  : props.titleSize === "xl"
                  ? "3"
                  : "6"
              }`}
            >
              {props.title}
            </span>

            {props?.isChecked ? (
              <div>
                <FaCheckCircle size={26} fill="#00a991" />
              </div>
            ) : (
              <div className={"icon-expand " + (isOpen ? "open " : "")}>
                <IoChevronDown />
              </div>
            )}
          </div>
          <AnimatePresence>
            {/* Show Material List */}
            {isOpen && (
              <motion.div
                key="body"
                initial={{ opacity: 0, height: 0 }}
                animate={{
                  opacity: 1,
                  height: "auto",
                }}
                exit={{ opacity: 0, height: 0 }}
                className="card-body"
              >
                <div className="inner-padding pt-0 pb-3">{props.children}</div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default AccordionCard;
