import React, { useState } from "react";
import { FaLuggageCart, FaCheckCircle } from "react-icons/fa";
import { GiMeal } from "react-icons/gi";
import { MdEventSeat } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Common/Button/Button";
import { flightSlice } from "../../../../redux/reducers/flight";
import { State } from "../../../../redux/types";
import {
  ISeatAvailability,
  ISSR,
  ISSRLegInfo,
} from "../../../../utils/types/flightTypes";
import { calculateSSRPrices } from "../../../../utils/utilityFunctions/flight/flightUtilityFunctions";
import { priceFormatter } from "../../../../utils/utilityFunctions/utilityFunction";
import SeatSelection from "./SeatSelection/SeatSelection";
import SSRSelection from "./SSRSelection/SSRSelection";
interface ISSRToBeSold {
  title: string;
  subtitle: string;
  actionLabel: string;
  postSelectionLabel: string;
  type: "EB" | "MEAL" | "SEAT";
  icon: JSX.Element;
  addedPrice?: number;
  isDisabled?: boolean;
  hasSomeSelected?: boolean;
}

type Props = {
  ssrList: ISSRListPerLeg[];
  seatList: ISeatAvailability[];
};
export interface ISSRListPerLeg {
  AvailableSSR: ISSR[];
  LegInfo: ISSRLegInfo;
}
interface ICardProps {
  onSelect: () => void;
  data: ISSRToBeSold;
}

const SSRCard = ({ data, onSelect }: ICardProps) => {
  const price = data?.addedPrice ? data?.addedPrice : 0;
  return (
    <div className="d-flex align-items-center gap-4 mb-3 p-4 bg-white rounded">
      {data.icon}
      <div className="d-flex flex-column">
        <span className=" fw-semibold">{data.title}</span>
        <span className="small text-muted">{data.subtitle}</span>
      </div>
      <div className="ms-auto">
        {price > 0 ? (
          <span className="fw-bold me-2">+ {priceFormatter(price)}</span>
        ) : (
          <></>
        )}
        <Button
          label={
            data.hasSomeSelected ? (
              <span>
                {data.postSelectionLabel}
                {/* <FaCheckCircle size={14} className=" ms-2" /> */}
              </span>
            ) : (
              data.actionLabel
            )
          }
          disabled={data?.isDisabled}
          outline={!data.hasSomeSelected}
          className="py-2 px-3 "
          onClick={() => onSelect()}
        />
      </div>
    </div>
  );
};

const AddOns = ({ ssrList, seatList }: Props) => {

  const dispatch = useDispatch();

  const { SelectedAddOns, Itinerary, searchInfo } = useSelector((state: State) => state.flight);

  const [showSSRModal, setShowSSRModal] = useState(-1);



  const selectedSSRPrices = calculateSSRPrices(
    SelectedAddOns || [],
    Itinerary?.Journeys?.length,
    searchInfo?.adult + searchInfo?.child
  );

  const SSRToBeSold: ISSRToBeSold[] = [
    {
      title: "Select Seats",
      subtitle: "Pre selected seats are cheaper",
      actionLabel: "Select Seat",
      type: "SEAT",
      postSelectionLabel: "Change seat",
      icon: <MdEventSeat className="text-primary" size={24} />,
      addedPrice: selectedSSRPrices.Seat.Charge + selectedSSRPrices.Seat.GST,
      hasSomeSelected:
        SelectedAddOns && SelectedAddOns.map((e) => e.Seats).flat(3).length > 0,
      isDisabled: seatList.length === 0,
    },
    {
      title: "Add meals for your trip",
      subtitle: "Get hot and fresh meals served to your seat.",
      actionLabel: "Select Meal",
      type: "MEAL",
      postSelectionLabel: "Change/Edit Meal",
      icon: <GiMeal className="text-primary" size={24} />,
      addedPrice: selectedSSRPrices.Meals.Charge + selectedSSRPrices.Meals.GST,
      hasSomeSelected:
        SelectedAddOns &&
        SelectedAddOns.map((e) =>
          e.SSR.map((s) => s.filter((p) => p.type === "MEAL"))
        ).flat(3).length > 0,
      isDisabled: ssrList.length === 0,
    },
    {
      title: "Need more baggage?",
      subtitle: "15kg for check-in and 7kg carry on is already included",
      actionLabel: "Add Baggage",
      postSelectionLabel: "Update baggage",
      type: "EB",
      icon: <FaLuggageCart className="text-primary" size={24} />,
      addedPrice:
        selectedSSRPrices.Baggage.Charge + selectedSSRPrices.Baggage.GST,
      hasSomeSelected:
        SelectedAddOns &&
        SelectedAddOns.map((e) =>
          e.SSR.map((s) => s.filter((p) => p.type === "EB"))
        ).flat(3).length > 0,
      isDisabled: ssrList.length === 0,
    },
  ];


  
  return (
    <div>
      {SSRToBeSold.map((item, index) => (
        <SSRCard
          data={item}
          key={item.type}
          onSelect={() => {
            setShowSSRModal(index);
          }}
        />
      ))}
      {showSSRModal === 0 ? (
        <SeatSelection
          seatList={seatList}
          onClose={() => setShowSSRModal(-1)}
          onSubmit={(selectedSeats) => {
            dispatch(flightSlice.actions.updateSelectedSeat(selectedSeats));
            setShowSSRModal(-1);
          }}
        />
      ) : showSSRModal !== -1 ? (
        <SSRSelection
          type={SSRToBeSold[showSSRModal].type as "EB" | "MEAL"}
          ssrList={ssrList}
          onSubmit={(data) => {
            dispatch(flightSlice.actions.updateSelectedSSR(data));
            setShowSSRModal(-1);
          }}
          onClose={() => setShowSSRModal(-1)}
          onClear={() => {
            dispatch(
              flightSlice.actions.clearSelectedSSR({
                type: SSRToBeSold[showSSRModal].type as "EB" | "MEAL",
              })
            );
            setShowSSRModal(-1);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddOns;
