import React from 'react';

import Text from '@kiwicom/orbit-components/lib/Text';

interface TypeWrapper {
    label: string;
    children: React.ReactNode;
};

export default function Wrapper({ label, children }: TypeWrapper) {
    return (
        <div>
            <Text
                size="normal"
                type="secondary">{label}</Text>
                <div style={{ marginTop: 5 }}>{children}</div>
        </div>
    )
}
