import moment from "moment";
import axios, { AxiosResponse } from "axios";

import { API_URL } from "../../utils/constants";
import { getFlightItinerary } from "./../../utils/apis/CommonFlightAPIs";
import { IAirIQFlightBookingPayload, IAirIQFlightSearchPayload, IuAPIFlightSearchPayload } from "../../utils/types/flightTypes";



export interface ISearchFlight {
  getAvailability: (
    search: { from: string; to: string; depDate: string }[],
    passenger: {
      child: number;
      adult: number;
    }
  ) => Promise<AxiosResponse>;
  getItineraryPrice: (
    keys: {
      JourneySellKey: string;
      FareSellKey: string;
    }[],
    passenger: {
      child: number;
      adult: number;
    }
  ) => Promise<AxiosResponse>;
  getSSRAvailability: (
    Segments: {
      CarrierCode: string;
      FlightNumber: string;
      DepartureDate: string;
      DepartureStation: string;
      ArrivalStation: string;
    }[],
    passengerIndices: number[]
    // userId: string | number
  ) => Promise<AxiosResponse>;
  getSeatAvailability: (SegmentData: {
    STD: string;
    DepartureStation: string;
    ArrivalStation: string;
    FlightNumber: string;
    CarrierCode: string;
    PassengerID: number[];
  }) => Promise<AxiosResponse>;
}

class SearchFlights implements ISearchFlight {
  private baseUrl: string;
  private carrierCode: string;
  constructor(carrierCode: string) {
    this.baseUrl = `${API_URL}${carrierCode}/`;
    this.carrierCode = carrierCode.toUpperCase();
  }
  async getAvailability(
    search: { from: string; to: string; depDate: string }[],
    passenger: {
      child: number;
      adult: number;
    }
  ): Promise<AxiosResponse> {

    // console.log("search: ", search);
    // console.log("passenger: ", passenger);

    let PaxPriceType: { PaxType: "ADT" | "CHD"; PaxCount: number }[] = [
      {
        PaxType: "ADT",
        PaxCount: passenger.adult,
      },
    ];
    passenger.child > 0 &&
      PaxPriceType.push({
        PaxType: "CHD",
        PaxCount: passenger.child,
      });

    // console.log("PaxPriceType: ", PaxPriceType);

    const TravelData = search.map((s) => ({
      ArrivalStation: s.to,
      BeginDate: moment(s.depDate).format("YYYY-MM-DDThh:mm:ss"),
      CarrierCode: this.carrierCode,
      CurrencyCode: "INR",
      DepartureStation: s.from,
      Dow: "Daily",
      EndDate: moment(s.depDate).format("YYYY-MM-DDThh:mm:ss"),
      // FareTypes: this.carrierCode === "G8" ? ["R"] : ["R", "MX", "IO"],
      PaxCount: passenger.adult + passenger.child,
      PaxPriceType,
    }));
    return axios.post(`${this.baseUrl}booking/getAvailabilityVer2`, {
      TravelData,
    });
  }
  // return getFlightAvailability(
  //   TravelData.map((e) =>
  //     this.carrierCode === "G8" ? { ...e, ProductClasses: ["GS"] } : e
  //   ),
  //   `${this.baseUrl}booking/getAvailabilityVer2`
  // );
  // }
  async getItineraryPrice(
    keys: {
      JourneySellKey: string;
      FareSellKey: string;
    }[],
    passenger: {
      child: number;
      adult: number;
    }
  ): Promise<AxiosResponse> {
    return getFlightItinerary(
      keys,
      passenger,
      `${this.baseUrl}booking/getItineraryPrice`
    );
  }
  async getSSRAvailability(
    Segments: {
      CarrierCode: string;
      FlightNumber: string;
      DepartureDate: string;
      DepartureStation: string;
      ArrivalStation: string;
    }[],
    passengerIndices: number[]
    // userId: string | number
  ): Promise<AxiosResponse> {
    let reqBody = {
      userId: localStorage.getItem("sessionId"),
      SegmentKeyList: Segments,
      CurrencyCode: "INR",
      Passengers: passengerIndices,
    };
    return axios.post(
      `${this.baseUrl}booking/GetSSRAvailabilityForBooking`,
      reqBody
    );
  }
  getSeatAvailability(SegmentData: {
    STD: string;
    DepartureStation: string;
    ArrivalStation: string;
    FlightNumber: string;
    CarrierCode: string;
    PassengerID: number[];
  }): Promise<AxiosResponse> {
    return axios.post(`${this.baseUrl}booking/getSeatAvailability`, {
      ...SegmentData,
      userId: localStorage.getItem("sessionId"),
    });
  }
};

const spicejetObj = new SearchFlights("sg");
const goAirObj = new SearchFlights("g8");
const indigoObj = new SearchFlights("6E");
// export const searchFlights: ISearchFlight[] = [spicejetObj];
export const searchFlights: ISearchFlight[] = [spicejetObj, indigoObj];


/*
* AirIQ
*/
export interface IAirIQFlight {

  searchTickets: (
    payload: IAirIQFlightSearchPayload[]
  ) => Promise<AxiosResponse>;

  ticketBooking: (
    payload: IAirIQFlightBookingPayload[]
  ) => Promise<AxiosResponse>;

  ticketDetails: (
    booking_id: string
  ) => Promise<AxiosResponse>;

};

class AirIQFlight implements IAirIQFlight {

  async searchTickets(payload: IAirIQFlightSearchPayload[]): Promise<AxiosResponse> {
    return axios.post(`${API_URL}airIq/search`, payload);
  };

  async ticketBooking(payload: IAirIQFlightBookingPayload[]): Promise<AxiosResponse> {
    return axios.post(`${API_URL}airIq/booking`, payload);
  };

  async ticketDetails(booking_id: string): Promise<AxiosResponse> {
    return axios.get(`${API_URL}airIq/bookingDetail?${booking_id}`);
  };

};

export const airIQFlight: IAirIQFlight = new AirIQFlight();


/*
* uAPI
*/
export interface IuAPIFlight {

  searchTickets: (
    payload: IuAPIFlightSearchPayload
  ) => Promise<AxiosResponse>;
  airPricing: (
    payload: any
  ) => Promise<AxiosResponse>;

};

class UAPIFlight implements IuAPIFlight {

  async searchTickets(payload: IuAPIFlightSearchPayload): Promise<AxiosResponse> {
    return axios.post(`${API_URL}1g/uAPI/booking/low-fare-search`, payload);
  };

  async airPricing(payload: any): Promise<AxiosResponse> {
    return axios.post(`${API_URL}1g/booking/air-pricing`, payload);
  };

};

export const uAPIFlight: IuAPIFlight = new UAPIFlight();