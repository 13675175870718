import React, { useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { MdDone } from "react-icons/md";
import moment from "moment";

import BsModal from "../../../../components/Modal/BSModal";
import Button from "../../../../components/Common/Button/Button";
import ListCheck from "../../../../components/Common/ListCheck/ListCheck";
import RoomPlaceholder from "../../../../assets/images/hotel-placeholder.png";

import { State, ITBORoom, IHotel, ICommon } from "../../../../redux/types";
import { getMarkedUpPrice, priceFormatter, removeSpecialCharacters, splitString } from "../../../../utils/utilityFunctions/utilityFunction";

interface Props {
    active?: boolean;
    room: ITBORoom;
    onSelect: () => void;
    onRemove: () => void;
}

export default function THotelDetailsRoomInfo({ active, room, onSelect, onRemove }: Props) {

    const [
        { TBO, metaData, searchHotel },
        { ClientData }
    ]: [IHotel, ICommon] = useSelector((state: State) => [
        state.hotel,
        state.commonState
    ]);

    const [viewDetails, setViewDetails] = useState<boolean>(false); // to render details of room in a modal

    return (
        <React.Fragment>

            <motion.div className="card card--room-info">
                <div className="card-img">
                    <img src={RoomPlaceholder} alt="" />
                </div>
                <div className="card-body">
                    <div>
                        <h3>{room?.RoomTypeName}</h3>
                    </div>
                    {/* <h3>{room?.RoomTypeName}</h3> */}
                    <div className="d-flex align-items-center">
                        {/* <span>400 sq.ft</span> */}
                        {/* <div
            className="bg-dark mx-2"
            style={{ width: 4, height: 4, borderRadius: 4 }}
          ></div> */}
                        <span>{room?.BedTypes?.map((bed, index) => (
                            <span key={index}>{bed}</span>
                        ))}</span>
                    </div>
                    <div className="spacer spacer--sm"></div>
                    <p className="small" dangerouslySetInnerHTML={{
                        __html: splitString(room?.RoomDescription, 100) + "..."
                    }} />

                    <div className="spacer spacer--sm"></div>
                    {
                        room?.Amenities?.slice(0, 3).map((amenity, index) => (
                            <ListCheck title={amenity} key={index} />
                        ))
                    }

                    <Button
                        type={"button"}
                        color={"link"}
                        size={"sm"}
                        label={"View Details"}
                        className={"text-decoration-none px-0"}
                        onClick={() => setViewDetails(true)}
                    />
                </div>

                <div className="card-footer d-flex align-items-center justify-content-between">
                    <div>
                        <div className="d-flex align-items-start gap-1">
                            <h3 className="mb-0">
                                {priceFormatter(
                                    getMarkedUpPrice(
                                        metaData?.markUps,
                                        (room?.Price?.OfferedPrice * searchHotel.RoomGuest.length),
                                        // metaData.BookingSupplier[0].SupplierName,
                                        "TBO",
                                        ClientData.GSTRates[0]?.Value,
                                        metaData.affiliateMarkup,
                                        searchHotel.RoomGuest
                                    )?.markedUpPrice.Base || 0
                                )}
                            </h3>
                            {/* <span className="text-muted small"> /per night</span> */}
                        </div>
                        <div className="small">+ {priceFormatter(room?.Price?.Tax) || 0} taxes & fees</div>
                        <div className="small">+ {priceFormatter(room?.Price?.TotalGSTAmount) || 0} GST</div>
                    </div>

                    {TBO.selectedRoom?.findIndex((each) => each.RoomIndex === room?.RoomIndex) !== -1 ?
                        // <Button
                        //     type={"button"}
                        //     color={"danger"}
                        //     outline={true}
                        //     size={"sm"}
                        //     label={"Selected"}
                        //     onClick={() => {
                        //         onRemove();
                        //         setViewDetails(false);
                        //     }} />
                        <button
                            type="button"
                            className="btn btn-sm btn-success"
                            onClick={() => {
                                onRemove();
                                setViewDetails(false);
                            }}
                        >
                            <MdDone className="me-1" /> Selected
                        </button>
                        :
                        <Button
                            type={"button"}
                            color={"primary"}
                            size={"sm"}
                            label={"Select"}
                            onClick={() => {
                                onSelect();
                            }} />}
                </div>
            </motion.div>

            {/* View details for room */}
            {viewDetails ? <React.Fragment>
                <BsModal
                    size={"lg"}
                    title={"Information"}
                    subTitle={"Detailed information of the room"}
                    scrollable={true}
                    onClose={() => setViewDetails(false)}
                    body={<React.Fragment>
                        {/* <div className="spacer spacer--sm"></div> */}
                        <div className="row">
                            <div className="col-sm-12">
                                <h4>
                                    <span>Description:</span>
                                </h4>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: String(room?.RoomDescription
                                            ?
                                            room.RoomDescription : `${<span className="text-muted small fw-bold">No description available</span>}`)
                                    }} />
                            </div>

                            <div className="spacer spacer--sm"></div>
                            <div className="col-sm-12">
                                <h4>
                                    <span>Amenities:</span>
                                </h4>
                                <div className="row">
                                    {room.Amenity.length < 5 ? <div className="col-12">
                                        {room.Amenity.map((amenity, index) => (
                                            <div className="d-flex flex-wrap" key={index}>
                                                <ListCheck title={amenity} />
                                            </div>
                                        ))}
                                        {room.Amenity?.length === 1 && room.Amenity[0]?.includes(",")
                                            ?
                                            room.Amenity[0].split(",").map((item, index) => (
                                                <ListCheck title={item} key={index} />
                                            ))
                                            :
                                            room.Amenity.map((amenity, index) => (
                                                <div className="d-flex flex-wrap" key={index}>
                                                    <ListCheck title={amenity} />
                                                </div>
                                            ))}
                                    </div>
                                        :
                                        <React.Fragment>
                                            <div className="col-md-6 col-sm-12">
                                                {room.Amenity?.slice(0, Math.abs(room.Amenity?.length / 2))?.map((amenity, index) => (
                                                    <div className="d-flex flex-wrap" key={index}>
                                                        <ListCheck title={amenity} />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                {room.Amenity?.slice(Math.abs(room.Amenity?.length / 2))?.map((amenity, index) => (
                                                    <div className="d-flex flex-wrap" key={index}>
                                                        <ListCheck title={amenity} />
                                                    </div>
                                                ))}
                                            </div>
                                        </React.Fragment>}
                                </div>
                            </div>
                        </div>

                        <div className="spacer spacer--sm"></div>
                        <div>
                            <h4>
                                <span>Cancellation Policy:</span>
                            </h4>
                            <ul className="list-group list-group-numbered list-group-flush">
                                {
                                    room?.CancellationPolicies?.map((policy, i) => (
                                        <li className="list-group-item justify-content-center px-0" key={i}>
                                            <div className="d-flex">
                                                <div>
                                                    <label className="fw-bold small">Charges:</label>
                                                    {policy.ChargeType === 1 && <span>&#x20B9;</span>}
                                                    {policy?.Charge}
                                                    {policy.ChargeType === 2 && '%'}
                                                    {policy.ChargeType === 3 && <span> Night</span>}
                                                </div>
                                                <div></div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div><label className="fw-bold small">From: </label> {moment(policy?.FromDate).format("MMM Do YYYY hh:mm a")}</div>
                                                <div><label className="fw-bold small">To: </label> {moment(policy?.ToDate).format("MMM Do YYYY hh:mm a")}</div>
                                            </div>
                                        </li>
                                    )
                                    )}
                            </ul>
                            <div className="mt-3">
                                <span className="fw-bold">Description: </span>
                                <span>{removeSpecialCharacters(room?.CancellationPolicy)}</span>
                                {/* <div dangerouslySetInnerHTML={{ __html: String(room?.CancellationPolicy) }} /> */}
                            </div>
                        </div>
                    </React.Fragment>}
                    footer={<div className="w-100 d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex align-items-start gap-1">
                                <h3 className="mb-0">
                                    {priceFormatter(
                                        getMarkedUpPrice(
                                            metaData?.markUps,
                                            (room?.Price?.OfferedPrice * searchHotel.RoomGuest.length),
                                            // metaData.BookingSupplier[0].SupplierName,
                                            "TBO",
                                            ClientData.GSTRates[0]?.Value,
                                            metaData.affiliateMarkup,
                                            searchHotel.RoomGuest
                                        )?.markedUpPrice.Base || 0
                                    )}
                                </h3>
                                {/* <span className="text-muted small"> /per night</span> */}
                            </div>
                            <div className="small">+ {priceFormatter(room?.Price?.Tax) || 0} taxes & fees</div>
                            <div className="small">+ {priceFormatter(room?.Price?.TotalGSTAmount) || 0} GST</div>
                        </div>

                        {TBO.selectedRoom?.findIndex((each) => each.RoomIndex === room?.RoomIndex) !== -1 ?
                            // <Button
                            //     type={"button"}
                            //     color={"danger"}
                            //     outline={true}
                            //     size={"sm"}
                            //     label={"Selected"}
                            //     onClick={() => {
                            //         onRemove();
                            //         setViewDetails(false);
                            //     }} />
                            <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => {
                                    onRemove();
                                    setViewDetails(false);
                                }}
                            >
                                <MdDone className="me-1" /> Selected
                            </button>
                            :
                            <Button
                                type={"button"}
                                color={"primary"}
                                size={"sm"}
                                label={"Select"}
                                onClick={() => {
                                    onSelect();
                                    // setViewDetails(false);
                                }} />}
                    </div>}
                />
            </React.Fragment> : <></>}

        </React.Fragment>
    );
}

