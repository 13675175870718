import { useEffect, useRef, useState } from "react";

export const useElementOnScreen = (options: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const callbackFunction = (entries: any) => {
    // entries.forEach((entry: any) => {
    //     if (entry.isIntersecting) {
    //         setIsVisible(true);
    //     } else {
    //         setIsVisible(false);
    //     }
    // });
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      // observer.disconnect();
    };
  }, [containerRef, options]);

  return [containerRef, isVisible];
};
