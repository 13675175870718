import { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';

import {
    ISearchHotelPayload,
    IHotelbedsBooking,
    TTicketStatus
} from "../../types/hotelTypes";
import {
    ICommon,
    IDynamicHotelRate,
    IHotel,
    IPassenger,
    ISelectedHotel,
    IStaticHotelRoomInfo,
    IUser,
    State
} from './../../../redux/types';
import useHotel from './useHotel';
import useHotelMetaData from './useHotelMetaData';
import { searchHotelBeds } from "../../../Interfaces/SearchHotel/SearchHotel";
import { postBookingHotel } from '../../../Interfaces/SearchHotel/PostBookHotel';
import { formatHotelAvailabilityList } from '../../utilityFunctions/hotelUtitlityFunctions';
import usePayment, { ICreateOrderResponse, IVerifyOrderResponse } from '../Payment/usePayment';


export default function useHotelDetails() {

    const paymentForm = useRef<HTMLDivElement>(null);

    const [
        { userId, affiliateId },
        { Client, ClientData },
        { searchHotel, filters, passenger, metaData, HotelBeds }
    ]: [IUser, ICommon, IHotel] = useSelector((state: State) => [
        state.user,
        state.commonState,
        state.hotel
    ]);

    const {
        handleHotelFilters,
        updateSearchedHotelList,
        removeSelectedHotel,
        updateSelectedRoom,
        handleShowToast,
        updatePaginationInfo,
        resetPassengerInfo,
        handleHotelListingInfo,
        handleHotelListingInfoReset,
        resetPaginationInfo,
        resetMarkups,
        resetSelectedNearbySearchGeoLocationFromFilters
    } = useHotel();
    const {
        handlePostMarkup,
        handleAffiliatePostMarkup
    } = useHotelMetaData();


    const [confirmCancelPayment, setConfirmCancelPayment] = useState<boolean>(false);
    const [isPaymentDone, setIsPaymentDone] = useState<boolean>(false);


    /* --------------------------------------------------
    |           For Handling Payment
    --------------------------------------------------- */
    const handlePaymentSuccess = async (onSuccessDetails: IVerifyOrderResponse) => {

        console.log(" - SUCCESSFUL PAYMENT -");
        // console.log("Payment response: ", onSuccessDetails);
        handleBookingForHotelBeds(onSuccessDetails);

    };

    const handlePaymentFailure = (
        onFailureDetails: ICreateOrderResponse,
        isPaymentDone: boolean
    ) => {

        handleShowToast({
            title: "Payment Failed!",
            subTitle: "If any amount is debited from your account please contact support.",
            type: "warning",
            time: 5000
        });

        handleCreateHotelBooking(
            {
                order_amount: onFailureDetails.order_amount,
                order_id: onFailureDetails.order_id,
                order_status: null,
                payment_session_id: onFailureDetails.payment_session_id
            },
            isPaymentDone ? "Failed_Booking" : "Payment_Failed",
            "",
            null,
            null,
            null
        );

    };

    const addPayment = async (bookingSums: number) => {

        // console.log("Booking sum to handle payment: ", bookingSums);

        /* 
        The below code is validating the guest details (FirstName) 
        If any one of the guest first name is empty then it will 
        assign validateGuestDetails as true
        */
        let validateGuestDetails = false;
        passenger.forEach((eachRoom) => {
            eachRoom.forEach((eachPassengerDetail) => {
                eachPassengerDetail.FirstName.trim().length === 0 && (validateGuestDetails = true)
            })
        });

        if (validateGuestDetails) {
            handleShowToast({
                title: "Invalid Guest Info!",
                subTitle: "Please provide the guest information correctly for all the guests.",
                type: "warning",
            });
        } else {

            // TODO: remove this function call from here and un-comment the below code block
            // handleCreateHotelBooking(
            //     {
            //         order_amount: "",
            //         order_id: "order_id#123",
            //         order_status: null,
            //         payment_session_id: null
            //     },
            //     "Payment_Failed",
            //     "TESTING"
            // );
            if (bookingSums) {

                setIsPaymentFormVisible(true);
                setNetPayable(String(bookingSums));
                setOnSuccessDetails(bookingSums);

            } else {

                console.error("Booking sum can not be 0 or falsy value.");
                handleShowToast({
                    title: "Payment couldn't be initiated!",
                    subTitle: "We could not validate the booking amount that is to be paid.",
                    type: "warning",
                    time: 5000
                });

            };

        };

    };

    const {
        isGeneratingOrder,
        isPaymentFormVisible,
        setIsPaymentFormVisible,
        setNetPayable,
        setOnSuccessDetails
    } = usePayment({
        paymentForm,
        handlePaymentSuccess,
        handlePaymentFailure
    });

    // for booking status
    const [isHotelBookingSuccess, setIsHotelBookingSuccess] = useState<"Confirmed" | "Failed" | "">("");

    // show modal for AvailabilityType - when type === 'Available'
    const [showModalAvailabilityType, setShowModalAvailabilityType] = useState<boolean>(false);

    // loading
    const [isBookingInProgress, setIsBookingInProgress] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false); // for search hotel


    /* --------------------------------------------------
    |   Get Hotel Availability - AvailabilityRQ
    -------------------------------------------------- */
    /**
     * This function is used to get the list of hotels based on the search parameters
     */
    const searchHotelHandler = (page?: number) => {

        let checkIn = moment(searchHotel.checkInDate).format("YYYY-MM-DD");
        let checkOut = moment(searchHotel.checkOutDate).format("YYYY-MM-DD");


        /*
         * constructing payload with search parameters
         * "occupancies" is an array of objects containing room and guest information
         * if a room has any children, then age of that child has to be provided
         * "paxes" array contains the info for children. "type": "CH" -> "CH" stands for children
        */
        let payload: ISearchHotelPayload = {
            stay: {
                checkIn: checkIn,
                checkOut: checkOut,
            },
            occupancies: searchHotel.RoomGuest.map((each) => {
                return Object.assign({
                    rooms: 1,
                    adults: each?.NoOfAdults,
                    children: each?.NoOfChild,
                    paxes: each?.ChildAge?.map((age) => Object.assign({
                        type: "CH",
                        age: age
                    }))
                })
            }),
            filter: {
                minCategory: +filters.StarRating[0] || 1,
                maxCategory: +filters.StarRating[0] || 5,
                // minRate: filters.price.length > 1 ? +filters.price[0] : 500,
                // maxRate: filters.price.length > 2 ? +filters.price[1] : 65000,
                // maxHotels: 30
                // maxRatesPerRoom: searchHotel.RoomGuest.length,
            },
            sourceMarket: searchHotel.whereTo.CountryCode
        };

        // add maximum price
        if (payload.filter && filters.price.length > 0) {
            payload.filter.minRate = 200;
            payload.filter.maxRate = +filters.price[0];
        };

        // add selected boards for filter
        if (filters.HotelBoards.length) {
            payload.boards = {
                included: true,
                board: filters.HotelBoards
            };
        };

        // add category
        if (filters.HotelCategory.length) {
            payload.reviews = [{
                type: "TRIPADVISOR",
                minRate: filters.HotelCategory[0],
                maxRate: "5",
                minReviewCount: 2
            }]
        };

        // add accommodations
        if (filters.accommodationTypes.length) {
            payload.accommodations = filters.accommodationTypes;
        };

        // if geo-location is selected
        if (searchHotel.geoLocation.location.latitude && searchHotel.geoLocation.location.longitude) {
            payload.geolocation = {
                latitude: searchHotel.geoLocation.location.latitude,
                longitude: searchHotel.geoLocation.location.longitude,
                radius: 4,
                unit: "km"
            };
        } else {
            if (filters.selectedNearbySearchGeoLocation) resetSelectedNearbySearchGeoLocationFromFilters();
        };


        //* handle child age validation
        let isChildAgeValid = true;
        searchHotel.RoomGuest.forEach((each) => {
            if (each.NoOfChild !== each.ChildAge.length) {
                handleShowToast({
                    title: "Child Age Missing!",
                    subTitle: "Please provide age of child for each room",
                    type: "warning",
                });
                isChildAgeValid = false;
                return;
            };
        });
        if (!isChildAgeValid) return;

        //* checking whether check-out date is after check-in date or not
        if (moment(checkOut).isAfter(checkIn)) {

            //* checking whether destination is selected or not
            if (
                searchHotel.whereTo.HB_DestinationNumber !== ""
                ||
                (searchHotel.geoLocation.location.latitude && searchHotel.geoLocation.location.longitude)
            ) {

                setIsLoading(prev => !prev);
                updateSearchedHotelList([], "HOTELBEDS");
                resetMarkups();
                console.log("Calling AvailabilityRQ!!!");

                let isUsingGeoLocation: boolean = searchHotel.geoLocation.name ? true : false;

                const chunk_size = 50;
                searchHotelBeds.getAvailability(
                    payload,
                    searchHotel.whereTo.HB_DestinationNumber,
                    page || 1,
                    isUsingGeoLocation,
                    chunk_size
                ).then(
                    (response: AxiosResponse) => {
                        // console.log("Response for available hotels: ", response?.data?.data?.hotels?.hotels);

                        removeSelectedHotel("HOTELBEDS");

                        let hotels: ISelectedHotel[] = response?.data?.data?.hotels?.hotels?.map((hotel: ISelectedHotel) => {
                            return Object.assign({
                                ...hotel,
                                noOfNights: moment(searchHotel.checkOutDate).diff(searchHotel.checkInDate, "days")
                            })
                        });

                        updatePaginationInfo("totalPages", response?.data?.data?.pagination?.total_pages, "HOTELBEDS");
                        updateSearchedHotelList(hotels, "HOTELBEDS");

                        // console.log("Hotel list for HotelBeds: ", formatHotelAvailabilityList(
                        //     response?.data?.data?.hotels?.hotels,
                        //     checkIn,
                        //     checkOut,
                        //     "HOTELBEDS"
                        // ));
                        handleHotelListingInfo(
                            formatHotelAvailabilityList(
                                response?.data?.data?.hotels?.hotels,
                                checkIn,
                                checkOut,
                                "HOTELBEDS"
                            )
                        );

                        // setting maximum price for filter
                        // handleHotelFilters(
                        //     'price',
                        //     "",
                        //     String(
                        //         Math.ceil(
                        //             hotels?.reduce((acc, shot) => acc = acc > Number(shot.hotel_dynamic_data?.maxRate)
                        //                 ?
                        //                 acc
                        //                 :
                        //                 Number(shot.hotel_dynamic_data?.maxRate), 0) || 0
                        //         )
                        //     )
                        //     // String(Math.max.apply(Math, hotels?.map(hotel => +hotel.hotel_dynamic_data?.maxRate)))
                        // );
                    }, (error: AxiosError) => {
                        console.log("Error for available hotels: ", error?.response);
                        // handleShowToast({
                        //     title: "Error !",
                        //     subTitle: "Seems like there is something wrong. Please try again later.",
                        //     type: "error",
                        // });
                    }
                ).finally(() => setIsLoading(false));

            } else {

                handleShowToast({
                    title: "City Not Selected!",
                    subTitle: "Please select a city to search hotels",
                    type: "error",
                });

            };

        } else {

            handleShowToast({
                title: "Invalid Dates!",
                subTitle: "Check-out date should be greater than check-in date",
                type: "error",
            });

        };

        //* get Markup for searched hotel based on selected destination
        handlePostMarkup(
            Number(searchHotel.whereTo.DestinationId),
            searchHotel.checkInDate,
            searchHotel.checkOutDate,
            affiliateId
        );

        if (affiliateId !== null) {
            handleAffiliatePostMarkup(
                Number(searchHotel.whereTo.DestinationId),
                searchHotel.checkInDate,
                searchHotel.checkOutDate,
                affiliateId
            );
        };

    };

    /**
     * It checks for rate changes for selected rate combinations.
     */
    const handleCheckRate = () => {
        searchHotelBeds.checkRate(HotelBeds.selectedRoomCombinations.combination).then(
            (response: AxiosResponse) => {
                // console.log("Response for check rate: ", response.data?.data);

                let res = response.data?.data;
                let combinations = JSON.parse(JSON.stringify(HotelBeds.selectedRoomCombinations));

                HotelBeds.selectedRoomCombinations.combination.forEach((eachRate, index) => {
                    res.hotel.rooms.forEach((eachRoom: any) => {
                        eachRoom.rates.forEach((eachRoomRate: any) => {
                            if (
                                eachRate.rateKey === eachRoomRate.rateKey
                                &&
                                eachRate.adults === eachRoomRate.adults
                                &&
                                eachRate.children === eachRoomRate.children
                                &&
                                eachRate.boardCode.toLocaleLowerCase() === eachRoomRate.boardCode.toLocaleLowerCase()
                            ) {
                                // re-assign combination objects
                                combinations.combination[index] = eachRoomRate;
                                console.log("Rate Check Found: ", eachRoomRate);
                            }
                        })
                    })
                });
                updateSelectedRoom(combinations, "HOTELBEDS");
                // console.log("New Combinatios: ", combinations);

            }, (error: AxiosError) => {
                console.log("Error for check rate: ", error?.response);
            }
        );
    };

    /*
    * This function is used to create a hotel booking for HotelBeds
    */
    const handleBookingForHotelBeds = (
        onSuccessDetails: IVerifyOrderResponse
    ) => {

        /* 
        The below code is validating the guest details (FirstName) 
        If any one of the guest first name is empty then it will 
        assign incompleteGuestInformation as true
        */
        let incompleteGuestInformation = false;
        passenger.forEach((eachRoom) => {
            eachRoom.forEach((eachPassengerDetail) => {
                if (eachPassengerDetail.FirstName.trim().length === 0) {
                    incompleteGuestInformation = true
                };
                if (eachPassengerDetail.InternationalTraveler === "Yes") {
                    if (eachPassengerDetail?.PassportNo.trim().length === 0) {
                        incompleteGuestInformation = true;
                    };
                    if (eachPassengerDetail.PassportExpDate?.trim().length === 0) {
                        incompleteGuestInformation = true;
                    };
                    if (eachPassengerDetail.PassportIssueDate?.trim().length === 0) {
                        incompleteGuestInformation = true;
                    };
                } else if (eachPassengerDetail.InternationalTraveler === "No") {
                    if (eachPassengerDetail.PAN?.trim().length === 0) {
                        incompleteGuestInformation = true;
                    };
                };
            });
        });

        if (incompleteGuestInformation) {

            handleShowToast({
                title: "Invalid Guest Info!",
                subTitle: "Please provide the guest information correctly for all the guests.",
                type: "warning"
            });

        } else {

            setIsBookingInProgress(true);

            let payload: IHotelbedsBooking = {
                clientReference: "IntegrationAgency",
                remark: "",
                holder: {
                    name: passenger[0][0].FirstName,
                    surname: passenger[0][0].LastName,
                },
                rooms: passenger.map((eachRoomPax: IPassenger[], index: number) => {
                    return Object.assign({
                        rateKey: HotelBeds.selectedRoomCombinations.combination[index].rateKey,
                        paxes: eachRoomPax.map((eachPax: IPassenger) => {
                            return Object.assign({
                                roomId: 1,
                                type: !eachPax.Age ? "AD" : "CH",
                                name: eachPax.FirstName,
                                surname: eachPax.LastName,
                            })
                        })
                    });
                })
            };

            searchHotelBeds.bookRoom(payload).then(
                (response: AxiosResponse) => {

                    // console.log("Response for HotelBeds booking: ", response?.data.data, response?.data.data?.booking.reference);

                    if (response?.data.data?.booking?.status.toLowerCase() === "confirmed") {
                        handleCreateHotelBooking(
                            onSuccessDetails,
                            "Ticketed_Booking",
                            response.data.data.booking.reference,
                            response.data.data.booking.clientReference,
                            response.data.data.booking.hotel.supplier.name,
                            response.data.data.booking.hotel.supplier.vatNumber,
                        );
                        // response.data.data.booking.reference.split("-")[0]
                        // +
                        // response.data.data.booking.reference.split("-")[1]
                        setIsHotelBookingSuccess("Confirmed");
                    } else {
                        console.log("* Booking is not confirmed!!!");
                    };
                    // cancelBooking(response.data.data.booking.reference); //WARN: for testing only

                }, (error: AxiosError) => {

                    handleCreateHotelBooking(
                        onSuccessDetails,
                        "Failed_Booking",
                        "",
                        null,
                        null,
                        null
                    );

                    //WARN: remove this
                    // handleCreateHotelBooking(
                    //     "IBE00740"
                    // );
                    // setIsHotelBookingSuccess("Confirmed");
                    // handleCreateHotelBooking("");
                    console.log("Error for hotel bed booking: ", error?.response?.data);
                    if (error?.response?.data?.message?.error?.message?.toLowerCase().includes("insufficient allotment")) {
                        handleShowToast({
                            title: "Something went wrong!",
                            subTitle: "The selected room can't be booked. Please select some other type of room or hotel.",
                            type: "error",
                        });
                    } else {
                        handleShowToast({
                            title: "Something went wrong!",
                            subTitle: String(error?.response?.data?.message?.error?.message) || "It seems like something went wrong. Please try again later.",
                            type: "error",
                            // time: 4000
                        });
                    };

                }
            ).finally(() => setIsBookingInProgress(false));

        };

    };

    /* --------------------------------------------------
    |              For Canceling a Previous Booking
    -------------------------------------------------- */
    const cancelBooking = (referenceNumber: string) => {
        searchHotelBeds.cancelPreviousBooking(referenceNumber).then(
            (response: AxiosResponse) => {
                console.log("Response for hotel bed booking cancelation: ", response?.data.data);
                response?.data?.data?.booking?.status.toLowerCase() === "cancelled"
                    ?
                    handleShowToast({
                        title: "Booking Cancelled!",
                        subTitle: "You have successfully cancelled your booking.",
                        type: "info",
                    })
                    :
                    console.log("* Booking is not cancelled!!!");
            }, (error: AxiosError) => {
                console.log("Error for hotel bed booking cancelation: ", error?.response);
                handleShowToast({
                    title: "Something went wrong!",
                    subTitle: "It seems like something went wrong. Please try again later.",
                    type: "error",
                });
            }
        );
    };

    // creates booking at server side(.NET - Admin Portal)
    const handleCreateHotelBooking = (
        onSuccessDetails: IVerifyOrderResponse,
        ticketStatus: TTicketStatus,
        referenceId: string,
        clientReference: string | null,
        supplierName: string | null,
        supplierVatNumber: string | null
    ) => {

        // let combinations = createRoomCombinationsForHotelBedsRateArray(HotelBeds.searchedHotel.hotel_dynamic_data, searchHotel.RoomGuest);

        let staticRoom: IStaticHotelRoomInfo[] = [];
        HotelBeds.selectedRoomCombinations.combination.forEach((eachCombination: IDynamicHotelRate) => {
            staticRoom.push(HotelBeds.searchedHotel.hotel_static_data.rooms?.filter(eachRoom => eachCombination.rateKey.includes(eachRoom.roomCode))[0] || []);
        });
        // console.log("Static information for the selected room: ", staticRoom.flat());

        postBookingHotel.createBooking(
            onSuccessDetails,
            ticketStatus,
            ClientData,
            Client,
            userId !== null ? +userId : 0,
            metaData,
            HotelBeds.selectedRoomCombinations,
            staticRoom,
            searchHotel,
            HotelBeds.searchedHotel,
            passenger,
            referenceId,
            clientReference,
            supplierName,
            supplierVatNumber
        ).then((response: AxiosResponse) => {

            // console.log("Response for CreateBooking: ", response?.data);

            let voucherPayload = {
                hotelDetails: {
                    HotelName: HotelBeds.searchedHotel.hotel_dynamic_data?.name,
                    HotelAddress: HotelBeds.searchedHotel.hotel_static_data.address?.content || HotelBeds.searchedHotel.hotel_static_data.address?.street,
                    HotelDestinationName: searchHotel.whereTo?.CityName + ", " + searchHotel.whereTo?.CountryName,
                    HotelCategory: HotelBeds.searchedHotel.hotel_dynamic_data.categoryCode + "-" + HotelBeds.searchedHotel.hotel_dynamic_data.categoryName,
                    HotelPhoneNumber: HotelBeds.searchedHotel.hotel_static_data.phones?.map(each => each.phoneNumber)?.join(", ") || "Not Available",
                },
                passengerDetails: {
                    leadPassenger: (passenger[0][0].FirstName || "") + (passenger[0][0].LastName || ""),
                    passengerDetails: passenger, // at least one pax per room has to be mentioned. If child is present in room, then mention child age as well
                },
                bookingDetails: {
                    HotelbedsBookingReference: referenceId,
                    CheckInDate: searchHotel.checkInDate,
                    CheckOutDate: searchHotel.checkOutDate,
                    RoomType: HotelBeds.selectedRoomCombinations?.roomTypes ? Object.keys(HotelBeds.selectedRoomCombinations?.roomTypes).join(", ") : "Not Available",
                    BoardType: HotelBeds.selectedRoomCombinations.combination.map(each => each.boardName)?.join(", "),
                    // RateComments?: string;
                }
            };
            postBookingHotel.sendVoucherPayload(
                voucherPayload.hotelDetails,
                voucherPayload.passengerDetails,
                voucherPayload.bookingDetails
            );

        }, (error: AxiosError) => {

            console.log("Error for CreateBooking: ", error?.response);

        });

    };

    return {
        paymentForm,
        addPayment,
        isPaymentDone,
        setIsPaymentDone,
        isPaymentFormVisible,
        setIsPaymentFormVisible,
        isGeneratingOrder,
        confirmCancelPayment,
        setConfirmCancelPayment,

        searchHotelHandler,
        isLoading,
        isHotelBookingSuccess,
        setIsHotelBookingSuccess,
        showModalAvailabilityType,
        setShowModalAvailabilityType,
        isBookingInProgress,
        handleCheckRate,
        handleCreateHotelBooking,
        handleBookingForHotelBeds,
        cancelBooking
    };

}

