import { useEffect, useRef, useState } from "react";
import { GoVerified } from "react-icons/go";
import { useSelector } from "react-redux";
import { initialState } from "../../../redux/initialState";
import { IProfile, State } from "../../../redux/types";
import useAuth from "../../../utils/hooks/useAuth";
import DetailsInputBox from "./DetailsInputBox";
export default function BasicInfo() {
  const data = useSelector((state: State) => state.user.profile);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>("");
  const { updateUserProfile } = useAuth();
  const clickTrigger = useRef<HTMLButtonElement>(null);
  const [editProfileData, setEditProfileData] = useState({
    CustomerId: 0,
    Title: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Mobile: "",
    City: "",
    Pincode: "",
    Address1: "",
    Address2: "",
  });
  useEffect(() => {
    setEditProfileData({
      CustomerId: data.CustomerId,
      Title: data.Title || "",
      Email: data.Email || "",
      Phone: "9839399398",
      Mobile: data.Mobile || "",
      City: data.City || "",
      Pincode: data.Pincode || "",
      Address1: data.Address1 || "",
      Address2: data.Address2 || "",
      FirstName: data.FirstName || "",
      LastName: data.LastName || "",
    });
    setFullName(`${data.FirstName} ${data.LastName}`);
  }, []);

  const updateProfile = () => {
    updateUserProfile(editProfileData);
    setEditProfile(false);
  };
  return (
    <>
      <div className="basic-information p-4">
        <div className="heading d-flex justify-content-between mb-4">
          <div className="heading-text">Basic Information</div>
          {editProfile ? (
            <button
              className="btn px-2 py-0 btn-outline-primary shadow-none button-for-profile-page"
              onClick={() => clickTrigger?.current?.click()}
            >
              Save Changes
            </button>
          ) : (
            <button
              type="button"
              className="btn px-2 py-0 btn-outline-primary shadow-none button-for-profile-page"
              onClick={() => setEditProfile(true)}
            >
              Edit Profile
            </button>
          )}
        </div>
        <div className="regularly-update-alert rounded ps-3 p-2 mb-4">
          Please make sure your details are updated regularly.
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateProfile();
          }}
        >
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <DetailsInputBox
                value={editProfileData.FirstName}
                setEditProfileData={setEditProfileData}
                editProfile={editProfile}
                editProfileData={editProfileData}
                nameOfField="FirstName"
                label="First Name"
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <DetailsInputBox
                value={editProfileData?.LastName}
                setEditProfileData={setEditProfileData}
                editProfile={editProfile}
                editProfileData={editProfileData}
                nameOfField="LastName"
                label="Last Name"
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <DetailsInputBox
                value={editProfileData?.Mobile}
                setEditProfileData={setEditProfileData}
                editProfile={editProfile}
                editProfileData={editProfileData}
                nameOfField="Mobile"
                label="Mobile"
                showIcon={true}
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <DetailsInputBox
                value={editProfileData?.Email}
                setEditProfileData={setEditProfileData}
                editProfile={editProfile}
                editProfileData={editProfileData}
                nameOfField="Email"
                label="Email"
              />
            </div>

            <div className="col-12">
              <DetailsInputBox
                value={editProfileData?.Address1}
                setEditProfileData={setEditProfileData}
                editProfile={editProfile}
                editProfileData={editProfileData}
                nameOfField="Address1"
                label="Address"
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <DetailsInputBox
                value={editProfileData?.City}
                setEditProfileData={setEditProfileData}
                editProfile={editProfile}
                editProfileData={editProfileData}
                nameOfField="City"
                label="City"
              />
            </div>

            <div className="col-md-6 col-sm-12">
              <DetailsInputBox
                value={editProfileData?.Pincode}
                setEditProfileData={setEditProfileData}
                editProfile={editProfile}
                editProfileData={editProfileData}
                nameOfField="Pincode"
                label="Pincode"
              />
            </div>
          </div>

          <button type="submit" ref={clickTrigger} hidden={true}></button>
        </form>
      </div>

      <div className="basic-information mt-4 p-3">
        <div className="heading d-flex justify-content-between mb-4">
          <div className="heading-text">Account Details</div>
        </div>

        <div className="row">
        <div className="col-md-6 col-sm-12">
        <DetailsInputBox
                value={editProfileData?.Mobile}
                setEditProfileData={setEditProfileData}
                editProfile={false}
                editProfileData={editProfileData}
                nameOfField="Mobile"
                label="Mobile"
              />
        </div>
        <div className="col-md-6 col-sm-12 d-flex align-items-center">
          <button
            disabled={true}
            type="button"
            className="btn px-2 mt-auto py-0 btn-outline-primary shadow-none button-for-profile-page"
          >
            Change Password
          </button>
        </div>
        </div>
      </div>
    </>
  );
}
