import React, { useEffect, useMemo } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { IPageInfo } from '../../../redux/types';

import { TypeHotelServices, TypePagination } from '../../../utils/types/hotelTypes';
import { createPaginatedArray } from '../../../utils/utilityFunctions/utilityFunction';

import "../hotel.scss";

interface Props {
    TPaginationInfo: IPageInfo;
    HBPaginationInfo: IPageInfo;
    searchHotel: (page: number) => void;
    updatePagination: (key: TypePagination, value: number, service: TypeHotelServices) => void;
    getTBOHotelList: (page: number) => void;
};

export default function Pagination({
    TPaginationInfo,
    HBPaginationInfo,
    searchHotel,
    updatePagination,
    getTBOHotelList
}: Props) {

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(1);


    useEffect(() => {

        setCurrentPage(
            HBPaginationInfo.totalPages > TPaginationInfo.totalPages
                ?
                HBPaginationInfo.pageNumber
                :
                TPaginationInfo.pageNumber
        );
        setTotalPages(
            HBPaginationInfo.totalPages > TPaginationInfo.totalPages
                ?
                HBPaginationInfo.totalPages
                :
                TPaginationInfo.totalPages
        );

    }, [TPaginationInfo, HBPaginationInfo]);


    return (
        <React.Fragment>
            <nav className="my-4" aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    {totalPages > 1 &&
                        <React.Fragment>
                            {currentPage !== 1 && <li
                                className={`item ${currentPage === 1 && "disabled"}`}
                                onClick={() => {
                                    updatePagination("pageNumber", currentPage - 1, "HOTELBEDS");
                                    getTBOHotelList(currentPage - 1);
                                    HBPaginationInfo.pageNumber <= HBPaginationInfo.totalPages && searchHotel(currentPage - 1);
                                }}
                            >
                                {/* <span>Previous</span> */}
                                <IoIosArrowBack />
                            </li>}

                            {/* 
                            for last page
                            visible when first page is selected 
                            */}
                            {/* {currentPage === totalPages && <li className="item">
                                <span
                                    onClick={() => {
                                        updatePagination("pageNumber", 1, "HOTELBEDS");
                                        getTBOHotelList(1);
                                        HBPaginationInfo.pageNumber <= HBPaginationInfo.totalPages && searchHotel(1);
                                    }}
                                >Go to start</span>
                            </li>} */}

                            {
                                createPaginatedArray(currentPage - 1, totalPages, 2)?.map((page, index) => (
                                    <li
                                        key={index}
                                        className={`item ${currentPage === page && "selected"}`}
                                        onClick={() => {
                                            updatePagination("pageNumber", page, "HOTELBEDS");
                                            getTBOHotelList(page);
                                            page <= HBPaginationInfo.totalPages && searchHotel(page);
                                        }}
                                    >
                                        <span>{page}</span>
                                    </li>
                                ))
                            }

                            {/* 
                            for first page
                            visible when last page is selected
                            */}
                            {/* {currentPage === 1 && <li className="item">
                                <span
                                    onClick={() => {
                                        updatePagination("pageNumber", totalPages, "HOTELBEDS");
                                        getTBOHotelList(totalPages);
                                        HBPaginationInfo.pageNumber <= HBPaginationInfo.totalPages && searchHotel(totalPages);
                                    }}
                                >
                                    Go to end
                                </span>
                            </li>} */}

                            {currentPage !== totalPages 
                            ? 
                            <li
                                className={`item ${currentPage === totalPages && "disabled"}`}
                                onClick={() => {
                                    updatePagination("pageNumber", currentPage + 1, "HOTELBEDS");
                                    getTBOHotelList(currentPage + 1);
                                    HBPaginationInfo.pageNumber <= HBPaginationInfo.totalPages && searchHotel(currentPage + 1);
                                }}
                            >
                                {/* <span>Next</span> */}
                                <IoIosArrowForward />
                            </li>
                            :
                            <></>}

                            <div className="ms-5 small text-muted">Total pages: {totalPages}</div>
                        </React.Fragment>
                    }
                </ul>
            </nav>
        </React.Fragment>
    )
}