import axios from "axios";
const useFetch = (endPoint: string) => {
  const get = async (
    cb: ({ data, error }: { data: any; error: any }) => void
  ) => {
    // setLoading(true);
    const response = await axios.get(endPoint);
    const { ResponseData, IsSuccess, Message } = response?.data;
    if (IsSuccess) {
      cb({ data: ResponseData, error: null });
    } else {
      cb({ data: null, error: Message });
    }
  };

  // complete this
  const getWithoutCB = async () => {
    // setLoading(true);
    const response = await axios.get(endPoint);
    const { ResponseData, IsSuccess, Message } = response?.data;
    if (IsSuccess) {
      // cb({ data: ResponseData, error: null });
      return ResponseData;
    } else {
      // cb({ data: null, error: Message });
      return Message;
    }
  };
  const post = async (
    body: any,
    cb: ({ data, error }: { data: any; error: any }) => void
  ) => {
    // setLoading(true);
    const response = await axios.post(endPoint, body);
    const { ResponseData, IsSuccess, Message } = response?.data;
    if (IsSuccess) {
      cb({ data: ResponseData, error: null });
    } else {
      cb({ data: null, error: Message });
    }
  };

  return { get, post };
};

export default useFetch;
