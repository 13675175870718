import axios from 'axios';
import { useEffect } from 'react';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { State } from '../../../../redux/types';
import { SERVER_API_URL } from '../../../../utils/constants';
import { ITicketDetails } from '../../../../utils/types/flightTypes';

export default function useBookingDetails() {

  const params: { ticketID: string } = useParams();
  
  const history = useHistory();

  const clientId = useSelector((state: State) => state.commonState.Client?.id);

  // const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState<ITicketDetails | undefined>(undefined);
  const [netPayable, setNetPayable] = useState<{
    totalTravelerAmount: number;
    netAmount: number;
  }>({
    totalTravelerAmount: 0,
    netAmount: 0
  });

  useEffect(() => {

    getTicketDetails();

  }, []);

  // HANDLER FUNCTIONS
  const calculateTotalPaidAmount = (ticketDetails: ITicketDetails) => {

    let netPayable = 0;

    const totalTravelerAmount = ticketDetails?.lstTicketTravellerDetails?.reduce(
      (accumulator, currentObject) => {
        return accumulator + currentObject.TotalAmount;
      }, 0
    ) || 0;

    let totalCharges = 0;
    for (const eachCharge of ticketDetails?.lstTicketCharges) {

      if (eachCharge.ChargeName == "My Markup") continue;
      else totalCharges += eachCharge.Amount;

    };

    netPayable = totalTravelerAmount + totalCharges;
    setNetPayable({
      totalTravelerAmount: totalTravelerAmount,
      netAmount: netPayable
    });

  };

  const removeDuplicatesPax = (ticketDetails: ITicketDetails) => {
    const uniqueEntries: any = [];
    const keySet = new Set();

    ticketDetails?.lstTicketTravellerDetails.forEach((entry) => {
      const key = entry.AirlinePNR + entry.TravellerFirstName + entry.TravellerLastName || "";
      if (!keySet.has(key)) {
        uniqueEntries.push(entry);
        keySet.add(key);
      }
    });
    setTicketDetails({ ...ticketDetails, lstTicketTravellerDetails: uniqueEntries })
    return uniqueEntries;
  };


  // API CALLS
  const getTicketDetails = async () => {

    setIsLoading(true)
    let res = await axios.get(`${SERVER_API_URL}Customer/GetTicketDetails/${clientId}/${params?.ticketID}`).catch(
      (error: any) => {
        console.log("Error in fetching ticket details: ", error?.response);
        setIsLoading(false);

      }
    );
    if (res?.data?.IsSuccess) {
      const bookingDetails = res?.data?.ResponseData;
      setTicketDetails(bookingDetails);
      calculateTotalPaidAmount(bookingDetails);
      removeDuplicatesPax(bookingDetails)
    };

  };

  return {
    history,
    isLoading,
    netPayable,
    ticketDetails,
    isOpenModal,
    setIsOpenModal,
    isOpenInvoiceModal,
    setIsOpenInvoiceModal
  };

}
