import React, { FC, useRef } from "react";
import { motion } from "framer-motion";
import useClickOutSide from "../../utils/hooks/useClickOutSide";

interface Props {
  title: string;
  subTitle?: string;
  body: JSX.Element | string;
  footer?: JSX.Element | string;
  onClose?: () => void;
  scrollable?: boolean;
  size?: "sm" | "lg" | "xl" | "fullscreen";
  customFooter?: boolean; //for no padding full custom footer
  position?: "centered" | "top";
}

const BsModal: FC<Props> = ({
  title,
  subTitle,
  body,
  footer,
  onClose,
  scrollable,
  size,
  customFooter,
  position,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutSide(ref, () => {
    onClose && onClose();
  });
  return (
    <React.Fragment>
      {/* <div className="modal  d-block" aria-hidden="true"> */}
      <motion.div
        className="modal show d-block"
        // style={{ marginTop: "3em" }}
        tabIndex={-1}
        aria-labelledby="filtersModal"
        aria-hidden="true"
        initial={{ translateY: "-50%", scale: 0.5, opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        animate={{
          translateY: 0,
          scale: 1,
          opacity: 1,
        }}
      >
        <div
          className={`modal-dialog ${
            position === "top" ? "" : "modal-dialog-centered"
          } modal-${size || "md"} ${scrollable && "modal-dialog-scrollable"}`}
          ref={ref}
        >
          <div className="modal-content shadow-lg border-0 rounded ">
            <div className="modal-header border-0">
              <div className="d-flex flex-wrap">
                {title && (
                  <h3 className=" fw-semibold my-0 py-0 w-100">{title}</h3>
                )}
                {subTitle && <p className="text-muted small">{subTitle}</p>}
              </div>
              {onClose && (
                <button
                  type="button"
                  className="btn-close mb-auto mt-0"
                  aria-label="Close"
                  onClick={() => onClose()}
                ></button>
              )}
            </div>
            <div className="modal-body">{body}</div>
            <div className={`${!customFooter && "modal-footer"} border-0`}>
              {footer}
            </div>
          </div>
        </div>
      </motion.div>
      {/* </div> */}
      <div className="modal-backdrop fade show"></div>
    </React.Fragment>
  );
};

export default BsModal;
