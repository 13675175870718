import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RiArrowDownSLine } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";

import BasicInfo from "./Component/BasicInfo";
import BSInfoModal from "../../components/Modal/BsInfoModal";

import { State } from "../../redux/types";
import BookingHistory from "./BookingHistory";
import useAuth from "../../utils/hooks/useAuth";
import useBookingDetails from "../../utils/hooks/BookingDetails/useBookingDetails";

interface Props {}

const ProfilePage = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { firstName, lastName } = useSelector((state: State) => state.user);
  const { logout } = useAuth();
  const {
    getAllCustomerBookingDetails,
    getCustomerUpcomingBookingDetails,
    flightBookings,
    hotelBookings,
  } = useBookingDetails();

  const [myBookingOpen, setMyBookingOpen] = useState(false);
  const [showLogout, setShowLogout] = useState<boolean>(false);

  const activeTab = useMemo(() => {
    //0 means profile page & 1 means booking page(flight) & 2 booking
    const path = location.pathname;
    if (path.includes("/booking")) {
      setMyBookingOpen(true);
      if (path.includes("/flight")) {
        return 1;
      } else return 2;
    } else {
      return 0;
    }
  }, [location]);
  useEffect(() => {
    getAllCustomerBookingDetails();
    getCustomerUpcomingBookingDetails();
  }, [getAllCustomerBookingDetails, getCustomerUpcomingBookingDetails]);
  return (
    <div className="profile-page p-4">
      <div className="container ">
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <div className="profile-navigation mb-4">
              <div className="profile p-3 py-4">
                <div className="profile-text">
                  <div className="welcome">Welcome back,</div>
                  <div className="text-white name text-capitalize mt-1">
                    {firstName && firstName} {lastName && lastName}
                  </div>
                </div>
              </div>

              <div className="p-3">
                <div
                  className={`list-card  p-3 ${
                    activeTab === 0 ? "list-card-active " : ""
                  }`}
                  onClick={() => history.push("/profile")}
                >
                  Profile
                </div>
                <div
                  className="list-card p-3 d-flex justify-content-between cursor-pointer"
                  onClick={() => {
                    setMyBookingOpen(!myBookingOpen);
                  }}
                >
                  My Bookings
                  <RiArrowDownSLine />
                </div>

                {myBookingOpen && (
                  <span>
                    <div
                      className={`drop-down-item ps-4 p-3 ${
                        activeTab === 1 && "active"
                      }`}
                      onClick={() => {
                        history.push("/profile/booking/flight");
                      }}
                    >
                      Flights
                    </div>
                    <div
                      className={`drop-down-item ps-4 p-3 ${
                        activeTab === 2 && "active"
                      }`}
                      onClick={() => {
                        history.push("/profile/booking/hotel");
                      }}
                    >
                      Hotels
                    </div>
                  </span>
                )}
                {/* <div
                  className={`list-card  p-3 ${activeTab === 2 ? "list-card-active " : ""
                    }`}
                  onClick={() => history.push("/profile/travelers")}
                >
                  Travelers
                </div> */}
                <div
                  className="list-card p-3"
                  onClick={() => setShowLogout(true)}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
          {/* Booking detials section */}

          <div className="col-lg-9 col-md-12">
            {activeTab === 0 ? (
              <BasicInfo />
            ) : (
              <BookingHistory
                activeSection={activeTab === 1 ? "flight" : "hotel"}
                bookings={activeTab === 1 ? flightBookings : hotelBookings}
              />
            )}
          </div>
        </div>
      </div>

      {showLogout ? (
        <BSInfoModal
          title="Ready to Leave?"
          subTitle="you can sign in again later."
          confirmText="Yes"
          cancelText="No"
          onConfirm={() => {
            logout();
            setShowLogout(false);
          }}
          onClose={() => {
            setShowLogout(false);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProfilePage;
