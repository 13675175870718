import Illustration from '@kiwicom/orbit-components/lib/Illustration';

interface Props {
    title: string;
    illustration: any;
    size?: "small" | "medium" | "extraSmall" | "large" | "display" | undefined;
}

export default function Placeholder({ title, illustration, size }: Props) {
    return (
        <div className="h-50 d-flex flex-column justify-content-center align-items-center">
            <Illustration
                dataTest="test"
                name={illustration}
                size={size ? size : "medium"}
            />
            <div>
                <span className="fs-5 fw-bold text-muted mt-3">{title}</span>
            </div>
        </div>
    )
}
