import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FaClock } from 'react-icons/fa';
import { Badge } from '@kiwicom/orbit-components';

import { State } from '../../../../../redux/types';
import { TFlightResultList } from '../../../../../utils/types/flightTypes';

interface Props {
    selectedFlight: TFlightResultList; // IFlightSearchData;
};

export default function DetailedInfo1G({ selectedFlight }: Props) {

    const { searchInfo, metaData } = useSelector((state: State) => state.flight);

    return (
        <div className="detailed_info">
            {selectedFlight?.["1GSegmentData"]?.map((segment, index) => {

                let segmentDiff = moment(segment.arrival.scheduled_time).diff(segment.departure.scheduled_time, "minutes");
                let layOver = -1;
                // index < selectedFlight?.["1GSegmentData"]?.length - 1
                //     ?
                //     moment(selectedFlight.departureTime).diff(selectedFlight.arrivalTime, "minutes")
                //     : -1;
                // if city name is not match in DB airport then show the iata code itself coming from  spicejet
                let cityName = metaData?.FlightOrigins.find(
                    (airport) =>
                        airport.RouteDestinationCode === segment.departure.iata_code
                )?.City || segment.departure.iata_code;
                let arrivalCityName = metaData?.FlightDestinations.find(
                    (airport) =>
                        airport.RouteDestinationCode === segment.arrival.iata_code
                )?.City || segment.arrival.iata_code;

                return (
                    <React.Fragment key={index}>
                        <div className="items">
                            <div className="content">
                                <>
                                    <p className="mb-1 fw-semibold">
                                        {moment(segment.departure.scheduled_time).format("HH:mm")} - {cityName}
                                    </p>
                                    <p className="small text-muted d-flex align-items-center mb-0">
                                        {metaData?.FlightOrigins.find(
                                            (airport) =>
                                                airport.RouteDestinationCode === segment.departure.iata_code
                                        )?.RouteDestinationName?.slice(0, 35)}
                                        <div
                                            className="bg-dark mx-2"
                                            style={{ width: 4, height: 4, borderRadius: 4 }}
                                        ></div>
                                        {segment.departure.iata_code}
                                    </p>
                                </>

                                <div className=" d-flex gap-2 mt-3">
                                    <Badge>
                                        {Math.floor(segmentDiff / 60)}h{" "}
                                        {Math.floor(segmentDiff % 60)}m flight
                                    </Badge>
                                    <Badge>
                                        {segment.carrierCode} {segment.flightNumber}
                                    </Badge>
                                    {/* <Badge type="info">
                                        <span className="text-capitalize">
                                            {searchInfo?.class?.FlightClassName}
                                        </span>
                                    </Badge> */}
                                </div>
                            </div>
                        </div>
                        {/* Leg end */}
                        <div className="items">
                            <div className="content">
                                <>
                                    <p className="mb-1 fw-semibold">
                                        {moment(segment.arrival.scheduled_time).format("HH:mm")} - {arrivalCityName}
                                    </p>
                                    <p className="small text-muted d-flex align-items-center mb-0">
                                        {metaData?.FlightDestinations.find(
                                            (airport) =>
                                                airport.RouteDestinationCode === segment.arrival.iata_code
                                        )?.RouteDestinationName?.slice(0, 35)}
                                        <div
                                            className="bg-dark mx-2"
                                            style={{ width: 4, height: 4, borderRadius: 4 }}
                                        ></div>
                                        {segment.arrival.iata_code}
                                    </p>
                                </>

                                {/* <div className=" d-flex gap-2 mt-3">
                  <Badge>
                    {Math.floor(segmentDiff / 60)}h{" "}
                    {Math.floor(segmentDiff % 60)}m flight
                  </Badge>
                  <Badge>
                    {segment?.FlightDesignator?.CarrierCode}-
                    {segment?.FlightDesignator?.FlightNumber}
                  </Badge>
                  <Badge type="info">
                    <span className="text-capitalize">{searchInfo?.class}</span>
                  </Badge>
                </div> */}
                            </div>
                        </div>

                        {layOver !== -1 ? (
                            <div className="item_layover">
                                <div className="layover small">
                                    <FaClock />
                                    <div className="opacity-75">
                                        <strong>
                                            {Math.floor(layOver / 60)}h {Math.floor(layOver % 60)}m
                                        </strong>{" "}
                                        Stopover in {arrivalCityName}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="item_layover">
                                <div className="layover">
                                    <div className="opacity-75"></div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                );

            })}
        </div>
    );

};
