import React, { useRef } from 'react';
import moment from 'moment';
import Barcode from 'react-barcode';
import { FaPrint } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import { BsDownload } from 'react-icons/bs';
import { GoPrimitiveDot } from 'react-icons/go';
import { BiLeftArrow, BiRupee, BiUser } from 'react-icons/bi';
import { IoBagCheckSharp, IoCall, IoDownloadOutline, IoMail, IoTicketSharp } from 'react-icons/io5';

import "./BookingDetails.scss";

import TicketInvoice from '../../Component/TicketInvoice';
import BsModal from '../../../../components/Modal/BSModal';
import Image from '../../../../components/Common/Image/Image';
import Button from '../../../../components/Common/Button/Button';

import useBookingDetails from '../../Hooks/Flight/useBookingDetails';

// @ts-ignore
import html2pdf from 'html2pdf.js';

export default function FlightBookingDetails() {

    const {
        history,
        isLoading,
        netPayable,
        ticketDetails,
        isOpenModal,
        setIsOpenModal,
        isOpenInvoiceModal,
        setIsOpenInvoiceModal
    } = useBookingDetails();

    const invoiceRef: any = useRef(null);
    const componentRef: any = useRef(null);

    const handlePrint = () => {
        var element = componentRef.current;

        html2pdf()
            .set({ top: 2 })
            .from(element)
            .save(`e-Ticket_${ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || '--'}`);
    };

    const handlePrintInvoice = () => {
        var element = invoiceRef.current;

        html2pdf()
            .set(
                {
                    margin: 6
                    , top: 8,
                    bottom: 2
                })
            .from(element)
            .save(`Invoice${ticketDetails?.lstTicketBooking[0]?.InvoiceNumber || '--'}`);
    };



    return (
        <div className="booking-details p-4 fakeBody">
            <div className="container">

                <div className="d-flex gap-3 justify-content-end mb-3">
                    <Button
                        label={<div><BiLeftArrow />  Go Back</div>}
                        onClick={() => history.goBack()}
                    />
                </div>
                <div className='row gap-2'>
                    {/* LEFT PANEL */}
                    <div className='col'>
                        <div className='d-flex justify-content-between fw-bolder text-muted'>
                            <p className='m-0 p-1 pb-2'>
                                <span className='text-primary fw-bolder'>Booking Ref No : </span>
                                {ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || ""}
                            </p>
                            <p className='m-0 p-1 pb-2'>
                                <span className='text-primary fw-bolder'>Invoice No : </span>
                                {ticketDetails?.lstTicketBooking[0]?.InvoiceNumber || ""}
                            </p>
                            <p className='m-0 p-1 pb-2'>
                                <span className='text-primary fw-bolder'>Booking Date : </span>
                                {moment(ticketDetails?.lstTicketBooking[0]?.CreatedDate).format("DD-MM-YYYY  ( hh:mm a )")}
                            </p>
                        </div>

                        {/* TRIPS */}
                        {ticketDetails?.lstTicketPNRDetails?.map((eachTrip, index) => (
                            <div className="flight-detail p-3 mb-3" key={index + "trip"}>
                                {/* <h1> Amritsar <IoIosArrowRoundForward /> Kolkata  </h1> */}
                                <div className='row p-3 mt-4'>
                                    <div className='col col-lg-3'>
                                        <div className='row'>
                                            <div className='col col-lg-3 text-end'>
                                                <Image
                                                    width={38}
                                                    src={
                                                        eachTrip?.AirlineName == "Go Air"
                                                            ?
                                                            require("../../../../assets/images/goAir.jpg").default
                                                            :
                                                            eachTrip?.AirlineName == "Spicejet"
                                                                ?
                                                                require("../../../../assets/images/spicejet.png").default
                                                                :
                                                                require('../../../../assets/images/takeOff.png').default
                                                    } />
                                            </div>
                                            <div className='col text-start text-muted'>
                                                <p className='m-0 fs-8 fw-bolder'>
                                                    {eachTrip?.AirlineName}
                                                </p>
                                                <p className='fw-bolder fs-8'>
                                                    {eachTrip?.FlightNo}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col'>
                                        <p className='fs-5 m-0 fw-bolder'>
                                            {eachTrip?.Departure}
                                            {" - "}
                                            {eachTrip?.DepartureCode}
                                        </p>
                                        <p className='fs-4 fw-bolder'>
                                            {moment(eachTrip?.FromDate).format("MMM Do YYYY")}
                                        </p>
                                        {/* <p className='border-top fs-8 pt-2 text-secondary'>
                                            Sri Guru Ram Dass Jee International
                                            Airport <a href=''> View Location</a>
                                        </p> */}
                                    </div>
                                    <div className='col text-center align-self-center col-lg-2'>
                                        <Image
                                            width={30}
                                            src={require('../../../../assets/images/takeOff.png').default}
                                        />
                                        <div className='align-self-center row'>
                                            <GoPrimitiveDot size={20} className='col' />
                                            <p className='col'>................</p>
                                            <GoPrimitiveDot className='col' size={20} />
                                        </div>
                                        {/* <p className='text-secondary fs-8 fw-bold m-0'>2h 35m</p> */}
                                    </div>
                                    <div className='col'>
                                        <p className='fs-5 fw-bolder m-0'>
                                            {eachTrip?.Arrival}
                                            {" - "}
                                            {eachTrip?.ArrivalCode}
                                        </p>
                                        <p className='fs-4 fw-bolder'>
                                            {moment(eachTrip?.ToDate).format("MMM Do YYYY")}
                                        </p>
                                        {/* <p className='border-top pt-2 fs-8 text-secondary'>
                                            Netaji Subhash Chandra Bose
                                            International Airport <a href=''> View Location</a>
                                        </p> */}
                                    </div>
                                </div>
                                <div className='row mt-4 px-3'>
                                    <div className='col col-lg-3'>
                                        <p className='fs-6 fw-bold text-secondary'> <IoTicketSharp /> Student Fare</p>
                                    </div>
                                    <div className='col'>
                                        <p className='fs-6 fw-bolder text-muted'><IoTicketSharp /> SAVER - <span className='fs-6 fw-normal'>Cabin baggage</span> 7 Kgs , <span className='fs-6 fw-normal'> check-in baggage included <span className='fs-6 fw-bold text-primary' >+ 3 more</span></span> </p>
                                    </div>
                                </div>
                                <div className='row px-3'>
                                    <div className='col col-lg-3'>
                                        <p className='fs-6 font fw-bold text-secondary'> <IoTicketSharp /> Economy</p>
                                    </div>
                                    <div className='col'>
                                        <p className='fs-6 fw-bolder text-muted'><IoBagCheckSharp /> Checked in -25 Kgs (1 piece only) per adult</p>
                                    </div>
                                    <div className='col'>
                                        <p className='fs-6 fw-bolder text-muted'><IoBagCheckSharp /> Carry on -7Kgs (1 piece only) per adult</p>
                                    </div>
                                </div>

                                {/* TRAVELER DETAILS */}
                                {ticketDetails?.lstTicketTravellerDetails?.filter(
                                    eachTraveler => eachTraveler?.AirlinePNR == eachTrip?.AirlinePNR
                                )?.map((eachTraveler, index) => (
                                    <div className='traveller-details row text-muted' key={index + "traveler"}>
                                        <div className='col col-lg-1 align-self-center mb-4'>
                                            <BiUser size={30} />
                                        </div>
                                        <div className='col col-lg-4'>
                                            <p className='text-secondary fw-bolder m-0'>
                                                TRAVELLER
                                            </p>
                                            <p className=' m-0 text-muted fs-8'>
                                                <span className='fw-bolder fs-7 text-dark'>
                                                    {eachTraveler?.TravellerFirstName + " " + eachTraveler?.TravellerLastName}
                                                </span>
                                                {" "}
                                                <span>
                                                    {eachTraveler?.TravellerTypeName}
                                                </span>
                                                {" ,"}
                                                <span>
                                                    {eachTraveler?.GenderName || ""}
                                                </span>
                                                {eachTraveler?.Age
                                                    ?
                                                    <>
                                                        {" ,"}
                                                        <span>
                                                            {eachTraveler?.Age}
                                                        </span>
                                                    </>
                                                    :
                                                    <></>}
                                            </p>
                                            {/* <p className='text-secondary fs-8'>( ID : 12214087)</p> */}
                                        </div>
                                        <div className='col'>
                                            <p className='m-1 text-secondary fw-bolder'>
                                                PNR
                                            </p>
                                            <p className='fs-8 m-1 text-muted ' >
                                                {eachTraveler?.AirlinePNR}
                                            </p>
                                        </div>
                                        <div className='col col-lg-3'>
                                            <p className='  m-1 text-secondary fw-bolder'>
                                                E_TICKET NUMBER
                                            </p>
                                            <p className='fs-8 m-1 text-muted'>
                                                {eachTraveler?.AirlinePNR}
                                            </p>
                                        </div>
                                        {/* <div className='col col-lg-1 m-1'>
                                            <p className=' m-0 text-secondary fw-bolder'>
                                                SEAT
                                            </p>
                                            <p className='fs-8 text-muted' >
                                                {eachTraveler?.SeatNumber ?? " - "}
                                            </p>
                                        </div>
                                        <div className='col col-lg-1 m-1'>
                                            <p className=' m-0 text-secondary fw-bolder'>
                                                MEAL
                                            </p>
                                            <p className='fs-8 text-muted'>
                                                {eachTraveler?.Meal ?? " - "}
                                            </p>
                                        </div> */}
                                    </div>
                                ))}

                            </div>
                        ))}

                        {/* <div className='info-detail p-3 mt-5'>
                            <h2 className='title-text'>IMPORTANT INFORMATION</h2>
                            <p className='fs-6 fw-bold m-0'>
                                <GoPrimitiveDot size={18} /> AGE/Id/PROOF :
                            </p>
                            <p className='fs-8'>
                                Only student above 12 years of age eligible for special fares and/or additional baggage allowances. Carrying valid student ID cards
                                and student visas (where application) is mandatory . else the passenger may be denied boarding or asked to pay for extra baggage
                            </p>
                            <p className='fs-6 fw-bold m-0'>
                                <GoPrimitiveDot size={18} /> Checked travel guidelines and baggage information below :
                            </p>
                            <p>
                                Wearing mask/face cover is no longer mandatory. However, all travellers are advised to wear them, in view of the threat posed by
                                COVID-!(. For the complete list of travel guidelines issued Indian States and UT's , Carry no more 1 check-in-baggage and 1 hand
                                baggage per passenger. If violated ,airline may levy extra charges)
                            </p>
                        </div>
                        <div className='ticket-detail mt-3'>
                            <p className='fs-6 fw-bold m-0'>
                                CANCELLATION
                            </p>
                            <p className='fs-7 text-muted'>
                                Your flight has already departed, online cancellation is not allowed.
                            </p>
                        </div> */}

                        {/* PRIMARY TRAVELER DETAILS */}
                        <div className='ticket-detail mt-3'>
                            <p className='fs-6 fw-bold m-0'>
                                CONTACT INFORMATION
                            </p>
                            <p className='fs-7 text-muted'>
                                Information about primary traveller. Any communication by airlines will be sent to these details
                            </p>
                            <div className='row'>
                                <div className='col'>
                                    <p className='text-muted fs-7'>
                                        <BiUser className='mx-4' />
                                        <span>
                                            {ticketDetails?.lstTicketBooking[0]?.FirstName}
                                            {" "}
                                            {ticketDetails?.lstTicketBooking[0]?.LastName
                                                ?
                                                " " + ticketDetails?.lstTicketBooking[0]?.LastName
                                                :
                                                ""}
                                        </span>
                                    </p>

                                    <p className='text-muted fs-7'>
                                        <IoMail className='mx-4' />
                                        <span>
                                            {ticketDetails?.lstTicketBooking[0]?.CustomerEmail
                                                ?
                                                ticketDetails?.lstTicketBooking[0]?.CustomerEmail
                                                :
                                                " - "}
                                        </span>
                                    </p>
                                    <p className='text-muted fs-7'>
                                        <IoCall className='mx-4' />
                                        <span>
                                            {ticketDetails?.lstTicketBooking[0]?.CustomerMobile
                                                ?
                                                ticketDetails?.lstTicketBooking[0]?.CustomerMobile
                                                :
                                                " - "}
                                        </span>
                                    </p>
                                </div>
                                <div className='col '>
                                    <p></p>
                                    {/* <p className='text-end pointer text-primary'><BiRightTopArrowCircle className='mx-3' /> Send E-Ticket on Email and Mobile No.</p> */}
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* RIGHT PANEL */}
                    <div className='col col-lg-3 mt-4'>
                        {/* DOWNLOAD TICKET */}
                        <div className='ticket-detail'>
                            <p className='fs-5 fw-bolder m-0'>Ticket(s)</p>
                            <div
                                className='row pointer m-1 '
                                onClick={() => setIsOpenModal(true)}
                            >
                                <div className='col col-lg-1 text-primary'><IoDownloadOutline /></div>
                                <div className='col text-primary '><p className='m-0 fw-bolder'>Download E-ticket(s) </p></div>
                            </div>
                            {/* <div className='row  m-1 '>
                                <div className='col col-lg-1 text-muted'><IoShareSocialOutline /></div>
                                <div className='col text-muted '><p className='m-0 fw-bolder'>Email/SMS E-ticket(s) </p></div>
                            </div> */}
                            <div className='row pointer m-1 '
                                onClick={() => setIsOpenInvoiceModal(true)}>
                                <div className='col col-lg-1 text-primary'><IoDownloadOutline /></div>
                                <div className='col text-primary '><p className='m-0 fw-bolder'>Download Invoice(s) </p></div>
                            </div>
                        </div>

                        {/* <div className='ticket-detail mt-3'>
                            <div className='row'>
                                <p className='col fs-5 fw-bolder'>Airline Contact</p>
                                <IoCall size={23} className='col col-lg-2 text-end' />
                            </div>
                            <div className='row'>
                                <div className='col col-lg-2 text-end'>
                                    <Image width={30} src={require('../../../assets/images/takeOff.png').default} />
                                </div>
                                <div className='col text-start'>
                                    <p className='m-0'>IndiDo</p>
                                    <p className='fw-bolder fs-8 m-0'>0124-6173838 , 0124-4973838</p>
                                </div>

                            </div>
                        </div> */}

                        {/* PRICE BREAKUP */}
                        <div className='price-detail mt-3'>
                            <p className='fw-bolder fs-5'>Price Breakup</p>
                            <div className='row border-bottom py-1 mt-2'>
                                <p className='col label '>{"Ticket Charge"}</p>
                                <p className='col value col-lg-4'>
                                    <BiRupee />
                                    <span>{netPayable.totalTravelerAmount ?? 0}</span>
                                </p>
                            </div>
                            {
                                ticketDetails?.lstTicketCharges?.map((eachCharge, index) => (
                                    eachCharge?.ChargeName !== "My Markup"
                                        ?
                                        <div className='row border-bottom py-1 my-2' key={index + "charge"}>
                                            <p className='col label '>{eachCharge?.ChargeName}</p>
                                            <p className='col value col-lg-4'>
                                                <BiRupee />
                                                <span>{eachCharge?.Amount ?? 0}</span>
                                            </p>
                                        </div>
                                        :
                                        <></>
                                ))
                            }
                            <div className='row'>
                                <p className='col value'>Total Amount</p>
                                <p className='col value col-lg-4'>
                                    <BiRupee />
                                    <span>
                                        {netPayable.netAmount}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {isOpenModal
                ?
                <BsModal
                    onClose={() => setIsOpenModal(false)}
                    title={'E-Ticket'}
                    body={
                        <div>
                            <div
                                ref={componentRef}
                                className='download-ticket-modal'>
                                {/* <div className='ticketHeader'>
                            < p className='label'>e-Ticket</p>
                            < p className='label fs-6'>Booking Reference : <span className='fs-6'> {ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || ""} </span></p>
                        </div> */}
                                <div className='header-with-logo'>
                                    <div>
                                        <p className='par fs-4 fw-bolder'>Paul Merchants Ltd</p>
                                        <p className='par fs-6 fw-bolder'>Corp.Off.PML House</p>
                                        <p className='par fs-6 fw-bolder'>S.C.O. 829830, Sector 22-A</p>
                                        <p className='par fs-6 fw-bolder'>Chandigarh</p>
                                        <p className='par fs-6 fw-bolder'>Phone: 9317714203</p>
                                    </div>
                                    <Image
                                        className='logo'
                                        height={80}
                                        src={require('../../../../assets/images/logo.png').default} />
                                    <div>
                                        <p className='w-full text-end fw-bold fs-5'>e-Ticket</p>
                                        <p className='par label fs-6 fw-bolder'>
                                            Booking Reference :
                                            <span className='fs-6'> {ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || ""}
                                            </span></p>
                                        {/* <p className='par fs-6 fw-bolder'>PNR :Qp-E5IFGM</p> */}
                                        <p className='par fs-6 fw-bolder'>Issued Date : {ticketDetails?.lstTicketBooking[0]?.CreatedDate ? moment(ticketDetails?.lstTicketBooking[0]?.CreatedDate).format("ddd DD MMM YYYY") : ""}</p>
                                    </div>
                                </div>
                                <p className='dotted-border mt-4 fs-6 fw-bolder' >Traveler</p>
                                <div>
                                    <table className='table table-hover'>
                                        <thead className='table-header'>
                                            <tr>
                                                <th>Passenger Name</th>
                                                <th>Prn No.</th>
                                                <th>Type</th >
                                                <th>Seat</th>
                                                <th>Meal</th>
                                                <th>Baggage</th>
                                                {/* <th>Contact</th> */}
                                                {/* <th>Passport Number</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketDetails?.lstTicketTravellerDetails?.map(e =>
                                                <tr>
                                                    <td>{`${e?.TravelerTitle || ""} ${e?.TravellerFirstName || " - "} ${e?.TravellerLastName || ""}`}</td>
                                                    <td>{e?.AirlinePNR || "-"}</td>
                                                    <td>{e?.TravellerTypeName || "-"}</td>
                                                    <td>{e?.SeatNumber || "-"}</td>
                                                    <td>{e?.Meal || "-"}</td>
                                                    <td>{e?.Baggage || "-"}</td>
                                                    {/* <td>{'-'}</td>  */}
                                                    {/* <td></td>  */}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <p className='fw-bolder '>Note :</p>
                                    <p className='m-0'>
                                        This is an Electronic ticket. Please carry a positive identification for Check in.
                                    </p>
                                    <p className='m-0 '>
                                        Passenger reporting late for check-in may be denied. Please bring a valid passport or photo ID
                                    </p>
                                    <p className='m-0 '>
                                        Check-in counter will be closed 20 minutes before departure for domestic and 60 minutes before departure for international flights.
                                    </p>
                                </div>
                                <p className='dotted-border mt-4 fs-6 fw-bolder' >Travel Itinerary</p>
                                <div className='d-flex text-secondary fw-bolder justify-content-between'>
                                    <span>From :
                                        <span className='text-muted fw-normal'>
                                            {ticketDetails?.lstTicketPNRDetails[0]?.DepartureCode}</span>
                                    </span>
                                    <span>To :
                                        <span className='text-muted fw-normal'>
                                            {ticketDetails?.lstTicketPNRDetails[0]?.ArrivalCode}</span>
                                    </span>
                                    <span>No. of Stope :
                                        <span className='text-muted fw-normal'>
                                            {ticketDetails?.lstTicketPNRDetails.length ?
                                                ` ${ticketDetails?.lstTicketPNRDetails.length - 1 == 0 ?
                                                    ` non-Stop`
                                                    : ` ${ticketDetails?.lstTicketPNRDetails.length - 1}`}`
                                                : ` -`}
                                        </span>
                                    </span>
                                    {/* <span>Duration :    <span className='text-muted fw-normal'>-- hrs</span> </span> */}
                                    {/* <span>Baggage :     <span className='text-muted fw-normal'>-- P / Person</span> </span> */}
                                    <span></span>
                                </div>

                                <table className='table table-hover mt-2 table-bordered'>
                                    <thead className='table-header'>
                                        <tr>
                                            <th>Flight</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Departure</th>
                                            <th>Arrival</th>
                                            <th>Terminal</th>
                                            <th>Supplier</th>
                                            <th>Cabin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ticketDetails?.lstTicketPNRDetails?.map((e) =>
                                                <tr>
                                                    <td>{e?.FlightNo || "-"}</td>
                                                    <td>{e?.Departure || "-"}</td>
                                                    <td>{e?.Arrival || "-"}</td>
                                                    <td>{e?.FromDate ? moment(e?.FromDate).format("DD-MM-YYYY hh:mm a") : "-"}</td>
                                                    <td>{e?.ToDate ? moment(e?.ToDate).format("DD-MM-YYYY hh:mm a") : "-"}</td>
                                                    <td>{e?.Terminal || "-"}</td>
                                                    <td>{e?.TicketedSupplierName || "-"}</td>
                                                    <td>{e?.FlightClassName || "-"}</td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>

                                <p className='dotted-border mt-4 fs-6 fw-bolder' >Bar code Details</p>
                                <table className='table table-hover text-center mt-2 table-bordered'>
                                    <thead className='table-header' >
                                        <tr>
                                            <th>Passenger Name</th>
                                            <th>Flight Details</th>
                                            <th>Bar code</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ticketDetails?.lstTicketTravellerDetails?.map((e) =>
                                            <tr>
                                                <td>{`${e?.TravelerTitle || ""} ${e?.TravellerFirstName || " - "} ${e?.TravellerLastName || ""}`}</td>
                                                <td>
                                                    <p>{e?.DepartureCode || "-"} - {e?.ArrivalCode || "-"} </p>
                                                    <p>{ }</p>
                                                </td>
                                                <td>{
                                                    <div className='d-flex justify-content-center'>
                                                        <Barcode width={1} height={25} displayValue={false} value={`Your PRN No. is : ${e?.AirlinePNR || "--"}`} />
                                                    </div>
                                                }</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                            {/* footer */}
                            <div className='d-flex justify-content-end gap-3 m-0 px-4 fakeBody'>
                                <ReactToPrint
                                    documentTitle={`e-Ticket_${ticketDetails?.lstTicketBooking[0]?.BookingRefNumber || '--'}`}
                                    bodyClass={""}
                                    removeAfterPrint={true}
                                    pageStyle="scale:9.4"
                                    trigger={() => (
                                        <Button
                                            label={<div className='d-flex gap-2'>
                                                <FaPrint size={20} />
                                                Print e-Ticket
                                            </div>}
                                        />
                                    )}
                                    content={() => componentRef.current!}
                                />
                                <Button
                                    label={<div className='d-flex gap-2'>
                                        <BsDownload size={22} />
                                        <span>Download</span>
                                    </div>}
                                    onClick={() => handlePrint()}
                                />

                            </div>
                        </div>
                    }
                    size='fullscreen'
                />
                :
                <></>}

            {isOpenInvoiceModal
                ?
                <BsModal
                    onClose={() => setIsOpenInvoiceModal(false)}
                    title={'Your E-Ticket Invoice: Ready for Download'}
                    body={
                        <div className='invoice-body'>
                            <div
                                ref={invoiceRef}
                                className=''>
                                <TicketInvoice ticketDetails={ticketDetails} netPayable={netPayable} />
                            </div>
                            {/* footer */}
                            <div className='d-flex justify-content-end m-0 px-4 gap-3'>
                                <ReactToPrint
                                    documentTitle={`Invoice${ticketDetails?.lstTicketBooking[0]?.InvoiceNumber || '--'}`}
                                    bodyClass={""}
                                    removeAfterPrint={true}
                                    pageStyle="scale:9.4"
                                    trigger={() => (
                                        <Button
                                            label={<div className='d-flex gap-2'>
                                                <FaPrint size={20} />
                                                Print Invoice
                                            </div>}
                                        />
                                    )}
                                    content={() => invoiceRef.current!}
                                />
                                <Button
                                    label={<div className='d-flex gap-2'>
                                        <BsDownload size={22} />
                                        <span>Download</span>
                                    </div>}
                                    onClick={() => handlePrintInvoice()}
                                />

                            </div>
                            {/* <p className='dotted-border mt-4 fs-6 fw-bolder' >Bar code Details</p>
                        <table className='table table-hover mt-2 table-bordered'>
                            <thead className='table-header'>
                                <tr>
                                    <th>Passenger Name</th>
                                    <th>Flight Details</th>
                                    <th>Bar code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> -</td>
                                    <td> -</td>
                                    <td> -</td>
                                </tr>

                            </tbody>
                        </table> */}

                        </div>
                    }
                    size='fullscreen'
                />
                :
                <></>}
        </div>
    )
}
