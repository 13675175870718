import React, { CSSProperties, ImgHTMLAttributes, useEffect, useState } from "react";
import placeholder from "../../../assets/images/hotel-placeholder.png";
// import { FILE_URL } from "../../utils/constants";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    fallback?: string;
    isLocal?: boolean;
    style?: CSSProperties;
}

export default function Image({
    fallback = placeholder,
    isLocal = false,
    style,
    src,
    ...props
}: Props) {
    const [imgSrc, setImgSrc] = useState<string | undefined>(src);

    useEffect(() => {
        setImgSrc(src);
    }, [src]);

    return (
        <img
            src={imgSrc ? imgSrc : fallback}
            onError={() => setImgSrc(fallback)}
            style={style ? style : { objectFit: "cover" }}
            {...props}
            alt="Attachment"
        />
    );
}
