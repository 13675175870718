import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  ISSR,
  ISSRLegInfo,
  IFlightSegmentData,
  AirLineServices,
  TypeCarrierCode,
} from "../../types/flightTypes";
import {
  formatSSRList,
  formatToArr,
} from "../../utilityFunctions/flight/flightUtilityFunctions";
import { State } from "../../../redux/types";
import { flightSlice } from "../../../redux/reducers/flight";
import { showToast } from "../../../redux/reducers/commonState";
import { BookFlights } from "../../../Interfaces/BookFlights/BookFlights";
import { searchFlights } from "../../../Interfaces/SearchFlights/SearchFlights";
import { getUniqueValuedArray } from "./../../utilityFunctions/utilityFunction";

interface ISegmentExtended extends IFlightSegmentData {
  isFirstSegmentOfJourney: boolean;
  journeyIndex: number;
};

const useFlightBookingSSRs = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { searchInfo, Itinerary, selectedFlights, SelectedAddOns } = useSelector((state: State) => state.flight);

  const [isSSRSelling, setIsSSRSelling] = useState<boolean>(false);
  const [isSeatSelling, setIsSeatSelling] = useState<boolean>(false);
  const [allSegments, setAllSegments] = useState<ISegmentExtended[]>([]);

  const [SSRList, setSSRList] = useState<
    {
      AvailableSSR: ISSR[];
      LegInfo: ISSRLegInfo;
    }[]
  >([]);

  const handleSessionExpired = () => {
    dispatch(
      showToast({
        title: "Session Expired!",
        subTitle: "Redirecting to HomePage",
        type: "error",
        time: 3,
      })
    );
    history.push("/");
  };

  const getSSRList = async (
    segments: {
      CarrierCode: string;
      FlightNumber: string;
      DepartureDate: string;
      DepartureStation: string;
      ArrivalStation: string;
    }[],
    passengerIndexes: number[]
  ) => {
    const uniqueCarrierCodes = getUniqueValuedArray(
      segments.map((e) => e.CarrierCode)
    );
    try {
      const ssrAvPromise: Promise<AxiosResponse<any, any>>[] = [];
      uniqueCarrierCodes.forEach((carrierCode) => {
        ssrAvPromise.push(
          searchFlights?.[
            AirLineServices[carrierCode as TypeCarrierCode]
          ]?.getSSRAvailability(
            segments.filter((e) => e.CarrierCode === carrierCode),
            passengerIndexes
          )
        );
      });

      // if (segments.some((seg) => seg.CarrierCode === "SG")) {
      //   ssrAvPromise.push(
      //     searchFlights[0].getSSRAvailability(
      //       segments.filter((s) => s.CarrierCode === "SG"),
      //       passengerIndexes
      //     )
      //   );
      // }
      // if (segments.some((seg) => seg.CarrierCode === "G8")) {
      //   ssrAvPromise.push(
      //     searchFlights[1].getSSRAvailability(
      //       segments.filter((s) => s.CarrierCode === "G8"),
      //       passengerIndexes
      //     )
      //   );
      // }
      const response = await Promise.allSettled(ssrAvPromise);
      const data: any[] = [];
      response.forEach((res) => {
        if (res.status === "fulfilled") {
          const d = res.value.data?.data;
          data.push(...formatToArr(d?.SSRSegmentList?.SSRSegment));
        }
      });
      return data;
    } catch (error: any) {
      let errorCode =
        error?.response?.data?.message?.APIGeneralFault?.ErrorCode;
      if (Number(errorCode) === 1004) {
        handleSessionExpired();
      }
      // setIsSessionExpired(true);
      // alert("session Expired");
      return [];
    }
  };

  async function getSSR(
    segmentArr: IFlightSegmentData[],
    passengerIndexes: number[]
  ) {
    const response = await getSSRList(
      segmentArr.map((seg) => ({
        CarrierCode: "" + seg?.carrierCode,
        FlightNumber:
          (seg?.flightNumber !== null && seg?.flightNumber.length === 3
            ? seg?.carrierCode === "G8"
              ? " "
              : "0"
            : "") + seg?.flightNumber,
        DepartureDate: "" + seg?.departure.scheduled_time,
        DepartureStation: "" + seg?.departure.iata_code,
        ArrivalStation: "" + seg?.arrival.iata_code,
      })),
      passengerIndexes
    );
    let formattedSSRList = formatSSRList(response);
    // console.log("formattedSSRList: ", formattedSSRList);
    setSSRList(formattedSSRList);
  }

  useEffect(() => {

    // filter out Navitaire based journeys to prepare for itinerary payload
    const filteredNavitaireJourneys = selectedFlights?.filter(
      journey => journey.supplier === "navitaire"
    );
    if (filteredNavitaireJourneys.length === 0) {
      // console.log("No Navitaire flights selected!!!");
      return;
    };

    let segmentsArr = filteredNavitaireJourneys?.map(
      (journey, journeyIndex) => journey?.SegmentData?.map(
        (seg, index) => ({
          ...seg,
          //adding isFirst Segment bcoz SSRs like infant, baggage is only counted once per journey
          //and we must not add the price of these ssr in any of the following segments other than 1st segment
          isFirstSegmentOfJourney: index === 0,
          journeyIndex,
        }))
    ).flat();
    // let segmentsArr = Itinerary.Journeys.map((journey, journeyIndex) =>
    //   journey.Segments.map((seg, index) => ({
    //     ...seg,
    //     //adding isFirst Segment bcoz SSRs like infant, baggage is only counted once per journey
    //     //and we must not add the price of these ssr in any of the following segments other than 1st segment
    //     isFirstSegmentOfJourney: index === 0,
    //     journeyIndex,
    //   }))
    // ).flat();

    // console.log("segmentsArr: ", segmentsArr);
    setAllSegments(segmentsArr);
    let passengersIndexes = [
      ...Array(searchInfo?.adult + searchInfo?.child),
    ].map((_, i) => i);

    getSSR(segmentsArr, passengersIndexes);
    dispatch(
      flightSlice.actions.setSelectedAddons(
        segmentsArr?.map((seg, index) => ({
          isFirstSegmentOfJourney: seg?.isFirstSegmentOfJourney,
          journeyIndex: seg?.journeyIndex,
          SSR: passengersIndexes?.map((p) => []),
          Seats: passengersIndexes?.map((p) => []),
          LegInfo: {
            CarrierCode: "" + seg?.carrierCode,
            FlightNumber:
              (seg?.flightNumber !== null && seg?.flightNumber?.length === 3
                ? "0"
                : "") + seg?.flightNumber,
            DepartureDate: "" + seg?.departure?.scheduled_time,
            DepartureStation: "" + seg?.departure?.iata_code,
            ArrivalStation: "" + seg?.arrival?.iata_code,
          },
        }))
      )
    );
    // dispatch(
    //   flightSlice.actions.setSelectedSSRList2(
    //     {
    //       segments: segmentsArr.map((seg) => ({
    //         isFirstSegmentOfJourney: seg.isFirstSegmentOfJourney,
    //         journeyIndex: seg.journeyIndex,
    //         CarrierCode: "" + seg?.carrierCode,
    //         FlightNumber:
    //           (seg?.flightNumber !== null && seg?.flightNumber?.length === 3
    //             ? "0"
    //             : "") + seg?.flightNumber,
    //         DepartureDate: "" + seg?.departure.scheduled_time,
    //         DepartureStation: "" + seg?.departure.iata_code,
    //         ArrivalStation: "" + seg?.arrival.iata_code,
    //       })),
    //       passengers: [
    //         {
    //           "Extra baggage": [],
    //         },
    //       ],
    //       ssrs: [],
    //     }
    //     // segmentsArr?.map((seg, index) => ({
    //     //   isFirstSegmentOfJourney: seg.isFirstSegmentOfJourney,
    //     //   journeyIndex: seg.journeyIndex,
    //     //   passengers: passengersIndexes.map((p) => []),
    //     //   LegInfo: {
    //     //     CarrierCode: "" + seg?.carrierCode,
    //     //     FlightNumber:
    //     //       (seg?.flightNumber !== null && seg?.flightNumber?.length === 3
    //     //         ? "0"
    //     //         : "") + seg?.flightNumber,
    //     //     DepartureDate: "" + seg?.departure.scheduled_time,
    //     //     DepartureStation: "" + seg?.departure.iata_code,
    //     //     ArrivalStation: "" + seg?.arrival.iata_code,
    //     //   },
    //     // }))
    //   )
    // );
  }, [selectedFlights]);

  useEffect(() => {
    // adding infant ssr into selected ssrs array
    if (SSRList.length > 0 && searchInfo.infant > 0) {
      allSegments.forEach((seg, index) => {
        if (seg?.isFirstSegmentOfJourney) {
          let i = 0;
          while (i < searchInfo?.infant) {
            dispatch(
              flightSlice.actions.updateSelectedSSR([
                {
                  segIndex: index,
                  passengerIndex: i,
                  ssrData: SSRList?.[index]?.AvailableSSR.filter(
                    (avSSR) => avSSR?.SSRCode === "INFT"
                  )[0],
                },
              ])
            );
            i++;
          }
        }
      });
    }
  }, [SSRList, searchInfo.infant, allSegments, dispatch]);

  useEffect(() => {
    // adding default ssrs  which are based on fare selected ex: max fare has def ssr MAXP
    if (SSRList.length > 0 && Itinerary?.Journeys?.length > 0) {
      Itinerary.Journeys.forEach((journey) => {
        const selectedFareType = journey?.meta?.selectedFareType;
        if (selectedFareType === "MAX") {
          journey.Segments.forEach((seg) => { });
        }
      });
      allSegments.forEach((seg, index) => {
        // if (seg?.isFirstSegmentOfJourney) {
        const selectedFareType =
          Itinerary?.Journeys[seg.journeyIndex]?.meta?.selectedFareType;
        if (selectedFareType === "MAX") {
          let i = 0;
          const MaxpSSR = SSRList?.[index]?.AvailableSSR?.filter(
            (avSSR) => avSSR?.SSRCode === "MAXP"
          )?.[0];
          const freeVegMealSSR = SSRList?.[index]?.AvailableSSR?.filter(
            (avSSR) => avSSR?.SSRCode === "VGM1"
          )?.[0];

          while (i < searchInfo?.adult + searchInfo?.child) {
            dispatch(
              flightSlice.actions.updateSelectedSSR([
                {
                  segIndex: index,
                  passengerIndex: i,
                  ssrData: MaxpSSR,
                },
                {
                  segIndex: index,
                  passengerIndex: i,
                  ssrData: freeVegMealSSR,
                },
              ])
            );
            i++;
          }
        } else if (selectedFareType === "FLEXI") {
          let i = 0;
          const freeVegMealSSR = SSRList?.[index]?.AvailableSSR?.filter(
            (avSSR) => avSSR?.SSRCode === "VGSW"
          )?.[0];
          while (i < searchInfo?.adult + searchInfo?.child) {
            dispatch(
              flightSlice.actions.updateSelectedSSR([
                {
                  segIndex: index,
                  passengerIndex: i,
                  ssrData: freeVegMealSSR,
                },
              ])
            );
            i++;
          }
        }
        // }
      });
    }
  }, [SSRList, Itinerary, allSegments, dispatch]);

  const handleSellSSRAndSeats = async (
    sellPromiseArray: Promise<AxiosResponse<any, any>>[],
    uniqueCarrierCodes: string[]
  ) => {
    const response = await Promise.all(sellPromiseArray);
    if (response) {
      response.forEach((res, index) => {
        const newBookingSum = res?.data?.data?.PNRAmount;
        if (newBookingSum) {
          dispatch(
            flightSlice.actions.updateItineraryBookingSum({
              bookingSum: newBookingSum,
              carrierCode: uniqueCarrierCodes[index],
            })
          );
        }
      });
      return true;
    } else {
      return false;
    }
  };

  async function sendSellSSR() {
    try {
      setIsSSRSelling(true);
      if (SelectedAddOns) {
        let segmentsWithSelectedAddOns = SelectedAddOns?.filter(
          (seg) => seg.SSR.flat(1).length > 0
        );
        let uniqueCarrierCodes = getUniqueValuedArray(
          segmentsWithSelectedAddOns?.map((seg) => seg?.LegInfo?.CarrierCode)
        );
        const segmentSSR = segmentsWithSelectedAddOns?.map((seg) => ({
          CarrierCode: "" + seg?.LegInfo?.CarrierCode,
          FlightNumber:
            (seg?.LegInfo?.FlightNumber !== null &&
              seg?.LegInfo?.FlightNumber.length === 3
              ? "0"
              : "") + seg?.LegInfo?.FlightNumber,
          STD: "" + seg?.LegInfo?.DepartureDate,
          DepartureStation: "" + seg?.LegInfo?.DepartureStation,
          ArrivalStation: "" + seg?.LegInfo?.ArrivalStation,
          PaxSSR: seg?.SSR.map((passenger, idx) =>
            passenger.map((eachSSR) => ({
              ActionStatusCode: "NN", //NN
              PassengerNumber: `${idx}`,
              SSRCode: eachSSR?.SSRCode,
              SSRNumber: 0, //0
              SSRValue: 0,
            }))
          ).flat(),
        }));

        let sellSSRPromise = uniqueCarrierCodes.map((carrierCode) =>
          BookFlights[AirLineServices[carrierCode as TypeCarrierCode]]?.sellSSR(
            segmentSSR.filter((seg) => seg.CarrierCode === carrierCode)
          )
        );
        const isSSRSold = await handleSellSSRAndSeats(
          sellSSRPromise,
          uniqueCarrierCodes
        );
        // const isPaxSSRSelected = //check if particular passenger has selected some ssr
        //   segmentSSR.findIndex((s) => s.PaxSSR.length > 0) !== -1;
        // if (isPaxSSRSelected) {
        // const response = await BookFlights[0]?.sellSSR(segmentSSR);
        // const newBookingSum = response?.data?.data?.PNRAmount;
        // TODO: handle this to add the updated booking sum to specific
        // BookingSUm index now that it is an array containing booking sum of different airlines
        // if (newBookingSum) {
        //   dispatch(
        //     flightSlice.actions.updateItineraryBookingSum(newBookingSum)
        //   );
        // }
        // }
      }
      setIsSSRSelling(false);
    } catch (error: any) {
      // setIsSessionExpired(true);
      let errorCode =
        error?.response?.data?.message?.APIGeneralFault?.ErrorCode;
      if (Number(errorCode) === 1004) {
        handleSessionExpired();
      }
    }
  }

  const sendAssignSeat = async () => {
    try {
      setIsSeatSelling(true);
      if (SelectedAddOns) {
        // let segmentsWithSelectedAddOns = SelectedAddOns?.filter(
        //   (seg) => seg.SSR.flat(1).length > 0
        // );
        let segmentsWithSelectedSeat = SelectedAddOns?.filter(
          (seg) => seg.Seats.flat(1).length > 0
        );
        let uniqueCarrierCodes = getUniqueValuedArray(
          segmentsWithSelectedSeat?.map((seg) => seg?.LegInfo?.CarrierCode)
        );

        // console.log("segmentsWithSelectedAddOns: ", segmentsWithSelectedAddOns);
        // console.log("segmentsWithSelectedSeat: ", segmentsWithSelectedSeat);
        // console.log("uniqueCarrierCodes: ", uniqueCarrierCodes);

        const seatsData = segmentsWithSelectedSeat
          ?.map((seg) =>
            seg.Seats.map((seat, index) => ({
              CarrierCode: "" + seg?.LegInfo?.CarrierCode,
              FlightNumber:
                (seg?.LegInfo?.FlightNumber !== null &&
                  seg?.LegInfo?.FlightNumber.length === 3
                  ? "0"
                  : "") + seg?.LegInfo?.FlightNumber,
              STD: "" + seg?.LegInfo?.DepartureDate,
              DepartureStation: "" + seg?.LegInfo?.DepartureStation,
              ArrivalStation: "" + seg?.LegInfo?.ArrivalStation,
              PassengerID: String(index),
              UnitDesignator: seat[0].SeatDesignator,
            }))
          ).flat(1);

        if (seatsData.length > 0) {
          let assignSeatPromise = uniqueCarrierCodes.map((carrierCode) =>
            BookFlights[
              AirLineServices[carrierCode as TypeCarrierCode]
            ]?.assignSeat(
              seatsData.filter((seg) => seg.CarrierCode === carrierCode)
            )
          );
          console.log("assignSeatPromise: ", assignSeatPromise);
          console.log("uniqueCarrierCodes: ", uniqueCarrierCodes);
          const isSeatAssigned = await handleSellSSRAndSeats(
            assignSeatPromise,
            uniqueCarrierCodes
          );
          // const response = await BookFlights[0]?.assignSeat(seatsData);
          // const newBookingSum = response?.data?.data?.PNRAmount;
          // if (newBookingSum) {
          //   dispatch(
          //     flightSlice.actions.updateItineraryBookingSum(newBookingSum)
          //   );
          // }
        }
      }
      setIsSeatSelling(false);
    } catch (error: any) {
      console.log("Error while assigning seat: ", error);
      // setIsSessionExpired(true);
      let errorCode =
        error?.response?.data?.message?.APIGeneralFault?.ErrorCode;
      if (Number(errorCode) === 1004) {
        handleSessionExpired();
      }
    }
  };

  return {
    allSegments,
    SSRList,
    sendSellSSR,
    sendAssignSeat,
    isSSRSelling,
    isSeatSelling,
  };
};
export default useFlightBookingSSRs;
