import React from "react";
import { FaClock } from "react-icons/fa";
import { useSelector } from "react-redux";
import moment from "moment";
import Badge from "@kiwicom/orbit-components/lib/Badge";

import "../tripSummary.scss";

import { State } from "../../../../../redux/types";
import { IFlightSearchData, TFlightResultList } from "../../../../../utils/types/flightTypes";

interface Props {
    selectedFlight: TFlightResultList; // IFlightSearchData;
};

const DetailedInfoAirIQ = ({ selectedFlight }: Props) => {

    const { searchInfo, metaData } = useSelector((state: State) => state.flight);

    return (
        <div className="detailed_info">
            {selectedFlight?.SegmentData?.map((_, index) => {

                let segmentDiff = moment(selectedFlight.departureTime).diff(selectedFlight.arrivalTime, "minutes");
                let layOver =
                    index < selectedFlight?.SegmentData?.length - 1
                        ?
                        moment(selectedFlight.departureTime).diff(selectedFlight.arrivalTime, "minutes")
                        : -1;
                // if city name is not match in DB airport then show the iata code itself coming from  spicejet
                let cityName = metaData?.FlightOrigins.find(
                    (airport) =>
                        airport.RouteDestinationCode === selectedFlight.airports[0]
                )?.City || selectedFlight.airports[0];
                let arrivalCityName = metaData?.FlightDestinations.find(
                    (airport) =>
                        airport.RouteDestinationCode === selectedFlight.airports[1]
                )?.City || selectedFlight.airports[1];

                return (
                    <React.Fragment key={index}>
                        <div className="items">
                            <div className="content">
                                <>
                                    <p className="mb-1 fw-semibold">
                                        {moment(selectedFlight.departureTime).format("HH:mm")} - {cityName}
                                    </p>
                                    <p className="small text-muted d-flex align-items-center mb-0">
                                        {metaData?.FlightOrigins.find(
                                            (airport) =>
                                                airport.RouteDestinationCode === selectedFlight.airports[0]
                                        )?.RouteDestinationName?.slice(0, 35)}
                                        <div
                                            className="bg-dark mx-2"
                                            style={{ width: 4, height: 4, borderRadius: 4 }}
                                        ></div>
                                        {selectedFlight.airports[0]}
                                    </p>
                                </>

                                <div className=" d-flex gap-2 mt-3">
                                    <Badge>
                                        {Math.floor(segmentDiff / 60)}h{" "}
                                        {Math.floor(segmentDiff % 60)}m flight
                                    </Badge>
                                    <Badge>
                                        {selectedFlight.flightCode}
                                    </Badge>
                                    {/* <Badge type="info">
                                        <span className="text-capitalize">
                                            {searchInfo?.class?.FlightClassName}
                                        </span>
                                    </Badge> */}
                                </div>
                            </div>
                        </div>
                        {/* Leg end */}
                        <div className="items">
                            <div className="content">
                                <>
                                    <p className="mb-1 fw-semibold">
                                        {moment(selectedFlight.arrivalTime).format("HH:mm")} - {arrivalCityName}
                                    </p>
                                    <p className="small text-muted d-flex align-items-center mb-0">
                                        {metaData?.FlightDestinations.find(
                                            (airport) =>
                                                airport.RouteDestinationCode === selectedFlight.airports[1]
                                        )?.RouteDestinationName?.slice(0, 35)}
                                        <div
                                            className="bg-dark mx-2"
                                            style={{ width: 4, height: 4, borderRadius: 4 }}
                                        ></div>
                                        {selectedFlight.airports[1]}
                                    </p>
                                </>

                                {/* <div className=" d-flex gap-2 mt-3">
                  <Badge>
                    {Math.floor(segmentDiff / 60)}h{" "}
                    {Math.floor(segmentDiff % 60)}m flight
                  </Badge>
                  <Badge>
                    {segment?.FlightDesignator?.CarrierCode}-
                    {segment?.FlightDesignator?.FlightNumber}
                  </Badge>
                  <Badge type="info">
                    <span className="text-capitalize">{searchInfo?.class}</span>
                  </Badge>
                </div> */}
                            </div>
                        </div>

                        {layOver !== -1 ? (
                            <div className="item_layover">
                                <div className="layover small">
                                    <FaClock />
                                    <div className="opacity-75">
                                        <strong>
                                            {Math.floor(layOver / 60)}h {Math.floor(layOver % 60)}m
                                        </strong>{" "}
                                        Stopover in {arrivalCityName}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </React.Fragment>
                );

            })}
        </div>
    );

};

export default DetailedInfoAirIQ;

