import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Faq from "./components/FAQ/Faq";
import Offers from "./components/DealsOffers/Offers";
import SearchTab from "./components/SearchTab/SearchTab";

import "./App.scss";

import { State } from "./redux/types";
import { userSlice } from "./redux/reducers/user";
import useClientData from "./utils/hooks/useClientData";
import useFlightMetaData from "./utils/hooks/Flight/useFlightMetaData";
import useHotelMetaData from "./utils/hooks/Hotel/useHotelMetaData";
import { createUniqueId } from "./utils/utilityFunctions/utilityFunction";
import { FaPlaneDeparture } from "react-icons/fa";
import Button from "./components/Common/Button/Button";

function App() {
  // const { config } = useConfig();
  const dispatch = useDispatch();

  const { location } = useSelector((state: State) => state.user);
  const { isFlightDataFetched, isHotelDataFetched, activeTab, Client } =
    useSelector((state: State) => state.commonState);
  const { loading: loadingClientData } = useClientData();

  const { refreshFlightMetaData } = useFlightMetaData();
  const { refreshHotelMetaData } = useHotelMetaData();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (
        location?.latitude !== position.coords.latitude ||
        location?.longitude !== position.coords.longitude
      ) {
        dispatch(
          userSlice.actions.updateLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
      }
    });
  }, [location, dispatch]);
  useEffect(() => {
    // setting unique id for session management with flight booking apis
    const sessionId = localStorage.getItem("sessionId") || createUniqueId();
    localStorage.setItem("sessionId", sessionId);

    // window.oncontextmenu = function () {
    //   return false;
    // };
    // const handleKeyDown = function (event: KeyboardEvent) {
    //   if (
    //     event.key == "F12" ||
    //     (event.ctrlKey &&
    //       event.shiftKey &&
    //       (event.key === "I" || event.key === "J"))
    //   ) {
    //     event.preventDefault();
    //     return false;
    //   }
    // };
    // document.addEventListener("keydown", handleKeyDown, false);
    // return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);
  useEffect(() => {
    if (activeTab?.ServiceId === 1) {
      refreshFlightMetaData();
    } else if (activeTab?.ServiceId === 2) {
      refreshHotelMetaData();
    }

      if (Client!==null && Client?.id === 2) {
        require("./App2.scss");
      }
  }, [Client, activeTab]);

  return (
    <React.Fragment>
      <SearchTab />
      {(activeTab?.ServiceId === 1 && !isFlightDataFetched) ||
      (activeTab?.ServiceId === 2 && !isHotelDataFetched) ? (
        <>
          {" "}
          <div className="container">
            <div className="spacer--lg"></div>
            <Offers />
          </div>
          <div className="spacer--lg"></div>
          <div className="container">
            <Faq />
          </div>
          <div className="spacer spacer--lg"></div>
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default App;
