import React, { PropsWithChildren } from "react";
interface Props {
  type: string;
  destinationCity: string;
  destinationCountry: string;
  offer: string;
  imgSrc: string;
}

const CardDestinationDeals = (props: PropsWithChildren<Props>) => {
  return (
    <div className="card card--deals h-100 ">
      <img src={props.imgSrc} alt="" className="card-img" />
      <div className="card-img-overlay">
        <div className="type">{props.type}</div>
        <div className="content">
          <div className="destination-country">{props.destinationCountry}</div>
          <div className="destination-city">{props.destinationCity}</div>
          <div className="offer">{props.offer}</div>
        </div>
      </div>
    </div>
  );
};

export default CardDestinationDeals;
