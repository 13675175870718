import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Badge } from '@kiwicom/orbit-components';
import { FlightDirect } from '@kiwicom/orbit-components/lib/icons';

import DetailedInfo from './DetailedInfo';

import { State } from '../../../../../redux/types';
import { IFlightSearchData, TFlightResultList } from '../../../../../utils/types/flightTypes';
import DetailedInfoAirIQ from './DetailedInfoAirIQ';

interface Props {
    selectedFlight: TFlightResultList; // IFlightSearchData;
    label?: string;
    isDetailedShown: boolean;
    departureDate: string;
};

export default function SingleTripAirIQ({
    selectedFlight,
    label,
    isDetailedShown,
    departureDate,
}: Props) {

    const { searchInfo, metaData } = useSelector((state: State) => state.flight);

    return (
        <React.Fragment>
            <p className="text-muted small mb-1 text-uppercase">{label}</p>
            <h4>
                {selectedFlight?.airports?.map((seg: any, index: number) => (
                    <span key={index}>
                        {" "}
                        {
                            metaData?.FlightOrigins.find(
                                (airport) => airport.RouteDestinationCode === seg
                            )?.City
                        }
                        {index < (selectedFlight?.airports && selectedFlight?.airports?.length - 1)
                            ?
                            <FlightDirect />
                            :
                            <></>
                        }
                    </span>
                ))}
            </h4>
            <div className="d-flex align-items-center gap-1">
                <img
                    src={
                        require(`../../../../../assets/${selectedFlight?.carrierCode === "SG"
                            ?
                            "spicejet"
                            :
                            selectedFlight?.carrierCode === "6E"
                                ?
                                "indigo"
                                :
                                selectedFlight?.carrierCode === "QP"
                                    ?
                                    "akasaair"
                                    :
                                    "goair"
                            }.png`).default
                    }
                    className="img rounded"
                    height={20}
                    width={20}
                    alt="airline"
                />
                <div className="d-flex align-items-center"></div>
                {
                    selectedFlight?.carrierCode === "SG"
                        ?
                        "Spicejet"
                        :
                        selectedFlight?.carrierCode === "6E"
                            ?
                            "IndiGo"
                            :
                            selectedFlight?.carrierCode === "QP"
                                ?
                                "Akasa Air"
                                :
                                "GoAir"
                }
                <div
                    className="bg-dark mx-2"
                    style={{ width: 4, height: 4, borderRadius: 4 }}
                ></div>
                {moment(departureDate).format("DD, MMM YYYY")}
            </div>
            {isDetailedShown ? (
                <DetailedInfoAirIQ selectedFlight={selectedFlight} />
            ) : (
                <>
                    {/* Time */}
                    <div className="pt-4">
                        <p className="mb-1 fw-semibold">
                            {moment(selectedFlight?.departureTime).format("HH:mm")} -{" "}
                            {moment(selectedFlight?.arrivalTime).format("HH:mm")}
                        </p>
                        <p className="small text-muted d-flex align-items-center">
                            {selectedFlight?.duration}
                            <span
                                className="bg-dark mx-2"
                                style={{ width: 4, height: 4, borderRadius: 4 }}
                            ></span>
                            {selectedFlight?.airports &&
                                selectedFlight?.airports?.length - 2 > 0
                                ? `${selectedFlight?.airports?.length - 2} Stop`
                                : "Non Stop"}{" "}
                            {/* {1 Stop in Ahmedabad (1h 50m)} */}
                        </p>
                    </div>
                    <div className=" d-flex justify-content-start">
                        <div className="me-1">
                            <Badge type="white">{selectedFlight?.flightCode}</Badge>
                        </div>

                        <Badge type="info">
                            <span className="text-capitalize">
                                {searchInfo?.class?.FlightClassName}
                            </span>
                        </Badge>
                    </div>
                </>
            )}
        </React.Fragment>
    );

};
