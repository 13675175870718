import { IService, IToast } from "./../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "../initialState";

export const commonStateSlice = createSlice({
  name: "commonState",
  initialState: initialState.commonState,
  reducers: {
    toggle: (
      state,
      {
        payload,
      }: PayloadAction<
        "showSignInModal" | "isFlightDataFetched" | "isHotelDataFetched"
      >
    ) => {
      state[payload] = !state[payload];
    },
    updateState: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: "showSignInModal" | "isFlightDataFetched" | "isHotelDataFetched";
        value: boolean;
      }>
    ) => {
      state[payload.key] = payload.value;
    },
    setActiveTab: (state, { payload }: PayloadAction<IService>) => {
      state.activeTab = payload;
    },

    showToast: (state, { payload }: PayloadAction<IToast>) => {
      state.toast = [...state.toast, payload];
    },
    hideToast: (state, { payload }: PayloadAction<number>) => {
      state.toast.splice(payload, 1);
    },
    setClient: (
      state,
      { payload }: PayloadAction<{ id: number; name: string }>
    ) => {
      state.Client = payload;
    },
    setClientData: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: "OtherCharges" | "MyCharges" | "GSTRates" | "Services";
        value: any[];
      }>
    ) => {
      state.ClientData[payload.key] = payload.value;
    },
    setCouponBoxStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        IsEnabledFlight: boolean;
        IsEnabledHotel: boolean;
        IsEnabledBus: boolean;
      }>
    ) => {
      state.CouponBox = payload;
    },
  },
});

export const {
  toggle,
  updateState,
  showToast,
  hideToast,
  setClient,
  setClientData,
  setCouponBoxStatus,
} = commonStateSlice.actions;

const commonStateReducer = commonStateSlice.reducer;
export default commonStateReducer;
