import { sellFlightTicket } from "./../../utils/apis/CommonFlightAPIs";
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../../utils/constants";
import { ISellSSR, IUpdatePassenger } from "../../utils/types/flightTypes";

export interface IBookFlight {
  postSell: (
    keys: {
      JourneySellKey: string;
      FareSellKey: string;
    }[],
    childCount: number,
    adultCount: number
  ) => Promise<AxiosResponse<any>>;
  updatePassenger: (
    passenger: IUpdatePassenger
    // userId: string
  ) => Promise<AxiosResponse<any>>;
  sellSSR: (
    // userId: string,
    Segments: {
      FlightNumber: string;
      CarrierCode: string;
      STD: string;
      DepartureStation: string;
      ArrivalStation: string;
      PaxSSR: {
        PassengerNumber: string;
        SSRCode: string;
        SSRValue: number;
      }[];
    }[]
  ) => Promise<AxiosResponse<any>>;
  addPaymentToBooking: (
    amount: string
    // userId: string
  ) => Promise<AxiosResponse<any>>;
  commitBooking: (
    passenger: {
      FirstName: string;
      LastName: string;
      Title: string;
    }[],
    email: string,
    mobile: string,
    passengerCount: number,
    // userId: string,
    recordLocator?: string
  ) => Promise<AxiosResponse<any>>;
  assignSeat: (
    // userId: string,
    selectedSeatList: {
      FlightNumber: string;
      CarrierCode: string;
      STD: string;
      DepartureStation: string;
      ArrivalStation: string;
      PassengerID: string;
      UnitDesignator: string;
    }[]
  ) => Promise<AxiosResponse<any>>;
}

class FlightBooking implements IBookFlight {
  private baseUrl: string;
  private carrierCode: string;
  constructor(carrierCode: string) {
    this.carrierCode = carrierCode.toUpperCase();
    this.baseUrl = `${API_URL}${carrierCode}/`;
  }
  async postSell(
    keys: {
      JourneySellKey: string;
      FareSellKey: string;
    }[],
    adultCount: number,
    childCount: number
  ): Promise<AxiosResponse<any>> {
    return sellFlightTicket(
      keys,
      adultCount,
      childCount,
      `${this.baseUrl}booking/sell`
    );
  }
  async updatePassenger(
    passenger: IUpdatePassenger
    // userId: string
  ): Promise<AxiosResponse<any>> {
    return axios.post(`${this.baseUrl}booking/updatePassengers`, {
      Passenger_Details: passenger,
      userId: localStorage.getItem("sessionId"),
    });
  }
  async sellSSR(
    // userId: string,
    Segments: {
      FlightNumber: string;
      CarrierCode: string;
      STD: string;
      DepartureStation: string;
      ArrivalStation: string;
      PaxSSR: {
        PassengerNumber: string;
        SSRCode: string;
        SSRValue: number;
      }[];
    }[]
  ): Promise<AxiosResponse<any>> {
    const userId = localStorage.getItem("sessionId");
    if (userId !== null) {
      const body: ISellSSR = {
        userId,
        SellBy: "SSR",
        CurrencyCode: "INR",
        SegmentSSR: Segments.map((seg) => ({
          CarrierCode: this.carrierCode,
          FlightNumber: seg.FlightNumber,
          STD: seg.STD,
          DepartureStation: seg.DepartureStation,
          ArrivalStation: seg.ArrivalStation,
          PaxSSR: seg.PaxSSR.map((ssr) => ({
            ActionStatusCode: "NN",
            PassengerNumber: ssr.PassengerNumber,
            SSRCode: ssr.SSRCode,
            SSRValue: ssr.SSRValue,
            SSRNumber: 0,
          })),
        })),
      };
      return axios.post(`${this.baseUrl}booking/sellSSR`, body);
    } else {
      return Promise.reject("No User Id Found");
    }
  }
  async addPaymentToBooking(
    amount: string
    // userId: string
  ): Promise<AxiosResponse<any>> {
    const body = {
      userId: localStorage.getItem("sessionId"),
      QuotedCurrencyCode: "INR",
      QuotedAmount: amount,
    };
    return axios.post(`${this.baseUrl}booking/addPaymentToBooking`, body);
  }
  async commitBooking(
    passenger: {
      FirstName: string;
      LastName: string;
      Title: string;
    }[],
    email: string,
    mobile: string,
    passengerCount: number,
    // userId: string,
    recordLocator?: string
  ): Promise<AxiosResponse<any>> {
    const userId = localStorage.getItem("sessionId");
    if (userId !== null) {
      let body: {
        userId: string;
        CurrencyCode: string;
        PaxCount: number;
        Email: string;
        HomePhone: string;
        AddressLine: string;
        City: string;
        CountryCode: string;
        Passenger_Details: {
          FirstName: string;
          LastName: string;
          Title: string;
        }[];
        DistributeToContacts: "true";
        RecordLocator?: string;
      } = {
        userId,
        CurrencyCode: "INR",
        PaxCount: passengerCount,
        Email: email,
        HomePhone: mobile,
        AddressLine: "A",
        City: "GGN",
        CountryCode: "IN",
        Passenger_Details: passenger,
        DistributeToContacts: "true",
      };
      if (recordLocator) {
        body.RecordLocator = recordLocator;
      }
      return axios.post(`${this.baseUrl}booking/bookingCommit`, body);
    } else {
      return Promise.reject("No Session Found");
    }
  }
  async assignSeat(
    selectedSeatList: {
      FlightNumber: string;
      CarrierCode: string;
      STD: string;
      DepartureStation: string;
      ArrivalStation: string;
      PassengerID: string;
      UnitDesignator: string;
    }[]
  ): Promise<AxiosResponse<any>> {
    const userId = localStorage.getItem("sessionId");
    const body = {
      userId,
      CurrencyCode: "INR",
      SegmentSeatList: selectedSeatList,
    };
    return axios.post(`${this.baseUrl}booking/assignSeat`, body);
  }
}

const spicejetObj = new FlightBooking("sg");
const goAirObj = new FlightBooking("g8");
const indigoObj = new FlightBooking("6E");

export const BookFlights: IBookFlight[] = [spicejetObj, indigoObj];
