import { useHistory } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Illustration from "@kiwicom/orbit-components/lib/Illustration";


import AddOns from "./AddOns/AddOns";
import Coupons from "./Components/Coupons";
import FareSummary from "./FareSummary/FareSummary";
import TripSummary from "./TripSummary/TripSummary";
import BsModal from "../../../components/Modal/BSModal";
import Wizard from "../../../components/KiwiWizard/Wizard";
import Button from "../../../components/Common/Button/Button";
import BsInfoModal from "../../../components/Modal/BsInfoModal";
import PassengerDetails from "./PassengerDetails/PassengerDetails";
import AffiliateAddOnMarkup from "./Components/AffiliateAddOnMarkup";

import {
  commonStateSlice,
  showToast,
} from "../../../redux/reducers/commonState";
import { State } from "../../../redux/types";
import { flightSlice } from "../../../redux/reducers/flight";
import useFlightBooking from "../../../utils/hooks/Flight/useFlightBooking";
import useFlightBookingSSRs from "../../../utils/hooks/Flight/useFlightBookingSSRs";
import { calculateIfAirIQFlightSelected, calculateIfNavitaireFlightSelected } from "../../../utils/utilityFunctions/flight/flightUtilityFunctions";


const FlightBooking = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { isAgent } = useSelector((state: State) => state.user);
  const { CouponBox } = useSelector((state: State) => state.commonState);
  const { selectedFlights, Itinerary, personalInfo } = useSelector((state: State) => state.flight);

  const {
    SSRList,
    sendSellSSR,
    sendAssignSeat,
    isSSRSelling,
    isSeatSelling
  } = useFlightBookingSSRs(); //initialization required to get te SSR lists and manage SSRs

  const [activeStep, setActiveStep] = useState<number>(0);
  // activeStep 0 means summary page,1 means ssr selection page, 2 means pax details page 3 means continue to payment


  const {
    // payment related
    paymentForm,
    isPaymentFormVisible,
    setIsPaymentFormVisible,
    confirmCancelPayment,
    setConfirmCancelPayment,

    // booking flow related
    sellTicket,
    getSeatAvailability,
    seatList,
    updatePassenger,
    addPayment,
    commitBooking,
    isSelling,
    isPassengerUpdated,
    isPaymentDone,
    setIsPaymentDone,
    isPaymentProcessing,
    isSessionExpired,
    agentAddOnMarkup,
    addAgentAddOnMarkup,
    isUpdatingPassengers
  } = useFlightBooking();

  // const { getBooking, createBooking } = useFlightPostBooking();
  // const { getChargesByService } = useClientData();
  // const { getChargesForAffiliate, getChargesForCustomer } = useFlightMetaData();

  useEffect(() => {
    //get data from mycharges API on  reaching the page.
    // if MyCharges Arr doesn't have any obj with service id 1 i.e flight
    // if (
    //   Client !== null
    //   // &&
    //   // ClientData?.MyCharges?.findIndex(
    //   //   (charges) => charges?.ServiceId === 1
    //   // ) === -1
    // ) {
    //   getChargesByService(1); //1 is serviceId for flight
    //   if (isAgent) {
    //     getChargesForAffiliate();
    //   } else {
    //     getChargesForCustomer();
    //   }
    // }

    /*
    * THE ABOVE PART HAS BEEN PLACED IN THE Flight.tsx FILE
    */

    // To test the create booking API of backend
    // Promise.all([getBooking("HFC15U", "SG"), getBooking("Y6WINP", "G8")]).then(
    //   (res) => {
    //     createBooking({ SG: res[0], G8: res[1] });
    //   }
    // );
    getSeatAvailability();

    const isNavitaireFLightSelected = calculateIfNavitaireFlightSelected(selectedFlights);
    if (
      isNavitaireFLightSelected
      &&
      (!Itinerary?.Journeys || Itinerary?.Journeys?.length === 0)
    ) {
      history.push("/");
    };
  }, []);

  const isPaxDetailsFilled = useMemo(() => {
    const isEmailFilled = personalInfo?.email.length > 0;
    const isPhoneNumFilled = personalInfo?.mobileNo.length > 0;
    const isPaxInfoFilled =
      personalInfo?.passengers?.adult?.filter(
        (pax) => pax.dateOfBirth.length === 0 || pax.firstName.length === 0
      ).length === 0 ||
      (personalInfo?.passengers.child.length > 0 &&
        personalInfo?.passengers?.child?.filter(
          (pax) => pax.dateOfBirth.length === 0 || pax.firstName.length === 0
        ).length === 0);
    return isEmailFilled && isPaxInfoFilled && isPhoneNumFilled;
  }, [personalInfo]);

  const headerText = useMemo(() => {
    let data = [
      {
        title: "Review your itinerary",
        subtitle: "Check if everything is correct before proceeding.",
      },
      {
        title: "Choose Add-ons",
        subtitle:
          "Choose for extra baggage, preferred seat and meals options etc.",
      },
      {
        title: "Passenger Details",
        subtitle: "Please fill correct details to avoid any inconvenience.",
      },
    ];
    return data[activeStep];
  }, [activeStep]);



  return (
    <>
      {/* <Button
        label={"Create Booking data"}
        onClick={() => {
          getBooking("C7RW7M");
        }}
      /> */}
      <div className="bg-light">
        {isPaymentDone ? (
          <BsInfoModal
            title="Booking Success"
            subTitle="Please check you mailbox and phone number for confirmation message."
            icon={<Illustration name="Success" size="small" />}
            confirmText="View Booking Details"
            cancelText="Return to Home"
            cancelTextColor="primary"
            onClose={() => {
              setIsPaymentDone(false);
              dispatch(flightSlice.actions.resetFlightData());
              dispatch(
                commonStateSlice.actions.updateState({
                  key: "isFlightDataFetched",
                  value: false,
                })
              );
              history.push("/");
            }}
            onConfirm={() => {
              setIsPaymentDone(false);
              dispatch(flightSlice.actions.resetFlightData());
              dispatch(
                commonStateSlice.actions.updateState({
                  key: "isFlightDataFetched",
                  value: false,
                })
              );
              history.push("/profile/booking/flight");
            }}
          />
        ) : (
          <></>
        )}

        {/* TABS WIZARD */}
        <div className="bg-white">
          <div className="container py-4 w-75">
            <Wizard
              stepsTitle={[
                "Review Itinerary",
                "Add ons",
                "Passenger Details",
                "Overview & Payment",
              ].filter(e => calculateIfAirIQFlightSelected(selectedFlights) ? e !== "Add ons" : true)}
              onStepChange={(step) => {
                setActiveStep(step);
              }}
              currentStep={activeStep}
            />
          </div>
        </div>

        <div
          className="container"
          style={{ marginBottom: "80px", paddingBottom: "20px" }}
        >
          {/* <div className="spacer--md"></div> */}
          <div className="d-flex justify-content-between align-items-center">
            <div className="section-title w-75">
              <div>
                <h2 className="d-flex align-items-center gap-2">
                  {/* Delhi <FlightDirect /> Bombay and Back */}
                  {headerText?.title}
                </h2>
                <p className="text-muted">{headerText?.subtitle}</p>
              </div>
            </div>

            <Button
              color="primary"
              label="Change Flight"
              style={{ height: "fit-content" }}
              onClick={(e) => history.goBack()}
            />
          </div>

          {/* Trip summary card */}
          <div className="row my-3">
            {/* Left Panels */}
            <div className="col-md-8 col-sm-12 ">
              {activeStep === 0 ? (
                <TripSummary />
              ) :
                !calculateIfAirIQFlightSelected(selectedFlights) && activeStep === 1 ? (
                  <AddOns ssrList={SSRList} seatList={seatList} />
                ) : (
                  <div className="px-2">
                    <PassengerDetails />
                  </div>
                )}
            </div>
            <div className="col-md-4 col-sm-12">
              {isAgent ? (
                <AffiliateAddOnMarkup
                  onAddonMarkupSubmit={(amount: number) => {
                    if (
                      amount < 0 &&
                      Math.abs(amount) > Number(Itinerary.TotalCost)
                    ) {
                      // amount < 0 mins discount
                      // Show error when the agent puts discount(-ve) amount greater than the total price.
                      dispatch(
                        showToast({
                          type: "error",
                          subTitle:
                            "Discount amount cannot be more than total price.",
                          title: "",
                        })
                      );
                    } else {
                      // dispatch(flightSlice.actions.setAgentAddonMarkup(amount));
                      addAgentAddOnMarkup(amount);
                    }
                  }}
                  addonMarkup={agentAddOnMarkup}
                />
              ) : CouponBox.IsEnabledFlight ? (
                <Coupons />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <FareSummary
          activeStep={activeStep}
          isPassengerUpdated={isPassengerUpdated}
          isUpdatingPassengers={isUpdatingPassengers}
          actionDisabled={
            (activeStep === 1 && (isSSRSelling || isSeatSelling))
            ||
            (activeStep === 2 && !isPaxDetailsFilled) //passenger info not filled hence cannot update passenger
            ||
            (activeStep === 3 && !isPassengerUpdated) //passenger not updated hence cannot move with sell
          }
          onStepChange={async () => {
            if (activeStep === 1) {
              await Promise.all([sendSellSSR(), sendAssignSeat()]);
            } else if (activeStep === 2) {
              updatePassenger();
            } else if (activeStep === 3) {
              // final booking call
              addPayment(Itinerary?.BookingSum);
            }
            if (activeStep !== 3) {
              setActiveStep((prev) => prev + 1);
            }
          }}
          agentAddOnMarkup={agentAddOnMarkup}
        />
      </div>

      {/* PAYMENT MODAL */}
      {isPaymentFormVisible
        ?
        <BsModal
          title={""}
          subTitle="Payment is in process. Don't close this screen."
          onClose={() => setConfirmCancelPayment(true)}
          scrollable={false}
          size={"fullscreen"}
          body={<div className="d-flex justify-content-center align-items-center">
            <div
              ref={paymentForm}
              className="my-5 w-50"
            />
          </div>}
        />
        :
        <></>}

      {/* CANCEL PAYMENT PROCESS */}
      {confirmCancelPayment
        ?
        <BsInfoModal
          title="Are you sure?"
          subTitle="This action will terminate the payment process."
          icon={<Illustration name="TimelineLeave" size="small" />}
          confirmText="Yes"
          // cancelText="Return"
          // cancelTextColor="primary"
          // onClose={() => {
          //   // setIsPaymentDone(false);
          //   // setIsPaymentFormVisible(false)
          // }}
          onConfirm={() => {
            setIsPaymentDone(false);
            setIsPaymentFormVisible(false);
            setConfirmCancelPayment(false);
          }}
        />
        :
        <></>}

    </>
  );
};

export default FlightBooking;
