import React, { FC, useRef, useState } from "react";
import BSInfoModal from "../Modal/BsInfoModal";
import useAuth from "../../utils/hooks/useAuth";
import useClickOutSide from "../../utils/hooks/useClickOutSide";
import { useHistory } from "react-router-dom";

interface Props {
  onClose: () => void;
  isAgent?: boolean;
}
const ProfileMenu: FC<Props> = ({ onClose, isAgent }) => {
  const [showLogout, setShowLogout] = useState<boolean>(false);
  const { logout } = useAuth();
  const profileMenuRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  // useClickOutSide(profileMenuRef, () => {
  //   onClose();
  // });
  return (
    <React.Fragment>
      <div
        className="profile-menu"
        onClick={() => onClose && onClose()}
        ref={profileMenuRef}
      >
        {!isAgent && (
          <>
            {" "}
            <div className="items" onClick={() => history.push("/profile")}>
              My Profile
            </div>
            <div
              className="items"
              onClick={() => history.push("/profile/booking/flight")}
            >
              My Bookings
            </div>
          </>
        )}
        <div
          className="items"
          onClick={(e) => {
            e.stopPropagation();
            setShowLogout(true);
          }}
        >
          Logout
        </div>
      </div>
      {showLogout ? (
        <BSInfoModal
          title="Ready to Leave?"
          subTitle="you can sign in again later."
          confirmText="Yes"
          cancelText="No"
          onConfirm={() => {
            logout();
            onClose();
            setShowLogout(false);
          }}
          onClose={() => {
            onClose();
            setShowLogout(false);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
export default ProfileMenu;
