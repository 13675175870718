import React, { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { createReduxStore } from "./redux/index";
import { Provider, useDispatch, useSelector } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import ThemeProvider from "@kiwicom/orbit-components/lib/ThemeProvider";
import Components from "./pages/ComponentsPage/Components";
import Routes from "./Routes/Routes";
import SignInModal from "./components/Common/SignInModal/SignInModal";
import { ICommon, IUser, State } from "./redux/types";
import Toast from "./components/Common/Toast/Toast";
import AppHeader from "./AppHeader";
import Footer from "./components/Common/Footer/Footer";
import { commonStateSlice } from "./redux/reducers/commonState";

const customTokens = getTokens({
  palette: {
    product: {
      light: "#4337BE30",
      lightHover: "#4337BE50",
      lightActive: "#4337BE70",
      normal: "#4337BE",
      normalHover: "#4337BE80",
      normalActive: "#4337BE70",
      dark: "#4337BE",
    },
    red: {
      normal: "#EE3A3A",
    },
    green: {
      normal: "#00A991",
    },
    social: {},
    blue: {
      // normal: "#FEDA38",
    },
    white: {
      normal: "#FFFFFF",
    },
    cloud: {
      // normal: "#FEDA38",
    },
    ink: {
      // normal: "#4337BE",
    },
    orange: {
      // normal: "#AEEEAA",
    },
  },
});

function MainApp() {
  const { showSignInModal, toast } = useSelector(
    (state: State) => state.commonState
  );
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    // clear fetched data if returned to home
    if (location.pathname === "/") {
      dispatch(
        commonStateSlice.actions.updateState({
          key: "isFlightDataFetched",
          value: false,
        })
      );
      dispatch(
        commonStateSlice.actions.updateState({
          key: "isHotelDataFetched",
          value: false,
        })
      );
    }
  }, [location, dispatch]);

  const hasNavBar = useMemo(
    () =>
      location.pathname !== "/" &&
      location.pathname !== "/privacy-policy" &&
      !location.pathname.includes("/flight/booking"),
    [location]
  );
  return (
    <>
      <div style={{ minHeight: "65vh" }}>
        <AppHeader hasNavbar={hasNavBar} />
        {showSignInModal && <SignInModal />}
        {toast?.length > 0 && <Toast />}
        <Switch>
          <Route exact path="/components-page">
            <Components />
          </Route>
          <Route path="/">
            <Routes />
          </Route>
        </Switch>
      </div>
      {!location.pathname.includes("/flight/booking") && <Footer />}
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={createReduxStore()}>
        <ThemeProvider
          theme={{
            orbit: customTokens,
            rtl: false,
            transitions: false,
            lockScrolling: false,
          }}
        >
          <MainApp />
        </ThemeProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
