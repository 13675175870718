import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaAngleDown,
  FaAngleRight,
  FaAngleUp
} from "react-icons/fa";

import { State } from "../../../../redux/types";
import { toggle } from "../../../../redux/reducers/commonState";
import Button from "../../../../components/Common/Button/Button";
import useClickOutSide from "../../../../utils/hooks/useClickOutSide";
import { priceFormatter } from "../../../../utils/utilityFunctions/utilityFunction";
import { IFLightPricesToShow, IFlightMarkUpData, IMarkUpResponse, TFlightResultList, TuAPIPax } from "../../../../utils/types/flightTypes";
import { calculateFlightPricesToShow, calculateFlightPricesToShow1G, calculateFlightPricesToShowAirIQ, getFlightMarkedUpPrice } from "../../../../utils/utilityFunctions/flight/flightUtilityFunctions";
import SummaryDetails from "./SummaryDetails";
import { uAPIFlight } from "../../../../Interfaces/SearchFlights/SearchFlights";

type Props = {
  activeStep: number;
  isPassengerUpdated: boolean;
  isUpdatingPassengers: boolean;
  onStepChange: () => void;
  actionDisabled: boolean;
  agentAddOnMarkup: number;
};

interface IExpandedRow {
  title: string;
  data: {
    text: string;
    price: number;
  }[];
};

const ExpandedRow = ({ title, data }: IExpandedRow) => {
  return (
    <div
      className="d-flex justify-content-between pb-2"
      style={{
        borderBottom: "1px solid #FFFFFF20",
      }}
    >
      <span className="fw-bold w-50">{title}</span>
      <div className="d-flex flex-column w-100 ms-auto">
        {data.map((d) => (
          <div className="d-flex justify-content-between">
            <span>{d.text}</span>
            <span className="fw-bold">{priceFormatter(d.price)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const FareSummary = ({
  activeStep,
  isPassengerUpdated,
  isUpdatingPassengers,
  actionDisabled,
  onStepChange,
  agentAddOnMarkup,
}: Props) => {

  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state: State) => state.user);
  const { GSTRates } = useSelector((state: State) => state.commonState.ClientData);
  const { searchInfo, selectedFlights, Itinerary, SelectedAddOns, metaData } = useSelector((state: State) => state.flight);

  const [show, setShow] = useState<boolean>(false);
  const [priceBreakUpForAirIQ, setPriceBreakUpForAirIQ] = useState<IFLightPricesToShow>({} as IFLightPricesToShow);
  const [priceBreakUpFor1G, setPriceBreakUpFor1G] = useState<IFLightPricesToShow>({} as IFLightPricesToShow);
  const [priceBreakUpForNavitaire, setPriceBreakUpForNavitaire] = useState<IFLightPricesToShow>({} as IFLightPricesToShow);
  const [combinedPriceToShow, setCombinedPriceToShow] = useState<IFLightPricesToShow[]>([]);
  const [baseFaresAndAddOnCharges, setBaseFaresAndAddOnCharges] = useState<{
    baseFareData: {
      text: string;
      price: any;
    }[];
    addOnPriceData: {
      text: string;
      price: any;
    }[];
  }>({
    baseFareData: [],
    addOnPriceData: [],
  });

  const fareRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    if (activeStep === 2) {
      airPricing();
    };
  }, [activeStep]);

  // let pricesToShow: IFLightPricesToShow = useMemo(
  //   () =>
  //     calculateFlightPricesToShow(
  //       Itinerary?.Journeys,
  //       searchInfo?.adult,
  //       searchInfo?.child,
  //       SelectedAddOns || [],
  //       agentAddOnMarkup
  //     ),
  //   [Itinerary, searchInfo, SelectedAddOns, agentAddOnMarkup]
  // );
  // console.log("Itinerary?.Journeys: ", Itinerary?.Journeys);
  // console.log("pricesToShow: ", pricesToShow);

  useEffect(() => {

    console.log("selectedFlights: ", selectedFlights);

    const priceToShowNavitaire = calculateFlightPricesToShow(
      Itinerary?.Journeys,
      searchInfo?.adult,
      searchInfo?.child,
      SelectedAddOns || [],
      agentAddOnMarkup
    );
    setPriceBreakUpForNavitaire(priceToShowNavitaire);

    // let priceToShow = JSON.parse(JSON.stringify(combinedPriceToShow));
    // priceToShow.push(priceToShowNavitaire);
    // setCombinedPriceToShow(priceToShow);
    priceToShowNavitaire.NetPayable && setCombinedPriceToShow(prev => ([...prev, priceToShowNavitaire]));

    console.log("-----------------------------------------")
    // console.log("priceToShow: ", priceToShow);
    console.log("priceToShowNavitaire: ", priceToShowNavitaire);

    // AirIQ flight prices
    calculateFlightPricingForAirIQ();

    // 1G flight prices
    calculateFlightPricingFor1G();

  }, []); // Itinerary, searchInfo, SelectedAddOns, agentAddOnMarkup

  // useEffect(() => {

  //   console.log("combinedPriceToShow: ", combinedPriceToShow);

  // }, [combinedPriceToShow]);


  // let baseFareData = [
  //   {
  //     text: `${searchInfo.adult} Adult passenger${searchInfo.adult > 1 ? `s` : ""
  //       }`,
  //     price: pricesToShow?.Base.TotalAdult,
  //   },
  // ];

  // if (searchInfo?.child > 0) {
  //   baseFareData.push({
  //     text: `${searchInfo.child} Child passenger${searchInfo.adult > 1 ? `s` : ""
  //       }`,
  //     price: pricesToShow?.Base.TotalChild,
  //   });
  // };
  // let bagPrice = pricesToShow?.Charges?.Baggage;
  // let mealPrice = pricesToShow?.Charges?.Meals;
  // let seatPrice = pricesToShow?.Charges?.Seat;
  // let addOnPriceData = [];
  // if (bagPrice && bagPrice > 0) {
  //   addOnPriceData.push({ text: "Extra Baggage", price: bagPrice });
  // };
  // if (mealPrice && mealPrice > 0) {
  //   addOnPriceData.push({ text: "Added Meals", price: mealPrice });
  // };
  // if (seatPrice && seatPrice > 0) {
  //   addOnPriceData.push({ text: "Selected Seat", price: seatPrice });
  // };

  useClickOutSide(fareRef, () => {
    setShow(false);
  });

  // HANDLER FUNCTIONS
  const calculateFlightPricingForAirIQ = () => {

    const AirIQFlights = selectedFlights?.filter((flights) => flights?.supplier === "airiq");
    if (AirIQFlights?.length > 0) {

      let markedUpPrice: IFlightMarkUpData[] = [];
      let markupResponse: IMarkUpResponse[][] = metaData?.Markup;
      let agentMarkupResponse: IMarkUpResponse[][] = metaData?.AffiliateMarkup;

      AirIQFlights.forEach((flight, index) => {

        // console.log("flight: ", flight);
        const adultBasePrice = flight.fare_data.price;
        const adultTravelFee = 0;
        const childBasePrice = 0;
        const childTravelFee = 0;
        const childDiscountPrice = 0;
        const carrierCode = flight.carrierCode;

        const markedupPrice = getFlightMarkedUpPrice(
          markupResponse[index],
          adultBasePrice,
          adultTravelFee,
          childBasePrice - childDiscountPrice,
          childTravelFee,
          carrierCode === "SG"
            ? "Spicejet"
            : carrierCode === "G8"
              ? "GoAir"
              : "",
          searchInfo?.adult,
          searchInfo?.child,
          selectedFlights?.length, // no. of journeys
          GSTRates,
          agentMarkupResponse?.[index],
          searchInfo?.fromCity.findIndex(
            (city) => city.ISOCountry !== "IN"
          ) === -1 //not proper recheck it
        );
        markedUpPrice.push(markedupPrice);

        // console.log("markedupPrice: ", markedupPrice);

      });

      const pricesToShowAirIQ = calculateFlightPricesToShowAirIQ(
        markedUpPrice,
        searchInfo?.adult,
        searchInfo?.child,
        agentAddOnMarkup
      );
      setPriceBreakUpForAirIQ(pricesToShowAirIQ);

      // let priceToShow = JSON.parse(JSON.stringify(combinedPriceToShow));
      // priceToShow.push(pricesToShowAirIQ);
      // setCombinedPriceToShow(priceToShow);
      pricesToShowAirIQ.NetPayable && setCombinedPriceToShow(prev => ([...prev, pricesToShowAirIQ]));

      // console.log("priceToShow: ", priceToShow);
      console.log("pricesToShowAirIQ: ", pricesToShowAirIQ);
      // console.log("----------------------------------------------");
    };

  };

  const calculateFlightPricingFor1G = () => {

    const Air1GFlights = selectedFlights?.filter((flights) => flights?.supplier === "1G");
    if (Air1GFlights?.length > 0) {

      let markedUpPrice: IFlightMarkUpData[] = [];
      let markupResponse: IMarkUpResponse[][] = metaData?.Markup;
      let agentMarkupResponse: IMarkUpResponse[][] = metaData?.AffiliateMarkup;

      Air1GFlights.forEach((flight, index) => {

        // console.log("flight: ", flight);
        const adultBasePrice = flight.totalFare;
        const adultTravelFee = 0;
        const childBasePrice = 0;
        const childTravelFee = 0;
        const childDiscountPrice = 0;
        const carrierCode = flight.carrierCode;

        const markedupPrice = getFlightMarkedUpPrice(
          markupResponse[index],
          adultBasePrice,
          adultTravelFee,
          childBasePrice - childDiscountPrice,
          childTravelFee,
          carrierCode === "SG"
            ? "Spicejet"
            : carrierCode === "G8"
              ? "GoAir"
              : "",
          searchInfo?.adult,
          searchInfo?.child,
          selectedFlights?.length, // no. of journeys
          GSTRates,
          agentMarkupResponse?.[index],
          searchInfo?.fromCity.findIndex(
            (city) => city.ISOCountry !== "IN"
          ) === -1 //not proper recheck it
        );
        markedUpPrice.push(markedupPrice);

        // console.log("markedupPrice: ", markedupPrice);

      });

      console.log("markedUpPrice: ", markedUpPrice);
      const pricesToShowAir1G = calculateFlightPricesToShow1G(
        markedUpPrice,
        searchInfo?.adult,
        searchInfo?.child,
        agentAddOnMarkup
      );
      setPriceBreakUpFor1G(pricesToShowAir1G);

      // let priceToShow = JSON.parse(JSON.stringify(combinedPriceToShow));
      // priceToShow.push(pricesToShowAir1G);
      // setCombinedPriceToShow(priceToShow);
      pricesToShowAir1G.NetPayable && setCombinedPriceToShow(prev => ([...prev, pricesToShowAir1G]));

      // console.log("priceToShow: ", priceToShow);
      console.log("pricesToShowAir1G: ", pricesToShowAir1G);

    };

  };

  const handleFormatAirPricingFor1G = (response: any, flights: TFlightResultList[]) => {

    console.log("response: ", response);
    let AirPricingData: any[] = [];

    const AirPriceRes = response?.["air:AirPriceRsp"];
    const AirPricingSolution = Array.isArray(AirPriceRes?.["air:AirPriceResult"]?.["air:AirPricingSolution"])
      ?
      AirPriceRes?.["air:AirPriceResult"]?.["air:AirPricingSolution"]
      :
      [AirPriceRes?.["air:AirPriceResult"]?.["air:AirPricingSolution"]];
    const AirSegment = Array.isArray(AirPriceRes?.["air:AirItinerary"]?.["air:AirSegment"])
      ?
      AirPriceRes?.["air:AirItinerary"]?.["air:AirSegment"]
      :
      [AirPriceRes?.["air:AirItinerary"]?.["air:AirSegment"]];

    AirSegment?.forEach((each: any) => {
      const segmentRefKey = each["@Key"];
      let filteredPricingSolutions: any[] = [];

      AirPricingSolution?.forEach((eachSolution: any) => {
        eachSolution?.["air:AirSegmentRef"]?.forEach((eachRef: any) => {
          if (eachRef["@Key"] === segmentRefKey) {
            filteredPricingSolutions.push(eachSolution);
          };
        });
      });

      if (filteredPricingSolutions?.length > 0) {
        AirPricingData.push({
          segmentRefKey: segmentRefKey,
          AirSegment: each,
          PricingSolutions: filteredPricingSolutions
        });
      };
    });
    console.log("AirPricingData: ", AirPricingData);
    console.log("flights: ", flights);

    let journeyWiseAirPricingData: any[] = [];
    if (AirPricingData?.length > 0) {

      flights.forEach((flight, index) => {

        let segmentWiseAirPricing: any[] = [];
        AirPricingData.forEach((each: any) => {
          flight["1GSegmentData"].forEach((segment) => {
            const origin = segment.departure.iata_code;
            const destination = segment.arrival.iata_code;

            if (each.AirSegment["@Origin"] === origin && each.AirSegment["@Destination"] === destination) {
              segmentWiseAirPricing.push(each);
            };
          });
          journeyWiseAirPricingData.push(segmentWiseAirPricing);

        });

      });

    };

    console.log("journeyWiseAirPricingData: ", journeyWiseAirPricingData);

  };

  // API CALLS
  const airPricing = () => {

    const adultPax: Array<{ Code: TuAPIPax }> = Array(searchInfo.adult).fill(1).map(_ => ({ Code: "ADT" }));
    const childPax: Array<{ Code: TuAPIPax }> = Array(searchInfo.child).fill(1).map(_ => ({ Code: "CHD" }));
    const infantPax: Array<{ Code: TuAPIPax }> = Array(searchInfo.infant).fill(1).map(_ => ({ Code: "INF" }));
    const passengers = [...adultPax, ...childPax, ...infantPax];

    const airSegments = selectedFlights.filter(
      each => each.supplier === "1G"
    )?.map(
      eachFlight => eachFlight?.journey?.map(
        (journey: any) => journey.AirSegment[0]
      ).flat()
    ).flat();

    const formattedAirSegments = airSegments.map(eachSegment => ({
      Key: eachSegment["@Key"],
      Group: eachSegment["@Group"],
      Carrier: eachSegment["@Carrier"],
      FlightNumber: eachSegment["@FlightNumber"],
      FlightTime: eachSegment["@FlightTime"],
      TravelTime: eachSegment["@TravelTime"], // need to change this
      Origin: eachSegment["@Origin"],
      Destination: eachSegment["@Destination"],
      DepartureTime: eachSegment["@DepartureTime"],
      ArrivalTime: eachSegment["@ArrivalTime"],
      ETicketability: eachSegment["@ETicketability"],
      Equipment: eachSegment["@Equipment"],
      ChangeOfPlane: eachSegment["@ChangeOfPlane"],
      ParticipantLevel: eachSegment["@ParticipantLevel"],
      PolledAvailabilityOption: eachSegment["@PolledAvailabilityOption"],
      OptionalServicesIndicator: eachSegment["@OptionalServicesIndicator"],
      AvailabilitySource: eachSegment["@AvailabilitySource"],
      AvailabilityDisplayType: eachSegment["@AvailabilityDisplayType"],
      ProviderCode: eachSegment["air:AirAvailInfo"]["@ProviderCode"]
    }));

    const payload = {
      AirSegment: formattedAirSegments,
      Passengers: passengers.map((each, index) => ({
        Key: index + 1,
        Code: each.Code
      })),
      AirPricingModifiers: [{
        CurrencyType: "INR"
      }]
    };
    // console.log("payload: ", payload);

    uAPIFlight.airPricing(payload).then(
      (response) => {
        // console.log("Response from 1G airPricing request: ", response.data?.data);
        const flights = selectedFlights.filter(
          each => each.supplier === "1G"
        );
        handleFormatAirPricingFor1G(response.data?.data, flights);
      }, (error) => {
        console.log("Error from 1G airPricing request: ", error);
      }
    );

  };



  return (
    <div
      className="bg-dark text-white position-fixed w-100 bottom-0 py-2"
      style={{ minHeight: "10vh", zIndex: 2 }}
      ref={fareRef}
    >

      <div className="container expanded-summary pb-4">
        <div className="row">
          {/* d-flex justify-content-between align-items-center */}
          {
            combinedPriceToShow.map((pricesToShow, index) =>
              <div className="col" key={index + "_fare_details"}>
                <SummaryDetails
                  show={show}
                  setShow={setShow}
                  searchInfo={searchInfo}
                  pricesToShow={pricesToShow}
                />
              </div>
            )
          }
        </div>
      </div>

      {/* FARE SUMMARY */}
      {/* {show
        ?
        <div className="container expanded-summary pb-4">
          <ExpandedRow title="Base Fare" data={[
            { text: "Passenger", price: pricesToShow.Base.TotalAdult + pricesToShow.Base.TotalChild },
          ]} />
          {addOnPriceData.length > 0 ? (
            <ExpandedRow title="Add-on Services" data={addOnPriceData} />
            // <ExpandedRow
            //   title="Add-on Services"
            //   data={[
            //     { text: "SSR", price: addOnPriceData.reduce((a, b) => a + b.price, 0) },
            //   ]} />
          ) : (
            <></>
          )}
          <ExpandedRow
            title="Taxes & Charges"
            data={[
              {
                text: "Tax (includes GST)",
                price:
                  pricesToShow.Charges.FareTax.TotalAdult +
                  pricesToShow.Charges.FareTax.TotalChild +
                  pricesToShow.Charges.TotalGST,
              },
              {
                text: "Booking charge (includes gateway charges)",
                price: pricesToShow.ServiceCharges,
              },
            ]}
          />
          {pricesToShow?.AgentAddOnMarkup > 0
            ?
            <ExpandedRow
              title="Agent Markup"
              data={[{ text: "", price: pricesToShow.AgentAddOnMarkup }]}
            />
            :
            <></>}
        </div>
        :
        <></>} */}

      <div
        className="container"
        onClick={() => {
          setShow((prev) => !prev);
        }}
      >
        <div className="d-flex justify-content-between my-auto">
          <div className="d-flex flex-column">
            <span className="fs-4">
              Fare Summary{" "}
              {show ? <FaAngleDown size={20} /> : <FaAngleUp size={20} />}
            </span>
          </div>
          <div
            className={`d-flex flex-col gap-4 align-items-center`}
          >
            {/*  ${show && "flex-column"
              }c */}
            <div className={`text-end `}>
              {/* ${show && "w-100"} */}
              {/* {!show && <span>Booking Total</span>} */}
              <span>Booking Total</span>
              <h3 className="fw-bold">
                {/* {priceFormatter(pricesToShow?.NetPayable)} */}
                {priceFormatter(combinedPriceToShow.reduce((a, b) => a + b.NetPayable, 0))}

              </h3>
            </div>
            {isLoggedIn || activeStep < 3 ? (
              <Button
                color="light"
                className="text-primary"
                onClick={(e) => {
                  e.stopPropagation();
                  onStepChange();
                }}
                disabled={actionDisabled}
                isLoading={isUpdatingPassengers}
                label={
                  <span>
                    Continue {activeStep === 3 && isPassengerUpdated ? "to payment" : ""}
                    <FaAngleRight className=" ps-2 mb-1" />
                  </span>
                }
              />
            ) : (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(toggle("showSignInModal"));
                }}
                color="light"
                className="text-primary"
                label="Signin to Continue"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FareSummary;
