import React from "react";
import { FaClock } from "react-icons/fa";
import { useSelector } from "react-redux";
import moment from "moment";
import Badge from "@kiwicom/orbit-components/lib/Badge";

import "../tripSummary.scss";

import { State } from "../../../../../redux/types";
import { IJourney } from "../../../../../utils/types/flightTypes";

interface Props {
  journey: IJourney;
};

const DetailedInfo = ({ journey }: Props) => {

  const { searchInfo, metaData } = useSelector((state: State) => state.flight);

  return (
    <div className="detailed_info">
      {journey?.Segments?.map((segment, index) => {
        let segmentDiff = moment(segment?.STA).diff(segment?.STD, "minutes");
        let layOver =
          index < journey?.Segments?.length - 1
            ? moment(journey?.Segments[index + 1]?.STD).diff(
              segment?.STA,
              "minutes"
            )
            : -1;
        // if city name is not match in DB airport then show the iata code itself coming from  spicejet
        let cityName =
          metaData?.FlightOrigins.find(
            (airport) =>
              airport.RouteDestinationCode === segment?.DepartureStation
          )?.City || segment?.DepartureStation;
        let arrivalCityName =
          metaData?.FlightDestinations.find(
            (airport) =>
              airport.RouteDestinationCode === segment?.ArrivalStation
          )?.City || segment?.ArrivalStation;
        return (
          <React.Fragment key={index}>
            <div className="items">
              <div className="content">
                <>
                  <p className="mb-1 fw-semibold">
                    {moment(segment?.STD).format("HH:mm")} - {cityName}
                  </p>
                  <p className="small text-muted d-flex align-items-center mb-0">
                    {metaData?.FlightOrigins.find(
                      (airport) =>
                        airport.RouteDestinationCode ===
                        segment?.DepartureStation
                    )?.RouteDestinationName?.slice(0, 35)}
                    <div
                      className="bg-dark mx-2"
                      style={{ width: 4, height: 4, borderRadius: 4 }}
                    ></div>
                    {segment?.DepartureStation}
                  </p>
                </>

                <div className=" d-flex gap-2 mt-3">
                  <Badge>
                    {Math.floor(segmentDiff / 60)}h{" "}
                    {Math.floor(segmentDiff % 60)}m flight
                  </Badge>
                  <Badge>
                    {segment?.FlightDesignator?.CarrierCode}-
                    {segment?.FlightDesignator?.FlightNumber}
                  </Badge>
                  {/* <Badge type="info">
                    <span className="text-capitalize">
                      {searchInfo?.class?.FlightClassName}
                    </span>
                  </Badge> */}
                </div>
              </div>
            </div>
            {/* Leg end */}
            <div className="items">
              <div className="content">
                <>
                  <p className="mb-1 fw-semibold">
                    {moment(segment?.STA).format("HH:mm")} - {arrivalCityName}
                  </p>
                  <p className="small text-muted d-flex align-items-center mb-0">
                    {metaData?.FlightDestinations.find(
                      (airport) =>
                        airport.RouteDestinationCode === segment?.ArrivalStation
                    )?.RouteDestinationName?.slice(0, 35)}
                    <div
                      className="bg-dark mx-2"
                      style={{ width: 4, height: 4, borderRadius: 4 }}
                    ></div>
                    {segment?.ArrivalStation}
                  </p>
                </>

                {/* <div className=" d-flex gap-2 mt-3">
                  <Badge>
                    {Math.floor(segmentDiff / 60)}h{" "}
                    {Math.floor(segmentDiff % 60)}m flight
                  </Badge>
                  <Badge>
                    {segment?.FlightDesignator?.CarrierCode}-
                    {segment?.FlightDesignator?.FlightNumber}
                  </Badge>
                  <Badge type="info">
                    <span className="text-capitalize">{searchInfo?.class}</span>
                  </Badge>
                </div> */}
              </div>
            </div>

            {layOver !== -1 ? (
              <div className="item_layover">
                <div className="layover small">
                  <FaClock />
                  <div className="opacity-75">
                    <strong>
                      {Math.floor(layOver / 60)}h {Math.floor(layOver % 60)}m
                    </strong>{" "}
                    Stopover in {arrivalCityName}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}
      {/* <div className="items">
        <div className="content ">
          <div >
            <p className="mb-1 fw-semibold" >
              9:00 am - Ahmedabad
            </p>
            <p className="small text-muted d-flex align-items-center mb-0">
              Ahmedabad Int.
              <div
                className="bg-dark mx-2"
                style={{ width: 4, height: 4, borderRadius: 4 }}
              ></div>
              AMD
            </p>
          </div>
        </div>
      </div> */}

      {/* Layover */}
      {/* <div className="item_layover">
        <div className="layover small">
          <FaClock />
          <div className="opacity-75">
            <strong>1h 30m</strong> Stopover in Ahmedabad
          </div>
        </div>
      </div> */}

      {/* mid */}

      {/* <div className="items">
        <div className="content">
          <div >
            <p className="mb-1 fw-semibold">
              6:00 am - Delhi
            </p>
            <p className="small text-muted d-flex align-items-center mb-0">
              Indra Gandhi Int.
              <div
                className="bg-dark mx-2"
                style={{ width: 4, height: 4, borderRadius: 4 }}
              ></div>
              DEL
            </p>
          </div>

          <div className=" d-flex gap-2 mt-3">
            <Badge>1h 45m flight</Badge>
            <Badge>Go First 710</Badge>
            <Badge type="info">Economy</Badge>
          </div>
        </div>
      </div> */}

      {/* Final Destination */}
      {/* <div className="items">
        <div className="content ">
          <div >
            <p className="mb-1 fw-semibold" >
              9:00 am - Ahmedabad
            </p>
            <p className="small text-muted d-flex align-items-center mb-0">
              Ahmedabad Int.
              <div
                className="bg-dark mx-2"
                style={{ width: 4, height: 4, borderRadius: 4 }}
              ></div>
              AMD
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DetailedInfo;
