import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { flightSlice } from "../../../../redux/reducers/flight";
import { State } from "../../../../redux/types";
import moment from "moment";
import { TypePassengerData } from "../../../../utils/types/flightTypes";
import GroupedInput from "../../../../components/Common/Input/GroupedInput";

interface Props {
  travellerType?: 1 | 2 | 3; //1 for adult 2 for child and 3 for infant
  index: number; //passenger Index
}
const PersonalInfoForm = ({ index, travellerType }: Props) => {
  const { personalInfo, metaData } = useSelector(
    (state: State) => state.flight
  );
  const dispatch = useDispatch();
  const type: "adult" | "child" | "infant" = React.useMemo(
    () =>
      travellerType === 1 ? "adult" : travellerType === 2 ? "child" : "infant",
    [travellerType]
  );
  const dispatchPassenger = (key: TypePassengerData, value: any) => {
    dispatch(
      flightSlice.actions.updatePassengerData({
        type,
        index: index,
        key,
        value,
      })
    );
  };
  return (
    <React.Fragment>
      <div className="d-flex py-2">
        <div className="col-md-6 col-12 px-3">
          <GroupedInput
            label="First Name"
            inputs={[
              {
                body: (
                  <select
                    className=" border-0 form-select shadow-none"
                    style={{ maxWidth: "30%" }}
                    onChange={function (e: any) {
                      const { value } = e.target;
                      dispatchPassenger("Title", value);
                    }}
                    value={personalInfo.passengers[type][index].Title}
                  >
                    {metaData?.TitleMaster?.map((title, index) => (
                      <option key={index} value={title}>
                        {title}
                      </option>
                    ))}
                  </select>
                ),
              },
              {
                body: (
                  <input
                    className="form-control border-0 shadow-none"
                    placeholder="John"
                    onChange={function (e: any) {
                      const { value } = e.target;
                      dispatchPassenger("firstName", value);
                    }}
                    value={personalInfo.passengers[type][index].firstName}
                  />
                ),
              },
            ]}
          />
        </div>
        <div className="col-md-6 col-12 px-3">
          <label htmlFor="lastname" className="form-label">
            Last Name
          </label>
          <input
            className="form-control"
            placeholder="Doe"
            onChange={function (e: any) {
              const { value } = e.target;
              dispatchPassenger("lastName", value);
            }}
            value={personalInfo.passengers[type][index].lastName}
          />
        </div>
      </div>
      <div className="d-flex pt-2">
        <div className="col-md-6 col-12 px-3">
          <label htmlFor="gender" className="form-label">
            Gender
          </label>
          <select
            className="form-select  shadow-none"
            onChange={function (e: any) {
              const { value } = e.target;
              dispatchPassenger("gender", value);
            }}
            value={personalInfo.passengers[type][index].gender}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="col-md-6 col-12 px-3 ">
          <div className="d-flex flex-column">
            <label className="form-label">Date of Birth</label>
            <input
              type="date"
              max={moment()
                .subtract(
                  type === "adult" ? 12 : type === "child" ? 2 : 0,
                  "years"
                )
                .format("YYYY-MM-DD")}
              className="form-control"
              value={personalInfo.passengers[type][index].dateOfBirth}
              onChange={function (e) {
                const { value } = e.target;
                dispatchPassenger("dateOfBirth", value);
              }}
            />
            {/* <DatePicker
              value={
                personalInfo?.passengers[props?.isChild ? "child" : "adult"][
                  props?.index
                ]?.dateOfBirth || undefined
              }
              maxDate={moment()
                .subtract(props.isChild ? 2 : 12, "years")
                .format("YYYY-MM-DD")}
              label=""
              onChange={(value) => {
                dispatchPassenger("dateOfBirth", value);
              }}
            /> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PersonalInfoForm;
