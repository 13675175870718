import moment from "moment";

import {
  IAirIQFareData,
  IAirIQSearchAvailabilityResponse,
  IFLightOrigins,
  IItineraryBookingSum,
  TFlightResultList,
} from "./../../types/flightTypes";
import {
  constructJourneyUniqueAirportArray,
  formatToArr,
  getFareInfo,
  getFlightMarkedUpPrice,
  getFlightMarkedUpPriceForSearchResults,
} from "./flightUtilityFunctions";
import {
  IFlightMetaData,
  IMarkUpResponse,
  ISeat,
  ISeatAvailability,
  ISearchInfo,
  ISelectedSeat,
  IFlightSearchData,
  IFareData,
  IFlightSegmentData,
} from "../../types/flightTypes";
import { combineAndFormatDateTime, getMyChargesAmount, isObject } from "../utilityFunction";
import { IFlightItinerary, IMyCharges } from "../../../redux/types";
import { ISeatData } from "../../../pages/Flight/FlightBooking/AddOns/SeatSelection/Components/Seat";


const getFareType = (classType: string) => {
  const saverProductTypes = ["RS", "SS", "SR", "SU", "XA", "AX"];
  const maxProductTypes = ["SC", "CS"];
  const flexiProductTypes = ["FS", "SF"];
  const indigoProductTypes = ["R", "N", "A", "B", "J", "S", "F", "T", "C"];

  return saverProductTypes.includes(classType)
    ? "SAVER"
    : maxProductTypes.includes(classType)
      ? "MAX"
      : flexiProductTypes.includes(classType)
        ? "FLEXI"
        : "NA";
};

export const formatSJToFlightCardData = (
  faresArray: any[],
  journeys: any[],
  markupResponse: IMarkUpResponse[],
  searchInfo: ISearchInfo,
  originAirports: IFLightOrigins[],
  isAgent: boolean,
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[],
  affiliateMarkupResponse?: IMarkUpResponse[]
) => {
  // console.time("FormattingSearchData");
  // console.log("Params::::", {
  //   faresArray,
  //   journeys,
  //   markupResponse,
  //   searchInfo,
  //   originAirports,
  //   isAgent,
  //   affiliateMarkupResponse,
  // });
  let formattedData: TFlightResultList[] = []; // IFlightSearchData[]
  formattedData = journeys.filter(
    //filter out flights will null AvailableFares
    (journey) =>
      formatToArr(journey?.AvailableSegment?.AvailableSegment).filter(
        (seg) => seg?.AvailableFares !== null
      ).length > 0
  ).map((journey: any) => {

    let obj: TFlightResultList = {} as TFlightResultList;

    // all the segments of each journey.It is array of more than 1 segments if connecting flight else 1 for direct flights;
    let avSegment = formatToArr(journey?.AvailableSegment?.AvailableSegment);
    // initial dep time is STA of first segment

    let depTime = avSegment[0]?.STD;
    // final arrival time of a journey is STA of last segment
    // 0th index if avSegment is 1 means direct flight i.e length-1 =0
    let arrivalTime = avSegment[avSegment.length - 1]?.STA;

    // difference between first dep and final destination arrival in minutes
    let diffTime = moment(arrivalTime).diff(moment(depTime), "minutes");

    /*
      fare mapped to specific fareIndex for each Segment;
      avSegment[0] is used below because if it is direct flight then avSegment.length===1
      even if non-direct flights all other segments have paxFare null when mapped to fares array
      */
    //  Not using because in case of SOS connecting flights fare and FareSellkey of all segment is required
    // const availableFare = formatToArr(
    //   avSegment[0]?.AvailableFares?.AvailableFare2
    // );
    // obj.fares = availableFare.map((av) => {
    //   let fareAtIndex = faresArray[av?.FareIndex];
    //   return Object.assign({
    //     fare: formatToArr(fareAtIndex?.PaxFares?.PaxFare)[0]?.ServiceCharges
    //       ?.BookingServiceCharge,
    //     // PaxFares array returns array if passenger type(paxtype)
    //     // child is used in search we are taking 0th value since this
    //     //denotes adult fare.
    //     // child fare can be used later
    //     FareClassOfService: fareAtIndex?.FareClassOfService,
    //     FareBasisCode: fareAtIndex?.FareBasisCode,
    //     CarrierCode: fareAtIndex?.CarrierCode,
    //     ClassOfService: fareAtIndex?.ClassOfService,
    //     FareSellKey: fareAtIndex?.FareSellKey,
    //   });
    // });

    // //
    // in SOS type flights all available fare and fareIndex(combined by ^ as in Documentation) is to be considered instead of just avsegment[0]
    // because in connecting flights there might be fare available in second segments also
    // we will be using SOS fares as default to keep consistency

    // availableFares[0] is used because it is minimum in most cases
    // might have multiple values with higher prices
    // for different services but we are considering only base service for now
    // might have to change this if we are considering all available fares
    // if so, then we will also have to update api req of getavailablity to send all service type(MX, IO, R)

    // const availableFareIndexSOS = avSegment.map(
    //   (seg) =>
    //     formatToArr(seg?.AvailableFares?.AvailableFare2)?.[0]?.FareIndex
    // );
    // availableFareIndexSOSArray contains all the fares
    // in array for different services
    let availableFareIndexSOSArray: any[] = [];
    if (avSegment?.length === 1) {
      formatToArr(avSegment[0]?.AvailableFares?.AvailableFare2)?.map((seg) =>
        availableFareIndexSOSArray.push(seg?.FareIndex)
      );
    } else {
      avSegment?.forEach((seg, index) => {
        formatToArr(seg?.AvailableFares?.AvailableFare2)?.forEach(
          (f, idx) => {
            if (availableFareIndexSOSArray[idx] === undefined) {
              availableFareIndexSOSArray[idx] = [];
            }
            availableFareIndexSOSArray?.[idx].push(f?.FareIndex);
          }
        );
      });
    }
    // console.log("availableFareIndexSOSArray: ", availableFareIndexSOSArray);
    // in case of multi segments, we have to send Fare Sell Keys of all segments combined by ^
    // should be single if segment is 1 or second segment onwards is null
    let FareData: IFareData[] = [];
    availableFareIndexSOSArray.forEach((avSOS, index) => {
      // console.log("avSOS: ", avSOS);
      // if not array then it is direct flight
      if (!Array.isArray(avSOS)) {
        let fIndex = faresArray?.[avSOS];
        let basePrice = +formatToArr(
          formatToArr(fIndex?.PaxFares?.PaxFare)[0]?.ServiceCharges
            ?.BookingServiceCharge
        )?.[0]?.Amount;
        let taxPrice = formatToArr(
          formatToArr(fIndex?.PaxFares?.PaxFare)[0]?.ServiceCharges
            ?.BookingServiceCharge
        )
          ?.map((charge, index) => (index === 0 ? 0 : charge.Amount))
          .reduce((acc: number, val: number) => acc + +val, 0);
        // console.log("fIndex: ", fIndex, "basePrice: ", basePrice, "taxPrice: ", taxPrice);
        const data = {
          CarrierCode: fIndex?.CarrierCode,
          ClassOfService: fIndex?.ClassOfService,
          FareBasisCode: fIndex?.FareBasisCode,
          ProductClass: fIndex?.ProductClass,
          FareType: getFareType(fIndex?.ProductClass),
          FareInfo: getFareInfo(fIndex?.ProductClass, fIndex?.CarrierCode),
          FareClassOfService: fIndex?.FareClassOfService,
          FareSellKey: fIndex?.FareSellKey,
          RuleNumber: fIndex?.RuleNumber,
          basePrice,
          taxPrice,
          markedUpPrice: getFlightMarkedUpPriceForSearchResults(
            markupResponse,
            basePrice,
            taxPrice,
            faresArray[0]?.CarrierCode === "SG"
              ? "Spicejet"
              : faresArray[0]?.CarrierCode === "G8"
                ? "GoAir"
                : faresArray[0]?.CarrierCode === "6E"
                  ? "Indigo"
                  : "",
            searchInfo?.adult + searchInfo?.child,
            searchInfo?.toCity?.length,
            true, //isDomestic, sending true as default for now. Need to change when we have international flights
            isAgent,
            GSTRates,
            affiliateMarkupResponse
          ),
        };
        // console.log("--- data: ", data);
        FareData.push(data);
      } else {
        let combinedKey = "";
        avSOS.forEach((av, idx) => {
          let fareAtIndex = faresArray?.[av];
          if (idx !== 0) {
            combinedKey += `^${fareAtIndex?.FareSellKey}`;
          } else {
            combinedKey += fareAtIndex?.FareSellKey;
          }
          // combinedFareSellKeyArray.push(combinedKey);
        });
        let basePrice: number = avSOS
          .map(
            (av) =>
              formatToArr(
                formatToArr(faresArray[av]?.PaxFares?.PaxFare)[0]
                  ?.ServiceCharges?.BookingServiceCharge
              )?.[0]?.Amount
          )
          .reduce(
            (acc: number, val: number) =>
              acc + (val !== undefined ? +val : 0),
            0
          );
        let taxPrice = avSOS
          ?.map((av) =>
            formatToArr(
              formatToArr(faresArray[av]?.PaxFares?.PaxFare)[0]
                ?.ServiceCharges?.BookingServiceCharge
            ).map((bookingCharge, index) =>
              index === 0 ? 0 : bookingCharge?.Amount
            )
          )
          .flat()
          .reduce((acc, segFare) => acc + Number(segFare), 0);
        let fIndex = faresArray?.[avSOS[0]];
        FareData.push({
          CarrierCode: fIndex?.CarrierCode,
          ClassOfService: fIndex?.ClassOfService,
          FareBasisCode: fIndex?.FareBasisCode,
          ProductClass: fIndex?.ProductClass,
          FareType: getFareType(fIndex?.ProductClass),
          FareInfo: getFareInfo(fIndex?.ProductClass, fIndex?.CarrierCode),
          FareClassOfService: fIndex?.FareClassOfService,
          FareSellKey: combinedKey,
          RuleNumber: fIndex?.RuleNumber,
          basePrice,
          taxPrice,
          markedUpPrice: getFlightMarkedUpPriceForSearchResults(
            markupResponse,
            basePrice,
            taxPrice,
            faresArray[0]?.CarrierCode === "SG"
              ? "Spicejet"
              : faresArray[0]?.CarrierCode === "G8"
                ? "GoAir"
                : faresArray[0]?.CarrierCode === "6E"
                  ? "Indigo"
                  : "",
            searchInfo?.adult + searchInfo?.child,
            searchInfo?.toCity?.length,
            true,
            isAgent,
            GSTRates,
            affiliateMarkupResponse
          ),
        });
      }
    });
    // console.log("FareData: ", FareData);
    // let saverProductTypes = ["RS", "SS", "SR", "SU", "XA", "AX"];
    // let returnSaverType = ["XA", "AX"];

    // "R", "J", "O" -> IndiGo
    // "GS" -> for GoAir
    // and others for Spicejet
    let oneWaySaverType = ["RS", "SS", "SR", "SU", "GS", "R", "J", "O"];
    //return trip
    // const hasReturntypeFare = FareData.some((f) =>
    //   ["XA", "AX"].includes(f.ProductClass)
    // );
    obj.FareData =
      searchInfo?.travelType?.SearchTypeId === 2
        ? FareData?.filter(
          (f) =>
            // hasReturntypeFare
            // ? !
            oneWaySaverType.includes(f?.ProductClass)
          // : true
        )
        : FareData;

    let AllSaverFare = FareData?.filter((fare) =>
      oneWaySaverType.includes(fare.ProductClass)
    );
    let Cheapest = AllSaverFare[0];
    AllSaverFare.forEach((fare) => {
      if (
        fare.markedUpPrice.markedupTotalPrice <
        Cheapest?.markedUpPrice.markedupTotalPrice
      ) {
        Cheapest = fare;
      }
    });
    obj.FareToShow = Cheapest !== undefined ? Cheapest : FareData[0];
    obj.fare_data = {} as IAirIQFareData;
    obj.carrierCode = FareData[0].CarrierCode;
    // in case of multi segments, we have to send Fare Sell Keys of all segments combined by ^
    // should be single if segment is 1 or second segment onwards is null
    // let combinedFareSellKey = "";
    // availableFareIndexSOS.forEach((avSOS, index) => {
    //   let fareAtIndex = faresArray[avSOS];
    //   if (index !== 0) {
    //     combinedFareSellKey += `^${fareAtIndex?.FareSellKey}`;
    //   } else {
    //     combinedFareSellKey += fareAtIndex?.FareSellKey;
    //   }
    // });
    // console.log(combinedFareSellKey);

    // obj.FareSellKey = combinedFareSellKey;

    // summing up amount of all segment because SOS flights have fare price in all segments
    // so for consistency we are summing weather or not it is SOS connecting,normal connecting or direct flight
    //  first is base fare amount and rest objects
    // can have taxes and other fees
    // totalPrice is summed up price of each segment of a flight

    // let basePrice = availableFareIndexSOS
    //   ?.map(
    //     (av) =>
    //       formatToArr(
    //         formatToArr(faresArray[av]?.PaxFares?.PaxFare)[0]?.ServiceCharges
    //           ?.BookingServiceCharge
    //       )?.[0]?.Amount
    //   )
    //   .reduce(
    //     (acc, segFare) => acc + (segFare !== undefined ? +segFare : 0),
    //     0
    //   );
    // let taxPrice = availableFareIndexSOS
    //   ?.map((av) =>
    //     formatToArr(
    //       formatToArr(faresArray[av]?.PaxFares?.PaxFare)[0]?.ServiceCharges
    //         ?.BookingServiceCharge
    //     ).map((bookingCharge, index) =>
    //       index === 0 ? 0 : bookingCharge?.Amount
    //     )
    //   )
    //   .flat()
    //   .reduce((acc, segFare) => acc + Number(segFare), 0);

    // obj.totalPrice = basePrice + taxPrice;

    // journey sell key is identifier key of the flight
    obj.JourneySellKey = journey?.JourneySellKey;
    let SegmentData: IFlightSegmentData[] = [];
    // flight for each segment might by different in SOS connecting flight.

    avSegment.forEach((av) => {
      let legInfo = formatToArr(av?.Legs?.Leg)[0]?.LegInfo;
      let diffTime = moment(av?.STA).diff(moment(av?.STD), "minutes");
      SegmentData.push({
        carrierCode: av?.FlightDesignator["a:CarrierCode"],
        flightNumber: av?.FlightDesignator["a:FlightNumber"],
        duration: `${Math.floor(diffTime / 60)}h ${Math.floor(
          diffTime % 60
        )}m`,
        durationInMins: diffTime,
        arrival: {
          iata_code: av?.ArrivalStation,
          city:
            originAirports?.find(
              (airport) =>
                airport?.RouteDestinationCode === av?.ArrivalStation
            )?.City || "",
          scheduled_time: av?.STA,
          terminal: legInfo?.ArrivalTerminal,
        },
        departure: {
          iata_code: av?.DepartureStation,
          city:
            originAirports?.find(
              (airport) =>
                airport?.RouteDestinationCode === av?.DepartureStation
            )?.City || "",
          scheduled_time: av?.STD,
          terminal: legInfo?.DepartureTerminal,
        },
      });
    });

    obj.SegmentData = SegmentData;
    obj.flightCode = avSegment?.map(
      (av) =>
        `${av?.FlightDesignator["a:CarrierCode"]}-${av?.FlightDesignator["a:FlightNumber"]}`
    );

    // list of airport names string[]
    // 2 in direct flight and more in connecting flights
    obj.airports = avSegment
      ?.map((seg, index) => {
        if (index === 0) return [seg?.DepartureStation, seg?.ArrivalStation];
        return seg?.ArrivalStation;
      })
      .flat();

    // total duration in HH mm format
    obj.duration = `${Math.floor(diffTime / 60)}h ${Math.floor(
      diffTime % 60
    )}m`;
    // total duration of journey
    obj.durationInMins = diffTime;

    obj.departureTime = depTime;
    obj.arrivalTime = arrivalTime;
    obj.supplier = "navitaire";
    return obj;

  });
  // console.timeEnd("FormattingSearchData");
  // console.log("formattedData: ", formattedData);
  return formattedData;
};

export const formatItineraryData = (
  itinerary: any[],
  metaData: IFlightMetaData,
  searchInfo: ISearchInfo,
  MyCharges: IMyCharges[],
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[]
) => {
  let markupResponse: IMarkUpResponse[][] = metaData?.Markup;
  let agentMarkupResponse: IMarkUpResponse[][] = metaData?.AffiliateMarkup;
  // let BookingInfo = itinerary?.BookingInfo;
  let TotalCost = 0;
  let BookingSum: IItineraryBookingSum[] = [];
  let Journeys: any[] = [];

  itinerary?.forEach((it) => {
    let journeys = formatToArr(it?.Journeys?.Journey);
    BookingSum.push({
      AlternateCurrencyBalanceDue: it?.BookingSum?.AlternateCurrencyBalanceDue,
      AlternateCurrencyCode: it.BookingSum.AlternateCurrencyCode,
      AuthorizedBalanceDue: it.BookingSum.AuthorizedBalanceDue,
      BalanceDue: it.BookingSum.BalanceDue,
      PassiveSegmentCount: it.BookingSum.PassiveSegmentCount,
      PointsBalanceDue: it.BookingSum.PointsBalanceDue,
      SegmentCount: it.BookingSum.SegmentCount,
      TotalCost: it.BookingSum.TotalCost,
      TotalPointCost: it.BookingSum.TotalPointCost,
      CarrierCode: formatToArr(journeys?.[0]?.Segments?.Segment)?.[0]
        ?.FlightDesignator["a:CarrierCode"],
    });
    // BookingSum.AlternateCurrencyBalanceDue +=
    //   +it?.BookingSum?.AlternateCurrencyBalanceDue;
    // BookingSum.AlternateCurrencyCode = it.BookingSum.AlternateCurrencyCode;
    // BookingSum.AuthorizedBalanceDue += +it.BookingSum.AuthorizedBalanceDue;
    // BookingSum.BalanceDue += +it.BookingSum.BalanceDue;
    // BookingSum.PassiveSegmentCount += +it.BookingSum.PassiveSegmentCount;
    // BookingSum.PointsBalanceDue += +it.BookingSum.PointsBalanceDue;
    // BookingSum.SegmentCount += +it.BookingSum.SegmentCount;
    // BookingSum.TotalCost += +it.BookingSum.TotalCost;
    // BookingSum.TotalPointCost += +it.BookingSum.TotalPointCost;
    // //
    TotalCost += +it.BookingSum.TotalCost;
    Journeys.push(...journeys);
  });
  // let Journeys = itinerary?.map(it=>formatToArr(it?.Journeys?.Journey)).flat();
  let formattedData: IFlightItinerary = {
    BookingSum,
    CurrencyCode: itinerary?.[0]?.CurrencyCode, //currency code will be same for all journeys hence using first journey
    // TotalCost: itinerary?.reduce((acc,it)=>acc+ Number(it.BookingSum.TotalCost),0),
    TotalCost,
    Journeys: Journeys?.map((journey, index) => {
      let segmentArr = formatToArr(journey?.Segments?.Segment);
      let arrivalTime = segmentArr[segmentArr.length - 1]?.STA;
      let departureTime = segmentArr[0]?.STD;
      let diffTime = moment(arrivalTime).diff(moment(departureTime), "minutes");
      let carrierCode = segmentArr[0]?.FlightDesignator["a:CarrierCode"];
      // segmentArr[0] is used below bcoz,
      // direct flights have 1 segment
      // connecting flights have multiple seg but all segments other than [0] have paxfare null and their total fare is in [0]
      // wont be using this for now as Other segment also have fare sometimes(SOS connecting flights)
      // let bookingCharges = formatToArr(
      //   formatToArr(segmentArr[0]?.Fares?.Fare)[0]?.PaxFares?.PaxFare
      // ).map((charges) => charges?.ServiceCharges?.BookingServiceCharge);

      // in case of SOS_connecting flights fare is also present in other segments
      // fare[0] is used because we are only considering one type of service i.e saver type in case of Spice jet ( USAV )
      // which is available at 0th position.
      // paxFare array might have two obj containing
      // fare for adult and fare for child and maybe more so mapping all
      let bookingChargesForSOS = segmentArr
        ?.filter((seg) => formatToArr(seg?.Fares?.Fare)[0]?.PaxFares !== null)
        ?.map((seg) => {
          return formatToArr(
            formatToArr(seg?.Fares?.Fare)[0]?.PaxFares?.PaxFare
          ).map((charges) => charges?.ServiceCharges?.BookingServiceCharge);
        });

      // [0] booking charge is always adult price
      // [1] is present if child is in search criteria and it always has child price
      // [0][0] is always  adult basePrice
      // [0][1] is always  child basePrice
      //
      // ChargeType FarePrice is BasePrice
      // ChargeType Tax are GST prices
      // ChargeType TravelFee are Other charges
      // ChargeType Discount are Discounts such as dicount on child prices
      //
      let adultBasePrice = bookingChargesForSOS
        .map((eachSegment) => formatToArr(eachSegment?.[0])?.[0]?.Amount)
        .reduce((acc: number, amount) => acc + Number(amount), 0);

      let childBasePrice = bookingChargesForSOS
        .map((eachSegment) => formatToArr(eachSegment?.[1])?.[0]?.Amount)
        .reduce((acc: number, amount) => acc + Number(amount), 0);

      let adultGSTPrice = bookingChargesForSOS
        .map((eachSegment) =>
          formatToArr(eachSegment?.[0]).filter(
            (fare) => fare?.ChargeType === "Tax"
          )
        )
        .flat()
        ?.reduce((acc: number, fare: any) => acc + Number(fare?.Amount), 0);

      let childGSTPrice = bookingChargesForSOS
        .map((eachSegment) =>
          formatToArr(eachSegment?.[1]).filter(
            (fare) => fare?.ChargeType === "Tax"
          )
        )
        .flat()
        ?.reduce((acc: number, fare: any) => acc + Number(fare?.Amount), 0);
      let adultTravelFee = bookingChargesForSOS
        .map((eachSegment) =>
          formatToArr(eachSegment?.[0]).filter(
            (fare) =>
              fare?.ChargeType !== "Tax" &&
              fare?.ChargeType !== "Discount" &&
              fare?.ChargeType !== "FarePrice"
          )
        )
        .flat()
        ?.reduce((acc: number, fare: any) => acc + Number(fare?.Amount), 0);
      let childTravelFee = bookingChargesForSOS
        .map((eachSegment) =>
          formatToArr(eachSegment?.[1]).filter(
            (fare) =>
              fare?.ChargeType !== "Tax" &&
              fare?.ChargeType !== "Discount" &&
              fare?.ChargeType !== "FarePrice"
          )
        )
        .flat()
        ?.reduce((acc: number, fare: any) => acc + Number(fare?.Amount), 0);
      let childDiscountPrice = bookingChargesForSOS
        .map((eachSegment) =>
          formatToArr(eachSegment?.[1]).filter(
            (fare) => fare?.ChargeType === "Discount"
          )
        )
        .flat()
        ?.reduce((acc: number, fare: any) => acc + Number(fare?.Amount), 0);
      return {
        JourneySellKey: journey?.JourneySellKey,
        Segments: segmentArr?.map((segment) => ({
          ActionStatusCode: segment?.ActionStatusCode,
          ArrivalStation: segment?.ArrivalStation,
          AvailabilitySourceCode: segment?.AvailabilitySourceCode,
          CabinOfService: segment?.CabinOfService,
          ChangeReasonCode: segment?.ChangeReasonCode,
          ChannelType: segment?.ChannelType,
          DepartureStation: segment?.DepartureStation,
          Fares: formatToArr(segment?.Fares?.Fare)?.map((fare) => ({
            CarrierCode: fare?.CarrierCode,
            ClassOfService: fare?.ClassOfService,
            ClassType: fare?.ClassType,
            FareApplicationType: fare?.FareApplicationType,
            FareBasisCode: fare?.FareBasisCode,
            ProductClass: fare?.ProductClass,
            FareType: getFareType(fare?.ProductClass),
            FareClassOfService: fare?.FareClassOfService,
            FareSellKey: fare?.FareSellKey,
            FareSequence: fare?.FareSequence,
            FareStatus: fare?.FareStatus,
            InboundOutbound: fare?.InboundOutbound,
            IsAllotmentMarketFare: fare?.IsAllotmentMarketFare,
            OriginalClassOfService: fare?.OriginalClassOfService,
            PaxFares: formatToArr(fare?.PaxFares?.PaxFare).map((paxFare) => ({
              FareDiscountCode: paxFare?.FareDiscountCode,
              PaxDiscountCode: paxFare?.PaxDiscountCode,
              PaxType: paxFare?.PaxType,
              ProofOfStatusRequired: paxFare?.ProofOfStatusRequired,
              ServiceCharges: formatToArr(
                paxFare?.ServiceCharges?.BookingServiceCharge
              ),
            })),
            TravelClassCode: fare?.TravelClassCode,
            XrefClassOfService: fare?.XrefClassOfService,
          })),
          FlightDesignator: {
            CarrierCode: segment?.FlightDesignator?.["a:CarrierCode"],
            FlightNumber: segment?.FlightDesignator?.["a:FlightNumber"],
            OpSuffix: segment?.FlightDesignator?.["a:OpSuffix"],
          },
          International: segment?.International,
          InventorySourceCode: segment?.InventorySourceCode,
          Legs: formatToArr(segment?.Legs?.Leg)?.map((leg) => ({
            ArrivalStation: leg?.ArrivalStation,
            DepartureStation: leg?.DepartureStation,
            InventoryLegID: leg?.InventoryLegID,
            STA: leg?.STA,
            STD: leg?.STD,
          })),
          PaxBags: segment?.PaxBags,
          PaxSSRs: segment?.PaxSSRs,
          PaxScores: segment?.PaxScores,
          STA: segment?.STA,
          STD: segment?.STD,
          SalesDate: segment?.SalesDate,
          SegmentSellKey: segment?.SegmentSellKey,
          SegmentType: segment?.SegmentType,
        })),
        meta: {
          adultBasePrice,
          childBasePrice,
          adultGSTPrice,
          childGSTPrice,
          adultTravelFee,
          childTravelFee,
          childDiscountPrice,
          arrivalTime,
          departureTime,
          carrierCode,
          selectedFareType: getFareType(
            formatToArr(segmentArr[0]?.Fares?.Fare)[0]?.ProductClass
          ),
          durationInMins: diffTime,
          duration: `${Math.floor(diffTime / 60)}h ${Math.floor(
            diffTime % 60
          )}m`,
          flightCode: segmentArr
            ?.map(
              (segment) =>
                `${segment?.FlightDesignator?.["a:CarrierCode"]}-${segment?.FlightDesignator?.["a:FlightNumber"]}`
            )
            .join(","),
          airports: segmentArr
            ?.map((seg, index) => {
              if (index === 0)
                return [seg?.DepartureStation, seg?.ArrivalStation];
              return seg?.ArrivalStation;
            })
            .flat(),
          priceBreakUp: bookingChargesForSOS.flat(1),
          markupPrice: getFlightMarkedUpPrice(
            markupResponse[index],
            adultBasePrice,
            adultTravelFee,
            childBasePrice - childDiscountPrice,
            childTravelFee,
            carrierCode === "SG"
              ? "Spicejet"
              : carrierCode === "G8"
                ? "GoAir"
                : "",
            searchInfo?.adult,
            searchInfo?.child,
            Journeys?.length,
            GSTRates,
            agentMarkupResponse?.[index],
            searchInfo?.fromCity.findIndex(
              (city) => city.ISOCountry !== "IN"
            ) === -1 //not proper recheck it
          ),
          vendorCharges: {
            adult: {
              GSTAmount: 0,
              otherCharges: 0,
              myCharges: getMyChargesAmount(
                MyCharges,
                adultBasePrice,
                searchInfo?.adult,
                searchInfo.toCity.length
                //   searchInfo?.fromCity.findIndex(
                //   (city) => city.ISOCountry !== "IN"
                // ) === -1,
              ),
            },
            child: {
              GSTAmount: 0,
              otherCharges: 0,
              myCharges:
                searchInfo?.child > 0
                  ? getMyChargesAmount(
                    MyCharges,
                    childBasePrice,
                    searchInfo?.child,
                    searchInfo.toCity.length
                    //   searchInfo?.fromCity.findIndex(
                    //   (city) => city.ISOCountry !== "IN"
                    // ) === -1,
                  )
                  : 0,
            },
          },
          flightAirline: metaData?.FlightAirline.filter((airline) =>
            carrierCode === "SG"
              ? airline.AirlineNameCode === "Spicejet"
              : carrierCode === "G8"
                ? airline.AirlineNameCode === "Go Air"
                : carrierCode === "6E"
                  ? airline.AirlineNameCode === "Indigo"
                  : false
          )[0],
          bookingSupplier: metaData?.BookingSupplier.filter((supplier) =>
            carrierCode === "SG"
              ? supplier.SupplierName === "Spicejet"
              : carrierCode === "G8"
                ? supplier.SupplierName === "Go Air"
                : carrierCode === "6E"
                  ? supplier.SupplierName === "Indigo"
                  : false
          )[0],
          ticketedSupplier: metaData?.TicketedSupplier.filter((supplier) =>
            carrierCode === "SG"
              ? supplier.SupplierName === "Spicejet"
              : carrierCode === "G8"
                ? supplier.SupplierName === "Go Air"
                : carrierCode === "6E"
                  ? supplier.SupplierName === "Indigo"
                  : false
          )[0],
        },
      };
    }),
  };
  return formattedData;
};

export const formatSGSeatAvailabilityData = (response: any) => {
  let leg = response?.Legs?.SeatAvailabilityLeg;
  let carrier = response?.EquipmentInfos?.EquipmentInfo;
  let compartment = formatToArr(
    formatToArr(carrier?.Compartments)[0]?.CompartmentInfo
  );

  // if (compartment.length === 0) {
  //   return;
  // };
  let allSeats = compartment?.map((com: any) =>
    com?.Seats?.SeatInfo.filter((seat: any) => seat.Assignable === "true")
  )
    .flat();
  let Compartment: any[] = [];
  compartment.forEach((com) => {
    let eqp = formatToArr(com?.PropertyList)[0]?.EquipmentProperty;
    Compartment.push({
      AvailableUnits: com?.AvailableUnits,
      CompartmentDesignator: com?.CompartmentDesignator,
      Deck: com?.Deck,
      Length: com?.Length,
      Orientation: com?.Orientation,
      Sequence: com?.Sequence,
      Width: com?.Width,
      EquipmentProperty: eqp,
    });
  });
  let allPrices = formatToArr(
    response?.SeatGroupPassengerFees?.SeatGroupPassengerFee
  );
  let result: ISeatAvailability = {
    LegInfo: {
      AircraftType: leg?.AircraftType,
      AircraftTypeSuffix: leg?.AircraftTypeSuffix,
      ArrivalStation: leg?.ArrivalStation,
      DepartureStation: leg?.DepartureStation,
      EquipmentIndex: leg?.EquipmentIndex,
      FlightNumber: leg?.FlightDesignator["a:FlightNumber"],
      CarrierCode: leg?.FlightDesignator["a:CarrierCode"],
      STA: leg?.STA,
      STD: leg?.STD,
    },
    EquipmentInfo: {
      AvailableUnits: carrier?.AvailableUnits,
      EquipmentCategory: carrier?.EquipmentCategory,
      EquipmentType: carrier?.EquipmentType,
      EquipmentTypeSuffix: carrier?.EquipmentTypeSuffix,
      MarketingCode: carrier?.MarketingCode,
      Name: carrier?.Name,
    },
    Compartment,
    Seats: allSeats?.map((seat: any) => {
      let pricesForSeat = allPrices?.filter(
        (price) => price?.SeatGroup === seat?.SeatGroup
      );
      return {
        Assignable: seat?.Assignable === "true",
        CabotageLevel: seat?.CabotageLevel,
        CarAvailableUnits: seat?.CarAvailableUnits,
        CompartmentDesignator: seat?.CompartmentDesignator,
        CriterionWeight: seat?.CriterionWeight,
        Height: seat?.Height,
        ODPenalty: seat?.ODPenalty,
        PremiumSeatIndicator: seat?.PremiumSeatIndicator === "true",
        Priority: seat?.Priority,
        SSRSeatMapCode: seat?.SSRSeatMapCode,
        SeatAngle: seat?.SeatAngle,
        SeatAvailability: seat?.SeatAvailability,
        SeatDesignator: seat?.SeatDesignator,
        SeatGroup: seat?.SeatGroup,
        SeatSet: seat?.SeatSet,
        SeatSetAvailableUnits: seat?.SeatSetAvailableUnits,
        SeatType: seat?.SeatType,
        TerminalDisplayCharacter: seat?.TerminalDisplayCharacter,
        Text: seat?.Text,
        TravelClassCode: seat?.TravelClassCode,
        Width: seat?.Width,
        X: seat?.X,
        Y: seat?.Y,
        Zone: seat?.Zone,
        PropertyList: formatToArr(
          formatToArr(seat?.PropertyList)[0]?.EquipmentProperty
        ),
        PriceList: pricesForSeat.map((price: any) => {
          let PaxFee = price?.PassengerFee;
          let Fares = formatToArr(
            formatToArr(PaxFee?.ServiceCharges)[0]?.BookingServiceCharge
          );
          let gst = Fares.filter((f) => f?.ChargeCode.includes("GST"));
          let charge = Fares.filter((f) => !f?.ChargeCode.includes("GST"));
          return {
            PassengerNumber: price?.PassengerNumber,
            SeatGroup: price?.SeatGroup,
            FeeApplicationType: PaxFee?.FeeApplicationType,
            FeeCode: PaxFee?.FeeCode,
            FeeDetail: PaxFee?.FeeDetail,
            FeeNumber: PaxFee?.FeeNumber,
            FeeOverride: PaxFee?.FeeOverride === "true",
            FeeType: PaxFee?.FeeType,
            FlightReference: PaxFee?.FlightReference,
            IsProtected: PaxFee?.IsProtected === "true",
            Note: PaxFee?.Note,
            PaymentNumber: PaxFee?.PaymentNumber,
            SSRCode: PaxFee?.SSRCode,
            Price: Fares?.map((fare: any) => ({
              Amount: Number(fare?.Amount),
              ChargeCode: fare?.ChargeCode,
              ChargeType: fare?.ChargeType,
              TicketCode: fare?.TicketCode,
            })),
            TotalPrice: {
              Charge: charge?.reduce(
                (acc: number, fare: any) => acc + Number(fare?.Amount),
                0
              ),
              GST: gst?.reduce(
                (acc: number, fare: any) => acc + Number(fare?.Amount),
                0
              ),
            },
          };
        }),
      };
    }),
  };
  return result;
};

export const formatSGSeatForProps = (
  seat: ISeat,
  maxRows: number[],
  rowNumber: number,
  selectedPax: number,
  selectedSeats: ISelectedSeat[],
  airCraftName?: string,
  rowsNotAllowed?: number[]
) => {
  let selectedBy = selectedSeats.find(
    (s) => s.selectedSeat.SeatDesignator === seat.SeatDesignator
  );

  let result: ISeatData = {
    seatId: seat?.SeatDesignator || "",
    isAvailable:
      seat?.SeatAvailability === "Open" &&
      rowsNotAllowed?.includes(rowNumber) !== true,
    seatClass: maxRows?.includes(rowNumber) ? "MAX" : "REG",
    seatLocation: findFlightSeatProp(seat.PropertyList, "AISLE")
      ? "A"
      : findFlightSeatProp(seat.PropertyList, "WINDOW")
        ? "W"
        : "M",
    isSelected: selectedBy !== undefined,
    selectedByPax: selectedBy?.passengerIndex,
    hasLegroom:
      airCraftName &&
        ((airCraftName === "B-737-900 (Y212)" && rowNumber === 29) ||
          (airCraftName === "B-737-800 (Y189)" && rowNumber === 5))
        ? false
        : findFlightSeatProp(seat.PropertyList, "LEGROOM"),
    price: seat.PriceList.filter(
      (p) => Number(p?.PassengerNumber) === selectedPax
    )[0],
    features: seat.PropertyList.map((prop) => prop.TypeCode) || [],
  };
  return result;
};

export const filterSeatsByRow = (
  seats: ISeat[],
  row: number,
  airCraftName: string
) => {
  let possibleSeatsOnLeft = [`${row}A`, `${row}B`, `${row}C`];
  let possibleSeatsOnRight = [`${row}D`, `${row}E`, `${row}F`];
  return {
    seatsOnLeft:
      airCraftName === "B-737-800 (Y189" && row === 4
        ? []
        : seats.filter((seat) =>
          possibleSeatsOnLeft.includes(seat.SeatDesignator)
        ),
    seatsOnRight: seats.filter((seat) =>
      possibleSeatsOnRight.includes(seat.SeatDesignator)
    ),
  };
};

const findFlightSeatProp = (
  props: { TypeCode: string; Value: string }[],
  searchKey: string
) => {
  return props.findIndex((p) => p.TypeCode === searchKey) !== -1;
};


export const formatAirIQFlightCardData = (
  journeys: IAirIQSearchAvailabilityResponse[],
  searchInfo: ISearchInfo,
  originAirports: IFLightOrigins[],
  isAgent: boolean,
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[],
  markupResponse: IMarkUpResponse[],
  affiliateMarkupResponse?: IMarkUpResponse[]
) => {

  // console.group("Format AirIQ Flight List");
  // console.log("journeys: ", journeys);
  // console.log("searchInfo: ", searchInfo);
  // console.log("originAirports: ", originAirports);
  // console.log("isAgent: ", isAgent);
  // console.log("GSTRates: ", GSTRates);
  // console.log("markupResponse: ", markupResponse);
  // console.log("affiliateMarkupResponse: ", affiliateMarkupResponse);
  // console.groupEnd();

  if (journeys.length === 0) {
    return [];
  };

  let formattedJourneyList: TFlightResultList[] = [];

  journeys.forEach((journey) => {

    // console.log("Journey: ", journey);
    const arrivalDateTime = combineAndFormatDateTime(journey.arival_date, journey.arival_time);
    const departureDateTime = combineAndFormatDateTime(journey.departure_date, journey.departure_time);

    const differenceInMinutes = moment(arrivalDateTime).diff(moment(departureDateTime), "minutes");
    const differenceInTime = `${Math.floor(differenceInMinutes / 60)}h ${Math.floor(differenceInMinutes % 60)}m`;

    let data: TFlightResultList = {
      JourneySellKey: journey.ticket_id,
      arrivalTime: arrivalDateTime,
      carrierCode: journey.airline === "SpiceJet"
        ?
        "SG"
        :
        journey.airline === "Akasa Air"
          ?
          "QP"
          :
          journey.airline,
      departureTime: departureDateTime,
      duration: differenceInTime,
      durationInMins: differenceInMinutes,
      airports: [journey.origin, journey.destination],
      flightCode: formatToArr(journey.flight_number),
      SegmentData: [
        {
          carrierCode: journey.airline === "SpiceJet"
            ?
            "SG"
            :
            journey.airline === "Akasa Air"
              ?
              "QP"
              :
              journey.airline,
          duration: differenceInTime,
          durationInMins: differenceInMinutes,
          flightNumber: journey.flight_number,
          arrival: {
            terminal: "",
            scheduled_time: arrivalDateTime,
            iata_code: journey.destination,
            city: originAirports?.find(
              (airport) =>
                airport?.RouteDestinationCode.toLowerCase() === journey.destination.toLowerCase()
            )?.City || "",
          },
          departure: {
            terminal: "",
            scheduled_time: departureDateTime,
            iata_code: journey.origin,
            city: originAirports?.find(
              (airport) =>
                airport?.RouteDestinationCode.toLowerCase() === journey.origin.toLowerCase()
            )?.City || "",
          }
        }
      ],
      // navitaire
      FareToShow: {} as IFareData,
      // new attributes
      "1GPricingSolutions": [],
      "1GSegmentData": [],
      supplier: "airiq",
      flight_route: journey.flight_route, // "Non - Stop", etc
      fare_data: {
        price: journey.price,
        infant_price: journey.infant_price,
        markedUpPrice: getFlightMarkedUpPriceForSearchResults(
          markupResponse,
          journey.price, // only considering price, and have ignored infant_price as for now
          0, // tax price
          journey.airline === "SpiceJet" ? "Spicejet" : journey.airline, // airline
          searchInfo?.adult + searchInfo?.child,
          searchInfo?.toCity?.length,
          true,
          isAgent,
          GSTRates,
          affiliateMarkupResponse
        )
      },
      fareInfo: {} as IAirIQFareData,
      totalFare: 0
    };

    formattedJourneyList.push(data);

  });

  return formattedJourneyList;

};


// uAPI
export const formatUAPIJourney = (
  responseData: any,
  searchInfo: ISearchInfo,
  originAirports: IFLightOrigins[],
  isAgent: boolean,
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[],
  markupResponse: IMarkUpResponse[],
  affiliateMarkupResponse?: IMarkUpResponse[]
) => {

  const parsedData = responseData["air:LowFareSearchRsp"];
  const mergedData: Array<any> = [];
  // Create a dictionary to group segments by flight detail key
  const flightDetailToSegments: any = {};
  // Iterate over each air segment to map them to flight details
  parsedData["air:AirSegmentList"]["air:AirSegment"].forEach((airSegment: any) => {
    // const segmentKey = airSegment["@Key"];
    let flightDetailsRefs = airSegment["air:FlightDetailsRef"];
    if (!Array.isArray(flightDetailsRefs)) {
      flightDetailsRefs = [flightDetailsRefs];
    }
    flightDetailsRefs.forEach((flightDetailsRef: any) => {
      const flightDetailKey = flightDetailsRef["@Key"];
      if (!flightDetailToSegments[flightDetailKey]) {
        flightDetailToSegments[flightDetailKey] = [];
      };
      flightDetailToSegments[flightDetailKey].push(airSegment);
    });
  });
  // Iterate over each flight detail and find the corresponding segments and pricing solutions
  parsedData["air:FlightDetailsList"]["air:FlightDetails"].forEach((flightDetail: any) => {
    const flightDetailKey = flightDetail["@Key"];
    if (flightDetailToSegments[flightDetailKey]) {
      const matchingSegments = flightDetailToSegments[flightDetailKey];
      // Find corresponding pricing solutions
      const matchingPricingSolutions: Array<any> = [];
      matchingSegments.forEach((airSegment: any) => {
        const segmentKey = airSegment["@Key"];
        parsedData["air:AirPricingSolution"].forEach((pricingSolution: any) => {
          const pricingInfo = pricingSolution["air:AirPricingInfo"];
          const bookingInfos = Array.isArray(pricingInfo["air:BookingInfo"]) ? pricingInfo["air:BookingInfo"] : [pricingInfo["air:BookingInfo"]];
          bookingInfos.forEach(bookingInfo => {
            if (bookingInfo["@SegmentRef"] === segmentKey) {
              const fareInfoRef = bookingInfo["@FareInfoRef"];
              const fareInfo = parsedData["air:FareInfoList"]["air:FareInfo"].find((fare: any) => fare["@Key"] === fareInfoRef);
              const brand = isObject(parsedData?.["air:BrandList"]?.["air:Brand"])
                ?
                parsedData?.["air:BrandList"]?.["air:Brand"]
                :
                parsedData?.["air:BrandList"]?.["air:Brand"]?.find((br: any) => br?.["@BrandID"] === fareInfo?.["air:Brand"]?.["@BrandID"]);

              // console.log("parsedData: ", parsedData?.["air:BrandList"]?.["air:Brand"]);
              // console.log("brand: ", brand);
              matchingPricingSolutions.push({
                "PricingSolution": pricingSolution,
                "PricingInfo": pricingInfo,
                "FareInfo": fareInfo,
                "Brand": brand
              });
            }
          });
        });
      });

      // Merging data
      mergedData.push({
        "AirSegment": matchingSegments,
        "FlightDetails": flightDetail,
        "PricingSolutions": matchingPricingSolutions
      });
    };
  });

  // console.log("mergedData", mergedData);

  const tripInfo = searchInfo?.toCity
    ?.filter((to) => to !== null)
    ?.map((trip, index) => ({
      Origin: searchInfo.fromCity[index]?.RouteDestinationCode,
      Destination: trip?.RouteDestinationCode
    }));

  let journeys: Array<any> = [];
  let formattedFlightJourneys: TFlightResultList[][] = [];
  tripInfo.forEach(eachTrip => {
    const journey = findJourneys(mergedData, eachTrip.Origin, eachTrip.Destination);
    journeys.push(journey);
    console.log("journey: ", journey);

    // 1 - One Way, 2 - Round Trip, 3 - Multi City
    const searchType = searchInfo.travelType.SearchTypeId;
    let airLegs = [];

    if (searchType === 1) {
      airLegs = [parsedData["air:RouteList"]["air:Route"]["air:Leg"]];
    } else if (searchType === 2) {
      airLegs = parsedData["air:RouteList"]["air:Route"]["air:Leg"];
    } else if (searchType === 3) {
      airLegs = parsedData["air:RouteList"]["air:Route"]?.map((leg: any) => leg["air:Leg"]).flat();
    };

    // console.log("airLegs: ", airLegs);
    // console.log("eachTrip: ", eachTrip);
    // console.log("Filtered airLeg: ", airLegs.filter((al: any) => al["@Origin"] === eachTrip.Origin && al["@Destination"] === eachTrip.Destination));
    const airLegRefKey = airLegs.filter((al: any) => al["@Origin"] === eachTrip.Origin && al["@Destination"] === eachTrip.Destination)?.[0]?.["@Key"];
    // console.log("airLegRefKey: ", airLegRefKey);
    const formattedFlightJourney = formatuAPIFlightCardData(
      searchInfo,
      journey,
      airLegRefKey,
      eachTrip,
      originAirports,
      isAgent,
      GSTRates,
      markupResponse,
      affiliateMarkupResponse
    );
    formattedFlightJourneys.push(formattedFlightJourney);
    // console.log("----------------------------------------------------------------");
    // console.log("eachTrip: ", eachTrip);
    // console.log("journeys: ", journey);
    // console.log("formattedFlightJourneys for each trip: ", formattedFlightJourneys);
  });

  // const journeysWithOriginDestination = journeys.map(
  //   (journey: any) => journey.map(
  //     (seg: any) => ({
  //       origin: seg.FlightDetails["@Origin"],
  //       destination: seg.FlightDetails["@Destination"]
  //     })
  //   )
  // );
  // console.log("journeysWithOriginDestination: ", journeysWithOriginDestination);



  return formattedFlightJourneys;

};

const findJourneys = (
  solutions: any,
  start: string,
  end: string
) => {

  // console.log("solutions: ", solutions);

  let journeys: Array<any> = [];
  let queue: {
    current: string;
    segmentPath: any[];
    visited: Set<string>;
    arrivalTime: string;
  }[] = [{
    current: start,
    segmentPath: [],
    visited: new Set([start]),
    arrivalTime: ""
  }];

  while (queue.length > 0) {
    let item = queue.shift();
    if (!item) continue; // Ensure the item is defined
    let { current, segmentPath, visited, arrivalTime } = item;

    if (current === end) {
      journeys.push(segmentPath);
      continue;
    };

    for (let solution of solutions) {
      // const origin = solution.FlightDetails["@Origin"];
      // const destination = solution.FlightDetails["@Destination"];

      const AirSegment = solution.AirSegment[0];
      const {
        "@Origin": origin,
        "@Destination": destination,
        "@DepartureTime": departureTime,
        "@ArrivalTime": arrivalTimeNew
      } = AirSegment;

      // Convert times to Date objects for comparison
      const previousArrival = arrivalTime ? new Date(arrivalTime) : null;
      const currentDeparture = new Date(departureTime);

      // Calculate the minimum gap required in milliseconds
      const minGapHours: number = 2;
      const minGapMs = minGapHours * 60 * 60 * 1000;

      if (
        origin === current
        &&
        !visited.has(destination)
        &&
        (!previousArrival || (currentDeparture.getTime() - previousArrival.getTime() >= minGapMs))
        // (!previousArrival || currentDeparture > previousArrival) // this one is without time (gap between segments) comparison
      ) {
        let newVisited = new Set(visited);
        newVisited.add(destination);
        queue.push({
          current: destination,
          segmentPath: [...segmentPath, solution],
          visited: newVisited,
          arrivalTime: arrivalTimeNew
        });
      };
    };
  };

  // Ensure there are journeys to consider before trying to find the minimum length
  if (journeys.length === 0) return [];

  const minLengthOfSegments = 2; // Math.min(...journeys.map(journey => journey.length));

  return journeys.filter(journey => journey.length === minLengthOfSegments);

};

export const formatuAPIFlightCardData = (
  searchInfo: ISearchInfo,
  journeys: {
    AirSegment: Array<any>;
    FlightDetails: any;
    PricingSolutions: Array<{
      Brand: any;
      FareInfo: any;
      PricingInfo: any;
      PricingSolution: any;
    }>;
  }[][],
  airLegRefKey: string,
  tripInfo: {
    Origin: string;
    Destination: string;
  },
  originAirports: IFLightOrigins[],
  isAgent: boolean,
  GSTRates: {
    ServiceId: number;
    ServiceName: string;
    Value: number;
  }[],
  markupResponse: IMarkUpResponse[],
  affiliateMarkupResponse?: IMarkUpResponse[]
) => {

  // console.group("Format AirIQ Flight List");
  // console.log("journeys: ", journeys);
  // console.log("searchInfo: ", searchInfo);
  // console.log("originAirports: ", originAirports);
  // console.log("isAgent: ", isAgent);
  // console.log("GSTRates: ", GSTRates);
  // console.log("markupResponse: ", markupResponse);
  // console.log("affiliateMarkupResponse: ", affiliateMarkupResponse);
  // console.groupEnd();

  if (journeys.length === 0) {
    return [];
  };

  let formattedJourneyList: TFlightResultList[] = [];

  // Iterate through each journey
  journeys.forEach(journey => {

    const originSegment = journey.filter(j => j.AirSegment[0]["@Origin"] === tripInfo.Origin)[0].AirSegment[0];
    const destinationSegment = journey.filter(j => j.AirSegment[0]["@Destination"] === tripInfo.Destination)[0].AirSegment[0];

    const arrivalDateTime = destinationSegment["@ArrivalTime"];
    const departureDateTime = originSegment["@DepartureTime"];
    const differenceInMinutes = moment(arrivalDateTime).diff(moment(departureDateTime), "minutes");
    const differenceInTime = `${Math.floor(differenceInMinutes / 60)}h ${Math.floor(differenceInMinutes % 60)}m`;

    let data: TFlightResultList = {
      JourneySellKey: "",
      arrivalTime: arrivalDateTime,
      carrierCode: "",
      departureTime: departureDateTime,
      duration: differenceInTime,
      durationInMins: differenceInMinutes,
      airports: [],
      flightCode: [],
      SegmentData: [],
      // navitaire
      FareToShow: {} as IFareData,
      // airiq
      fare_data: {} as IAirIQFareData,
      // uAPI
      journey: null,
      supplier: "1G",
      "1GPricingSolutions": [],
      "1GSegmentData": [],
      fareInfo: {} as IAirIQFareData,
      totalFare: 0
    };

    let airports: Array<{ origin: string; destination: string }> = [];

    // Iterate through each segment within each journey
    journey.forEach((segment, segmentIndex) => {

      // console.log("segment: ", segment);

      const currentSegment = segment.AirSegment[0];

      let filteredPricingSolutions: {
        Brand: any;
        FareInfo: any;
        PricingInfo: any;
        PricingSolution: any;
      }[] = [];
      segment.PricingSolutions.forEach(eachSolution => {
        // console.log("eachSolution: ", eachSolution.PricingSolution["air:LegRef"]["@Key"]);
        // console.log("airLegRefKey: ", airLegRefKey);
        let flag = false;
        if (isObject(eachSolution.PricingSolution["air:LegRef"])) {
          eachSolution.PricingSolution["air:LegRef"]["@Key"] === airLegRefKey && (flag = true);
        } else {
          eachSolution.PricingSolution["air:LegRef"].forEach((legRef: any) => {
            if (legRef["@key"] === airLegRefKey) {
              flag = true;
            };
          });
        };

        if (flag) {
          filteredPricingSolutions.push(eachSolution)
        };
      });
      // console.log("segment.PricingSolutions: ", segment.PricingSolutions);
      // console.log("filteredPricingSolutions: ", filteredPricingSolutions);

      airports.push({
        origin: currentSegment["@Origin"],
        destination: currentSegment["@Destination"]
      });

      data.JourneySellKey = currentSegment["@Key"];
      data.carrierCode = data.carrierCode.concat(`${currentSegment["@Carrier"]} - `);
      data.flightCode = data.flightCode.concat(currentSegment["@FlightNumber"]);
      // data["1GPricingSolutions"].push(filteredPricingSolutions.flat(1)[0]);

      const segmentData = journey.map(seg => { // SegmentData
        const arrivalDateTime = seg.AirSegment[0]["@ArrivalTime"];
        const departureDateTime = seg.AirSegment[0]["@DepartureTime"];
        const differenceInMinutes = moment(arrivalDateTime).diff(moment(departureDateTime), "minutes");
        const differenceInTime = `${Math.floor(differenceInMinutes / 60)}h ${Math.floor(differenceInMinutes % 60)}m`;

        return ({
          carrierCode: seg.AirSegment[0]["@Carrier"],
          duration: differenceInTime,
          durationInMins: differenceInMinutes,
          flightNumber: seg.AirSegment[0]["@FlightNumber"],
          arrival: {
            terminal: "",
            scheduled_time: arrivalDateTime,
            iata_code: seg.AirSegment[0]["@Destination"],
            city: originAirports?.find(
              (airport) =>
                airport?.RouteDestinationCode.toLowerCase() === seg.AirSegment[0]["@Destination"].toLowerCase()
            )?.City || "",
          },
          departure: {
            terminal: "",
            scheduled_time: departureDateTime,
            iata_code: seg.AirSegment[0]["@Origin"],
            city: originAirports?.find(
              (airport) =>
                airport?.RouteDestinationCode.toLowerCase() === seg.AirSegment[0]["@Origin"].toLowerCase()
            )?.City || "",
          },
          "1GPricingSolutions": segment.PricingSolutions,
          fareInfo: {
            price: Number(segment.PricingSolutions[0]?.PricingSolution["@TotalPrice"].slice(3)),
            infant_price: 0,
            markedUpPrice: getFlightMarkedUpPriceForSearchResults(
              markupResponse,
              Number(segment.PricingSolutions[0]?.PricingSolution["@TotalPrice"].slice(3)), // only considering price, and have ignored infant_price as for now
              0, // tax price
              currentSegment["@Carrier"] === "SpiceJet" ? "Spicejet" : currentSegment["@Carrier"], // airline
              searchInfo?.adult + searchInfo?.child,
              searchInfo?.toCity?.length,
              true,
              isAgent,
              GSTRates,
              affiliateMarkupResponse
            )
          }
        });
      });

      data["1GSegmentData"] = segmentData;
      data.SegmentData = segmentData;

    });

    let uniqueAirports = constructJourneyUniqueAirportArray(airports);
    data.airports = uniqueAirports;
    data.totalFare = data["1GSegmentData"][0].fareInfo.price; // data["1GSegmentData"].map((segment) => segment.fareInfo.price).reduce((acc, val) => acc + val, 0);
    data.journey = journey;
    formattedJourneyList.push(data);

  });

  console.log("formattedJourneyList: ", formattedJourneyList);
  return formattedJourneyList;

};

//   const parsed_data = responseData['air:LowFareSearchRsp'];
//   const mergedData: Array<any> = [];

//   const segment_details: any = {};
//   parsed_data['air:AirSegmentList']['air:AirSegment'].forEach((air_segment: any) => {
//     const segment_key = air_segment['@Key'];
//     let flight_details_refs = air_segment['air:FlightDetailsRef'];
//     if (!Array.isArray(flight_details_refs)) {
//       flight_details_refs = [flight_details_refs];
//     }
//     const flight_details = flight_details_refs.map((flight_details_ref: any) => {
//       return parsed_data['air:FlightDetailsList']['air:FlightDetails'].find((fd: any) => fd['@Key'] === flight_details_ref['@Key']);
//     });
//     segment_details[segment_key] = {
//       'AirSegment': air_segment,
//       'FlightDetails': flight_details,
//       'PricingSolutions': []
//     };
//   });

//   parsed_data['air:AirPricingSolution'].forEach((pricing_solution: any) => {
//     const pricing_info = pricing_solution['air:AirPricingInfo'];
//     if (Array.isArray(pricing_info['air:BookingInfo'])) {
//       pricing_info['air:BookingInfo'].forEach(booking_info => {
//         const segment_key = booking_info['@SegmentRef'];
//         const fare_info_ref = booking_info['@FareInfoRef'];
//         const fare_info = parsed_data['air:FareInfoList']['air:FareInfo'].find((fare: any) => fare['@Key'] === fare_info_ref);
//         const brand = parsed_data['air:BrandList']['air:Brand'].find((br: any) => br['@BrandID'] === fare_info['air:Brand']['@BrandID']);
//         segment_details[segment_key]['PricingSolutions'].push({
//           'PricingSolution': pricing_solution,
//           'PricingInfo': pricing_info,
//           'FareInfo': fare_info,
//           'Brand': brand
//         });
//       });
//     } else {
//       const booking_info = pricing_info['air:BookingInfo'];
//       const segment_key = booking_info['@SegmentRef'];
//       const fare_info_ref = booking_info['@FareInfoRef'];
//       const fare_info = parsed_data['air:FareInfoList']['air:FareInfo'].find((fare: any) => fare['@Key'] === fare_info_ref);
//       const brand = parsed_data['air:BrandList']['air:Brand'].find((br: any) => br['@BrandID'] === fare_info['air:Brand']['@BrandID']);
//       segment_details[segment_key]['PricingSolutions'].push({
//         'PricingSolution': pricing_solution,
//         'PricingInfo': pricing_info,
//         'FareInfo': fare_info,
//         'Brand': brand
//       });
//     }
//   });

//   Object.values(segment_details).forEach(segment => {
//     mergedData.push(segment);
//   });

//   console.log("mergedData:- ", mergedData);

// };



// export const formatUAPIJourney = (responseData: any) => {

//   const parsedData = responseData["air:LowFareSearchRsp"];

//   let mergedData: Array<any> = [];
//   parsedData["air:AirSegmentList"]["air:AirSegment"].forEach((airSegment: any) => {

//     const segmentKey = airSegment["@Key"];
//     let flightDetailsRefs = airSegment["air:FlightDetailsRef"];
//     if (!Array.isArray(flightDetailsRefs)) {
//       flightDetailsRefs = [flightDetailsRefs];
//     };
//     let flightDetails = flightDetailsRefs.map((flightDetailsRef: any) => {
//       return parsedData["air:FlightDetailsList"]["air:FlightDetails"].find((fd: any) => fd["@Key"] === flightDetailsRef["@Key"]);
//     });

//     let matchingPricingSolutions: Array<any> = [];
//     parsedData["air:AirPricingSolution"].forEach((pricingSolution: any) => {

//       const pricingInfo = pricingSolution["air:AirPricingInfo"];
//       const bookingInfos = Array.isArray(pricingInfo["air:BookingInfo"]) ? pricingInfo["air:BookingInfo"] : [pricingInfo["air:BookingInfo"]];

//       bookingInfos.forEach(bookingInfo => {

//         if (bookingInfo["@SegmentRef"] === segmentKey) {

//           const fareInfoRef = bookingInfo["@FareInfoRef"];
//           const fareInfo = parsedData["air:FareInfoList"]["air:FareInfo"].find((fare: any) => fare["@Key"] === fareInfoRef);
//           const brand = parsedData["air:BrandList"]["air:Brand"].find((br: any) => br["@BrandID"] === fareInfo["air:Brand"]["@BrandID"]);

//           matchingPricingSolutions.push({
//             PricingSolution: pricingSolution,
//             PricingInfo: pricingInfo,
//             FareInfo: fareInfo,
//             Brand: brand
//           });

//         };

//       });

//     });

//     // Merging data
//     mergedData.push({
//       AirSegment: airSegment,
//       FlightDetails: flightDetails,
//       PricingSolutions: matchingPricingSolutions
//     });

//   });

//   console.log("mergedData: ", mergedData);

// };



// export const formatUAPIJourney = (responseData: any) => {

//   const parsed_data = responseData["air:LowFareSearchRsp"];
//   let merged_data: any[] = [];

//   for (const pricing_solution of parsed_data["air:AirPricingSolution"]) {

//     let pricing_info = pricing_solution["air:AirPricingInfo"];
//     let segment_ref =
//       isObject(pricing_info["air:BookingInfo"])
//         ?
//         pricing_info["air:BookingInfo"]["@SegmentRef"]
//         :
//         Array.isArray(pricing_info["air:BookingInfo"])
//           ?
//           pricing_info["air:BookingInfo"][0]["@SegmentRef"]
//           :
//           null;
//     let fare_info_ref =
//       isObject(pricing_info["air:BookingInfo"])
//         ?
//         pricing_info["air:BookingInfo"]["@FareInfoRef"]
//         :
//         Array.isArray(pricing_info["air:BookingInfo"])
//           ?
//           pricing_info["air:BookingInfo"][0]["@FareInfoRef"]
//           :
//           null;
//     let air_segment = parsed_data["air:AirSegmentList"]["air:AirSegment"].find((segment: any) => segment["@Key"] == segment_ref) || null;
//     let fare_info = parsed_data["air:FareInfoList"]["air:FareInfo"].find((fare: any) => fare["@Key"] == fare_info_ref) || null;
//     let brand = parsed_data["air:BrandList"]["air:Brand"].find((br: any) => br["@BrandID"] == fare_info["air:Brand"]["@BrandID"]) || null;
//     let flight_details_ref =
//       isObject(air_segment["air:FlightDetailsRef"])
//         ?
//         air_segment["air:FlightDetailsRef"]["@Key"]
//         :
//         Array.isArray(air_segment["air:FlightDetailsRef"])
//           ?
//           air_segment["air:FlightDetailsRef"][0]["@Key"]
//           :
//           null;
//     let flight_details = parsed_data["air:FlightDetailsList"]["air:FlightDetails"].find((fd: any) => fd["@Key"] == flight_details_ref) || null;

//     merged_data.push({
//       PricingSolution: pricing_solution,
//       PricingInfo: pricing_info,
//       AirSegment: air_segment,
//       FareInfo: fare_info,
//       Brand: brand,
//       FlightDetails: flight_details
//     });

//   };

//   console.log("merged_data: ", merged_data);

// };