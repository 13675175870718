import { IBlockRoom, IBookHotel, IMarkup, TypeHotelServices } from './../types/hotelTypes';
import moment from "moment";
import { IHotelListingInfo, ITBOAvailabilityPayload } from "../types/hotelTypes";
import {
    IClientData,
    ICombination,
    IDynamicHotelInfo,
    IDynamicHotelRate,
    IDynamicHotelRoomInfo,
    IMetaHotel,
    IPassenger,
    IRoom,
    IRoomGuests,
    ISearchHotelInfo,
    ISelectedHotel,
    ITBOHotelDetails,
    ITBORoom,
} from "./../../redux/types";
import { getMarkedUpPrice, getMyChargesAmount } from './utilityFunction';


// TBO ROOM COMBINATION
export const formatRoomsToCombinationArr = (data: any) => {
    let HotelRoomsDetails: ITBORoom[] = data?.HotelRoomsDetails;
    const InfoSource: 'FixedCombination' | 'OpenCombination' = data?.RoomCombinations?.InfoSource;
    const RoomCombinationArr: Number[][] = data?.RoomCombinations?.RoomCombination?.map((room: any) => room?.RoomIndex);

    return RoomCombinationArr.map((roomIndex: Number[]) =>
        roomIndex?.map(room =>
            HotelRoomsDetails?.filter(r => r?.RoomIndex === room)[0]
        ));
}

/**
 * This function is used to handle the selection of a room combination
 * @param {'FixedCombination' | 'OpenCombination'} combinationType - 'FixedCombination' |
 * 'OpenCombination'
 * @param {number} selectedCombinationIndex - number
 * @param {IRoom[][]} data - The data that is passed to the component.
 */
export const handleRoomSelect = (
    combinationType: "FixedCombination" | "OpenCombination",
    selectedCombinationIndex: number,
    data: ITBORoom[][]
) => {
    let finalData: ITBORoom[] = [];
    if (combinationType === "FixedCombination") {
        finalData = data[selectedCombinationIndex];
        // push finalData to redux
    } else {
        // OpenCombination
        finalData.push(data[selectedCombinationIndex][0]);
        data.filter(
            (room: ITBORoom[], index: number) => index !== selectedCombinationIndex
        );
        //  if(finalData.length===NumberOfRooms){
        //     // push to redux
        //  }
    }
    // if finalData.length is equal to no of searched rooms then all rooms are selected
};

// HOTELBEDS ROOM COMBINATION
/**
 * Given a list of room wise combinations, it returns all possible combinations of the rooms
 * @param {IDynamicHotelRate[][]} roomWiseCombination - An array of arrays of DynamicHotelRate objects.
 * @returns An array of objects with the price and the combination of rooms.
 */
function getAllCombination(roomWiseCombination: IDynamicHotelRate[][]) {
    let result: {
        id: number;
        price: number;
        combination: IDynamicHotelRate[];
    }[];

    let temp: IDynamicHotelRate[][] = [];
    let max = roomWiseCombination.length - 1;
    function helper(arr: IDynamicHotelRate[], i: number) {
        for (let j = 0, l = roomWiseCombination[i].length; j < l; j++) {
            let a = arr.slice(0); // clone arr
            a.push(roomWiseCombination[i][j]);
            if (i === max) temp.push(a);
            else helper(a, i + 1);
        };
    };

    helper([], 0);

    result = temp.map((e, i) => ({
        id: i,
        price: e.reduce((acc: number, each) => acc + +each.net, 0),
        combination: e,
    }));
    return result;
};

/**
 * Given a list of rates and a list of room guests, return all possible combinations of rates that can
 * be used to book the rooms
 * @param {IDynamicHotelRate[]} rates - IDynamicHotelRate[]
 * @param {IRoomGuests[]} roomGuest - IRoomGuests[]
 */
function getCombinations(rates: IDynamicHotelRate[], roomGuest: IRoomGuests[]) {
    let result: { id: number; price: number; combination: IDynamicHotelRate[] }[];

    const roomWiseCombination = roomGuest.map((room) =>
        rates.filter(
            (rate) =>
                rate.adults === room.NoOfAdults && rate.children === room.NoOfChild
        )
    );

    result = getAllCombination(roomWiseCombination);

    return result;
};

/**
 * Generates room combinations for a given hotel's dynamic data and room guests.
 *
 * @param {IDynamicHotelInfo} hotelDynaminData - The dynamic data of the hotel.
 * @param {IRoomGuests[]} roomGuest - The list of room guests.
 * @return {IFinalRoomCombination} - The final room combinations sorted by price in ascending order.
 */
export const createRoomCombinationsForHotelBedsRateArray = (
    hotelDynaminData: IDynamicHotelInfo,
    roomGuest: IRoomGuests[]
) => {
    // /* Flattening the array of arrays of rates. */
    // let combinedRates: IDynamicHotelRate[] = hotelDynaminData?.rooms
    //     ?.map((room: IDynamicHotelRoomInfo) => room?.rates)
    //     ?.flat();

    let combinedRates: IDynamicHotelRate[] = hotelDynaminData?.rooms
        ?.map((room: IDynamicHotelRoomInfo) => room?.rates?.map(rate => ({ ...rate, roomName: room.name })))
        ?.flat();

    let uniqueBoardCodes: string[] = Array.from(
        new Set(combinedRates.map((rates) => rates.boardCode))
    );

    let roomCombinations: IDynamicHotelRate[][] = uniqueBoardCodes.map(
        (boardCode) =>
            combinedRates.filter(
                (rate: IDynamicHotelRate) => rate.boardCode === boardCode
            )
    );

    // console.group();
    // console.log("combinedRates: ", combinedRates);
    // console.log("uniqueBoardCodes: ", uniqueBoardCodes);
    // console.log("roomCombinations: ", roomCombinations);
    // console.groupEnd();

    interface IFinalRoomCombination {
        /* x denoted dynamic boardcode */
        [x: string]: {
            id: number,
            price: number;
            combination: IDynamicHotelRate[];
        }[];
    };

    let finalRoomCombinations: IFinalRoomCombination = {};
    // e -> combines rates[] based on boardcode
    roomCombinations.forEach((e) => {
        finalRoomCombinations = {
            ...finalRoomCombinations,
            [e[0].boardCode]: getCombinations(e, roomGuest),
        };
    });
    // console.log("final room Combination ", finalRoomCombinations);

    let sortedCombination: IFinalRoomCombination = {};
    /* Sorting the finalRoomCombinations based on the price in assending order. */
    Object.keys(finalRoomCombinations).forEach((key) => sortedCombination[key] = finalRoomCombinations[key].sort((a, b) => a.price - b.price));

    let combination: IFinalRoomCombination = {};
    Object.keys(sortedCombination).forEach((key) => combination[key] = sortedCombination[key].filter((value, index, self) => self.findIndex((t) => t.price === value.price) === index));
    // console.log("sortedCombination ", sortedCombination)
    // console.log("sortedCombination ", combination);

    return combination;

};


/**
 * Format the hotel availability list for TBO
 * @param {any} hotelList - The list of hotels returned by the TBO API.
 * @param {string} checkIn - The check-in date for the hotel search.
 * @param {string} checkOut - The check-out date for the hotel search.
 * @returns an array of objects that contain the hotel name, star rating, address, description,
 * picture, price, and number of nights.
 */
export const formatHotelAvailabilityList = (
    hotelList: any,
    checkIn: string,
    checkOut: string,
    service: TypeHotelServices
) => {
    let formattedHotelList: IHotelListingInfo[] = [];

    if (service === "TBO") {
        formattedHotelList = hotelList?.map((hotel: any) => {
            return Object.assign({
                hotelName: hotel?.HotelName,
                starRating: hotel?.StarRating,
                hotelAddress: hotel?.HotelAddress,
                hotelDescription: hotel?.HotelDescription,
                hotelPicture: hotel?.HotelPicture,
                price: hotel?.Price?.OfferedPrice,
                noOfNights: moment(checkOut).diff(checkIn, "days"),
                hotelCode: hotel?.HotelCode || "",
                hotelResultIndex: hotel?.ResultIndex || "",
                serviceProvider: "TBO"
            });
        });
    } else {
        formattedHotelList = hotelList?.map((hotel: ISelectedHotel) => {
            return Object.assign({
                hotelName: hotel?.hotel_dynamic_data.name,
                starRating: hotel?.hotel_dynamic_data?.categoryName?.split(" ")[0],
                hotelAddress: hotel?.hotel_static_data.address?.content || "",
                hotelDescription: hotel?.hotel_static_data.description?.content || "",
                hotelPicture: hotel.hotel_static_data.images?.find((image) => image?.imageTypeCode?.toLocaleLowerCase() === "gen")?.path || "", // hotel.hotel_static_data.images?.find((image) => image?.imageTypeCode?.toLocaleLowerCase() === "general view")?.path || "",
                price: hotel?.hotel_dynamic_data.minRate,
                facilities: hotel.hotel_static_data.facilities?.map(facility => facility?.facilityName),
                noOfNights: moment(checkOut).diff(checkIn, "days"),
                hotelCode: hotel?.hotel_dynamic_data.code || "",
                hotelResultIndex: "",
                zoneCode: hotel.hotel_dynamic_data.zoneCode,
                zoneName: hotel.hotel_dynamic_data.zoneName,
                serviceProvider: "HOTELBEDS"
            });
        });
    }

    return formattedHotelList;
};

/**
 * The function extracts unique zone information from a list of hotels.
 * @param {ISelectedHotel[]} hotelList - An array of objects representing hotels. Each object
 * should have a property called "hotel_dynamic_data" which contains the zoneCode and zoneName for the
 * hotel.
 * @returns an array of unique zone information extracted from the provided hotel list. Each object in
 * the array contains a zone code and zone name.
 */
export const extractUniqueZoneInfoFromHotelList = (hotelList: ISelectedHotel[]) => {

    // Create an array to store unique objects for zones
    let uniqueData: {
        zoneCode: number;
        zoneName: string;
    }[] = [];

    hotelList?.forEach(item => {

        if (!uniqueData.find(each => each)) {

            let zoneInfo: {
                zoneCode: number;
                zoneName: string;
            } = Object.assign({
                zoneCode: item.hotel_dynamic_data.zoneCode,
                zoneName: item.hotel_dynamic_data.zoneName
            });
            uniqueData.push(zoneInfo);

        };

    });

    return uniqueData;

};

/**
 * This function formats the payload for TBO block room and book hotel API request
 * @param {ITBOHotelDetails} searchedHotel - ITBOHotelDetails,
 * @param {ISearchHotelInfo} searchHotel - ISearchHotelInfo
 * @param {ITBORoom[]} selectedRoom - ITBORoom[]
 * @param {IPassenger[][]} passenger - IPassenger[][]
 * @param {string} TraceId - This is a unique identifier for the request.
 * @returns The return value is an object that contains two properties: blockRoomPayload and
 * bookingPayload.
 * blockRoomPayload is the payload that is sent to the block room API.
 * bookingPayload is the payload that is sent to the book hotel API.
 */
export const formatPayloadForTBOBlockRoom = (
    searchedHotel: ITBOHotelDetails,
    searchHotel: ISearchHotelInfo,
    selectedRoom: ITBORoom[],
    passenger: IPassenger[][],
    TraceId: string
) => {
    // format payload for block room
    let blockRoomPayload: IBlockRoom = {
        resultIndex: String(searchedHotel?.ResultIndex),
        hotelCode: searchedHotel?.HotelCode,
        hotelName: searchedHotel?.HotelName,
        guestNationality: "IN",
        noOfRooms: String(searchHotel?.noOfRooms),
        clientReferenceNo: "0",
        isVoucherBooking: "true",
        hotelRoomDetails: selectedRoom.map((each: ITBORoom) => ({
            RoomIndex: String(each?.RoomIndex),
            RoomTypeCode: each?.RoomTypeCode,
            RoomTypeName: each?.RoomTypeName,
            RatePlanCode: String(each?.RatePlanCode),
            BedTypeCode: null,
            SmokingPreference: 0,
            Supplements: null,
            Price: {
                AgentCommission: each?.Price?.AgentCommission,
                AgentMarkUp: each?.Price?.AgentMarkUp,
                ChildCharge: each?.Price?.ChildCharge,
                CurrencyCode: each?.Price?.CurrencyCode,
                Discount: each?.Price?.Discount,
                ExtraGuestCharge: each?.Price?.ExtraGuestCharge,
                GST: {
                    CGSTAmount: each?.Price?.GST?.CGSTAmount,
                    CGSTRate: each?.Price?.GST?.CGSTRate,
                    CessAmount: each?.Price?.GST?.CessAmount,
                    CessRate: each?.Price?.GST?.CessRate,
                    IGSTAmount: each?.Price?.GST?.IGSTAmount,
                    IGSTRate: each?.Price?.GST?.IGSTRate,
                    SGSTAmount: each?.Price?.GST?.SGSTAmount,
                    SGSTRate: each?.Price?.GST?.SGSTRate,
                    TaxableAmount: each?.Price?.GST?.TaxableAmount,
                },
                OfferedPrice: each?.Price?.OfferedPrice,
                OfferedPriceRoundedOff: each?.Price?.OfferedPriceRoundedOff,
                OtherCharges: each?.Price?.OtherCharges,
                PublishedPrice: each?.Price?.PublishedPrice,
                PublishedPriceRoundedOff: each?.Price?.PublishedPriceRoundedOff,
                RoomPrice: each?.Price?.RoomPrice,
                ServiceCharge: each?.Price?.ServiceCharge,
                ServiceTax: each?.Price?.ServiceTax,
                TCS: each?.Price?.TCS,
                TDS: each?.Price?.TDS,
                Tax: each?.Price?.Tax,
                TotalGSTAmount: each?.Price?.TotalGSTAmount,
            },
        })),
        traceId: TraceId
    };

    // format payload for book hotel
    let bookingPayload: IBookHotel = {
        resultIndex: String(searchedHotel?.ResultIndex),
        hotelCode: searchedHotel?.HotelCode,
        hotelName: searchedHotel?.HotelName,
        guestNationality: "IN",
        noOfRooms: String(searchHotel?.noOfRooms),
        clientReferenceNo: "0",
        isVoucherBooking: "true",
        IsPackageFare: "false",
        IsPackageDetailsMandatory: "false",
        hotelRoomDetails: selectedRoom.map((each, roomIndex) => ({
            RoomIndex: String(each?.RoomIndex),
            RoomTypeCode: each?.RoomTypeCode,
            RoomTypeName: each?.RoomTypeName,
            RatePlanCode: String(each?.RatePlanCode),
            BedTypeCode: null,
            SmokingPreference: 0,
            Supplements: null,
            Price: {
                AgentCommission: each?.Price?.AgentCommission,
                AgentMarkUp: each?.Price?.AgentMarkUp,
                ChildCharge: each?.Price?.ChildCharge,
                CurrencyCode: each?.Price?.CurrencyCode,
                Discount: each?.Price?.Discount,
                ExtraGuestCharge: each?.Price?.ExtraGuestCharge,
                GST: {
                    CGSTAmount: each?.Price?.GST?.CGSTAmount,
                    CGSTRate: each?.Price?.GST?.CGSTRate,
                    CessAmount: each?.Price?.GST?.CessAmount,
                    CessRate: each?.Price?.GST?.CessRate,
                    IGSTAmount: each?.Price?.GST?.IGSTAmount,
                    IGSTRate: each?.Price?.GST?.IGSTRate,
                    SGSTAmount: each?.Price?.GST?.SGSTAmount,
                    SGSTRate: each?.Price?.GST?.SGSTRate,
                    TaxableAmount: each?.Price?.GST?.TaxableAmount,
                },
                OfferedPrice: each?.Price?.OfferedPrice,
                OfferedPriceRoundedOff: each?.Price?.OfferedPriceRoundedOff,
                OtherCharges: each?.Price?.OtherCharges,
                PublishedPrice: each?.Price?.PublishedPrice,
                PublishedPriceRoundedOff: each?.Price?.PublishedPriceRoundedOff,
                RoomPrice: each?.Price?.RoomPrice,
                ServiceCharge: each?.Price?.ServiceCharge,
                ServiceTax: each?.Price?.ServiceTax,
                TCS: each?.Price?.TCS,
                TDS: each?.Price?.TDS,
                Tax: each?.Price?.Tax,
                TotalGSTAmount: each?.Price?.TotalGSTAmount,
            },
            HotelPassenger: passenger[roomIndex].map(
                (eachPassenger: IPassenger, passengerIndex) => {
                    let obj: any = {
                        Title: eachPassenger.Title,
                        FirstName: eachPassenger.FirstName?.trim(),
                        LastName: eachPassenger.LastName?.trim(),
                        PaxType: eachPassenger.PaxType,
                        Age: eachPassenger.Age ? Number(eachPassenger.Age) : 0,
                        Email: eachPassenger.Email?.trim(),
                        Phoneno: eachPassenger.CountryCode + eachPassenger.Phoneno,
                        LeadPassenger: passengerIndex === 0 ? "true" : "false",
                    };
                    if (eachPassenger?.InternationalTraveler === "No") {
                        obj["PAN"] = eachPassenger.PAN?.trim()?.toUpperCase();
                    } else {
                        obj["PassportNo"] = eachPassenger.PassportNo?.trim();
                        obj["PassportIssueDate"] = eachPassenger.PassportIssueDate || "";
                        obj["PassportExpDate"] = eachPassenger.PassportExpDate || "";
                    }

                    return obj;
                }
            ),
        })),
        traceId: TraceId,
    };

    return { blockRoomPayload, bookingPayload };
};


export const getTotalGuestCountForHotel = (roomGuest: IRoomGuests[]) => {
    let totalAdults = roomGuest?.reduce((accu, curr) => accu += Number(curr.NoOfAdults), 0);
    let totalChild = roomGuest?.reduce((accu, curr) => accu += Number(curr.NoOfChild), 0);

    return (totalAdults + totalChild);
};


export const handleGetCharges = async (
    searchHotel: ISearchHotelInfo,
    metaData: IMetaHotel,
    ClientData: IClientData,
    supplier: TypeHotelServices,
    HBCombination: IDynamicHotelRate[],
    HTCombination: ITBORoom[],
) => {

    //* calculating no. of rooms, adults and children
    let rooms = searchHotel?.noOfRooms;
    let adults = searchHotel?.RoomGuest?.reduce(
        (accu, curr) => (accu += Number(curr?.NoOfAdults)),
        0
    );
    let children = searchHotel?.RoomGuest?.reduce(
        (accu, curr) => (accu += Number(curr?.NoOfChild)),
        0
    );

    //* calculating billing info based on selected rooms
    let total_TAX: number = supplier === "HOTELBEDS" ? 0 : HTCombination.reduce((acc, curr) => curr?.Price?.Tax + acc, 0) || 0;
    let total_GST: number = supplier === "TBO" ? 0 : HTCombination.reduce((acc, curr) => curr?.Price?.TotalGSTAmount + acc, 0) || 0;

    let supplierName = metaData.BookingSupplier?.find(
        each => each?.SupplierName?.toLowerCase() === "hotelbeds")?.SupplierName
        ||
        metaData.BookingSupplier[0].SupplierName;

    let markup_charges: IMarkup = {
        Base: 0,
        baseMarkup: 0,
        Service: 0,
        Booking: 0,
        GST: 0
    };

    if (supplier === "HOTELBEDS" && HBCombination) {
        markup_charges = getMarkedUpPrice(
            metaData.markUps,
            HBCombination.reduce((accu, curr) => accu += Number(curr.net), 0),
            supplierName,
            ClientData.GSTRates[0]?.Value,
            metaData.affiliateMarkup,
            searchHotel.RoomGuest
        ).markedUpPrice;
    } else if (supplier === "TBO" && HTCombination) {
        markup_charges = getMarkedUpPrice(
            metaData.markUps,
            HTCombination.reduce((accu, curr) => accu += Number(curr.Price.OfferedPrice), 0),
            supplierName,
            ClientData.GSTRates[0]?.Value,
            metaData.affiliateMarkup,
            searchHotel.RoomGuest
        ).markedUpPrice;
    }

    let markup: {
        Base: number;
        baseMarkup: number;
        Service: number;
        Booking: number;
        GST: number;
    } = {
        Base: 0,
        baseMarkup: 0,
        Service: 0,
        Booking: 0,
        GST: 0
    };
    markup.Base += markup_charges.Base;
    markup.baseMarkup = markup_charges.baseMarkup;
    markup.Service += markup_charges.Service;
    markup.Booking += markup_charges.Booking;
    markup.GST += markup_charges.GST;

    //* calculating myCharges based on data received from myCharges API request
    let myCharges: number = 0
    if (supplier === "HOTELBEDS" && HBCombination) {
        myCharges = getMyChargesAmount(
            ClientData.MyCharges,
            HBCombination.reduce((accu, curr) => accu += Number(curr.net), 0),
            getTotalGuestCountForHotel(searchHotel.RoomGuest),
            HBCombination?.length
        );
    } else if (supplier === "TBO" && HTCombination) {
        myCharges = getMyChargesAmount(
            ClientData.MyCharges,
            HTCombination.reduce((accu, curr) => accu += Number(curr.Price.OfferedPrice), 0),
            getTotalGuestCountForHotel(searchHotel.RoomGuest),
            HTCombination?.length
        )
    }

    let billingCharges = {
        markedUpBasePrice: markup.Base - markup.GST, //markup_charges.Base,
        Tax: total_TAX,
        GST: total_GST + markup.GST,
        otherMarkUpCharges: markup.Service + markup.Booking, //markup_charges.Service + markup_charges.Booking,
        myCharges: myCharges
    };

    return {
        rooms,
        adults,
        children,
        billingCharges
    };

};

export const calculateDateForFreeCancellation = (date: string) => {
    const DAYS_TO_BE_SUBTRACTED = 3;
    const calculated_date = moment(date).subtract(DAYS_TO_BE_SUBTRACTED, 'd').format("MMM Do YYYY hh:mm a");
    // console.group("calculateDateForFreeCancellation");
    // console.log("parameter: ", date);
    // console.groupEnd();
    // console.log("calculated_date: ", moment(calculated_date, "MMM Do YYYY h:mm a").format("YYYY-MM-DDTHH:mm:ssZ"));
    // console.log("current_date: ", moment().format("YYYY-MM-DDTHH:mm:ssZ"));
    // console.log(moment(moment().format("YYYY-MM-DDTHH:mm:ssZ")).isAfter(moment(calculated_date, "MMM Do YYYY h:mm a").format("YYYY-MM-DDTHH:mm:ssZ")));
    return calculated_date;
};

export const calculateDateForCancellation = (date: string) => {
    const DAYS_TO_BE_SUBTRACTED = 3;
    const calculated_date = moment(date).subtract(DAYS_TO_BE_SUBTRACTED, 'd').format("MMM Do YYYY hh:mm a");
    return calculated_date;
};

/**
 * Determines if date1 is after date2.
 *
 * @param {string} date1 - The first date to compare.
 * @param {string} date2 - The second date to compare.
 * @return {boolean} Returns true if date1 is after date2, otherwise false.
 */
export const isDateAfter = (
    date1: string,
    date2: string
) => {

    const d1 = moment(date1).format("YYYY-MM-DDTHH:mm:ssZ");
    const d2 = moment(date2, "MMM Do YYYY h:mm a").format("YYYY-MM-DDTHH:mm:ssZ");

    if (!d1 || !d2) return false;
    if (moment(d1).isValid() === false || moment(d2).isValid() === false) return false;

    const result = moment(d1).isAfter(d2);
    // console.log(moment(date1).format("YYYY-MM-DDTHH:mm:ssZ"));
    // console.log(moment(date2, "MMM Do YYYY h:mm a").format("YYYY-MM-DDTHH:mm:ssZ"));
    // console.log("result: ", result);
    return result;

};