import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { BsGeo } from "react-icons/bs";
import { GrLocation } from "react-icons/gr";

import "./Input.scss";

import { State } from "../../../redux/types";
import { API_URL } from "../../../utils/constants";
import { initialState } from "../../../redux/initialState";
import useHotel from "../../../utils/hooks/Hotel/useHotel";
import { IHotelDestination, INearbySearchGeoLocation } from "../../../utils/types/hotelTypes";


interface ISuggestionData {
    title: string;
    subtitle?: string;
    value: string | number;
    label: string | number;
    icon?: JSX.Element | string;
    isGeoLocation?: boolean;
};
interface ISuggestionCard {
    onClick: (isCallingFromGeoLocation: boolean) => void;
    title: string;
    label?: string;
    subtitle: string;
    icon?: JSX.Element | string;
    isGeoLocation?: boolean;
    nearbySearchGeoLocations: INearbySearchGeoLocation[];
    onLocationSelect: (selectedLocation: INearbySearchGeoLocation) => any;
    removeGeoLocation: () => void;
};

const SuggestionCard: FC<ISuggestionCard> = ({
    onClick,
    title,
    label,
    subtitle,
    icon,
    isGeoLocation,
    nearbySearchGeoLocations,
    onLocationSelect,
    removeGeoLocation
}) => {
    return (
        <div>
            <motion.div
                className={`input_show-suggestion--card custom-scrollbar ${isGeoLocation ? "disabled-item" : ""}`}
                initial={{ y: 10, opacity: 0 }}
                animate={{
                    y: 0,
                    opacity: 1,
                }}
                onMouseDown={() => {
                    if (!isGeoLocation) {
                        onClick(true);
                        removeGeoLocation();
                    };
                }}
            >
                <div className={`d-flex align-items-center`}>
                    <div className="d-flex flex-column  pr-3">
                        <span className="fw-bold">
                            <GrLocation /> {title} {icon !== undefined ? `(${icon})` : ""}
                        </span>
                        <span className="text-muted" style={{ fontSize: "0.8em" }}>
                            {subtitle}
                        </span>
                    </div>
                </div>
                <div className="small text-sm text-muted">
                    <span>{label}</span>
                </div>
            </motion.div>

            {/* GEO LOCATIONS FROM GOOGLE PLACES NEARBY SEARCH */}
            {
                false // nearbySearchGeoLocations
                    ?
                    nearbySearchGeoLocations.map((each, index) => (
                        <motion.div
                            key={index + "_location"}
                            className="input_show-suggestion--card custom-scrollbar mx-3 my-2"
                            initial={{ y: 10, opacity: 0 }}
                            animate={{
                                y: 0,
                                opacity: 1,
                            }}
                            onMouseDown={() => {
                                onLocationSelect(each);
                                onClick(false);
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <div className="d-flex flex-column  pr-3">
                                    <span className="fw-bold">
                                        <BsGeo /> {each.name}
                                    </span>
                                    <span className="text-muted" style={{ fontSize: "0.8em" }}>
                                        {each.Address}
                                    </span>
                                </div>
                            </div>
                        </motion.div>
                    ))
                    :
                    <></>
            }
        </div>
    );
};


const DestinationSearch: FC<{ allowLocationSearch: boolean }> = ({ allowLocationSearch }) => {

    const { searchHotel } = useSelector((state: State) => state.hotel);

    const {
        handleHotelFilters
    } = useHotel();
    const {
        updateWhereTo,
        updateGeoLocation,
        removeGeoLocation,
        updateNearbyGeoLocations,
        resetNearbyGeoLocations
    } = useHotel();

    const inputFocus = useRef<HTMLInputElement>(null);

    const [text, setText] = useState<string>("");
    const [show, setShow] = useState<boolean>(false);
    // stores searched destination list
    const [destinationList, setDestinationList] = useState<IHotelDestination[]>([]);
    // stores formated destination list
    const [formatedDestinationList, setFormatedDestinationList] = useState<ISuggestionData[]>([]);
    const [isDestinationLoading, setIsDestinationLoading] = useState<boolean>(false);
    // nearby search geo locations
    const [nearbySearchGeoLocations, setNearbySearchGeoLocations] = useState<INearbySearchGeoLocation[]>([]);
    const [isGeoLocationAvailable, setIsGeoLocationAvailable] = useState<boolean>(false);


    useEffect(() => {

        if (text.length < 2) {

            // removeGeoLocation();
            setNearbySearchGeoLocations([]);

            setIsDestinationLoading(true);
            axios.get(`${API_URL}hotels/destinations`).then(
                (response: AxiosResponse) => {
                    // console.log("Fetched destinations", response.data?.data);
                    setDestinationList(response.data?.data);
                    let formatedDestination = response.data?.data?.map((destination: IHotelDestination) => {
                        return {
                            destinationId: destination.DestinationId,
                            title: destination?.CityName,
                            subtitle: "",
                            value: destination?.HB_DestinationNumber,
                            //   HB_DestinationNumber: destination?.HB_DestinationNumber,
                            //   HT_DestinationNumber: destination?.HT_DestinationNumber,
                            label: `${destination?.CountryName} (${destination?.CountryCode})`,
                        }
                    });
                    setFormatedDestinationList(formatedDestination);
                }, (error: AxiosError) => {
                    console.log("Fetched destinations error", error?.response);
                }
            ).finally(() => setIsDestinationLoading(false));

        };

        // if destination is selected, then call to fetch geo locations for that destination
        if (allowLocationSearch) fetchGeoLocations({
            title: searchHotel.whereTo.CityName,
            subtitle: searchHotel.whereTo.CountryName || "",
            value: searchHotel.whereTo.HB_DestinationNumber,
            label: searchHotel.whereTo.DestinationName
        }); // call to fetch geo locations here

    }, []);

    useEffect(() => {

        // removeGeoLocation();
        setNearbySearchGeoLocations([]);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();


        if (text.length >= 2) {

            setIsDestinationLoading(true);

            /* SEARCH DESTINATION/CITY LIST */
            axios.get(
                `${API_URL}hotels/destinations?search=${text}`,
                { cancelToken: source.token }
            ).then(
                (response: AxiosResponse) => {

                    // console.log("Response for fetching destinations: ", response.data?.data);
                    setDestinationList(response.data?.data);
                    let formatedDestination = response.data?.data?.map((destination: IHotelDestination) => {
                        return {
                            destinationId: destination.DestinationId,
                            title: destination?.CityName,
                            subtitle: "",
                            value: destination?.HB_DestinationNumber,
                            //   HB_DestinationNumber: destination?.HB_DestinationNumber,
                            //   HT_DestinationNumber: destination?.HT_DestinationNumber,
                            label: `${destination?.CountryName} (${destination?.CountryCode})`,
                        }
                    });
                    
                    setFormatedDestinationList(formatedDestination);

                }, (error: AxiosError) => {
                    console.log("Error while fetching destinations: ", error?.response);
                }
            ).finally(() => setIsDestinationLoading(false));

            if (allowLocationSearch) {

                /* SEARCH POINTS of INTEREST FROM GOOGLE */
                setIsGeoLocationAvailable(false);
                resetNearbyGeoLocations();
                axios.get(
                    `${API_URL}GetLocationFromGeocode/SearchLocationNew?address=${text}`,
                    { cancelToken: source.token }
                ).then(
                    (response: AxiosResponse) => {

                        removeGeoLocation();
                        // console.log("Response for fetch POI from Google: ", response.data?.data);
                        const data: INearbySearchGeoLocation[] = response.data?.data;
                        if (data?.length) {

                            let geoData = data.map((each, i) => ({
                                ...each,
                                index: i // this is handled in the frontend to uniquely identify the nearby search element
                            }));
                            setIsGeoLocationAvailable(true);
                            setNearbySearchGeoLocations(geoData);
                            updateNearbyGeoLocations(geoData);

                        } else {

                            console.log("Data not found for search POI(nearby places) from Google: ", data);

                        };

                    }, (error: AxiosError) => {

                        console.log("Error while fetching POI from Google: ", error?.response);

                    }
                );

            };

        };

        return () => {
            source.cancel('Operation to fetch destinations and POI canceled by the next request.');
        };

    }, [text]);


    /* HANDLER FUNCTIONS */
    // Filter suggestion list
    let filteredSuggestion: ISuggestionData[] = useMemo(() => {

        if (isGeoLocationAvailable && !formatedDestinationList.length) {

            // console.log("isGeoLocationAvailable: ", isGeoLocationAvailable);
            // console.log("!formatedDestinationList.length: ", !formatedDestinationList.length);
            const data = [{
                title: text,
                value: text,
                label: text,
                isGeoLocation: true
            }];
            // console.log("data: ", data);
            return data;

        };

        if (text.length === 0) {
            // console.log("Length: 0 -", formatedDestinationList.slice(0, 4));
            return formatedDestinationList.slice(0, 8);
        } else {
            // console.log("Length: > 0 -");
            return formatedDestinationList
                ?.filter((item) =>
                    item.title?.toLowerCase()?.includes(text.toLowerCase())
                )
                .slice(0, 4) || [];
        };

    }, [text, formatedDestinationList, isGeoLocationAvailable]);

    const handleSelectionOfWhereTo = (item: ISuggestionData, isCallingFromGeoLocation: boolean) => {

        updateWhereTo(
            destinationList?.find((dest) => dest?.HB_DestinationNumber === item.value)
            ||
            initialState.hotel.searchHotel.whereTo
        );
        setText("");
        setShow(false);

        // if destination is selected, then call to fetch geo locations for that destination
        if (isCallingFromGeoLocation && allowLocationSearch) fetchGeoLocations(item); // call to fetch geo locations here

    };

    const handleSelectGeoLocation = (selectedLocation: INearbySearchGeoLocation) => {
        updateGeoLocation(selectedLocation);
        handleHotelFilters("selectedNearbySearchGeoLocation", String(selectedLocation.index));
    };


    /* API CALLS */
    const fetchGeoLocations = (item: ISuggestionData) => {

        resetNearbyGeoLocations();
        axios.get(
            `${API_URL}GetLocationFromGeocode/SearchLocationNew?address=${item.title}`
        ).then(
            (response: AxiosResponse) => {

                removeGeoLocation();
                // console.log("Response for fetch POI from Google: ", response.data?.data);
                const data: INearbySearchGeoLocation[] = response.data?.data;
                if (data?.length) {

                    let geoData = data.map((each, i) => ({
                        ...each,
                        index: i // this is handled in the frontend to uniquely identify the nearby search element
                    }));
                    setIsGeoLocationAvailable(true);
                    setNearbySearchGeoLocations(geoData);
                    updateNearbyGeoLocations(geoData);

                } else {

                    console.log("Data not found for search POI(nearby places) from Google: ", data);

                };

            }, (error: AxiosError) => {

                console.log("Error while fetching POI from Google: ", error?.response);

            }
        );

    };


    return (
        <div className="input rounded w-100" onMouseLeave={() => setShow(false)}>
            <div
                className={`${show ? "input_show shadow-lg" : "rounded w-100"}`}
                onClick={() => {
                    setShow(true);
                    inputFocus.current?.focus();
                }}
            >
                {!show ? (
                    // <div
                    //     className="py-1 px-2 overflow-hidden"
                    //     style={{
                    //         height: "56px",
                    //     }}
                    // >
                    //     <span className="small text-muted">{label}</span>
                    //     {value ? (
                    //         <p className="fw-bold">
                    //             {value}
                    //             <span className="small">
                    //                 {
                    //                     formatedDestinationList?.find((sug) => sug?.title === value)
                    //                         ?.subtitle
                    //                 }
                    //             </span>
                    //         </p>
                    //     ) : (
                    //         <p className="text-muted ms-auto"></p>
                    //     )}
                    // </div>
                    <div
                        className="py-1 px-2 overflow-hidden"
                        style={{
                            height: "56px"
                        }}
                    >
                        <span className="small text-muted">{searchHotel.whereTo.CityName ? searchHotel.whereTo.CityName : "Search City"}</span>
                        {/* {searchHotel.whereTo.CityName ? (
                            <p className="fw-bold">
                                {searchHotel.whereTo.CityName}
                            </p>
                        ) : (
                            <p className="text-muted ms-auto"></p>
                        )} */}
                        {
                            // searchHotel?.geoLocation?.name
                            //     ?
                            //     <p className="fw-bold">
                            //         {searchHotel.geoLocation.name}
                            //     </p>
                            //     :
                            searchHotel.whereTo.CityName ? (
                                <p className="fw-bold">
                                    {searchHotel.whereTo.CityName}
                                </p>
                            ) : (
                                <p className="text-muted ms-auto">{/* search City... */}</p>
                            )
                        }
                    </div>
                ) : (
                    <div>
                        <input
                            className="form-control search-input"
                            tabIndex={0}
                            type="text"
                            ref={inputFocus}
                            autoFocus
                            onChange={(e) => {
                                const { value } = e.target;
                                setText(String(value));
                            }}
                            placeholder="Search city"
                        />

                        {
                            isDestinationLoading
                                ?
                                <div className="spinner-border spinner-border-sm search-icon" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <></>
                        }

                        <div className="input_show-suggestion">
                            {
                                filteredSuggestion.length > 0
                                    ?
                                    filteredSuggestion?.map((item, index: number) => (
                                        <SuggestionCard
                                            key={index + "_city"}
                                            title={item.title}
                                            subtitle={item?.subtitle || ""}
                                            label={String(item.label)}
                                            icon={item?.icon}
                                            isGeoLocation={item?.isGeoLocation || false}
                                            nearbySearchGeoLocations={nearbySearchGeoLocations}
                                            onClick={(isCallingFromGeoLocation) => handleSelectionOfWhereTo(item, isCallingFromGeoLocation)}
                                            onLocationSelect={(selectedLocation: INearbySearchGeoLocation) => handleSelectGeoLocation(selectedLocation)}
                                            removeGeoLocation={removeGeoLocation}
                                        />
                                    ))
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DestinationSearch;
