import React from "react";
interface Props {
  label: string | JSX.Element;
  color?:
    | "primary"
    | "secondary"
    | "light"
    | "dark"
    | "warning"
    | "danger"
    | "link";
  type?: "submit" | "button" | "clear";
  disabled?: boolean;
  size?: "sm" | "lg" | "xs";
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  outline?: boolean;
  className?: string;
  isLoading?: boolean;
  style?: any;
}

const Button = (props: Props) => {
  return (
    <button
      className={`btn btn-${
        props.outline
          ? `outline-${props.color || "primary"}`
          : props.color || "primary"
      } ${props.size ? `btn-${props.size}` : ""} ${
        props.disabled ? "disabled" : ""
      } ${props.className ? props.className : ""} shadow-none`}
      onClick={(e) => props.onClick && props.onClick(e)}
      style={props.style && props.style}
    >
      {props.isLoading ? (
        <svg height={"20px"} width={"50px"}>
          {[1, 2, 3].map((e, index: number) => {
            let initPos = 20;
            let r = 4;
            let initBounce = 4;
            return (
              <circle
                fill="#fff"
                stroke="none"
                cx={`${initPos + 30 * index}%`}
                cy="50%"
                r={r}
                key={index}
              >
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  type="translate"
                  values={`0  ${initBounce - index}; 0 -${
                    initBounce - index
                  }; 0 ${initBounce - index}`}
                  repeatCount="indefinite"
                  begin={`0.${index + 1}`}
                />
              </circle>
            );
          })}
        </svg>
      ) : (
        props.label
      )}
    </button>
  );
};

export default Button;
