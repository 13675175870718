import { commonStateSlice } from "./../../../redux/reducers/commonState";
// import { useEffect, useState } from "react";
import { State } from "../../../redux/types";
import { flightSlice } from "../../../redux/reducers/flight";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  IFlightSearchData,
  TFlightResultList,
  TypeFlightFiltersKeys,
  TypeFlightSearchKeys,
} from "../../types/flightTypes";

export default function useFlight() {

  const dispatch = useDispatch();

  const { filters } = useSelector((state: State) => state.flight);
  const { isFlightDataFetched } = useSelector((state: State) => state.commonState);



  const updateSearch = (
    key: TypeFlightSearchKeys,
    value: any,
    index?: number
  ) => {
    dispatch(flightSlice.actions.updateSearch({ key, value, index }));
    // reset searched flight data if the data it is already fetched
    if (isFlightDataFetched) {
      dispatch(
        commonStateSlice.actions.updateState({
          key: "isFlightDataFetched",
          value: false,
        })
      );
    }
  };

  const updateFilter = (key: TypeFlightFiltersKeys, value: string) => {
    dispatch(flightSlice.actions.updateFilters({ key, value }));
  };
  const filterByTimeOfFlight = (depTime: string, filter: string[]) => {
    let dep = moment(depTime).get("h");
    return filter?.length === 0
      ? true
      : filter.some((f) => {
        return f === "morning"
          ? dep >= 0 && dep < 12
          : f === "afternoon"
            ? dep >= 12 && dep < 16
            : f === "evening"
              ? dep >= 16 && dep < 20
              : f === "night"
                ? dep >= 20 && dep < 24
                : false;
      });
  };

  const filterFlightsCardData = (flightCardData: TFlightResultList[]) => { // IFlightSearchData[]
    const filteredFlightCardData = flightCardData?.filter((flight) => {
      return (
        (filters?.layover.length !== 0 && filters?.layover.includes(String(flight.SegmentData.length - 1)))
        &&
        (filters?.stops.length === 0 || filters?.stops.includes(String(flight.airports.length - 2)))
        // &&
        // Number(
        //   flight.supplier === "navitaire"
        //     ?
        //     flight?.FareToShow?.markedUpPrice?.markedupTotalPrice || 0
        //     :
        //     flight.supplier === "airiq"
        //       ?
        //       flight?.fare_data?.markedUpPrice?.markedupTotalPrice || 0
        //       :
        //       flight.supplier === "1G"
        //         ?
        //         flight.totalFare
        //         :
        //         0
        // ) <= filters?.priceRange[0]
        &&
        Number(flight?.durationInMins) / 60 <= filters?.duration[0]
        &&
        filterByTimeOfFlight(flight?.departureTime, filters.departureTime)
        &&
        filterByTimeOfFlight(flight?.arrivalTime, filters.arrivalTime)
        &&
        (filters?.airlines.length === 0 || filters?.airlines.includes(flight?.carrierCode))
      );
    });

    // console.log("filteredFlightCardData: ", filteredFlightCardData);
    return filteredFlightCardData;
  };

  const resetFlightData = () => {
    dispatch(flightSlice.actions.resetFlightData());
  };

  // const getAirportData = () => {
  //   setAirports(airPortData);
  // };
  return {
    updateSearch,
    updateFilter,
    filterFlightsCardData,
    // getAirportData,
    resetFlightData,
  };
}
